import React from 'react';
import { Popover, PopoverHandler, PopoverContent } from '@material-tailwind/react';

export interface PopOverFeatureProps {
  className?: string;
  buttonName?: number | string;
  contentData?: string[];
  // contentString?: string;
}

const PopOverFeature: React.FC<PopOverFeatureProps> = ({
  className = undefined,
  buttonName = undefined,
  // contentString = undefined,
  contentData = undefined,
}) => {
  const emptyContentData = contentData && contentData.length > 0;

  return (
    <Popover placement="bottom">
      <PopoverHandler>
        <button type="button" disabled={!emptyContentData}>
          {buttonName}
        </button>
      </PopoverHandler>
      <PopoverContent className=" bg-[#2c2b2a] shadow-md">
        <div className={className}>
          {contentData?.map((content) => (
            <div key={content} className="bg-[#343434] shadow-md p-2 rounded-md">
              {content ?? ''}
            </div>
          ))}
        </div>
        {/* <div className="bg-[#343434] shadow-md p-2 rounded-md">{contentString}</div> */}
      </PopoverContent>
    </Popover>
  );
};

export default PopOverFeature;
