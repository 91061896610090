import React, { useState } from 'react';
import { Field, FieldAttributes } from 'formik';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeSlashIcon } from '../../../assets/images/icons/eye-slash.svg';

interface PasswordFieldProps {
  placeholder?: string;
  className?: string;
}

const PasswordField: React.FC<FieldAttributes<object> & PasswordFieldProps> = ({
  placeholder = 'Password',
  className = '',
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="relative">
      <Field
        type={showPassword ? 'text' : 'password'}
        className={`password-field ${className}`}
        placeholder={placeholder}
        {...props}
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute inset-y-0 right-0 pr-3 flex items-center focus:outline-none"
        tabIndex={-1}
      >
        {showPassword ? (
          <EyeSlashIcon className="w-6 h-6 icon " />
        ) : (
          <EyeIcon className="w-6 h-6 icon " />
        )}
      </button>
    </div>
  );
};

export default PasswordField;
