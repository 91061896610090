import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Checkbox, Typography } from '@material-tailwind/react';
import LeaveRightSideBar from './LeaveRightSideBar';
import UploadFileAttachments from './UploadFileAttachments';
import SelectLeaveNature from '../../../atoms/dropdowns/SelectLeaveNature';
import LeaveReviewFormSubmit from './LeaveReviewFormSubmit';
import useLeaves from '../../../../hooks/leave/useLeaves';
import MultipleDatesPicker from '../../../atoms/datepickers/MultipleDatesPicker';
import CustomRangeDatePicker from '../../../atoms/datepickers/CustomRangeDatePicker';
import countDaysExcluding from '../../../../utils/countTotalDaysWithExclusions';
import SelectEmploymentScheduleType from '../../../atoms/dropdowns/SelectEmploymentScheduleType';
import defaultValue from '../../../../store/constants/utils/default-value.const';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import LeaveNature from '../../../../store/constants/leaves/leave-nature.interface';
import EmploymentScheduleType from '../../../../store/constants/employments/schedules/employment-schedule-type.interface';
import createValidationSchema from '../../../../hooks/utils/validation/leaveValidationSchemas';
import HelperMessage from '../../../atoms/HelperMessage';

interface LeaveFormProps {
  loading?: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  leave?: LeaveApplication;
  onSubmit: (
    values: LeaveApplication,
    formikHelpers: FormikHelpers<LeaveApplication>,
  ) => Promise<void>;
}

const LeaveApplicationForm: React.FC<LeaveFormProps> = ({
  leave = undefined,
  loading = false,
  setLoading,
  onSubmit,
}) => {
  const [dateStart, setDateStart] = useState<Date | undefined>();
  const [dateEnd, setDateEnd] = useState<Date | undefined>();
  const [reviewFormModal, setConfirmationModalOpen] = useState(false);
  const [leaveForm, setLeaveForm] = useState<LeaveApplication | undefined>();

  const { leavesState } = useLeaves();
  const { leaveCredits } = leavesState;

  const convertedExcludedDates =
    leaveForm && leaveForm.excludedDates.map((dateFormat) => new Date(dateFormat));
  const fromManageSelectedDays =
    leaveForm?.dateStart &&
    leaveForm?.dateEnd &&
    countDaysExcluding(
      new Date(leaveForm.dateStart),
      new Date(leaveForm.dateEnd),
      convertedExcludedDates as Date[],
    );

  const validationSchema = createValidationSchema(leaveCredits);

  const closeReviewFormModal = () => {
    setConfirmationModalOpen(false);
  };

  const handleSubmit = async (
    values: Partial<LeaveApplication>,
    formikHelpers: FormikHelpers<LeaveApplication>,
  ) => {
    setLoading(true);
    const { setSubmitting } = formikHelpers;

    onSubmit(values as LeaveApplication, formikHelpers)
      .then(() => {
        setLoading(false);
      })
      .finally(() => closeReviewFormModal());

    setSubmitting(false);
  };

  useEffect(() => {
    if (leave) {
      setLeaveForm(leave);
    }
  }, [leave]);

  return (
    <div className="flex w-full mt-5 leave-application__container">
      {/* Container wrapper */}
      <div className="w-full leave-application__container-wrapper">
        <div className="w-[60%] h-full rounded-xl shadow-lg leave-application__content-wrapper user-newsfeed-test">
          <div className="flex justify-center items-center py-5 leave-application__heading-wrapper">
            {leave && leave.id !== undefined && <span className="rounded-md px-2">Edit Form</span>}
            <Typography variant="h3" className="leave-title">
              Leave Application
            </Typography>
          </div>
          {leave === undefined && (
            <div className="flex items-center gap-2  pb-5 pl-10 leave-application__credits-wrapper">
              <span>{leaveCredits}</span>
              <span>LEAVE CREDITS REMAINING</span>
              <HelperMessage content="1 leave credit is added each month." placement="right" />
            </div>
          )}
          <Formik
            initialValues={
              {
                ...leave,
                id: leave?.id ? leave.id : undefined,
                isPaid: leave?.isPaid ? leave.isPaid : false,
                duration: leave?.duration ? leave.duration : [],
                excludedDates: leave?.excludedDates ? leave.excludedDates : [],
                leaveNature: leave?.leaveNature
                  ? ({
                      ...leave?.leaveNature,
                      label: leave?.leaveNature.nature,
                      value: leave?.leaveNature,
                    } as LeaveNature)
                  : ({
                      ...defaultValue.leaveNature,
                      label: defaultValue.leaveNature.nature,
                      value: defaultValue.leaveNature,
                    } as LeaveNature),
                employmentScheduleType: leave?.employmentScheduleType
                  ? ({
                      ...leave.employmentScheduleType,
                      label: leave?.employmentScheduleType.name,
                      value: leave?.employmentScheduleType,
                    } as EmploymentScheduleType)
                  : ({
                      ...defaultValue.employmentScheduleType,
                      label: defaultValue.employmentScheduleType.name,
                      value: defaultValue.employmentScheduleType,
                    } as EmploymentScheduleType),
                notes: leave?.notes ? leave.notes : undefined,
                attachments: leave?.attachments ? leave.attachments : [],
                attachmentsFile: [],
              } as LeaveApplication
            }
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange
            validateOnBlur={false}
          >
            {(formik: FormikProps<LeaveApplication>) => {
              const selectedDays =
                dateStart && dateEnd
                  ? countDaysExcluding(dateStart, dateEnd, formik.values.excludedDates)
                  : 0;
              return (
                <Form className="h-fit w-full leave-application__form-container">
                  {/* Whole Container */}
                  <div className="flex justify-between form-content">
                    {/* first container in form */}
                    <div className="flex justify-center leave-application__first-container">
                      <div className="h-full w-[80%] leave-content-container">
                        {/* schedule type */}
                        <div className="w-full mb-4 leave-application__input-wrapper">
                          <label
                            htmlFor="employmentScheduleType"
                            className="w-full leave-application__label-title"
                          >
                            SCHEDULE TYPE
                          </label>
                          <div className="w-full mt-1">
                            <SelectEmploymentScheduleType
                              id="employmentScheduleType"
                              name="employmentScheduleType"
                              value={formik.values.employmentScheduleType}
                              setFieldValue={formik.setFieldValue}
                            />
                          </div>
                          <ErrorMessage
                            className="leave-error"
                            name="employmentScheduleType"
                            component="div"
                          />
                        </div>
                        {/* PAID */}
                        <div className="w-full mb-4 leave-application__input-wrapper">
                          <label htmlFor="isPaid" className="leave-application__label-title">
                            PAID
                          </label>
                          <div className="w-full mt-1 leave-checkbox-content">
                            <Checkbox
                              label={formik.values.isPaid ? 'Paid' : 'Unpaid'}
                              checked={formik.values.isPaid}
                              className="leave-checkbox-field"
                              disabled={(leave && leave.id !== undefined) || leaveCredits === 0}
                              onChange={(e) => formik.setFieldValue('isPaid', e.target.checked)}
                            />
                          </div>
                          <ErrorMessage className="leave-error" name="isPaid" component="div" />
                        </div>
                        {/* attachments */}
                        <UploadFileAttachments
                          id="attachmentsFile"
                          name="attachmentsFile"
                          label="Insert attachments"
                          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                          setFieldValue={formik.setFieldValue}
                          setFieldTouched={formik.setFieldTouched}
                        />
                      </div>
                    </div>

                    {/* second container right-side */}
                    <div className="flex items-center flex-col leave-application__second-container">
                      <div className="h-full w-[80%]">
                        {/* Nature */}
                        <div className="w-full mb-4 leave-application__input-wrapper">
                          <label
                            htmlFor="leaveNature"
                            className="w-full leave-application__label-title"
                          >
                            NATURE
                          </label>
                          <div className="w-full mt-1">
                            <SelectLeaveNature
                              id="leaveNature"
                              name="leaveNature"
                              value={formik.values.leaveNature}
                              setFieldValue={formik.setFieldValue}
                              required
                            />
                          </div>
                          <ErrorMessage name="leaveNature" component="div" />
                        </div>

                        {/* Date-Duration */}
                        <div className="w-full mb-4 leave-application__input-wrapper">
                          <div className="flex justify-between">
                            <div>
                              <label
                                htmlFor="duration"
                                className="w-full leave-application__label-title"
                              >
                                DURATION
                              </label>
                            </div>
                            <div className="flex items-center gap-1 leave-application__duration-days">
                              <p className="w-full rounded-md">
                                {leaveForm ? fromManageSelectedDays : selectedDays}
                              </p>
                              <span>Day(s)</span>
                            </div>
                          </div>
                          {leaveForm === undefined ? (
                            <div className="w-full mt-1">
                              <CustomRangeDatePicker
                                id="duration"
                                name="duration"
                                className="rounded-lg leave-application__field-date"
                                placeholder="Leave Duration"
                                excludedDates={formik.values.excludedDates}
                                setDateStart={setDateStart}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                setDateEnd={setDateEnd}
                                disabled={leave ? !!leave.id : false}
                                setFieldValue={formik.setFieldValue}
                              />
                            </div>
                          ) : (
                            <div className="w-full mt-1">
                              <CustomRangeDatePicker
                                id="duration"
                                name="duration"
                                className="rounded-lg leave-application__field-date"
                                placeholder="Leave Duration"
                                popperPlacement="bottom"
                                popperClassName="m-0"
                                excludedDates={convertedExcludedDates}
                                dateStart={leaveForm && new Date(leaveForm.duration[0])}
                                dateEnd={
                                  leaveForm &&
                                  new Date(leaveForm.duration[leaveForm.duration.length - 1])
                                }
                                readOnly
                              />
                            </div>
                          )}
                          <ErrorMessage className="leave-error" name="duration" component="div" />
                        </div>

                        {/* EXCLUDED */}
                        {leave === undefined && (
                          <div className="w-full mb-4 leave-application__input-wrapper">
                            <div className="flex items-center">
                              <label
                                htmlFor="excluded"
                                className="pr-1 w-fit leave-application__label-title"
                              >
                                EXCLUDED
                              </label>
                              <span className="icon-wrapper">
                                <HelperMessage
                                  content="Only use exclude if necessary (Click Plus icon to exclude)."
                                  placement="right"
                                />
                              </span>
                            </div>
                            <div className="w-full mt-1">
                              <MultipleDatesPicker
                                id="excludedDates"
                                name="excludedDates"
                                className="rounded-lg leave-application__field-date"
                                placeholder="Excluded Dates"
                                selectedDays={formik.values.excludedDates}
                                setFieldValue={formik.setFieldValue}
                              />
                            </div>
                            <ErrorMessage className="leave-error" name="excluded" component="div" />
                          </div>
                        )}
                        {/* REASON */}
                        <div className="w-full mb-4 leave-application__input-wrapper">
                          <label htmlFor="notes" className="w-full leave-application__label-title">
                            REASON
                          </label>
                          <div className="w-full mt-1">
                            <Field
                              className="w-full p-3 rounded-md shadow-md leave-application__field-textarea"
                              as="textarea"
                              id="notes"
                              name="notes"
                              placeholder="Kindly Write The Reason..."
                            />
                          </div>
                          <ErrorMessage className="leave-error" name="notes" component="div" />
                        </div>
                      </div>
                      <div className="flex justify-end h-full w-full pb-4 leave-application__submit-wrapper">
                        <button
                          type="button"
                          onClick={() => {
                            const touchedFields: Record<string, boolean> = {};
                            Object.keys(formik.values).forEach((fieldName) => {
                              touchedFields[fieldName] = true;
                            });
                            formik.setTouched(touchedFields);
                            formik.validateForm().then((errors) => {
                              if (Object.keys(errors).length === 0) setConfirmationModalOpen(true);
                            });
                          }}
                          className={`leave-application__submit-button ${
                            formik.isValid ? '' : 'leave-application--button-disabled'
                          }`}
                        >
                          Submit
                        </button>
                        <LeaveReviewFormSubmit
                          open={reviewFormModal}
                          onClose={closeReviewFormModal}
                          handleSubmit={formik.handleSubmit}
                          formData={formik.values}
                          loading={loading}
                          selectedDays={leave?.id !== undefined ? leave.leaveCount : selectedDays}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/* leave Right Side Bar */}
      </div>
      <LeaveRightSideBar />
    </div>
  );
};

export default LeaveApplicationForm;
