import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import Team from '../../../store/constants/teams/team.interface';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';
import EditSvg from '../../../assets/images/icons/edit.svg';
import ReassignSvg from '../../../assets/images/icons/arrow-around.svg';

export interface ClientTeamListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Team;
  getDateTime: (date: Date) => string;
  onEditClick: (team: Team) => void;
  setSelectedTeam: (team: Team) => void;
  onReassignClick: (team: Team) => void;
  onDeleteClick: (team: Team) => Promise<unknown>;
}

const ClientTeamListRow: React.FC<ClientTeamListRowProps> = ({
  className = '',
  data,
  getDateTime,
  setSelectedTeam,
  onEditClick,
  onReassignClick,
  onDeleteClick,
  ...props
}: ClientTeamListRowProps) => {
  const deleteMessage = {
    pending: 'Pending',
    success: 'Team information is successfully deleted.',
    error: 'Error on deleting Team information.',
  };
  return (
    <div
      {...props}
      className={`${className} client-team-list-data table-list-data flex items-center text-left`}
    >
      <div>{data.name}</div>
      <div>{data.fte}</div>
      <div>{data.teamLeader?.userInformation?.fullName}</div>
      <div>{data.department?.name}</div>
      <a href={`mailto:${data.emailDistro}`}>{data.emailDistro}</a>
      <div className="space-x-6 action-buttons justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <img className="edit-data-icon" src={EditSvg} alt="Edit Client team" />
          </button>
        </Tooltip>
        <Tooltip content="Reassign" className="bg-[#343434]">
          <button
            className="textButton"
            type="button"
            onClick={() => onReassignClick({ ...data, client: undefined })}
          >
            <img className="edit-data-icon" src={ReassignSvg} alt="Delete Client team" />
          </button>
        </Tooltip>
        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};
export default ClientTeamListRow;
