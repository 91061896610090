import ReactDOMServer from 'react-dom/server';

interface PrintDocumentProps {
  documentTitle: string;
  componentToPrint: JSX.Element;
}

const printDocument = ({ documentTitle, componentToPrint }: PrintDocumentProps) => {
  const componentHtml = ReactDOMServer.renderToString(componentToPrint);

  const printWindow = window.open('', '_blank');

  if (printWindow) {
    const printableDocument = `
      <html>
        <head>
          <title>${documentTitle}</title>
          <style type="text/css" media="print">
            * {
              box-sizing: border-box;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
              font-size: 12px;
              margin: 0;
              padding: 0;
            }
            @page {
              size: auto;
              margin: 10mm;
            }
            body {
              background-color: #FFFFFF;
              height: 100%;
              width: 100%;
              padding: 10mm;
            }
            .page-break {
              page-break-before: always;
            }
          </style>
        </head>
        <body>
          ${componentHtml}
        </body>
      </html>
    `;

    printWindow.document.write(printableDocument);
    printWindow.document.close();
    printWindow.print();
  }
};

export default printDocument;
