import React, { useState, useEffect, useCallback } from 'react';
import { VariableSizeList } from 'react-window';
import DateRangePicker from 'react-datepicker';
import TextWithIcons from '../../../molecules/TextWithIcons';
import Hmo from '../../../../store/constants/hmo/hmo.interface';
import consoleLog from '../../../../utils/consoleLog';
import useHmo from '../../../../hooks/hmo/useHmo';
import HmoListRow from './HmoListRow';
import EmptyListMessage from '../../../atoms/EmptyListMessage';
import HmoFormModal from './HmoFormModal';
import useDateTime from '../../../../hooks/utils/useDateTime';
import SearchSvg from '../../../../assets/images/icons/search.svg';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow-with-tail-down.svg';

interface HmoProps {
  isSearchable: boolean;
  filter: boolean;
  searchValue: string | number;
  setSearchValue: (value: string | number) => void;
}

const HmoMemberList = ({
  isSearchable = true,
  filter = true,
  searchValue,
  setSearchValue,
}: Partial<HmoProps>) => {
  type SortByHeaderOptions = '' | 'accountNumber' | 'lastName' | 'effectiveDate' | 'expiryDate';

  const [selectedHmo, setSelectedHmo] = useState<Hmo | undefined>();
  const { hmoState, getAll, remove } = useHmo();
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const { getDateTime, getDateAsMoment } = useDateTime();

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const onEditClick = (hmo: Hmo) => {
    setSelectedHmo(hmo);
  };
  const onDeleteClick = async (hmo: Hmo) => {
    remove(hmo.id).then((r) => {
      consoleLog('DELETE LOAN', r);
    });
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const itemSizes: { [key: number]: number } = {};

  const hmoList = hmoState.hmo
    .map((hmo: Hmo) => hmo)
    .sort((a: Hmo, b: Hmo) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'accountNumber') {
        return sortOrder * a.accountNumber.localeCompare(b.accountNumber);
      }

      if (sortOption === 'lastName') {
        if (!a.userInfo?.lastName) return -sortOrder;
        if (!b.userInfo?.lastName) return sortOrder;

        return sortOrder * a.userInfo.lastName.localeCompare(b.userInfo.lastName);
      }

      if (sortOption === 'effectiveDate') {
        return sortOrder * getDateAsMoment(a.effectiveDate).diff(getDateAsMoment(b.effectiveDate));
      }

      if (sortOption === 'expiryDate') {
        return sortOrder * getDateAsMoment(a.expiryDate).diff(getDateAsMoment(b.expiryDate));
      }
      return sortOrder * (a.id - b.id);
    });

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  const onTeamModalClose = () => setSelectedHmo(undefined);

  // const handleChange = (date: Date | null) => {
  //   const start: Moment = moment(date).startOf('month');
  //   // const end: Moment = moment(date).endOf('month');

  //   const startDate: Date = start.toDate();
  //   // const endDate: Date = end.toDate();

  //   setDateStart(startDate);
  // };

  const getAllHmo = useCallback(
    async (value?: string | number) => {
      await getAll(value).then();
    },
    [getAll],
  );

  // useOnLoad(() => {
  //   getAll().then();
  // });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchValue) {
      setSearchValue(e.target.value);
    }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      getAllHmo(searchValue);
    }, 900);

    return () => clearTimeout(debounce);
  }, [searchValue]); // eslint-disable-line

  return (
    <div className="w-full h-full z-40 relative">
      <div className="w-full max-w-[100%]">
        <HmoFormModal hmo={selectedHmo} onClose={onTeamModalClose} />
        <div className="w-full flex pb-5">
          <div className="flex gap-4">
            {filter && (
              <div className="w-1/2 flex items-center gap-2">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
                    <path
                      fill="#f05825"
                      d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"
                    />
                  </svg>
                </div>
                <div className="w-full hmo-date-wrapper">
                  <DateRangePicker
                    className="rounded-lg datepicker-hmo-member"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    isClearable
                    popperPlacement="bottom"
                    popperClassName="m-0"
                    placeholderText="Select Date Range..."
                  />
                </div>
              </div>
            )}
            {isSearchable && (
              <div className="relative">
                <input
                  type="text"
                  className="search-body__input search-bar"
                  placeholder="Search Hmo"
                  onChange={handleSearch}
                />
                <img
                  style={{ filter: 'invert(0) invert(1)' }}
                  className="search-body__icon"
                  src={SearchSvg}
                  alt="Search for Team"
                />
              </div>
            )}
          </div>
        </div>
        <div className="p-2 border-b-2 hmo-list-header table-header">
          <button
            type="button"
            onClick={() => sortByHeader('accountNumber', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'accountNumber' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'accountNumber' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div>Account No.</div>
            </TextWithIcons>
          </button>

          <button
            type="button"
            onClick={() => sortByHeader('lastName', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'lastName' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'lastName' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div>Name</div>
            </TextWithIcons>
          </button>

          <button
            type="button"
            onClick={() => sortByHeader('effectiveDate', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'effectiveDate' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'effectiveDate' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div>Date started</div>
            </TextWithIcons>
          </button>

          <button
            type="button"
            onClick={() => sortByHeader('expiryDate', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'expiryDate' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'expiryDate' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div>Date ended</div>
            </TextWithIcons>
          </button>

          <TextWithIcons className="flex justify-self-center" variant="h6">
            <div>Action</div>
          </TextWithIcons>
        </div>
        {hmoList.length ? (
          <VariableSizeList
            itemCount={hmoList.length}
            itemSize={getItemSize}
            height={window.innerHeight - 400}
            width="100%"
            className="no-scrollbars"
          >
            {({ index, style }) =>
              hmoList[index] && (
                <HmoListRow
                  data={hmoList[index]}
                  className={`${index % 2 === 0 ? 'stripped' : ''}`}
                  style={style}
                  getDateTime={getDateTime}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                />
              )
            }
          </VariableSizeList>
        ) : (
          <EmptyListMessage />
        )}
      </div>
    </div>
  );
};

export default HmoMemberList;
