import React from 'react';
import { Typography } from '@material-tailwind/react';

export interface SummaryItems {
  name: string;
  value: string;
}

export interface SummaryCardContentProps {
  className?: string;
  heading: string;
  items: SummaryItems[];
  optionalComponent?: React.ReactNode;
}

const SummaryCard: React.FC<SummaryCardContentProps> = ({
  className = '',
  heading,
  items,
  optionalComponent = '',
}) => (
  <div
    className={`p-5 w-96 border border-[#2e2d2c] bg-[#252423] border-solid rounded-xl shadow-2xl ${className}`}
  >
    <Typography variant="h5" className="text-[#f05825] flex gap-2 mb-5">
      <span className="text-gray-200">{heading}</span>
      Information
    </Typography>
    <div className="grid grid-cols-2 gap-x-16 gap-y-5">
      {optionalComponent && ( // Render only if optionalComponent exists
        <div className="optional-component">{optionalComponent}</div>
      )}
      {items.map((item) => (
        <div key={item.name} className="flex flex-col gap-2">
          <span className="text-xs uppercase text-gray-600">{item.name}</span>
          <span className="text-sm text-white break-words">{item.value || '_ _'}</span>
        </div>
      ))}
    </div>
  </div>
);

export default SummaryCard;
