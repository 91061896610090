import { useAppDispatch, useAppSelector } from '../../store/store';
import userEmploymentPositionsActions from '../../store/actions/users/employments/userEmploymentPositionsActions';
import {
  userPositionsReducerActions,
  UserPositionsState,
} from '../../store/reducers/positions/userPositionsReducer';
import UserEmploymentPosition from '../../store/constants/employees/positions/employee-position.interface';

const useUserPositionsAssignment = () => {
  const dispatch = useAppDispatch();
  const userPositionsState: UserPositionsState = useAppSelector((state) => state.userPositions);

  const resetUserPositionsState = () => {
    dispatch(userPositionsReducerActions.resetUserPositionsState());
  };

  const setUserPositionsState = (state: Partial<UserPositionsState>) => {
    dispatch(userPositionsReducerActions.setUserPositionsState(state));
  };

  const get = async (id: UserEmploymentPosition['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.getAll({}));

  const getAllUserPositions = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.getAllUsersByEmploymentPosition({}));

  const getUsers = async (id: UserEmploymentPosition['employeeNumber'] | undefined) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.getUsers(id));

  const post = async (data: Omit<UserEmploymentPosition, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.post(data));

  const put = async (data: Partial<UserEmploymentPosition>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.put(data));

  const remove = async (id: UserEmploymentPosition['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.remove(id));

  const getUserPosition = (employeeNumber: UserEmploymentPosition['employeeNumber'] | undefined) =>
    userPositionsState.userPositions.find(
      (userPosition) => userPosition.employeeNumber === employeeNumber,
    );

  const getUserPositions = (employeeNumber: UserEmploymentPosition['employeeNumber'] | undefined) =>
    userPositionsState.userPositions.filter(
      (userPosition) => userPosition.employeeNumber === employeeNumber,
    );

  const getAllUserEmploymentsPosition = (id: UserEmploymentPosition['employmentPositionId']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.getAllUsersByEmploymentPosition(id));

  const updateEmployeePosition = async (data: Partial<UserEmploymentPosition>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.updateEmployeePosition(data));

  return {
    resetUserPositionsState,
    userPositionsState,
    updateEmployeePosition,
    setUserPositionsState,
    get,
    getAll,
    getAllUserPositions,
    getAllUserEmploymentsPosition,
    getUsers,
    getUserPosition,
    getUserPositions,
    post,
    put,
    remove,
  };
};

export default useUserPositionsAssignment;
