import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useUserPositionsAssignment from '../../../../hooks/users/useUserPositionsAssignment';
import SelectEmploymentPosition from '../../../atoms/dropdowns/SelectEmploymentPosition';
import UserEmployeePosition from '../../../../store/constants/employees/positions/employee-position.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import EmploymentPosition from '../../../../store/constants/employments/positions/employment-position.interface';

export interface PositionEmployeeDateEndModalParams {
  userEmployeePosition?: UserEmployeePosition;
  className?: string;
  dataName?: string;
  onClose: () => void;
}

const PositionEmployeeDateEndModal: React.FC<PositionEmployeeDateEndModalParams> = ({
  userEmployeePosition = undefined,
  className = undefined,
  dataName = undefined,
  onClose,
}) => {
  const [userEmployeePositionForm, setUserEmployeePositionForm] = useState<
    UserEmployeePosition | undefined
  >();

  const [open, setOpen] = React.useState(false);

  const { updateEmployeePosition } = useUserPositionsAssignment();

  const handleClose = () => {
    setUserEmployeePositionForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setUserEmployeePositionForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<UserEmployeePosition>) => {
    const data = {
      ...userEmployeePositionForm,
      ...values,
    };
    await toastNotification({
      action: updateEmployeePosition(data),
      onSuccess: () => {},
      onError: () => {},
      onFulfilled: () => {
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Position information is successfully updated.',
        error: 'Error on updating Position information.',
      },
    });

    setOpen(false);
  };

  useEffect(() => {
    if (userEmployeePosition) {
      setOpen(true);
      setUserEmployeePositionForm(userEmployeePosition);
    }
  }, [userEmployeePosition]);

  return (
    <>
      <button type="button" className={className} onClick={handleOpen}>
        {dataName}
      </button>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="w-5 h-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="white"
            className="modal-icons"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
            />
          </svg>

          <DialogHeader className="p-0 mt-1 text-gray-200 modal-header">
            Enter Position Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="formik-container">
            <Formik
              initialValues={{
                ...userEmployeePositionForm,
                employmentPosition: userEmployeePositionForm?.employmentPosition
                  ? {
                      ...userEmployeePositionForm?.employmentPosition,
                      label: userEmployeePositionForm?.employmentPosition?.name,
                      value: userEmployeePositionForm?.employmentPosition,
                    }
                  : undefined,
              }}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form className="form-positions">
                  <div className="mt-5 mb-4 form-section">
                    <div className="flex flex-col justify-center w-full">
                      <div className="mb-4">
                        <label
                          htmlFor="employmentPosition"
                          className="block mb-2 text-gray-200 modal-labels"
                        >
                          Employee Position
                        </label>
                        <SelectEmploymentPosition
                          id="employmentPosition"
                          name="employmentPosition"
                          // when cleared, set to initial value
                          isClearable
                          onMenuClose={() => {
                            if (!formik.values.employmentPosition) {
                              formik.setFieldValue('employmentPosition', {
                                ...userEmployeePositionForm?.employmentPosition,
                                label: userEmployeePositionForm?.employmentPosition?.name,
                                value: userEmployeePositionForm?.employmentPosition,
                              });
                            }
                          }}
                          allowWithRates
                          excludedData={
                            [userEmployeePositionForm?.employmentPosition] as EmploymentPosition[]
                          }
                          value={formik.values.employmentPosition}
                          setFieldValue={formik.setFieldValue}
                        />
                        <ErrorMessage name="employmentPosition" component="div" />
                      </div>

                      <div className="grid grid-cols-2 gap-5">
                        <div className="mb-4">
                          <label htmlFor="tier" className="block mb-2 text-gray-200 modal-labels">
                            Tier
                          </label>
                          <Field
                            type="number"
                            id="tier"
                            name="tier"
                            placeholder="Enter Tier"
                            className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                          />
                          <ErrorMessage name="tier" component="div" />
                        </div>

                        <div className="mb-4">
                          <label htmlFor="rate" className="block mb-2 text-gray-200 modal-labels">
                            Rate
                          </label>
                          <Field
                            type="number"
                            id="rate"
                            name="rate"
                            placeholder="Enter Rate"
                            className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                          />
                          <ErrorMessage name="rate" component="div" />
                        </div>
                      </div>
                      {userEmployeePositionForm?.employmentPosition.name ===
                      formik.values.employmentPosition?.name ? (
                        <div className="mb-4">
                          <label
                            htmlFor="dateEnd"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Date end
                          </label>
                          <div className="">
                            <Field
                              type="date"
                              id="dateEnd"
                              name="dateEnd"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              required
                            />
                          </div>
                          <ErrorMessage name="dateEnd" component="div" />
                        </div>
                      ) : (
                        <div className="mb-4">
                          <label
                            htmlFor="dateStart"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Date start
                          </label>
                          <div className="">
                            <Field
                              type="date"
                              id="dateStart"
                              name="dateStart"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              required
                            />
                          </div>
                          <ErrorMessage name="dateStart" component="div" />
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Buttons */}
                  <div className="flex space-x-2 w-max">
                    <div className="flex justify-center w-full">
                      <button
                        type="submit"
                        className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="flex justify-center w-max">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default PositionEmployeeDateEndModal;
