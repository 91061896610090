import React from 'react';
import { Typography } from '@material-tailwind/react';
import InactiveEmployeesTable from './tables/InactiveEmployeesTable';

const InactiveEmployeesPage: React.FC = () => (
  <div className="w-full list-of-reports mt-5">
    <div className="title">
      <Typography variant="h3" className="text-[#f05825] ml-0 mt-2 mb-5">
        Inactive Employee Report
      </Typography>
    </div>
    <div className="flex flex-col gap-10">
      <div className="w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg">
        <div className="p-3 w-full bg-[#f05825] rounded-t-lg">
          <span className="text-gray-200 font-bold">Filters</span>
        </div>
        <div className="p-5 flex gap-5">
          <input
            type="text"
            placeholder="Date Started"
            className="bg-[#343434] w-40 rounded-md border border-solid border-[#434343] px-2"
          />
          <input
            type="text"
            className="bg-[#343434] w-40 rounded-md border border-solid border-[#434343] px-2"
          />
        </div>
      </div>
      <div className="w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg">
        <div className="p-3 w-full bg-[#f05825] rounded-t-lg">
          <span className="text-gray-200 font-bold">Inactive Employee Report</span>
        </div>
        <div className="p-5">
          <div className="flex">
            <div className="flex flex-col gap-5">
              <div className="flex gap-2 items-center">
                <label htmlFor="entries" className="text-gray-200 text-xs">
                  Show
                </label>
                <input
                  type="text"
                  className="bg-[#343434] w-14 rounded-md border border-solid border-[#434343] px-2"
                />
                <label htmlFor="entries" className="text-gray-200 text-xs">
                  Entries
                </label>
              </div>
              <div className="flex gap-2 items-center">
                <label htmlFor="search" className="text-gray-200 text-xs">
                  Search:
                </label>
                <input
                  type="text"
                  className="bg-[#343434] w-40 rounded-md border border-solid border-[#434343] px-2"
                />
              </div>
            </div>
            <div className="ml-auto">
              <button
                type="button"
                className="p-2 py-1 bg-[#f05825] text-white text-sm rounded-md shadow-md"
              >
                Generate Excel File
              </button>
            </div>
          </div>
          <div className="border border-solid border-[#343434] mt-5 shadow-lg">
            <InactiveEmployeesTable />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default InactiveEmployeesPage;
