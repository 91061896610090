import React from 'react';
import { Typography } from '@material-tailwind/react';

const ClientDailyAttendanceReportPage: React.FC = () => (
  <div className="w-full list-of-reports mt-5">
    <div className="title p-5 w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg">
      <Typography variant="h3" className="text-[#f05825] ml-0 mt-2 mb-5">
        Client Daily Attendance Report
      </Typography>

      <div className="flex gap-5">
        <div className="flex flex-col gap-2 mb-5">
          <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
            Select Date Range
          </label>
          <input
            type="date"
            className="bg-[#343434] w-56 rounded-md border border-solid border-[#434343] p-2 text-gray-200"
          />
        </div>
        <div className="flex flex-col gap-2 mb-5">
          <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
            Export Attendance
          </label>
          <select className="bg-[#343434] w-60 rounded-md border border-solid border-[#434343] p-2">
            <option value="">Offsourcing - Internal - Tech Teams</option>
          </select>
        </div>
        <div className="flex flex-col gap-2 mb-5">
          <label htmlFor="exportAttendance" className="text-gray-200 text-sm font-semibold">
            Export Attendance
          </label>
          <button
            type="button"
            className="p-2 bg-[#f05825] text-white text-sm rounded-md shadow-md w-56"
          >
            Export
          </button>
        </div>
        <div className="flex flex-col gap-2 mb-5">
          <label htmlFor="exportAttendance" className="text-gray-200 text-sm font-semibold">
            Click Download once DONE
          </label>
          <button
            type="button"
            className="p-2 bg-[#f0582559] text-[#ffffff4d] text-sm rounded-md shadow-md w-56 cursor-not-allowed"
            disabled
          >
            Click Export
          </button>
        </div>
      </div>
      <Typography variant="h2" className="text-[#f05825] ml-0 mt-2 mb-5">
        This export is for internal use only, please dont send this to clients. THANK YOU!
      </Typography>
    </div>
  </div>
);

export default ClientDailyAttendanceReportPage;
