import apiFetch, { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiGetAllBaseQueryParams from '../../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import UserEmploymentSchedules from '../../../constants/employees/schedules/employee-schedule.interface';

export interface GetAllSchedulesQueryParams extends ApiGetAllBaseQueryParams {
  employeeNumber?: UserEmploymentSchedules['employeeNumber'];
}

const get = async (
  id: UserEmploymentSchedules['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> =>
  apiFetch<ApiBaseResponse<UserEmploymentSchedules>>(`employees/schedules/${id}`, { state })
    .then((response: ApiBaseResponse<UserEmploymentSchedules>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '', employeeNumber = undefined }: GetAllSchedulesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<UserEmploymentSchedules> | unknown> =>
  apiFetch<ApiGetAllBaseResponse<UserEmploymentSchedules>>(
    `employees/schedules?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}${
      employeeNumber ? `&employeeNumber=${employeeNumber}` : ''
    }`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<UserEmploymentSchedules>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<UserEmploymentSchedules, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> =>
  apiFetch<ApiBaseResponse<UserEmploymentSchedules>>('employees/schedules', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentSchedules>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: UserEmploymentSchedules,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> =>
  apiFetch<ApiBaseResponse<UserEmploymentSchedules>>(`employees/schedules/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentSchedules>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<UserEmploymentSchedules, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> =>
  apiFetch<ApiBaseResponse<UserEmploymentSchedules>>(`employees/schedules/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentSchedules>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: UserEmploymentSchedules['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> =>
  apiFetch<ApiBaseResponse<UserEmploymentSchedules>>(`employees/schedules/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentSchedules>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getCurrentSchedule = async (
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<UserEmploymentSchedules> | unknown> =>
  apiFetch<ApiGetAllBaseResponse<UserEmploymentSchedules>>('employees/schedules/current-schedule', {
    state,
  })
    .then((response: ApiGetAllBaseResponse<UserEmploymentSchedules>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  getCurrentSchedule,
} as const;
