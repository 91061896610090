import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import EmployeeOvertime from '../../constants/employees/attendances/employee-overtime.interface';
import EmployeeOvertimeType from '../../constants/employees/attendances/employee-overtime-type.interface';
import overtimeActions from '../../actions/attendances/overtimeActions';

export interface OvertimeState {
  overtimes: EmployeeOvertime[];
  overtimeType: EmployeeOvertimeType[];
}

const initialState: OvertimeState = {
  overtimes: [],
  overtimeType: [
    {
      id: 1,
      overtimeName: 'Regular Overtime',
      rate: 1.25,
      excessRate: 1.25,
      description: 'Regular Overtime',
      key: 'OT',
    },
    {
      id: 2,
      overtimeName: 'Regular Rest Day Overtime',
      rate: 1.3,
      excessRate: 1.69,
      description: 'Regular Rest Day Overtime',
      key: 'RDOT',
    },
    {
      id: 3,
      overtimeName: 'Holiday Overtime',
      rate: 2,
      excessRate: 2.6,
      description: 'Regular Holiday Overtime',
      key: 'HOT',
    },
    {
      id: 4,
      overtimeName: 'Special Holiday Overtime',
      rate: 1.3,
      excessRate: 1.69,
      description: 'Special Holiday Overtime',
      key: 'SOT',
    },
    {
      id: 5,
      overtimeName: 'Holiday Rest Day Overtime',
      rate: 2.6,
      excessRate: 3.38,
      description: 'Regular Holiday, Rest Day Overtime',
      key: 'HRDOT',
    },
    {
      id: 6,
      overtimeName: 'Special Holiday Rest Day Overtime',
      rate: 1.5,
      excessRate: 1.95,
      description: 'Special Holiday, Rest Day Overtime',
      key: 'SRDOT',
    },
    {
      id: 7,
      overtimeName: 'Reg Hol Spcl Hol OT',
      rate: 2.6,
      excessRate: 2.6,
      description: 'Regular Holiday, Special Holiday, Overtime',
      key: 'HSOT',
    },
    {
      id: 8,
      overtimeName: 'Reg Hol Spcl Hol RDOT',
      rate: 3,
      excessRate: 3,
      description: 'Regular Holiday, Special Holiday, Rest Day Overtime',
      key: 'HSRDOT',
    },
    {
      id: 9,
      overtimeName: 'Two Holiday Overtime',
      rate: 3.9,
      excessRate: 3.9,
      description: 'Double Regular Holiday Overtime',
      key: 'HHOT',
    },
    {
      id: 10,
      overtimeName: 'Two Holiday RDOT',
      rate: 3.9,
      excessRate: 5.07,
      description: 'Double Regular Holiday Rest Day Overtime',
      key: 'HHRDOT',
    },
  ],
};

const overtimeSlice = createSlice({
  name: 'overtimes',
  initialState,
  reducers: {
    resetOvertimeState: () => ({
      ...initialState,
    }),
    setOvertimeState: (state, action: PayloadAction<Partial<OvertimeState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertOvertime: (state, { payload }) => {
      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status === StatusCodes.OK) return;

      state.overtimes = upsertData<EmployeeOvertime>(state.overtimes, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(overtimeActions.post.fulfilled, (state, { payload }) => {
      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;
      consoleLog('OVERTIMES REDUCER', 'POST', data);

      if (status !== StatusCodes.CREATED) return;

      if (data) {
        state.overtimes = upsertData<EmployeeOvertime>(state.overtimes, data);
      }
    });

    builder.addCase(overtimeActions.get.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'GET', payload);

      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.OK) return;

      state.overtimes = upsertData<EmployeeOvertime>(state.overtimes, data);
    });

    builder.addCase(overtimeActions.put.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.OK) return;

      state.overtimes = upsertData<EmployeeOvertime>(state.overtimes, data);
    });

    builder.addCase(overtimeActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'REMOVE', payload);

      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.NO_CONTENT) return;

      state.overtimes = state.overtimes.filter((overtime) => overtime.id !== data.id);
    });

    builder.addCase(overtimeActions.getAllOvertimes.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.OK) return;

      if (data) {
        state.overtimes = data;
      }
    });

    builder.addCase(overtimeActions.approveEmployeeOvertime.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'APPROVE', payload);

      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.OK) return;

      state.overtimes = upsertData<EmployeeOvertime>(state.overtimes, data);
    });
  },
});

export const overtimesReducerActions = overtimeSlice.actions;
export default overtimeSlice.reducer;
