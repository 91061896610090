import React from 'react';
import LeaveList from './LeaveList';
import ManagementHeading from '../../../atoms/ManagementHeading';

const LeaveListManagement: React.FC = () => (
  <div className="w-full flex flex-col">
    <div className="m-2 title">
      <ManagementHeading title="Leave" subtitle="Applications" />
    </div>
    <div className="organisms-leave-management w-full">
      <LeaveList />
    </div>
  </div>
);

export default LeaveListManagement;
