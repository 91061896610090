import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
// import { ReactComponent as Person } from '../../../../assets/images/icons/person.svg';
// import { ReactComponent as Image } from '../../../../assets/images/icons/image.svg';
// import { ReactComponent as Film } from '../../../../assets/images/icons/film.svg';
// import { ReactComponent as Poll } from '../../../../assets/images/icons/poll.svg';

const UserPostStatus: React.FC = () => {
  const data = [
    {
      label: 'Status',
      value: 'Status',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path
            fillRule="evenodd"
            d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
            clipRule="evenodd"
          />
        </svg>
      ),
      desc: (
        <Field
          as="textarea"
          id="Status"
          name="Status"
          placeholder="What's on your mind?"
          className="p-2 w-full h-32 bg-[#2c2b2a] rounded-md user-post-status__input"
        />
      ),
    },
    {
      label: 'Add Picture',
      value: 'Add Picture',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path
            fillRule="evenodd"
            d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
            clipRule="evenodd"
          />
        </svg>
      ),
      desc: (
        <Field
          as="textarea"
          id="Status"
          name="Status"
          placeholder="What's on your mind?"
          className="p-2 w-full bg-[#1C1C1E] bg-[#2c2b2a]-old rounded-md user-post-status__input"
        />
      ),
    },
    {
      label: 'Add Video',
      value: 'Add Video',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path
            fillRule="evenodd"
            d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 18.375V5.625Zm1.5 0v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-1.5A.375.375 0 0 0 3 5.625Zm16.125-.375a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5A.375.375 0 0 0 21 7.125v-1.5a.375.375 0 0 0-.375-.375h-1.5ZM21 9.375A.375.375 0 0 0 20.625 9h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5ZM4.875 18.75a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5ZM3.375 15h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375Zm0-3.75h1.5a.375.375 0 0 0 .375-.375v-1.5A.375.375 0 0 0 4.875 9h-1.5A.375.375 0 0 0 3 9.375v1.5c0 .207.168.375.375.375Zm4.125 0a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9Z"
            clipRule="evenodd"
          />
        </svg>
      ),
      desc: (
        <Field
          as="textarea"
          id="Status"
          name="Status"
          placeholder="What's on your mind?"
          className="p-2 w-full bg-[#2c2b2a] rounded-md user-post-status__input"
        />
      ),
    },
    {
      label: 'Add Poll',
      value: 'Add Poll',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z" />
        </svg>
      ),
      desc: (
        <Field
          as="textarea"
          id="Status"
          name="Status"
          placeholder="What's on your mind?"
          className="p-2 w-full bg-[#2c2b2a] rounded-md user-post-status__input"
        />
      ),
    },
  ];
  return (
    <div className="bg-[#242426] p-1 rounded-lg shadow-lg user-posts-parent-container-oldstyle">
      <Tabs value="Status">
        {/* Set the value prop to 'Status' */}
        <div className="ml-5 mt-2">
          <TabsHeader
            className="bg-[#1A1A1B]-old w-[65%] shadow-lg bg-[#343434]"
            indicatorProps={{
              className: 'bg-[#2C2C2E]-old shadow-none bg-[#f05825]/50',
            }}
          >
            {data.map(({ label, value, icon }) => (
              <Tab key={value} value={value} className="w-[30%] z-10">
                <div className="flex items-center gap-2">
                  <span className="text-gray-200">{icon}</span>
                  <span className="text-gray-200 text-xs">{label}</span>
                </div>
              </Tab>
            ))}
          </TabsHeader>
        </div>
        <TabsBody>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              <Formik initialValues={{ Status: '' }} onSubmit={() => {}}>
                <Form>
                  {desc}
                  <div className="flex mt-2">
                    {value === 'Add Picture' && (
                      <button
                        type="button"
                        className="p-2 py-1 bg-[#f05825] text-white text-sm rounded-md shadow-md"
                      >
                        Upload Photo
                      </button>
                    )}
                    {value === 'Add Video' && (
                      <button
                        type="button"
                        className="p-2 py-1 bg-[#f05825] text-white text-sm rounded-md shadow-md"
                      >
                        Upload Video
                      </button>
                    )}
                    <button
                      type="submit"
                      className="ml-auto p-2 py-1 bg-[#f05825] text-white text-sm rounded-md shadow-md"
                    >
                      Post
                    </button>
                  </div>
                </Form>
              </Formik>
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default UserPostStatus;
