import React from 'react';
import { Typography } from '@material-tailwind/react';

const AttendanceExportPage: React.FC = () => (
  <div className="w-full list-of-reports mt-5">
    <div className="title">
      <Typography variant="h3" className="text-[#f05825] ml-0 mt-2 mb-5">
        Attendance export
      </Typography>
    </div>
    <div className="flex flex-col gap-10">
      <div className="w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg p-5">
        <div className="title">
          <Typography variant="h4" className="text-gray-200 ml-0 mt-2 mb-5">
            Monthly Attendance Report
          </Typography>
        </div>
        <div className="flex gap-5">
          <div className="flex flex-col gap-2 mb-5">
            <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
              Month and Year
            </label>
            <input
              type="date"
              className="bg-[#343434] w-56 p-1 rounded-md border border-solid border-[#434343] px-2 text-gray-200"
            />
          </div>
          <div className="flex flex-col gap-2 mb-5">
            <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
              Export Client Employees
            </label>
            <button
              type="button"
              className="p-2 bg-[#f05825] text-white text-sm rounded-md shadow-md w-56"
            >
              Export
            </button>
          </div>
        </div>
      </div>
      <div className="w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg p-5">
        <div className="title">
          <Typography variant="h4" className="text-gray-200 ml-0 mt-2 mb-5">
            Monthly Reliability Report
          </Typography>
        </div>
        <div className="flex gap-5">
          <div className="flex flex-col gap-2 mb-5">
            <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
              Month and Year
            </label>
            <input
              type="date"
              className="bg-[#343434] w-56 p-1 rounded-md border border-solid border-[#434343] px-2 text-gray-200"
            />
          </div>
          <div className="flex flex-col gap-2 mb-5">
            <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
              Export Reliability Report
            </label>
            <button
              type="button"
              className="p-2 bg-[#f05825] text-white text-sm rounded-md shadow-md w-56"
            >
              Export
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AttendanceExportPage;
