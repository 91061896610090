import React from 'react';
import { Typography } from '@material-tailwind/react';

interface ManagementHeadingProps {
  title: string;
  subtitle?: string;
  className?: string;
}

const ManagementHeading: React.FC<ManagementHeadingProps> = ({
  title,
  subtitle = 'Management',
  className = '',
}) => (
  <div className={`management-heading ${className}`}>
    <Typography variant="h3" className="management-heading__typography">
      {title}
    </Typography>
    <Typography variant="h3" className="management-heading__secondary">
      {subtitle}
    </Typography>
  </div>
);

export default ManagementHeading;
