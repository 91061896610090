import { useAppDispatch, useAppSelector, RootState } from '../../store/store';
import { toggleItem as toggleItemAction } from '../../store/reducers/utils/sidebarReducer';

type UseAccordionMenu = {
  openedItems: number[];
  toggleItem: (item: number) => void;
};

const useAccordionMenu = (): UseAccordionMenu => {
  const dispatch = useAppDispatch();
  const { openedItems } = useAppSelector((state: RootState) => state.sidebarMenu);

  const toggleItem = (item: number) => {
    const index = openedItems.indexOf(item);
    if (index !== -1) {
      // If the item is already in openedItems, dispatch an action to remove it.
      dispatch(toggleItemAction(item));
    } else {
      // If the item is not in openedItems, dispatch actions to close all opened items
      openedItems.forEach((openedItem) => {
        dispatch(toggleItemAction(openedItem));
      });
      dispatch(toggleItemAction(item));
    }
  };

  return {
    openedItems,
    toggleItem,
  };
};

export default useAccordionMenu;
