import React from 'react';
import moment from 'moment';
import UserOvertime from './userOvertime/UserOverTime';
import UserTimeOut from './userTimeOut/UserTimeOut';
import OvertimeDurationTimer from './userOvertime/OvertimeDurationTimer';
import useAttendance from '../../../../hooks/attendances/useAttendance';
import useOvertime from '../../../../hooks/attendances/useOvertime';
import UserNextTimeIn from './userTimeIn/UserNextTimeIn';
import UserOnLeave from './userActiveLeave/UserOnLeave';
import UserTimeIn from './userTimeIn/UserTimeIn';
import useOnLoad from '../../../../hooks/utils/useOnLoad';

const UserAttendanceAction = () => {
  const { attendanceState } = useAttendance();
  const { overtimeDuration, checkActiveOvertime } = useOvertime();

  useOnLoad(() => {
    checkActiveOvertime();
  });

  const ThresholdTimer = 3 * 60 + 20;
  const shouldShowTimeIn =
    attendanceState.expectedTimes?.timeIn?.date &&
    moment(attendanceState.expectedTimes.timeIn.date).diff(moment(), 'minutes') <= ThresholdTimer;

  if (overtimeDuration.hours > 0 || overtimeDuration.minutes > 0) {
    return <OvertimeDurationTimer overtimeDetail={attendanceState.myOverTimeDetail} />;
  }

  if (attendanceState.modals?.showActiveLeaveModal) {
    return <UserOnLeave />;
  }

  if (shouldShowTimeIn) {
    return <UserTimeIn />;
  }

  if (attendanceState.expectedTimes?.timeIn) {
    return <UserNextTimeIn expectedTimeIn={attendanceState.expectedTimes.timeIn} />;
  }

  if (attendanceState.expectedTimes?.timeOut) {
    return <UserTimeOut expectedTimeOut={attendanceState.expectedTimes.timeOut} />;
  }

  return <UserOvertime />;
};

export default UserAttendanceAction;
