type Loggable = string | number | boolean | object | unknown | unknown[];

const displayLog = <T extends Loggable>(arg: T): void => {
  if (typeof arg === 'object' && arg !== null) {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(arg, null, 2));
  } else {
    // eslint-disable-next-line no-console
    console.log(arg);
  }
};

/**
 * This will handle all the console logs for development purposes.
 * We can switch the logs in .env file
 *
 * @param type
 * @param args
 */
const consoleLog = (type: string, ...args: Loggable[]): void => {
  const { REACT_APP_CONSOLE_LOG } = process.env;

  if (
    REACT_APP_CONSOLE_LOG &&
    (REACT_APP_CONSOLE_LOG === '*' ||
      REACT_APP_CONSOLE_LOG === type ||
      REACT_APP_CONSOLE_LOG.split(',').includes(type))
  ) {
    // eslint-disable-next-line no-console
    console.log(`--- ${type} ---`);
    if (args.length === 1) {
      displayLog(args[0]);
    } else {
      displayLog(args);
    }
  }
};

export default consoleLog;
