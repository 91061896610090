import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import Holiday from '../../../store/constants/holidays/holiday.interface';
import useHolidays from '../../../hooks/holidays/useHolidays';
import useHolidayTypes from '../../../hooks/holidays/useHolidayTypes';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useCompanies from '../../../hooks/companies/useCompanies';
import useCompanyBranches from '../../../hooks/companies/branches/useCompanyBranches';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import toastNotification from '../../../hooks/utils/toastNotification';

export interface HolidayFormValues
  extends Omit<Holiday, 'holidayType' | 'company' | 'companyBranch'> {
  holidayTypeId?: number | undefined;
  companyId?: number | undefined;
  companyBranchId?: number | undefined;
}

export interface HolidayFormProps {
  holiday?: Holiday;
  className?: string;
  resetSort?: () => void;
  onClose: () => void;
}

const UserHoliday: React.FC<HolidayFormProps> = ({
  holiday = undefined,
  className = undefined,
  resetSort = () => {},
  onClose,
}) => {
  const [holidayForm, setHolidayForm] = useState<HolidayFormValues | undefined>();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { post, put } = useHolidays();
  const { getAll: getAllCompanies, companiesState } = useCompanies();
  const { getAll: getAllCompanyBranches, companyBranchesState } = useCompanyBranches();
  const { getAll: getAllHolidayTypes, holidayTypesState } = useHolidayTypes();

  const handleClose = () => {
    setHolidayForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setHolidayForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<HolidayFormValues>) => {
    setLoading(true);
    if (holidayForm) {
      await toastNotification({
        action: put({
          ...holidayForm,
          ...values,
        } as Partial<Holiday>),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: async () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Holiday information is successfully updated.',
          error: 'Error on updating Holiday information.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as Holiday),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: async () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Holiday information is successfully added.',
          error: 'Error on adding Holiday information.',
        },
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (holiday) {
      setOpen(true);
      setHolidayForm({
        ...holiday,

        ...(holiday.holidayType && {
          holidayType: undefined,
          holidayTypeId: holiday.holidayType.id,
        }),

        ...(holiday.company && {
          company: undefined,
          companyId: holiday.company.id,
        }),
        ...(holiday.companyBranch && {
          companyBranch: undefined,
          companyBranchId: holiday.companyBranch.id,
        }),
      });
    }
  }, [holiday]);

  useOnLoad(() => {
    getAllHolidayTypes().then();
    getAllCompanies().then();
    getAllCompanyBranches().then();
  });

  // @TODO
  // 1. web console error changing uncontrolled input to controlled. undefined to defined value
  return (
    <>
      <button
        type="button"
        className={` ${className} button button--state-default gap-2`}
        onClick={handleOpen}
      >
        <Add className="w-5 h-5" />
        Add holiday
      </button>
      <Dialog
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 sm-modal-size"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="w-5 h-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="white"
            className="modal-icons"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
            />
          </svg>

          <DialogHeader className="p-0 mt-1 text-gray-200 modal-header">
            Enter Holiday Details
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{
                  ...holidayForm,
                  name: holidayForm?.name ?? '',
                }}
                onSubmit={handleSubmit}
              >
                <Form className="">
                  <div className="mt-4 mb-2 form-section">
                    <div className="flex justify-between gap-5">
                      <div className="first-section">
                        <div className="mb-6">
                          <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                            Holiday
                          </label>
                          <div className="">
                            <Field
                              type="text"
                              id="name"
                              name="name"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              placeholder="Name of the holiday"
                              required
                            />
                          </div>
                          <ErrorMessage name="name" component="div" />
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="holidayTypeId"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Holiday Type
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              id="holidayTypeId"
                              name="holidayTypeId"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              placeholder=""
                              required
                            >
                              {holidayTypesState.holidayTypes.map((holidayType) => (
                                <option key={holidayType.id} value={holidayType.id}>
                                  {holidayType.name} - {holidayType.ratePercentage}%
                                </option>
                              ))}
                            </Field>
                          </div>
                          <ErrorMessage name="holidayType" component="div" />
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="companyId"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Company
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              id="companyId"
                              name="companyId"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            >
                              <option>Choose If Specific Company Holiday</option>
                              {companiesState.companies.map((company) => (
                                <option key={company.id} value={company.id}>
                                  {company.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <ErrorMessage name="companyId" component="div" />
                        </div>
                      </div>

                      <div className="second-section">
                        <div className="mb-6">
                          <label
                            htmlFor="companyBranchId"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Company Branch
                          </label>
                          <div className="">
                            <Field
                              as="select"
                              id="companyBranchId"
                              name="companyBranchId"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              placeholder=""
                            >
                              <option>Choose If Specific Company Branch Holiday</option>
                              {companyBranchesState.companyBranches.map((companyBranch) => (
                                <option key={companyBranch.id} value={companyBranch.id}>
                                  {companyBranch.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <ErrorMessage name="companyBranchId" component="div" />
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="dateCelebrated"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Date of Celebration
                          </label>
                          <div className="">
                            <Field
                              type="date"
                              id="dateCelebrated"
                              name="dateCelebrated"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              required
                            />
                          </div>
                          <ErrorMessage name="dateCelebrated" component="div" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-2 w-max">
                    <div className="flex justify-center w-full">
                      <ButtonAction
                        type="submit"
                        className="modal-buttons flex justify-center items-center p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                        isLoading={loading}
                        hasSpinnerText={false}
                        isSubmit
                      />
                    </div>
                    <div className="flex justify-center w-max">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default UserHoliday;
