import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card, List, ListItem, ListItemPrefix, Spinner } from '@material-tailwind/react';
import ImageWithDefault from '../../../atoms/ImageWithDefault';
import useAuth from '../../../../hooks/auths/useAuth';
import AccordionMenu from '../../../molecules/AccordionMenu';
import LinkMenu from '../../../atoms/LinkMenu';
import SmallSidebar from './SmallSidebar';
import useUser from '../../../../hooks/users/useUser';
import useAccordionMenu from '../../../../hooks/utils/useAccordionMenu';
import { adminMenu, payrollMenu, personalMenu, reportsMenu } from './SidebarMenuOptions';
import { ReactComponent as Chart } from '../../../../assets/images/icons/chart.svg';
import { ReactComponent as Table } from '../../../../assets/images/icons/table.svg';
import { ReactComponent as Person } from '../../../../assets/images/icons/person.svg';
import { ReactComponent as Book } from '../../../../assets/images/icons/book.svg';
import { ReactComponent as Briefcase } from '../../../../assets/images/icons/briefcase.svg';
import { ReactComponent as Phone } from '../../../../assets/images/icons/phone.svg';
import { ReactComponent as Newspaper } from '../../../../assets/images/icons/newspaper.svg';
import { ReactComponent as Settings } from '../../../../assets/images/icons/settings.svg';
import { ReactComponent as Home } from '../../../../assets/images/icons/home.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/images/icons/logout.svg';

const SidebarHome: React.FC = () => {
  const { openedItems, toggleItem } = useAccordionMenu();
  const { loggedUser } = useAuth();
  const { currentUser, loading } = useUser((loggedUser?.userId ?? '').toString());

  const userDataImage = {
    ...currentUser,
    imageProfileImage:
      'https://images.pexels.com/photos/653429/pexels-photo-653429.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  };

  const employeeName = currentUser?.employment?.currentPosition?.employmentPosition.name;
  const employeeTier = currentUser?.employment?.currentPosition?.tier;

  const navigate = useNavigate();
  const pathArray = useLocation().pathname.split('/');

  const onLogoutClick = () => {
    navigate('/logout');
  };

  const singleMenuClick = () => {
    openedItems.forEach((openedItem) => {
      toggleItem(openedItem);
    });
  };
  // UNUSED SIDEBAR COMPONENT

  return (
    <div>
      <div className="hidden lg:block">
        <Card className="shadow-xl sidebar-card-wrapper shadow-blue-gray-900/5">
          <div className="account-profile-information">
            <div className="flex space-x-3">
              <div className="cursor-pointer rounded-full sidebar-image-wrapper">
                {loading ? (
                  <div className="h-full w-full flex justify-center items-center">
                    <Spinner height="1rem" color="deep-orange" />
                  </div>
                ) : (
                  <Link to="/my/profile">
                    <ImageWithDefault
                      className="h-full w-full sidebar-profile-image"
                      src={userDataImage.imageProfileImage}
                      defaultImageUrl="/assets/images/profile/default.png"
                      alt={`${loggedUser?.fullName.toUpperCase()} Profile Image`}
                    />
                  </Link>
                )}
              </div>
              <div className="flex justify-center m-auto cursor-pointer sidebar-main-info">
                <Link to="/my/profile">
                  <div className="flex gap-1 sidebar-fullname-wrapper">
                    <span className="sidebar-lastname">{loggedUser?.lastName}, </span>
                    <span className="sidebar-firstname">{loggedUser?.firstName}</span>
                  </div>
                  <div className="sidebar-user-position">
                    {employeeTier ? (
                      <span className="">{`${employeeName} ${employeeTier}`}</span>
                    ) : (
                      <span />
                    )}
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <hr className="rounded-md ml-4 mr-5 border-1 opacity-10 sidebar-borders" />

          <div className="h-full sidebar-content">
            <List className="sidebar-menus">
              <LinkMenu
                menuTitle="Home"
                icon={<Home className="sidebar-icon" />}
                pathArray={pathArray}
                toPath="/user/home"
                onClick={singleMenuClick}
              />
              {/* PERSONAL */}
              <AccordionMenu
                menuTitle="Personal"
                openNumber={1}
                options={personalMenu}
                open={openedItems.includes(1) ? 1 : 0}
                handleOpen={() => toggleItem(1)}
                accordionIcon={<Person className="sidebar-icon" />}
              />

              {/* ADMIN */}
              <AccordionMenu
                menuTitle="Admin"
                openNumber={2}
                options={adminMenu}
                open={openedItems.includes(2) ? 2 : 0}
                handleOpen={() => toggleItem(2)}
                accordionIcon={<Table className="sidebar-icon" />}
              />
              {/* REPORTS */}
              <AccordionMenu
                menuTitle="Reports"
                openNumber={3}
                options={reportsMenu}
                open={openedItems.includes(3) ? 3 : 0}
                handleOpen={() => toggleItem(3)}
                accordionIcon={<Chart className="sidebar-icon" />}
              />

              {/* PAYROLL */}
              <AccordionMenu
                menuTitle="Payroll"
                openNumber={4}
                options={payrollMenu}
                open={openedItems.includes(4) ? 4 : 0}
                handleOpen={() => toggleItem(4)}
                accordionIcon={<Chart className="sidebar-icon" />}
              />

              <LinkMenu
                menuTitle="Handbook"
                icon={<Book className="sidebar-icon" />}
                pathArray={pathArray}
                toPath="/user/handbook"
                onClick={singleMenuClick}
              />
              <LinkMenu
                menuTitle="Workforce"
                icon={<Briefcase className="sidebar-icon" />}
                pathArray={pathArray}
                toPath="https://awesomeos.org/app"
                exter
                onClick={singleMenuClick}
              />
              <LinkMenu
                menuTitle="IT Policies"
                icon={<Newspaper className="sidebar-icon" />}
                pathArray={pathArray}
                toPath="/user/policies"
                onClick={singleMenuClick}
              />

              <hr className="mt-3 mb-3 rounded-md border-1 opacity-10 sidebar-borders" />

              <LinkMenu
                menuTitle="Contact Information"
                icon={<Phone className="sidebar-icon" />}
                pathArray={pathArray}
                toPath="/user/contact-information"
                onClick={singleMenuClick}
              />
              <LinkMenu
                menuTitle="Troubleshooting"
                icon={<Settings className="sidebar-icon" />}
                pathArray={pathArray}
                toPath="/user/osnet/troubleshooting"
                onClick={singleMenuClick}
              />

              <ListItem className="sidebar-borders flex items-center gap-2 p-2 cursor-pointer hover:bg-[#f05825] focus:bg-[#f05825]">
                <ListItemPrefix className="sidebar-icon">
                  <LogoutIcon className="sidebar-icon" />
                </ListItemPrefix>
                <button
                  className="flex w-full h-full flex-start"
                  type="button"
                  onClick={onLogoutClick}
                >
                  <span className="sidebar-text">Log Out</span>
                </button>
              </ListItem>
            </List>
          </div>
        </Card>
      </div>
      <div className="hidden sm:block lg:hidden">
        <SmallSidebar />
      </div>
    </div>
  );
};

export default SidebarHome;
