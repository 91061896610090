import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import AuthActions from '../../actions/auths/authActions';

import {
  AuthLoginResponse,
  AuthRefreshAccessTokenResponse,
} from '../../services/auths/authServices';
import time from '../../../utils/time';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import UserInformation from '../../constants/users/user-information.interface';
import UserEmploymentActions from '../../actions/users/employments/userEmploymentActions';
import ACL from '../../constants/utils/acl.interface';

export interface AuthState {
  token?: string;
  role?: string;
  acl?: ACL;
  expireTime?: number;
  refreshTime?: number;
  userInformation?: UserInformation;
}

const initialState: AuthState = {
  token: undefined,
  role: undefined,
  acl: undefined,
  expireTime: undefined,
  refreshTime: undefined,
  userInformation: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => ({
      ...initialState,
    }),
    setAuthState: (state, action: PayloadAction<Partial<AuthState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(AuthActions.login.fulfilled, (state, { payload }) => {
      const { status, data } = payload as ApiBaseResponse<AuthLoginResponse>;

      if (status !== 200) return;

      state.acl = data.acl;
      state.role = data.role;
      state.userInformation = data.userInformation;
      state.token = data.token;
      state.expireTime = time() + 3600;
      state.refreshTime = 1000 * 60 * 55; // ms * s * m = 55 minutes refresh time
    });
    builder.addCase(AuthActions.refreshToken.fulfilled, (state, { payload }) => {
      const { status, data } = payload as ApiBaseResponse<AuthRefreshAccessTokenResponse>;

      if (status !== 200) return;

      state.token = data;
      state.expireTime = time() + 3600;
    });
    builder.addCase(UserEmploymentActions.updatePassword.fulfilled, (state, { payload }) => {
      const { status } = payload as ApiBaseResponse<boolean>;

      if (status !== 200) return;

      state.token = undefined;
    });
  },
});

export const authReducerActions = authSlice.actions;
export default authSlice.reducer;
