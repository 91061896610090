import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyBranch from '../../../../store/constants/companies/branches/company-branch.interface';
import useCompanyBranches from '../../../../hooks/companies/branches/useCompanyBranches';
import CompanyBranchForm from '../../../molecules/Companies/Branches/CompanyBranchForm';

const CompanyBranchAddForm: React.FC = () => {
  const { post } = useCompanyBranches();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);

  const handleClose = () => {
    navigate('/admin/companies/branches');
  };

  const handleSubmit = async (value: Partial<CompanyBranch>) => {
    setIsloading(true);
    post(value as CompanyBranch)
      .then(() => navigate('/admin/companies/branches'))
      .finally(() => setIsloading(false));
  };

  return (
    <div className="client-manage-form-container">
      <div className="">
        <CompanyBranchForm
          onSubmit={handleSubmit}
          handleClose={handleClose}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default CompanyBranchAddForm;
