import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemPrefix } from '@material-tailwind/react';
import AccordionMenu from '../../../molecules/AccordionMenu';
import useAccordionMenu from '../../../../hooks/utils/useAccordionMenu';
import useSettings from '../../../../hooks/utils/useSettings';
import LinkMenu from '../../../atoms/LinkMenu';
import { personalMenu } from './SidebarMenuOptions';
import { ReactComponent as Home } from '../../../../assets/images/icons/home-solid.svg';
import { ReactComponent as Person } from '../../../../assets/images/icons/person-solid.svg';
import { ReactComponent as Admin } from '../../../../assets/images/icons/person-circle-solid.svg';
import { ReactComponent as Handbook } from '../../../../assets/images/icons/handbook-solid.svg';
import { ReactComponent as Briefcase } from '../../../../assets/images/icons/briefcase-solid.svg';
import { ReactComponent as Newspaper } from '../../../../assets/images/icons/newspaper-solid.svg';
import { ReactComponent as Phone } from '../../../../assets/images/icons/phone-solid.svg';
import { ReactComponent as Cog } from '../../../../assets/images/icons/cog-solid.svg';
import { ReactComponent as Logout } from '../../../../assets/images/icons/arrow-left-end.svg';

const SmallSidebar = () => {
  const navigate = useNavigate();
  const { hideUserDrawer } = useSettings();
  const { openedItems, toggleItem } = useAccordionMenu();

  const onLogoutClick = () => navigate('/logout');

  const singleMenuClick = () => {
    if (hideUserDrawer) {
      hideUserDrawer();
    }

    openedItems.forEach((openedItem) => {
      toggleItem(openedItem);
    });
  };

  const pathArray = useLocation().pathname.split('/');
  return (
    <List className=" items-center">
      <ListItem className="sidebar-borders p-0">
        <LinkMenu
          menuTitle="Home"
          icon={<Home className="my-sidebar-icon" />}
          pathArray={pathArray}
          toPath="/user/newsfeed"
          onClick={singleMenuClick}
        />
      </ListItem>
      {/* PERSONAL */}
      <AccordionMenu
        menuTitle="Personal"
        openNumber={1}
        options={personalMenu}
        open={openedItems.includes(1) ? 1 : 0}
        handleOpen={() => toggleItem(1)}
        accordionIcon={<Person className="my-sidebar-icon" />}
      />
      {/* ADMIN */}
      <ListItem className="sidebar-borders p-0">
        <LinkMenu
          menuTitle="Admin"
          icon={<Admin className="my-sidebar-icon" />}
          pathArray={pathArray}
          toPath="/admin/dashboard"
        />
      </ListItem>
      {/* HANDBOOK */}
      <ListItem className="sidebar-borders p-0">
        <LinkMenu
          menuTitle="Handbook"
          icon={<Handbook className="my-sidebar-icon" />}
          pathArray={pathArray}
          toPath="/user/handbook"
        />
      </ListItem>

      {/* WORKFORCE */}
      <ListItem className="sidebar-borders p-0">
        <LinkMenu
          menuTitle="Workforce"
          icon={<Briefcase className="my-sidebar-icon" />}
          pathArray={pathArray}
          toPath="#"
          // toPath="https://awesomeos.org/app"
          // exter
        />
      </ListItem>

      <ListItem className="sidebar-borders p-0">
        <LinkMenu
          menuTitle="IT Policies"
          icon={<Newspaper className="my-sidebar-icon" />}
          pathArray={pathArray}
          toPath="/user/policies"
        />
      </ListItem>

      <ListItem className="sidebar-borders p-0">
        <LinkMenu
          menuTitle="Contact Information"
          icon={<Phone className="my-sidebar-icon" />}
          pathArray={pathArray}
          toPath="/user/contact-information"
        />
      </ListItem>

      <ListItem className="sidebar-borders p-0">
        <LinkMenu
          menuTitle="Troubleshooting"
          icon={<Cog className="my-sidebar-icon" />}
          pathArray={pathArray}
          toPath="/user/osnet/troubleshooting"
        />
      </ListItem>

      <ListItem
        onClick={onLogoutClick}
        className={`sidebar-borders flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825]
        }`}
      >
        <ListItemPrefix>
          <Logout className="my-sidebar-icon" />
        </ListItemPrefix>
        <span className="sidebar-text">Log Out</span>
      </ListItem>
    </List>
  );
};

export default SmallSidebar;
