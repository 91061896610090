import React, { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useUsers from '../../../../hooks/users/useUsers';
import consoleLog from '../../../../utils/consoleLog';
import AtLeast from '../../../../types/atleast';
import User from '../../../../store/constants/users/user.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import { ReactComponent as PencilIcon } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/icons/check-solid.svg';

interface EmergencyInfoProps {
  isLoading?: boolean;
  user: AtLeast<User, 'contact'>;
}

const EmergencyInfo: React.FC<EmergencyInfoProps> = ({ isLoading = true, user }) => {
  const { put } = useUsers();

  const [showButtonAction, setShowButtonAction] = useState(false);
  const [showInputAction, setShowInputAction] = useState(false);

  const handleSubmit = async (data: AtLeast<User, 'contact'>) => {
    consoleLog('DATA VALUES:', data);

    if (user.id) {
      await toastNotification({
        action: put(data as User),
        onSuccess: () => {
          setShowButtonAction(false);
          setShowInputAction(false);
        },
        onError: () => {
          setShowButtonAction(false);
          setShowInputAction(false);
        },
        toastPromiseMessages: {
          pending: `Updating emergency contact for ${user.lastName} ${user.lastName}...`,
          success: `${user.lastName} ${user.lastName} is successfully updated.`,
          error: `Failed to update emergency contact for ${user.lastName} ${user.lastName}! Try again.`,
        },
      });
    }
  };

  const onEditClick = () => {
    setShowButtonAction(!showButtonAction);
    setShowInputAction(!showInputAction);
  };

  const onCancel = () => {
    setShowButtonAction(false);
    setShowInputAction(false);
  };

  return (
    <Formik
      initialValues={{
        id: user.id,
        contact: user.contact,
      }}
      onSubmit={handleSubmit}
    >
      <Form className="rounded-xl shadow-xl user-update__form-wrapper">
        <div className="flex items-center mb-5">
          <div className="user-update__form-heading">
            <Typography variant="h4" className="user-update__form-title">
              Emergency Contact
            </Typography>
            <span className="user-update__form-sub-title">Information</span>
          </div>
          {!isLoading && (
            <div className="ml-auto">
              {!showButtonAction ? (
                <Tooltip content="Edit">
                  <IconButton
                    onClick={onEditClick}
                    size="sm"
                    className="bg-primary-gray rounded-none"
                  >
                    <PencilIcon className="w-5 h-5 stroke-white" />
                  </IconButton>
                </Tooltip>
              ) : (
                <div className="flex flex-row-reverse gap-2">
                  <Tooltip content="Cancel">
                    <IconButton
                      onClick={onCancel}
                      size="sm"
                      className="bg-primary-gray rounded-none"
                    >
                      <CloseIcon className="w-5 h-5 stroke-red-900" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Save">
                    <IconButton type="submit" size="sm" className="bg-primary-gray rounded-none">
                      <CheckIcon className="w-5 h-5 stroke-green-800" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="user.contact.emergencyContactNumber"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Number
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contact.emergencyContactNumber"
                  name="contact.emergencyContactNumber"
                  placeholder="Number"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contact?.emergencyContactNumber || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contact.emergencyContactNumber" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="contact.emergencyContactName"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Name
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contact.emergencyContactName"
                  name="contact.emergencyContactName"
                  placeholder="Name"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contact?.emergencyContactName || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contact.emergencyContactName" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="contact.emergencyContactRelation"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Relation
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contact.emergencyContactRelation"
                  name="contact.emergencyContactRelation"
                  placeholder="Relation"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contact?.emergencyContactRelation || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contact.emergencyContactRelation" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="contact.emergencyContactAddress"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Address
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contact.emergencyContactAddress"
                  name="contact.emergencyContactAddress"
                  placeholder="Address"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contact?.emergencyContactAddress || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contact.emergencyContactAddress" component="div" />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default EmergencyInfo;
