import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Checkbox, Dialog, DialogBody, IconButton } from '@material-tailwind/react';
import UserEmployment from '../../../../store/constants/employees/employee.interface';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import SelectEmployee from '../../../atoms/dropdowns/SelectEmployee';
import useCalculateTimeDuration from '../../../../hooks/utils/useCalculateTimeDuration';
import useAuth from '../../../../hooks/auths/useAuth';
import useUser from '../../../../hooks/users/useUser';
import toastNotification from '../../../../hooks/utils/toastNotification';
import useOvertimes from '../../../../hooks/attendances/useOvertimes';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as Plus } from '../../../../assets/images/icons/person.svg';

export interface OvertimeApproveFormModalParams {
  manageOpenModal: boolean;
  handleCloseModal: () => void;
}

export interface OvertimeNewEmployeeFormType {
  date: Date | string;
  preApproved: boolean;
  timeIn: string;
  timeOut: string;
}

export interface ExtendedEmployeeOvertime extends EmployeeOvertime, OvertimeNewEmployeeFormType {}

const OvertimeNewEmployeeForm: React.FC<OvertimeApproveFormModalParams> = ({
  manageOpenModal,
  handleCloseModal,
}) => {
  const {
    authState: { userInformation },
  } = useAuth();
  const { duration, setDuration, calculateDuration } = useCalculateTimeDuration();
  const { post } = useOvertimes();

  const { currentUser } = useUser((userInformation?.userId ?? '').toString());
  const maxDate = moment().format('YYYY-MM-DD');

  const [loading, setLoading] = useState(false);
  const [selectedUserEmployment, setSelectedUserEmployment] = useState<
    UserEmployment | undefined
  >();

  const handleClose = () => {
    setDuration('');
    setSelectedUserEmployment(undefined);
    handleCloseModal();
  };

  const handleEmployeeOvertimeFormSubmit = async (values: Partial<ExtendedEmployeeOvertime>) => {
    setLoading(true);

    const formattedDate = moment(values.date).format('YYYY-MM-DD');
    const formattedTimeIn = moment(`${formattedDate} ${values.timeIn}`, 'YYYY-MM-DD hh:mmA').format(
      'YYYY-MM-DD HH:mm',
    );
    let formattedTimeOut = moment(`${formattedDate} ${values.timeOut}`, 'YYYY-MM-DD hh:mmA').format(
      'YYYY-MM-DD HH:mm',
    );

    // Check if time-out is on the same day or next day
    if (moment(formattedTimeOut).isBefore(formattedTimeIn)) {
      formattedTimeOut = moment(values.date).add(1, 'day').format('YYYY-MM-DD');
      formattedTimeOut = moment(
        `${formattedTimeOut} ${values.timeOut}`,
        'YYYY-MM-DD hh:mmA',
      ).format('YYYY-MM-DD HH:mm');
    }

    const overtimeData: Omit<EmployeeOvertime, 'id'> = {
      overtimeTimeIn: formattedTimeIn,
      overtimeTimeOut: formattedTimeOut,
      createdBy: currentUser?.employment?.employeeNumber,
      employeeNumber: selectedUserEmployment?.employeeNumber ?? 0,
      approvedBy: values.preApproved ? currentUser?.employment?.employeeNumber : undefined,
      duration,
    } as unknown as EmployeeOvertime;

    await toastNotification({
      action: post(overtimeData),
      onSuccess: () => {
        setSelectedUserEmployment(undefined);
        setLoading(false);
        handleClose();
      },
      onError: () => {},
      onFulfilled: async () => {
        setSelectedUserEmployment(undefined);
        setLoading(false);
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing Employee Overtime.',
        success: 'Employee Overtime is successfully created.',
        error: 'An error has occurred, creating Employees Overtime.',
      },
    });
  };

  return (
    <Dialog
      size="sm"
      open={manageOpenModal}
      handler={handleCloseModal}
      className="shadow-none overtime-dialog__container overtime-content__new-overtime"
    >
      <div className="absolute top-0 right-0 flex p-0 m-2">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex items-center gap-2 pb-2 overtime-content__wrapper">
        <Plus className="w-5 h-5 overtime-content__icon" />
        <h1 className="overtime-content__heading">New Overtime</h1>
      </div>
      <DialogBody divider className="p-0">
        <Formik
          initialValues={{
            id: 0,
            createdBy: 0,
            employeeNumber: 0,
            overtimeType: '',
            timeIn: '',
            timeOut: '',
            duration: '',
            employee: undefined,
            date: moment().format('YYYY-MM-DD'),
            preApproved: false,
            nightDiff: 0,
          }}
          onSubmit={handleEmployeeOvertimeFormSubmit}
        >
          {(formik) => (
            <Form className="w-full">
              <div className="flex justify-center gap-3 w-full my-5">
                <div className="overtime-content__first-container">
                  {/* Employee Name */}
                  <div className="w-full mb-3 overtime-content__field-wrapper">
                    <label
                      htmlFor="employee"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      Employee
                    </label>
                    <div className="w-full">
                      <SelectEmployee
                        id="employee"
                        name="employee"
                        placeholder="Select employee"
                        value={selectedUserEmployment}
                        onChange={(value) => setSelectedUserEmployment(value as UserEmployment)}
                        hasSearchIcon
                        isSearchable
                        isClearable
                      />
                      <ErrorMessage name="employee" component="div" />
                    </div>
                  </div>
                  {/* Time-in */}
                  <div className="mb-3">
                    <label
                      htmlFor="timeIn"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      TIME IN
                    </label>
                    <div className="flex gap-2 justify-center items-center">
                      <Field
                        type="time"
                        id="timeIn"
                        name="timeIn"
                        placeholder="Time in"
                        className="w-full p-2 rounded-md shadow-lg overtime-content__field-overtime overtime-content__field"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue('timeIn', e.target.value);
                          if (formik.values.timeOut) {
                            calculateDuration(e.target.value, formik.values.timeOut);
                          }
                        }}
                      />
                      <ErrorMessage name="timeIn" component="div" />
                    </div>
                  </div>
                  {/* Duration */}
                  <div className="mb-3">
                    <h2 className="block mb-2 font-semibold overtime-content__title">Duration</h2>
                    <p className="w-full p-2 rounded-md shadow-lg overtime-content__field-overtime overtime-content__field disabled-field">
                      <span className="pl-1">{duration}</span>
                    </p>
                  </div>
                </div>

                <div className="overtime-content__second-container">
                  {/* Overtime Date  */}
                  <div className="mb-3">
                    <label
                      htmlFor="date"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      Date
                    </label>
                    <Field
                      type="date"
                      id="date"
                      name="date"
                      max={maxDate}
                      placeholder="Date Start"
                      className="w-full p-2 rounded-md shadow-lg overtime-content__field-overtime overtime-content__field"
                    />
                    <ErrorMessage name="date" component="div" />
                  </div>
                  {/* Time-Out */}
                  <div className="mb-3">
                    <label
                      htmlFor="timeOut"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      TIME OUT
                    </label>
                    <div className="flex gap-2 justify-center items-center">
                      <Field
                        type="time"
                        id="timeOut"
                        name="timeOut"
                        placeholder="Time out"
                        className="w-full p-2 rounded-md shadow-lg overtime-content__field-overtime overtime-content__field"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          formik.setFieldValue('timeOut', e.target.value);
                          if (formik.values.timeIn) {
                            calculateDuration(formik.values.timeIn, e.target.value);
                          }
                        }}
                      />
                      <ErrorMessage name="timeOut" component="div" />
                    </div>
                  </div>
                  {/* Pre Approved */}
                  <div className="w-full">
                    <label
                      htmlFor="preApproved"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      Pre Approve
                    </label>
                    <div className="w-full flex items-center mt-1 rounded-md shadow-lg overtime-content__checkbox-field overtime-content__field">
                      <Checkbox
                        label="Approved"
                        checked={formik.values.preApproved}
                        className="w-full p-2 checked:bg-[#f05825] checked:border-[#f05825] checked:before:bg-[#f05825]"
                        onChange={(e) => formik.setFieldValue('preApproved', e.target.checked)}
                      />
                    </div>
                    <ErrorMessage name="preApproved" component="div" />
                  </div>
                </div>
              </div>
              <div className="flex justify-between w-full pb-5 scheduler-button-wrapper">
                <div className="flex justify-center w-max">
                  <ButtonAction
                    type="submit"
                    className=" p-2 rounded-md shadow-lg button-submit-scheduler"
                    isLoading={loading}
                    hasSpinnerText={false}
                    isSubmit
                  />
                </div>
                <button
                  type="button"
                  onClick={handleClose}
                  className="p-2  rounded-lg shadow-lg button-close-scheduler"
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export default OvertimeNewEmployeeForm;
