import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import type { SwitchStates } from '../PayrollToolOptions';
import Payslip from '../../../../../store/constants/payroll/payslip.interface';

const getViewPayslipColumns = (
  switchStates: SwitchStates,
  // eslint-disable-next-line
  getUserEmploymentByEmployeeNumber: Function,
): ColumnsType<Partial<Payslip>> => {
  const columns: ColumnsType<Partial<Payslip>> = [
    {
      title: 'ID',
      width: 60,
      dataIndex: 'id',
      fixed: 'left',
      className: 'first-column whitespace-nowrap',
      key: 'id',
    },
    {
      title: 'Name',
      width: 100,
      dataIndex: ['employeeNumber'],
      fixed: 'left',
      className: 'whitespace-nowrap',
      key: 'name',
      render: (employeeNumber: string) =>
        getUserEmploymentByEmployeeNumber(parseInt(employeeNumber, 10))?.userInformation.fullName,
    },
    {
      title: 'Schedule',
      width: 80,
      dataIndex: 'scheduleId',
      fixed: 'left',
      key: 'scheduleId',
    },
    {
      title: 'Department',
      dataIndex: ['details', 'department'],
      width: 100,
      fixed: 'left',
      className: 'whitespace-nowrap',
      key: 'department',
    },
    {
      title: 'Team',
      dataIndex: ['details', 'team_name'],
      width: 80,
      fixed: 'left',
      className: 'whitespace-nowrap',
      key: 'team',
    },
    {
      title: 'Gross Pay',
      dataIndex: ['details', 'monthly_basic'],
      width: 80,
      fixed: 'left',
      align: 'center',
      className: 'whitespace-nowrap',
      key: 'grossPay',
    },
    {
      title: 'Daily Rate',
      dataIndex: ['dailyRate'],
      width: 80,
      fixed: 'left',
      align: 'center',
      key: 'dailyRate',
    },
    {
      title: 'Days Work',
      dataIndex: ['workedDays'],
      width: 60,
      fixed: 'left',
      align: 'center',
      key: 'daysWorked',
    },
    {
      title: 'Days Leave',
      dataIndex: ['details', 'days_leave'],
      width: 60,
      fixed: 'left',
      align: 'center',
      key: 'daysLeave',
    },
    {
      title: 'Monthly Basic',
      dataIndex: ['monthlyBasic'],
      width: 80,
      fixed: 'left',
      align: 'center',
      key: 'monthlyBasic',
    },
    ...(switchStates.taxable
      ? [
          {
            title: 'Taxable',
            key: 'taxable.taxableIncome',
            className: 'scroll-first-column',
            children: [
              {
                title: 'Total Taxable',
                dataIndex: ['taxable', 'totalTaxable'],
                className: 'scroll-first-column',
                width: 150,
                key: 'taxable.taxableIncome',
              },
            ],
          },
        ]
      : []),
    ...(switchStates.incentiveCompany
      ? [
          {
            title: 'Incentive Company',
            key: 'incentiveCompany',
            className: 'scroll-column',
            children: [
              {
                title: 'Total',
                dataIndex: 'incentiveCompany',
                className: 'scroll-column',
                width: 150,
                key: 'incentiveCompany',
              },
            ],
          },
        ]
      : []),
    ...(switchStates.incentiveClient
      ? [
          {
            title: 'Incentive Client',
            key: 'incentiveClient',
            className: 'scroll-column',
            children: [
              {
                title: 'Incentive Client',
                dataIndex: 'incentiveClient',
                className: 'scroll-column',
                width: 150,
                key: 'incentiveClient',
              },
            ],
          },
        ]
      : []),
    ...(switchStates.allowances
      ? [
          {
            title: 'Allowances',
            key: 'allowances.totalAllowance',
            className: 'scroll-column',
            children: [
              {
                title: 'Total Allowances',
                dataIndex: ['allowances', 'totalAllowances'],
                className: 'scroll-column',
                width: 150,
                key: 'allowances.totalAllowance',
              },
            ],
          },
        ]
      : []),
    ...(switchStates.deductions
      ? [
          {
            title: 'Deductions',
            key: 'deductions.totalDeductions',
            className: 'scroll-column bottom-line-column',
            children: [
              {
                title: 'Total Deductions',
                dataIndex: ['deductions', 'totalDeductions'],
                className: 'scroll-column',
                width: 150,
                key: 'deductions.totalDeductions',
              },
              {
                title: 'Taxable Income',
                dataIndex: ['details', 'taxable_income'],
                className: 'scroll-column',
                width: 150,
                key: 'taxableIncome',
              },
            ],
          },
        ]
      : []),
    {
      title: 'Incomes',
      key: 'status',
      className: 'scroll-column',
      children: [
        {
          title: 'Status',
          dataIndex: 'status',
          className: 'scroll-column',
          width: 100,
          key: 'status',
        },
        {
          title: 'Taxes',
          dataIndex: 'taxes',
          className: 'scroll-column',
          width: 150,
          key: 'taxes',
        },
        {
          title: 'Gross Income',
          dataIndex: ['details', 'monthly_gross'],
          className: 'scroll-column',
          width: 150,
          key: 'grossIncome',
        },
        {
          title: 'Disputes',
          dataIndex: ['details', 'disputes'],
          className: 'scroll-column',
          width: 150,
          key: 'disputes',
        },
        {
          title: 'Net Income',
          dataIndex: ['details', 'net_income'],
          className: 'scroll-column',
          width: 150,
          key: 'netIncome',
        },
        {
          title: 'Start Date',
          dataIndex: ['details', 'date_started'],
          className: 'scroll-column',
          width: 150,
          key: 'startDate',
          render: (date: string) => moment(date).format('YYYY-MM-DD'),
        },
      ],
    },
    {
      title: '13 Month',
      key: 'endDate',
      className: 'scroll-column',
      children: [
        {
          title: 'End Date',
          dataIndex: 'endDate',
          className: 'scroll-column',
          width: 150,
          key: 'endDate',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          className: 'scroll-column',
          width: 150,
          key: 'amount',
        },
        {
          title: 'Date Started',
          dataIndex: 'dateStarted',
          className: 'scroll-column',
          width: 150,
          key: 'dateStarted',
        },
      ],
    },
    {
      title: 'Details',
      key: 'details.bankAccountNumber',
      className: 'scroll-last-column',
      children: [
        {
          title: 'Account Number',
          dataIndex: ['details', 'bank_account_number'],
          className: 'scroll-column',
          width: 150,
          key: 'details.bankAccountNumber',
        },
        {
          title: 'Employment Type',
          dataIndex: 'employmentType',
          width: 150,
          key: 'employmentType',
        },
      ],
    },
  ];

  return columns;
};

export default getViewPayslipColumns;
