import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import ScheduleType from '../../constants/employments/schedules/employment-schedule-type.interface';

export type GetAllScheduleTypesQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: ScheduleType['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<ScheduleType>> =>
  apiFetch<ApiBaseResponse<ScheduleType>>(`employments/schedules/types/${id}`, { state })
    .then((response: ApiBaseResponse<ScheduleType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllScheduleTypesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<ScheduleType>> =>
  apiFetch<ApiGetAllBaseResponse<ScheduleType>>(
    `employments/schedules/types?limit=${limit}&page=${page}${
      search !== '' ? `&search=${search}` : ''
    }`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<ScheduleType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<ScheduleType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<ScheduleType> | void> =>
  apiFetch<ApiBaseResponse<ScheduleType>>('employments/schedules/types', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<ScheduleType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: ScheduleType,
  state: StateHeaders,
): Promise<ApiBaseResponse<ScheduleType> | void> =>
  apiFetch<ApiBaseResponse<ScheduleType>>(`employments/schedules/types/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<ScheduleType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<ScheduleType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<ScheduleType> | void> =>
  apiFetch<ApiBaseResponse<ScheduleType>>(`employments/schedules/types/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<ScheduleType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: ScheduleType['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<ScheduleType> | void> =>
  apiFetch<ApiBaseResponse<ScheduleType>>(`employments/schedules/types/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<ScheduleType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
