import React from 'react';
import ThirteenMonthSemiAnnualToolOptions from './ThirteenthMonthSemiAnnualToolOptions';
import PayrollSidebarSearchOnly from '../../sidebar/PayrollSidebarSearchOnly';

const ThirteenthMonthSemiAnnual: React.FC = () => (
  <div className="flex w-full h-full organisms-payroll-management">
    <div className="flex w-full h-full">
      <div className="payroll-sidebar-wrapper">
        <PayrollSidebarSearchOnly />
      </div>
      <div className="flex flex-col w-full payroll-content-wrapper">
        <ThirteenMonthSemiAnnualToolOptions />
      </div>
    </div>
  </div>
);

export default ThirteenthMonthSemiAnnual;
