import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ReactComponent as CloseCircle } from '../../../../assets/images/icons/close-circle.svg';
import { ReactComponent as RightIcon } from '../../../../assets/images/icons/chevron-right.svg';
import { ReactComponent as LeftIcon } from '../../../../assets/images/icons/chevron-left.svg';

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageFiles: File[];
  selectedImageIndex?: number;
}

const ImageNavigationModal: React.FC<ImageModalProps> = ({
  isOpen,
  onClose,
  imageFiles,
  selectedImageIndex = undefined,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(selectedImageIndex || 0);
  const [isLoading, setIsLoading] = useState(true);
  const shouldRenderNavigation = imageFiles.length > 1;

  const imageUrlList = useMemo(
    () => imageFiles.map((file) => URL.createObjectURL(file)),
    [imageFiles],
  );

  const imageNameList = imageFiles.map((file) => file.name);

  const nextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrlList.length);
    setIsLoading(true);
  }, [imageUrlList]);

  const previousImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageUrlList.length - 1 : prevIndex - 1,
    );
    setIsLoading(true);
  }, [imageUrlList]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      previousImage();
    } else if (e.key === 'ArrowRight') {
      e.preventDefault();
      nextImage();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  };

  const handleClickOutside = (e: React.MouseEvent) => {
    if ((e.target as Element).classList.contains('image-modal__overlay')) {
      onClose();
    }
  };

  useEffect(() => {
    setCurrentImageIndex(selectedImageIndex || 0);
    if (isOpen) {
      const activeElement = document.querySelector('.image-modal__overlay--active');
      if (activeElement instanceof HTMLElement) {
        activeElement.focus();
      }
    }
  }, [selectedImageIndex, isOpen]);

  useEffect(() => {
    const cleanup = () => imageUrlList.forEach((url) => URL.revokeObjectURL(url));

    return cleanup;
  }, [imageUrlList]);

  if (!isOpen) return null;

  return (
    <div
      className="image-modal__overlay image-modal__overlay--active"
      onKeyDown={handleKeyDown}
      onClick={handleClickOutside}
      role="button"
      tabIndex={0}
    >
      <div className="image-modal__container">
        <div className="image-modal__wrapper">
          <img
            src={imageUrlList[currentImageIndex]}
            alt={imageNameList[currentImageIndex]}
            className={`image-modal__content-image ${
              isLoading
                ? 'image-modal__content-image--loading'
                : 'image-modal__content-image--loaded'
            }`}
            onLoad={() => setIsLoading(false)}
          />
          {shouldRenderNavigation && (
            <>
              <div
                className="image-modal__nav-area image-modal__nav-area--left"
                onClick={previousImage}
                role="button"
                tabIndex={0}
                aria-label="Previous Image"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') previousImage();
                }}
              >
                <span className="image-modal__visually-hidden">Previous Image</span>
              </div>
              <div
                className="image-modal__nav-area image-modal__nav-area--right"
                onClick={nextImage}
                role="button"
                tabIndex={0}
                aria-label="Next Image"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') nextImage();
                }}
              >
                <span className="image-modal__visually-hidden">Next Image</span>
              </div>
              <button
                type="button"
                className="image-modal__nav-button image-modal__nav-button--left"
                onClick={previousImage}
                aria-label="Previous Image"
              >
                <LeftIcon className="w-20 h-auto" />
              </button>
              <button
                type="button"
                className="image-modal__nav-button image-modal__nav-button--right"
                onClick={nextImage}
                aria-label="Next Image"
              >
                <RightIcon className="w-20 h-auto" />
              </button>
            </>
          )}
        </div>
        <button
          type="button"
          className="image-modal__close-button"
          onClick={onClose}
          aria-label="Close Modal"
        >
          <CloseCircle className="w-full h-full" />
        </button>
      </div>
    </div>
  );
};

export default ImageNavigationModal;
