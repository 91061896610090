import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setInit, setInitDate } from '../../store/reducers/utils/appInitializationReducer';

const useAppInitialization = () => {
  const dispatch = useDispatch();
  const { init, initDate } = useSelector((state: RootState) => state.appInitialization);

  const setInitValue = (value: boolean) => {
    dispatch(setInit(value));
    if (value) {
      dispatch(setInitDate(new Date().toDateString()));
    }
  };

  return {
    init,
    initDate,
    setInit: setInitValue,
    setInitDate,
  };
};

export default useAppInitialization;
