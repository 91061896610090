import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import TextWithIcons from '../../../molecules/TextWithIcons';
import EmploymentPosition from '../../../../store/constants/employments/positions/employment-position.interface';
import PositionManageFormModal from './PositionManageFormModal';
import PositionManageListRow from './PositionManageListRow';
import usePositions from '../../../../hooks/positions/usePositions';
import usePositionRates from '../../../../hooks/positions/usePositionRates';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import consoleLog from '../../../../utils/consoleLog';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import SearchSvg from '../../../../assets/images/icons/search.svg';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions = '' | 'positionName' | 'otEligible' | 'ndEligible';

const PositionManageList: React.FC = () => {
  const [selectedPositions, setSelectedPositions] = useState<EmploymentPosition | undefined>();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const { positionsState, getAll: getAllPositions, remove } = usePositions();
  const { getAll: getAllPositionRates } = usePositionRates();
  const { getAll: getAllUserEmployments } = useUserEmployments();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  // const [isDescending, setIsDescending] = useState(true);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const positions = positionsState.positions
    .filter((position: EmploymentPosition) => {
      if (position && position.name) {
        const positionName = position.name.toLowerCase();
        const searchValues = debouncedSearchValue.toLowerCase();

        return (
          (searchValues.includes('ot') && position.isOtEligible) ||
          (searchValues.includes('nd') && position.isNdEligible) ||
          positionName.startsWith(searchValues)
        );
      }

      return false;
    })
    // .sort((a: EmploymentPosition, b: EmploymentPosition) => {
    //   const sortOrder = isDescending ? -1 : 1;

    //   return sortOrder * a.name.localeCompare(b.name);
    // });

    .sort((a: EmploymentPosition, b: EmploymentPosition) => {
      const sortOrder = sortOrderBy ? -1 : 1;
      const defaultOrder = b.id - a.id;

      if (sortOption === 'positionName') {
        return sortOrder * a.name.localeCompare(b.name);
      }

      if (sortOption === 'otEligible') {
        const aValue = a.isOtEligible ? 1 : 0;
        const bValue = b.isOtEligible ? 1 : 0;
        return sortOrder * (aValue - bValue);
      }

      if (sortOption === 'ndEligible') {
        const aValue = a.isNdEligible ? 1 : 0;
        const bValue = b.isNdEligible ? 1 : 0;
        return sortOrder * (aValue - bValue);
      }

      return defaultOrder;
    });

  // const toggleSorting = () => {
  //   setIsDescending(!isDescending); // Toggle the sorting order
  // };

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (position: EmploymentPosition) => {
    setSelectedPositions(position);
  };

  const onDeleteClick = async (position: EmploymentPosition) => {
    remove(position.id).then((r) => {
      consoleLog('DELETE POSITION', r);
    });
  };

  const onPositionsModalClose = () => setSelectedPositions(undefined);

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAllPositions().then();
    getAllPositionRates().then();
    getAllUserEmployments().then();
  });

  return (
    <div className="organisms-positions-list">
      <div className="organisms-client-list__top-section">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search position"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Position"
          />
        </div>
        <PositionManageFormModal positions={selectedPositions} onClose={onPositionsModalClose} />
      </div>
      <div className="positions-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        <button
          type="button"
          onClick={() => sortByHeader('positionName', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Name</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'positionName' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'positionName' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('otEligible', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">OT Eligible</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'otEligible' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'otEligible' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('ndEligible', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">ND Eligible</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'ndEligible' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'ndEligible' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <TextWithIcons variant="h6">Action</TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={positions.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <PositionManageListRow
            data={positions[index]}
            className={`${index % 2 === 0 ? 'stripped' : ''}`}
            style={style}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default PositionManageList;
