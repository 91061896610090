import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Bank from '../../constants/banks/bank.interface';
import BankActions from '../../actions/banks/bankActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';

export interface BanksState {
  banks: Bank[];
}

const initialState: BanksState = {
  banks: [],
};

const getBankData = (bank: Partial<Bank>) => ({
  ...bank,
});

const banksSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {
    resetBanksState: () => ({
      ...initialState,
    }),
    setBanksState: (state, action: PayloadAction<Partial<BanksState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertBank: (state, action: PayloadAction<Partial<Bank>>) => {
      const data: Bank = getBankData(action.payload) as Bank;

      if (typeof data.id === 'undefined') return;

      state.banks = upsertData(state.banks, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(BankActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Bank>;
      consoleLog('BANKS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.banks = upsertData(state.banks, data);
      }
    });
    builder.addCase(BankActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('BANKS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Bank>;

      if (status === 503) return;

      if (data) {
        state.banks = data;
      }
    });
    builder.addCase(BankActions.get.fulfilled, (state, { payload }) => {
      consoleLog('BANKS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Bank>;

      if (typeof data.id === 'undefined') return;
      state.banks = upsertData(state.banks, data);
    });
    builder.addCase(BankActions.put.fulfilled, (state, { payload }) => {
      consoleLog('BANKS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<Bank>;

      if (typeof data.id === 'undefined') return;
      state.banks = upsertData(state.banks, data);
    });
    builder.addCase(BankActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('BANKS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Bank>;

      if (typeof data.id === 'undefined') return;
      state.banks = state.banks.filter((bank) => bank.id !== data.id);
    });
  },
});

export const banksReducerActions = banksSlice.actions;
export default banksSlice.reducer;
