import React from 'react';
import { toast } from 'react-toastify';
import PrintPayslip from './PrintPayslip';
import { ReactComponent as Print } from '../../../../assets/images/icons/print.svg';
import printDocument from '../../../../utils/printDocument';

interface PrintPayslipButtonProps extends React.HTMLProps<HTMLButtonElement> {
  // eslint-disable-next-line
  payslips: any[]; // @todo: Replace any with Payslip interface when interface details are finalized
}

const PrintPayslipButton: React.FC<PrintPayslipButtonProps> = ({ payslips, ...props }) => {
  const handlePrint = () => {
    if (payslips.length === 0) {
      toast.error('No payslip to print');
      return;
    }

    const componentToPrint = (
      <div>
        {payslips.map((payslip, index) => (
          <React.Fragment key={payslip.id}>
            <PrintPayslip payslip={payslip} />
            {index < payslips.length - 1 && <div className="page-break" />}
          </React.Fragment>
        ))}
      </div>
    );

    printDocument({ documentTitle: 'Payslip', componentToPrint });
  };

  return (
    <button {...props} onClick={handlePrint} className="print-payslip__button " type="button">
      <Print className="h-4 w-4" />
      <span>Print Payslip</span>
    </button>
  );
};

export default PrintPayslipButton;
