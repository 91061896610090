import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { Form, Formik } from 'formik';
import { Typography } from '@material-tailwind/react';
import TextWithIcons from '../../molecules/TextWithIcons';
import SelectDepartment from '../../atoms/dropdowns/SelectDepartment';
import SelectLoanType from '../../atoms/dropdowns/SelectLoanType';
import SelectEmployee from '../../atoms/dropdowns/SelectEmployee';
import CustomRangeDatePicker from '../../atoms/datepickers/CustomRangeDatePicker';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import Department from '../../../store/constants/departments/department.interface';
import Loan from '../../../store/constants/loans/loan.interface';
import useLoans from '../../../hooks/loans/useLoans';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import LoanListRow from './LoanListRow';
import consoleLog from '../../../utils/consoleLog';
import LoanFormModal from './LoanFormModal';
import LoanType from '../../../store/constants/loans/loan-type.interface';
import LoanSaviiModal from './LoanSaviiModal';

const LoanList: React.FC = () => {
  const [selectedLoan, setSelectedLoan] = useState<Loan | undefined>();
  const [selectedLoanType, setSelectedLoanType] = useState<LoanType | undefined>();
  const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>();
  const [selectedUserEmployment, setSelectedUserEmployment] = useState<UserEmployment>();
  const [dateStart, setDateStart] = useState<Date | undefined>();
  const [dateEnd, setDateEnd] = useState<Date | undefined>();
  const { loansState, getAll, remove } = useLoans();

  const onEditClick = (loan: Loan) => {
    setSelectedLoan(loan);
  };

  const onDeleteClick = async (loan: Loan) => {
    remove(loan.id).then((r) => {
      consoleLog('DELETE LOAN', r);
    });
  };

  const onClose = () => setSelectedLoan(undefined);

  const itemSizes: {
    [key: number]: number;
  } = {};

  // @TODO: Add filter and sorting eg slice().filter().sort()
  const loans = loansState.loans
    .filter((loan: Loan) => {
      if (!loan) {
        return false;
      }
      return true;
    })
    .sort((a, b) => {
      if (a.dateGranted && b.dateGranted) {
        return a.dateGranted > b.dateGranted ? -1 : 1;
      }
      return 0;
    });

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  const handleSelectedUserEmploymentChange = (selectedValue: UserEmployment) => {
    setSelectedUserEmployment(selectedValue);
  };

  const handleDepartmentChange = (selectedValue: Department) => {
    setSelectedDepartment(selectedValue);
  };

  const handleLoanTypeChange = (selectedValue: LoanType) => {
    setSelectedLoanType(selectedValue);
  };

  useOnLoad(() => {
    getAll({
      departmentId: selectedDepartment?.id,
      loanTypeId: selectedLoanType?.id,
      employeeNumber: selectedUserEmployment?.employeeNumber,
    }).then();
  });

  return (
    <div className="w-full h-full">
      <div className="max-w-[100%]">
        <div className="flex justify-between items-center pb-5">
          <Formik initialValues={{ ...selectedLoan }} onSubmit={() => {}}>
            <Form className="flex justify-between w-full">
              <div className="flex justify-center items-center gap-10">
                <LoanFormModal
                  className="w-40 rounded-lg shadow-lg muiButton--primary"
                  loan={selectedLoan}
                  onClose={onClose}
                />
                <LoanSaviiModal />
              </div>
              <div className="flex w-8-percent gap-10">
                <div className="w-full space-y-2">
                  <Typography className="muiTypography--headline6">Department</Typography>
                  <SelectDepartment
                    id="team"
                    name="team"
                    placeholder="Select"
                    value={selectedDepartment}
                    onChange={(value) => handleDepartmentChange(value as Department)}
                    isSearchable
                    isClearable
                  />
                </div>
                <div className="w-full space-y-2">
                  <Typography className="muiTypography--headline6">Loan Type</Typography>
                  <SelectLoanType
                    id="loan_type"
                    name="loan_type"
                    placeholder="Select"
                    value={selectedLoanType}
                    onChange={(value) => handleLoanTypeChange(value as LoanType)}
                    isSearchable
                    isClearable
                  />
                </div>
                <div className="w-full space-y-2">
                  <Typography className="muiTypography--headline6">Date Range</Typography>
                  <CustomRangeDatePicker
                    id="range"
                    name="range"
                    className="rounded-lg field-layout loan-field-date"
                    placeholder="Select"
                    dateStart={dateStart}
                    setDateStart={setDateStart}
                    dateEnd={dateEnd}
                    setDateEnd={setDateEnd}
                  />
                </div>
                <div className="w-full space-y-2">
                  <Typography className="muiTypography--headline6">Employee Search</Typography>
                  <SelectEmployee
                    id="employee"
                    name="employee"
                    placeholder="Search"
                    value={selectedUserEmployment}
                    onChange={(value) =>
                      handleSelectedUserEmploymentChange(value as UserEmployment)
                    }
                    hasSearchIcon
                    isSearchable
                    isClearable
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="p-2 border-b-2 loan-list-header loan-header-items">
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Employee</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Department</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Loan Amount</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Date Granted</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Type</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Terms to Pay</div>
          </TextWithIcons>
          <TextWithIcons className="justify-self-center" variant="h6">
            <div className="flex gap-5">Action</div>
          </TextWithIcons>
        </div>

        <VariableSizeList
          itemCount={loans.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) =>
            loans[index] && (
              <LoanListRow
                data={loans[index]}
                className={`${index % 2 === 0 ? 'stripped' : ''}`}
                style={style}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            )
          }
        </VariableSizeList>
      </div>
    </div>
  );
};

export default LoanList;
