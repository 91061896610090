import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import User from '../../constants/users/user.interface';
import objectToFormData from '../../../utils/objectToFormData';

export interface GetAllUsersQueryParams extends ApiGetAllBaseQueryParams {
  positionCode?: string;
}

const get = async (id: User['id'], state: StateHeaders): Promise<ApiBaseResponse<User>> =>
  apiFetch<ApiBaseResponse<User>>(`users/${id}`, { state })
    .then((response: ApiBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getContact = async (
  id: User['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<AtLeast<User, 'contact' | 'address'>>> =>
  apiFetch<ApiBaseResponse<User>>(`users/${id}/contact`, { state })
    .then((response: ApiBaseResponse<AtLeast<User, 'contact' | 'address'>>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getEmployment = async (
  id: User['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<AtLeast<User, 'employment'>>> =>
  apiFetch<ApiBaseResponse<User>>(`users/${id}/employment`, { state })
    .then((response: ApiBaseResponse<AtLeast<User, 'employment'>>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getBank = async (
  id: User['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<AtLeast<User, 'bank'>>> =>
  apiFetch<ApiBaseResponse<User>>(`users/${id}/bank`, { state })
    .then((response: ApiBaseResponse<AtLeast<User, 'bank'>>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAccountNumber = async (
  id: User['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<AtLeast<User, 'accountNumber'>>> =>
  apiFetch<ApiBaseResponse<User>>(`users/${id}/accountNumber`, { state })
    .then((response: ApiBaseResponse<AtLeast<User, 'accountNumber'>>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getTeams = async (id: User['id'], state: StateHeaders): Promise<ApiBaseResponse<User>> =>
  apiFetch<ApiBaseResponse<User>>(`users/${id}/teams`, { state })
    .then((response: ApiBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllUsersQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<User>> =>
  apiFetch<ApiGetAllBaseResponse<User>>(
    `users?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllByUserEmploymentPosition = async (
  { positionCode, page = 1, limit = 50, search = '' }: GetAllUsersQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<User>> =>
  apiFetch<ApiGetAllBaseResponse<User>>(
    `users/employment-positions/${positionCode}?limit=${limit}&page=${page}${
      search !== '' ? `&search=${search}` : ''
    }`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<User, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<User> | void> =>
  apiFetch<ApiBaseResponse<User>>('users', {
    state,
    options: {
      method: 'POST',
      body: objectToFormData(data as unknown as Record<string, never>),
    },
  })
    .then((response: ApiBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (data: User, state: StateHeaders): Promise<ApiBaseResponse<User> | void> =>
  apiFetch<ApiBaseResponse<User>>(`users/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<User, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<User> | void> =>
  apiFetch<ApiBaseResponse<User>>(`users/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (id: User['id'], state: StateHeaders): Promise<ApiBaseResponse<User> | void> =>
  apiFetch<ApiBaseResponse<User>>(`users/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getContact,
  getEmployment,
  getBank,
  getAccountNumber,
  getAll,
  getTeams,
  getAllByUserEmploymentPosition,
  post,
  patch,
  put,
  remove,
} as const;
