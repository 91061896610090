import React, { HTMLAttributes } from 'react';

interface IconTextProps extends HTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  placeholder: string;
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputIconLeft: React.FC<IconTextProps> = ({
  icon = undefined,
  placeholder = undefined,
  className = undefined,
  value = undefined,
  onChange = () => {},
  ...props
}) => (
  <div className="relative">
    <div>
      <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        {icon}
      </span>
      <input
        {...props}
        value={value}
        onChange={onChange}
        type="text"
        className={className}
        placeholder={placeholder}
      />
    </div>
  </div>
);

export default InputIconLeft;
