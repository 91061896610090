import React, { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useUsers from '../../../../hooks/users/useUsers';
import consoleLog from '../../../../utils/consoleLog';
import User from '../../../../store/constants/users/user.interface';
import AtLeast from '../../../../types/atleast';
import toastNotification from '../../../../hooks/utils/toastNotification';
import { ReactComponent as PencilIcon } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/icons/check-solid.svg';

interface PayrollInfoProps {
  isLoading?: boolean;
  user: AtLeast<User, 'accountNumber' | 'bank'>;
}

const PayrollInfo: React.FC<PayrollInfoProps> = ({ isLoading = true, user }) => {
  const { put } = useUsers();

  const [showButtonAction, setShowButtonAction] = useState(false);
  const [showInputAction, setShowInputAction] = useState(false);

  const { accountNumber, bank } = user;

  const handleSubmit = async (data: AtLeast<User, 'accountNumber' | 'bank'>) => {
    const values = {
      ...data,
    };

    consoleLog('FORM VALUES', data);

    if (user.id) {
      await toastNotification({
        action: put(values as User),
        onSuccess: () => {
          setShowButtonAction(!showButtonAction);
          setShowInputAction(!showInputAction);
        },
        onError: () => {
          setShowButtonAction(!showButtonAction);
          setShowInputAction(!showInputAction);
        },
        toastPromiseMessages: {
          pending: `Updating payroll details for ${user.lastName} ${user.lastName}...`,
          success: `${user.lastName} ${user.lastName} is successfully updated.`,
          error: `Failed to update payroll details for ${user.lastName} ${user.lastName}! Try again.`,
        },
      });
    }
  };

  const onEditClick = () => {
    setShowButtonAction(!showButtonAction);
    setShowInputAction(!showInputAction);
  };

  const onCancel = () => {
    setShowButtonAction(false);
    setShowInputAction(false);
  };

  consoleLog('PAYROLL', user);

  return (
    <Formik
      initialValues={{
        accountNumber,
        bank,
        id: user.id,
      }}
      onSubmit={handleSubmit}
    >
      <Form className="rounded-xl shadow-xl user-update__form-wrapper">
        <div className="flex items-center mb-5">
          <div className="user-update__form-heading">
            <Typography variant="h4" className="user-update__form-title">
              Payroll
            </Typography>
            <span className="user-update__form-sub-title">Information</span>
          </div>
          {!isLoading && (
            <div className="ml-auto">
              {!showButtonAction ? (
                <Tooltip content="Edit">
                  <IconButton
                    onClick={onEditClick}
                    size="sm"
                    className="bg-primary-gray rounded-none"
                  >
                    <PencilIcon className="w-5 h-5 stroke-white" />
                  </IconButton>
                </Tooltip>
              ) : (
                <div className="flex flex-row-reverse gap-2">
                  <Tooltip content="Cancel">
                    <IconButton
                      onClick={onCancel}
                      size="sm"
                      className="bg-primary-gray rounded-none"
                    >
                      <CloseIcon className="w-5 h-5 stroke-red-900" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Save">
                    <IconButton type="submit" size="sm" className="bg-primary-gray rounded-none">
                      <CheckIcon className="w-5 h-5 stroke-green-800" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="accountNumber.sss"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              SSS
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="accountNumber.sss"
                  name="accountNumber.sss"
                  placeholder="SSS"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {accountNumber?.sss || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="accountNumber.sss" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="accountNumber.phic"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              PHIC
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="accountNumber.phic"
                  name="accountNumber.phic"
                  placeholder="PHIC"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {accountNumber?.phic || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="accountNumber.phic" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="accountNumber.tin"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              TIN
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="accountNumber.tin"
                  name="accountNumber.tin"
                  placeholder="TIN"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {accountNumber?.tin || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="accountNumber.tin" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="accountNumber.hdmf"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              HDMF
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="accountNumber.hdmf"
                  name="accountNumber.hdmf"
                  placeholder="HDMF"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {accountNumber?.hdmf || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="accountNumber.hdmf" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="accountNumber" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Account Number
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="bank.accountNumber"
                  name="bank.accountNumber"
                  placeholder="Account Number"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {bank?.accountNumber || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="bank.accountNumber" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="bank.accountName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Account Name
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="bank.accountName"
                  name="bank.accountName"
                  placeholder="Account Name"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {bank?.accountName || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="bank.accountName" component="div" />
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="bank.accountName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Position
            </label>
            <div className="w-full flex items-center">
              {showInputAction ? (
                <Field
                  type="text"
                  id="user.employment.currentPosition.employmentPosition.name"
                  name="user.employment.currentPosition.employmentPosition.name"
                  placeholder="Employment Position"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.currentPosition?.employmentPosition?.name || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage
              name="user.employment.currentPosition.employmentPosition.name"
              component="div"
            />
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="bank.accountName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Position Rate
            </label>
            <div className="w-full flex items-center">
              {showInputAction ? (
                <Field
                  type="text"
                  id="user.employment?.currentPosition?.employmentPositionRatesId?.gross"
                  name="user.employment?.currentPosition?.employmentPositionRatesId?.gross"
                  placeholder="Employment Position Rate"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.currentPosition?.employmentPositionRates?.gross || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage
              name="user.employment?.currentPosition?.employmentPositionRatesId?.gross"
              component="div"
            />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default PayrollInfo;
