import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { Dialog, DialogBody, IconButton } from '@material-tailwind/react';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import useOvertimes from '../../../../hooks/attendances/useOvertimes';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as Info } from '../../../../assets/images/icons/info.svg';

export interface OvertimeApproveFormModalProps {
  overtime: EmployeeOvertime;
  onClose: () => void;
}

interface OvertimeApproveFilterState {
  approveLoadingInternal: boolean;
  approveLoadingClient: boolean;
  removeApprove: boolean;
  open: boolean;
}

const OvertimeApproveModal: React.FC<OvertimeApproveFormModalProps> = ({
  overtime = undefined,
  onClose,
}) => {
  const { getEmployeeByEmployeeNumber } = useUserEmployments();
  const { approveEmployeeOvertime } = useOvertimes();

  const [overtimeApproveFilterState, setOvertimeApproveFilterState] =
    useState<OvertimeApproveFilterState>({
      approveLoadingInternal: false,
      approveLoadingClient: false,
      removeApprove: false,
      open: false,
    });

  const { approveLoadingInternal, approveLoadingClient, removeApprove, open } =
    overtimeApproveFilterState;

  const employeeName = overtime
    ? getEmployeeByEmployeeNumber(overtime.employeeNumber)?.userInformation.fullName
    : undefined;

  const updateState = (key: keyof OvertimeApproveFilterState, value: boolean) => {
    setOvertimeApproveFilterState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOpen = () => updateState('open', true);
  const handleClose = () => {
    updateState('open', false);
    onClose();
  };

  const handleSetLoadingState = (value: boolean, type?: string) => {
    if (type === 'client') {
      updateState('approveLoadingClient', value);
    } else if (type === 'internal') {
      updateState('approveLoadingInternal', value);
    } else {
      updateState('removeApprove', value);
    }
  };

  const handleApprove = async (values: Partial<EmployeeOvertime>, type?: string) => {
    handleSetLoadingState(true, type);

    const data = { ...values, type };

    const successMessage = values.approvedBy
      ? `The overtime of ${employeeName} was successfully reverted to pending.`
      : `The overtime of ${employeeName} was successfully approved.`;

    await toastNotification({
      action: approveEmployeeOvertime(data),
      onSuccess: () => {
        handleSetLoadingState(false, type);
        handleClose();
      },
      onError: (response) => {
        toast.error(response.payload.message.error);
      },
      onFulfilled: () => {
        handleSetLoadingState(false, type);
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing Approval...',
        success: successMessage,
      },
    });
  };

  useEffect(() => {
    if (overtime) {
      setOvertimeApproveFilterState((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  }, [overtime]);

  return (
    <Dialog
      size="sm"
      open={open}
      handler={handleOpen}
      className="shadow-none overtime-dialog__container overtime-content__approve"
    >
      <div className="absolute top-0 right-0 flex p-0 m-2">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      {overtime?.approvedBy ? (
        <div className="flex justify-center gap-2 pt-8 overtime-content__wrapper">
          <div className="flex flex-col gap-2">
            <div className="flex items-center pt-1 gap-2">
              <Info className="w-5 h-5 overtime-content__icon" />
              <span className="overtime-content__heading">OT was already approved!</span>
            </div>
            <div>
              <span className="flex justify-center overtime-content__title">Remove approval?</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center gap-2 pb-4 overtime-content__wrapper">
            <Info className="w-4 h-4 overtime-content__icon" />
            <h1 className="overtime-content__heading">Approve Overtime?</h1>
          </div>
          <div className="pt-2 flex justify-center overtime-content__wrapper">
            <span className="overtime-content__title">Overtime is paid by?</span>
          </div>
        </>
      )}
      <DialogBody divider className="p-0">
        <Formik initialValues={{ ...overtime }} onSubmit={() => {}}>
          <Form className="w-full">
            <div className="my-7">
              {overtime?.approvedBy ? (
                <div className="w-full flex justify-center">
                  <ButtonAction
                    type="button"
                    className="flex justify-center items-center p-2 rounded-md shadow-lg overtime-content__button"
                    isLoading={removeApprove}
                    hasSpinnerText={false}
                    onClick={() => handleApprove(overtime as EmployeeOvertime)}
                    isSubmit
                  />
                </div>
              ) : (
                <div className="flex justify-between px-2">
                  <ButtonAction
                    type="button"
                    className="flex justify-center items-center p-2 rounded-md shadow-lg overtime-content__button"
                    isLoading={approveLoadingClient}
                    hasSpinnerText={false}
                    text="Client"
                    onClick={() => handleApprove(overtime as EmployeeOvertime, 'client')}
                    isSubmit
                  />
                  <ButtonAction
                    type="button"
                    className="flex justify-center items-center p-2 rounded-md shadow-lg overtime-content__button"
                    isLoading={approveLoadingInternal}
                    hasSpinnerText={false}
                    text="Internal"
                    onClick={() => handleApprove(overtime as EmployeeOvertime, 'internal')}
                    isSubmit
                  />
                </div>
              )}
            </div>
          </Form>
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export default OvertimeApproveModal;
