import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Adjustments } from '../../../../../assets/images/icons/adjustments.svg';
import { ReactComponent as Calendar } from '../../../../../assets/images/icons/calendar.svg';
import { ReactComponent as PlusFolder } from '../../../../../assets/images/icons/plus-folder.svg';
import { ReactComponent as Save } from '../../../../../assets/images/icons/save.svg';
import { ReactComponent as No } from '../../../../../assets/images/icons/no-symbol.svg';
import { ReactComponent as ArrowRight } from '../../../../../assets/images/icons/arrow-right-single.svg';
import { EmploymentPayroll } from '../../../../../store/reducers/payroll/payrollsReducer';
import PayrollTableContextMenuPayroll from './PayrollTableContextMenuPayroll';

interface PayrollTableContextMenuProps {
  handleMenuClose: () => void;
  record?: Partial<EmploymentPayroll>;
}

const menuData = [
  {
    name: 'Salary Adjustments',
    icon: <Adjustments className="w-4 h-4 text-[#f2f2f2]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Attendance',
    icon: <Calendar className="w-4 h-4 text-[#f2f2f2]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Leave',
    icon: <Calendar className="w-4 h-4 text-[#f2f2f2]" />,
    linkTo: '/admin/leaves',
  },
  {
    name: 'HMO Payment Plan',
    icon: <Calendar className="w-4 h-4 text-[#f2f2f2]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Save Dispute',
    icon: <Save className="w-4 h-4 text-[#f2f2f2]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Generate ITR',
    icon: <PlusFolder className="w-4 h-4 text-[#f2f2f2]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Manage Holidays',
    icon: <Calendar className="w-4 h-4 text-[#f2f2f2]" />,
    linkTo: '/payroll/',
  },
];

const menuDataSecond = [
  {
    name: 'Remove Taxable Contribution',
    icon: <No className="w-4 h-4 text-[#f05825]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Remove Tax Deduction',
    icon: <No className="w-4 h-4 text-[#f05825]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Remove HMO Principal',
    icon: <No className="w-4 h-4 text-[#f05825]" />,
    linkTo: '/payroll/',
  },
  {
    name: 'Remove HMO Dependent',
    icon: <No className="w-4 h-4 text-[#f05825]" />,
    linkTo: '/payroll/',
  },
];

const PayrollTableContextMenu: React.FC<PayrollTableContextMenuProps> = ({
  handleMenuClose,
  record = undefined,
}) => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#343434] rounded-lg rounded-tl-sm shadow-lg w-80 border border-solid border-[#383838]">
      <span className="">{record?.name}</span>
      <ul className="flex flex-col gap-1 p-2">
        <li>
          <button type="button" className="w-full">
            <li className="hover:bg-[#2c2b2a] flex items-center place-content-start w-full gap-3 p-2 rounded-lg">
              {/* <Money className="w-4 h-4 text-[#f2f2f2]" />
              <span className="text-xs text-white">Payroll Menu</span> */}
              <PayrollTableContextMenuPayroll />
              <ArrowRight className="w-4 h-4 text-[#f2f2f2] ml-auto" />
            </li>
          </button>
        </li>
        {menuData.map((item) => (
          <li key={item.name}>
            <button
              type="button"
              onClick={() => {
                handleMenuClose();
                navigate(item.linkTo, { state: { record } });
              }}
              className="w-full"
            >
              <li className="hover:bg-[#2c2b2a] flex items-center place-content-start w-full gap-3 p-2 rounded-lg">
                {item.icon}
                <span className="text-xs text-white">{item.name}</span>
              </li>
            </button>
            {/* <hr className="rounded-md h-0.2 opacity-10" /> */}
          </li>
        ))}
        <hr className="rounded-md h-0.2 opacity-40 border border-solid border-[#f05825]" />
        {menuDataSecond.map((item) => (
          <li>
            <button type="button" onClick={handleMenuClose} className="w-full">
              <li className="hover:bg-[#2c2b2a] flex items-center place-content-start w-full gap-3 p-2 rounded-lg">
                {item.icon}
                <span className="text-[#f05825] text-xs">{item.name}</span>
              </li>
            </button>
            {/* <hr className="rounded-md h-0.2 opacity-10" /> */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PayrollTableContextMenu;
