import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import Holiday from '../../../store/constants/holidays/holiday.interface';
import useDateTime from '../../../hooks/utils/useDateTime';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';
import EditSvg from '../../../assets/images/icons/edit.svg';

export interface HolidayListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Holiday;
  onEditClick: (holiday: Holiday) => void;
  onDeleteClick: (holiday: Holiday) => Promise<unknown>;
}

const HolidayListRow: React.FC<HolidayListRowProps> = ({
  className = '',
  data,
  onEditClick,
  onDeleteClick,
  ...props
}: HolidayListRowProps) => {
  const { getDateAsMoment } = useDateTime();
  const dateCelebrated = getDateAsMoment(data?.dateCelebrated);

  const deleteMessage = {
    pending: 'Pending',
    success: `Holiday ${data.name} is successfully deleted.`,
    error: 'Error on deleting Holiday.',
  };

  return (
    <div
      {...props}
      className={`${className} user-holiday-list-data table-list-data flex items-center`}
    >
      <div>{data.name}</div>
      <div>{dateCelebrated?.format('MMMM DD, YYYY')}</div>
      <div>{dateCelebrated?.format('dddd')}</div>
      <div>{data.holidayType?.name}</div>

      <div className="space-x-6 action-buttons justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <img className="edit-data-icon" src={EditSvg} alt="Edit Holiday" />
          </button>
        </Tooltip>

        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default HolidayListRow;
