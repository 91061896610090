import React, { useState } from 'react';
import ImageNavigationModal from './ImageNavigationModal';
import FileUploadDisplayImage from './FileUploadDisplayImage';

interface FileDisplayWithModalProps {
  files: File[];
  onDeleteFile: (fileName: string) => void;
}

const FileDisplayWithModal: React.FC<FileDisplayWithModalProps> = ({ files, onDeleteFile }) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  const imageFiles = files.filter((file) => file.type.startsWith('image/'));
  const openImageModal = (index: number) => {
    setSelectedImageIndex(index);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  return (
    <>
      {/* Display Files */}
      <FileUploadDisplayImage
        files={files}
        onDeleteFile={onDeleteFile}
        openImageModal={openImageModal}
      />

      {/* Modal for Image Viewing & navigation */}
      {imageFiles.length > 0 && (
        <ImageNavigationModal
          isOpen={isImageModalOpen}
          onClose={closeImageModal}
          imageFiles={imageFiles}
          selectedImageIndex={selectedImageIndex}
        />
      )}
    </>
  );
};

export default FileDisplayWithModal;
