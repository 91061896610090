import { useAppDispatch, useAppSelector } from '../../store/store';
import employmentTypeActions from '../../store/actions/employments/employmentTypeActions';

import {
  employmentTypesReducerActions,
  EmploymentTypesState,
} from '../../store/reducers/employments/employmentTypesReducer';
import EmploymentType from '../../store/constants/employments/employment-type.interface';

const useEmploymentTypes = () => {
  const dispatch = useAppDispatch();
  const employmentTypesState: EmploymentTypesState = useAppSelector(
    (state) => state.employmentTypes,
  );

  const resetEmploymentRolesState = () => {
    dispatch(employmentTypesReducerActions.resetEmploymentTypesState());
  };

  const setEmploymentRolesState = (state: Partial<EmploymentTypesState>) => {
    dispatch(employmentTypesReducerActions.setEmploymentTypesState(state));
  };

  const get = async (employmentTypeId: EmploymentType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentTypeActions.get(employmentTypeId));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentTypeActions.getAll({}));

  const post = async (employmentTypeData: Omit<EmploymentType, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentTypeActions.post(employmentTypeData));

  const put = async (employmentType: Partial<EmploymentType>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentTypeActions.put(employmentType));

  const remove = async (id: EmploymentType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentTypeActions.remove(id));

  return {
    resetEmploymentRolesState,
    employmentTypesState,
    setEmploymentRolesState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useEmploymentTypes;
