import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import toastNotification from '../../../../../hooks/utils/toastNotification';
import EmployeeTimeOutDetail from '../../../../../store/constants/employees/attendances/employee-Timeout-detail.interface';
import ButtonAction from '../../../../atoms/buttons/ButtonAction';
import { TimeRemaining } from '../../../../../hooks/utils/useTimeRemaining';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import formatTimeDuration from '../../../../../utils/formatTimeDuration';
import dateToDateString from '../../../../../hooks/utils/dateToDateString';
import useSchedules from '../../../../../hooks/schedule/useSchedules';
import useTimeDifference from '../../../../../hooks/utils/useTimeDifference';
import UserOvertimeModal from '../userOvertime/UserOvertimeModal';
import UserAttendanceModal from '../UserAttendanceModal';
import useTheme from '../../../../../hooks/utils/useTheme';

interface UnderTimeProps {
  handleOnClose?: () => void;
  timeRemaining?: TimeRemaining;
}

export interface UndertimeFormValues {
  id?: number;
  reason: string;
  category: string;
}

const UserUndertimeModal: React.FC<UnderTimeProps> = ({
  handleOnClose = () => {},
  timeRemaining = undefined,
}) => {
  const { theme } = useTheme();
  const { timeOut, getMyTimeInDetail } = useAttendance();
  const { schedulesState, getCurrentSchedule } = useSchedules();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUndertimeForm, setShowUndertimeForm] = useState<boolean>(false);
  const [showOvertime, setShowOvertime] = useState<boolean>(false);

  const initialValues: EmployeeTimeOutDetail = {
    id: 0,
    reason: '',
    category: '',
  };

  const advanceTimeDiff = useTimeDifference(
    schedulesState.currentEmployeeSchedule?.timeIn,
    dateToDateString(new Date()).time,
  );

  const handleSubmit = async (values: EmployeeTimeOutDetail) => {
    setIsLoading(true);
    /*
    @To do accept category
  */

    await toastNotification({
      action: timeOut(values),
      onSuccess: () => {
        handleOnClose();
      },
      onError: () => {
        // setFormError(response.payload.message);
      },
      onFulfilled: () => {
        // setIsLoading(false);
        setIsLoading(false);
      },
      toastPromiseMessages: {
        pending: 'Processing...',
        success: 'Undertime submitted.',
        error: 'Error submitting undertime.',
      },
    });
  };

  const handleOvertimeClick = () => {
    setShowOvertime(!showOvertime);
  };

  useOnLoad(() => {
    getCurrentSchedule({});
  });

  return (
    <UserAttendanceModal
      heading={
        <>
          <h1 className={`${theme}`}>
            You are currently{' '}
            <span
              className={`${
                getMyTimeInDetail?.isLate
                  ? 'dialog-prompt-sub-header__text-late'
                  : 'dialog-prompt-sub-header__text-ontime'
              }`}
            >
              Timed in
            </span>
            .
          </h1>
          <h1>
            {`${formatTimeDuration({
              hours: timeRemaining?.hours,
              minutes: timeRemaining?.minutes,
              seconds: timeRemaining?.seconds,
            })}`}{' '}
            until end of shift.
          </h1>
        </>
      }
    >
      {showUndertimeForm ? (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="undertime-reason__field">
              <label htmlFor="reason" className={`field__label ${theme}`}>
                Undertime Reason
              </label>
              <Field
                as="textarea"
                id="reason"
                name="reason"
                placeholder="Reason for under time..."
                className={`undertime-reason__field-input ${theme}`}
              />
              <ErrorMessage name="reason" component="div" />
            </div>
            <div className="undertime-category__field">
              <label htmlFor="category" className={`field__label ${theme}`}>
                Category
              </label>
              <Field
                as="select"
                id="category"
                name="category"
                className={`undertime-category__field-input ${theme}`}
              >
                <option value="" disabled>
                  Select Category
                </option>
                <option value="medicalReason">Medical Reasons</option>
                <option value="personalReason">Personal Reasons</option>
              </Field>
              <ErrorMessage name="category" component="div" />
            </div>
            <div className="action-button-container">
              <ButtonAction
                type="button"
                className="action-button__submit"
                isLoading={isLoading}
                disabled={isLoading}
                hasSpinnerText={false}
                isSubmit
              />
              <button onClick={handleOnClose} type="button" className="action-button__cancel">
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      ) : (
        <div className="flex flex-col items-center justify-center gap-3">
          {!showOvertime && (
            <ButtonAction
              className="action-button__submit"
              text="Undertime"
              onClick={() => setShowUndertimeForm(true)}
            />
          )}

          {advanceTimeDiff && advanceTimeDiff.hours < 3 && (
            <div className="flex gap-1">
              <h1>{`You are ${advanceTimeDiff.hours ? `${advanceTimeDiff.hours}h` : ''} ${
                advanceTimeDiff.minutes ? `${advanceTimeDiff.minutes}m` : ''
              } early of your shift.`}</h1>
              <button onClick={handleOvertimeClick} type="button" className="textButton">
                Overtime?
              </button>
            </div>
          )}
        </div>
      )}
      {showOvertime && (
        <UserOvertimeModal
          handleOnClose={() => setShowOvertime(false)}
          advanceOtTimeDiff={advanceTimeDiff}
        />
      )}
    </UserAttendanceModal>
  );
};

export default UserUndertimeModal;
