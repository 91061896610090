import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUserForm from '../../../hooks/users/useUserForm';

const PreviousButton: React.FC = () => {
  const navigate = useNavigate();
  const { resetUserFormState } = useUserForm();

  const handleBack = () => {
    resetUserFormState();
    navigate('/admin/user/manage');
  };

  return (
    <button type="submit" onClick={handleBack} className="muiButton--primary rounded-lg shadow-lg">
      Previous
    </button>
  );
};

export default PreviousButton;
