import { useState } from 'react';
import useDepartments from './useDepartments';
import useOnLoad from '../utils/useOnLoad';

const useDepartment = (id: string) => {
  const {
    get,
    departmentsState: { departments },
  } = useDepartments();
  const [loading, setLoading] = useState(true);

  const departmentId = parseInt(id, 10);
  const currentDepartment = departments.find((department) => department.id === departmentId);

  useOnLoad(() => {
    if (currentDepartment) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    get(departmentId).then(() => {
      setLoading(false);
    });
  });

  return {
    loading,
    currentDepartment,
  };
};

export default useDepartment;
