import React from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';
import { ReactComponent as QuestionMarkIcon } from '../../assets/images/icons/question-mark-circle.svg';

interface HelperMessageProps {
  contentTitle?: string;
  content: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  className?: string;
}

const HelperMessage: React.FC<HelperMessageProps> = ({
  contentTitle = undefined,
  content,
  placement = 'top',
  className = undefined,
}) => (
  <Tooltip
    content={
      <div className={`${className} w-80`}>
        {contentTitle && (
          <Typography variant="h6" color="white" className="font-bold">
            {contentTitle}
          </Typography>
        )}
        <Typography variant="small" color="white" className="font-normal opacity-80">
          {content}
        </Typography>
      </div>
    }
    className="tooltip-content shadow-lg"
    placement={placement}
  >
    <QuestionMarkIcon className="w-5 h-auto tooltip-icon" />
  </Tooltip>
);

export default HelperMessage;
