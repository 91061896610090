import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import ResultDocumentActions from '../../../actions/my/transactionFeedActions';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../../utils/consoleLog';
import upsertData from '../../../../utils/upsertData';
import ResultDocumentType from '../../../constants/my/healthTracker/result-document';

export interface ResultDocumentsState {
  resultDocuments: ResultDocumentType[];
}

const initialState: ResultDocumentsState = {
  resultDocuments: [
    {
      id: 1,
      dateOfResult: new Date('2023-01-12'),
      type: 'Annual Physical Exam',
      description:
        'CHEST X-RAY-DEXTRO | CBC-NORMAL | URINALYSIS-NORMAL | FECALYSIS-NORMAL | DIAGNOSIS B-ORTHO CLEARANCE',
    },
  ],
};

const getResultDocumentData = (resultDocument: Partial<ResultDocumentType>) => ({
  ...resultDocument,
});

const resultDocumentsSlice = createSlice({
  name: 'resultDocuments',
  initialState,
  reducers: {
    resetResultDocumentsState: () => ({
      ...initialState,
    }),
    setResultDocumentsState: (state, action: PayloadAction<Partial<ResultDocumentsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertResultDocument: (state, action: PayloadAction<Partial<ResultDocumentType>>) => {
      const data: ResultDocumentType = getResultDocumentData(action.payload) as ResultDocumentType;

      if (typeof data.id === 'undefined') return;

      state.resultDocuments = upsertData(state.resultDocuments, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ResultDocumentActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<ResultDocumentType>;
      consoleLog('RESULT_DOCUMENT REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.resultDocuments = upsertData(state.resultDocuments, data);
      }
    });
    builder.addCase(ResultDocumentActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('RESULT_DOCUMENT REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<ResultDocumentType>;

      if (status === 503) return;

      if (data) {
        state.resultDocuments = data;
      }
    });
    builder.addCase(ResultDocumentActions.get.fulfilled, (state, { payload }) => {
      consoleLog('RESULT_DOCUMENT REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<ResultDocumentType>;

      if (typeof data.id === 'undefined') return;
      state.resultDocuments = upsertData(state.resultDocuments, data);
    });
    builder.addCase(ResultDocumentActions.put.fulfilled, (state, { payload }) => {
      consoleLog('RESULT_DOCUMENT REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<ResultDocumentType>;

      if (typeof data.id === 'undefined') return;
      state.resultDocuments = upsertData(state.resultDocuments, data);
    });
    builder.addCase(ResultDocumentActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('RESULT_DOCUMENT REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<ResultDocumentType>;

      if (typeof data.id === 'undefined') return;
      state.resultDocuments = state.resultDocuments.filter(
        (resultDocument) => resultDocument.id !== data.id,
      );
    });
  },
});

export const resultDocumentsReducerActions = resultDocumentsSlice.actions;
export default resultDocumentsSlice.reducer;
