import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, Tooltip } from '@material-tailwind/react';
import useDateTime from '../../../../hooks/utils/useDateTime';
import useHmoDependents from '../../../../hooks/my/useHmoDependents';
import useIncentives from '../../../../hooks/my/useIncentives';
import useHmoPrincipals from '../../../../hooks/my/useHmoPrincipal';
import { ReactComponent as DownArrowTail } from '../../../../assets/images/icons/arrow-with-tail-down.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/images/icons/question-mark-circle.svg';

const UserPayroll = () => {
  const { getDate } = useDateTime();
  const { hmoDependentsState } = useHmoDependents();
  const { incentivesState } = useIncentives();
  const { hmoPrincipalsState } = useHmoPrincipals();

  const [hmoPrincapal, setHmoPrincapal] = useState(true);
  const [hmoDependent, setHmoDependent] = useState(true);
  const [incentives, setIncentives] = useState(true);

  return (
    <div className="user-tab-container">
      <div className="px-5 user-payroll-container">
        <h1 className="mb-8 flex user-profile-tab-title">UserPayroll</h1>
        {/* whole content */}
        <div className="w-full h-full user-payroll-wrapper">
          {/* first container */}
          <div className="w-full h-full gap-8 flex mb-6">
            {/* Compensation Details */}
            <div className="first-payroll-wrapper">
              <h2 className="user-payroll-title">Compensation Details</h2>
              <div className="payroll-compensation-container">
                <div className="flex justify-between payroll-body payroll-first-field">
                  <h3>Gross Income</h3>
                  <span>16454</span>
                </div>
                <div className="flex justify-between payroll-body payroll-second-field">
                  <h3>Basic Pay</h3>
                  <span>12000</span>
                </div>
                <div className="flex justify-between payroll-body payroll-first-field">
                  <h3>Attendance Incentive</h3>
                  <span>34545.00</span>
                </div>
              </div>
            </div>

            {/* bank account number */}
            <div className="second-payroll-wrapper">
              <h2 className="user-payroll-title">Bank Account</h2>
              <div className="flex flex-col user-bank-wrapper">
                <div className="flex gap-2 items-center py-4">
                  <span>Number: </span>
                  <span className="">10236523031126</span>
                </div>
                <span className="italic pb-1 payroll-notif">
                  All rates are monthly and will be processed every payroll unless specified
                </span>
              </div>
            </div>
          </div>

          {/* <hr className="w-full mx-auto mb-4 rounded-md border-1 opacity-10" /> */}
          <hr className="w-full mx-auto mb-4 rounded-md border-1 opacity-10" />

          {/* HMO Dependent */}
          <Accordion
            open={hmoDependent}
            icon={
              <DownArrowTail
                className={`w-5 h-5 transition-transform user-accordion-icon ${
                  hmoDependent ? 'rotate-180' : ''
                }`}
              />
            }
          >
            <div className="h-full mb-6">
              <AccordionHeader
                onClick={() => setHmoDependent(!hmoDependent)}
                className="border-none py-1 px-2 user-accordion-header "
              >
                <h2 className="accordion-header-title">HMO Dependent</h2>
              </AccordionHeader>
              <AccordionBody className="p-2">
                <div className="h-full">
                  {/* header */}
                  <div className="hmodependent-header-wrapper accordion-header-second">
                    <h3>Account Number</h3>
                    <h3>Plan</h3>
                    <h3>Full Name</h3>
                    <h3>Premium Class</h3>
                    <h3>Expiry</h3>
                    <h3>Classification</h3>
                  </div>
                  {/* list */}
                  <div className="user-payroll-accordion-wrapper no-scrollbars">
                    {hmoDependentsState.hmoDependents.map((data, index) => (
                      <div
                        className={`${
                          index % 2 === 0 ? 'stripped' : ''
                        } hmodependent-list-wrapper accordion-list-second`}
                        key={data.id}
                      >
                        <span>{data.accountNumber}</span>
                        <span>{data.plan}</span>
                        <span>{data.fullName}</span>
                        <span>{data.premiumClass}</span>
                        <span>{getDate(data.expiry)}</span>
                        <span>{data.classification}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </AccordionBody>
            </div>
          </Accordion>

          <hr className="w-full mx-auto mb-4 rounded-md border-1 opacity-10" />

          {/* Incentives */}
          <div className="w-full incentives-first-container">
            <Accordion
              open={incentives}
              icon={
                <DownArrowTail
                  className={`w-5 h-5 transition-transform user-accordion-icon ${
                    incentives ? 'rotate-180' : ''
                  }`}
                />
              }
            >
              <div className="h-full mb-6">
                <AccordionHeader
                  onClick={() => setIncentives(!incentives)}
                  className="border-none py-1 px-2 user-accordion-header "
                >
                  <h2 className="accordion-header-title">Incentives</h2>
                </AccordionHeader>
                <AccordionBody className="p-2">
                  <div className="h-full">
                    {/* header */}
                    <div className="incentives-header-wrapper accordion-header-second">
                      <h3>Incentive</h3>
                      <h3>Amount</h3>
                      <h3>Amount Type</h3>
                      <h3>Effective Date</h3>
                      <h3>End Date</h3>
                      <h3>Status</h3>
                      <h3 className="flex justify-center">Pay Period</h3>
                    </div>
                    {/* list */}
                    <div className="user-payroll-accordion-wrapper no-scrollbars">
                      {incentivesState.incentives.map((data, index) => (
                        <div
                          className={`${
                            index % 2 === 0 ? 'stripped' : ''
                          } incentives-list-wrapper accordion-list-second`}
                          key={data.id}
                        >
                          <span>{data.incentive}</span>
                          <span>{data.amount}</span>
                          <span>{data.amountType}</span>
                          <span>{getDate(data.effectiveDate)}</span>
                          <span>{getDate(data.endDate)}</span>
                          <span>{data.status}</span>
                          <span className="flex justify-center">{data.payPeriod}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </AccordionBody>
              </div>
            </Accordion>
          </div>

          <hr className="w-full mx-auto mb-4 rounded-md border-1 opacity-10" />

          <div className="w-full flex gap-4">
            {/* HMO Principal */}
            <div className="hmo-principal-first-container">
              <Accordion
                open={hmoPrincapal}
                icon={
                  <DownArrowTail
                    className={`w-5 h-5 transition-transform user-accordion-icon ${
                      hmoPrincapal ? 'rotate-180' : ''
                    }`}
                  />
                }
              >
                <div className="h-full mb-6">
                  <AccordionHeader
                    onClick={() => setHmoPrincapal(!hmoPrincapal)}
                    className="border-none py-1 px-2 user-accordion-header "
                  >
                    <h2 className="accordion-header-title">HMO Principal</h2>
                  </AccordionHeader>
                  <AccordionBody className="p-2">
                    <div className="h-full">
                      {/* header */}
                      <div className="hmo-header-user-wrapper accordion-header-second">
                        <h3>Account Number</h3>
                        <h3>Plan</h3>
                        <h3>Premium Class</h3>
                        <h3>Plan</h3>
                      </div>
                      {/* list */}
                      <div className="user-payroll-accordion-wrapper no-scrollbars">
                        {hmoPrincipalsState.hmoPrincipals.map((data, index) => (
                          <div
                            className={`${
                              index % 2 === 0 ? 'stripped' : ''
                            } hmo-list-user-wrapper accordion-list-second`}
                            key={data.id}
                          >
                            <span>{data.accountNumber}</span>
                            <span>{data.plan}</span>
                            <span>{data.premiumClass}</span>
                            <span>{data.principal}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionBody>
                </div>
              </Accordion>
            </div>

            {/* Loans */}
            <div className="loan-second-container">
              <div className="relative">
                <h2 className="user-payroll-title">Loans</h2>
                <div className="absolute top-[0.3rem] left-[3.4rem]">
                  <Tooltip
                    className="shadow-md loan-tooltip-body"
                    placement="right-start"
                    content={
                      <div className="w-80">
                        <span className="text-xs">
                          Loans are pre computed per cut off deduction amount already
                        </span>
                      </div>
                    }
                  >
                    <QuestionMarkIcon className="w-5 h-auto cursor-pointer loan-icon" />
                  </Tooltip>
                </div>
              </div>
              <div className="payroll-compensation-container">
                <div className="flex justify-between payroll-body payroll-first-field">
                  <h3>SUN GOD NIKA</h3>
                  <span>2543</span>
                </div>
                <div className="flex justify-between payroll-body payroll-second-field">
                  <h3>PAGIBIG Loan</h3>
                  <span>793.83</span>
                </div>
                <div className="flex justify-between payroll-body payroll-first-field">
                  <h3>SSS Salary Loan</h3>
                  <span>1212.14</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPayroll;
