import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Hmo from '../../constants/hmo/hmo.interface';
import HmoActions from '../../actions/hmo/hmoActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface HmoState {
  hmo: Hmo[];
}

const initialState: HmoState = {
  hmo: [],
};

const getHmoData = (hmo: Partial<Hmo>) => ({
  ...hmo,
});

const upsertItems = (hmo: Hmo[], ...data: Hmo[]) => {
  const dataObject: { [id: number]: Hmo } = {};
  hmo.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((value) => {
    if (!dataObject[value.id]) {
      dataObject[value.id] = value;
    } else if (dataObject[value.id]) {
      dataObject[value.id] = { ...dataObject[value.id], ...value };
    }
  });

  return Object.values(dataObject);
};

const hmoSlice = createSlice({
  name: 'hmo',
  initialState,
  reducers: {
    resetHmoState: () => ({
      ...initialState,
    }),
    setHmoState: (state, action: PayloadAction<Partial<HmoState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertHmo: (state, action: PayloadAction<Partial<Hmo>>) => {
      const data: Hmo = getHmoData(action.payload) as Hmo;

      if (typeof data.id === 'undefined') return;

      state.hmo = upsertItems(state.hmo, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HmoActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Hmo>;
      consoleLog('HMO REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.hmo = upsertItems(state.hmo, data);
      }
    });
    builder.addCase(HmoActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('HMO REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Hmo>;

      if (status === 503) return;

      if (data) {
        state.hmo = data;
      }
    });
    builder.addCase(HmoActions.get.fulfilled, (state, { payload }) => {
      consoleLog('HMO REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Hmo>;

      if (typeof data.id === 'undefined') return;
      state.hmo = upsertItems(state.hmo, data);
    });
    builder.addCase(HmoActions.put.fulfilled, (state, { payload }) => {
      consoleLog('HMO REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<Hmo>;

      if (typeof data.id === 'undefined') return;
      state.hmo = upsertItems(state.hmo, data);
    });
    builder.addCase(HmoActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('HMO REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Hmo>;

      if (typeof data.id === 'undefined') return;
      state.hmo = state.hmo.filter((hmo) => hmo.id !== data.id);
    });

    builder.addCase(HmoActions.reports.fulfilled, (state, action) => {
      const blob = action.payload;

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'HMO REPORT.xlsx';
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      a.remove();
    });
  },
});

export const hmoReducerActions = hmoSlice.actions;
export default hmoSlice.reducer;
