import React from 'react';
import { Typography } from '@material-tailwind/react';
import ReferralDataTable from './tables/ReferralDataTable';

const ReferralDataPage: React.FC = () => (
  <div className="w-full list-of-reports mt-5">
    <div className="title">
      <Typography variant="h3" className="text-[#f05825] ml-0 mt-2 mb-5">
        Referral Data Summary
      </Typography>
    </div>
    <div className="w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg">
      <div className="p-3 w-full bg-[#f05825] rounded-t-lg">
        <span className="text-gray-200 font-bold">Referral Data Table</span>
      </div>
      <div className="p-5">
        <div className="flex w-[60%]">
          <div className="flex gap-2 items-center">
            <label htmlFor="entries" className="text-gray-200 text-xs">
              Show
            </label>
            <input
              type="text"
              className="bg-[#343434] w-14 rounded-md border border-solid border-[#434343] px-2"
            />
            <label htmlFor="entries" className="text-gray-200 text-xs">
              Entries
            </label>
          </div>
          <div className="flex gap-2 items-center ml-auto">
            <label htmlFor="search" className="text-gray-200 text-xs">
              Search:
            </label>
            <input
              type="text"
              className="bg-[#343434] w-60 rounded-md border border-solid border-[#434343] px-2"
            />
          </div>
        </div>
        <div className="table p-2 mt-5">
          <ReferralDataTable />
        </div>
      </div>
    </div>
  </div>
);

export default ReferralDataPage;
