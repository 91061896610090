import React from 'react';
import { Typography } from '@material-tailwind/react';
// import SalaryLookupTable from './SalaryLookupTable';

const SalaryLookupManagement = () => (
  <div className="w-full flex">
    <div className="flex flex-col w-full">
      <div className="m-2">
        <div className="title">
          <Typography variant="h3" className="text-[#f05825] flex gap-2 management-heading">
            <Typography variant="h3" className="text-gray-200">
              Employee Dependent HMO
            </Typography>
            Table
          </Typography>
        </div>
      </div>

      <div className="w-[95%] border border-solid border-[#343434] rounded-lg shadow-lg p-3">
        <div className="flex gap-5">
          <div className="w-full border border-solid border-[#343434] rounded-lg shadow-lg">
            <div className="p-3 w-full bg-[#343434] rounded-t-lg">
              <span className="text-gray-200 font-bold">Filters</span>
            </div>
            <div className="p-5 flex flex-col gap-5">
              <div className="flex gap-5 items-center">
                <div className="w-[10%]">
                  <label htmlFor="entries" className="text-gray-200 text-xs w-full">
                    Department
                  </label>
                </div>
                <select className="bg-[#343434] w-full rounded-md border border-solid border-[#434343] p-1 text-gray-400 text-sm">
                  <option value="">-- Select --</option>
                </select>
              </div>
              <div className="flex gap-5 items-center">
                <div className="w-[10%]">
                  <label htmlFor="entries" className="text-gray-200 text-xs w-full">
                    Client
                  </label>
                </div>
                <select className="bg-[#343434] w-full rounded-md border border-solid border-[#434343] p-1 text-gray-400 text-sm">
                  <option value="">-- Select --</option>
                </select>
              </div>
              <div className="flex gap-5 items-center">
                <div className="w-[10%]">
                  <label htmlFor="entries" className="text-gray-200 text-xs w-full">
                    Team
                  </label>
                </div>
                <select className="bg-[#343434] w-full rounded-md border border-solid border-[#434343] p-1 text-gray-400 text-sm">
                  <option value="">-- Select --</option>
                </select>
              </div>
            </div>
          </div>
          <div className="w-full border border-solid border-[#343434] rounded-lg shadow-lg">
            <div className="p-3 w-full bg-[#343434] rounded-t-lg">
              <span className="text-gray-200 font-bold">Search</span>
            </div>
            <div className="p-5">
              <div className="ml-auto flex gap-5 items-center">
                <label htmlFor="entries" className="text-gray-200 text-xs">
                  Employee
                </label>
                <input
                  type="text"
                  className="bg-[#343434] w-full rounded-md border border-solid border-[#434343] px-2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full border border-solid border-[#343434] rounded-lg shadow-lg mt-5">
          <div className="p-3 w-full bg-[#343434] rounded-t-lg">
            <span className="text-gray-200 font-bold">Result Table</span>
          </div>
          <div className="p-5">
            {/* <SalaryLookupTable /> */}
            <span className="text-lg text-white">* TABLE HERE *</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SalaryLookupManagement;
