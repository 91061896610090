import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import LeaveApplication from '../../constants/leaves/leave-application.interface';

export type GetAllLeavesQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: LeaveApplication['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication>> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`my/leaves/applications/${id}`, { state })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllLeavesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<LeaveApplication>> =>
  apiFetch<ApiGetAllBaseResponse<LeaveApplication>>(
    `my/leaves/applications?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<LeaveApplication, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>('my/leaves/applications', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: LeaveApplication,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`my/leaves/applications/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<LeaveApplication, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`my/leaves/applications/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: LeaveApplication['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`my/leaves/applications/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const uploadAttachments = async (
  formData: FormData,
  state: StateHeaders,
  leaveId: number,
): Promise<ApiBaseResponse<LeaveApplication>> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`my/leaves/upload/attachments/${leaveId}`, {
    state,
    options: {
      method: 'POST',
      body: formData,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  uploadAttachments,
} as const;
