import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import TextWithIcons from '../../../molecules/TextWithIcons';
import CompanyBranch from '../../../../store/constants/companies/branches/company-branch.interface';
import useDateTime from '../../../../hooks/utils/useDateTime';
import consoleLog from '../../../../utils/consoleLog';
import useCompanyBranch from '../../../../hooks/companies/branches/useCompanyBranches';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import CompanyBranchListRow from './CompanyBranchListRow';
import SearchSvg from '../../../../assets/images/icons/search.svg';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow-with-tail-down.svg';
import { ReactComponent as Add } from '../../../../assets/images/icons/plus.svg';

type SortByHeaderOptions =
  | ''
  | 'company'
  | 'name'
  | 'contact'
  | 'email'
  | 'offices'
  | 'dateCreated';
interface CompanyBranchListHeader {
  name: string;
  field: SortByHeaderOptions | 'action';
  style: string;
}

const CompanyBranchListHeaders: CompanyBranchListHeader[] = [
  { name: 'Company', field: 'company', style: 'hidden md:flex' },
  { name: 'Name', field: 'name', style: '' },
  { name: 'Contact', field: 'contact', style: '' },
  { name: 'Email', field: 'email', style: '' },
  { name: 'Offices', field: 'offices', style: '' },
  { name: 'Date Created', field: 'dateCreated', style: 'hidden lg:flex' },
  { name: 'Action', field: 'action', style: 'flex justify-self-center' },
];

const CompanyBranchList: React.FC = () => {
  const { getDateTime } = useDateTime();

  const navigate = useNavigate();

  // const [selectedBranch, setSelectedBranch] = useState<CompanyBranch | undefined>();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const { companyBranchesState, getAll, remove } = useCompanyBranch();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');

  // Use a state variable to track ascending or descending order
  const [sortOrderBy, setSortOrderBy] = useState(false);

  // Function to toggle the sorting order
  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const companyBranches = companyBranchesState.companyBranches
    .filter((companyBranch: CompanyBranch) => {
      if (!companyBranch) return false;
      const { name, contact, company } = companyBranch;
      return (
        name.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        (contact || '').toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        (company?.name || '').toLowerCase().startsWith(debouncedSearchValue.toLowerCase())
      );
    })
    .sort((a: CompanyBranch, b: CompanyBranch) => {
      const sortOrder = sortOrderBy ? -1 : 1;
      const defaultOrder = b.id - a.id;

      if (sortOption === 'company') {
        if (!a.company.name) return -sortOrder;
        if (!b.company?.name) return sortOrder;
        return sortOrder * a.company.name.localeCompare(b.company.name);
      }
      if (sortOption === 'name') {
        return sortOrder * a.name.localeCompare(b.name);
      }
      if (sortOption === 'contact') {
        if (!a.contact) return 1;
        return sortOrder * (a?.contact || '').localeCompare(b?.contact || '');
      }
      if (sortOption === 'email') {
        if (!a.emailDistro) return 1;
        return sortOrder * (a?.emailDistro || '').localeCompare(b?.emailDistro || '');
      }
      if (sortOption === 'offices') {
        if (a.companyBranchOffices.length === null) return 0;
        return sortOrder * a.companyBranchOffices.length - b.companyBranchOffices.length;
        // return sortOrder * a.companyBranchOffices.length - b.companyBranchOffices.length;
      }
      if (sortOption === 'dateCreated') {
        return sortOrder * getDateTime(a.dateCreated).localeCompare(getDateTime(b.dateCreated));
      }
      return defaultOrder;
    });

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = false) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    consoleLog('BRANCH_LIST', 'onSearchKeyDown', e);

    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleOpen = () => {
    // @todo use a simple button instead of calling this modal
    navigate('/admin/companies/branches/new');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    consoleLog('BRANCH_LIST', 'onSearchKeyDown', e);
    setSearchValue(e.target.value);
  };

  const onEditClick = (companyBranch: CompanyBranch) => {
    consoleLog('BRANCH_LIST', 'onEditClick', companyBranch);
    navigate(`/admin/companies/branches/${companyBranch.id}`);
  };

  const onDeleteClick = async (companyBranch: CompanyBranch) => {
    remove(companyBranch.id).then((r) => {
      consoleLog('DELETE BRANCH', r);
    });
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full px-5 organisms-companyBranch-list">
      <div className="flex justify-between pb-5">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search company branch"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Company Branches"
          />
        </div>{' '}
        <button type="button" className="button button--state-default gap-2" onClick={handleOpen}>
          <Add className="w-5 h-5" />
          Add company branch
        </button>
      </div>

      <div className="companyBranch-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        {CompanyBranchListHeaders.map((header) => (
          <button
            key={header.name}
            type="button"
            onClick={() => sortByHeader(header.field as SortByHeaderOptions)}
            className={`items-center gap-3 ${header.style}`}
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                header.field !== 'action' &&
                (sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === header.field ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === header.field ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                ))
              }
            >
              <div className="flex gap-5">{header.name}</div>
            </TextWithIcons>
          </button>
        ))}
      </div>

      <VariableSizeList
        itemCount={companyBranches.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <CompanyBranchListRow
            data={companyBranches[index]}
            style={style}
            className={`${index % 2 === 0 ? 'stripped' : ''}`}
            getDateTime={getDateTime}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default CompanyBranchList;
