import { DateClickArg } from '@fullcalendar/interaction';
import moment from 'moment';
import {
  ModalContent,
  TransformedEvent,
} from '../../../store/constants/calendar/calendar.interface';

const CalendarHandleCLickEvents = (
  dayInfo: DateClickArg,
  filterStartDateEntries: TransformedEvent[],
  setModalContent: React.Dispatch<React.SetStateAction<ModalContent>>,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const dayDate = dayInfo.date;
  const eventsOnDay = filterStartDateEntries.filter((event) => {
    const eventStart = new Date(event.start);
    return eventStart.toDateString() === dayDate.toDateString();
  });

  const eventContent: Record<string, string | boolean | string[]> = {
    leaveApproved: '',
    leavePending: '',
    leaveDenied: '',
    attendance: '',
    undertime: '',
    noEvents: '',
    absent: '',
    holiday: [],
    overtime: [],
    isLate: false,
  };

  eventsOnDay.forEach((event) => {
    const typedEvent = event as TransformedEvent;

    switch (typedEvent.classNames) {
      case 'present': {
        const timeIn = typedEvent.extendedProps?.timeIn || '00:00:00';
        const timeOut = typedEvent.extendedProps?.timeOut || '00:00:00';
        const isLate = typedEvent.title.toLowerCase().includes('late');

        if (isLate) {
          eventContent.attendance += `Late ${timeIn} - ${timeOut} `;
          eventContent.isLate = true;
        } else {
          eventContent.attendance += `${typedEvent.title ?? ''} ${timeIn} - ${timeOut} `;
        }
        break;
      }
      case 'overtime-event': {
        const overtimeTimeIn = typedEvent.extendedProps?.timeIn || '00:00:00';
        const overtimeTimeOut = typedEvent.extendedProps?.timeOut || '00:00:00';

        (eventContent.overtime as string[]).push(
          `Overtime (approved): ${overtimeTimeIn} - ${overtimeTimeOut}`,
        );
        break;
      }
      case 'absent': {
        eventContent.absent += `${typedEvent.title} `;
        break;
      }
      case 'holiday-event': {
        const holidayName = typedEvent.extendedProps?.holiday || '';
        if (holidayName) {
          (eventContent.holiday as string[]).push(holidayName);
        }
        break;
      }
      case 'undertime-event': {
        const undertime = typedEvent.extendedProps?.undertime || '00:00:00';
        eventContent.undertime += `Undertime: ${moment(undertime, 'HH:mm:ss').format('hh:mm A')}`;
        break;
      }
      case 'leave-pending': {
        eventContent.leavePending += `${typedEvent.extendedProps?.leavePending} (Pending)`;
        break;
      }
      case 'leave-approved': {
        eventContent.leaveApproved += `${typedEvent.extendedProps?.leaveApproved} (Approved)`;
        break;
      }
      case 'leave-denied': {
        eventContent.leaveDenied += `${typedEvent.extendedProps?.leaveDenied} (Denied)`;
        break;
      }
      default:
        break;
    }
  });

  if (!Object.values(eventContent).some((value) => (Array.isArray(value) ? value.length : value))) {
    eventContent.noEvents = 'No events';
  }

  setModalContent((prevContent) => ({
    ...prevContent,
    ...eventContent,
    attendanceRanges: eventsOnDay
      .filter((event) => event.classNames === 'present')
      .map((event) => {
        const typedEvent = event as TransformedEvent;
        const timeIn = typedEvent.extendedProps?.timeIn || '00:00:00';
        const timeOut = typedEvent.extendedProps?.timeOut || '00:00:00';
        return `${timeIn} - ${timeOut}`;
      }),
  }));

  setModalOpen(true);
};

export default CalendarHandleCLickEvents;
