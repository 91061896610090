import { useAppDispatch, useAppSelector } from '../../store/store';
import transactionFeedActions from '../../store/actions/my/transactionFeedActions';
import {
  TransactionFeedsState,
  transactionFeedsReducerActions,
} from '../../store/reducers/my/transactionFeedsReducer';
import TransactionFeed from '../../store/constants/my/transaction-feed.interface';

const useTransactionFeeds = () => {
  const dispatch = useAppDispatch();
  const transactionFeedsState: TransactionFeedsState = useAppSelector(
    (state) => state.transactionFeeds,
  );

  const resetTransactionFeedState = () => {
    dispatch(transactionFeedsReducerActions.resetTransactionFeedsState());
  };

  const setTransactionFeedState = (state: Partial<TransactionFeedsState>) => {
    dispatch(transactionFeedsReducerActions.setTransactionFeedsState(state));
  };

  const get = async (id: TransactionFeed['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(transactionFeedActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(transactionFeedActions.getAll({}));

  const post = async (transaction: Omit<TransactionFeed, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(transactionFeedActions.post(transaction));

  const put = async (transaction: Partial<TransactionFeed>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(transactionFeedActions.put(transaction));

  const remove = async (id: TransactionFeed['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(transactionFeedActions.remove(id));

  const isRead = async (data: TransactionFeed) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(transactionFeedActions.isRead(data));

  return {
    resetTransactionFeedState,
    transactionFeedsState,
    setTransactionFeedState,
    get,
    getAll,
    post,
    put,
    remove,
    isRead,
  };
};

export default useTransactionFeeds;
