import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {
  UserFormPage,
  userFormReducerActions,
  UserFormState,
  UserFormSteps,
} from '../../store/reducers/users/userFormReducer';
import consoleLog from '../../utils/consoleLog';

const useUserForm = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userFormState: UserFormState = useAppSelector((state) => state.userForm);

  const [isLastStep, setIsLastStep] = useState(false);

  const [isFirstStep, setIsFirstStep] = useState(false);

  const resetUserFormState = () => {
    consoleLog('RESET FORM');
    dispatch(userFormReducerActions.resetUserFormState());
  };

  const setUserFormState = (state: Partial<UserFormState>) => {
    dispatch(userFormReducerActions.setUserFormState(state));
  };

  const setActiveStep = (step: UserFormSteps) => {
    dispatch(userFormReducerActions.setUserFormState({ activeStep: step }));
  };

  const getUrlStep = (step: string) => {
    switch (step as UserFormPage) {
      case 'basic':
        return UserFormSteps.BASIC_INFO;
      case 'contact':
        return UserFormSteps.CONTACT_INFO;
      case 'employment':
        return UserFormSteps.EMPLOYMENT_INFO;
      case 'payroll':
        return UserFormSteps.EMPLOYMENT_PAYROLL;
      case 'teams':
        return UserFormSteps.EMPLOYMENT_TEAM_ASSIGNMENT;
      case 'summary':
        return UserFormSteps.SUMMARY;
      default:
        return UserFormSteps.BASIC_INFO;
    }
  };

  const updateURL = (step: UserFormSteps) => {
    switch (step) {
      case UserFormSteps.BASIC_INFO:
        navigate('/admin/user/manage/new/basic');
        break;
      case UserFormSteps.CONTACT_INFO:
        navigate('/admin/user/manage/new/contact');
        break;
      case UserFormSteps.EMPLOYMENT_INFO:
        navigate('/admin/user/manage/new/employment');
        break;
      case UserFormSteps.EMPLOYMENT_PAYROLL:
        navigate('/admin/user/manage/new/payroll');
        break;
      case UserFormSteps.EMPLOYMENT_TEAM_ASSIGNMENT:
        navigate('/admin/user/manage/new/teams');
        break;
      default:
        navigate('/admin/user/manage/new/basic');
    }
  };

  const handleNext = () => {
    if (userFormState.nextStep) {
      const step = getUrlStep(userFormState.nextStep);
      updateURL(step);
      setActiveStep(step);
      return;
    }

    if (!isLastStep) {
      const newStep = userFormState.activeStep + 1;
      updateURL(newStep);
      setActiveStep(newStep);
    }
  };

  const handlePrev = () => {
    if (!isFirstStep) {
      const newStep = userFormState.activeStep - 1;
      updateURL(newStep);
      setActiveStep(newStep);
    }
  };

  return {
    userFormState,
    resetUserFormState,
    setUserFormState,
    activeStep: userFormState.activeStep,
    getUrlStep,
    setActiveStep,
    isLastStep,
    setIsLastStep,
    isFirstStep,
    setIsFirstStep,
    handleNext,
    handlePrev,
  };
};

export default useUserForm;

// any file that has 'use' is a hook
// Handles the reducer userForm
