import React from 'react';
import { Accordion, AccordionHeader, AccordionBody } from '@material-tailwind/react';
import Team from '../../../../../../store/constants/teams/team.interface';

export interface PayrollAccordionProps {
  open: number;
  openNumber?: number;
  menuTitle?: string;
  teamOptions: Team[];
  handleOpen: (value: number) => void;
  handleCheckboxChange: (id: number, checked: boolean) => void;
}

const PayrollAccordion: React.FC<PayrollAccordionProps> = ({
  openNumber = undefined,
  menuTitle = undefined,
  teamOptions,
  open,
  handleOpen,
  handleCheckboxChange,
}) => (
  <Accordion
    open={open === openNumber}
    icon={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="#f05825"
        className="w-3 h-3"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
        />
      </svg>
    }
  >
    <div
      className={`list-header border border-solid border-t-[#2c2b2a] border-b-[#2c2b2a] border-l-transparent border-r-transparent border-opacity-40 p-2 hover:bg-[#2c2b2a] ${
        open === openNumber ? 'bg-[#2c2b2a]' : ''
      }`}
    >
      <AccordionHeader onClick={() => handleOpen(openNumber ?? 0)} className="p-0 border-b-0 ">
        <span className="mr-auto text-[0.65rem] font-normal text-white">{menuTitle}</span>
      </AccordionHeader>
    </div>
    <AccordionBody className="py-1 duration-300 overflow-y-scroll border border-[#2c2b2a] border-solid no-scrollbars h-fit">
      {teamOptions.map((team) => (
        <div
          key={team.id}
          className="flex items-center gap-2 px-2 py-1 hover:bg-[#2c2b2a] border-b border-solid border-[#2c2b2a] border-opacity-40"
        >
          <input
            type="checkbox"
            id={`${team.id}`}
            name={`${team.client} - ${team.name}`}
            className="w-4 h-4 text-[#f05825] rounded-md border border-solid border-[#f05825]"
            onChange={(e) => handleCheckboxChange(team.id, e.target.checked)}
          />
          <label htmlFor={`${team.id}`} className="text-[0.65rem] font-normal text-white">
            {`${team.client?.name} - ${team.name}`}
          </label>
        </div>
      ))}
    </AccordionBody>
  </Accordion>
);

export default PayrollAccordion;
