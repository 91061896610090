import React from 'react';
import PayrollSidebarSearchOnly from '../../sidebar/PayrollSidebarSearchOnly';
import ThirteenthMonthMonthlyTable from './ThirteenthMonthMonthlyTable';

const ThirteenthMonthMonthly: React.FC = () => (
  <div className="flex w-full h-full organisms-payroll-management">
    <div className="flex w-full h-full">
      <div className="payroll-sidebar-wrapper">
        <PayrollSidebarSearchOnly />
      </div>
      <div className="flex flex-col w-full payroll-content-wrapper">
        <ThirteenthMonthMonthlyTable />
      </div>
    </div>
  </div>
);

export default ThirteenthMonthMonthly;
