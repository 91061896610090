import { useCallback, useEffect, useState } from 'react';

const calculateCalendarHeight = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth >= 1600) {
    return '298px';
  }

  if (screenWidth >= 1200) {
    return '260';
  }
  if (screenWidth >= 1000) {
    return '250px';
  }
  return '230px';
};

const CalendarHeightHandler = () => {
  const [calendarHeight, setCalendarHeight] = useState('298px');

  const handleResize = useCallback(() => {
    const newHeight = calculateCalendarHeight();
    setCalendarHeight(newHeight);
  }, []);

  useEffect(() => {
    // Set initial height
    const initialHeight = calculateCalendarHeight();
    setCalendarHeight(initialHeight);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return calendarHeight;
};

export default CalendarHeightHandler;
