import { useAppDispatch, useAppSelector } from '../../store/store';
import { settingsReducerActions, SettingsState } from '../../store/reducers/utils/settingsReducer';

const useSettings = () => {
  const dispatch = useAppDispatch();
  const settingStates: SettingsState = useAppSelector((state) => state.settings);

  const resetSettingsState = () => dispatch(settingsReducerActions.resetSettingsState());

  const setSettingsState = (state: Partial<SettingsState>) =>
    dispatch(settingsReducerActions.setSettingsState(state));

  const getCurrencyFormat = (amount: number) =>
    Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'PHP',
    }).format(amount);

  const showUserDrawer = () =>
    dispatch(
      settingsReducerActions.setSettingsState({
        userDrawerOpen: true,
      }),
    );

  const hideUserDrawer = () =>
    dispatch(
      settingsReducerActions.setSettingsState({
        userDrawerOpen: false,
      }),
    );

  return {
    settingStates,
    resetSettingsState,
    setSettingsState,
    getCurrencyFormat,
    showUserDrawer,
    hideUserDrawer,
  };
};

export default useSettings;
