import moment from 'moment';
import useSchedules from '../schedule/useSchedules';
import UserEmploymentSchedules from '../../store/constants/employees/schedules/employee-schedule.interface';
import useAttendance from './useAttendance';
import useAuth from '../auths/useAuth';

const useAttendanceAction = () => {
  const { schedulesState } = useSchedules();
  const { attendanceState } = useAttendance();

  const { loggedUser } = useAuth();

  const isTodayInSchedule = (schedule: UserEmploymentSchedules) => {
    if (!schedule) {
      return false;
    }

    const today = moment();
    const currentDay = today.day();

    const daysArray = schedule.days?.split(',').map(Number) || [];

    return daysArray.includes(currentDay);
  };

  // return truthy if has schedule
  const hasScheduleForToday = schedulesState.schedules.some((schedule) =>
    isTodayInSchedule(schedule),
  );

  const convertNumberDaysToNames = (daysString: string, shortFormat = true): string =>
    daysString
      .split(',')
      .map(Number)
      .map((day) => {
        const daysArray = shortFormat
          ? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
          : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysArray[day % 7];
      })
      .join(', ');

  // Convert number days to name | return timein & timeout
  const userScheduleDaysTime = schedulesState.schedules.map((schedule) => ({
    convertedDays: convertNumberDaysToNames(schedule?.days || ''),
    timeIn: schedule.timeIn,
    timeOut: schedule.timeOut,
    id: schedule.id,
  }));

  const userNextSchedule = schedulesState.schedules
    // filter by logged employee
    .filter((schedule) => schedule.employeeNumber === loggedUser?.employeeNumber)
    // map days to array
    .map((schedule) => ({
      schedule,
      daysArray: schedule.days?.split(',').map(Number) || [],
    }))
    // filter out schedules with no days
    .filter(({ daysArray }) => daysArray.length > 0)
    // sort schedule by next scheduled day/time
    .sort((a, b) => {
      const currentDay = moment().day();
      const nextScheduledDayA =
        a.daysArray.find((day) => day >= currentDay) ||
        (a.daysArray[0] === currentDay ? currentDay : Math.min(...a.daysArray) + 7);
      const nextScheduledDayB =
        b.daysArray.find((day) => day >= currentDay) ||
        (b.daysArray[0] === currentDay ? currentDay : Math.min(...b.daysArray) + 7);

      if (nextScheduledDayA !== nextScheduledDayB) {
        return nextScheduledDayA - nextScheduledDayB;
      }
      return moment(a.schedule.timeIn, 'HH:mm').diff(moment(b.schedule.timeIn, 'HH:mm'));
    })
    // finds the next schedule after the current day
    .find(({ schedule, daysArray }) => {
      const currentTime = moment();
      const currentDay = currentTime.day();
      // Find the next scheduled day
      const nextScheduledDay =
        daysArray.find((day) => day >= currentDay) ||
        // if next sched is today ? today : get sched for next week
        (daysArray[0] === currentDay ? currentDay : Math.min(...daysArray) + 7);

      // Check if the next scheduled day is after the current day
      if (nextScheduledDay > currentDay) {
        return true;
      }
      if (nextScheduledDay === currentDay) {
        // Check if there are remaining hours for the current day's schedule
        const scheduleEndTime = moment(schedule.timeOut, 'HH:mm');
        if (currentTime.isBefore(scheduleEndTime)) {
          return true;
        }
        // Check if the current day's schedule has time out
        if (
          currentTime.isAfter(scheduleEndTime) &&
          attendanceState.myAttendance?.employeeAttendanceDetails.timeOut
        )
          return true;

        return false;
      }
      return false;
    })?.schedule;

  const myNextSchedule = attendanceState.myCurrentSchedule;

  return {
    userNextSchedule,
    userScheduleDaysTime,
    hasScheduleForToday,
    convertNumberDaysToNames,
    myNextSchedule,
  };
};

export default useAttendanceAction;
