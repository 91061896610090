import React from 'react';
import UserList from './UserList';
import ManagementHeading from '../../atoms/ManagementHeading';

const UserManagement: React.FC = () => (
  <div className="flex w-full user-management">
    <div className="w-full flex flex-col">
      <div className="m-2 title">
        <ManagementHeading title="User" />
      </div>
      <div className="organisms-user-management w-full">
        <UserList />
      </div>
    </div>
  </div>
);

export default UserManagement;
