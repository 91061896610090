import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

export type User = {
  id: string;
  name: string;
  accountClient: string;
  dateStarted: string;
  dateEnded: string;
  Status: string;
};

const columnHelper = createColumnHelper<User>();

const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('name', {
    header: 'NAME',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('accountClient', {
    header: 'Account/Client',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('dateStarted', {
    header: 'Date Started',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('dateEnded', {
    header: 'Date Ended',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('Status', {
    header: 'Status',
    cell: (info) => info.getValue(),
  }),
];

const InactiveEmployeesTable = () => {
  const [
    users,
    // setUsers
  ] = React.useState<User[]>([]);

  const table = useReactTable({
    data: users,
    columns,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <div className="table-wrapper" style={{ maxHeight: '600px', overflow: 'hidden' }}>
        <div className="header-wrapper">
          <table className={`department-table ${table.getTotalSize()}`}>
            <thead className="">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className="" key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className="department-table-cell text-gray-200 text-sm font-bold"
                      key={header.id}
                      style={{ width: header.column.getSize() }} // Set the width inline
                    >
                      <div className="">
                        {/* {flexRender(header.column.columnDef.header, header.getContext())} */}
                        {header.column.columnDef.header?.toString()}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          </table>
        </div>
        <div
          className="body-wrapper customize-scrollbars"
          style={{ maxHeight: '600px', overflowY: 'auto' }}
        >
          <table className={`department-table ${table.getTotalSize()}`}>
            <tbody className="table-body">
              {table.getRowModel().rows.map((row, index) => (
                <tr className={`${index % 2 === 0 ? 'stripped' : ''}`} key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      className={`department-table-row text-xs text-gray-200 ${cell.column.getSize()}`}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InactiveEmployeesTable;
