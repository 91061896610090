import { useAppDispatch, useAppSelector } from '../../../store/store';
import Payslip from '../../../store/constants/payroll/payslip.interface';
import myPayslipActions from '../../../store/actions/my/payslip/myPayslipActions';
import { GetAllMyPayslipQueryParams } from '../../../store/services/my/payslip/myPayslipServices';
import {
  myPayslipsReducerAction,
  MyPayslipsState,
} from '../../../store/reducers/payroll/myPayslipsReducer';

const useMyPayslips = () => {
  const dispatch = useAppDispatch();
  const myPayslipsState: MyPayslipsState = useAppSelector((state) => state.myPayslips);

  const resetPayslipState = () => {
    dispatch(myPayslipsReducerAction.resetMyPayslipsState());
  };

  const resetPayslipToPrint = () => {
    dispatch(myPayslipsReducerAction.resetPayslipToPrint());
  };

  const setPayslipState = (state: Partial<MyPayslipsState>) => {
    dispatch(myPayslipsReducerAction.setMyPayslipsState(state));
  };

  const getMy = async (payslipId: Payslip['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myPayslipActions.getMy(payslipId));

  const getMyAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myPayslipActions.getMyAll({}));

  const getMyPayslips = async (params = {} as GetAllMyPayslipQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myPayslipActions.getMyPayslips(params));

  const printMyPayslips = async (params = {} as GetAllMyPayslipQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myPayslipActions.printMyPayslips(params));

  const myPost = async (payslipData: Omit<Payslip, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myPayslipActions.myPost(payslipData));

  const myPut = async (payslip: Partial<Payslip>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myPayslipActions.myPut(payslip));

  const myRemove = async (id: Payslip['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myPayslipActions.myRemove(id));

  return {
    myPayslipsState,
    resetPayslipState,
    resetPayslipToPrint,
    setPayslipState,
    getMy,
    getMyAll,
    myPost,
    myPut,
    myRemove,
    getMyPayslips,
    printMyPayslips,
  };
};

export default useMyPayslips;
