import React from 'react';
import Employee from '../../../store/constants/employees/employee.interface';

export interface TeamListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Employee;
  getDateTime: (date: Date) => string;
}

const TeamAssignmentRow: React.FC<TeamListRowProps> = ({
  className = '',
  data,
  getDateTime,
  ...props
}: TeamListRowProps) => (
  <div
    {...props}
    className={`${className} team-assignment-list-data table-list-data flex items-center`}
  >
    <div>{data.userInformation.fullName}</div>
    <div>{undefined}</div>
    <div>{undefined}</div>
  </div>
);

export default TeamAssignmentRow;
