import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/auths/authReducer';
import usersReducer from './reducers/users/usersReducer';
import userSearchReducer from './reducers/users/userSearchReducer';
import userFormReducer from './reducers/users/userFormReducer';
import holidaysReducer from './reducers/holidays/holidaysReducer';
import clientsReducer from './reducers/clients/clientsReducer';
import teamsReducer from './reducers/teams/teamsReducer';
import departmentsReducer from './reducers/departments/departmentsReducer';
import holidayTypesReducer from './reducers/holidays/holidayTypesReducer';
import leavesReducer from './reducers/leaves/leavesReducer';
import myLeavesReducer from './reducers/leaves/myLeavesReducer';
import companyReducer from './reducers/companies/companiesReducer';
import companyBranchReducer from './reducers/companies/companyBranchesReducer';
import scheduleTypesReducer from './reducers/employments/employmentScheduleTypesReducer';
import schedulesReducer from './reducers/schedules/schedulesReducer';
import positionsReducer from './reducers/positions/positionsReducer';
import userPositionsReducer from './reducers/positions/userPositionsReducer';
import positionRatesReducer from './reducers/positions/positionRatesReducer';
import attendanceReducer from './reducers/attendances/attendancesReducer';
import overtimesReducer from './reducers/attendances/overtimesReducer';
import banksReducer from './reducers/banks/banksReducer';
import employmentRolesReducer from './reducers/employments/employmentRolesReducer';
import employmentStatusReducer from './reducers/employments/employmentStatusReducer';
import modalsReducer from './reducers/utils/modalsReducer';
import settingsReducer from './reducers/utils/settingsReducer';
import websocketReducer from './reducers/utils/websocketReducer';
import userEmploymentsReducer from './reducers/users/userEmploymentsReducer';
import employmentTypesReducer from './reducers/employments/employmentTypesReducer';
import userTeamsReducer from './reducers/teams/userTeamsReducer';
import loanTypesReducer from './reducers/loans/loanTypesReducer';
import loansReducer from './reducers/loans/loansReducer';
import hmoReducer from './reducers/hmo/hmoReducer';
import payrollsReducer from './reducers/payroll/payrollsReducer';
import sidebarReducer from './reducers/utils/sidebarReducer';
import appInitializationReducer from './reducers/utils/appInitializationReducer';
import transactionFeedReducer from './reducers/my/transactionFeedsReducer';
import hmoDependentReducer from './reducers/my/hmoDependentsReducer';
import incentiveReducer from './reducers/my/incentivesReducer';
import payslipConcernReducer from './reducers/my/payslipConcernsReducer';
import hmoPrincipalReducer from './reducers/my/hmoPrincipalsReducer';
import resultDocumentReducer from './reducers/my/healthTracker/resultDocumentsReducer';
import nurseStationVisitReducer from './reducers/my/healthTracker/nurseStationVisitsReducer';
import overtimeReducer from './reducers/attendances/overtimeReducer';
import loadingReducer from './reducers/utils/loadingReducer';
import myPayslipsReducer from './reducers/payroll/myPayslipsReducer';
import calendarReducer from './reducers/calendar/calendarsReducer';
import userActivityReducer from './reducers/utils/userActivitiesReducer';
import uploadFileAttachmentsReducer from './reducers/utils/uploadFileAttachmentsReducer';

const version = '1.10.2';
if (localStorage.getItem('version') !== version) {
  localStorage.removeItem('persist:root');
  localStorage.setItem('version', version);
}

const reducers = combineReducers({
  websocket: websocketReducer,
  settings: settingsReducer,
  loading: loadingReducer,
  auth: authReducer,
  company: companyReducer,
  companyBranch: companyBranchReducer,
  modals: modalsReducer,
  holidays: holidaysReducer,
  holidayTypes: holidayTypesReducer,
  leaves: leavesReducer,
  myLeaves: myLeavesReducer,
  clients: clientsReducer,
  positions: positionsReducer,
  userPositions: userPositionsReducer,
  userTeams: userTeamsReducer,
  positionRates: positionRatesReducer,
  teams: teamsReducer,
  departments: departmentsReducer,
  attendances: attendanceReducer,
  overtimes: overtimesReducer,
  users: usersReducer,
  userEmployments: userEmploymentsReducer,
  userSearch: userSearchReducer,
  banks: banksReducer,
  userForm: userFormReducer,
  scheduleTypes: scheduleTypesReducer,
  schedule: schedulesReducer,
  employmentRoles: employmentRolesReducer,
  employmentStatus: employmentStatusReducer,
  employmentTypes: employmentTypesReducer,
  loanTypes: loanTypesReducer,
  loans: loansReducer,
  hmo: hmoReducer,
  payroll: payrollsReducer,
  sidebarMenu: sidebarReducer,
  transactionFeeds: transactionFeedReducer,
  hmoDependents: hmoDependentReducer,
  incentives: incentiveReducer,
  payslipConcerns: payslipConcernReducer,
  hmoPrincipals: hmoPrincipalReducer,
  resultDocuments: resultDocumentReducer,
  nurseStationVisits: nurseStationVisitReducer,
  appInitialization: appInitializationReducer,
  overtime: overtimeReducer,
  myPayslips: myPayslipsReducer,
  calendars: calendarReducer,
  userActivity: userActivityReducer,
  UploadFileAttachment: uploadFileAttachmentsReducer,
});

const persistConfig = {
  key: 'root',
  storage, // Non-persistent states
  blacklist: ['modals', 'userSearch', 'clientForm', 'userTeams', 'payroll', 'loading'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
