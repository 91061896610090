import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import LeaveApplication from '../../constants/leaves/leave-application.interface';
import leaveServices, { GetAllLeavesQueryParams } from '../../services/leave/leaveServices';

const get = createAsyncThunk(
  'leaves/applications/get',
  async (
    id: LeaveApplication['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await leaveServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'leaves/applications/getAll',
  async (
    {
      page = 1,
      limit = 50,
      search = '',
      employeeNumber = undefined,
      teamId = undefined,
      departmentId = undefined,
      status = undefined,
      duration = undefined,
    }: GetAllLeavesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await leaveServices.getAll(
        { page, limit, search, employeeNumber, teamId, departmentId, status, duration },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'leaves/applications/post',
  async (
    data: Omit<LeaveApplication, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await leaveServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'leaves/applications/put',
  async (
    data: AtLeast<LeaveApplication, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await leaveServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'leaves/applications/remove',
  async (
    id: LeaveApplication['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await leaveServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const approve = createAsyncThunk(
  'leaves/applications/approve',
  async (
    data: AtLeast<LeaveApplication, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await leaveServices.approve(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const deny = createAsyncThunk(
  'leaves/applications/deny',
  async (
    data: AtLeast<LeaveApplication, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await leaveServices.deny(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default {
  get,
  getAll,
  post,
  put,
  remove,
  approve,
  deny,
} as const;
