import React from 'react';
import { Spinner as Loader } from '@material-tailwind/react';
import { color as muitColor } from '@material-tailwind/react/types/components/spinner';

interface SpinnerProps {
  height?: string;
  color?: muitColor;
}

const Spinner: React.FC<SpinnerProps> = ({ height = '0.8rem', color = 'deep-orange' }) => (
  <Loader height={height} color={color} />
);

export default Spinner;
