import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginTemplate from './components/templates/LoginTemplate';
import MyTemplate from './components/templates/MyTemplate';
import AdminTemplate from './components/templates/AdminTemplate';
import ProtectedRoute from './components/atoms/ProtectedRoute';
import UserTemplate from './components/templates/UserTemplate';
import PayrollTemplate from './components/templates/PayrollTemplate';

const App = () => (
  <Routes>
    <Route element={<ProtectedRoute accessibleTo="superadmin" />}>
      <Route path="/admin/*" Component={AdminTemplate} />
      <Route path="/payroll/*" Component={PayrollTemplate} />
    </Route>
    <Route element={<ProtectedRoute />}>
      <Route path="/my/*" Component={MyTemplate} />
      <Route path="/user/*" Component={UserTemplate} />
    </Route>
    <Route path="/login" Component={LoginTemplate} />
    <Route path="*" element={<Navigate replace to="/login" />} />
  </Routes>
);

export default App;
