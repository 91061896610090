import React from 'react';
import OvertimePageList from './OvertimePageList';
import ManagementHeading from '../../../atoms/ManagementHeading';

const OvertimePage: React.FC = () => (
  <div className="w-full flex flex-col overtime-page-parent">
    <div className="m-2 title">
      <ManagementHeading title="Overtimes" subtitle="Log" />
    </div>
    <div className="organisms-overtime-management w-full">
      <OvertimePageList />
    </div>
  </div>
);

export default OvertimePage;
