import React from 'react';
// import { Tooltip } from '@material-tailwind/react';
import Employee from '../../../store/constants/employees/employee.interface';
// import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';

export interface ClientEmployeeListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Employee;
  getDateTime: (date: Date) => string;
  onEditClick: (user: Employee) => void;
  onDeleteClick: (user: Employee) => void;
}

const ClientEmployeeListRow: React.FC<ClientEmployeeListRowProps> = ({
  className = '',
  data,
  getDateTime,
  onEditClick,
  onDeleteClick,
  ...props
}: ClientEmployeeListRowProps) => {
  const currentTeam = data?.teams[0];
  return (
    <div {...props} className={`${className} client-employee-list-data table-list-data`}>
      <div>{data?.employeeNumber}</div>
      <div>{data?.userInformation.fullName}</div>
      <div>{data.currentPosition?.employmentPosition.name}</div>
      <div>{currentTeam.team?.name}</div>
      <div>{currentTeam.dateStart}</div>
      <div>{currentTeam.dateEnd}</div>
    </div>
  );
};

export default ClientEmployeeListRow;
