import React from 'react';
import { Tooltip } from '@material-tailwind/react';
// import UserEmploymentSchedules from '../../../store/constants/schedules/user-employment-schedules.interface';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import { ReactComponent as Eye } from '../../../assets/images/icons/eye.svg';

export interface ScheduleListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: UserEmployment;
  onViewClick: (schedule: UserEmployment) => void;
  onDeleteClick: (schedule: UserEmployment) => void;
}

const ScheduleListRow: React.FC<ScheduleListRowProps> = ({
  className = '',
  data,
  onViewClick,
  onDeleteClick,
  ...props
}: ScheduleListRowProps) => {
  const { getUserEmploymentByEmployeeNumber } = useUserEmployments();

  const getEmploymentTeamByEmployeeNumber = (employeeNumber: UserEmployment['employeeNumber']) =>
    getUserEmploymentByEmployeeNumber(employeeNumber)
      ?.teams?.map((userTeam) => userTeam.team?.name)
      .join(', ');

  const mapDaysToString = (days: string): string => {
    const daysArray = ['M', 'T', 'W', 'Th', 'F', 'Sat', 'Sun'];
    const dayIndexes = days.split(',').map(Number);

    if (
      dayIndexes.length === 5 &&
      dayIndexes.every((index) => index >= 1 && index <= daysArray.length)
    ) {
      return 'Mon - Fri';
    }

    if (
      dayIndexes.length > 5 &&
      dayIndexes.every((index) => index >= 1 && index <= daysArray.length)
    ) {
      return 'Multiple Schedules';
    }

    if (dayIndexes.every((index) => index >= 1 && index <= daysArray.length)) {
      const selectedDays = dayIndexes.map((index) => daysArray[index - 1]);
      return selectedDays.join(', ');
    }

    return days;
  };

  const getEmploymentScheduleByEmployeeNumber = (
    employeeNumber: UserEmployment['employeeNumber'],
  ) =>
    getUserEmploymentByEmployeeNumber(employeeNumber)
      ?.schedules?.map((userSchedule) => userSchedule.days)
      .join(', ');

  const employeeSchedulesNumber = getEmploymentScheduleByEmployeeNumber(data?.employeeNumber);
  const employeeSchedules = mapDaysToString(employeeSchedulesNumber || '');

  return (
    <div {...props} className={`${className} schedule-list-data table-list-data flex items-center`}>
      <div>{data.employeeNumber}</div>
      <div>
        {data?.userInformation.firstName} {data?.userInformation.lastName}
      </div>
      <div>{getEmploymentTeamByEmployeeNumber(data?.employeeNumber)}</div>
      <div>{employeeSchedules || ''}</div>

      <div className="space-x-2 action-buttons justify-self-center">
        <Tooltip content="Manage Schedule" className="bg-[#343434]">
          <button type="button" onClick={() => onViewClick(data)}>
            <Eye className="w-6 h-6 cursor-pointer textButton" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ScheduleListRow;
