import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { authReducerActions, AuthState } from '../../store/reducers/auths/authReducer';
import authActions from '../../store/actions/auths/authActions';
import { resetMenuState } from '../../store/reducers/utils/sidebarReducer';
import { attendancesReducerActions } from '../../store/reducers/attendances/attendancesReducer';
import { calendarsReducerActions } from '../../store/reducers/calendar/calendarsReducer';

export interface LoginProps {
  username: string;
  password: string;
}

let interval: string | number | NodeJS.Timer | null | undefined;

const useAuth = () => {
  const dispatch = useAppDispatch();
  const authState: AuthState = useAppSelector((state) => state.auth);

  const setAuthState = (state: Partial<AuthState>) => {
    dispatch(authReducerActions.setAuthState(state));
  };

  const login = async (loginProps: LoginProps) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(authActions.login(loginProps));

  const refreshToken = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(authActions.refreshToken());

  const logout = async () => {
    dispatch(authReducerActions.resetAuthState());
    dispatch(attendancesReducerActions.resetActiveAttendanceAction());
    dispatch(calendarsReducerActions.resetCalendarsState());
    dispatch(resetMenuState());
  };

  useEffect(() => {
    if (authState.refreshTime && !interval) {
      interval = setInterval(() => {
        refreshToken().then();
      }, authState.refreshTime);
    } else if (typeof authState.refreshTime === 'undefined' && interval) {
      clearInterval(interval);
    }

    // eslint-disable-next-line
  }, [authState.refreshTime]);

  return {
    loggedUser: authState.userInformation,
    authState,
    setAuthState,
    login,
    logout,
  };
};

export default useAuth;
