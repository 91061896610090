import React from 'react';

const RelatedEmployees: React.FC = () => (
  <div className="shadow-sm related-employees-container">
    <h1>Related Employees</h1>
    <div className="grid grid-cols-3 gap-3 mt-5">
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
      <div className="related-image-container" />
    </div>
  </div>
);

export default RelatedEmployees;
