import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Team from '../../../store/constants/teams/team.interface';
import useTeams from '../../../hooks/teams/useTeams';
import SelectClient from '../../atoms/dropdowns/SelectClient';
import SelectDepartment from '../../atoms/dropdowns/SelectDepartment';
import SelectCompany from '../../atoms/dropdowns/SelectCompany';
import SelectCompanyBranch from '../../atoms/dropdowns/SelectCompanyBranch';
import SelectTeamLeader from '../../atoms/dropdowns/SelectTeamLeader';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import Employee from '../../../store/constants/employees/employee.interface';
import Client from '../../../store/constants/clients/client.interface';
import Department from '../../../store/constants/departments/department.interface';
import Company from '../../../store/constants/companies/company.interface';
import CompanyBranch from '../../../store/constants/companies/branches/company-branch.interface';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import toastNotification from '../../../hooks/utils/toastNotification';

export interface TeamFormModalProps {
  team?: Team;
  teamClient?: Client;
  className?: string;
  onClose: () => void;
}

const TeamFormModal: React.FC<TeamFormModalProps> = ({
  team = undefined,
  teamClient = undefined,
  className = '',
  onClose,
}) => {
  const [teamForm, setTeamForm] = useState<Team | undefined>();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const { post, put } = useTeams();

  const handleClose = () => {
    setTeamForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setTeamForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<Team>) => {
    setLoading(true);
    if (teamForm) {
      await toastNotification({
        action: put(values as Team),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Team information is successfully updated.',
          error: {
            render: ({ data }) =>
              typeof data === 'string' ? data : 'Error on updating Team information.',
          },
        },
      });
    } else {
      await toastNotification({
        action: post(values as Team),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Team information is successfully added.',
          error: 'Error on adding Team information.',
        },
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (team) {
      setOpen(true);
      setTeamForm(team);
    }
  }, [team]);

  return (
    <div>
      <button
        type="button"
        className={` ${className} button button--state-default gap-2`}
        onClick={handleOpen}
      >
        <Add className="w-5 h-5" />
        Add team
      </button>
      <Dialog
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-12 md-modal-size"
      >
        <div className="m-2 p-0 absolute flex top-0 right-0">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="white"
            className="modal-icons"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
            />
          </svg>
          <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
            {team?.id ? 'Edit' : 'Enter'} Team Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{
                  ...teamForm,
                  /**
                   * @todo
                   *
                   * solution for the blank label when editing
                   *
                   */
                  name: teamForm?.name ?? '',
                  emailDistro: teamForm?.emailDistro ?? '',
                  teamLeader: teamForm?.teamLeader
                    ? ({
                        ...teamForm?.teamLeader,
                        label: teamForm?.teamLeader?.userInformation.fullName,
                        value: teamForm?.teamLeader,
                      } as Employee)
                    : undefined,
                  // eslint-disable-next-line
                  client: teamClient
                    ? ({
                        ...teamClient,
                        label: teamClient?.name,
                        value: teamClient,
                      } as Client)
                    : teamForm?.client
                    ? ({
                        ...teamForm?.client,
                        label: teamForm?.client?.name,
                        value: teamForm?.client,
                      } as Client)
                    : undefined,
                  department: teamForm?.department
                    ? ({
                        ...teamForm?.department,
                        label: teamForm?.department?.name,
                        value: teamForm?.department,
                      } as Department)
                    : undefined,
                  company: teamForm?.company
                    ? ({
                        ...teamForm?.company,
                        label: teamForm?.company?.name,
                        value: teamForm?.company,
                      } as Company)
                    : undefined,
                  companyBranch: teamForm?.companyBranch
                    ? ({
                        ...teamForm?.companyBranch,
                        label: teamForm?.companyBranch?.name,
                        value: teamForm?.companyBranch,
                      } as CompanyBranch)
                    : undefined,
                }}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form className="">
                    <div className="mt-4 mb-3 form-section">
                      <div className="flex justify-between">
                        <div className="first-section w-[45%]">
                          <div className="mb-6">
                            <label htmlFor="name" className="modal-labels block mb-2 text-gray-200">
                              Team Name
                            </label>
                            <div className="">
                              <Field
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Team Name"
                                required
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              />
                              <ErrorMessage name="name" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="emailDistro"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Email Distro
                            </label>
                            <div className="">
                              <Field
                                type="email"
                                id="emailDistro"
                                name="emailDistro"
                                placeholder="Email Distro"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              />
                              <ErrorMessage name="emailDistro" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="teamLeader"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Team Leader
                            </label>
                            <SelectTeamLeader
                              id="teamLeader"
                              name="teamLeader"
                              isClearable
                              isSearchable
                              value={formik.values.teamLeader}
                              setFieldValue={formik.setFieldValue}
                              required
                            />
                            <ErrorMessage name="teamLeader" component="div" />
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="client"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Client name
                            </label>
                            <div className="">
                              <SelectClient
                                id="client"
                                name="client"
                                isClearable
                                isSearchable
                                isDisabled={teamClient !== undefined}
                                value={formik.values.client}
                                setFieldValue={formik.setFieldValue}
                              />
                            </div>
                            <ErrorMessage name="client" component="div" />
                          </div>
                        </div>

                        <div className="second-section w-[45%]">
                          <div className="mb-6">
                            <label
                              htmlFor="department"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Department
                            </label>
                            <div className="">
                              <SelectDepartment
                                id="department"
                                name="department"
                                isClearable
                                isSearchable
                                value={formik.values.department}
                                setFieldValue={formik.setFieldValue}
                              />
                            </div>
                            <ErrorMessage name="department" component="div" />
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="company"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Company
                            </label>
                            <div className="">
                              <SelectCompany
                                id="company"
                                name="company"
                                isClearable
                                isSearchable
                                value={formik.values.company}
                                setFieldValue={formik.setFieldValue}
                              />
                            </div>
                            <ErrorMessage name="company" component="div" />
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="location"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Location
                            </label>
                            <div className="">
                              <SelectCompanyBranch
                                id="companyBranch"
                                name="companyBranch"
                                isClearable
                                isSearchable
                                value={formik.values.companyBranch}
                                setFieldValue={formik.setFieldValue}
                              />
                            </div>
                            <ErrorMessage name="location" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-2 w-max">
                      <div className="flex justify-center w-max">
                        <ButtonAction
                          type="submit"
                          className="modal-buttons flex justify-center item-center p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                          isLoading={loading}
                          hasSpinnerText={false}
                          isSubmit
                        />
                      </div>
                      <div className="flex justify-center w-full">
                        <button
                          type="button"
                          onClick={() => handleClose()}
                          className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default TeamFormModal;
