import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import EmploymentPosition from '../../../../store/constants/employments/positions/employment-position.interface';
import usePositions from '../../../../hooks/positions/usePositions';
import toastNotification from '../../../../hooks/utils/toastNotification';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import { ReactComponent as Add } from '../../../../assets/images/icons/plus.svg';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as Trophy } from '../../../../assets/images/icons/trophy.svg';

export interface PositionsFormModalParams {
  positions?: EmploymentPosition;
  className?: string;
  onClose: () => void;
}

const PositionManageFormModal: React.FC<PositionsFormModalParams> = ({
  positions = undefined,
  className = undefined,
  onClose,
}) => {
  const [positionsForm, setPositionsForm] = useState<EmploymentPosition | undefined>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { post, put } = usePositions();

  const handleClose = () => {
    setPositionsForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setPositionsForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<EmploymentPosition>) => {
    setLoading(true);
    if (positionsForm) {
      const data: EmploymentPosition = {
        ...positionsForm,
        ...values,
      };

      await toastNotification({
        action: put(data),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Position information is successfully updated.',
          error: {
            render: ({ data: errorMsg }) =>
              typeof errorMsg === 'string' ? errorMsg : 'Error on updating Position information.',
          },
        },
      });
    } else {
      const data: EmploymentPosition = {
        ...values,
        isOtEligible: 'isOtEligible' in values ? values.isOtEligible : false,
        isNdEligible: 'isNdEligible' in values ? values.isNdEligible : false,
      } as EmploymentPosition;

      await toastNotification({
        action: post(data),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Position information is successfully added.',
          error: 'Error on adding Position information.',
        },
      });
    }

    setOpen(false);
  };

  useEffect(() => {
    if (positions) {
      setOpen(true);
      setPositionsForm(positions);
    }
  }, [positions]);

  return (
    <div>
      <button
        type="button"
        className={` ${className} button button--state-default gap-2`}
        onClick={handleOpen}
      >
        <Add className="w-5 h-5" />
        Add position
      </button>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <Close className="w-5 h-auto" />
          </IconButton>
        </div>
        <div className="flex gap-3">
          <Trophy className="modal-icons" />
          <DialogHeader className="p-0 mt-1 text-gray-200 modal-header">
            Enter Positions Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{ ...positionsForm, name: positionsForm?.name || '' }}
                onSubmit={handleSubmit}
              >
                <Form className="w-full">
                  <div className="mt-4 mb-5 form-section">
                    <div className="flex flex-col justify-center w-full">
                      <div className="mb-6">
                        <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                          Employment Position Name
                        </label>
                        {/**
                         *
                         * fixed
                         * - console error @uncontrolled input
                         *
                         */}
                        <div className="">
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Employment Positions Name"
                            className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                          />
                          <ErrorMessage name="name" component="div" />
                        </div>
                      </div>

                      <div className="flex mt-3 space-x-20">
                        <div className="mb-6">
                          <label
                            htmlFor="isOtEligible"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Is OT Eligible?
                          </label>
                          <div className="">
                            <Field type="checkbox" name="isOtEligible" className="w-5 h-5" />
                          </div>
                          <ErrorMessage name="isOtEligible" component="div" />
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="isNdEligible"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Is ND Eligible
                          </label>
                          <div className="">
                            <Field type="checkbox" name="isNdEligible" className="w-5 h-5" />
                          </div>
                          <ErrorMessage name="isNdEligible" component="div" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-2 w-max">
                    <div className="flex justify-center w-full">
                      <ButtonAction
                        type="submit"
                        className="modal-buttons flex justify-center items-center p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                        isLoading={loading}
                        hasSpinnerText={false}
                        isSubmit
                      />
                    </div>
                    <div className="flex justify-center w-max">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default PositionManageFormModal;
