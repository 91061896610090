import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import Team from '../../../store/constants/teams/team.interface';
import { ReactComponent as Edit } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/images/icons/delete.svg';

export interface DepartmentTeamListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Team;
  setSelectedTeam: (team: Team) => void;
  DepartmentTeam: (team: Team) => void;
}

const DepartmentTeamListRow: React.FC<DepartmentTeamListRowProps> = ({
  className = '',
  data,
  setSelectedTeam,
  DepartmentTeam,
  ...props
}: DepartmentTeamListRowProps) => {
  const onClickSelectedDepartmentTeam = () => {
    setSelectedTeam(data);
  };

  return (
    <div
      {...props}
      className={`${className} department-team-list-data table-list-data flex items-center text-left cursor-pointer`}
    >
      <div>{data.name}</div>
      <div>0</div>
      <div className="flex jus gap-4 items-center">
        <Tooltip content="Edit" className="tooltip">
          <button
            className="flex gap-4 textButton"
            type="button"
            onClick={onClickSelectedDepartmentTeam}
          >
            <Edit className="w-6 h-auto" />
          </button>
        </Tooltip>
        <Tooltip content="Remove" className="tooltip">
          <button className="flex gap-4" type="button" onClick={() => DepartmentTeam(data)}>
            <DeleteSvg className="w-6 h-6 trash-icon" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default DepartmentTeamListRow;
