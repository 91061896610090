import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Loan from '../../constants/loans/loan.interface';
import loanServices, { GetAllLoanQueryParams } from '../../services/loans/loanServices';

const get = createAsyncThunk(
  'loan/get',
  async (
    id: Loan['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Loan> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await loanServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAllTest = createAsyncThunk(
  'loanType/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllLoanQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Loan> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await loanServices.getAllTest({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'loan/getAll',
  async (
    {
      employeeNumber = 0,
      departmentId = 0,
      loanTypeId = 0,
      page = 1,
      limit = 50,
      search = '',
    }: GetAllLoanQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Loan> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await loanServices.getAll(
        { employeeNumber, departmentId, loanTypeId, page, limit, search },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'loan/post',
  async (
    data: Omit<Loan, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Loan> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await loanServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'loan/put',
  async (
    data: AtLeast<Loan, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Loan> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await loanServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'loan/remove',
  async (
    id: Loan['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Loan> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await loanServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, getAllTest, post, put, remove } as const;
