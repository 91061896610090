import { useAppDispatch, useAppSelector } from '../../store/store';
import IncentiveActions from '../../store/actions/my/incentiveActions';
import {
  IncentivesState,
  incentivesReducerActions,
} from '../../store/reducers/my/incentivesReducer';
import Incentives from '../../store/constants/my/incentives.interface';

const useIncentives = () => {
  const dispatch = useAppDispatch();
  const incentivesState: IncentivesState = useAppSelector((state) => state.incentives);

  const resetIncentiveState = () => {
    dispatch(incentivesReducerActions.resetIncentivesState());
  };

  const setIncentiveState = (state: Partial<IncentivesState>) => {
    dispatch(incentivesReducerActions.setIncentivesState(state));
  };

  const get = async (id: Incentives['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(IncentiveActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(IncentiveActions.getAll({}));

  const post = async (data: Omit<Incentives, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(IncentiveActions.post(data));

  const put = async (data: Partial<Incentives>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(IncentiveActions.put(data));

  const remove = async (id: Incentives['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(IncentiveActions.remove(id));

  return {
    resetIncentiveState,
    incentivesState,
    setIncentiveState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useIncentives;
