import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useUsers from '../../../hooks/users/useUsers';
import { UserRelation } from '../../../store/reducers/users/usersReducer';

export interface SelectRelationProps extends Partial<AutoSearchSelectProps<UserRelation>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectRelation: React.FC<SelectRelationProps> = ({
  id,
  name,
  placeholder = 'Select Relation',
  className = '',
  ...props
}) => {
  const {
    usersState: { userRelation },
  } = useUsers();

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-relation modal-select ${className}`}
      placeholder={placeholder}
      data={userRelation}
    />
  );
};

export default SelectRelation;
