import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Payroll from '../../constants/payroll/payroll.interface';
import PayrollActions from '../../actions/payroll/payrollActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import { GetAllPayrollQueryParams } from '../../services/payroll/payrollServices';
import consoleLog from '../../../utils/consoleLog';
import Payslip from '../../constants/payroll/payslip.interface';

export interface EmploymentPayroll extends Payroll {
  id: number;
  name: string;
  disputes: number;
  accountNumber: string;
  monthlyGross?: number;
  payPeriod?: number;
}

export interface PayrollsState {
  payrolls: Payroll[];
  payslips: Payslip[];
  payPeriods: string[];
  employmentPayrolls: EmploymentPayroll[];
  payrollParams: GetAllPayrollQueryParams;
}

const initialState: PayrollsState = {
  payrolls: [],
  payslips: [],
  payPeriods: [],
  employmentPayrolls: [
    {
      id: 1,
      name: 'John Doe',
      scheduleId: 3,
      departmentId: 1,
      teamId: 2,
      grossPay: 16888.9,
      dailyRate: 9000,
      daysWorked: 9000,
      daysLeave: 365,
      monthlyBasic: 6888.99,
      totalTaxable: 32423432,
      incentiveCompany: 324,
      incentiveClient: 23432,
      allowances: {},
      deductions: {},
      taxableIncome: 1470,
      status: 'Living Person',
      taxes: 50,
      grossIncome: 1450,
      netIncome: 1400,
      disputes: 2,
      startDate: new Date(),
      endDate: new Date(),
      amount: 100,
      dateStarted: new Date(),
      accountNumber: 'ACC123',
      employmentType: undefined,
      monthlyGross: 43543,
      payPeriod: 34587,
    },
    {
      id: 2,
      name: 'John Doe',
      scheduleId: 3,
      departmentId: 1,
      teamId: 2,
      grossPay: 16888.9,
      dailyRate: 9000,
      daysWorked: 9000,
      daysLeave: 365,
      monthlyBasic: 6888.99,
      totalTaxable: 32423432,
      incentiveCompany: 324,
      incentiveClient: 23432,
      allowances: {},
      deductions: {},
      taxableIncome: 1470,
      status: 'Living Person',
      taxes: 50,
      grossIncome: 1450,
      netIncome: 1400,
      disputes: 2,
      startDate: new Date(),
      endDate: new Date(),
      amount: 100,
      dateStarted: new Date(),
      accountNumber: 'ACC123',
      employmentType: undefined,
      monthlyGross: 43543,
      payPeriod: 34587,
    },
    {
      id: 3,
      name: 'John Doe',
      scheduleId: 3,
      departmentId: 1,
      teamId: 2,
      grossPay: 16888.9,
      dailyRate: 9000,
      daysWorked: 9000,
      daysLeave: 365,
      monthlyBasic: 6888.99,
      totalTaxable: 32423432,
      incentiveCompany: 324,
      incentiveClient: 23432,
      allowances: {},
      deductions: {},
      taxableIncome: 1470,
      status: 'Living Person',
      taxes: 50,
      grossIncome: 1450,
      netIncome: 1400,
      disputes: 2,
      startDate: new Date(),
      endDate: new Date(),
      amount: 100,
      dateStarted: new Date(),
      accountNumber: 'ACC123',
      employmentType: undefined,
      monthlyGross: 43543,
      payPeriod: 34587,
    },
  ] as EmploymentPayroll[],
  payrollParams: {
    search: '',
    teamId: undefined,
    companyId: undefined,
    payPeriod: undefined,
    inactive: undefined,
    resigned: undefined,
    terminated: undefined,
    suspended: undefined,
    endOfContract: undefined,
    authorizedCause: undefined,
    dropTraining: undefined,
    resignedWithCase: undefined,
    awol: undefined,
    floating: undefined,
    lastPayIncentives: undefined,
  },
};

const getPayrollData = (payroll: Partial<Payroll>) => ({
  ...payroll,
});

const upsertItems = (payrolls: Payroll[], ...data: Payroll[]) => {
  const dataObject: { [id: number]: Payroll } = {};
  payrolls.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((payroll) => {
    if (!dataObject[payroll.id]) {
      dataObject[payroll.id] = payroll;
    } else if (dataObject[payroll.id]) {
      dataObject[payroll.id] = { ...dataObject[payroll.id], ...payroll };
    }
  });

  return Object.values(dataObject);
};

const payrollsSlice = createSlice({
  name: 'payrolls',
  initialState,
  reducers: {
    resetPayrollsState: () => ({
      ...initialState,
    }),
    setPayrollsState: (state, action: PayloadAction<Partial<PayrollsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertPayroll: (state, action: PayloadAction<Partial<Payroll>>) => {
      const data: Payroll = getPayrollData(action.payload) as Payroll;

      if (typeof data.id === 'undefined') return;

      state.payrolls = upsertItems(state.payrolls, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PayrollActions.get.fulfilled, (state, { payload }) => {
      consoleLog('PAYROLLS REDUCER', 'GET', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Payroll>;

      if (status === 503) return;

      if (data) {
        state.payrolls = data;
      }
    });
    builder.addCase(PayrollActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('PAYROLL REDUCER', 'GET ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Payroll>;

      if (status === 503) return;

      if (data) {
        state.payrolls = data;
      }
    });
    builder.addCase(PayrollActions.getPayPeriods.fulfilled, (state, { payload }) => {
      consoleLog('PAYROLLS REDUCER', 'GET PAY PERIODS', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<string>;

      if (status === 503) return;

      if (data) {
        state.payPeriods = data;
      }
    });
    builder.addCase(PayrollActions.getEmployeePayrolls.fulfilled, (state, { payload }) => {
      consoleLog('PAYROLL REDUCER', 'GET EMPLOYEE PAYROLLS', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Payroll>;

      if (status === 503) return;

      if (data) {
        state.payrolls = data;
      }
    });
    builder.addCase(PayrollActions.getPayslips.fulfilled, (state, { payload }) => {
      consoleLog('PAYROLL REDUCER', 'GET PAYSLIPS', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Payslip>;

      if (status === 503) return;

      if (data) {
        state.payslips = data;
      }
    });
    builder.addCase(PayrollActions.getAllLatesUndertime.fulfilled, (state, { payload }) => {
      consoleLog('PAYROLL REDUCER', 'GET ALL LATES & UNDERTIME', payload);

      const { data } = payload as ApiBaseResponse<Payroll>;

      if (typeof data.id === 'undefined') return;
      state.payrolls = upsertItems(state.payrolls, data);
    });
    builder.addCase(PayrollActions.createPayslips.fulfilled, (state, { payload }) => {
      const { data, message } = payload as ApiBaseResponse<Payroll[]>;
      consoleLog('CLIENTS REDUCER', 'POST', data);

      if (typeof data === 'undefined') return;

      if (message === 'No Payroll Data was Saved') return;

      if (data) {
        state.payrolls = state.payrolls.filter(
          (payroll) => !data.some((item) => item.employeeNumber === payroll.employeeNumber),
        );
      }
    });
  },
});

export const payrollsReducerActions = payrollsSlice.actions;
export default payrollsSlice.reducer;
