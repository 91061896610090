import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import React from 'react';
import InfoSvg from '../../../assets/images/icons/info.svg';

interface IdleDialogProps {
  handler: () => void;
  isOpen?: boolean;
  onYes?: () => void;
  onLogout?: () => void;
  className?: string;
}

const IdleDialog: React.FC<IdleDialogProps> = ({
  handler,
  isOpen = false,
  onYes = () => {},
  onLogout = () => {},
  className = '',
}) => (
  <Dialog
    size="xs"
    className={`dialog-idle ${className} bg-[#343434] px-5 py-2 rounded-2xl`}
    open={isOpen}
    handler={handler}
  >
    <DialogHeader className="p-3 text-base text-white">
      <div className="flex gap-3">
        <img
          // @todo should be in css
          style={{ filter: 'invert(0) invert(1)' }}
          className="w-6 h-6"
          src={InfoSvg}
          alt="Info for being idle for 10 minutes"
        />
        You have been idle for 10 minutes.
      </div>
    </DialogHeader>
    <DialogBody className="p-3 text-base text-white">
      <div className="flex flex-col gap-3">
        <p className="text-sm">Do you wish to be kept logged-in?</p>
      </div>
    </DialogBody>

    <DialogFooter>
      <div className="flex w-full justify-evenly gap-7">
        <button
          type="button"
          onClick={onYes}
          className="z-10 flex items-center justify-center px-4 py-2 font-bold rounded-full confirm-button"
        >
          YES
        </button>

        <button
          type="button"
          onClick={onLogout}
          className="z-10 flex items-center justify-center px-4 py-2 font-bold rounded-full cancel-button"
        >
          Logout
        </button>
      </div>
    </DialogFooter>
  </Dialog>
);

export default IdleDialog;
