import { createAsyncThunk } from '@reduxjs/toolkit';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import { StateHeaders } from '../../../../utils/apiFetch';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import myPayslipServices, {
  GetAllMyPayslipQueryParams,
} from '../../../services/my/payslip/myPayslipServices';
import Payslip from '../../../constants/payroll/payslip.interface';
import AtLeast from '../../../../types/atleast';

const getMy = createAsyncThunk(
  'payslips/getMy',
  async (
    id: Payslip['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myPayslipServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getMyAll = createAsyncThunk(
  'payslips/getMyAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllMyPayslipQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myPayslipServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getMyPayslips = createAsyncThunk(
  'my/payrolls/payslips',
  async (
    { employeeNumber, payPeriodFrom, payPeriodTo }: GetAllMyPayslipQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myPayslipServices.getMyPayslips(
        { employeeNumber, payPeriodFrom, payPeriodTo },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const printMyPayslips = createAsyncThunk(
  'my/payrolls/payslips/print',
  async (
    { employeeNumber, payPeriodFrom, payPeriodTo }: GetAllMyPayslipQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myPayslipServices.getMyPayslips(
        { employeeNumber, payPeriodFrom, payPeriodTo },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const myPost = createAsyncThunk(
  'my/payslips/myPost',
  async (
    data: Omit<Payslip, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myPayslipServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const myPut = createAsyncThunk(
  'my/payslips/myPut',
  async (
    data: AtLeast<Payslip, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myPayslipServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const myRemove = createAsyncThunk(
  'my/payslips/myRemove',
  async (
    id: Payslip['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myPayslipServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default {
  getMy,
  getMyAll,
  myPost,
  myPut,
  myRemove,
  getMyPayslips,
  printMyPayslips,
} as const;
