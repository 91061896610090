import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Company from '../../../store/constants/companies/company.interface';
import useCompanies from '../../../hooks/companies/useCompanies';
import toastNotification from '../../../hooks/utils/toastNotification';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';

export interface CompanyFormModalParams {
  company?: Company;
  className?: string;
  resetSort?: () => void;
  onClose: () => void;
}

const CompanyFormModal: React.FC<CompanyFormModalParams> = ({
  company = undefined,
  className = undefined,
  resetSort = () => {},
  onClose,
}) => {
  const [companyForm, setCompanyForm] = useState<Company | undefined>();
  const [open, setOpen] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const { post, put } = useCompanies();

  const handleClose = () => {
    setCompanyForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setCompanyForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<Company>) => {
    setIsLoading(true);
    if (companyForm) {
      const data: Company = {
        ...companyForm,
        ...values,
      };

      await toastNotification({
        action: put(data),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: () => {
          setIsLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Company information is successfully updated.',
          error: {
            render: ({ data: errorMsg }) =>
              typeof errorMsg === 'string' ? errorMsg : 'Error on updating Company information.',
          },
        },
      });
    } else {
      await toastNotification({
        action: post(values as Company),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: () => {
          setIsLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Company information is successfully added.',
          error: 'Error on adding Company information.',
        },
      });
    }

    setOpen(false);
  };

  useEffect(() => {
    if (company) {
      setOpen(true);
      setCompanyForm(company);
    }
  }, [company]);

  return (
    <>
      <button
        type="button"
        className={` ${className} button button--state-default gap-2`}
        onClick={handleOpen}
      >
        <Add className="w-5 h-5" />
        Add company
      </button>
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 sm-modal-size"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <Close className="w-5 h-auto" />
          </IconButton>
        </div>
        <div className="flex gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="white"
            className="modal-icons"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
            />
          </svg>

          <DialogHeader className="p-0 mt-1 text-gray-200 modal-header">
            Enter Company Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{
                  ...companyForm,
                  name: companyForm?.name ?? '',
                  code: companyForm?.code ?? '',
                  contact: companyForm?.contact ?? '',
                  address: companyForm?.address ?? '',
                }}
                onSubmit={handleSubmit}
              >
                <Form className="w-full">
                  <div className="mt-4 mb-8 form-section">
                    <div className="flex justify-between w-full">
                      <div className="first-section w-[45%]">
                        <div className="mb-4">
                          <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                            Company Name
                          </label>
                          <div className="">
                            <Field
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Company Name"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="name" component="div" />
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="code" className="block mb-2 text-gray-200 modal-labels">
                            Code
                          </label>
                          <div className="">
                            <Field
                              type="text"
                              id="code"
                              name="code"
                              placeholder="Enter Code"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="code" component="div" />
                          </div>
                        </div>
                      </div>
                      <div className="second-section w-[45%]">
                        <div className="mb-4">
                          <label
                            htmlFor="contact"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Contact
                          </label>
                          <div className="">
                            <Field
                              type="text"
                              id="contact"
                              name="contact"
                              placeholder="Enter Contact"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="contact" component="div" />
                          </div>
                        </div>

                        <div className="">
                          <label
                            htmlFor="address"
                            className="block mb-2 text-gray-200 modal-labels"
                          >
                            Address
                          </label>
                          <div className="">
                            <Field
                              as="textarea"
                              id="address"
                              name="address"
                              placeholder="Enter Address"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="address" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Buttons */}
                  <div className="flex gap-8">
                    <div className="flex justify-center items-center">
                      <ButtonAction
                        type="submit"
                        className="flex justify-center items-center rounded-md shadow-lg submit-loader-button"
                        isLoading={isloading}
                        hasSpinnerText={false}
                        isSubmit
                      />
                    </div>
                    <div className="flex justify-center w-fit">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default CompanyFormModal;
