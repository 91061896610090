import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import ScheduleType from '../../constants/employments/schedules/employment-schedule-type.interface';
import scheduleTypeActions from '../../actions/schedule/scheduleTypeActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface ScheduleTypesState {
  scheduleTypes: ScheduleType[];
}

const initialState: ScheduleTypesState = {
  scheduleTypes: [
    {
      id: 1,
      name: 'Regular',
      daysPerWeek: 5,
      daysPerMonth: 21.75,
      hours: 9,
      breakLength: 1,
    },
    {
      id: 2,
      name: '4x11',
      daysPerWeek: 4,
      daysPerMonth: 17.42,
      hours: 11,
      breakLength: 1,
    },
    {
      id: 3,
      name: '6x8',
      daysPerWeek: 6,
      daysPerMonth: 26,
      hours: 9,
      breakLength: 2,
    },
    {
      id: 4,
      name: 'Consultant',
      daysPerWeek: 5,
      daysPerMonth: 21,
      hours: 4,
      breakLength: 1,
    },
  ],
};

const getScheduleTypeData = (scheduleTypes: Partial<ScheduleType>) => ({
  ...scheduleTypes,
});

const upsertItems = (scheduleTypes: ScheduleType[], ...data: ScheduleType[]) => {
  const dataObject: { [id: number]: ScheduleType } = {};
  scheduleTypes.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((scheduleType) => {
    if (!dataObject[scheduleType.id]) {
      dataObject[scheduleType.id] = scheduleType;
    } else if (dataObject[scheduleType.id]) {
      dataObject[scheduleType.id] = { ...dataObject[scheduleType.id], ...scheduleType };
    }
  });

  return Object.values(dataObject);
};

const scheduleTypesSlice = createSlice({
  name: 'scheduleTypes',
  initialState,
  reducers: {
    resetScheduleTypesState: () => ({
      ...initialState,
    }),
    setScheduleTypesState: (state, action: PayloadAction<Partial<ScheduleTypesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertScheduleType: (state, action: PayloadAction<Partial<ScheduleType>>) => {
      const data: ScheduleType = getScheduleTypeData(action.payload) as ScheduleType;

      if (typeof data.id === 'undefined') return;

      state.scheduleTypes = upsertItems(state.scheduleTypes, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(scheduleTypeActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<ScheduleType>;
      consoleLog('SCHEDULETYPE-REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.scheduleTypes = upsertItems(state.scheduleTypes, data);
      }
    });
    builder.addCase(scheduleTypeActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULETYPE-REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<ScheduleType>;

      if (status === 503) return;

      if (data) {
        state.scheduleTypes = data;
      }
    });
    builder.addCase(scheduleTypeActions.get.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULETYPE-REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<ScheduleType>;

      if (typeof data.id === 'undefined') return;
      state.scheduleTypes = upsertItems(state.scheduleTypes, data);
    });
    builder.addCase(scheduleTypeActions.put.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULETYPE-REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<ScheduleType>;

      if (typeof data.id === 'undefined') return;
      state.scheduleTypes = upsertItems(state.scheduleTypes, data);
    });
    builder.addCase(scheduleTypeActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULETYPE-REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<ScheduleType>;

      if (typeof data.id === 'undefined') return;
      state.scheduleTypes = state.scheduleTypes.filter(
        (scheduleType) => scheduleType.id !== data.id,
      );
    });
  },
});

export const scheduleTypesReducerActions = scheduleTypesSlice.actions;
export default scheduleTypesSlice.reducer;
