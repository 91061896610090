import React from 'react';
import { Typography } from '@material-tailwind/react';

const PayrollDefaultView: React.FC = () => (
  <div className="flex justify-center items-center">
    <div className="payroll-navbar-logo__home flex flex-col justify-center items-center">
      <Typography className="payroll-navbar-logo__text">AWESOME CX</Typography>
      <span className="payroll-navbar-logo__subtext place-self-end">by Transcom</span>
    </div>
  </div>
);

export default PayrollDefaultView;
