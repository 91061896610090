import { useAppDispatch, useAppSelector } from '../../store/store';
import payslipConcernActions from '../../store/actions/my/payslipConcernActions';
import {
  PayslipConcernsState,
  payslipConcernsReducerActions,
} from '../../store/reducers/my/payslipConcernsReducer';
import PayslipConcern from '../../store/constants/my/payslip-concern.interface';

const usePayslipConcerns = () => {
  const dispatch = useAppDispatch();
  const payslipConcernsState: PayslipConcernsState = useAppSelector(
    (state) => state.payslipConcerns,
  );

  const resetPayslipConcernState = () => {
    dispatch(payslipConcernsReducerActions.resetPayslipConcernsState());
  };

  const setPayslipConcernState = (state: Partial<PayslipConcernsState>) => {
    dispatch(payslipConcernsReducerActions.setPayslipConcernsState(state));
  };

  const get = async (id: PayslipConcern['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payslipConcernActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payslipConcernActions.getAll({}));

  const post = async (data: Omit<PayslipConcern, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payslipConcernActions.post(data));

  const put = async (data: Partial<PayslipConcern>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payslipConcernActions.put(data));

  const remove = async (id: PayslipConcern['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payslipConcernActions.remove(id));

  return {
    resetPayslipConcernState,
    payslipConcernsState,
    setPayslipConcernState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default usePayslipConcerns;
