import React from 'react';
import moment from 'moment';
import { Tooltip } from '@material-tailwind/react';
import { EventContentArg } from '../../../store/constants/calendar/calendar.interface';
import { ReactComponent as Exclamation } from '../../../assets/images/icons/exclamation-circle.svg';

const EventContentHandler = (arg: EventContentArg) => {
  const { event } = arg;

  if (!event) {
    return null;
  }

  if (event.classNames.includes('present')) {
    const isLate = event.title.toLowerCase().includes('late');
    const timeIn = event.extendedProps?.timeIn;
    const timeOut = event.extendedProps?.timeOut;
    const attendanceLabel = isLate ? 'Late' : 'Attendance';
    const content = `${attendanceLabel}: ${timeIn} - ${timeOut}`;
    return (
      <Tooltip content={content} className="calendar-tooltip" placement="bottom">
        <div className={`${isLate ? ' late' : ' onTime'}`} />
      </Tooltip>
    );
  }

  if (event.classNames.includes('holiday-event')) {
    return (
      <Tooltip
        content={event.extendedProps?.holiday}
        placement="bottom"
        className="max-w-[240px] calendar-tooltip"
      >
        <div className="holiday-calendar-event" />
      </Tooltip>
    );
  }

  if (event.classNames.includes('overtime-event')) {
    const overtimeTimeIn = event.extendedProps?.timeIn;
    const overtimeTimeOut = event.extendedProps?.timeOut;
    const content = `Overtime: ${overtimeTimeIn} - ${overtimeTimeOut}`;
    return (
      <Tooltip content={content} className="max-w-[240px] calendar-tooltip" placement="bottom">
        <div className="overtime-approved-event" />
      </Tooltip>
    );
  }

  if (event.classNames.includes('leave-pending')) {
    return (
      <Tooltip
        content={`${event.extendedProps?.leavePending} (Pending)`}
        placement="bottom"
        className="max-w-[240px] calendar-tooltip"
      >
        <div className="leave-pending-event" />
      </Tooltip>
    );
  }

  if (event.classNames.includes('leave-approved')) {
    return (
      <Tooltip
        content={`${event.extendedProps?.leaveApproved} (Approved)`}
        placement="bottom"
        className="max-w-[240px] calendar-tooltip"
      >
        <div className="leave-approved-event" />
      </Tooltip>
    );
  }

  if (event.classNames.includes('leave-denied')) {
    return (
      <Tooltip
        content={`${event.extendedProps?.leaveDenied} (Denied)`}
        placement="bottom"
        className="max-w-[240px] calendar-tooltip"
      >
        <div className="leave-denied-event" />
      </Tooltip>
    );
  }

  if (event.classNames.includes('undertime-event')) {
    const undertime = event.extendedProps?.undertime || '';
    const contentTime = moment(undertime, 'HH:mm:ss').format('hh:mm A');
    const undertimeContent = `Undertime: ${contentTime}`;
    return (
      <Tooltip
        content={undertimeContent}
        placement="bottom"
        className="max-w-[240px] calendar-tooltip"
      >
        <div className="undertime-calendar-event" />
      </Tooltip>
    );
  }

  if (event.classNames.includes('absent')) {
    return (
      <Tooltip
        content={
          <p className="flex items-center gap-1">
            <Exclamation className="h-4 w-auto flex-shrink-0" />
            <span>Absent</span>
          </p>
        }
        placement="bottom"
        className="max-w-[240px] calendar-tooltip"
      >
        <div className="event-absent" />
      </Tooltip>
    );
  }

  return null;
};

export default EventContentHandler;
