import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useUserForm from '../../../hooks/users/useUserForm';
import useUser from '../../../hooks/users/useUser';
import ContactAddressInfo from './Form/ContactAddressInfo';
import EmergencyInfo from './Form/EmergencyInfo';
import PayrollInfo from './Form/PayrollInfo';
import EmploymentInfo from './Form/EmploymentInfo';
import UserBasicInfoForm from './Form/UserBasicInfoForm';
import User from '../../../store/constants/users/user.interface';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import ManagementHeading from '../../atoms/ManagementHeading';
import PreviousButton from '../../atoms/buttons/PreviousButton';

const UserUpdateForm: React.FC = () => {
  const navigate = useNavigate();
  const { userFormState, setUserFormState, resetUserFormState } = useUserForm();
  const { id } = useParams<{
    id: string;
  }>();

  const { currentUser: user, loading } = useUser(id as string);

  useEffect(() => {
    if (user && userFormState.user.id !== user.id) {
      setUserFormState({ user });
    }
  }, [setUserFormState, user, userFormState.user.id]);

  useOnLoad(() => () => {
    resetUserFormState();
  });

  if (!user) {
    navigate('/admin/users');
    return null;
  }

  const loadedUser = userFormState.user as User;

  return (
    <div className="user-update__form">
      <div className="flex m-5">
        <ManagementHeading title="Edit Employee" subtitle="Profile" />
        <div className="ml-auto flex items-center">
          <PreviousButton />
        </div>
      </div>
      <div className="w-full pr-5 pb-5 user-update__content">
        <div className="grid grid-cols-3 gap-5 ">
          <div className="basic-information">
            <UserBasicInfoForm isLoading={loading} user={loadedUser} />
          </div>
          <div className="contact-address-information">
            <ContactAddressInfo isLoading={loading} user={loadedUser} />
          </div>
          <div className="emergency-information">
            <EmergencyInfo isLoading={loading} user={loadedUser} />
          </div>
          <div className="employment-information">
            <EmploymentInfo isLoading={loading} user={loadedUser} />
          </div>
          <div className="payroll-information">
            <PayrollInfo isLoading={loading} user={loadedUser} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserUpdateForm;
