const toURLParams = <T>(params: Record<string, T>) => {
  const keys = Object.keys(params);

  const filteredParams = keys
    .filter((key) => {
      const value = params[key];
      return value !== undefined && value !== null && value !== '';
    })
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return value.map((v) => `${key}[]=${v}`).join('&');
      }
      return `${key}=${value}`;
    })
    .join('&');

  if (filteredParams.length === 0) {
    return '';
  }

  return `${filteredParams}`;
};

export default toURLParams;
