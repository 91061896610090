import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import ClientManager from '../../constants/clients/client-manager.interface';
import clientManagerServices, {
  GetAllClientManagersQueryParams,
} from '../../services/clients/clientManagerServices';

const get = createAsyncThunk(
  'clientManager/get',
  async (
    data: ClientManager,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ClientManager> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientManagerServices.get(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'clientManager/getAll',
  async (
    { data, page = 1, limit = 50, search = '' }: GetAllClientManagersQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<ClientManager> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientManagerServices.getAll({ data, page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'clientManager/post',
  async (
    data: Omit<ClientManager, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ClientManager> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientManagerServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'clientManager/put',
  async (
    data: AtLeast<ClientManager, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ClientManager> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientManagerServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'clientManager/remove',
  async (
    data: ClientManager,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ClientManager> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientManagerServices.remove(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
