import React, { HTMLAttributes } from 'react';

interface UploadImageButtonProps extends HTMLAttributes<HTMLDivElement> {
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadImageButton: React.FC<UploadImageButtonProps> = ({
  className = '',
  handleImageChange,
}) => (
  <div className={`upload-image-button ${className}`}>
    <input type="file" accept="image/*" onChange={handleImageChange} />
  </div>
);

export default UploadImageButton;
