import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Typography,
} from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import useRealTimeDate from '../../../../../hooks/utils/useRealTimeDate';
import useGreetingTime from '../../../../../hooks/utils/useGreetingTime';
import CustomRangeDatePicker from '../../../../atoms/datepickers/CustomRangeDatePicker';
import LeaveApplication from '../../../../../store/constants/leaves/leave-application.interface';
import useAuth from '../../../../../hooks/auths/useAuth';

interface LeaveFormModalParams {
  activeLeaveApplication?: LeaveApplication;
}

const UserAttendanceActiveLeave: React.FC<LeaveFormModalParams> = ({
  activeLeaveApplication = undefined,
}) => {
  const { loggedUser } = useAuth();
  const currentDateTime = useRealTimeDate();
  const greetingTime = useGreetingTime();

  const [open, setOpen] = useState(false);

  const convertedExcludedDates = activeLeaveApplication?.excludedDates.map(
    (dateFormat) => new Date(dateFormat),
  );

  useEffect(() => {
    if (activeLeaveApplication) {
      setOpen(true);
    }
  }, [activeLeaveApplication]);

  return (
    <Dialog
      size="xs"
      open={open}
      handler={() => setOpen(false)}
      className="user-active-leave__container rounded-xl p-3"
    >
      <DialogHeader className="flex justify-center">
        <div className="user-active-leave__header">
          <span className="uppercase text-xs">
            {moment(currentDateTime).format('MMMM YYYY hh:mm A')}
          </span>
        </div>
      </DialogHeader>
      <DialogBody className="">
        <div className="flex flex-col justify-center user-active-leave__body">
          <Typography className="muiTypography--headline5 mb-5">
            {`Hello ${loggedUser?.fullName}👋, Awesome ${greetingTime}!`}
          </Typography>
          <div className="flex leave-content__wrapper">
            <div className="w-full flex gap-1">
              <p className="w-fit">You are currently on</p>
              <span className="active-leave-text">leave</span>
            </div>
            <CustomRangeDatePicker
              id="duration"
              name="duration"
              className="w-full active-leave-duration"
              placeholder="Leave Duration"
              popperPlacement="right-end"
              popperClassName="m-0"
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [155, 24],
                  },
                },
              ]}
              excludedDates={convertedExcludedDates}
              dateStart={
                activeLeaveApplication ? new Date(activeLeaveApplication.duration[0]) : new Date()
              }
              dateEnd={
                activeLeaveApplication
                  ? new Date(
                      activeLeaveApplication.duration[activeLeaveApplication.duration.length - 1],
                    )
                  : new Date()
              }
              readOnly
            />
          </div>
          <div className="mt-2 active-leave__view">
            <Link to="/my/leave/history" target="_blank">
              <span>View leave History</span>
            </Link>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" onClick={() => setOpen(false)} className="user-active-leave__button">
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default UserAttendanceActiveLeave;
