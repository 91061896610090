import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useMyLeaves from '../../../../hooks/leave/useMyLeaves';
import useLeave from '../../../../hooks/leave/useLeave';
import LeaveApplicationForm from './LeaveApplicationForm';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import LeaveSkeletalLoader from './LeaveSkeletalLoader';

const LeaveEditForm: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{
    id: string;
  }>();

  const { leaveCurrentData: leave, loading: isLoading } = useLeave(id as string);
  const { myPut } = useMyLeaves();
  const [loading, setLoading] = useState(false);

  if (isLoading) return null;

  // return a loader to prevent state being pass emmediately
  if (!leave && !isLoading) return <LeaveSkeletalLoader />;

  const handleSubmit = async (data: Partial<LeaveApplication>) => {
    setLoading(true);
    // const fileNames = data?.attachments?.map((file) => file.name);

    const {
      notes,
      isPaid,
      employmentScheduleType,
      leaveNature,
      duration,
      excludedDates,
      id: leaveId,
    } = data;

    const putData = {
      id: leaveId,
      // attachments: fileNames,
      notes,
      isPaid,
      employmentScheduleType,
      leaveNature,
      duration,
      excludedDates,
    };

    await toastNotification({
      action: myPut(putData as LeaveApplication),
      onSuccess: () => {
        navigate('/my/leave/history');
      },
      onFulfilled: () => {
        setLoading(false);
      },
      toastPromiseMessages: {
        pending: 'Sending your leave application...',
        success:
          'Leave application is successfully updated. Please inform your superior for approval.',
        error: 'Something went wrong! Please try again.',
      },
    });
  };

  return (
    <div className="h-full">
      <LeaveApplicationForm
        setLoading={setLoading}
        onSubmit={handleSubmit}
        loading={loading}
        leave={leave}
      />
    </div>
  );
};

export default LeaveEditForm;
