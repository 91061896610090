import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { Spinner, Tooltip } from '@material-tailwind/react';
import AcxIcons from '../../../assets/images/icons/AcxIcons';
import Department from '../../../store/constants/departments/department.interface';
import ClientManager from '../../../store/constants/clients/client-manager.interface';
import SelectEmploymentRole from '../../atoms/dropdowns/SelectEmploymentRole';
import EmploymentRole from '../../../store/constants/employments/roles/employment-role.interface';
import SelectDepartment from '../../atoms/dropdowns/SelectDepartment';
import SelectUserEmploymentByRole from '../../atoms/dropdowns/SelectEmployee';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import Client from '../../../store/constants/clients/client.interface';
import useClients from '../../../hooks/clients/useClients';
import AddInsideCircle from '../../../assets/images/icons/add-inside-circle.svg';
import { ReactComponent as Save } from '../../../assets/images/icons/save.svg';

interface FormValues {
  clientManagers: ClientManager[];
}

const ClientManagerDynamicForm: React.FC<{ client: Client }> = ({ client }) => {
  const { Close } = AcxIcons();
  const { put } = useClients();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues: FormValues = {
    clientManagers: [],
  };

  const handleAddFieldInstance = (
    values: FormValues,
    setFieldValue: (field: string, value: Partial<ClientManager>[]) => void,
  ) => {
    const newClientManagerFieldInstance: Partial<ClientManager> = {
      department: undefined,
      employmentRole: undefined,
      employee: undefined,
      dateStart: client.dateStart,
      dateEnd: undefined,
    };
    setFieldValue('clientManagers', [...values.clientManagers, newClientManagerFieldInstance]);
  };

  const handleRemoveFieldInstance = (
    values: FormValues,
    index: number,
    setFieldValue: (field: string, value: Partial<ClientManager>[]) => void,
  ) => {
    const updatedFields = [...values.clientManagers];
    updatedFields.splice(index, 1);
    setFieldValue('clientManagers', updatedFields);
  };

  const handleSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    setIsLoading(true);
    const data: Partial<Client> = {
      ...client,
      clientManagers: values.clientManagers
        .filter((clientManager) => !!clientManager.employee)
        .map((clientManager) => clientManager),
    } as Partial<Client>;

    put(data).then(() => {
      resetForm();
      setIsLoading(false);
    });
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form className="flex flex-col align-end w-full">
            <div className="client-manager-create-submit-container">
              <Tooltip content="Add manager field">
                <button
                  type="button"
                  onClick={() => handleAddFieldInstance(values, setFieldValue)}
                  className=" create-client-manager-button"
                >
                  <img src={AddInsideCircle} className="client-icons" alt="Export" />
                </button>
              </Tooltip>

              {values.clientManagers.length > 0 && (
                <Tooltip content={`Submit manager${values.clientManagers.length > 1 ? 's' : ''}`}>
                  <button type="submit" className="submit-client-manager-button">
                    {isLoading ? (
                      <Spinner height="1rem" color="deep-orange" />
                    ) : (
                      <Save className="submit-icon" />
                    )}
                  </button>
                </Tooltip>
              )}
            </div>

            {values.clientManagers.map((clientManagers, index) => {
              const setSelectedEmploymentRole = (value?: EmploymentRole) => {
                setFieldValue(`clientManagers[${index}].employmentRole`, value).then();
              };

              const setSelectedDepartment = (value?: Department) => {
                setFieldValue(`clientManagers[${index}].department`, value).then();
              };

              const setSelectedUserEmployment = (value?: UserEmployment) => {
                setFieldValue(`clientManagers[${index}].employee`, value).then();
              };

              const currentClientManager = values.clientManagers[index];
              return (
                <div
                  // eslint-disable-next-line
                  key={index}
                  className="client-row-text-sm client-manager-list-data list-data-clients flex items-center p-2"
                >
                  <div>
                    <SelectDepartment
                      id={`clientManagers[${index}].department`}
                      name={`clientManagers[${index}].department`}
                      value={currentClientManager.department}
                      isSearchable
                      isClearable
                      placeholder="Department"
                      onChange={(value) => setSelectedDepartment(value as Department)}
                    />
                    <ErrorMessage name={`clientManagers[${index}].department`} component="div" />
                  </div>
                  <div>
                    <SelectEmploymentRole
                      id={`clientManagers[${index}].employmentRole`}
                      name={`clientManagers[${index}].employmentRole`}
                      value={currentClientManager.employmentRole}
                      isSearchable
                      isClearable
                      placeholder="Role"
                      onChange={(value) => setSelectedEmploymentRole(value as EmploymentRole)}
                    />
                    <ErrorMessage name={`clientManagers.${index}.employmentRole`} component="div" />
                  </div>
                  <div>
                    <SelectUserEmploymentByRole
                      id={`clientManagers.${index}.employee`}
                      name={`clientManagers.${index}.employee`}
                      // @TODO: Temporarily disabled
                      // allowedRolesByCode={
                      //   currentClientManager.employmentRole?.code
                      //     ? currentClientManager.employmentRole.code.split(',')
                      //     : undefined
                      // }
                      value={currentClientManager.employee}
                      isClearable
                      isSearchable
                      placeholder="Employee"
                      onChange={(value) => setSelectedUserEmployment(value as UserEmployment)}
                    />
                    <ErrorMessage name={`clientManagers[${index}].employee`} component="div" />
                  </div>

                  <div className="field-content">
                    <Field
                      type="date"
                      id={`clientManagers[${index}].dateStart`}
                      name={`clientManagers[${index}].dateStart`}
                      placeholder="Date start"
                      required
                      className="p-2 modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                    />
                    <ErrorMessage name={`clientManagers[${index}].dateStart`} component="div" />
                  </div>
                  <div className="field-content">
                    <Field
                      type="date"
                      id={`clientManagers[${index}].dateEnd`}
                      name={`clientManagers[${index}].dateEnd`}
                      placeholder="Date end"
                      className="p-2 modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                    />
                    <ErrorMessage name={`clientManagers[${index}].dateEnd`} component="div" />
                  </div>
                  <div className="flex space-x-5 w-max justify-self-center">
                    <div className="flex justify-center w-max">
                      <button
                        type="button"
                        onClick={() => handleRemoveFieldInstance(values, index, setFieldValue)}
                      >
                        <Close className="client-manager-add__icon" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ClientManagerDynamicForm;
