import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Payroll from '../../constants/payroll/payroll.interface';
import { DateRange } from '../clients/clientServices';
import Company from '../../constants/companies/company.interface';
import Team from '../../constants/teams/team.interface';
import toURLParams from '../../../utils/toURLParams';
import AtLeast from '../../../types/atleast';
import Employee from '../../constants/employees/employee.interface';
import Payslip from '../../constants/payroll/payslip.interface';

export interface GetAllPayrollQueryParams extends ApiGetAllBaseQueryParams {
  payPeriod?: DateRange;
  employeeNumbers?: Employee['employeeNumber'][];
  companyId?: Company['id'];
  teamId?: Team['id'][];
  inactive?: boolean;
  resigned?: boolean;
  terminated?: boolean;
  suspended?: boolean;
  endOfContract?: boolean;
  authorizedCause?: boolean;
  dropTraining?: boolean;
  resignedWithCase?: boolean;
  awol?: boolean;
  floating?: boolean;
  lastPayIncentives?: boolean;
  lock?: boolean;
}

const get = async (id: Payroll['id'], state: StateHeaders): Promise<ApiBaseResponse<Payroll>> =>
  apiFetch<ApiBaseResponse<Payroll>>(`payrolls/${id}`, { state })
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payroll>> =>
  apiFetch<ApiGetAllBaseResponse<Payroll>>(
    `payrolls/employees?limit=${toURLParams({
      limit,
      page,
      search,
    })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllLatesUndertime = async (
  { page = 0, payPeriod }: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payroll>> =>
  apiFetch<ApiGetAllBaseResponse<Payroll>>(
    `payrolls/getAllLatesUndertime/excel_report/generate_late_undertime/export-late-undertime?${toURLParams(
      {
        page,
        payPeriod,
      },
    )}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getExports = async (
  { page = 0, payPeriod }: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payroll>> =>
  apiFetch<ApiGetAllBaseResponse<Payroll>>(
    `payrolls/?${toURLParams({
      page,
      payPeriod,
    })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getLastpays = async (
  { page = 1, limit = 50, search = '', teamId }: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payroll>> =>
  apiFetch<ApiGetAllBaseResponse<Payroll>>(
    `payrolls/lastpays?${toURLParams({
      limit,
      page,
      search,
      ...(teamId && { teamId }),
    })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getPayPeriods = async (state: StateHeaders): Promise<ApiGetAllBaseResponse<string[]>> =>
  apiFetch<ApiGetAllBaseResponse<string[]>>('payrolls/payperiods', { state })
    .then((response: ApiGetAllBaseResponse<string[]>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getEmployeePayrolls = async (
  {
    page = 1,
    limit = 50,
    search = '',
    payPeriod,
    employeeNumbers: employee_numbers,
    companyId,
    teamId: team_ids,
    inactive,
    resigned,
    terminated,
    suspended,
    endOfContract,
    authorizedCause,
    dropTraining,
    resignedWithCase,
    awol,
    floating,
    lastPayIncentives,
  }: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payroll>> =>
  apiFetch<ApiGetAllBaseResponse<Payroll>>(
    `payrolls/employees?${toURLParams({
      limit,
      page,
      search,
      ...(payPeriod && {
        pay_period_from: payPeriod.from,
        pay_period_to: payPeriod.to,
      }),
      ...(employee_numbers && { employee_numbers }),
      companyId,
      ...(team_ids && { team_ids }),
      inactive,
      resigned,
      terminated,
      suspended,
      endOfContract,
      authorizedCause,
      dropTraining,
      resignedWithCase,
      awol,
      floating,
      lastPayIncentives,
    })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getEmployeeGross = async (
  {
    scheduleTypeId,
    monthlyGross,
    basicGross,
  }: { scheduleTypeId: number | undefined; monthlyGross: number; basicGross: number },
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll['employeeNumber']>> =>
  apiFetch<ApiBaseResponse<Payroll['employeeNumber']>>(
    `payrolls/employees/gross?${toURLParams({
      scheduleTypeId,
      monthlyGross,
      basicGross,
    })}`,
    { state },
  )
    .then((response: ApiBaseResponse<Payroll['employeeNumber']>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getPayslips = async (
  {
    page = 1,
    limit = 50,
    search = '',
    payPeriod,
    employeeNumbers: employee_numbers,
    companyId,
    teamId: team_ids,
  }: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payslip>> =>
  apiFetch<ApiGetAllBaseResponse<Payslip>>(
    `payrolls/payslips?${toURLParams({
      limit,
      page,
      search,
      ...(payPeriod && {
        pay_period_from: payPeriod.from,
        pay_period_to: payPeriod.to,
      }),
      ...(employee_numbers && { employee_numbers }),
      companyId,
      ...(team_ids && { team_ids }),
    })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getCompanyITR = async (
  id: Company['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll>> =>
  apiFetch<ApiBaseResponse<Payroll>>(`payrolls/companies/${id}/itr`, { state })
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const createPayslip = async (
  data: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll> | void> =>
  apiFetch<ApiBaseResponse<Payroll>>(
    `payrolls/employees/payslip?${
      toURLParams({
        ...(data.payPeriod && {
          pay_period_from: data.payPeriod.from,
          pay_period_to: data.payPeriod.to,
        }),
        ...(data.employeeNumbers && { employee_numbers: data.employeeNumbers }),
      }) ?? ''
    }`,
    {
      state,
      options: {
        method: 'POST',
        body: data,
      },
    },
  )
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const addIncentives = async (
  data: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll> | void> =>
  apiFetch<ApiBaseResponse<Payroll>>('payrolls/incentives', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const generateThirteenMonth = async (
  { payPeriod }: GetAllPayrollQueryParams,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll> | void> =>
  apiFetch<ApiBaseResponse<Payroll>>('payrolls/13months', {
    state,
    options: {
      method: 'POST',
      body: payPeriod,
    },
  })
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const createLastpay = async (
  data: Omit<Payroll, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll> | void> =>
  apiFetch<ApiBaseResponse<Payroll>>('payrolls/lastpays', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const updateTeamPHICBasic = async (
  data: AtLeast<Payroll, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll> | void> =>
  apiFetch<ApiBaseResponse<Payroll>>(`payrolls/teams/${data.teamId}/phic/basic`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const updateTeamPHICGross = async (
  data: AtLeast<Payroll, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payroll> | void> =>
  apiFetch<ApiBaseResponse<Payroll>>(`payrolls/teams/${data.teamId}/phic/gross`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Payroll>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  getExports,
  getLastpays,
  getPayPeriods,
  getEmployeePayrolls,
  getPayslips,
  addIncentives,
  getCompanyITR,
  createPayslip,
  createLastpay,
  updateTeamPHICGross,
  updateTeamPHICBasic,
  getAllLatesUndertime,
  generateThirteenMonth,
  getEmployeeGross,
} as const;
