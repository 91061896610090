import { useState, useEffect } from 'react';
import moment from 'moment';
import EmployeeSchedule from '../../store/constants/employees/schedules/employee-schedule.interface';

interface TimeDiff {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const useNextUserScheduleTimeDiff = (schedule: EmployeeSchedule | undefined) => {
  const [timeDiff, setTimeDiff] = useState<TimeDiff>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!schedule || !schedule.timeIn) {
      setTimeDiff({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      return;
    }

    const scheduleDays = schedule.days?.split(',').map(Number) || [];
    const scheduleTimeIn = moment(schedule.timeIn, 'HH:mm:ss');
    const currentMoment = moment();
    const currentDay = currentMoment.isoWeekday();

    // Find next schedule occurrence
    let nextOccurrence: moment.Moment;
    if (scheduleDays.includes(currentDay) && scheduleTimeIn.isSameOrAfter(currentMoment)) {
      // If schedule is today and time is in the future
      nextOccurrence = currentMoment.clone().set({
        hour: scheduleTimeIn.hours(),
        minute: scheduleTimeIn.minutes(),
        second: scheduleTimeIn.seconds(),
      });
    } else {
      // Find next schedule occurrence in the future days
      const futureDays = scheduleDays.filter((day) => day > currentDay);
      if (futureDays.length > 0) {
        const nextDay = Math.min(...futureDays);
        nextOccurrence = currentMoment
          .clone()
          .add(nextDay - currentDay, 'days')
          .startOf('day')
          .set({
            hour: scheduleTimeIn.hours(),
            minute: scheduleTimeIn.minutes(),
            second: scheduleTimeIn.seconds(),
          });
      } else {
        const nextDay = Math.min(...scheduleDays) + 7;
        nextOccurrence = currentMoment
          .clone()
          .add(nextDay - currentDay, 'days')
          .startOf('day')
          .set({
            hour: scheduleTimeIn.hours(),
            minute: scheduleTimeIn.minutes(),
            second: scheduleTimeIn.seconds(),
          });
      }
    }

    // Calculate difference
    const diffMilliseconds = nextOccurrence.diff(currentMoment);
    const diffDuration = moment.duration(diffMilliseconds);

    setTimeDiff({
      days: diffDuration.days(),
      hours: diffDuration.hours(),
      minutes: diffDuration.minutes(),
      seconds: diffDuration.seconds(),
    });
  }, [schedule]);

  return timeDiff;
};

export default useNextUserScheduleTimeDiff;
