import React, { useEffect } from 'react';
import { Drawer } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import ClientManagement from '../organisms/client/ClientManagement';
import AttendancePage from '../organisms/reports/AttendancePage';
import TeamManagement from '../organisms/team/TeamManagement';
import UserDOHVaxReport from '../organisms/user/UserDOHVaxReport';
import UserCertificateOfEmployment from '../organisms/user/UserCertificateofEmployment';
import UserNewsFeed from '../organisms/user/newsfeed/UserNewsFeed';
import SidebarHome from '../organisms/user/sidebar/SidebarHomeUnused';
import UserNavbar from '../organisms/user/navbar/UserHeader';
import Handbook from '../organisms/user/handbook/Handbook';
import Workforce from '../organisms/user/workforce/Workforce';
import Policies from '../organisms/user/policies/Policies';
import ContactInfo from '../organisms/user/contactInfo/ContactInfo';
import Troubleshooting from '../organisms/user/troubleshooting/Troubleshooting';
import SideMenu from '../organisms/user/sidebar/SideMenu';
import useTheme from '../../hooks/utils/useTheme';
import useSettings from '../../hooks/utils/useSettings';
import SmallSidebar from '../organisms/user/sidebar/SmallSidebar';
import useMediaQuery from '../../hooks/utils/useMediaQuery';
import useAuth from '../../hooks/auths/useAuth';

const UserTemplate: React.FC = () => {
  const { authState } = useAuth();
  const { theme } = useTheme();
  const { settingStates, hideUserDrawer } = useSettings();
  const is2xl = useMediaQuery('(min-width: 1320px)');

  useEffect(() => {
    if (is2xl && settingStates.userDrawerOpen) {
      hideUserDrawer();
    }
    // eslint-disable-next-line
  }, [is2xl]);

  if (!authState.token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className={`parent-container ${theme}`}>
      <UserNavbar />
      <div className="user-container">
        <div className="sidebar-wrapper hidden">
          <SidebarHome />
        </div>
        <div className="sidemenu-container hidden 2xl:block">
          <div className="mt-[5.5rem]">
            <SideMenu />
          </div>
        </div>
        <Drawer
          title="AWESOME CX by Transcom"
          onClose={hideUserDrawer}
          open={settingStates.userDrawerOpen}
        >
          <SmallSidebar />
        </Drawer>
        <div className="main-page">
          <Routes>
            <Route path="/client" element={<ClientManagement />} />
            <Route path="/coe" element={<UserCertificateOfEmployment />} />
            <Route path="/team" element={<TeamManagement />} />
            <Route path="/doh/vax-report" element={<UserDOHVaxReport />} />
            <Route path="/osnet/troubleshooting" element={<Troubleshooting />} />
            <Route path="/newsfeed" element={<UserNewsFeed />} />
            <Route path="/handbook" element={<Handbook />} />
            <Route path="/workforce" element={<Workforce />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/contact-information" element={<ContactInfo />} />
            <Route path="/attendance" element={<AttendancePage />} />

            <Route path="*" element={<Navigate replace to="newsfeed" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserTemplate;
