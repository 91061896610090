import { useAppDispatch, useAppSelector } from '../../store/store';
import hmoPrincipalActions from '../../store/actions/my/hmoPrincipalActions';
import {
  HmoPrincipalsState,
  hmoPrincipalsReducerActions,
} from '../../store/reducers/my/hmoPrincipalsReducer';
import HmoPrincipalType from '../../store/constants/my/hmo-principal.interface';

const useHmoPrincipals = () => {
  const dispatch = useAppDispatch();
  const hmoPrincipalsState: HmoPrincipalsState = useAppSelector((state) => state.hmoPrincipals);

  const resetHmoPrincipalState = () => {
    dispatch(hmoPrincipalsReducerActions.resetHmoPrincipalsState());
  };

  const setHmoPrincipalState = (state: Partial<HmoPrincipalsState>) => {
    dispatch(hmoPrincipalsReducerActions.setHmoPrincipalsState(state));
  };

  const get = async (id: HmoPrincipalType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoPrincipalActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoPrincipalActions.getAll({}));

  const post = async (data: Omit<HmoPrincipalType, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoPrincipalActions.post(data));

  const put = async (data: Partial<HmoPrincipalType>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoPrincipalActions.put(data));

  const remove = async (id: HmoPrincipalType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoPrincipalActions.remove(id));

  return {
    resetHmoPrincipalState,
    hmoPrincipalsState,
    setHmoPrincipalState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useHmoPrincipals;
