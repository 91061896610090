import React, { useState } from 'react';
import moment from 'moment/moment';
import { FormikErrors } from 'formik';
import { IconButton } from '@material-tailwind/react';
import rangeDates from '../../../utils/rangeDates';
import uniqueDates from '../../../utils/uniqueDates';
import CustomRangeDatePicker from './CustomRangeDatePicker';
import DeleteSvg from '../../../assets/images/icons/delete.svg';
import AddInsideCircleSvg from '../../../assets/images/icons/add-inside-circle.svg';

interface DatePickerDateSelectionProps {
  id: string;
  name: string;
  placeholder?: string;
  selectedDays?: Date[];
  onChange?: (dates: Date[]) => void;
  className?: string;
  setFieldValue?: (
    field: string,
    value: Date[],
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<unknown>>;
}

const MultipleDatesPicker: React.FC<DatePickerDateSelectionProps> = ({
  id,
  name,
  selectedDays = [],
  onChange = undefined,
  placeholder = 'Select Duration',
  className = '',
  setFieldValue = undefined,
  ...props
}) => {
  const [dateStart, setDateStart] = useState<Date | undefined>();
  const [dateEnd, setDateEnd] = useState<Date | undefined>();

  const handleChangeDates = (dates: Date[]) => {
    if (onChange) onChange(dates);

    if (setFieldValue) {
      setFieldValue(name, dates);
    }
  };

  const handleAddClick = () => {
    if (dateStart && dateEnd) {
      // store only unique dates
      handleChangeDates(uniqueDates([...selectedDays, ...rangeDates(dateStart, dateEnd)]));

      // reset input
      setDateStart(undefined);
      setDateEnd(undefined);
    }
  };

  const handleDeleteDate = (index: number) => {
    // Remove the item with the specified ID from the fieldValues array
    const updatedDates = [...selectedDays];
    updatedDates.splice(index, 1);
    handleChangeDates(updatedDates);
  };

  return (
    <div className="w-full datePicker-wrapper">
      <div className="flex items-center mb-2">
        <CustomRangeDatePicker
          {...props}
          id={id}
          name={name}
          placeholder={placeholder}
          className={`multiple-dates-picker ${className}`}
          excludedDates={selectedDays}
          dateStart={dateStart}
          setDateStart={setDateStart}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
        />
        <IconButton
          color="blue-gray"
          size="md"
          variant="text"
          className="add-icon-button"
          onClick={handleAddClick}
        >
          <span>
            <img
              style={{ filter: 'invert(0) invert(1)' }}
              className="w-7 h-7"
              src={AddInsideCircleSvg}
              alt="Add Office"
            />
          </span>
        </IconButton>
      </div>
      <div className="w-full mb-2">
        <div className="flex flex-wrap items-center gap-3 multiple-date-container">
          {selectedDays.map((selectedDay, index) => {
            const date = moment(selectedDay).format('YYYY-MM-DD');
            return (
              <div
                key={date}
                className="rounded-lg shadow-lg bg-[#343434] p-1 text-white flex gap-3"
              >
                <div className="p-1 m-auto ml-2">{date}</div>
                <div className="">
                  <IconButton
                    color="blue-gray"
                    size="sm"
                    variant="text"
                    className="m-auto delete-icon-button"
                    onClick={() => handleDeleteDate(index)}
                  >
                    <img className="w-5 h-5 trash-icon" src={DeleteSvg} alt={`Delete ${date}`} />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultipleDatesPicker;
