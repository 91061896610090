import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { UserFormStateAction } from '../../../store/reducers/users/userFormReducer';
import UserListRow from './UserListRow';
import User from '../../../store/constants/users/user.interface';
import TextWithIcons from '../../molecules/TextWithIcons';
import useUsers from '../../../hooks/users/useUsers';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import consoleLog from '../../../utils/consoleLog';
import useUserForm from '../../../hooks/users/useUserForm';
import SearchSvg from '../../../assets/images/icons/search.svg';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import toastNotification from '../../../hooks/utils/toastNotification';
import useVariableItemSize from '../../../hooks/utils/useVariableItemSize';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions =
  | ''
  | 'id'
  | 'employeeNumber'
  | 'fullName'
  | 'position'
  | 'contactNumber'
  | 'birthDate';

const UserList: React.FC = () => {
  const navigate = useNavigate();
  const { resetUserFormState, setUserFormState } = useUserForm();
  const { usersState, getAll, get: getSelectedUser } = useUsers();
  const { suspendAccount, activateAccount } = useUserEmployments();
  const { getItemSize } = useVariableItemSize();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 2000);
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(false);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleAddUserClick = () => {
    resetUserFormState();
    navigate('/admin/user/manage/new');
  };

  const onEditClick = async (selectedUser: User) => {
    consoleLog('LOG_THIS', 'EDIT_CLICK', selectedUser);
    await getSelectedUser(selectedUser.id).then((data) => {
      const user: User = data.payload.data;
      setUserFormState({ action: UserFormStateAction.UPDATE, user });
    });
    navigate(`/admin/user/manage/${selectedUser.id}/summary`);
  };

  const handleAccountAction = async (
    data: User | undefined,
    actionType: 'suspend' | 'activate',
  ) => {
    if (!data) return;
    const action = actionType === 'suspend' ? suspendAccount : activateAccount;
    const successMessage = actionType === 'suspend' ? 'Account Suspended.' : 'Account Activated.';
    const errorMessage =
      actionType === 'suspend' ? 'Error on suspending account.' : 'Error on activating account.';

    await toastNotification({
      action: action(data.employment?.employeeNumber).then(() => getSelectedUser(data.id)),
      onSuccess: () => {},
      onError: () => {},
      onFulfilled: () => {},
      toastPromiseMessages: {
        pending: 'Processing',
        success: successMessage,
        error: errorMessage,
      },
    });
  };

  const onPositionClick = (user: User) => {
    setUserFormState({ user });
    navigate(`/admin/user/manage/${user.id}/position`);
  };

  const onTeamClick = (user: User) => {
    setUserFormState({ user });
    navigate(`/admin/user/manage/${user.id}/team`);
  };

  const users = usersState.users
    .filter((user: User) => {
      if (debouncedSearchValue.trim() === '') return true;

      if (!user) return false;

      const { id, firstName, lastName, contactNumber, dateBirth } = user;

      return (
        id?.toString().startsWith(debouncedSearchValue.toLowerCase()) ||
        firstName?.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        lastName?.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        contactNumber?.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        dateBirth?.toLowerCase().startsWith(debouncedSearchValue.toLowerCase())
      );
    })
    .sort((a: User, b: User) => {
      const sortOrder = sortOrderBy ? -1 : 1;
      const defaultOrder = b.id - a.id;

      if (sortOption === 'id') {
        return sortOrder * b.id - a.id;
      }

      if (sortOption === 'employeeNumber') {
        if (!a.employment && !b.employment) return sortOrder * -1;

        if (b.employment && !a.employment) return sortOrder * 1;

        if (a.employment && !b.employment) return sortOrder * -1;

        if (a.employment && b.employment)
          return sortOrder * b.employment.employeeNumber - a.employment.employeeNumber;
      }

      if (sortOption === 'fullName') {
        return (
          sortOrder *
          (a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName))
        );
      }

      if (sortOption === 'position') {
        if (!a.employment?.currentPosition && !b.employment?.currentPosition) return sortOrder * -1;

        if (b.employment?.currentPosition && !a.employment?.currentPosition) return sortOrder * 1;

        if (a.employment?.currentPosition && !b.employment?.currentPosition) return sortOrder * -1;

        if (a.employment?.currentPosition && b.employment?.currentPosition)
          return (
            sortOrder *
            a.employment.currentPosition.employmentPosition.name.localeCompare(
              b.employment.currentPosition.employmentPosition.name,
            )
          );
      }

      if (sortOption === 'contactNumber') {
        if (!a.contact) return -sortOrder;
        if (!b.contact) return sortOrder;
        return sortOrder * a.contactNumber.localeCompare(b.contactNumber);
      }

      if (sortOption === 'birthDate') {
        if (!a.dateBirth) return -sortOrder;
        if (!b.dateBirth) return sortOrder;
        return sortOrder * a.dateBirth.localeCompare(b.dateBirth);
      }
      return defaultOrder;
    });

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="px-5 organisms-user-list user-list-wrapper">
      <div className="flex justify-between pb-5">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search user"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for User"
          />
        </div>
        <button
          type="button"
          className="button button--state-default gap-2"
          onClick={handleAddUserClick}
        >
          <Add className="w-5 h-5" />
          Add user
        </button>
      </div>
      <div className="user-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        <button
          type="button"
          onClick={() => sortByHeader('id', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'id' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'id' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">ID</div>
          </TextWithIcons>
        </button>

        <button
          type="button"
          onClick={() => sortByHeader('employeeNumber', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'employeeNumber' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'employeeNumber' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Employee #</div>
          </TextWithIcons>
        </button>

        <button
          type="button"
          onClick={() => sortByHeader('fullName', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'fullName' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'fullName' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Full name</div>
          </TextWithIcons>
        </button>

        <button
          type="button"
          onClick={() => sortByHeader('position', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'position' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'position' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Position</div>
          </TextWithIcons>
        </button>

        <button
          type="button"
          onClick={() => sortByHeader('birthDate', false)}
          className="flex items-center justify-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'birthDate' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'birthDate' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Birth date</div>
          </TextWithIcons>
        </button>

        <TextWithIcons className="justify-self-center" variant="h6">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={users.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => {
          const user = users[index];
          return (
            <UserListRow
              data={user}
              className={index % 2 === 0 ? 'stripped' : ''}
              style={style}
              onEditClick={() => onEditClick(user)}
              onPositionClick={() => onPositionClick(user)}
              onTeamClick={() => onTeamClick(user)}
              handleAccountAction={handleAccountAction}
            />
          );
        }}
      </VariableSizeList>
    </div>
  );
};

export default UserList;
