import apiFetch, { StateHeaders } from '../../../../utils/apiFetch';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import UserEmploymentTeam from '../../../constants/employees/teams/employee-team.interface';
import ApiGetAllBaseQueryParams from '../../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import AtLeast from '../../../../types/atleast';

export type GetAllUserEmploymentTeamsQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: UserEmploymentTeam['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentTeam>> =>
  apiFetch<ApiBaseResponse<UserEmploymentTeam>>(`employees/teams/${id}`, { state })
    .then((response: ApiBaseResponse<UserEmploymentTeam>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllUserEmploymentTeamsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<UserEmploymentTeam>> =>
  apiFetch<ApiGetAllBaseResponse<UserEmploymentTeam>>(
    `employees/teams?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<UserEmploymentTeam>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<UserEmploymentTeam, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentTeam> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentTeam>>('employees/teams', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentTeam>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: UserEmploymentTeam,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentTeam> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentTeam>>(`employees/teams/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentTeam>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<UserEmploymentTeam, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentTeam> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentTeam>>(`employees/teams/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentTeam>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: UserEmploymentTeam['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentTeam> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentTeam>>(`employees/teams/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentTeam>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getUser = async (
  id: UserEmploymentTeam['employeeNumber'] | undefined,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentTeam>> =>
  apiFetch<ApiBaseResponse<UserEmploymentTeam>>(`employees/teams/${id}/user`, { state })
    .then((response: ApiBaseResponse<UserEmploymentTeam>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  getUser,
} as const;
