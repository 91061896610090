import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from '@material-tailwind/react';
import momentFormat from '../../../../utils/momentFormat';
import useMyLeaves from '../../../../hooks/leave/useMyLeaves';
import useAuth from '../../../../hooks/auths/useAuth';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import { ReactComponent as DocumentFileIcon } from '../../../../assets/images/icons/document-magnifying-glass.svg';
import { ReactComponent as SadLife } from '../../../../assets/images/icons/sad-face.svg';

const LeaveRightSideBar: React.FC = () => {
  const {
    authState: { userInformation },
  } = useAuth();

  const { myLeavesState, getMyAll } = useMyLeaves();

  const [isLoading, setIsLoading] = useState(false);

  const currentYear = new Date().getFullYear();

  const leaves = myLeavesState.myLeaves
    .filter((leave: LeaveApplication) => {
      if (leave.employeeNumber !== userInformation?.employeeNumber) return false;

      const leaveYear = new Date(leave.dateCreated).getFullYear();
      return leaveYear === currentYear;
    })
    .sort((a: LeaveApplication, b: LeaveApplication) => {
      const dateA = new Date(a.dateCreated).getTime();
      const dateB = new Date(b.dateCreated).getTime();

      return dateB - dateA;
    });

  const getLeaveStatus = (data: LeaveApplication) => {
    if (data.dateApproved) {
      return 'Approved';
    }
    if (data.dateDenied) {
      return 'Denied';
    }
    return 'Pending';
  };

  useOnLoad(() => {
    setIsLoading(true);

    getMyAll().then(() => {
      setIsLoading(false);
    });
  });

  return (
    <div className="basis-[30%] leave-sidebar__parent-container">
      <div className="w-[80%] rounded-xl shadow-md leave-sidebar__container-wrapper">
        <div className="h-full w-full leave-sidebar__content-wrapper">
          <div className="flex justify-between items-center small-wrapper">
            <h2 className="flex align-middle">
              {isLoading && <Spinner color="deep-orange" className="mr-2" />}
              LEAVE HISTORY - {currentYear}
            </h2>
            <div className="flex justify-center items-center leave-icon-wrapper pr-2">
              <Link
                to="/my/leave/history"
                target="_blank"
                className="w-full h-full flex justify-center items-center link-wrapper"
              >
                <DocumentFileIcon className="w-6 h-6" />
              </Link>
            </div>
          </div>

          <div className="h-full leave-sidebar__employee-application">
            {leaves.length === 0 ? (
              <div className="h-full w-full flex flex-col justify-center items-center leave-sidebar__empty-message">
                <div className="w-full flex justify-center">
                  <SadLife className="w-10 h-auto" />
                </div>
                <p className="text-center px-4">
                  You don&apos;t have any leave for the current year.
                </p>
              </div>
            ) : (
              leaves.map((leave) => (
                <div
                  key={leave.id}
                  className={`gap-2 flex justify-between mt-3 shadow-md rounded-xl py-3 px-4 leave-sidebar__card-wrapper
                    ${leave.isPaid ? 'leave-sidebar--paid' : 'leave-sidebar--unpaid'}`}
                >
                  <div className="leave-sidebar__card-content">
                    <div className="leave-type">
                      <p>DATE FILED</p>
                      <p>NATURE</p>
                      <p>PAID</p>
                    </div>
                    <div className="leave-sidebar__leave-info">
                      <span>{momentFormat(leave.dateCreated, 'MMM DD, YYYY hh:mm A')}</span>
                      <span>
                        {
                          myLeavesState.leaveNatures.find(
                            (nature) => nature.id === leave.leaveNatureId,
                          )?.nature
                        }
                      </span>
                      <span>{leave.isPaid ? 'Yes' : 'No'}</span>
                    </div>
                  </div>
                  <div className="flex justify-center items-center">
                    <div
                      className={`shadow-lg rounded-2xl leave-status ${getLeaveStatus(
                        leave,
                      )?.toLowerCase()}`}
                    >
                      {getLeaveStatus(leave)}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveRightSideBar;
