import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from '@material-tailwind/react';

const LoanSaviiModal: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div>
      <div className="">
        <button
          type="button"
          onClick={handleOpen}
          className="w-40 rounded-lg shadow-lg muiButton--primary"
        >
          Savii Upload
        </button>
      </div>
      <Dialog open={open} handler={handleOpen} className="bg-[#2c2b2a] p-2">
        <DialogHeader>
          <Typography variant="h3" className="text-[#f05825]">
            Upload Soa
          </Typography>
        </DialogHeader>
        <DialogBody>
          <div className="w-full border border-solid border-[#343434] rounded-lg shadow-lg">
            <div className="p-3 w-full bg-[#f05825] rounded-t-lg">
              <span className="text-gray-200 font-bold">Upload Excel</span>
            </div>
            <div className="p-5 flex ">
              <div className="flex w-[60%]">
                <div className="flex gap-2 items-center">
                  <input
                    type="file"
                    className="bg-[#343434] w-full rounded-md border border-solid border-[#434343]"
                  />
                </div>
              </div>
              <button
                type="button"
                className="p-2 bg-[#f05825] text-[#ffffff] text-sm rounded-md shadow-md w-32 ml-auto"
              >
                Submit
              </button>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <button
            type="button"
            onClick={handleOpen}
            className="p-2 bg-[#f05825b2] text-[#ffffff] text-sm rounded-md shadow-md w-32"
          >
            Close
          </button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default LoanSaviiModal;
