import React from 'react';
import CompanyBranchList from './CompanyBranchList';
import ManagementHeading from '../../../atoms/ManagementHeading';

const CompanyBranchManagement: React.FC = () => (
  <div className="flex w-full">
    <div className="w-full flex flex-col">
      <div className="m-2 title">
        <ManagementHeading title="Company" subtitle="Branch" />
      </div>
      <div className="w-full organisms-branch-management">
        <CompanyBranchList />
      </div>
    </div>
  </div>
);

export default CompanyBranchManagement;
