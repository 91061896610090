import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import IncentiveActions from '../../actions/my/transactionFeedActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import Incentive from '../../constants/my/incentives.interface';

export interface IncentivesState {
  incentives: Incentive[];
}

const initialState: IncentivesState = {
  incentives: [
    {
      id: 1,
      incentive: 'Others - Company',
      amount: 26000.0,
      amountType: 'Fixed',
      effectiveDate: new Date('2023-05-01'),
      endDate: new Date('2024-08-13'),
      status: 'Active',
      payPeriod: 1,
    },
    {
      id: 2,
      incentive: 'Bonus',
      amount: 1500.0,
      amountType: 'Variable',
      effectiveDate: new Date('2023-06-01'),
      endDate: new Date('2023-07-01'),
      status: 'Inactive',
      payPeriod: 2,
    },
    {
      id: 3,
      incentive: 'Awesomecx',
      amount: 4546.0,
      amountType: 'Money',
      effectiveDate: new Date('2024-03-12'),
      endDate: new Date('2024-11-24'),
      status: 'Active',
      payPeriod: 4,
    },
  ],
};

const getIncentiveData = (incentive: Partial<Incentive>) => ({
  ...incentive,
});

const incentivesSlice = createSlice({
  name: 'incentives',
  initialState,
  reducers: {
    resetIncentivesState: () => ({
      ...initialState,
    }),
    setIncentivesState: (state, action: PayloadAction<Partial<IncentivesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertIncentive: (state, action: PayloadAction<Partial<Incentive>>) => {
      const data: Incentive = getIncentiveData(action.payload) as Incentive;

      if (typeof data.id === 'undefined') return;

      state.incentives = upsertData(state.incentives, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(IncentiveActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Incentive>;
      consoleLog('INCENTIVES REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.incentives = upsertData(state.incentives, data);
      }
    });
    builder.addCase(IncentiveActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('INCENTIVES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Incentive>;

      if (status === 503) return;

      if (data) {
        state.incentives = data;
      }
    });
    builder.addCase(IncentiveActions.get.fulfilled, (state, { payload }) => {
      consoleLog('INCENTIVES REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Incentive>;

      if (typeof data.id === 'undefined') return;
      state.incentives = upsertData(state.incentives, data);
    });
    builder.addCase(IncentiveActions.put.fulfilled, (state, { payload }) => {
      consoleLog('INCENTIVES REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<Incentive>;

      if (typeof data.id === 'undefined') return;
      state.incentives = upsertData(state.incentives, data);
    });
    builder.addCase(IncentiveActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('INCENTIVES REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Incentive>;

      if (typeof data.id === 'undefined') return;
      state.incentives = state.incentives.filter((incentive) => incentive.id !== data.id);
    });
  },
});

export const incentivesReducerActions = incentivesSlice.actions;
export default incentivesSlice.reducer;
