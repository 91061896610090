import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import moment from 'moment';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import useUser from '../../../../hooks/users/useUser';
import useAuth from '../../../../hooks/auths/useAuth';
import PrintButton from './LeaveFormPrint';
import useDateTime from '../../../../hooks/utils/useDateTime';
import { ReactComponent as Document } from '../../../../assets/images/icons/document-text.svg';

export interface LeaveListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: LeaveApplication;
  getDateTime: (date: Date) => string;
  managePending: (leave: LeaveApplication) => void;
}

const LeaveListRowHistory: React.FC<LeaveListRowProps> = ({
  className = '',
  data,
  getDateTime,
  managePending,
  ...props
}: LeaveListRowProps) => {
  const {
    authState: { userInformation },
  } = useAuth();

  const { currentUser } = useUser((userInformation?.userId ?? '').toString());
  const { getDate } = useDateTime();

  const convertedDateToString = getDate(data.dateCreated);

  const getLeaveStatus = () => {
    if (data.dateApproved) {
      return 'Approved';
    }
    if (data.dateDenied) {
      return 'Denied';
    }
    return 'Pending';
  };

  return (
    <div {...props} className={`${className} leave-history-data table-list-data flex items-center`}>
      <span>{moment(data.dateCreated).format('MMM DD, YYYY hh:mm:ss A')}</span>
      <span>{data.dateStart && moment(data.dateStart).format('MMM DD, YYYY')}</span>
      <span>{data.dateEnd && moment(data.dateEnd).format('MMM DD, YYYY')}</span>
      <span>{data.leaveNature?.nature}</span>
      <span>{data.notes}</span>
      <div className="flex justify-center">
        <span className={`status ${getLeaveStatus().toLowerCase()}`}>{getLeaveStatus()}</span>
      </div>
      <div className="action-buttons space-x-6 justify-self-center">
        <Tooltip content="Details" className="tooltip shadow-sm">
          <button className="textButton" type="button" onClick={() => managePending(data)}>
            <Document className="w-6 h-auto" />
          </button>
        </Tooltip>
        <Tooltip content="Leave Request Form" className="tooltip shadow-sm">
          <PrintButton
            dataToPrint={data}
            convertedDateToString={convertedDateToString}
            employeeName={userInformation?.fullName as string}
            position={currentUser?.employment?.currentPosition?.employmentPosition.name as string}
            department={currentUser?.employment?.department?.name as string}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default LeaveListRowHistory;
