import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useCompanies from '../../../hooks/companies/useCompanies';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import Company from '../../../store/constants/companies/company.interface';

export interface SelectCompanyProps extends Partial<AutoSearchSelectProps<Company>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectCompany: React.FC<SelectCompanyProps> = ({
  id,
  name,
  placeholder = 'Select Company',
  className = '',
  ...props
}) => {
  const { companiesState, getAll } = useCompanies();

  useOnLoad(() => {
    getAll().then();
  });

  const companies = companiesState.companies
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((company) => ({
      ...company,
      value: company.id,
      label: company.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-company modal-select ${className}`}
      placeholder={placeholder}
      data={companies}
    />
  );
};

export default SelectCompany;
