import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OvertimeState {
  duration: {
    hours: number;
    minutes: number;
  };
}

const initialState: OvertimeState = {
  duration: {
    hours: 0,
    minutes: 0,
  },
};

const overtimeSlice = createSlice({
  name: 'overtime',
  initialState,
  reducers: {
    setOvertimeDuration: (state, action: PayloadAction<{ hours: number; minutes: number }>) => {
      state.duration = action.payload;
    },
  },
});

export const { setOvertimeDuration } = overtimeSlice.actions;
export default overtimeSlice.reducer;
