import React from 'react';
import moment from 'moment';
import { Tooltip } from '@material-tailwind/react';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import useOvertimes from '../../../../hooks/attendances/useOvertimes';
import DeleteConfirmationBanner from '../../../molecules/DeleteConfirmationBanner';
import { ReactComponent as ThumbUp } from '../../../../assets/images/icons/hand-thumb-up.svg';
import { ReactComponent as Edit } from '../../../../assets/images/icons/edit.svg';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as Check } from '../../../../assets/images/icons/check.svg';

export interface AttendanceListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: EmployeeOvertime;
  onGenerateClick: (attendance: EmployeeOvertime) => void;
  approveOvertime: (overtime: EmployeeOvertime) => void;
  endOvertime: (overtime: EmployeeOvertime) => void;
  editOvertime: (overtime: EmployeeOvertime) => void;
  onDeleteClick: (overtime: EmployeeOvertime) => Promise<unknown>;
}

const OvertimePageListRow: React.FC<AttendanceListRowProps> = ({
  className = '',
  data,
  approveOvertime,
  endOvertime,
  onGenerateClick,
  editOvertime,
  onDeleteClick,
  ...props
}: AttendanceListRowProps) => {
  const { getEmployeeByEmployeeNumber } = useUserEmployments();
  const { overtimeState } = useOvertimes();

  const employee = getEmployeeByEmployeeNumber(data.employeeNumber);
  const overtimeTimeInDate = moment(data.overtimeTimeIn as unknown as Date).format('YYYY-MM-DD');
  const overtimeTimeInTime = moment(data.overtimeTimeIn as unknown as Date).format('hh:mm A');
  const overtimeTimeOutTime = moment(data.overtimeTimeOut as unknown as Date).format('hh:mm A');

  const overtimeTypeId =
    typeof data.overtimeType === 'string' ? parseInt(data.overtimeType, 10) : undefined;
  const overtimeType =
    overtimeTypeId !== undefined
      ? overtimeState?.overtimeType.find((overtime) => overtime.id === overtimeTypeId)
      : undefined;
  const employeeLabel = ` the Overtime of ${employee?.userInformation.fullName}`;

  const deleteMessage = {
    pending: 'Pending...',
    success: `The overtime of ${employee?.userInformation.fullName} is successfully deleted.`,
    error: `Error on deleting the Overtime of ${employee?.userInformation.fullName}.`,
  };

  return (
    <div
      {...props}
      className={`${className} flex items-center p-2 overtime-list-data table-list-data`}
    >
      <div>
        {employee?.userInformation.firstName} {employee?.userInformation.lastName}
      </div>
      <div>{overtimeTimeInDate}</div>
      <div>{overtimeTimeInTime}</div>
      <div>{data.overtimeTimeOut ? overtimeTimeOutTime : ''}</div>
      <div>{data.duration || ''}</div>
      <div>{overtimeType?.overtimeName}</div>
      {/* <div className="w-fit overtime-pending">{data.reviewedBy ? data.reviewedBy : 'Pending'}</div> */}
      {data.approvedBy ? (
        <div className="w-fit overtime-approved">
          {getEmployeeByEmployeeNumber(data.approvedBy)?.userInformation.firstName}{' '}
          {getEmployeeByEmployeeNumber(data.approvedBy)?.userInformation.lastName}
        </div>
      ) : (
        <div className="w-fit overtime-pending">Pending</div>
      )}
      <div className="space-x-2 action-buttons justify-self-center">
        {data.overtimeTimeOut ? (
          <Tooltip
            content={data.approvedBy ? 'Remove Approval' : 'Approve'}
            className="tooltip shadow-sm"
          >
            <button className="textButton" type="button" onClick={() => approveOvertime(data)}>
              {data.approvedBy ? <Check className="w-6 h-6" /> : <ThumbUp className="w-5 h-5" />}
            </button>
          </Tooltip>
        ) : (
          <Tooltip content="End Overtime" className="tooltip shadow-sm">
            <button className="textButton" type="button" onClick={() => endOvertime(data)}>
              <Close className="w-5 h-5" />
            </button>
          </Tooltip>
        )}
        <Tooltip content="Edit" className="tooltip shadow-sm">
          <button className="textButton" type="button" onClick={() => editOvertime(data)}>
            <Edit className="w-5 h-5" />
          </button>
        </Tooltip>
        <DeleteConfirmationBanner
          data={data}
          message={deleteMessage}
          label={employeeLabel}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};
export default OvertimePageListRow;
