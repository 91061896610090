import React, { useState, useEffect } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useSchedules from '../../../hooks/schedule/useSchedules';
import useScheduleTypes from '../../../hooks/schedule/useScheduleTypes';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectDays from '../../atoms/dropdowns/SelectDays';
import {
  ScheduleDaysOption,
  ScheduleTempOption,
} from '../../../store/reducers/schedules/schedulesReducer';
import SelectEmploymentScheduleType from '../../atoms/dropdowns/SelectEmploymentScheduleType';
import EmploymentScheduleType from '../../../store/constants/employments/schedules/employment-schedule-type.interface';
import EmployeeSchedule from '../../../store/constants/employees/schedules/employee-schedule.interface';
import SelectTemporary from '../../atoms/dropdowns/SelectTemporary';
import { ReactComponent as Clock } from '../../../assets/images/icons/clock.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import toastNotification from '../../../hooks/utils/toastNotification';
import { calculateScheduledTimeOut } from './SchedulerTestAddForm';

export interface SchedulerEditFormModalProps {
  schedule?: EmployeeSchedule;
  onClose: () => void;
}

const SchedulerEditFormModal: React.FC<SchedulerEditFormModalProps> = ({
  schedule = undefined,
  onClose,
}) => {
  const { scheduleTypesState, getAll: getAllScheduleTypes } = useScheduleTypes();
  const { put } = useSchedules();

  const [scheduleForm, setScheduleForm] = useState<EmployeeSchedule | undefined>();
  const [selectedDays, setSelectedDays] = useState<ScheduleDaysOption | undefined>();
  const [selectedTemp, setSelectedTemp] = useState<ScheduleTempOption | undefined>(
    schedule?.temp
      ? {
          value: schedule?.temp,
          label: schedule?.temp,
        }
      : undefined,
  );

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedTemp(undefined);
    setSelectedDays(undefined);
    onClose();
  };

  const handleOpen = () => {
    setSelectedTemp(undefined);
    setOpen(true);
  };

  const daysToString: string = Object.values(selectedDays || {})
    .map((item) => item.value)
    .join(',');

  const scheduleDays: string = daysToString || schedule?.days || '';

  const handleSubmit = async (values: Partial<EmployeeSchedule>) => {
    console.log('values', values);
    setLoading(true);

    const data = {
      ...values,
      scheduleTypeId: values.scheduleType?.id || scheduleForm?.scheduleTypeId,
      temp: selectedTemp?.value,
      days: scheduleDays,
      dateUpdated: new Date(),
    };

    await toastNotification({
      action: put(data as EmployeeSchedule),
      onSuccess: () => {
        setLoading(false);
        handleClose();
      },
      onError: () => {},
      onFulfilled: () => {
        setLoading(false);
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Schedule information is successfully updated.',
        error: 'Error on updating Schedule information.',
      },
    });
  };

  useEffect(() => {
    if (schedule) {
      setOpen(true);
      setScheduleForm(schedule);
    }
  }, [schedule]);

  useOnLoad(() => {
    getAllScheduleTypes().then();
  });

  return (
    <Dialog
      size="xs"
      open={open}
      handler={handleOpen}
      className="shadow-none p-4 scheduler-form-dialog"
    >
      <div className="absolute top-0 right-0 flex p-0 m-2">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <Close className="w-5 h-5" />
        </IconButton>
      </div>
      <div className="flex gap-3">
        <Clock className="w-8 h-auto scheduler-icon" />

        <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
          Update Schedule Info
        </DialogHeader>
      </div>
      <DialogBody divider className="mt-1">
        <div className="flex justify-center w-full">
          <Formik
            enableReinitialize
            initialValues={{
              ...scheduleForm,
              break: scheduleForm?.break ?? 0,
              scheduleType: scheduleForm?.scheduleTypeId
                ? ({
                    ...scheduleTypesState.scheduleTypes.find(
                      (type) => type.id === scheduleForm?.scheduleTypeId,
                    ),
                    label: scheduleTypesState.scheduleTypes.find(
                      (type) => type.id === scheduleForm?.scheduleTypeId,
                    )?.name,
                    value: scheduleTypesState.scheduleTypes.find(
                      (type) => type.id === scheduleForm?.scheduleTypeId,
                    )?.id,
                  } as EmploymentScheduleType)
                : undefined,
              temporary: scheduleForm?.temp
                ? ({
                    label: scheduleForm?.temp,
                    value: scheduleForm?.temp,
                  } as ScheduleTempOption)
                : undefined,
            }}
            onSubmit={handleSubmit}
          >
            {(formik) => {
              if (!formik.values.scheduleType) {
                const selectedType = scheduleTypesState.scheduleTypes.find(
                  (type) => type.id === scheduleForm?.scheduleTypeId,
                );
                formik.setFieldValue('scheduleType', {
                  ...selectedType,
                  label: selectedType?.name,
                  value: selectedType,
                });
              }
              return (
                <Form className="w-full">
                  <div className="w-full flex justify-center">
                    <div className="scheduler-container ">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="time-schedule mb-3">
                          <div className="flex mb-2 text-sm font-semibold items-center">
                            <label htmlFor="timeIn_out" className="text-gray-200">
                              Time schedule
                            </label>
                            {formik.values.scheduleType ? (
                              <span className="text-xs ml-2">
                                ( {formik.values.scheduleType?.hours} hours )
                              </span>
                            ) : (
                              <span className="text-xs ml-2">( select a schedule type )</span>
                            )}
                          </div>
                          <div className="flex gap-2 justify-center items-center">
                            <Field
                              type="time"
                              id="timeIn"
                              name="timeIn"
                              placeholder="Time in"
                              className="w-full p-2 border border-gray-600 rounded-md shadow-lg placeholder:text-gray-600 scheduler-field-time"
                              disabled={!formik.values.scheduleType}
                              onChange={(e: any) => {
                                formik.handleChange(e);
                                const timeIn = e.target.value;
                                const scheduleHours = formik.values.scheduleType?.hours ?? 0;
                                const timeOut = calculateScheduledTimeOut(timeIn, scheduleHours); // Implement this function to calculate Time Out
                                formik.setFieldValue('timeOut', timeOut);
                              }}
                            />
                            <ErrorMessage name="timeIn" component="div" />
                            <span>to</span>
                            <Field
                              type="time"
                              id="timeOut"
                              name="timeOut"
                              placeholder="Time out"
                              className="w-full p-2 border border-gray-600 rounded-md shadow-lg placeholder:text-gray-600 scheduler-field-time"
                              disabled
                            />
                            <ErrorMessage name="timeOut" component="div" />
                          </div>
                        </div>

                        <div className="schedule-type w-full mb-3">
                          <label
                            htmlFor="scheduleType"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Schedule type
                          </label>
                          <SelectEmploymentScheduleType
                            id="scheduleType"
                            name="scheduleType"
                            className="w-full text-xs placeholder:text-xs"
                            menuPlacement="auto"
                            value={formik.values.scheduleType}
                            setFieldValue={formik.setFieldValue}
                            isClearable
                            required
                            onChange={(value) => {
                              formik.setFieldValue('scheduleType', value);
                              const { timeIn } = formik.values;
                              const scheduleType = value as EmploymentScheduleType;
                              if (timeIn)
                                formik.setFieldValue(
                                  'timeOut',
                                  calculateScheduledTimeOut(timeIn, scheduleType.hours),
                                );
                            }}
                          />
                          <ErrorMessage name="scheduleType" component="div" />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="date-start mb-3">
                          <label
                            htmlFor="dateStart"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Date start
                          </label>
                          <Field
                            type="date"
                            id="dateStart"
                            name="dateStart"
                            placeholder="Date Start"
                            className="w-full p-2 border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 scheduler-field"
                          />
                          <ErrorMessage name="dateStart" component="div" />
                        </div>
                        <div className="date-end mb-3">
                          <label
                            htmlFor="dateEnd"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Date end
                          </label>
                          <Field
                            type="date"
                            id="dateEnd"
                            name="dateEnd"
                            placeholder="Type"
                            className="w-full p-2 border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 scheduler-field"
                          />
                          <ErrorMessage name="dateEnd" component="div" />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className=" temporary mb-3">
                          <label
                            htmlFor="temp"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Temporary
                          </label>

                          <SelectTemporary
                            id="temp"
                            name="temp"
                            className="w-full text-xs placeholder:text-xs"
                            menuPlacement="auto"
                            value={selectedTemp ?? formik.values.temporary}
                            onChange={(value) => setSelectedTemp(value as ScheduleTempOption)}
                            setFieldValue={formik.setFieldValue}
                          />
                          <ErrorMessage name="temp" component="div" />
                        </div>
                        <div className="break mb-3">
                          <label
                            htmlFor="timeIn_out"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Break
                          </label>
                          <Field
                            type="number"
                            id="break"
                            name="break"
                            className="w-full p-2 border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 scheduler-field"
                            required
                          />
                          <ErrorMessage name="break" component="div" />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="days" className="block mb-2 text-sm font-semibold">
                          Days
                        </label>
                        <SelectDays
                          id="days"
                          name="days"
                          value={selectedDays}
                          initialValue={scheduleForm?.days}
                          className="w-full text-xs placeholder:text-xs"
                          onChange={(value) => setSelectedDays(value as ScheduleDaysOption)}
                          isMulti
                          required
                          menuPlacement="auto"
                        />
                        <ErrorMessage name="days" component="div" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between w-full mt-5 scheduler-button-wrapper">
                    <div className="flex justify-center w-max">
                      <ButtonAction
                        type="submit"
                        className=" p-2 rounded-md shadow-lg button-submit-scheduler"
                        isLoading={loading}
                        hasSpinnerText={false}
                        isSubmit
                      />
                    </div>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="p-2  rounded-lg shadow-lg button-close-scheduler"
                    >
                      Close
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default SchedulerEditFormModal;
