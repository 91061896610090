import { useAppDispatch, useAppSelector } from '../../store/store';
import hmoDependentActions from '../../store/actions/my/hmoDependentActions';
import {
  HmoDependentsState,
  hmoDependentsReducerActions,
} from '../../store/reducers/my/hmoDependentsReducer';
import HmoDependentType from '../../store/constants/my/hmo-dependent.interface';

const useHmoDependents = () => {
  const dispatch = useAppDispatch();
  const hmoDependentsState: HmoDependentsState = useAppSelector((state) => state.hmoDependents);

  const resetHmoDependentState = () => {
    dispatch(hmoDependentsReducerActions.resetHmoDependentsState());
  };

  const setHmoDependentState = (state: Partial<HmoDependentsState>) => {
    dispatch(hmoDependentsReducerActions.setHmoDependentsState(state));
  };

  const get = async (id: HmoDependentType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoDependentActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoDependentActions.getAll({}));

  const post = async (data: Omit<HmoDependentType, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoDependentActions.post(data));

  const put = async (data: Partial<HmoDependentType>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoDependentActions.put(data));

  const remove = async (id: HmoDependentType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoDependentActions.remove(id));

  return {
    resetHmoDependentState,
    hmoDependentsState,
    setHmoDependentState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useHmoDependents;
