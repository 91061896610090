import React from 'react';
import PayrollSidebar from '../sidebar/PayrollSidebarCreateView';
import PayslipToolOptions from './PayslipToolOptions';

const PayslipViewManagement: React.FC = () => (
  <div className="flex w-full h-full organisms-payroll-management">
    <div className="flex w-full h-full">
      <div className="payroll-sidebar-wrapper">
        <PayrollSidebar />
      </div>
      <div className="flex flex-col w-full payroll-content-wrapper">
        {/* tool options */}
        <PayslipToolOptions />
      </div>
    </div>
  </div>
);

export default PayslipViewManagement;
