import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import userEmploymentPositionsActions from '../../store/actions/users/employments/userEmploymentPositionsActions';
import {
  userPositionsReducerActions,
  UserPositionsState,
} from '../../store/reducers/positions/userPositionsReducer';
import UserEmploymentPosition from '../../store/constants/employees/positions/employee-position.interface';

const useUserPositions = () => {
  const dispatch = useDispatch();
  const userPositionsState: UserPositionsState = useSelector(
    (state: RootState) => state.userPositions,
  );

  const resetUserPositionsState = () => {
    dispatch(userPositionsReducerActions.resetUserPositionsState());
  };

  const setUserPositionsState = (state: Partial<UserPositionsState>) => {
    dispatch(userPositionsReducerActions.setUserPositionsState(state));
  };

  const get = async (id: UserEmploymentPosition['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.getAll({}));

  const getAllUsersByEmploymentPosition = async (positionCode?: string) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.getAllUsersByEmploymentPosition(positionCode));

  const post = async (data: Omit<UserEmploymentPosition, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.post(data));

  const put = async (data: Partial<UserEmploymentPosition>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.put(data));

  const remove = async (id: UserEmploymentPosition['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentPositionsActions.remove(id));

  return {
    resetUserPositionsState,
    userPositionsState,
    setUserPositionsState,
    get,
    getAll,
    getAllUsersByEmploymentPosition,
    post,
    put,
    remove,
  };
};

export default useUserPositions;
