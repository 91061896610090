import React, { useState, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { IconButton, Typography } from '@material-tailwind/react';
import AddInsideCircleSvg from '../../../../assets/images/icons/add-inside-circle.svg';
import DeleteSvg from '../../../../assets/images/icons/delete.svg';
import CompanyBranch from '../../../../store/constants/companies/branches/company-branch.interface';
import SelectCompany from '../../../atoms/dropdowns/SelectCompany';
import Company from '../../../../store/constants/companies/company.interface';
import AtLeast from '../../../../types/atleast';
import CompanyBranchOffices from '../../../../store/constants/companies/branches/company-branch-office.interface';
import FormikFOrmValuesMonitor from '../../../atoms/FormikFormValuesMonitor';
import ButtonAction from '../../../atoms/buttons/ButtonAction';

interface CompanyBranchFormProps {
  companyBranch?: CompanyBranch;
  onSubmit: (value: Partial<CompanyBranch>) => void;
  handleClose?: () => void;
  onChange?: (value: Partial<CompanyBranch>) => void;
  isLoading?: boolean;
}

const CompanyBranchForm: React.FC<CompanyBranchFormProps> = ({
  companyBranch = undefined,
  handleClose = undefined,
  onChange = undefined,
  onSubmit,
  isLoading = undefined,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(
    companyBranch?.company,
  );
  const [officeName, setOfficeName] = useState('');
  const [companyBranchOffices, setCompanyBranchOffices] = useState<
    AtLeast<CompanyBranchOffices, 'name'>[]
  >(companyBranch?.companyBranchOffices ?? []);
  const [formikValues, setFormikValues] = useState<Partial<CompanyBranch>>();

  const onBranchOfficeNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOfficeName(e.target.value);
  };

  const handleAddCompanyBranchOfficeClick = () => {
    if (!officeName.trim()) {
      return;
    }

    const newOffices = [...companyBranchOffices, { name: officeName.trim() }];

    setCompanyBranchOffices(newOffices);

    setOfficeName('');
  };

  const handleDeleteBranchOfficeClick = (index: number) => {
    // Remove the item with the specified ID from the fieldValues array
    const updatedOffices = [...companyBranchOffices];
    updatedOffices.splice(index, 1);
    setCompanyBranchOffices(updatedOffices);
  };

  const handleSubmit = (values: Partial<CompanyBranch>) => {
    const data = {
      ...values,
      companyBranchOffices,
    };
    onSubmit(data as CompanyBranch);
  };

  useEffect(() => {
    if (onChange) {
      const data = {
        ...(formikValues && { ...formikValues }),
        company: selectedCompany,
        companyBranchOffices,
      };
      onChange(data as CompanyBranch);
    }
  }, [selectedCompany, companyBranchOffices, onChange, formikValues]);

  return (
    <div className="w-full ">
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          ...companyBranch,
          name: companyBranch?.name ? companyBranch.name : '',
          address: companyBranch?.address ? companyBranch.address : '',
          contact: companyBranch?.contact ? companyBranch.contact : '',
          emailDistro: companyBranch?.emailDistro ? companyBranch.emailDistro : '',
          company: companyBranch?.company
            ? ({
                ...companyBranch?.company,
                label: companyBranch.company.name,
                value: companyBranch?.company,
              } as Company)
            : undefined,
        }}
      >
        {(formik) => (
          <Form className="w-full">
            <div className="mt-4 mb-5 form-section">
              <div className="justify-between w-full">
                <div className="first-section w-[45%]">
                  <div className="mb-4">
                    <div className="">
                      <Typography variant="h3" className="text-[#f05825] ml-1 mt-2 mb-5">
                        Company Branch Information
                      </Typography>
                    </div>
                  </div>
                  <div className="mb-5">
                    <label htmlFor="company" className="block mb-2 text-gray-200 modal-labels">
                      Company
                    </label>
                    <div className="">
                      <SelectCompany
                        id="company"
                        name="company"
                        onChange={(value) => setSelectedCompany(value as Company)}
                        value={formik.values.company}
                        setFieldValue={formik.setFieldValue}
                        isClearable
                        isSearchable
                        className="w-full text-gray-200 placeholder:text-gray-600"
                      />
                    </div>
                    <ErrorMessage name="company" component="div" />
                  </div>
                  <div className="mb-5">
                    <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                      Branch Name
                    </label>
                    <div className="">
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter Name"
                        className="p-2 w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                      />
                      <ErrorMessage name="name" component="div" />
                    </div>
                  </div>
                </div>
                <div className="second-section w-[45%]">
                  <div className="mb-5">
                    <label htmlFor="contact" className="block mb-2 text-gray-200 modal-labels">
                      Contact
                    </label>
                    <div className="">
                      <Field
                        type="text"
                        id="contact"
                        name="contact"
                        placeholder="Contact"
                        className="p-2 w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                      />
                      <ErrorMessage name="contact" component="div" />
                    </div>
                  </div>
                  <div className="mb-5">
                    <label htmlFor="emailDistro" className="block mb-2 text-gray-200 modal-labels">
                      Email Distro
                    </label>
                    <div className="">
                      <Field
                        type="email"
                        id="emailDistro"
                        name="emailDistro"
                        placeholder="Enter email"
                        className="p-2 w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                      />
                      <ErrorMessage name="emailDistro" component="div" />
                    </div>
                  </div>
                  <div className="mb-5">
                    <label htmlFor="address" className="block mb-2 text-gray-200 modal-labels">
                      Address
                    </label>
                    <div className="">
                      <Field
                        as="textarea"
                        id="address"
                        name="address"
                        placeholder="Address"
                        className="p-2 w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                      />
                      <ErrorMessage name="address" component="div" />
                    </div>
                  </div>
                  <div className="mb-5">
                    <label htmlFor="Office" className="block mb-2 text-gray-200 modal-labels">
                      Branch Offices
                    </label>
                    <div className="flex gap-5">
                      <Field
                        type="text"
                        id="office"
                        name="office"
                        placeholder="Enter Offices"
                        className="p-2 w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg placeholder:text-gray-600 text-gray-200"
                        value={officeName}
                        onChange={onBranchOfficeNameChange}
                      />
                      <IconButton
                        color="blue-gray"
                        size="md"
                        variant="text"
                        className="m-auto add-icon-button"
                        onClick={handleAddCompanyBranchOfficeClick}
                      >
                        <span>
                          <img
                            style={{ filter: 'invert(0) invert(1)' }}
                            className="w-7 h-7"
                            src={AddInsideCircleSvg}
                            alt="Add Office"
                          />
                        </span>
                      </IconButton>
                    </div>
                    <ErrorMessage name="office" component="div" />
                    <div className="flex gap-3 p-2 mt-3 display-field-input">
                      {companyBranchOffices.map((item, index) => (
                        <div
                          key={item.name}
                          className="rounded-lg shadow-lg bg-[#343434] p-1 text-white flex gap-3"
                        >
                          <div className="p-1 m-auto ml-2">{item.name}</div>
                          <div className="">
                            <IconButton
                              color="blue-gray"
                              size="sm"
                              variant="text"
                              className="m-auto delete-icon-button"
                              onClick={() => handleDeleteBranchOfficeClick(index)}
                            >
                              <img
                                className="w-5 h-5 trash-icon"
                                src={DeleteSvg}
                                alt={`Delete ${item.name}`}
                              />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Buttons */}
            <div className="flex gap-8">
              <div className="flex justify-center items-center">
                <ButtonAction
                  type="submit"
                  className="flex justify-center items-center rounded-md shadow-lg submit-loader-button"
                  isLoading={isLoading}
                  hasSpinnerText={false}
                  isSubmit
                />
              </div>
              <div className="flex justify-center w-fit">
                <button
                  type="button"
                  onClick={handleClose}
                  className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                >
                  Close
                </button>
              </div>
            </div>
            <FormikFOrmValuesMonitor onChange={setFormikValues} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyBranchForm;
