import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import useClient from '../../../hooks/clients/useClient';
import ClientForm from '../../molecules/ClientForm';
import Client from '../../../store/constants/clients/client.interface';
import useClients from '../../../hooks/clients/useClients';
import toastNotification from '../../../hooks/utils/toastNotification';

const ClientEditForm: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{
    id: string;
  }>();

  const { currentClient: client, loading } = useClient(id as string);
  const { put } = useClients();

  if (loading) return null;

  if (!client) return null;

  const handleClose = () => {
    navigate('/admin/clients');
  };

  const handleSubmit = async (data: Partial<Client>) => {
    await toastNotification({
      action: put(data as Client),
      onSuccess: () => {
        handleClose();
      },
      onError: (res) => {
        if (res && res.error) {
          throw new Error(res.error);
        }
      },
      onFulfilled: () => {},
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Client information updated.',
        error: {
          render({ data: errorMsg }) {
            return errorMsg as string;
          },
        },
      },
    });
  };

  return (
    <div className="client-manage-form-container space-y-10">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/clients">
          <Typography className="crumb">Clients</Typography>
        </Link>
        <span className="current-crumb">{client.name}</span>
      </Breadcrumbs>

      <ClientForm client={client} onClose={handleClose} onSubmit={handleSubmit} />
    </div>
  );
};

export default ClientEditForm;
