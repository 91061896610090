import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

interface HmoDashboardChartProps {
  onPieEnter?: () => void;
}

const HmoDashboardChart: React.FC<HmoDashboardChartProps> = ({ onPieEnter = undefined }) => {
  const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="p-5 flex gap-5">
      <div className="chat_area">
        <PieChart width={200} height={200} onMouseEnter={onPieEnter}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
      <div className="description_chart flex flex-col">
        <span className="font-bold text-[#f05825] text-lg">Chart Area</span>
        <span className="font-normal text-gray-200 text-xs">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta est nostrum ea libero,
          fugit doloremque deleniti pariatur placeat quis magni nobis facilis esse non enim eum
          inventore maxime maiores amet!
        </span>
      </div>
    </div>
  );
};

export default HmoDashboardChart;
