import React, { useEffect, useState } from 'react';
import DateRangePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FormikErrors } from 'formik';
import excludingDates from '../../../utils/excludingDates';

interface CustomRangeDatePickerProps extends Partial<ReactDatePickerProps> {
  id: string;
  name: string;
  placeholder?: string;
  excludedDates?: Date[];
  dateStart?: Date;
  setDateStart?: (date?: Date) => void;
  dateEnd?: Date;
  setDateEnd?: (date?: Date) => void;
  className?: string;
  setSelectedDays?: (dates: Date[]) => void;
  setFieldValue?: (
    field: string,
    value?: Date[],
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<unknown>>;
}

const CustomRangeDatePicker: React.FC<CustomRangeDatePickerProps> = ({
  id,
  name,
  placeholder = 'Select Date Range',
  excludedDates = [],
  dateStart = undefined,
  setDateStart = undefined,
  dateEnd = undefined,
  setDateEnd = undefined,
  className = '',
  setSelectedDays = undefined,
  setFieldValue = undefined,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(props.open ?? false);
  const handleDateChange = (dates: [Date, Date]) => {
    if (!setDateStart || !setDateEnd) return;

    // If dateStart is not set, or all dates is already set.
    if (!dateStart || (dateStart && dateEnd)) {
      setDateStart(dates[0]);
      setDateEnd(undefined);
      return;
    }

    if (dateStart) {
      // If dates[1] is not null because the next selected date is future
      if (dates[1]) {
        setDateEnd(dates[1]);
      }
      // dates[1] is null because the selected date is past.
      // Therefore, dates[0] should become dateStart and the current dateStart should become dateEnd
      else {
        setDateStart(dates[0]);
        setDateEnd(dateStart);
      }
    }
  };

  useEffect(() => {
    if (dateStart && dateEnd) {
      const dates = excludingDates(dateStart, dateEnd, excludedDates);
      if (setSelectedDays) {
        setSelectedDays(dates);
      }

      if (setFieldValue) {
        setFieldValue(name, dates);
      }
    }

    if (dateEnd && !props.readOnly) {
      setIsOpen(false);
    }
  }, [dateStart, dateEnd, setFieldValue, name, excludedDates, setSelectedDays, props.readOnly]);

  return (
    <div className="datePicker-wrapper w-full datePicker-main-parent">
      <DateRangePicker
        {...props}
        open={isOpen}
        id={id}
        name={name}
        placeholderText={placeholder}
        className={`custom-range-datepicker ${className}`}
        excludeDates={excludedDates}
        startDate={dateStart}
        endDate={dateEnd}
        onChange={handleDateChange}
        autoComplete="off"
        onFocus={(e) => e.target.blur()}
        // filterDate={isDayExcluded}
        selectsRange
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
      />
    </div>
  );
};

export default CustomRangeDatePicker;
