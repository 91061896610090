import { useRef, useCallback } from 'react';

const useVariableItemSize = () => {
  const itemSizes = useRef<{ [key: number]: number }>({});

  const getItemSize = useCallback((index: number) => {
    if (itemSizes.current[index] !== undefined) {
      return itemSizes.current[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes.current[index] = itemHeight;
    return itemHeight;
  }, []);

  return { getItemSize };
};

export default useVariableItemSize;
