import React from 'react';
import { Typography } from '@material-tailwind/react';
import PayslipConcernList from './PayslipConcernList';

const PayslipConcernManagement = () => (
  <div className="w-full flex flex-col pl-[24rem]">
    <div className="ml-auto mt-5 w-[90%]">
      <div className="passlip-concern-title">
        <Typography variant="h4" className="ml-1 mt-2 mb-5">
          Payslip Concern
        </Typography>
      </div>

      <div className="payslip-concern-management w-full">
        <PayslipConcernList />
      </div>
    </div>
  </div>
);

export default PayslipConcernManagement;
