import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import CompanyListRow from './CompanyListRow';
import useDateTime from '../../../hooks/utils/useDateTime';
import Company from '../../../store/constants/companies/company.interface';
import useCompanies from '../../../hooks/companies/useCompanies';
import consoleLog from '../../../utils/consoleLog';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import CompanyFormModal from './CompanyFormModal';
import SearchSvg from '../../../assets/images/icons/search.svg';
import useVariableItemSize from '../../../hooks/utils/useVariableItemSize';
import SortableTableHeader, { HeaderOption } from '../../molecules/SortableTableHeader';

type SortByHeaderOptions = '' | keyof Company;

const CompanyList: React.FC = () => {
  const { getItemSize } = useVariableItemSize();
  const { companiesState, getAll, remove } = useCompanies();
  const { getDateTime } = useDateTime();

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy); // Toggle the sorting order
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const companies = companiesState.companies
    .filter((company: Company) => {
      if (!company) return false;
      const { name, code, contact } = company;
      return (
        name.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        code.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        contact.toLowerCase().startsWith(debouncedSearchValue.toLowerCase())
      );
    })
    .sort((a: Company, b: Company) => {
      const sortOrder = sortOrderBy ? -1 : 1;
      const defaultOrder = b.id - a.id;

      if (sortOption === 'id') {
        return sortOrder * b.id - a.id;
      }
      if (sortOption === 'name') {
        return sortOrder * a.name.localeCompare(b.name);
      }
      if (sortOption === 'code') {
        return sortOrder * a.code.localeCompare(b.code);
      }
      if (sortOption === 'contact') {
        return sortOrder * a.contact.localeCompare(b.contact);
      }
      if (sortOption === 'dateCreated') {
        return sortOrder * getDateTime(a.dateCreated).localeCompare(getDateTime(b.dateCreated));
      }
      return defaultOrder;
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const onCompanyModalClose = () => setSelectedCompany(undefined);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (company: Company) => {
    setSelectedCompany(company);
  };

  const onDeleteClick = async (company: Company) => {
    remove(company.id).then((r) => {
      consoleLog('DELETE COMPANY', r);
    });
  };

  const headers: HeaderOption<Company>[] = [
    { value: 'id', label: 'ID', defaultOrder: true },
    { value: 'name', label: 'Name' },
    { value: 'code', label: 'Code' },
    { value: 'contact', label: 'Contact' },
    { value: 'dateCreated', label: 'Date Created' },
  ];

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className=" w-full px-5 organisms-company-list">
      <div className="flex justify-between pb-5">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search company"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Company"
          />
        </div>
        <CompanyFormModal company={selectedCompany} onClose={onCompanyModalClose} />
      </div>

      <SortableTableHeader
        classNames="company-list-header"
        sortOption={sortOption}
        sortOrderBy={sortOrderBy}
        headers={headers}
        sortByHeader={sortByHeader}
      />

      <VariableSizeList
        itemCount={companies.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <CompanyListRow
            data={companies[index]}
            className={index % 2 === 0 ? 'stripped' : ''}
            style={style}
            getDateTime={getDateTime}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default CompanyList;
