import { StatusCodes } from 'http-status-codes';

export interface FetchOptions extends Omit<RequestInit, 'body'> {
  body?: object | FormData | null;
}

export interface StateHeaders {
  auth: { token?: string; expireTime?: number };
  settings: { locale: { locale?: string }; timezone: { timezone?: string } };
}

export interface APIOptions {
  state: StateHeaders;
  options?: FetchOptions;
}

const initOptions =
  {
    state: {
      auth: {
        token: undefined,
      },
      settings: {
        locale: undefined,
        timezone: undefined,
      },
    },
    options: {},
  } ?? undefined;

const requestStatus: { [key: string]: boolean } = {};

async function apiFetch<T>(
  endPoint = '' as string,
  { state, options = {} } = initOptions as unknown as APIOptions,
): Promise<T> {
  const {
    auth: { token },
    settings: { locale, timezone },
  } = state;

  const method = options?.method ?? 'GET';
  if (requestStatus[`${method}${endPoint}`])
    return { status: 503, data: {} as T } as unknown as Promise<T>;

  if (method === 'GET') requestStatus[`${method}${endPoint}`] = true;

  const apiURL = `${process.env.REACT_APP_API_URL}/api/${endPoint}`;

  return fetch(apiURL, {
    ...options,
    method: options?.method ?? 'GET',
    body:
      (options.body && options.body instanceof FormData
        ? options.body
        : JSON.stringify(options.body)) ?? undefined,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      ...options.headers,
      ...(token && {
        Authorization: `Bearer ${token}`,
      }),
      ...(locale.locale && {
        'Accept-Language': locale.locale,
      }),
      ...(timezone.timezone && {
        'X-Timezone': timezone.timezone,
      }),
    },
  })
    .then(async (response) => {
      requestStatus[`${method}${endPoint}`] = false;
      const result = (await response.json()) as Promise<T>;

      const payload = await result;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.location.pathname !== '/login' && payload.status === 401) {
        window.location.href = '/logout';
      }

      // eslint-disable-next-line
      // @ts-ignore
      if (payload.code === StatusCodes.INTERNAL_SERVER_ERROR) throw new Error(payload.message);

      return payload;
    })
    .catch((e) => {
      throw new Error(e);
    });
}

export default apiFetch;
