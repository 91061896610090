type TimeValues = { days?: number; hours?: number; minutes?: number; seconds?: number };

const formatTimeDuration = <T extends TimeValues>({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}: T): string => {
  const parts = [];
  if (days !== 0) parts.push(`${days}d`);
  if (hours !== 0) parts.push(`${hours}h`);
  if (minutes !== 0 || hours !== 0) parts.push(`${minutes}m`);
  if (seconds !== 0 || (minutes !== 0 && hours === 0)) parts.push(`${seconds}s`);

  return parts.join(' ');
};

export default formatTimeDuration;
