import React from 'react';
import moment from 'moment';
import useRealTimeDate from '../../hooks/utils/useRealTimeDate';
import Card from '../atoms/Card';
import LoginForm from '../organisms/LoginForm';
import useTheme from '../../hooks/utils/useTheme';

const LoginTemplate: React.FC = () => {
  const { theme } = useTheme();
  const currentDateTime = useRealTimeDate();
  const dateTime = moment(currentDateTime).format('ddd MMM DD hh:mm:ss A');

  return (
    <div className={`login-template template flex items-center justify-center ${theme}`}>
      <div className="">
        <div className="company-name justify-center mb-8">
          <h1 className="mb-0">Awesome CX</h1>
          <span>by Transcom</span>
        </div>
        <div className="login-date__wrapper flex justify-center mb-3 text-sm font-medium">
          <span>{dateTime}</span>
        </div>
        <LoginForm />
        <Card className="login-footer__wrapper mt-1 text-xs">
          <div className="mt-10 text-center">
            <p className="text-base font-medium">
              {currentDateTime.getFullYear()} All Rights Reserved. OSNet v2.
              {localStorage.getItem('version')}.
            </p>
          </div>
        </Card>
      </div>
      <ul className="bg-bubbles">
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
        <li className={`${theme}`} />
      </ul>
    </div>
  );
};

export default LoginTemplate;
