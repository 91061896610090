import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import useLeaves from '../../../../hooks/leave/useLeaves';
import DeleteConfirmationBanner from '../../../molecules/DeleteConfirmationBanner';
import useDateTime from '../../../../hooks/utils/useDateTime';
import CustomRangeDatePicker from '../../../atoms/datepickers/CustomRangeDatePicker';
import PrintButton from '../../my/leaveHistory/LeaveFormPrint';
import { ReactComponent as Document } from '../../../../assets/images/icons/document-text.svg';

interface LeaveListFormModalProps {
  className?: string;
  style: React.CSSProperties;
  data: LeaveApplication;
  manageLeaveList: (leave: LeaveApplication) => void;
  onDeleteClick: (leave: LeaveApplication) => Promise<unknown>;
}

const LeaveListFormModal: React.FC<LeaveListFormModalProps> = ({
  className = '',
  data,
  manageLeaveList,
  onDeleteClick,
  ...props
}: LeaveListFormModalProps) => {
  const { getEmployeeByEmployeeNumber } = useUserEmployments();
  const { getDateTime, getDate } = useDateTime();
  const { leavesState } = useLeaves();

  const employeeName = getEmployeeByEmployeeNumber(data?.employeeNumber)?.userInformation?.fullName;
  const convertedDateToString = getDate(data.dateCreated);

  const leaveNature = leavesState.leaveNatures.find((nature) => nature.id === data.leaveNatureId);
  const convertedExcludedDates = data?.excludedDates.map((dateFormat) => new Date(dateFormat));

  const getLeaveStatus = () => {
    if (data.dateApproved) {
      return 'Approved';
    }
    if (data.dateDenied) {
      return 'Denied';
    }
    return 'Pending';
  };

  const deleteMessage = {
    pending: 'Pending',
    success: 'Success',
    error: 'Error',
  };

  return (
    <div
      {...props}
      className={`${className} leave-list-data table-list-data flex items-center my-auto`}
    >
      <div>{employeeName}</div>
      <div>{data?.team?.name}</div>
      <div>{getDateTime(data.dateCreated)}</div>
      <div>
        <CustomRangeDatePicker
          id="duration"
          name="duration"
          className="leave-list-duration"
          popperPlacement="left"
          popperClassName="m-0"
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 3],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 16,
              },
            },
          ]}
          excludedDates={convertedExcludedDates}
          dateStart={new Date(data.duration[0])}
          dateEnd={new Date(data.duration[data.duration.length - 1])}
          readOnly
        />
      </div>
      <div>{leaveNature?.nature}</div>
      <div>{data.notes}</div>
      <div className="leave-list-status">
        <div className="leave-list-status">
          <span className={`leave-list-content ${getLeaveStatus().toLowerCase()}`}>
            {getLeaveStatus()}
          </span>
        </div>
      </div>
      <div className="flex gap-3 items-center justify-center">
        <Tooltip content="View" className="tooltip shadow-sm">
          <button className="textButton" type="button" onClick={() => manageLeaveList(data)}>
            <Document className="w-5 h-5" />
          </button>
        </Tooltip>
        <DeleteConfirmationBanner
          data={data}
          label={getEmployeeByEmployeeNumber(data?.employeeNumber)?.userInformation?.fullName}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
        <Tooltip content="Leave Request Form" className="tooltip shadow-sm">
          <PrintButton
            dataToPrint={data}
            convertedDateToString={convertedDateToString}
            employeeName={employeeName as string}
            position=""
            department=""
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default LeaveListFormModal;
