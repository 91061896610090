import { useAppDispatch, useAppSelector } from '../../store/store';
import calendarActions from '../../store/actions/calendar/calendarActions';
import {
  CalendarsStateType,
  calendarsReducerActions,
} from '../../store/reducers/calendar/calendarsReducer';
import { GetAllCalendarEventsQueryParams } from '../../store/services/calendar/calendarServices';

const useCalendarApiEvents = () => {
  const dispatch = useAppDispatch();
  const calendarsState: CalendarsStateType = useAppSelector((state) => state.calendars);

  const resetCalendarState = () => {
    dispatch(calendarsReducerActions.resetCalendarsState());
  };

  const setCalendarState = (state: Partial<CalendarsStateType>) => {
    dispatch(calendarsReducerActions.setCalendarsState(state));
  };

  const setCurrentMonthView = (monthView: string) => {
    dispatch(calendarsReducerActions.setCurrentMonthView(monthView));
  };

  const getAllCalendarEvents = async (params = {} as GetAllCalendarEventsQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(calendarActions.getAllCalendarEvents(params));

  return {
    calendarsState,
    setCalendarState,
    resetCalendarState,
    setCurrentMonthView,
    getAllCalendarEvents,
  };
};

export default useCalendarApiEvents;
