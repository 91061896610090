import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import User from '../../../store/constants/users/user.interface';
import UserGender from '../../../store/constants/users/user-gender.enum';
import { ReactComponent as EyeIcon } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as SinglePersonIcon } from '../../../assets/images/icons/client.svg';
import { ReactComponent as EditTeamIcon } from '../../../assets/images/icons/user-three.svg';
import { ReactComponent as FemaleGenderIcon } from '../../../assets/images/icons/female-gender.svg';
import { ReactComponent as MaleGenderIcon } from '../../../assets/images/icons/male-gender.svg';
import { ReactComponent as ActivateIcon } from '../../../assets/images/icons/shield-check.svg';
import { ReactComponent as SuspendIcon } from '../../../assets/images/icons/shield-exclamation.svg';

export interface UserListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: User;
  getDateTime?: (date: Date) => string;
  onEditClick: (user: User['id']) => void;
  onPositionClick: (user: User['id']) => void;
  onTeamClick: (user: User['id']) => void;
  onDeleteClick?: (user: User['id']) => void;
  handleAccountAction: (data: User | undefined, actionType: 'suspend' | 'activate') => void;
}

const UserListRow: React.FC<UserListRowProps> = ({
  className = '',
  data,
  onEditClick,
  onPositionClick,
  onTeamClick,
  handleAccountAction,
  ...props
}: UserListRowProps) => (
  <div
    {...props}
    className={`${className} flex items-center user-list-data table-list-data user-list__wrapper`}
  >
    <div className="user-row-text-sm">{data.id}</div>
    <div className="user-row-text-sm">{data.employment?.employeeNumber ?? ' '}</div>
    <div className="flex gap-3 user-row-text-sm">
      {data.gender === UserGender.MALE ? (
        <MaleGenderIcon className="user-list__wrapper-icon" />
      ) : (
        <FemaleGenderIcon className="user-list__wrapper-icon" />
      )}
      {data.firstName} {data.middleName ? data.middleName : ''} {data.lastName}
    </div>
    <div className="user-row-text-sm">
      {data?.employment?.currentPosition?.employmentPosition?.name ?? ' '}
    </div>
    <div className="user-row-text-xs text-center">{data.dateBirth}</div>

    <div className="action-buttons space-x-6 justify-self-center">
      <Tooltip content="View" className="tooltip-content">
        <button className="textButton" type="button" onClick={() => onEditClick(data.id)}>
          <EyeIcon className="w-6 h-6" />
        </button>
      </Tooltip>

      <Tooltip content="Edit Position" className="tooltip-content">
        <button className="textButton" type="button" onClick={() => onPositionClick(data.id)}>
          <SinglePersonIcon className="w-5 h-5" />
        </button>
      </Tooltip>

      <Tooltip content="Edit Team" className="tooltip-content">
        <button className="textButton" type="button" onClick={() => onTeamClick(data.id)}>
          <EditTeamIcon className="w-5 h-5" />
        </button>
      </Tooltip>
      {data.employment?.employmentStatusId === 5 ? (
        <Tooltip content="Activate" className="tooltip-content">
          <button
            className="textButton"
            type="button"
            onClick={() => handleAccountAction(data, 'activate')}
          >
            <ActivateIcon className="w-6 h-6" />
          </button>
        </Tooltip>
      ) : (
        <Tooltip content="Suspend" className="tooltip-content">
          <button
            className="textButton"
            type="button"
            onClick={() => handleAccountAction(data, 'suspend')}
          >
            <SuspendIcon className="w-6 h-6" />
          </button>
        </Tooltip>
      )}
    </div>
  </div>
);

export default UserListRow;
