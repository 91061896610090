import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import usePositions from '../../../hooks/positions/usePositions';
import EmploymentPosition from '../../../store/constants/employments/positions/employment-position.interface';
import usePositionRates from '../../../hooks/positions/usePositionRates';

export interface SelectEmploymentPositionProps
  extends Partial<AutoSearchSelectProps<EmploymentPosition>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  excludedData?: (EmploymentPosition | undefined)[];
  allowWithRates?: boolean;
}

const SelectEmploymentPosition: React.FC<SelectEmploymentPositionProps> = ({
  id,
  name,
  placeholder = 'Select Employee Position',
  className = '',
  excludedData = [],
  allowWithRates = false,
  ...props
}) => {
  const { positionsState, getAll } = usePositions();
  const { positionRatesState, getAll: getAllPositionRates } = usePositionRates();

  useOnLoad(() => {
    getAll().then();
    if (allowWithRates) {
      getAllPositionRates().then();
    }
  });

  const employmentPositions = positionsState.positions
    .filter((position) => {
      if (allowWithRates) {
        return (
          !excludedData.some((excludedPosition) => excludedPosition?.id === position.id) &&
          positionRatesState.positionRates.some(
            (positionRate) => positionRate.employmentPosition?.id === position.id,
          )
        );
      }
      return !excludedData.some((excludedPosition) => excludedPosition?.id === position.id);
    })
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((position) => ({
      ...position,
      value: position?.id,
      label: position?.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-employment-position modal-select ${className}`}
      isSearchable
      placeholder={placeholder}
      data={employmentPositions}
    />
  );
};

export default SelectEmploymentPosition;
