import React from 'react';
import TeamList from './TeamList';
import ManagementHeading from '../../atoms/ManagementHeading';

const TeamManagement: React.FC = () => (
  <div className="flex w-full organisms-teams-management">
    <div className="w-full flex flex-col">
      <div className="m-2 title">
        <ManagementHeading title="Team" />
      </div>
      <div className="organisms-team-management w-full">
        <TeamList />
      </div>
    </div>
  </div>
);

export default TeamManagement;
