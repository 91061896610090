import apiFetch, { StateHeaders } from '../../../../utils/apiFetch';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import AtLeast from '../../../../types/atleast';
import Payslip from '../../../constants/payroll/payslip.interface';
import toURLParams from '../../../../utils/toURLParams';

export interface GetAllMyPayslipQueryParams extends ApiGetAllBaseQueryParams {
  employeeNumber?: number;
  payPeriodFrom?: string[];
  payPeriodTo?: string[];
}

const get = async (id: Payslip['id'], state: StateHeaders): Promise<ApiBaseResponse<Payslip>> =>
  apiFetch<ApiBaseResponse<Payslip>>(`my/payslips/${id}`, { state })
    .then((response: ApiBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllMyPayslipQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payslip>> =>
  apiFetch<ApiGetAllBaseResponse<Payslip>>(
    `my/payslips?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getMyPayslips = async (
  {
    employeeNumber: employee_number,
    payPeriodFrom: payperiod_from,
    payPeriodTo: payperiod_to,
  }: GetAllMyPayslipQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Payslip>> =>
  apiFetch<ApiGetAllBaseResponse<Payslip>>(
    `my/payrolls/payslips?${toURLParams({
      employee_number,
      payperiod_from,
      payperiod_to,
    })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<Payslip, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payslip> | void> =>
  apiFetch<ApiBaseResponse<Payslip>>('my/payslips', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: Payslip,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payslip> | void> =>
  apiFetch<ApiBaseResponse<Payslip>>(`my/payslips/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<Payslip, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Payslip> | void> =>
  apiFetch<ApiBaseResponse<Payslip>>(`my/payslips/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: Payslip['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<Payslip> | void> =>
  apiFetch<ApiBaseResponse<Payslip>>(`my/payslips/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Payslip>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  getMyPayslips,
} as const;
