import React from 'react';

const HmoDashboardMembers = () => (
  <div className="flex bg-[#343434] shadow-lg rounded-md justify-between w-full p-2">
    <div className="flex flex-col justify-between p-2">
      <div>
        <p className="text-sm text-[#f05825] font-semibold">Total Members</p>
        <p className="text-xs text-gray-200">Principals and Dependents</p>
      </div>
      <div className="text-sm text-gray-200">1200 users</div>
    </div>
    <div className="p-2 bg-[#1abc9c] text-white rounded-full m-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
        />
      </svg>
    </div>
  </div>
);

export default HmoDashboardMembers;
