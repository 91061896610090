import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Loan from '../../constants/loans/loan.interface';
import toURLParams from '../../../utils/toURLParams';

// export type GetAllLoanQueryParams = ApiGetAllBaseQueryParams;
export interface GetAllLoanQueryParams extends ApiGetAllBaseQueryParams {
  departmentId?: number;
  loanTypeId?: number;
  employeeNumber?: number;
}

const get = async (id: Loan['id'], state: StateHeaders): Promise<ApiBaseResponse<Loan>> =>
  apiFetch<ApiBaseResponse<Loan>>(`loans/${id}`, { state })
    .then((response: ApiBaseResponse<Loan>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllTest = async (
  { page = 1, limit = 50, search = '' }: GetAllLoanQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Loan>> =>
  apiFetch<ApiGetAllBaseResponse<Loan>>(
    `loans?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Loan>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  {
    employeeNumber = 0,
    departmentId = 0,
    loanTypeId = 0,
    page = 1,
    limit = 50,
    search = '',
  }: GetAllLoanQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Loan>> =>
  apiFetch<ApiGetAllBaseResponse<Loan>>(
    `loans?${toURLParams({ limit, page, search, employeeNumber, departmentId, loanTypeId })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Loan>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<Loan, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Loan> | void> =>
  apiFetch<ApiBaseResponse<Loan>>('loans', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Loan>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (data: Loan, state: StateHeaders): Promise<ApiBaseResponse<Loan> | void> =>
  apiFetch<ApiBaseResponse<Loan>>(`loans/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Loan>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<Loan, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Loan> | void> =>
  apiFetch<ApiBaseResponse<Loan>>(`loans/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Loan>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (id: Loan['id'], state: StateHeaders): Promise<ApiBaseResponse<Loan> | void> =>
  apiFetch<ApiBaseResponse<Loan>>(`loans/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Loan>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  getAllTest,
  post,
  patch,
  put,
  remove,
} as const;
