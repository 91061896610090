import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDebounce } from 'use-debounce';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import UserEmploymentServices from '../../../../store/services/users/employments/userEmploymentServices';
import { useAppSelector } from '../../../../store/store';
import ApiBaseResponse from '../../../../store/constants/utils/api-base-response.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import PasswordField from '../../../atoms/formFields/PasswordField';
import Spinner from '../../../atoms/Spinner';
import { ReactComponent as WrongIcon } from '../../../../assets/images/icons/close.svg';

export interface UserAccountChangePasswordFormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const initialValues = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

const UserAccountChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Current Password is required.'),
  password: Yup.string().required('Password is required.'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required.')
    .oneOf([Yup.ref('password')], 'Confirm Password must match Password'),
});

const toastPromiseMessages = {
  pending: 'Updating your password...',
  success:
    'Your password is successfully updated. You have been logged out to try your new password.',
  error: 'Password update failed! Try again.',
};

const UserAccountChangePasswordForm = () => {
  const rootState = useAppSelector((state) => state);
  const { updatePassword } = useUserEmployments();

  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentPasswordOk, setIsCurrentPasswordOk] = useState<boolean | null>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordValue] = useDebounce(currentPassword, 1000);
  const [isCheckingPassword, setIsCheckingPassword] = useState(false);

  const handleSubmit = async (values: UserAccountChangePasswordFormValues) => {
    setFormError('');
    setIsLoading(true);

    await toastNotification({
      action: updatePassword(values),
      onSuccess: () => {
        setFormError('');
      },
      onError: (response) => {
        setFormError(response.payload.message);
      },
      onFulfilled: () => {
        setIsLoading(false);
      },
      toastPromiseMessages,
    });
  };

  const onCurrentPasswordKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setCurrentPassword(e.currentTarget.value);
    }
  };

  const handleCurrentPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  useEffect(() => {
    if (currentPasswordValue.length >= 4) {
      setIsCheckingPassword(true);
      UserEmploymentServices.checkPassword(currentPasswordValue, rootState).then((payload) => {
        const { data } = payload as ApiBaseResponse<boolean>;
        setIsCurrentPasswordOk(data);
        setIsCheckingPassword(false);
      });
    }
  }, [currentPasswordValue, rootState]);

  return (
    <div className="w-full flex justify-center user-tab-container">
      <div className="w-full flex flex-col items-center user-password-container">
        <div className="flex justify-between px-7 my-3 w-full">
          <div className="flex justify-center items-center">
            <h1>Change Password</h1>
          </div>
          {formError ? (
            <div className="form-error flex flex-col mb-2">
              <span>Authentication Failed!</span>
              Check your current password.
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="mt-4 user-password-wrapper">
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={UserAccountChangePasswordSchema}
            validateOnChange
            onSubmit={handleSubmit}
          >
            {({ handleChange }) => (
              <Form className="">
                <div className="flex flex-col">
                  {/* Hidden Username Field, due to warning in browser console which requires a Username */}
                  <div className="hidden">
                    <input type="text" name="username" autoComplete="username" value="" readOnly />
                  </div>
                  <div className="flex w-full relative ">
                    <div className="absolute left-[-2.5rem] top-6">
                      {isCheckingPassword && <Spinner height="1.2rem" />}
                      {!isCheckingPassword && isCurrentPasswordOk === false && (
                        <WrongIcon className="text-red-700 w-6 h-auto" />
                      )}
                    </div>
                    <div className="w-full my-2">
                      <PasswordField
                        id="currentPassword"
                        name="currentPassword"
                        placeholder="Current Password"
                        className="w-full rounded-lg shadow-md user-password-field"
                        value={currentPassword}
                        onKeyDown={onCurrentPasswordKeyDown}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleCurrentPassword(e);
                          handleChange(e);
                          return true;
                        }}
                        required
                        autoComplete="current-password"
                      />
                      <ErrorMessage
                        name="currentPassword"
                        component="div"
                        className="pt-2 pl-2 input-error"
                      />
                    </div>
                  </div>
                  {isCurrentPasswordOk && (
                    <>
                      <div className="space-y-2">
                        <PasswordField
                          id="password"
                          name="password"
                          placeholder="New Password"
                          className="w-full rounded-lg shadow-md user-password-field"
                          required
                          autoComplete="new-password"
                        />
                        <ErrorMessage name="password" component="div" className="input-error" />
                      </div>
                      <div className="space-y-2">
                        <PasswordField
                          id="confirmPassword"
                          name="confirmPassword"
                          className="w-full rounded-lg shadow-md user-password-field"
                          placeholder="Confirm Password"
                          required
                          autoComplete="new-password"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="input-error"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="flex justify-center mt-6">
                  <ButtonAction
                    className="p-2 rounded-md shadow-lg muiButton--primary"
                    isLoading={isLoading}
                    text="Update Password"
                    disabled={!isCurrentPasswordOk}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default UserAccountChangePasswordForm;
