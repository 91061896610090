import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useEmploymentStatus from '../../../hooks/employment/useEmploymenStatus';
import EmploymentStatus from '../../../store/constants/employments/employment-status.interface';

export interface EmploymentSSelectEmploymentStatusProps
  extends Partial<AutoSearchSelectProps<EmploymentStatus>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectEmploymentStatus: React.FC<EmploymentSSelectEmploymentStatusProps> = ({
  id,
  name,
  placeholder = 'Select Employee Status',
  className = '',
  ...props
}) => {
  const { employmentStatusState, getAll } = useEmploymentStatus();

  useOnLoad(() => {
    getAll().then();
  });

  const employmentStatus = employmentStatusState.employmentStatus
    .slice()
    // @TODO There should be a sort by order in backend
    .sort((a, b) => b.id - a.id)
    .map((data) => ({
      ...data,
      value: data.id,
      label: data.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-employment-role modal-select ${className}`}
      placeholder={placeholder}
      data={employmentStatus}
    />
  );
};

export default SelectEmploymentStatus;
