import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface FormikFOrmValuesMonitorProps<T> {
  onChange: (values: T) => void;
}

// @todo must be in an arrow function
// eslint-disable-next-line react/function-component-definition
function FormikFOrmValuesMonitor<T>({ onChange }: FormikFOrmValuesMonitorProps<T>) {
  const formik = useFormikContext<T>();

  useEffect(() => {
    onChange(formik.values);
  }, [formik.values, onChange]);

  return null;
}

export default FormikFOrmValuesMonitor;
