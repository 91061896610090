import React from 'react';

const HmoDashboardPrincipal = () => (
  <div className="flex bg-[#343434] rounded-md shadow-lg justify-between w-full p-2">
    <div className="flex flex-col justify-between p-2">
      <div>
        <p className="text-sm text-[#f05825] font-semibold">Total Principals</p>
        <p className="text-xs text-gray-200">Last Update was on May 25, 2023</p>
      </div>
      <div className="text-sm text-gray-200">1200 users</div>
    </div>
    <div className="p-2 bg-[#9b59b6] text-white rounded-full m-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  </div>
);

export default HmoDashboardPrincipal;
