import React from 'react';
import { Typography } from '@material-tailwind/react';
import OvertimesList from './OvertimesList';

const OvertimesManagement: React.FC = () => (
  <div className="w-full flex flex-col overtime-organism-parent">
    <div className="m-2">
      <div className="title">
        <Typography variant="h3" className="ml-1 mt-2 mb-5 overtime-manangement-title">
          My Overtime
        </Typography>
      </div>
    </div>
    <div className="w-full organisms-overtime-management">
      <OvertimesList />
    </div>
  </div>
);

export default OvertimesManagement;
