import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import Client from '../../../../store/constants/clients/client.interface';

export interface TeamListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Client;
  getDateTime: (date: Date) => string;
}

const ClientPositionPageRow: React.FC<TeamListRowProps> = ({
  className = '',
  data,
  getDateTime,
  ...props
}: TeamListRowProps) => {
  const navigate = useNavigate();

  const handleOnClientManagersClick = () => {
    navigate(`/admin/clients/${data.id}/managers`);
  };

  const maxNumberData = 2;

  const employeeFullName = data.managers
    ?.map((manager) => manager.userInformation?.fullName)
    .filter((fullName): fullName is string => fullName !== undefined);

  const department = data.managers
    ?.map((manager) => manager.department?.name)
    .filter((dept): dept is string => dept !== undefined);

  const showEllipsis = (dataArray: string[] | undefined) => dataArray && dataArray.length > maxNumberData ? '...' : '';

  return (
    <div
      {...props}
      className={`${className} client-positions-list-data table-list-data flex justify-center`}
    >
      <div>{data.id}</div>
      <div className="client">{data.name}</div>
      <div className="flex gap-3">
        {employeeFullName?.slice(0, maxNumberData).join(', ')}
        {showEllipsis(employeeFullName)}
      </div>
      <div className="flex gap-3">
        {department?.slice(0, maxNumberData).join(', ')}
        {showEllipsis(department)}
      </div>
      <div>{data.dateStart}</div>
      <div className="space-x-2 action-buttons justify-self-center">
        <Tooltip content="View Client Managers" className="bg-[#343434]">
          <button type="button" onClick={() => handleOnClientManagersClick()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 cursor-pointer textButton"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ClientPositionPageRow;
