import React, { useState } from 'react';
import { Menu, MenuHandler, MenuList, MenuItem, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import GenerateThirteenthMonth from '../13month/GenerateThirteenthMonth';
import { ReactComponent as Arrow } from '../../../../../assets/images/icons/arrow-sort.svg';
import { ReactComponent as Calendar } from '../../../../../assets/images/icons/calendar.svg';
import { ReactComponent as Plus } from '../../../../../assets/images/icons/plus-folder.svg';
import { ReactComponent as Upload } from '../../../../../assets/images/icons/upload-file.svg';
import UploadTransactionMonth from '../13month/UploadTransactionMonth';

const menuData = {
  '13th Month': [
    {
      name: 'Semi annual',
      icon: <Calendar className="w-4 h-4" />,
      linkTo: '/payroll/13month/semi-annual',
    },
    {
      name: 'Monthly',
      icon: <Calendar className="w-4 h-4" />,
      linkTo: '/payroll/13month/monthly',
    },
  ],
};

const PayrollThirteenthMonthMenu: React.FC = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [generateThirteenthMonthOpen, setGenerateThirteenthMonthOpen] = useState(false);
  const [uploadTransactionOpen, setUploadTransactionOpen] = useState(false);

  return (
    <div>
      {Object.entries(menuData).map(([title, items]) => (
        <Menu open={openMenu} handler={setOpenMenu} placement="bottom-start" allowHover key={title}>
          <MenuHandler className="menu-handler p-1 hover:bg-[#f05825] rounded-md">
            <div className="menu-handler__title">
              <Calendar className="w-4 h-4" />
              <span className="text-xs font-normal">{title}</span>
              <Arrow
                strokeWidth={1.5}
                className={`h-3.5 w-3.5 transition-transform ml-2 ${openMenu ? 'rotate-180' : ''}`}
              />
            </div>
          </MenuHandler>
          <div className="menu-list-container">
            <MenuList className="mt-1 menu-list">
              <div className="focus:outline-none">
                <ul>
                  {items.map((item) => (
                    <li key={item.name}>
                      <Link to={item.linkTo}>
                        <MenuItem className="flex items-center gap-5 pl-2 menu-item">
                          {item.icon}
                          <Typography className="menu-title">{item.name}</Typography>
                        </MenuItem>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <MenuItem
                      className="flex items-center gap-5 pl-2 menu-item"
                      onClick={() => setGenerateThirteenthMonthOpen(true)}
                    >
                      <Plus className="w-4 h-4" />
                      <Typography className="menu-title">Generate New 13th Month</Typography>
                    </MenuItem>
                  </li>
                  <li>
                    <MenuItem
                      className="flex items-center gap-5 pl-2 menu-item"
                      onClick={() => setUploadTransactionOpen(true)}
                    >
                      <Upload className="w-4 h-4" />
                      <Typography className="menu-title">Upload Transaction 13th Month</Typography>
                    </MenuItem>
                  </li>
                </ul>
              </div>
            </MenuList>
          </div>
        </Menu>
      ))}
      {generateThirteenthMonthOpen && (
        <GenerateThirteenthMonth
          isOpen={generateThirteenthMonthOpen}
          setOpen={setGenerateThirteenthMonthOpen}
        />
      )}
      {uploadTransactionOpen && (
        <UploadTransactionMonth isOpen={uploadTransactionOpen} setOpen={setUploadTransactionOpen} />
      )}
    </div>
  );
};

export default PayrollThirteenthMonthMenu;
