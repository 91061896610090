import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import Client from '../../../store/constants/clients/client.interface';
import useClients from '../../../hooks/clients/useClients';

export interface SelectClientProps extends Partial<AutoSearchSelectProps<Client>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  excludedData?: Client[];
}

const SelectClient: React.FC<SelectClientProps> = ({
  id,
  name,
  placeholder = 'Select Client',
  className = '',
  excludedData = [],
  ...props
}) => {
  const { clientsState, getAll } = useClients();

  useOnLoad(() => {
    getAll().then();
  });

  const clients = clientsState.clients
    .filter((client) => !excludedData.some((excludedClient) => excludedClient.id === client.id))
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((client: Client) => ({
      ...client,
      value: client.id,
      label: client.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-client modal-select ${className}`}
      placeholder={placeholder}
      data={clients}
      isSearchable
    />
  );
};

export default SelectClient;
