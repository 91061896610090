import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import UserNewsFeed from '../organisms/user/newsfeed/UserNewsFeed';
import UserOSNetTroubleshootingTips from '../organisms/user/UserOSNetTroubleshootingTips';
import UserDOHVaxReport from '../organisms/user/UserDOHVaxReport';
import useAuth from '../../hooks/auths/useAuth';
import ClientManagement from '../organisms/client/ClientManagement';
import UserFormStepper from '../organisms/user/UserFormStepper';
import UserTeamAssignment from '../molecules/Users/UserTeamAssignment';
import UserPositionAssignment from '../molecules/Users/UserPositionAssignment';
import TeamManagement from '../organisms/team/TeamManagement';
import HolidayManagement from '../organisms/holidays/HolidayManagement';
import PositionManagePage from '../organisms/positions/manage/PositionManagePage';
import DepartmentManagement from '../organisms/department/DepartmentManagement';
import CompanyManagement from '../organisms/company/CompanyManagement';
import ScheduleManagement from '../organisms/schedule/ScheduleManagement';
import AttendancePage from '../organisms/reports/AttendancePage';
import UserNavbar from '../organisms/user/navbar/UserHeader';
import UserManagement from '../organisms/user/UserManagement';
import UserEmploymentSummary from '../molecules/Users/UserUpdateForm';
import ClientManageAddForm from '../organisms/client/ClientAddForm';
import ClientEditForm from '../organisms/client/ClientEditForm';
import ClientManagersList from '../organisms/client/ClientManagersList';
import ClientEmployeeList from '../organisms/client/ClientEmployeeList';
import ScheduleManagementList from '../organisms/schedule/ScheduleManagementList';
import TeamAssignment from '../organisms/team/TeamAssignment';
import LeaveListManagement from '../organisms/leave/leaveList/LeaveListManagement';
import ClientTeamList from '../organisms/client/ClientTeamList';
import PositionEmployeeList from '../organisms/positions/employees/PositionEmployeeList';
import ClientPositionPage from '../organisms/positions/clients/ClientPositionPage';
import CompanyBranchEditForm from '../organisms/company/branch/CompanyBranchEditForm';
import CompanyBranchAddForm from '../organisms/company/branch/CompanyBranchAddForm';
import CompanyBranchManagement from '../organisms/company/branch/CompanyBranchManagement';
import LeaveAddForm from '../organisms/leave/leaveApplication/LeaveAddForm';
import LeaveEditForm from '../organisms/leave/leaveApplication/LeaveEditForm';
import LoanManagementPage from '../organisms/payroll/LoanManagementPage';
import OvertimePage from '../organisms/reports/overtime/OvertimePage';
import TimelogPage from '../organisms/reports/TimelogPage';
import ReportsPage from '../organisms/reports/ReportsPage';
import DepartmentTeamList from '../organisms/department/DepartmentTeamList';
import HmoManagement from '../organisms/hmo/members/HmoManagement';
import HmoDashboard from '../organisms/hmo/dashboard/HmoDashboard';
import SideMenu from '../organisms/user/sidebar/SideMenu';
import AdminPageSidebar from '../organisms/user/sidebar/AdminPageSidebar';
import EmployeeTimelogPageList from '../organisms/reports/EmployeeTimelogPageList';
import InactiveEmployeesPage from '../organisms/reports/reportsPages/InactiveEmployeesPage';
import AccountInfoReportPage from '../organisms/reports/reportsPages/AccountInfoReportPage';
import AttendanceExportPage from '../organisms/reports/reportsPages/AttendanceExportPage';
import ClientDailyAttendanceReportPage from '../organisms/reports/reportsPages/ClientDailyAttendanceReportPage';
import ReferralDataPage from '../organisms/reports/reportsPages/ReferralDataPage';
import TermsAndAgreementReportPage from '../organisms/reports/reportsPages/TermsAndAgreementReportPage';
import EmployeeHmoManagementPage from '../organisms/payroll/employeeHmoModule/EmployeeHmoManagementPage';
import EmployeeDependentManagementPage from '../organisms/payroll/employeeDependentModule/EmployeeDependentManagementPage';
import DependentHmoManagement from '../organisms/payroll/dependentHmoModule/DependentHmoManagement';
import HdmfManagement from '../organisms/payroll/hdmfModule/HdmfManagement';
import SalaryLookupManagement from '../organisms/payroll/salaryLookupModule/SalaryLookupManagement';
import AdminDashboard from '../organisms/user/sidebar/dashboard/AdminDashboard';
import BillingReport from '../organisms/billing/BillingReport';
import BillingTitles from '../organisms/billing/BillingTitles';
import BillingGroups from '../organisms/billing/BillingGroups';

const AdminTemplate: React.FC = () => {
  const { authState } = useAuth();
  const location = useLocation();

  if (!authState.token) {
    return <Navigate to="/login" replace />;
  }

  // Check if the current URL includes "/newsfeed"
  const displaySideMenu = location.pathname.includes('/newsfeed');

  return (
    <div className="admin-parent-container">
      <UserNavbar />
      <div className="flex gap-5 admin-container">
        <div className={`sidebarAdmin-wrapper ${displaySideMenu ? 'hidden' : 'block z-50'}`}>
          {/* <SidebarHome /> */}
          <AdminPageSidebar />
        </div>
        <div className={`sidemenu-container ${displaySideMenu ? 'block' : 'hidden -z-50'}`}>
          <div className="mt-[5.5rem]">
            <SideMenu />
          </div>
        </div>
        <div className={`${displaySideMenu ? 'main-page' : 'admin-center-wrapper'}`}>
          <Routes>
            <Route path="/user/manage/new" element={<UserFormStepper />} />
            <Route path="/user/manage/new/:step" element={<UserFormStepper />} />
            <Route path="/user/manage/:id/summary" element={<UserEmploymentSummary />} />
            <Route path="/user/manage/:id" element={<UserFormStepper />} />
            <Route path="/user/manage/:id/position" element={<UserPositionAssignment />} />
            <Route path="/user/manage/:id/team" element={<UserTeamAssignment />} />
            <Route path="/user/manage" element={<UserManagement />} />

            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/clients" element={<ClientManagement />} />
            <Route path="/clients/new" element={<ClientManageAddForm />} />
            <Route path="/clients/:id" element={<ClientEditForm />} />
            <Route path="/clients/:id/managers" element={<ClientManagersList />} />
            <Route path="/clients/:id/employees" element={<ClientEmployeeList />} />
            <Route path="/clients/:id/teams-list" element={<ClientTeamList />} />
            <Route path="/teams" element={<TeamManagement />} />
            <Route path="/teams/:id/employees" element={<TeamAssignment />} />
            <Route path="/holidays" element={<HolidayManagement />} />
            <Route path="/positions" element={<PositionManagePage />} />
            <Route path="/positions/:id/user-positions" element={<PositionEmployeeList />} />
            <Route path="/positions/:id/client-positions" element={<ClientPositionPage />} />
            <Route path="/departments" element={<DepartmentManagement />} />
            <Route path="/departments/:id/teams-list" element={<DepartmentTeamList />} />

            <Route path="/companies" element={<CompanyManagement />} />
            <Route path="/companies/branches" element={<CompanyBranchManagement />} />
            <Route path="/companies/branches/new" element={<CompanyBranchAddForm />} />
            <Route path="/companies/branches/:id" element={<CompanyBranchEditForm />} />
            <Route path="/scheduler" element={<ScheduleManagement />} />
            <Route path="/scheduler/:id/employees" element={<ScheduleManagementList />} />

            <Route path="/attendance" element={<AttendancePage />} />
            <Route path="/attendance/logs" element={<TimelogPage />} />
            <Route path="/attendance/logs/employees/:id" element={<EmployeeTimelogPageList />} />
            <Route path="/attendance/overtimes" element={<OvertimePage />} />
            <Route path="/attendance/reports" element={<ReportsPage />} />
            <Route
              path="/attendance/reports/inactive-employee-report"
              element={<InactiveEmployeesPage />}
            />
            <Route
              path="/attendance/reports/account_info_report"
              element={<AccountInfoReportPage />}
            />
            <Route
              path="/attendance/reports/attendance-export"
              element={<AttendanceExportPage />}
            />
            <Route
              path="/attendance/reports/ajax-client-leave-reports"
              element={<ClientDailyAttendanceReportPage />}
            />
            <Route path="/attendance/reports/referral-data" element={<ReferralDataPage />} />
            <Route
              path="/attendance/reports/term-and-agreement-reports"
              element={<TermsAndAgreementReportPage />}
            />

            <Route path="/leaves/applications" element={<LeaveAddForm />} />
            <Route path="/leaves/applications/:id" element={<LeaveEditForm />} />
            <Route path="/leaves" element={<LeaveListManagement />} />

            <Route path="/payroll/loan" element={<LoanManagementPage />} />
            <Route path="/employee/hmo" element={<EmployeeHmoManagementPage />} />
            <Route path="/employee/dependent" element={<EmployeeDependentManagementPage />} />
            <Route path="/dependent/hmo" element={<DependentHmoManagement />} />
            <Route path="/employee/hdmf" element={<HdmfManagement />} />
            <Route path="/payroll/salary-lookup" element={<SalaryLookupManagement />} />

            <Route path="/hmo/members" element={<HmoManagement />} />
            <Route path="/hmo/dashboard" element={<HmoDashboard />} />

            <Route path="/doh/vax-report" element={<UserDOHVaxReport />} />
            <Route path="/osnet/troubleshooting" element={<UserOSNetTroubleshootingTips />} />
            <Route path="/newsfeed" element={<UserNewsFeed />} />
            <Route path="*" element={<Navigate replace to="newsfeed" />} />

            <Route path="/billing/report" element={<BillingReport />} />
            <Route path="/billing/titles" element={<BillingTitles />} />
            <Route path="/billing/groups" element={<BillingGroups />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminTemplate;
