import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';

export interface StatusOption {
  label: string;
  value: string;
}

export interface SelectStatusProps extends Partial<AutoSearchSelectProps<StatusOption>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  options?: StatusOption[];
}

const defaultStatusOptions: StatusOption[] = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Approved', value: 'Approved' },
];

const SelectStatusOptions: React.FC<SelectStatusProps> = ({
  id,
  name,
  placeholder = 'Select Status',
  className = '',
  options = [],
  ...props
}) => {
  const customOptions = [...defaultStatusOptions, ...options];

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-status-option modal-select ${className}`}
      placeholder={placeholder}
      data={customOptions}
      isSearchable
    />
  );
};

export default SelectStatusOptions;
