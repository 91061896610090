import React from 'react';
import CompanyList from './CompanyList';
import ManagementHeading from '../../atoms/ManagementHeading';

const CompanyManagement: React.FC = () => (
  <div className="flex w-full organisms-company-management">
    <div className="w-full flex flex-col">
      <div className="m-2 title">
        <ManagementHeading title="Company" />
      </div>
      <div>
        <CompanyList />
      </div>
    </div>
  </div>
);

export default CompanyManagement;
