import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import { ScheduleDaysOption } from '../../../store/reducers/schedules/schedulesReducer';
import useSchedules from '../../../hooks/schedule/useSchedules';

export interface SelectDaysProps extends Partial<AutoSearchSelectProps<ScheduleDaysOption>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  initialValue?: string;
}

const SelectDays: React.FC<SelectDaysProps> = ({
  id,
  name,
  placeholder = 'Select Days',
  className = '',
  initialValue = undefined,
  ...props
}) => {
  const {
    schedulesState: { scheduleDaysOption },
  } = useSchedules();

  if (!props.value && initialValue) {
    const daysString = initialValue;
    const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const daysStringArray = daysString?.split(',');

    const selectedDays = daysStringArray?.map((valueString) => {
      const dayValue = parseInt(valueString, 10);
      const labelNumDays = dayValue - 1; // Adjust Number of days based on 1-based day numbering
      return {
        value: dayValue,
        label: labels[labelNumDays],
      };
    });

    // eslint-disable-next-line
    // @ts-ignore
    // @TODO: Fix this
    props.value = selectedDays as ScheduleDaysOption[];
  }

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-days modal-select ${className}`}
      placeholder={placeholder}
      data={scheduleDaysOption}
    />
  );
};

export default SelectDays;
