import moment from 'moment/moment';

const uniqueDates = (dates: Date[]) => {
  // Create a Set to store unique date strings
  const uniqueDateStrings = new Set<string>();

  // Iterate through the original array and add date strings to the Set
  dates
    .sort((a, b) => a.getTime() - b.getTime())
    .forEach((date) => {
      uniqueDateStrings.add(moment(date).format('YYYY-MM-DD')); // Use ISO string for consistent comparison
    });

  // Convert the unique date strings back to date objects
  return Array.from(uniqueDateStrings).map((date) => new Date(date));
};

export default uniqueDates;
