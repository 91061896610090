import React, { useEffect } from 'react';
import { ConfigProvider, Table } from 'antd';
import { ReactComponent as Money } from '../../../../../../assets/images/icons/money.svg';
import getLastPay from './LastPayColumn';
import usePayrolls from '../../../../../../hooks/payroll/usePayrolls';

const LastPayTable: React.FC = () => {
  const { payrollsState, getLastpays } = usePayrolls();
  const getLastPayColumns = getLastPay();

  const titleContent = () => (
    <div className="flex items-center justify-between px-5">
      <div className="flex items-center gap-2">
        <Money className="w-5 h-auto" />
        <span>Show Last Pay</span>
      </div>
      <span>Employee List</span>
    </div>
  );

  const showTotal = (totalCount: number, range: [number, number]) => (
    <div className="ant-custom-show-total">{`Displaying ${range[0]}-${range[1]} of ${totalCount} items`}</div>
  );

  useEffect(
    () => {
      getLastpays({ ...payrollsState.payrollParams });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payrollsState.payrollParams],
  );

  return (
    <div className="px-2 payroll-container-parent thirteenth-container">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              rowHoverBg: '#3e3e3e !important',
            },
          },
        }}
      >
        <Table
          className="payroll-table-content"
          columns={getLastPayColumns}
          dataSource={payrollsState.employmentPayrolls.map((item) => ({ ...item, key: item.id }))}
          size="small"
          tableLayout="auto"
          scroll={{ x: 'max-content', y: 'calc(100vh - 13rem)' }}
          title={() => titleContent()}
          pagination={{
            position: ['bottomRight'],
            defaultPageSize: 20,
            showTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50'],
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default LastPayTable;
