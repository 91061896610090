import { useState } from 'react';
import useOnLoad from '../utils/useOnLoad';
import useUserEmployments from './useUserEmployments';
import Employee from '../../store/constants/employees/employee.interface';

const useUserEmployment = (id: string) => {
  const {
    get,
    userEmploymentsStates: { userEmployments },
  } = useUserEmployments();
  const [loading, setLoading] = useState(true);

  const userEmploymentId = parseInt(id, 10);
  const currentUserEmployment = userEmployments.find(
    (userEmployment) => userEmployment.id === userEmploymentId,
  );

  useOnLoad(() => {
    setLoading(true);
    get(currentUserEmployment as Employee).then(() => {
      setLoading(false);
    });
  });

  return {
    loading,
    currentUserEmployment,
  };
};

export default useUserEmployment;
