import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import EmploymentPositionRate from '../../constants/employments/positions/employment-position-rate.interface';
import positionRatesActions from '../../actions/positions/positionRateActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface PositionRatesState {
  positionRates: EmploymentPositionRate[];
}

const initialState: PositionRatesState = {
  positionRates: [],
};

const getPositionRatesData = (positionRates: Partial<EmploymentPositionRate>) => ({
  ...positionRates,
});

const upsertItems = (
  positionRates: EmploymentPositionRate[],
  ...data: EmploymentPositionRate[]
) => {
  const dataObject: { [id: number]: EmploymentPositionRate } = {};
  positionRates.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((positionRate) => {
    if (!dataObject[positionRate.id]) {
      dataObject[positionRate.id] = positionRate;
    } else if (dataObject[positionRate.id]) {
      dataObject[positionRate.id] = { ...dataObject[positionRate.id], ...positionRate };
    }
  });

  return Object.values(dataObject);
};

const positionRatesSlice = createSlice({
  name: 'positionRates',
  initialState,
  reducers: {
    resetPositionRatesState: () => ({
      ...initialState,
    }),
    setPositionRatesState: (state, action: PayloadAction<Partial<PositionRatesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertPositionRates: (state, action: PayloadAction<Partial<EmploymentPositionRate>>) => {
      const data: EmploymentPositionRate = getPositionRatesData(
        action.payload,
      ) as EmploymentPositionRate;

      if (typeof data.id === 'undefined') return;

      state.positionRates = upsertItems(state.positionRates, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(positionRatesActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<EmploymentPositionRate>;
      consoleLog('POSITION RATES REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.positionRates = upsertItems(state.positionRates, data);
      }
    });
    builder.addCase(positionRatesActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('POSITION RATES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmploymentPositionRate>;

      if (status === 503) return;

      if (data) {
        state.positionRates = data;
      }
    });
    builder.addCase(positionRatesActions.get.fulfilled, (state, { payload }) => {
      consoleLog('POSITION RATES REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<EmploymentPositionRate>;

      if (typeof data.id === 'undefined') return;
      state.positionRates = upsertItems(state.positionRates, data);
    });
    builder.addCase(positionRatesActions.put.fulfilled, (state, { payload }) => {
      consoleLog('POSITION RATES REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<EmploymentPositionRate>;

      if (status !== StatusCodes.OK) return;
      state.positionRates = upsertItems(state.positionRates, data);
    });
    builder.addCase(positionRatesActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('POSITION RATES REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<EmploymentPositionRate>;

      if (typeof data.id === 'undefined') return;
      state.positionRates = state.positionRates.filter(
        (positionRate) => positionRate.id !== data.id,
      );
    });
  },
});

export const positionRatesReducerActions = positionRatesSlice.actions;
export default positionRatesSlice.reducer;
