import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuHandler, MenuList, MenuItem, Typography } from '@material-tailwind/react';
import { ReactComponent as Arrow } from '../../../../../assets/images/icons/arrow-sort.svg';
import { ReactComponent as Settings } from '../../../../../assets/images/icons/settings.svg';
import { ReactComponent as TeamIcon } from '../../../../../assets/images/icons/user-two.svg';
import GenerateCompanyITR from '../others/GenerateCompanyITR';
import GenerateAlphalist from '../others/GenerateAlphalist';
import TeamGross from '../others/TeamGross';

const menuData = [
  {
    name: 'Manage Incentives',
    icon: <Settings className="w-4 h-4" />,
    linkTo: '/payroll/incentive/add',
  },
];

const PayrollOthers: React.FC = () => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [generateCompanyITROpen, setGenerateCompanyITROpen] = useState(false);
  const [generateAlphalistOpen, setGenerateAlphalistOpen] = useState(false);
  const [teamGrossOpen, setTeamGrossOpen] = useState(false);

  return (
    <div>
      <Menu placement="bottom-start" open={openMenu} handler={setOpenMenu} allowHover>
        <MenuHandler className="menu-handler p-1 hover:bg-[#f05825] rounded-md">
          <div className="menu-handler__title">
            <span className="text-xs font-normal">Others</span>
            <Arrow
              strokeWidth={1.5}
              className={`h-3.5 w-3.5 transition-transform ml-2 ${openMenu ? 'rotate-180' : ''}`}
            />
          </div>
        </MenuHandler>
        <div className="menu-list-container">
          <MenuList className="mt-1 menu-list">
            <div className="focus:outline-none">
              <ul>
                {menuData.map((item) => (
                  <li key={item.name}>
                    <Link to={item.linkTo}>
                      <MenuItem className="flex items-center gap-5 pl-2 menu-item">
                        {item.icon}
                        <Typography className="menu-title">{item.name}</Typography>
                      </MenuItem>
                    </Link>
                  </li>
                ))}
                <li>
                  <MenuItem
                    className="flex items-center gap-5 pl-2 menu-item"
                    onClick={() => setGenerateCompanyITROpen(true)}
                  >
                    <Settings className="w-4 h-4" />
                    <Typography className="menu-title">Generate Company ITR</Typography>
                  </MenuItem>
                </li>
                <li>
                  <MenuItem
                    className="flex items-center gap-5 pl-2 menu-item"
                    onClick={() => setGenerateAlphalistOpen(true)}
                  >
                    <Settings className="w-4 h-4" />
                    <Typography className="menu-title">Generate alphalist</Typography>
                  </MenuItem>
                </li>
                <li>
                  <MenuItem
                    className="flex items-center gap-5 pl-2 menu-item"
                    onClick={() => setTeamGrossOpen(true)}
                  >
                    <TeamIcon className="w-4 h-4" />
                    <Typography className="menu-title">Team gross</Typography>
                  </MenuItem>
                </li>
              </ul>
            </div>
          </MenuList>
        </div>
      </Menu>
      {generateCompanyITROpen && (
        <GenerateCompanyITR isOpen={generateCompanyITROpen} setOpen={setGenerateCompanyITROpen} />
      )}
      {generateAlphalistOpen && (
        <GenerateAlphalist isOpen={generateAlphalistOpen} setOpen={setGenerateAlphalistOpen} />
      )}
      {teamGrossOpen && <TeamGross isOpen={teamGrossOpen} setOpen={setTeamGrossOpen} />}
    </div>
  );
};

export default PayrollOthers;
