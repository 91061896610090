import React from 'react';
import LabelledToggleSwitch from '../../../../molecules/LabelledToggleSwitch';
// eslint-disable-next-line import/no-cycle
import type { SwitchStates } from '../PayrollToolOptions';

interface OptionsContentProps {
  switchStates: SwitchStates;
  handleParamsSwitchesChange: (id: string, checked: boolean) => void;
}

const EmployeeSettings: React.FC<OptionsContentProps> = ({
  switchStates,
  handleParamsSwitchesChange,
}) => (
  <div className="payroll-create-employee-settings">
    <div className="payroll-create-employee-settings__switches">
      <div className="payroll-create-employee-settings__section">
        <LabelledToggleSwitch
          id="inactive"
          label="Inactive"
          isChecked={switchStates.inactive}
          onChange={(checked) => handleParamsSwitchesChange('inactive', checked)}
        />
        <LabelledToggleSwitch
          id="resignedNoCase"
          label="Resigned"
          isChecked={switchStates.resignedNoCase}
          onChange={(checked) => handleParamsSwitchesChange('resigned', checked)}
        />
        <LabelledToggleSwitch
          id="authorizedCause"
          label="Authorized cause"
          isChecked={switchStates.authorizedCause}
          onChange={(checked) => handleParamsSwitchesChange('authorizedCause', checked)}
        />
      </div>
      <div className="payroll-create-employee-settings__section">
        <LabelledToggleSwitch
          id="suspended"
          label="Suspended"
          isChecked={switchStates.suspended}
          onChange={(checked) => handleParamsSwitchesChange('suspended', checked)}
        />
        <LabelledToggleSwitch
          id="endOfContract"
          label="End of contract"
          isChecked={switchStates.endOfContract}
          onChange={(checked) => handleParamsSwitchesChange('endOfContract', checked)}
        />
        <LabelledToggleSwitch
          id="floating"
          label="Floating"
          isChecked={switchStates.floating}
          onChange={(checked) => handleParamsSwitchesChange('floating', checked)}
        />
      </div>
      <div className="payroll-create-employee-settings__section">
        <LabelledToggleSwitch
          id="dropTraining"
          label="Drop (Training)"
          isChecked={switchStates.dropTraining}
          onChange={(checked) => handleParamsSwitchesChange('dropTraining', checked)}
        />
        <LabelledToggleSwitch
          id="awol"
          label="AWOL"
          isChecked={switchStates.awol}
          onChange={(checked) => handleParamsSwitchesChange('awol', checked)}
        />
      </div>
      <div className="payroll-create-employee-settings__section">
        <LabelledToggleSwitch
          id="resignedWithCase"
          label="Resigned (Case)"
          isChecked={switchStates.resignedWithCase}
          onChange={(checked) => handleParamsSwitchesChange('resignedWithCase', checked)}
        />

        <LabelledToggleSwitch
          id="terminated"
          label="Terminated"
          isChecked={switchStates.terminated}
          onChange={(checked) => handleParamsSwitchesChange('terminated', checked)}
        />
      </div>
    </div>
    <div className="flex self-end justify-center">
      <span className="text-xs"> Employee settings</span>
    </div>
  </div>
);

export default EmployeeSettings;
