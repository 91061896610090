import React from 'react';
import HmoDashboardChart from './HmoDashboardChart';
import HmoDashboardDependents from './HmoDashboardDependents';
import HmoDashboardFreeDependents from './HmoDashboardFreeDependents';
import HmoDashboardMembers from './HmoDashboardMembers';
import HmoDashboardPrincipal from './HmoDashboardPrincipal';

import HmoMemberList from '../members/HmoMemberList';
import HmoGeneralList from './HmoGeneralList';

const HmoDashboard = () => (
  <div className="flex flex-col gap-y-4 w-full h-full -ml-2 px-2 py-4 ">
    <div className="dashboard__top__container flex gap-10">
      <div className="chart__container w-full rounded-lg shadow-md">
        <HmoDashboardChart />
      </div>
      <div className="grid grid-cols-2 gap-5 info__container w-full">
        <div className="flex flex-col gap-3">
          <HmoDashboardPrincipal />
          <HmoDashboardDependents />
        </div>
        <div className="flex flex-col gap-3">
          <HmoDashboardMembers />
          <HmoDashboardFreeDependents />
        </div>
      </div>
    </div>
    <div className="flex gap-3 dashboard__bottom__container">
      <div className="member__list__contaner w-full h-1/2">
        <HmoMemberList isSearchable={false} filter={false} />
      </div>
      <div className="general__list__container">
        <HmoGeneralList />
      </div>
    </div>
  </div>
);

export default HmoDashboard;
