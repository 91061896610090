import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import HmoDependentType from '../../constants/my/hmo-dependent.interface';

export type GetAllHmoDependentsQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: HmoDependentType['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<HmoDependentType>> =>
  apiFetch<ApiBaseResponse<HmoDependentType>>(`hmo/dependent/${id}`, { state })
    .then((response: ApiBaseResponse<HmoDependentType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllHmoDependentsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<HmoDependentType>> =>
  apiFetch<ApiGetAllBaseResponse<HmoDependentType>>(
    `hmo/dependent?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<HmoDependentType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<HmoDependentType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<HmoDependentType> | void> =>
  apiFetch<ApiBaseResponse<HmoDependentType>>('hmo/dependent', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<HmoDependentType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: HmoDependentType,
  state: StateHeaders,
): Promise<ApiBaseResponse<HmoDependentType> | void> =>
  apiFetch<ApiBaseResponse<HmoDependentType>>(`hmo/dependent/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<HmoDependentType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<HmoDependentType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<HmoDependentType> | void> =>
  apiFetch<ApiBaseResponse<HmoDependentType>>(`hmo/dependent/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<HmoDependentType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: HmoDependentType['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<HmoDependentType> | void> =>
  apiFetch<ApiBaseResponse<HmoDependentType>>(`hmo/dependent/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<HmoDependentType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
