import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Team from '../../constants/teams/team.interface';
import teamServices, { GetAllTeamsQueryParams } from '../../services/teams/teamServices';

const get = createAsyncThunk(
  'team/get',
  async (
    id: Team['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'team/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllTeamsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'team/post',
  async (
    data: Omit<Team, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'team/put',
  async (
    data: AtLeast<Team, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'team/remove',
  async (
    id: Team['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAllTeamEmployees = createAsyncThunk(
  'team/getAllEmployees',
  async (
    id: Team['id'],
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.getAllTeamEmployees(id, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const updateTeamLeader = createAsyncThunk(
  'team/updateTeamLeader',
  async (
    data: AtLeast<Team, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.updateTeamLeader(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const removeDepartmentFromTeam = createAsyncThunk(
  'team/removeDepartmentFromTeam',
  async (
    data: AtLeast<Team, 'id' | 'departmentId'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Team> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await teamServices.removeDepartmentFromTeam(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default {
  get,
  getAll,
  post,
  put,
  remove,
  getAllTeamEmployees,
  updateTeamLeader,
  removeDepartmentFromTeam,
} as const;
