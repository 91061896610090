import { useAppDispatch, useAppSelector } from '../../../store/store';
import nurseStationVisitActions from '../../../store/actions/my/healthTracker/nurseStationVisitActions';
import {
  NurseStationVisitsState,
  nurseStationVisitsReducerActions,
} from '../../../store/reducers/my/healthTracker/nurseStationVisitsReducer';
import NurseStationVisit from '../../../store/constants/my/healthTracker/nurse-station-visit.interface';

const useNurseStationVisits = () => {
  const dispatch = useAppDispatch();
  const nurseStationVisitsState: NurseStationVisitsState = useAppSelector(
    (state) => state.nurseStationVisits,
  );

  const resetNurseStationVisitState = () => {
    dispatch(nurseStationVisitsReducerActions.resetNurseStationVisitsState());
  };

  const setNurseStationVisitState = (state: Partial<NurseStationVisitsState>) => {
    dispatch(nurseStationVisitsReducerActions.setNurseStationVisitsState(state));
  };

  const get = async (id: NurseStationVisit['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(nurseStationVisitActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(nurseStationVisitActions.getAll({}));

  const post = async (data: Omit<NurseStationVisit, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(nurseStationVisitActions.post(data));

  const put = async (data: Partial<NurseStationVisit>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(nurseStationVisitActions.put(data));

  const remove = async (id: NurseStationVisit['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(nurseStationVisitActions.remove(id));

  return {
    resetNurseStationVisitState,
    nurseStationVisitsState,
    setNurseStationVisitState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useNurseStationVisits;
