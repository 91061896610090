import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { VariableSizeList } from 'react-window';
import DateRangePicker from 'react-datepicker';
import { Spinner } from '@material-tailwind/react';
import OvertimesListRow from './OvertimesListRow';
import EmptyListMessage from '../../../atoms/EmptyListMessage';
import useDateTime from '../../../../hooks/utils/useDateTime';
import consoleLog from '../../../../utils/consoleLog';
import useAttendance from '../../../../hooks/attendances/useAttendance';
import SelectStatusOptions, { StatusOption } from '../../../atoms/dropdowns/SelectStatusOptions';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import useVariableItemSize from '../../../../hooks/utils/useVariableItemSize';
import SortableTableHeader, { HeaderOption } from '../../../molecules/SortableTableHeader';

type SortByHeaderOptions = '' | keyof EmployeeOvertime | 'name' | 'status' | 'date';
type MyEmployeeOvertimeCustomType = EmployeeOvertime & {
  name?: number;
  status?: string;
  date?: Date;
};

const OvertimesList: React.FC = () => {
  const { getDate } = useDateTime();
  const { getItemSize } = useVariableItemSize();
  const { getAllMyOvertimes, attendanceState, myOvertimeRemove, setAttendanceState } =
    useAttendance();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);
  const [statusInteracted, setStatusInteracted] = useState(false);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const [selectedStatus, setSelectedStatus] = useState<StatusOption>({
    value: 'Pending',
    label: 'Pending',
  });

  const [isLoading, setIsLoading] = useState(false);

  const getFirstDayOfCurrentMonth = () => moment().startOf('month').toDate();
  const getLastDayOfCurrentMonth = () => moment().endOf('month').toDate();
  const defaultStartDate = getFirstDayOfCurrentMonth();
  const defaultEndDate = getLastDayOfCurrentMonth();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [dateStart, dateEnd] = dateRange;

  const myOvertimes = isLoading
    ? []
    : attendanceState.myOvertimes
        .filter((overtime: EmployeeOvertime) => !!overtime.overtimeTimeOut)
        .sort((a: EmployeeOvertime, b: EmployeeOvertime) => {
          const dateA = a.dateCreated ? new Date(a.dateCreated).getTime() : 0;
          const dateB = b.dateCreated ? new Date(b.dateCreated).getTime() : 0;
          return dateB - dateA;
        });

  const handleDelete = async (overtime: EmployeeOvertime) => {
    myOvertimeRemove(overtime.id).then((data) => {
      consoleLog('DELETED EMPLOYEE_OVERTIME', data);
    });
  };

  const headers: HeaderOption<MyEmployeeOvertimeCustomType>[] = [
    { value: 'date', label: 'Date', defaultOrder: true },
    { value: 'overtimeTimeIn', label: 'TimeIn' },
    { value: 'overtimeTimeOut', label: 'Time out' },
    { value: 'duration', label: 'Duration' },
    { value: 'overtimeType', label: 'Type' },
    { value: 'status', label: 'Status', center: true },
  ];

  useEffect(() => {
    setAttendanceState({ myOvertimes: [] });
    const formattedStartDate = dateStart ? getDate(dateStart) : undefined;
    const formattedEndDate = dateEnd ? getDate(dateEnd) : undefined;

    if (dateEnd) {
      setIsLoading(true);
      getAllMyOvertimes({
        status: selectedStatus?.value,
        dateStart: formattedStartDate,
        dateEnd: formattedEndDate,
      }).finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [selectedStatus, dateStart, dateEnd]);

  return (
    <div className="organisms-leave-list px-5 w-full">
      <div className="flex justify-center pb-5">
        <div className="gap-5 items-center w-full">
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
              {/* First Container */}
              <div className="my-overtime-content-wrapper">
                {/* Date field */}
                <div className="w-full overtime-date-wrapper datePicker-main-parent">
                  <h2 className="w-fit pl-1 mb-1">Date</h2>
                  <div
                    className={`w-full h-fit date-wrapper ${
                      statusInteracted && !dateEnd ? 'overtime-red' : ''
                    }`}
                  >
                    <DateRangePicker
                      className="rounded-lg datepicker-list-layout datepicker-list-date"
                      startDate={dateStart}
                      endDate={dateEnd}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      selectsRange
                      isClearable
                      placeholderText="Select Date Range..."
                    />
                  </div>
                </div>
                {/* Status options */}
                <div className="w-full overtime-status-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Status</h2>
                  <div className="w-full status-search-wrapper">
                    <SelectStatusOptions
                      id="status"
                      name="status"
                      className="overtime-status-text"
                      value={selectedStatus as StatusOption}
                      onChange={(value) => {
                        setSelectedStatus(value as unknown as StatusOption);
                        setStatusInteracted(true);
                      }}
                      isClearable
                      hasSearchIcon
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>

      <SortableTableHeader
        classNames="overtimes-list-header"
        sortOption={sortOption}
        sortOrderBy={sortOrderBy}
        headers={headers}
        sortByHeader={sortByHeader}
      />

      {isLoading && (
        <div className="w-full p-5 flex justify-center">
          <Spinner color="deep-orange" className="mr-2" />
        </div>
      )}

      {!isLoading && myOvertimes.length === 0 ? (
        <EmptyListMessage />
      ) : (
        <VariableSizeList
          itemCount={myOvertimes.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <OvertimesListRow
              data={myOvertimes[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              onDeleteClick={handleDelete}
            />
          )}
        </VariableSizeList>
      )}
    </div>
  );
};

export default OvertimesList;
