import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import EmploymentPositionRate from '../../../../store/constants/employments/positions/employment-position-rate.interface';
import DeleteConfirmationBanner from '../../../molecules/DeleteConfirmationBanner';
import EditSvg from '../../../../assets/images/icons/edit.svg';
import useSettings from '../../../../hooks/utils/useSettings';

export interface PositionRatesListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: EmploymentPositionRate;
  onEditClick: (employment: EmploymentPositionRate) => void;
  onDeleteClick: (employment: EmploymentPositionRate) => Promise<unknown>;
}

const PositionRateListRow: React.FC<PositionRatesListRowProps> = ({
  className = '',
  data,
  onDeleteClick,
  onEditClick,
  ...props
}: PositionRatesListRowProps) => {
  const { getCurrencyFormat } = useSettings();

  const deleteMessage = {
    pending: 'Pending',
    success: 'Position Rate information is successfully deleted.',
    error: 'Error on deleting Position Rate information.',
  };

  return (
    <div
      {...props}
      className={`${className} position-rates-list-data table-list-data flex items-center`}
    >
      <div>{data.employmentPosition?.name}</div>
      <div>{getCurrencyFormat(data.gross)}</div>
      <div>{getCurrencyFormat(data.minimumRate)}</div>
      <div>{getCurrencyFormat(data.maximumRate)}</div>

      <div className="space-x-5 action-buttons justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <img
              className="edit-data-icon"
              src={EditSvg}
              alt={`Edit ${data.employmentPosition?.name}`}
            />
          </button>
        </Tooltip>
        <DeleteConfirmationBanner
          data={data}
          label={data.employmentPosition?.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default PositionRateListRow;
