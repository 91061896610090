import React from 'react';
import useDateTime from '../../../../hooks/utils/useDateTime';
import { ReactComponent as Calendar } from '../../../../assets/images/icons/calendar.svg';
import useTransactionFeeds from '../../../../hooks/my/useTransactionFeeds';

const UserTransactionHistory = () => {
  const { getDate } = useDateTime();
  const { transactionFeedsState } = useTransactionFeeds();

  const readedTransactions = transactionFeedsState.transactionFeeds.filter(
    (transaction) => transaction.isRead,
  );

  return (
    <div className="w-full flex justify-center user-tab-container">
      <div className="flex flex-wrap w-full justify-center gap-6 transaction-history-wrapper">
        {readedTransactions.map((transaction) => (
          <div className="shadow-md transaction-wrapper" key={transaction.id}>
            {/* img & title */}
            <div className="flex gap-2 mb-2">
              <span className="w-6 h-6 rounded-full user-image" />
              <h2>{transaction.name}</h2>
            </div>
            {/* description */}
            <p className="transaction-description">{transaction.description}</p>
            {/* bottom description */}
            <div className="w-full flex justify-between mt-2 px-2 transaction-date-wrapper">
              <div className="flex items-center gap-1">
                <span className="w-2 h-2 rounded-full user-image" />
                <span className="read-date">Read on {getDate(transaction.dateRead)}</span>
              </div>
              <div className="flex items-center gap-2">
                <Calendar className="w-6 h-6 icons" />
                <span className="shared-date">Shared on {getDate(transaction.dateShared)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserTransactionHistory;
