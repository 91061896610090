import React, { useEffect, useState } from 'react';
import { Dialog, DialogHeader, DialogBody, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Department from '../../../store/constants/departments/department.interface';
import useDepartments from '../../../hooks/departments/useDepartments';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import toastNotification from '../../../hooks/utils/toastNotification';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';

export interface DepartmentFormModalParams {
  department?: Department;
  className?: string;
  resetSort?: () => void;
  onClose: () => void;
}

const DepartmentFormModal: React.FC<DepartmentFormModalParams> = ({
  department = undefined,
  className = undefined,
  resetSort = () => {},
  onClose,
}) => {
  const [departmentForm, setDepartmentForm] = useState<Department | undefined>();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const { post, put } = useDepartments();

  const handleClose = () => {
    setDepartmentForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setDepartmentForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<Department>) => {
    setLoading(true);
    if (departmentForm) {
      const data: Department = {
        ...departmentForm,
        ...values,
      };

      await toastNotification({
        action: put(data),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: async () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Department information is successfully updated.',
          error: {
            render({ data: errorMsg }) {
              return errorMsg as string;
            },
          },
        },
      });
    } else {
      await toastNotification({
        action: post(values as Department),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: async () => {
          setLoading(false);
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Department information is successfully added.',
          error: 'Error on adding Department information.',
        },
      });
    }
  };

  useEffect(() => {
    if (department) {
      setOpen(true);
      setDepartmentForm(department);
    }
  }, [department]);

  return (
    <>
      <button
        type="button"
        className={` ${className} button button--state-default gap-2`}
        onClick={handleOpen}
      >
        <Add className="w-5 h-5" />
        Add department
      </button>
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="m-2 p-0 absolute flex top-0 right-0">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <Close className="w-5 h-auto" />
          </IconButton>
        </div>
        <div className="flex gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="white"
            className="modal-icons"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
            />
          </svg>

          <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
            Enter Department Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{ ...departmentForm, name: departmentForm?.name ?? '' }}
                onSubmit={handleSubmit}
              >
                <Form className="">
                  {/* FIRST SECTION */}
                  <div className="mt-4 mb-4 form-section">
                    <div className="flex flex-col justify-center w-full">
                      <div className="mb-4">
                        <label htmlFor="name" className="modal-labels block mb-2 text-gray-200">
                          Department Name
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Enter Department"
                            className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                          />
                          <ErrorMessage name="name" component="div" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Buttons */}
                  <div className="flex space-x-2 w-max">
                    <div className="flex justify-center w-max">
                      <ButtonAction
                        type="submit"
                        className="modal-buttons flex justify-center item-center p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                        isLoading={loading}
                        hasSpinnerText={false}
                        isSubmit
                      />
                    </div>
                    <div className="flex justify-center w-max">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default DepartmentFormModal;
