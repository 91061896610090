/**
 * This function converts object into FormData
 *
 * @param data
 */
const objectToFormData = <T extends Record<string, never>>(data: T) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    // If value is an object but not a file
    if (
      typeof data[key as string] === 'object' &&
      !((data[key as string] as unknown) instanceof File)
    ) {
      formData.append(key, JSON.stringify(data[key as string]));
    }
    // Else, value should be as is
    else {
      formData.append(key, data[key as string]);
    }
  });

  return formData;
};

export default objectToFormData;
