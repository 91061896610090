interface DateOption {
  value: string;
  label: string;
}

export const formatDateShort = (dateStr: string) =>
  new Date(dateStr).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

export const generateDatePeriodOptions = (): DateOption[] => {
  const options: DateOption[] = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  let startDate = new Date(currentYear, 0, 1);
  const endDate = new Date(currentYear, 11, 31);

  while (startDate <= endDate) {
    const startDateString = startDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    let endDatePointer: Date;

    if (startDate.getDate() <= 15) {
      // If current date is < 15, set end date to 15th of the curr. month
      endDatePointer = new Date(startDate.getFullYear(), startDate.getMonth(), 15);
    } else {
      // Otherwise, endDate is the last day of the curr. month
      endDatePointer = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    }

    const endDateString = endDatePointer.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const value = `${formatDate(startDate)},${formatDate(endDatePointer)}`;
    const label = `${startDateString} - ${endDateString}`;

    options.push({ value, label });
    startDate = new Date(startDate);
    startDate.setDate(endDatePointer.getDate() + 1);
  }

  return options;
};

export const getCurrentDatePeriod = (): { value: string; label: string } => {
  const currentDate = new Date();
  const formattedDateValue = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1,
  ).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
  const currentTimestamp = new Date(formattedDateValue).getTime();
  const options = generateDatePeriodOptions();

  let defaultIndex = 0;

  options.forEach((option, index) => {
    const [start, end] = option.value.split(',');
    const startDateTimestamp = new Date(start).getTime();
    const endDateTimestamp = new Date(end).getTime();

    if (currentTimestamp >= startDateTimestamp && currentTimestamp <= endDateTimestamp) {
      defaultIndex = index;
    }
  });

  const [startDateValue, endDateValue] = options[defaultIndex].value.split(',');
  const label = `${formatDateShort(startDateValue)} - ${formatDateShort(endDateValue)}`;

  return {
    label,
    value: options[defaultIndex].value,
  };
};
