import React from 'react';
import DepartmentList from './DepartmentList';
import ManagementHeading from '../../atoms/ManagementHeading';

const DepartmentManagement: React.FC = () => (
  <div className="flex w-full organisms-department-management">
    <div className="flex flex-col w-full">
      <div className="m-2 title">
        <ManagementHeading title="Department" />
      </div>
      <div className="">
        <DepartmentList />
      </div>
    </div>
  </div>
);

export default DepartmentManagement;
