import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import moment from 'moment';
import useDateTime from '../../../hooks/utils/useDateTime';
import EmployeeSchedule from '../../../store/constants/employees/schedules/employee-schedule.interface';
import useScheduleTypes from '../../../hooks/schedule/useScheduleTypes';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';
import { ReactComponent as Edit } from '../../../assets/images/icons/edit.svg';

export interface ScheduleManagersListRowProps {
  className?: string;
  style: React.CSSProperties;
  data?: EmployeeSchedule | undefined;
  onEditClick: (schedule: EmployeeSchedule) => void;
  onDeleteClick: (schedule: EmployeeSchedule) => Promise<unknown>;
}

const ScheduleManagementListRow: React.FC<ScheduleManagersListRowProps> = ({
  className = '',
  data = undefined,
  onEditClick,
  onDeleteClick,
  ...props
}: ScheduleManagersListRowProps) => {
  const { getDate } = useDateTime();
  const { getScheduleTypeById } = useScheduleTypes();

  const dateEndFormatted = getDate(new Date(data?.dateEnd as Date));

  if (!data) return null;

  const scheduleType = getScheduleTypeById(data.scheduleTypeId)?.name;

  const editData = {
    ...data,
    dateStart: moment(data.dateStart).format('YYYY-MM-DD'),
  };

  const timeIn = moment(data.timeIn, 'h:mm a').toDate();
  const timeOut = moment(data.timeOut, 'h:mm a').toDate();

  const mapDaysToString = (days: string): string => {
    const daysArray = ['M', 'T', 'W', 'Th', 'F', 'Sat', 'Sun'];
    const dayIndexes = days.split(',').map(Number);

    if (
      dayIndexes.length === 5 &&
      dayIndexes.every((index) => index >= 1 && index <= daysArray.length)
    ) {
      return 'Mon - Fri';
    }

    if (dayIndexes.every((index) => index >= 1 && index <= daysArray.length)) {
      const selectedDays = dayIndexes.map((index) => daysArray[index - 1]);
      return selectedDays.join(', ');
    }

    return days;
  };

  const daysData = mapDaysToString(data.days || '');

  const deleteMessage = {
    pending: 'Pending',
    success: 'Successfully deleted.',
    error: 'Error on deleting this schedule.',
  };

  return (
    <div {...props} className={`${className} grid grid-cols-11 list-data  items-center p-2`}>
      <span className="text-gray-200 text-xs">{data.id}</span>
      <span className="text-gray-200 text-xs">{scheduleType}</span>
      <span className="text-gray-200 text-xs">{daysData}</span>
      <span className="text-gray-200 text-xs">{moment(timeIn).format('h:mm a')}</span>
      <span className="text-gray-200 text-xs">{moment(timeOut).format('h:mm a')}</span>
      <span className="text-gray-200 text-xs">{data.break}</span>
      <span className="text-gray-200 text-xs">{data.temp}</span>
      <span className="text-gray-200 text-xs">{moment(data.dateStart).format('YYYY-MM-DD')}</span>
      <span className="text-gray-200 text-xs">{data.dateEnd && dateEndFormatted}</span>
      <span className="text-gray-200 text-xs">{data.dateUpdated && getDate(data.dateUpdated)}</span>

      <div className="space-x-2 action-buttons justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button type="button" onClick={() => onEditClick(editData)}>
            <Edit className="w-5 h-auto cursor-pointer textButton" />
          </button>
        </Tooltip>
        <DeleteConfirmationBanner
          data={data}
          label="this schedule"
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default ScheduleManagementListRow;
