import { useState } from 'react';
import moment from 'moment';

const useCalculateTimeDuration = () => {
  const [duration, setDuration] = useState<string>('');

  const calculateDuration = (timeIn: string | Date, timeOut: string | Date) => {
    const format = 'HH:mm';
    const timeInMoment = moment(timeIn, format);
    const timeOutMoment = moment(timeOut, format);

    if (timeInMoment.isValid() && timeOutMoment.isValid()) {
      let durationInMinutes;
      if (timeOutMoment.isAfter(timeInMoment)) {
        durationInMinutes = timeOutMoment.diff(timeInMoment, 'minutes');
      } else if (timeOutMoment.isSame(timeInMoment)) {
        durationInMinutes = 0;
      } else {
        timeOutMoment.add(1, 'day');
        durationInMinutes = timeOutMoment.diff(timeInMoment, 'minutes');
      }

      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;

      const durationString = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}`;
      setDuration(durationString);
    } else {
      setDuration('');
    }
  };

  return { duration, setDuration, calculateDuration };
};

export default useCalculateTimeDuration;
