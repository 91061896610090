import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from '@material-tailwind/react';
import PayrollCreateViewMenu from './navbarProperties/PayrollCreateViewMenu';
import PayrollThirteenthMonthMenu from './navbarProperties/PayrollThirteenthMonthMenu';
import PayrollLastpayMenu from './navbarProperties/PayrollLastpayMenu';
import PayrollOthers from './navbarProperties/PayrollOthers';
import { ReactComponent as ArrowRight } from '../../../../assets/images/icons/arrow-right-single.svg';

const PayrollNavigation: React.FC = () => (
  <div className="w-full payroll-navbar-parent">
    <Navbar className="payroll-navbar">
      <div className="flex items-center justify-center gap-8 payroll-menu">
        <PayrollCreateViewMenu />
        <PayrollThirteenthMonthMenu />
        <PayrollLastpayMenu />
        <PayrollOthers />
      </div>
      <Link to="/user/newsfeed" className="ml-auto">
        <button type="button" className="p-1 px-2 animatedButton">
          <span className="flex items-center gap-1 justify-center place-self-center payroll-navbar-logo__text-only">
            Awesome CX
            <ArrowRight className="w-4 h-4 animatedArrow" />
          </span>
        </button>
      </Link>
    </Navbar>
  </div>
);

export default PayrollNavigation;
