import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Hmo from '../../constants/hmo/hmo.interface';

export type GetAllHmosQueryParams = ApiGetAllBaseQueryParams;

export interface HmoReportsParams {
  startDate?: number;
  endDate?: number;
}

export interface ApiResponse<T> {
  status: number;
  data: T;
  // Add other response properties if needed
}

const get = async (id: Hmo['id'], state: StateHeaders): Promise<ApiBaseResponse<Hmo>> =>
  apiFetch<ApiBaseResponse<Hmo>>(`hmo/${id}`, { state })
    .then((response: ApiBaseResponse<Hmo>) => response)
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllHmosQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Hmo>> =>
  apiFetch<ApiGetAllBaseResponse<Hmo>>(
    `hmo?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Hmo>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<Hmo, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Hmo> | void> =>
  apiFetch<ApiBaseResponse<Hmo>>('hmo', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Hmo>) => response)
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (data: Hmo, state: StateHeaders): Promise<ApiBaseResponse<Hmo> | void> =>
  apiFetch<ApiBaseResponse<Hmo>>(`hmo/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Hmo>) => response)
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<Hmo, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Hmo> | void> =>
  apiFetch<ApiBaseResponse<Hmo>>(`hmo/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Hmo>) => response)
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (id: Hmo['id'], state: StateHeaders): Promise<ApiBaseResponse<Hmo> | void> =>
  apiFetch<ApiBaseResponse<Hmo>>(`hmo/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Hmo>) => response)
    .catch((e) => {
      throw new Error(e);
    });

const reports = async (
  { search }: { search: string | number },
  state: StateHeaders,
): Promise<Blob> => {
  const {
    auth: { token },
  } = state;

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/hmo/reports?search=${search}`,
    {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    },
  );
  const blob = await response.blob();
  return blob;
};

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  reports,
} as const;
