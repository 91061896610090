import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmployeeAttendance from '../../constants/employees/attendances/employee-attendance.interface';
import attendanceServices, {
  GetAllAttendanceQueryParams,
  ExportAttendanceQueryParams,
} from '../../services/attendances/attendanceServices';
import EmployeeOvertime from '../../constants/employees/attendances/employee-overtime.interface';
import EmployeeTimeOutDetail from '../../constants/employees/attendances/employee-Timeout-detail.interface';

const get = createAsyncThunk(
  'my/attendance/get',
  async (
    id: EmployeeAttendance['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'my/attendance/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllAttendanceQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.getAll(
        {
          page,
          limit,
          search,
        },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAllMyAttendance = createAsyncThunk(
  'my/attendance/getAllMyAttendance',
  async (
    { page = 1, limit = 50, search = '' }: GetAllAttendanceQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.getAllMyAttendance(
        {
          page,
          limit,
          search,
        },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'my/attendance/post',
  async (
    data: Omit<EmployeeAttendance, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'my/attendance/remove',
  async (
    id: EmployeeAttendance['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const timeIn = createAsyncThunk(
  'my/attendance/timeIn',
  async (
    _,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.timeIn(state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const timeBreak = createAsyncThunk(
  'my/attendance/timeBreak',
  async (
    id: EmployeeAttendance['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.timeBreak(state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const timeOut = createAsyncThunk(
  'my/attendance/timeOut',
  async (
    data: AtLeast<EmployeeTimeOutDetail, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeTimeOutDetail> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.timeOut(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const underTime = createAsyncThunk(
  'my/attendance/underTime',
  async (
    _,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.underTime(state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const myOvertimeRemove = createAsyncThunk(
  'my/overtimes/remove',
  async (
    id: EmployeeOvertime['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.myOvertimeRemove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const overtimeTimein = createAsyncThunk(
  'my/overtimes/time-in',
  async (
    data: Omit<EmployeeOvertime, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.overtimeTimeIn(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const overtimeTimeOut = createAsyncThunk(
  'my/overtimes/time-out',
  async (
    data: AtLeast<EmployeeOvertime, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.overtimeTimeOut(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAllMyOvertimes = createAsyncThunk(
  'my/getAllMyOvertimes',
  async (
    {
      page = 1,
      limit = 50,
      search = '',
      status = undefined,
      dateStart = undefined,
      dateEnd = undefined,
    }: GetAllAttendanceQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.getAllMyOvertimes(
        { page, limit, search, status, dateStart, dateEnd },
        state,
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const getMyCurrentOvertime = createAsyncThunk(
  'my/current/overtime',
  async (
    _,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.getMyCurrentOvertime(state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const exportAttendance = createAsyncThunk(
  'export/attendance',
  async ({ teamId }: ExportAttendanceQueryParams, { getState }): Promise<Blob | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await attendanceServices.exportAttendance({ teamId }, state);
      // eslint-disable-next-line
    } catch (err: any) {
      // The validation error that is stored in the authReducer state error
      throw new Error(err.message);
    }
  },
);

export default {
  get,
  getAll,
  post,
  remove,
  timeIn,
  timeOut,
  timeBreak,
  underTime,
  overtimeTimein,
  overtimeTimeOut,
  myOvertimeRemove,
  getAllMyAttendance,
  getAllMyOvertimes,
  exportAttendance,
  getMyCurrentOvertime,
} as const;
