import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useUsers from '../../../hooks/users/useUsers';
import { UserGenderOption } from '../../../store/reducers/users/usersReducer';

export interface SelectGenderProps extends Partial<AutoSearchSelectProps<UserGenderOption>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectGender: React.FC<SelectGenderProps> = ({
  id,
  name,
  placeholder = 'Select Gender',
  className = '',
  ...props
}) => {
  const {
    usersState: { userGenders },
  } = useUsers();

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-gender modal-select ${className}`}
      placeholder={placeholder}
      data={userGenders}
    />
  );
};

export default SelectGender;
