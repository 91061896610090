import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentStatus from '../../constants/employments/employment-status.interface';
import employmentStatusServices, {
  GetAllEmploymentStatusQueryParams,
} from '../../services/employments/employmentStatusServices';
import EmployeeAttendance from '../../constants/employees/attendances/employee-attendance.interface';
import UserActivity from '../../constants/utils/userActivity.interface';

const get = createAsyncThunk(
  'employments/status/get',
  async (
    id: EmploymentStatus['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentStatus> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentStatusServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'employments/status/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllEmploymentStatusQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmploymentStatus> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentStatusServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const postUser = createAsyncThunk(
  'users/activity',
  async (
    _,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeAttendance> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentStatusServices.postUser(state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const putUser = createAsyncThunk(
  'users/activity',
  async (
    id: UserActivity['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserActivity> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentStatusServices.putUser(id, state);
    } catch (err) {
      // Handle and return the error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, postUser, putUser } as const;
