import React from 'react';
import { Typography } from '@material-tailwind/react';
// import EmployeeDependentTable from './EmployeeDependentTable';

const EmployeeDependentManagementPage = () => (
  <div className="w-full flex">
    <div className="flex flex-col w-full">
      <div className="m-2">
        <div className="title">
          <Typography variant="h3" className="text-[#f05825] flex gap-2 management-heading">
            <Typography variant="h3" className="text-gray-200">
              Employee
            </Typography>
            Dependent
          </Typography>
        </div>
      </div>

      <div className="w-[95%] border border-solid border-[#343434] rounded-lg shadow-lg">
        <div className="p-3 w-full bg-[#343434] rounded-t-lg">
          <span className="text-gray-200 font-bold">Employee Dependent Table</span>
        </div>
        <div className="p-5">
          <div className="flex gap-2 mb-5">
            <div className="ml-auto">
              <button
                type="button"
                className="p-2 py-1 bg-[#f05825] text-white text-sm rounded-md shadow-md"
              >
                New Dependent Entry
              </button>
            </div>
          </div>
          <hr className="rounded-md  mr-5 border-1 opacity-10" />
          <div className="flex mt-5">
            <div className="flex flex-col gap-5">
              <div className="flex gap-2 items-center">
                <label htmlFor="entries" className="text-gray-200 text-xs">
                  Show
                </label>
                <input
                  type="text"
                  className="bg-[#343434] w-14 rounded-md border border-solid border-[#434343] px-2"
                />
                <label htmlFor="entries" className="text-gray-200 text-xs">
                  Entries
                </label>
              </div>
            </div>
            <div className="ml-auto flex gap-5 items-center">
              <label htmlFor="entries" className="text-gray-200 text-xs">
                Search
              </label>
              <input
                type="text"
                className="bg-[#343434] w-full rounded-md border border-solid border-[#434343] px-2"
              />
            </div>
          </div>
          <div className="border border-solid border-[#343434] mt-5 shadow-lg p-5">
            {/* <EmployeeDependentTable /> */}
            <span className="text-lg text-white">* TABLE HERE *</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EmployeeDependentManagementPage;
