import React from 'react';
// import Payslip from '../../../../store/constants/payroll/payslip.interface';

export interface PayslipSummaryProps {
  // @todo: Replace any with Payslip interface when interface details is finalized
  // eslint-disable-next-line
  payslip: any;
}

const PayslipSummary: React.FC<PayslipSummaryProps> = ({ payslip }) => (
  <div className=" px-6 gap-3 grid grid-cols-1 2xl:grid-cols-2 payslip-first-content-wrapper">
    {/* Payslip summary */}
    <div className="shadow-md payslip-content-container">
      <h1 className="payslip-title">Payslip Summary</h1>
      <div className="flex flex-col w-full payslip-content-field-wrapper">
        <div className="payslip-first-content-field payslip-content-body">
          <span>Name</span>
          <span>
            {payslip.lastName} {payslip.firstName}
          </span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Employment Number</span>
          <span>{payslip.employeeNumber}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Days Worked</span>
          <span>{payslip.workedDays}</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Days on Leave</span>
          <span>0</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Daily Gross Rate</span>
          <span>{payslip.daysPerMonth}</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Daily Basic Rate</span>
          <span>{payslip.dailyRate}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Gross Income</span>
          <span>21,555.17</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span className="payslip-title-total">Net Income:</span>
          <span className="payslip-total">20,807.65</span>
        </div>
      </div>
    </div>
    {/* Taxable income */}
    <div className="payslip-content-container">
      <h1 className="payslip-title">Taxable Income</h1>
      <div className="flex flex-col w-full payslip-content-field-wrapper">
        <div className="payslip-first-content-field payslip-content-body">
          <span>Basic Pay</span>
          <span>{payslip.monthlyBasic}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Holiday Overtime Pay</span>
          <span>3,424</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Holiday Pay</span>
          <span>32,432</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Night Diff Regular Holiday Overtime</span>
          <span>45.12</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Night Diff Regular Overtime</span>
          <span>34.21</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Productivity</span>
          <span>3,213.43</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Regular Overtime Pay</span>
          <span>3,244.77</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span className="payslip-title-total">Sub Total:</span>
          <span className="payslip-total">21,435.17</span>
        </div>
      </div>
    </div>
    {/* Non-Taxable Pay and Allowances */}
    <div className="shadow-md payslip-content-container">
      <h1 className="payslip-title">Non-Taxable Pay and Allowances</h1>
      <div className="flex flex-col w-full payslip-content-field-wrapper">
        <div className="payslip-first-content-field payslip-content-body">
          <span>Clothing Allowance</span>
          <span>{payslip.clothingAllowance}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Gifts</span>
          <span>{payslip.giftAllowance}</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Laundry Allowance</span>
          <span>{payslip.laundryAllowance}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Meal Allowance</span>
          <span>{payslip.mealAllowance}</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Medical</span>
          <span>500</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Other Benefits</span>
          <span>414.43</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>Rice Allowance</span>
          <span>{payslip.riceAllowance}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span className="payslip-title-total">Sub Total:</span>
          <span className="payslip-total">23,123.17</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span className="payslip-title-total">Gross Pay:</span>
          <span className="payslip-total">23,807.65</span>
        </div>
      </div>
    </div>
    {/* Deductions */}
    <div className=" shadow-md payslip-content-container">
      <h1 className="payslip-title">Deductions</h1>
      <div className="flex flex-col w-full payslip-content-field-wrapper">
        <div className="payslip-first-content-field payslip-content-body">
          <span>PAGIBIG</span>
          <span>{payslip.pagibig}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>PHIC</span>
          <span>{payslip.phic}</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span>SSS</span>
          <span>{payslip.sss}</span>
        </div>
        <div className="payslip-second-content-field payslip-content-body">
          <span>Tax</span>
          <span>{payslip.tax}</span>
        </div>
        <div className="payslip-first-content-field payslip-content-body">
          <span className="payslip-title-total">Total Deductions:</span>
          <span className="payslip-total-deductions">1,088.5</span>
        </div>
      </div>
    </div>
  </div>
);

export default PayslipSummary;
