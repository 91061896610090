import React from 'react';
import moment from 'moment';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';
import ClientManager from '../../../store/constants/clients/client-manager.interface';
import consoleLog from '../../../utils/consoleLog';

export interface ClientManagerListRowProps {
  className?: string;
  style: React.CSSProperties;
  data?: ClientManager;
  getDateTime: (date: Date) => string;
  onEditClick: (manager: ClientManager) => void;
  onDeleteClick: (manager: ClientManager) => Promise<unknown>;
}

const ClientManagerListRow: React.FC<ClientManagerListRowProps> = ({
  className = '',
  data = undefined,
  getDateTime,
  onEditClick,
  onDeleteClick,
  ...props
}: ClientManagerListRowProps) => {
  if (!data) return null;

  const deleteMessage = {
    pending: 'Pending',
    success: 'Success',
    error: 'Error',
  };

  return (
    <div
      {...props}
      role="button"
      className={`${className} client-manager-list-data table-list-data flex items-center `}
    >
      <div>{data.department?.name}</div>
      <div>{data.employmentRole?.name}</div>
      <div>{data.userInformation?.fullName}</div>
      <div>{moment(data.dateStart as unknown as Date).format('YYYY-MM-DD')}</div>
      <div>
        <button
          type="button"
          className={`text-left w-fit ${data.dateEnd ? 'cursor-default' : ''}`}
          onClick={() => {
            if (data.dateEnd) return;

            /**
             * @todo
             * component for setting date end
             */

            consoleLog('ClientManagerListRow', 'onSetDateEndClick', data);
          }}
        >
          {data.dateEnd ? moment(data.dateEnd as unknown as Date).format('YYYY-MM-DD') : '✖️'}
        </button>
      </div>
      <div className="space-x-6 action-buttons justify-self-center">
        <DeleteConfirmationBanner
          data={data}
          label={data.userInformation?.fullName}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default ClientManagerListRow;
