import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-tailwind/react';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import toastNotification from '../../../../../hooks/utils/toastNotification';
import UserOvertimeModal from '../userOvertime/UserOvertimeModal';
import useTheme from '../../../../../hooks/utils/useTheme';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';
import Spinner from '../../../../atoms/Spinner';

const UserTimeIn = () => {
  const { timeIn } = useAttendance();
  const { theme } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [showOvertime, setShowOvertime] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogOut = () => {
    setShowOvertime(false);
    setOpen(!open);
  };

  const closeDialog = () => {
    setOpen(false);
    setShowOvertime(false);
  };

  const handleTimeIn = async () => {
    setIsLoading(true);

    await toastNotification({
      action: timeIn(),
      onSuccess: () => {},
      onError: () => {},
      onFulfilled: () => {
        setIsLoading(false);
        setShowOvertime(true);
        setOpen(true);
      },
      toastPromiseMessages: {
        pending: 'Processing your Time in...',
        success: 'Successfully Time in...',
        error: 'You are not ready to time in at this moment, please check your schedule...',
      },
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={handleTimeIn}
        className={`user-attendance-action__button ${isLoading ? 'isLoading' : ''}`}
        disabled={isLoading}
      >
        <span className="user-attendance-action-button__heading-text">
          {isLoading ? <Spinner /> : 'TIME IN'}
        </span>
      </button>
      <Dialog
        open={open}
        size="xs"
        handler={handleLogOut}
        className={`user-attendance-action__prompt-container ${theme}`}
      >
        <div className="close-prompt-button">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={closeDialog}>
            <Close className="h-5 w-5" />
          </IconButton>
        </div>
        {showOvertime && <UserOvertimeModal handleOnClose={closeDialog} />}
      </Dialog>
    </>
  );
};

export default UserTimeIn;
