import React, { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import consoleLog from '../../../../utils/consoleLog';
import useUsers from '../../../../hooks/users/useUsers';
import User from '../../../../store/constants/users/user.interface';
import SelectTimezone from '../../../atoms/dropdowns/SelectTimezone';
import Timezone from '../../../../store/constants/timezones/timezone.interface';
import SelectGender from '../../../atoms/dropdowns/SelectGender';
import UserGender from '../../../../store/constants/users/user-gender.enum';
import { UserGenderOption } from '../../../../store/reducers/users/usersReducer';
import toastNotification from '../../../../hooks/utils/toastNotification';
import Spinner from '../../../atoms/Spinner';
import { ReactComponent as PencilIcon } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/icons/check-solid.svg';
import useEmploymentStatus from '../../../../hooks/employment/useEmploymenStatus';
import useUserActivity from '../../../../hooks/utils/useUserActivity';
import { UserActivityState } from '../../../../store/reducers/utils/userActivitiesReducer';

interface UserBasicInfoFormProps {
  isLoading?: boolean;
  user: User;
}

const UserBasicInfoForm: React.FC<UserBasicInfoFormProps> = ({ isLoading = true, user }) => {
  const { put } = useUsers();
  const { postUser, putUser } = useEmploymentStatus();
  const { setUserActivity, userActivityState, resetUserActivity } = useUserActivity();

  const [showButtonAction, setShowButtonAction] = useState(false);
  const [showInputAction, setShowInputAction] = useState(false);

  const [selectedGender, setSelectedGender] = useState<UserGenderOption | undefined>({
    value: user.gender,
    label: user.gender,
  });

  const [selectedTimezone, setSelectedTimezone] = useState<Timezone | undefined>({
    ...user.timezone,
    value: user.timezoneCode,
    label: user.timezoneCode,
  } as Timezone);

  const handleSubmit = async (data: Partial<User>) => {
    if (user.id) {
      consoleLog('DATA SELECTED', data);

      const values = {
        ...data,
        gender: selectedGender?.value,
        timezoneCode: selectedTimezone?.code,
        timezone: selectedTimezone,
      };

      await toastNotification({
        action: put(values as User),
        onSuccess: () => {
          if (userActivityState.activity) putUser(userActivityState.activity.id);

          resetUserActivity();
          setShowButtonAction(!showButtonAction);
          setShowInputAction(!showInputAction);
        },
        onError: (response) => {
          consoleLog('ERROR', response);
        },
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: `Updating user basic information for ${user.lastName} ${user.lastName}...`,
          success: `${user.lastName} ${user.lastName} is successfully updated.`,
          error: `Failed to update user basic information for ${user.lastName} ${user.lastName}! Try again.`,
        },
      });
    }
  };

  const onEditClick = async () => {
    setShowButtonAction(!showButtonAction);
    setShowInputAction(!showInputAction);

    try {
      await toastNotification({
        action: postUser(),
        onSuccess: (response) => {
          setUserActivity(response.payload.data as UserActivityState);
        },
        onError: () => {},
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: 'Processing ',
          success: 'Successfully',
          error: 'Error',
        },
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };

  const onCancel = () => {
    setShowButtonAction(false);
    setShowInputAction(false);
  };

  const calculateAge = (birthDate: User['dateBirth']) => {
    const birthdate = new Date(birthDate);
    const currentDate = new Date();

    // Calculate the difference in years
    const age = currentDate.getFullYear() - birthdate.getFullYear();

    // Check if the birthday for this year has passed or not
    const birthMonth = birthdate.getMonth();
    const currentMonth = currentDate.getMonth();
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDate.getDate() < birthdate.getDate())
    ) {
      return age - 1; // Subtract 1 if the birthday has not occurred yet this year
    }

    return age;
  };

  // to do create an actual loader -> to avoid NAN values
  if (isLoading) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        id: user.id,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        suffixName: user.suffixName,
        nickName: user.nickName,
        maidenName: user.maidenName,
        emailPersonal: user.emailPersonal,
        gender: user.gender,
        dateBirth: user.dateBirth,
      }}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form className="rounded-xl shadow-xl user-update__form-wrapper">
          <div className="flex items-center mb-5">
            <div className="user-update__form-heading">
              <Typography variant="h4" className="user-update__form-title">
                Information
              </Typography>
              <span className="user-update__form-sub-title">Basic</span>
            </div>
            {!isLoading && (
              <div className="ml-auto">
                {!showButtonAction ? (
                  <Tooltip content="Edit">
                    <IconButton
                      onClick={onEditClick}
                      size="sm"
                      className="bg-primary-gray rounded-none"
                    >
                      <PencilIcon className="w-5 h-5 stroke-white" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <div className="flex flex-row-reverse gap-2">
                    <Tooltip content="Cancel">
                      <IconButton
                        onClick={onCancel}
                        size="sm"
                        className="bg-primary-gray rounded-none"
                      >
                        <CloseIcon className="w-5 h-5 stroke-red-900" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip content="Save">
                      <IconButton type="submit" size="sm" className="bg-primary-gray rounded-none">
                        <CheckIcon className="w-5 h-5 stroke-green-800" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center py-40">
              {' '}
              <Spinner height="4rem" />{' '}
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="firstName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  First Name
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                      required
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.firstName || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="firstName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="middleName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Middle Name
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <Field
                      type="text"
                      id="middleName"
                      name="middleName"
                      placeholder="Middle Name"
                      className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.middleName || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="middleName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="lastName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Last Name
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                      required
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.lastName || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="lastName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="suffixName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Suffix
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <Field
                      type="text"
                      id="suffixName"
                      name="suffixName"
                      placeholder="Suffix Name"
                      className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.suffixName || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="suffixName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="nickName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Nickname
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <Field
                      type="text"
                      id="nickName"
                      name="nickName"
                      placeholder="Nickname"
                      className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.nickName || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="nickName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="gender" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Gender
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <SelectGender
                      id="gender"
                      name="gender"
                      value={selectedGender}
                      onChange={(value) => setSelectedGender(value as UserGenderOption)}
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.gender || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="gender" component="div" />
                </div>
              </div>

              {selectedGender && selectedGender.value === UserGender.FEMALE && (
                <div className="flex flex-col gap-2 mb-2">
                  <label htmlFor="gender" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                    Maiden Name
                  </label>
                  <div className="w-full">
                    {showInputAction ? (
                      <Field
                        type="text"
                        id="maidenName"
                        name="maidenName"
                        placeholder="Maiden Name"
                        className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                      />
                    ) : (
                      <span className="capitalize ml-5 text-sm text-white">
                        {values.maidenName || '- -'}
                      </span>
                    )}
                    <ErrorMessage name="maidenName" component="div" />
                  </div>
                </div>
              )}

              {!showInputAction && (
                <div className="flex flex-col gap-2 mb-2">
                  <label htmlFor="dateBirth" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                    Age
                  </label>
                  <div className="w-full">
                    <span className="capitalize ml-5 text-sm text-white">
                      {calculateAge(values.dateBirth || '- -')}
                    </span>

                    <ErrorMessage name="dateBirth" component="div" />
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="dateBirth" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Birth Date
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <Field
                      type="date"
                      id="dateBirth"
                      name="dateBirth"
                      placeholder="Birth Date"
                      className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.dateBirth || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="dateBirth" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-2">
                <label htmlFor="gender" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Timezone
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <SelectTimezone
                      id="timezone"
                      name="timezone"
                      value={selectedTimezone}
                      onChange={(value) => setSelectedTimezone(value as Timezone)}
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.timezoneCode || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="maidenName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-2">
                <label
                  htmlFor="emailPersonal"
                  className="mt-2 mr-8 text-xs uppercase text-gray-600"
                >
                  Personal Email
                </label>
                <div className="w-full">
                  {showInputAction ? (
                    <Field
                      type="email"
                      id="emailPersonal"
                      name="emailPersonal"
                      placeholder="Personal Email"
                      className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    />
                  ) : (
                    <span className="capitalize ml-5 text-sm text-white">
                      {values.emailPersonal || '- -'}
                    </span>
                  )}
                  <ErrorMessage name="emailPersonal" component="div" />
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UserBasicInfoForm;
