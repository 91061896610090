import { Typography } from '@material-tailwind/react';
import React from 'react';

interface EmptyListMessageProps {
  message?: string;
}

const EmptyListMessage: React.FC<EmptyListMessageProps> = ({
  message = 'Looks like there\'s nothing here for now.',
}) => (
  <div className="flex justify-center items-center h-40">
    <Typography className="muiTypography--subtitle1">{message}</Typography>
  </div>
);

export default EmptyListMessage;
