import React from 'react';
import { Typography } from '@material-tailwind/react';
import UserCalendar from '../UserCalendar';

const Troubleshooting: React.FC = () => (
  <div className="w-full user-newsfeed-test flex">
    <div className="basis-[68%]">
      <div className="flex justify-between w-full pb-3">
        <Typography variant="h3" className="ml-5 my-2 header text-header">
          OSNet Troubleshooting
        </Typography>
      </div>
    </div>
    <div className="calendar-wrapper   mt-[1.5rem]">
      <UserCalendar />
    </div>
  </div>
);

export default Troubleshooting;
