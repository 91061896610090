import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import Employee from '../../store/constants/employees/employee.interface';

interface AccountQRCodeProps {
  userEmployment: Employee;
  employeeName: string;
}

const AccountQRCode: React.FC<AccountQRCodeProps> = ({
  userEmployment,
  employeeName = undefined,
}) => {
  const qrCodeRef = useRef(null);
  const qrValue = JSON.stringify({
    fullName: employeeName,
    employeeNumber: userEmployment.employeeNumber,
  });

  const saveAsPNG = () => {
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current).then((canvas: HTMLCanvasElement) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'my-qrcode.png';
        link.click();
      });
    }
  };

  return (
    <div className="w-full flex justify-center user-tab-container">
      <button
        ref={qrCodeRef}
        className="qr-code-button cursor-pointer"
        type="button"
        onClick={saveAsPNG}
      >
        <QRCode
          className="qr-code"
          bgColor="#F05625"
          fgColor="#FFFFFF"
          value={qrValue}
          size={420}
          level="Q"
        />
      </button>
    </div>
  );
};

export default AccountQRCode;
