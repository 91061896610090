import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import toastNotification from '../../../../../hooks/utils/toastNotification';
import useAttendanceAction from '../../../../../hooks/attendances/useAttendanceAction';
import useOvertime from '../../../../../hooks/attendances/useOvertime';
import ButtonAction from '../../../../atoms/buttons/ButtonAction';
import useNextUserScheduleTimeDiff from '../../../../../hooks/utils/useNextUserScheduleTimeDiff';
import formatTimeDuration from '../../../../../utils/formatTimeDuration';
import { formatTimeTo12HourString } from '../../../../../utils/convertTimes';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import useTheme from '../../../../../hooks/utils/useTheme';
import UserAttendanceModal from '../UserAttendanceModal';

interface OverTimeProps {
  handleOnClose?: () => void;
  advanceOtTimeDiff?: { hours: number; minutes: number; seconds: number };
}

export interface EmployeeOverTimeDetail {
  hours: number;
  minutes: number;
}

const initialValues: EmployeeOverTimeDetail = {
  hours: 0,
  minutes: 0,
};

const UserOvertimeModal: React.FC<OverTimeProps> = ({
  handleOnClose = () => {},
  advanceOtTimeDiff = undefined,
}) => {
  const { theme } = useTheme();
  const { convertNumberDaysToNames, myNextSchedule } = useAttendanceAction();
  const { overtimeTimeIn } = useAttendance();
  const { updateOvertimeDuration } = useOvertime();
  const { days, hours, minutes } = useNextUserScheduleTimeDiff(myNextSchedule);

  const [overtime, setOvertime] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [combinedDuration, setCombinedDuration] = useState({
    hours: 1,
    minutes: 0,
  });

  const handleSubmit = async () => {
    setLoading(true);
    const { hours: hrs, minutes: mins } = combinedDuration;
    const duration = moment().hours(hrs).minutes(mins).format('HH:mm');

    if (hrs === 0 && mins === 0) return toast.error('Overtime duration cannot be zero.');

    await toastNotification({
      action: overtimeTimeIn({ duration }),
      onSuccess: async () => {
        updateOvertimeDuration(hrs, mins);
        setLoading(false);
        handleOnClose();
      },
      onError: () => {},
      onFulfilled: () => {
        setLoading(false);
        handleOnClose();
      },
      toastPromiseMessages: {
        pending: 'Processing your overtime...',
        success: `You have conducted an Overtime for ${duration}.`,
        error: 'An error occurred while processing overtime.',
      },
    });

    return null;
  };

  const updateCombinedDuration = (hrs: number, mins: number) => {
    setCombinedDuration({ hours: hrs, minutes: mins });
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>, isHour: boolean) => {
    let value = parseInt(e.target.value, 10);
    if (Number.isNaN(value)) value = 0;
    value = isHour ? Math.min(3, Math.max(0, value)) : Math.min(59, Math.max(0, value));
    updateCombinedDuration(
      isHour ? value : combinedDuration.hours,
      isHour ? combinedDuration.minutes : value,
    );
  };

  useOnLoad(() => {
    if (advanceOtTimeDiff) {
      const { hours: hrs, minutes: mins } = advanceOtTimeDiff;
      setCombinedDuration({ hours: hrs, minutes: mins });
      setOvertime(true);
    }
  });

  return (
    <UserAttendanceModal
      hasHeading={!advanceOtTimeDiff}
      heading={
        <>
          <h1 className={` ${theme}`}>
            Oops! You are{' '}
            {`${formatTimeDuration({
              days,
              hours,
              minutes,
            })}`}{' '}
            ahead of your schedule.
          </h1>
          {myNextSchedule && (
            <>
              <h3 className="mt-2 text-sm">Your next schedule:</h3>
              <div className="user-schedule-time">
                <span>
                  {formatTimeTo12HourString(myNextSchedule.timeIn)} -{' '}
                  {formatTimeTo12HourString(myNextSchedule.timeOut)}{' '}
                  {convertNumberDaysToNames(myNextSchedule.days || '')}
                </span>
              </div>
            </>
          )}
        </>
      }
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          {/* first container */}
          <div className="user-overtime-wrapper">
            {overtime ? (
              <div className="w-full mb-3 user-overtime-content">
                <div className="w-full mb-3 flex gap-2">
                  <div>
                    <label htmlFor="hours">Hour(s)</label>
                    <input
                      type="number"
                      id="hours"
                      min={0}
                      max={3}
                      className={`user-overtime__input ${theme}`}
                      name="hours"
                      onChange={(e) => handleDurationChange(e, true)}
                      value={combinedDuration.hours}
                    />
                  </div>
                  <div>
                    <label htmlFor="minutes">Minute(s)</label>
                    <input
                      type="number"
                      id="minutes"
                      min={0}
                      max={59}
                      className={`user-overtime__input ${theme}`}
                      name="minutes"
                      onChange={(e) => handleDurationChange(e, false)}
                      value={combinedDuration.minutes}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="duration">
                    Your overtime duration is{' '}
                    {formatTimeDuration({
                      hours: combinedDuration.hours,
                      minutes: combinedDuration.minutes,
                    })}
                  </label>
                </div>

                <div className="pt-2 action-button-container">
                  <ButtonAction
                    type="button"
                    className="action-button__submit"
                    isLoading={loading}
                    disabled={loading}
                    hasSpinnerText={false}
                    isSubmit
                  />
                  <button onClick={handleOnClose} type="button" className="action-button__cancel">
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <ButtonAction
                  className="action-button__submit"
                  text="Overtime"
                  onClick={() => setOvertime(true)}
                />
              </div>
            )}
          </div>
        </Form>
      </Formik>
    </UserAttendanceModal>
  );
};

export default UserOvertimeModal;
