import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { VariableSizeList } from 'react-window';
import TextWithIcons from '../../../../molecules/TextWithIcons';
import useDateTime from '../../../../../hooks/utils/useDateTime';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import PayslipConcern from '../../../../../store/constants/my/payslip-concern.interface';
import PayslipConcernListRow from './PayslipConcernListRow';
import { ReactComponent as Arrow } from '../../../../../assets/images/icons/arrow-with-tail-down.svg';
import { ReactComponent as Search } from '../../../../../assets/images/icons/search.svg';
import usePayslipConcerns from '../../../../../hooks/my/usePayslipConcerns';

type SortByHeaderOptions = '' | 'id' | 'subject' | 'contents' | 'status' | 'date';

const PayslipConcernList = () => {
  const { getDateTime } = useDateTime();
  const { payslipConcernsState, getAll } = usePayslipConcerns();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const { getDateAsMoment } = useDateTime();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const payslipConcern = payslipConcernsState.payslipConcerns
    .filter((payslipConcernData: PayslipConcern) => {
      const searchValues = debouncedSearchValue.toLowerCase();
      const formattedDate = getDateAsMoment(payslipConcernData.Date)
        .format('MMM DD, YYYY')
        .toLowerCase();

      return (
        payslipConcernData.Subject.toLowerCase().includes(searchValues) ||
        formattedDate.includes(searchValues)
      );
    })
    .sort((a: PayslipConcern, b: PayslipConcern) => {
      const sortOrder = sortOrderBy ? -1 : 1;
      const defaultOrder = a.id - b.id;

      if (sortOption === 'id') {
        return sortOrder * b.id - a.id;
      }

      if (sortOption === 'subject') {
        return sortOrder * a.Subject.localeCompare(b.Subject);
      }

      if (sortOption === 'contents') {
        return sortOrder * a.Contents.localeCompare(b.Contents);
      }

      if (sortOption === 'status') {
        return sortOrder * a.Status.localeCompare(b.Status);
      }

      if (sortOption === 'date') {
        return sortOrder * (new Date(b.Date).getTime() - new Date(a.Date).getTime());
      }

      return defaultOrder;
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full px-5 organism-payslip-concern-list">
      <div className="flex justify-between pt-2 pb-5">
        <div className="w-full flex justify-between items-center payslip-concern-search">
          <h1>Feedback Manager Table</h1>
          <div className="search-input-wrapper payslip-concern-wrapper relative">
            <input
              onKeyDown={onSearchKeyDown}
              type="text"
              className="payslip-concern-search-input search-bar"
              placeholder="Search Payslip Concern"
              onChange={handleSearch}
            />
            <Search className="h-4 w-4 payslip-search-icon" />
          </div>
        </div>
      </div>
      <div className="payslip-concern-list-header header-items">
        <button
          type="button"
          onClick={() => sortByHeader('id', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'id' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'id' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">ID</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('subject', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'subject' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'subject' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Subject</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('contents', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'contents' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'contents' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Contents</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('status', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'status' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'status' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Status</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('date', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'date' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'date' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Date</div>
          </TextWithIcons>
        </button>
        <TextWithIcons variant="h6" className="flex justify-center">
          <span className="flex gap-5">Action</span>
        </TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={payslipConcern.length}
        itemSize={getItemSize}
        height={window.innerHeight - 300}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <PayslipConcernListRow
            data={payslipConcern[index]}
            className={`${index % 2 === 0 ? 'stripped' : ''}`}
            style={style}
            getDateTime={getDateTime}
            // onEditClick={onEditClick}
            // onDeleteClick={onDeleteClick}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default PayslipConcernList;
