import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import EmploymentPosition from '../../constants/employments/positions/employment-position.interface';
import positionsActions from '../../actions/positions/positionActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface PositionsState {
  positions: EmploymentPosition[];
}

const initialState: PositionsState = {
  positions: [],
};

const getPositionsData = (positions: Partial<EmploymentPosition>) => ({
  ...positions,
});

const upsertItems = (positions: EmploymentPosition[], ...data: EmploymentPosition[]) => {
  const dataObject: { [id: number]: EmploymentPosition } = {};
  positions.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((position) => {
    if (!dataObject[position.id]) {
      dataObject[position.id] = position;
    } else if (dataObject[position.id]) {
      dataObject[position.id] = { ...dataObject[position.id], ...position };
    }
  });

  return Object.values(dataObject);
};

const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    resetPositionsState: () => ({
      ...initialState,
    }),
    setPositionsState: (state, action: PayloadAction<Partial<PositionsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertPositions: (state, action: PayloadAction<Partial<EmploymentPosition>>) => {
      const data: EmploymentPosition = getPositionsData(action.payload) as EmploymentPosition;

      if (typeof data.id === 'undefined') return;

      state.positions = upsertItems(state.positions, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(positionsActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<EmploymentPosition>;
      consoleLog('POSITIONS REDUCER', 'POST', data);

      // if (typeof data.id === 'undefined') return;

      if (data) {
        state.positions = upsertItems(state.positions, data);
      }
    });
    builder.addCase(positionsActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('POSITIONS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmploymentPosition>;
      if (status === 503) return;

      if (data) {
        state.positions = data;
      }
    });
    builder.addCase(positionsActions.get.fulfilled, (state, { payload }) => {
      consoleLog('POSITIONS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<EmploymentPosition>;

      if (typeof data.id === 'undefined') return;
      state.positions = upsertItems(state.positions, data);
    });
    builder.addCase(positionsActions.put.fulfilled, (state, { payload }) => {
      consoleLog('POSITIONS REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<EmploymentPosition>;

      if (status !== StatusCodes.OK) return;
      state.positions = upsertItems(state.positions, data);
    });
    builder.addCase(positionsActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('POSITIONS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<EmploymentPosition>;

      if (typeof data.id === 'undefined') return;
      state.positions = state.positions.filter((position) => position.id !== data.id);
    });
  },
});

export const positionsReducerActions = positionsSlice.actions;
export default positionsSlice.reducer;
