import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/auths/useAuth';
import ButtonAction from '../atoms/buttons/ButtonAction';
import toastNotification from '../../hooks/utils/toastNotification';
import useLoading from '../../hooks/utils/useLoading';
import useTheme from '../../hooks/utils/useTheme';
import useCalendarApiEvents from '../../hooks/calendar/useCalendarApiEvents';
import useAttendance from '../../hooks/attendances/useAttendance';
import { ReactComponent as ShowIcon } from '../../assets/images/icons/eye.svg';
import { ReactComponent as HideIcon } from '../../assets/images/icons/eye-slash.svg';

export interface LoginFormValues {
  username: string;
  password: string;
}

const initialValues = {
  username: '',
  password: '',
};

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required.'),
  password: Yup.string().required('Password is required.'),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const { getAllCalendarEvents } = useCalendarApiEvents();
  const { isLoading, setLoading } = useLoading();
  const { timeIn, getMyCurrentOvertime } = useAttendance();
  const { login } = useAuth();
  const { theme } = useTheme();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSubmit = async (values: LoginFormValues) => {
    setLoading(true);

    await toastNotification({
      action: login(values),
      onSuccess: async () => {
        try {
          await timeIn();
          await getAllCalendarEvents();
          await getMyCurrentOvertime();
        } finally {
          setLoading(false);
          navigate('/user/newsfeed');
        }
      },
      onError: () => {
        setLoading(false);
      },
      onFulfilled: () => {
        setLoading(false);
      },
      toastPromiseMessages: {
        pending: {
          render: `Checking credentials for ${values.username}...`,
          delay: 100,
          autoClose: 7000,
        },
        success: {
          render: `${values.username} is successfully authenticated.`,
          delay: 100,
          autoClose: 4000,
        },
        error: {
          render({ data }) {
            if (data === 'Unauthorized') {
              return 'You have entered an invalid username or password.';
            }
            if (data === 'Suspended') {
              return 'Your account has been suspended. Please Contact Your Supervisor or hr@awesomeoscx.org.';
            }

            return 'An error has occurred during login attempt. Contact hr@awesomeoscx.org.';
          },
          delay: 100,
          autoClose: 5000,
        },
      },
    });
  };

  return (
    <div className="login-form">
      <Formik initialValues={initialValues} validationSchema={LoginSchema} onSubmit={handleSubmit}>
        <Form className="space-y-10">
          <div className="flex flex-col space-y-5">
            <div className="space-y-2">
              <Field
                id="username"
                type="text"
                name="username"
                className={`login__field tracking-widest ${theme}`}
                style={{ paddingRight: '3rem', paddingLeft: '3rem' }}
                placeholder="USERNAME"
                required
                autoComplete="current-password"
              />
              <ErrorMessage name="username" component="div" className="input-error" />
            </div>
            <div className="div space-y-2">
              <div className="relative password-wrapper">
                <Field
                  id="password"
                  type={isPasswordVisible ? 'text' : 'password'}
                  name="password"
                  className={`login__field tracking-widest ${theme}`}
                  style={{ paddingRight: '3rem', paddingLeft: '3rem' }}
                  placeholder="PASSWORD"
                  required
                  autoComplete="current-password"
                />
                <button
                  type="button"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  className="absolute inset-y-0 right-0 px-3 flex items-center z-50 bg-gray-700 bg-opacity-50 rounded-tr-md rounded-br-md"
                >
                  {isPasswordVisible ? (
                    <HideIcon className="h-5 w-5 text-white" />
                  ) : (
                    <ShowIcon className="h-5 w-5 text-white" />
                  )}
                </button>
              </div>
              <ErrorMessage name="password" component="div" className="input-error" />
            </div>
          </div>
          <div className="flex justify-center">
            <ButtonAction
              isLoading={isLoading.isLoading}
              className={`login__button z-10 ${theme}`}
              text="Login"
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default LoginForm;
