import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import Department from '../../constants/departments/department.interface';
import DepartmentActions from '../../actions/departments/departmentActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface DepartmentsState {
  departments: Department[];
}

const initialState: DepartmentsState = {
  departments: [],
};

const getDepartmentData = (department: Partial<Department>) => ({
  ...department,
});

const upsertItems = (departments: Department[], ...data: Department[]) => {
  const dataObject: { [id: number]: Department } = {};
  departments.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((department) => {
    if (!dataObject[department.id]) {
      dataObject[department.id] = department;
    } else if (dataObject[department.id]) {
      dataObject[department.id] = { ...dataObject[department.id], ...department };
    }
  });

  return Object.values(dataObject);
};

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    resetDepartmentsState: () => ({
      ...initialState,
    }),
    setDepartmentsState: (state, action: PayloadAction<Partial<DepartmentsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertDepartment: (state, action: PayloadAction<Partial<Department>>) => {
      const data: Department = getDepartmentData(action.payload) as Department;

      if (typeof data.id === 'undefined') return;

      state.departments = upsertItems(state.departments, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(DepartmentActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Department>;
      consoleLog('CLIENTS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.departments = upsertItems(state.departments, data);
      }
    });
    builder.addCase(DepartmentActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Department>;

      if (status === 503) return;

      if (data) {
        state.departments = data;
      }
    });
    builder.addCase(DepartmentActions.get.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Department>;

      if (typeof data.id === 'undefined') return;
      state.departments = upsertItems(state.departments, data);
    });
    builder.addCase(DepartmentActions.put.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<Department>;

      if (status !== StatusCodes.OK) return;
      state.departments = upsertItems(state.departments, data);
    });
    builder.addCase(DepartmentActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Department>;

      if (typeof data.id === 'undefined') return;
      state.departments = state.departments.filter((department) => department.id !== data.id);
    });
  },
});

export const departmentsReducerActions = departmentsSlice.actions;
export default departmentsSlice.reducer;
