import React from 'react';
import TabbedContent, { Tab } from '../../molecules/TabbedContent';
import Scheduler from './SchedulerList';
import ScheduleTypeList from './ScheduleTypeList';
import SchedulerView from './old/SchedulerView';
import useSchedules from '../../../hooks/schedule/useSchedules';
import ManagementHeading from '../../atoms/ManagementHeading';

const ScheduleManagement: React.FC = () => {
  const {
    schedulesState: { isViewEmployeeSchedule },
  } = useSchedules();

  const tabs: Tab[] = [
    { title: 'Schedules', content: <Scheduler /> },
    { title: 'Schedule Type', content: <ScheduleTypeList /> },
  ];

  return (
    <div className="flex w-full schedule-management">
      <div className={`${isViewEmployeeSchedule ? 'w-[50%]' : ''} w-[100%] flex flex-col`}>
        <div className="m-2 title">
          <ManagementHeading title="Schedule" />
        </div>
        <div className="w-[100%] organisms-schedule-management">
          <TabbedContent tabs={tabs} />
        </div>
      </div>
      <div className="mt-32 mr-5">
        <SchedulerView />
      </div>
    </div>
  );
};

export default ScheduleManagement;
