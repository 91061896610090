import React from 'react';
import { useLocation } from 'react-router-dom';
import TabbedContent, { Tab } from '../../../molecules/TabbedContent';
import ProfileCard from '../../ProfileCard';
import useUser from '../../../../hooks/users/useUser';
import useAuth from '../../../../hooks/auths/useAuth';
import AccountQRCode from '../../../atoms/AccountQRCode';
import UserAccountChangePasswordForm from './UserAccountChangePasswordForm';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import UserTransactionHistory from './UserTransactionHistory';
import UserTimeLogs from './UserTimeLogs';
import RelatedEmployees from '../../RelatedEmployees';
import UserPayroll from './UserPayroll';
import HealthTracker from './HealthTracker';
import TransactionFeed from './TransactionFeed';

const UserMyProfile: React.FC = () => {
  const location = useLocation();
  const { getAll } = useUserEmployments();

  const {
    authState: { userInformation },
  } = useAuth();

  const { currentUser } = useUser((userInformation?.userId ?? '').toString());

  const tabs: Tab[] = [
    ...(currentUser
      ? [
          {
            urlPath: '/my/profile',
            title: 'Profile',
            content: <ProfileCard user={currentUser} />,
          },
        ]
      : []),
    {
      urlPath: '/my/profile/transactions',
      title: 'Transaction History',
      content: <UserTransactionHistory />,
    },
    {
      urlPath: '/my/profile/password',
      title: 'Password',
      content: <UserAccountChangePasswordForm />,
    },
    {
      urlPath: '/my/profile/time-logs',
      title: 'Time Logs',
      content: <UserTimeLogs />,
    },
    {
      urlPath: '/my/profile/payroll',
      title: 'Payroll',
      content: <UserPayroll />,
    },
    {
      urlPath: '/my/profile/health-tracker',
      title: 'Health Tracker',
      content: <HealthTracker />,
    },
    ...(currentUser && currentUser.employment
      ? [
          {
            urlPath: '/my/profile/qr',
            title: 'My Qr code',
            content: (
              <AccountQRCode
                userEmployment={currentUser.employment}
                employeeName={(userInformation?.fullName as string) ?? ''}
              />
            ),
          },
        ]
      : []),
  ];

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full h-full organisms-user-profile">
      <div className="flex user-profile-container">
        <div className="ml-[10%] user-profile-first-wrapper">
          <h1 className="pb-7 pt-4 user-profile-title">Employee Profile</h1>
          <TabbedContent
            tabs={tabs}
            currentActiveTab={tabs.findIndex((tab) => tab.urlPath === location.pathname)}
          />
        </div>
        <div className="flex flex-col gap-3 w-full h-full pr-3 user-profile-second-wrapper">
          <RelatedEmployees />
          <TransactionFeed />
        </div>
      </div>
    </div>
  );
};

export default UserMyProfile;
