import React, { useEffect, useState } from 'react';
import { Dialog, DialogHeader, DialogBody, Typography, IconButton } from '@material-tailwind/react';
import { useDebounce } from 'use-debounce';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';
import SearchSvg from '../../../../../assets/images/icons/search.svg';
import Client from '../../../../../store/constants/clients/client.interface';
import useClients from '../../../../../hooks/clients/useClients';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';

interface TeamGrossProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const TeamGross: React.FC<TeamGrossProps> = ({ isOpen, setOpen }) => {
  // @todo replace with the endpoint phic gross and basic.
  const { getAll, clientsState } = useClients();

  const [basicPayPhicState, setBasicPayPhicState] = useState<Client[]>([]);
  const [grossPayPhicState, setGrossPayPhicState] = useState<Client[]>([]);
  const [basicPaySearchValue, setBasicPaySearchValue] = useState('');
  const [debouncedBasicPaySearchValue] = useDebounce(basicPaySearchValue, 2000);

  const filteredBPPClients = basicPayPhicState
    .filter((client) =>
      client.name.toLowerCase().includes(debouncedBasicPaySearchValue.toLowerCase()),
    )
    .sort((a, b) => (a?.name || '').localeCompare(b?.name || ''));

  useEffect(() => {
    if (debouncedBasicPaySearchValue === '') {
      setBasicPayPhicState(
        clientsState.clients
          .filter((client) => !grossPayPhicState.some((data) => data.id === client.id))
          .sort((a, b) => (a?.name || '').localeCompare(b?.name || '')),
      );
    } else {
      setBasicPayPhicState(filteredBPPClients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBasicPaySearchValue]);

  const onBasicPaySearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setBasicPaySearchValue(basicPaySearchValue);
    }
  };

  const handleBasicPaySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBasicPaySearchValue(e.target.value);
  };

  const [draggedData, setDraggedData] = useState<Client | undefined>();

  const handleOnDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  const handleOnDrop = (droppableContainer: string) => {
    if (draggedData === undefined) return;

    const draggedNotInBasic = !basicPayPhicState.includes(draggedData);
    const draggedNotInGross = !grossPayPhicState.includes(draggedData);

    if (droppableContainer === 'basic-pay-phic-container') {
      if (draggedNotInBasic) {
        setBasicPayPhicState(
          [...basicPayPhicState, draggedData].sort((a, b) => {
            if (a?.name < b?.name) return -1;
            if (a?.name > b?.name) return 1;
            return 0;
          }),
        );
      }
      setGrossPayPhicState(grossPayPhicState.filter((data) => data !== draggedData));
    } else if (droppableContainer === 'gross-pay-phic-container') {
      if (draggedNotInGross) {
        setGrossPayPhicState(
          [...grossPayPhicState, draggedData].sort((a, b) => {
            if (a?.name < b?.name) return -1;
            if (a?.name > b?.name) return 1;
            return 0;
          }),
        );
      }
      setBasicPayPhicState(basicPayPhicState.filter((data) => data !== draggedData));
    }

    setDraggedData(undefined);
  };

  const handleOnDragStart = (e: React.DragEvent<HTMLDivElement>, data: Client) => {
    setDraggedData(data);
  };

  const handleOnDragEnd = () => {
    setDraggedData(undefined);
  };
  useOnLoad(() => {
    getAll();
    const sortedClients = [...clientsState.clients].sort((a, b) =>
      (a?.name || '').localeCompare(b?.name || ''),
    );
    setBasicPayPhicState(sortedClients);
  });

  return (
    <Dialog
      size="sm"
      open={isOpen}
      handler={setOpen}
      className="bg-[#343434] shadow-none p-10 modal-size flex flex-col gap-5"
    >
      <div className="m-2 p-0 absolute flex top-0 right-0">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={() => setOpen(false)}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>

      <DialogHeader className="modal-header mt-1 p-0 text-gray-200">Team gross</DialogHeader>

      <DialogBody divider className="p-0">
        <div className="grid grid-cols-2 gap-5">
          <div className="flex flex-col gap-2">
            <Typography color="gray">Basic pay PHIC</Typography>
            <div className="relative duration-300 m-1">
              <input
                onKeyDown={onBasicPaySearchKeyDown}
                onChange={handleBasicPaySearch}
                type="text"
                className="text-white rounded-md bg-[#2c2b2a] text-[0.65rem] w-full p-1 py-2 placeholder:text-[0.65rem] search-bar_payroll"
                placeholder="Search..."
              />
              <img
                style={{ filter: 'invert(0) invert(1)' }}
                className="absolute w-4 h-4 transform -translate-y-1/2 top-1/2 right-2 search-body_payroll"
                src={SearchSvg}
                alt="Search..."
              />
            </div>
            <div
              className="basic-pay-phic-container no-scrollbars bg-[#3e3e3e] rounded-lg"
              onDrop={() => handleOnDrop('basic-pay-phic-container')}
              onDragOver={handleOnDragOver}
            >
              {basicPayPhicState.map((data) => (
                <div
                  key={data.id}
                  draggable
                  onDragStart={(e) => handleOnDragStart(e, data)}
                  onDragEnd={handleOnDragEnd}
                  className={`basicPayPhicData cursor-pointer ${
                    draggedData === data ? 'dragging' : ''
                  }`}
                >
                  <span className="text-sm ">{data.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Typography color="gray">Gross pay PHIC</Typography>
            <div className="relative duration-300 m-1">
              <input
                type="text"
                className="text-white rounded-md bg-[#2c2b2a] text-[0.65rem] w-full p-1 py-2 placeholder:text-[0.65rem] search-bar_payroll"
                placeholder="Search..."
              />
              <img
                style={{ filter: 'invert(0) invert(1)' }}
                className="absolute w-4 h-4 transform -translate-y-1/2 top-1/2 right-2 search-body_payroll"
                src={SearchSvg}
                alt="Search..."
              />
            </div>
            <div
              className="gross-pay-phic-container no-scrollbars bg-[#3e3e3e] rounded-lg"
              onDrop={() => handleOnDrop('gross-pay-phic-container')}
              onDragOver={handleOnDragOver}
            >
              {grossPayPhicState.map((data) => (
                <div
                  key={data.id}
                  draggable
                  onDragStart={(e) => handleOnDragStart(e, data)}
                  onDragEnd={handleOnDragEnd}
                  className={`grossPayPhicData cursor-pointer ${
                    draggedData === data ? 'dragging' : ''
                  }`}
                >
                  <span className="text-sm">{data.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogBody>
      <div className="flex justify-center w-max">
        <button
          type="button"
          onClick={() => setOpen(false)}
          className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
        >
          Close
        </button>
      </div>
    </Dialog>
  );
};
export default TeamGross;
