import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Chip, Typography } from '@material-tailwind/react';
import { VariableSizeList } from 'react-window';
import { Form, Formik } from 'formik';
import { useDebounce } from 'use-debounce';
import DateRangePicker from 'react-datepicker';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import useTeams from '../../../hooks/teams/useTeams';
import Team from '../../../store/constants/teams/team.interface';
import TextWithIcons from '../../molecules/TextWithIcons';
import TeamAssignmentRow from './TeamAssignmentRow';
import useDateTime from '../../../hooks/utils/useDateTime';
import TeamLeaderAssignmentModal from './TeamLeaderAssignmentModal';
import SearchSvg from '../../../assets/images/icons/search.svg';
import SelectActiveStatus, { ActiveStatusOption } from '../../atoms/dropdowns/SelectActiveStatus';
import useTeam from '../../../hooks/teams/useTeam';
import Employee from '../../../store/constants/employees/employee.interface';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';
import EmptyListMessage from '../../atoms/EmptyListMessage';

type SortByHeaderOptions = '' | 'member' | 'dateStart' | 'dateEnd';

const TeamAssignment: React.FC = () => {
  const { getAllTeamEmployees } = useTeams();
  const { getDateTime, getDateAsMoment } = useDateTime();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 2000);
  const [open, setOpen] = useState(false);
  const [currentTeam, setCurrentTeam] = useState<Team | undefined>();
  const [dateStartRange, setDateStartRange] = useState<[Date | null, Date | null]>([null, null]);
  const [dateEndRange, setDateEndRange] = useState<[Date | null, Date | null]>([null, null]);
  const [selectedActiveStatus, setSelectedActiveStatus] = useState<ActiveStatusOption | undefined>({
    label: 'Active',
    value: 1,
  });

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const { id } = useParams<{
    id: string;
  }>();
  const { currentTeam: team, loading } = useTeam(id as string);

  useOnLoad(() => {
    getAllTeamEmployees(team?.id as number).then();
    setCurrentTeam(team);
  });

  if (loading) return null;
  if (!team) return null;

  const employees =
    team.employees ??
    []
      .filter((employee: Employee) =>
        employee.userInformation.fullName
          .toLowerCase()
          .includes(debouncedSearchValue.toLowerCase()),
      )
      .sort((a: Employee, b: Employee) => {
        const sortOrder = sortOrderBy ? -1 : 1;

        if (sortOption === 'member') {
          return sortOrder * a.userInformation.fullName.localeCompare(b.userInformation.fullName);
        }

        if (sortOption === 'dateStart') {
          return (
            sortOrder *
            getDateAsMoment(new Date(a.dateStart)).diff(getDateAsMoment(new Date(b.dateStart)))
          );
        }

        if (sortOption === 'dateEnd') {
          return (
            sortOrder *
            getDateAsMoment(new Date(a.dateEnd)).diff(getDateAsMoment(new Date(b.dateEnd)))
          );
        }

        return sortOrder * (a.id - b.id);
      });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  return (
    <div className="team-manage-form-container">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/teams">
          <Typography className="crumb">Teams</Typography>
        </Link>
        {currentTeam?.company && <span className="crumb">{currentTeam?.company?.name}</span>}
        {currentTeam?.companyBranch && (
          <span className="crumb">{currentTeam?.companyBranch?.name}</span>
        )}
        <span className="crumb">{currentTeam?.department?.name}</span>
        <span className="crumb">{currentTeam?.name}</span>
        <span className=" flex items-center gap-2">
          <Typography className="current-crumb">Members</Typography>{' '}
          <Chip className="chip-style" value={team.employees?.length} variant="ghost" />
        </span>
      </Breadcrumbs>
      <div className="main-team-assignment-section pr-10">
        <div className="organisms-team-assignment__top-section">
          <div>
            <Typography className="team-leader-title pl-5">
              Team: <span className="team-leader-name">{currentTeam?.name}</span>
            </Typography>

            <div className="flex">
              <TeamLeaderAssignmentModal
                team={open ? currentTeam : undefined}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
              />
              <Typography className="team-leader-title">
                Team Leader:{' '}
                <span className="team-leader-name">
                  {currentTeam?.teamLeader?.userInformation?.fullName}
                </span>
              </Typography>
            </div>
          </div>

          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
              <div className="team-assignment-filters team-assignment-filter">
                <div className="w-full text-xs">
                  <SelectActiveStatus
                    id="status"
                    name="status"
                    className="status-filter"
                    value={selectedActiveStatus as ActiveStatusOption}
                    onChange={(value) => {
                      setSelectedActiveStatus(value as ActiveStatusOption);
                    }}
                  />
                </div>

                <div className="date-filter">
                  <DateRangePicker
                    className="date-input"
                    startDate={dateStartRange[0]}
                    endDate={dateStartRange[1]}
                    onChange={(update) => {
                      setDateStartRange(update);
                    }}
                    selectsRange
                    isClearable
                    placeholderText="Date start..."
                  />
                </div>

                <div className="date-filter">
                  <DateRangePicker
                    className="date-input"
                    startDate={dateEndRange[0]}
                    endDate={dateEndRange[1]}
                    onChange={(update) => {
                      setDateEndRange(update);
                    }}
                    selectsRange
                    isClearable
                    placeholderText="Date end..."
                  />
                </div>

                <div className="search-body">
                  <input
                    onKeyDown={onSearchKeyDown}
                    type="text"
                    className="search-body__input search-bar"
                    placeholder="Search client"
                    onChange={handleSearch}
                  />
                  <img
                    style={{ filter: 'invert(0) invert(1)' }}
                    className="search-body__icon"
                    src={SearchSvg}
                    alt="Search for Client"
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
        <div className="team-assignment-list-header table-header">
          <button
            type="button"
            onClick={() => sortByHeader('member', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'member' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'member' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Member</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('dateStart', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'dateStart' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'dateStart' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Date start</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('dateEnd', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'dateEnd' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'dateEnd' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Date end</div>
            </TextWithIcons>
          </button>
        </div>

        {employees.length === 0 ? (
          <EmptyListMessage />
        ) : (
          <VariableSizeList
            itemCount={employees.length}
            itemSize={getItemSize}
            height={window.innerHeight - 400}
            width="100%"
            className="no-scrollbars"
          >
            {({ index, style }) => (
              <TeamAssignmentRow
                data={employees[index]}
                className={`${index % 2 === 0 ? 'stripped' : ''}`}
                style={style}
                getDateTime={getDateTime}
              />
            )}
          </VariableSizeList>
        )}
      </div>
    </div>
  );
};

export default TeamAssignment;
