import React from 'react';
import TransactionFeedCard from '../../../molecules/TransactionFeedCard';
import useTransactionFeeds from '../../../../hooks/my/useTransactionFeeds';

const TransactionFeed = () => {
  const { transactionFeedsState } = useTransactionFeeds();

  const unreadTransactions = transactionFeedsState.transactionFeeds.filter(
    (transaction) => !transaction.isRead,
  );

  return unreadTransactions.length > 0 ? (
    <div className="transaction-feed-container">
      <h2 className="user-profile-header pb-1">Transaction Feed</h2>
      <div className="w-full flex flex-wrap justify-center gap-6">
        {unreadTransactions.map((transaction) => (
          <TransactionFeedCard
            key={transaction.id}
            transaction={transaction}
            className="transaction-read-wrapper"
          />
        ))}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default TransactionFeed;
