import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { useDebounce } from 'use-debounce';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import Employee from '../../../store/constants/employees/employee.interface';
import useEmploymentRoles from '../../../hooks/employment/useEmploymentRoles';

export interface SelectTeamLeaderProps extends Partial<AutoSearchSelectProps<Employee>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectTeamLeader: React.FC<SelectTeamLeaderProps> = ({
  id,
  name,
  placeholder = 'Select Team Leader',
  className = '',
  ...props
}) => {
  const { findRoleById } = useEmploymentRoles();
  const { userEmploymentsStates, getAll } = useUserEmployments();
  const [searchInput, setSearchInput] = useState<string>('');
  const [additionalEmployees, setAdditionalEmployees] = useState<Employee[]>([]);
  const [debouncedSearchValue] = useDebounce(searchInput, 2000);

  const userEmployments = userEmploymentsStates.userEmployments
    .slice()
    .sort((a, b) => a.userInformation.lastName.localeCompare(b.userInformation.lastName))
    .filter((userEmployment) => {
      if (!userEmployment.employeeNumber) return false;

      if (
        userEmployment.roles &&
        userEmployment.roles.find(
          (userEmploymentRole) => findRoleById(userEmploymentRole.roleId)?.code === 'tl',
        )
      )
        return true;

      return false;
    })
    .map((userEmployment: Employee) => ({
      ...userEmployment,
      value: userEmployment,
      label: userEmployment.userInformation.fullName,
    }));

  useEffect(() => {
    const searchResults = userEmploymentsStates.userEmployments
      .slice()
      .sort((a, b) => a.userInformation.lastName.localeCompare(b.userInformation.lastName))
      .filter((userEmployment) => {
        if (!debouncedSearchValue) return false;

        return userEmployment.userInformation.fullName
          .toLowerCase()
          .includes(debouncedSearchValue.toLowerCase());
      })
      .map((userEmployment: Employee) => ({
        ...userEmployment,
        value: userEmployment,
        label: userEmployment.userInformation.fullName,
      }));

    setAdditionalEmployees(searchResults);
  }, [debouncedSearchValue, userEmploymentsStates.userEmployments]);

  const userEmploymentData = (userEmployments as { value: Employee; label: string }[]).concat(
    additionalEmployees.map((userEmployment) => ({
      ...userEmployment,
      value: userEmployment,
      label: userEmployment.userInformation.fullName,
    })),
  );

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-immediate-supervisor modal-select ${className}`}
      placeholder={placeholder}
      data={userEmploymentData}
      onInputChange={setSearchInput}
    />
  );
};

export default SelectTeamLeader;
