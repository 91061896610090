import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import CompanyBranch from '../../../../store/constants/companies/branches/company-branch.interface';
import useCompanyBranches from '../../../../hooks/companies/branches/useCompanyBranches';
import Breadcrumbs from '../../../molecules/Breadcrumbs';
import CompanyBranchForm from '../../../molecules/Companies/Branches/CompanyBranchForm';
import useCompanyBranch from '../../../../hooks/companies/branches/useCompanyBranch';

const CompanyBranchEditForm: React.FC = () => {
  const { put } = useCompanyBranches();

  const navigate = useNavigate();

  const { id } = useParams<{
    id: string;
  }>();

  const { currentCompanyBranch: companyBranch, loading } = useCompanyBranch(id as string);
  const [isLoading, setIsloading] = useState(false);
  const [companyName, setCompanyName] = useState('');

  if (loading) return null;

  if (!companyBranch) {
    navigate('/admin/companies/branches');
    return null;
  }

  const handleClose = () => {
    navigate('/admin/companies/branches');
  };

  const handleSubmit = async (data: Partial<CompanyBranch>) => {
    setIsloading(true);
    put(data)
      .then(() => navigate('/admin/companies/branches'))
      .finally(() => setIsloading(false));
  };

  const handleOnChange = (data: Partial<CompanyBranch>) => {
    setCompanyName(data.company?.name ?? '');
  };

  return (
    <div className="client-manage-form-container">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/companies/branches">
          <Typography className="crumb">Company Branch</Typography>
        </Link>
        <span className="current-crumb">{companyName}</span>
      </Breadcrumbs>
      <div className="">
        <CompanyBranchForm
          companyBranch={companyBranch}
          onSubmit={handleSubmit}
          handleClose={handleClose}
          onChange={handleOnChange}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default CompanyBranchEditForm;
