import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import UserEmployment from '../../constants/employees/employee.interface';
import UserEmploymentActions from '../../actions/users/employments/userEmploymentActions';
import { EmployeeTeamTransferReason } from '../../constants/employees/teams/employee-team-transfer-reason';

export interface UserEmploymentsState {
  userEmployments: UserEmployment[];
  transferReasons: EmployeeTeamTransferReason[];
}

const initialState: UserEmploymentsState = {
  userEmployments: [],
  transferReasons: [
    {
      id: 1,
      name: 'AWOL',
    },
    {
      id: 2,
      name: 'Resignation',
    },
    {
      id: 3,
      name: 'Termination - Performance',
    },
    {
      id: 4,
      name: 'Termination - Pending Transition',
    },
    {
      id: 5,
      name: 'Termination - Attendance',
    },
    {
      id: 6,
      name: 'Termination - Downsizing',
    },
    {
      id: 7,
      name: 'Termination - Violation of Policy',
    },
    {
      id: 8,
      name: 'Transfer - Account Endorsement',
    },
    {
      id: 9,
      name: 'Transfer - Internal Endorsement',
    },
  ],
};

const getUserEmploymentData = (userEmployment: Partial<UserEmployment>) => ({
  ...userEmployment,
});

const userEmploymentsSlice = createSlice({
  name: 'userEmployments',
  initialState,
  reducers: {
    resetUserEmploymentsState: () => ({
      ...initialState,
    }),
    setUserEmploymentsState: (state, action: PayloadAction<Partial<UserEmploymentsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertUserEmployment: (state, action: PayloadAction<Partial<UserEmployment>>) => {
      const data = getUserEmploymentData(action.payload) as UserEmployment;

      if (typeof data.id === 'undefined') return;

      state.userEmployments = upsertData(state.userEmployments, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UserEmploymentActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<UserEmployment>;
      consoleLog('USER EMPLOYMENTS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.userEmployments = upsertData(state.userEmployments, data);
      }
    });
    builder.addCase(UserEmploymentActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('USER EMPLOYMENTS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<UserEmployment>;

      if (status === 503) return;

      if (data) {
        data.forEach((userEmployment) => {
          if (userEmployment)
            state.userEmployments = upsertData(state.userEmployments, userEmployment);
        });
      }
    });
    builder.addCase(UserEmploymentActions.get.fulfilled, (state, { payload }) => {
      consoleLog('USER EMPLOYMENTS REDUCER', 'GET', payload);

      const { status, data } = payload as ApiBaseResponse<UserEmployment>;

      if (status === 503 || !data || typeof data.id === 'undefined') return;

      state.userEmployments = upsertData(state.userEmployments, data);
    });
    builder.addCase(UserEmploymentActions.put.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<UserEmployment>;

      if (typeof data.id === 'undefined') return;

      state.userEmployments = upsertData(state.userEmployments, data);
    });
    builder.addCase(UserEmploymentActions.suspendAccount.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<UserEmployment>;

      if (status !== StatusCodes.OK) return;

      state.userEmployments = upsertData(state.userEmployments, data);
    });
    builder.addCase(UserEmploymentActions.activateAccount.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<UserEmployment>;

      if (status !== StatusCodes.OK) return;

      state.userEmployments = upsertData(state.userEmployments, data);
    });
    builder.addCase(UserEmploymentActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<UserEmployment>;

      if (typeof data.id === 'undefined') return;

      state.userEmployments = state.userEmployments.filter(
        (userEmployments) => userEmployments.id !== data.id,
      );
    });
  },
});

export const userEmploymentsReducerActions = userEmploymentsSlice.actions;
export default userEmploymentsSlice.reducer;
