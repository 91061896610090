import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import type { SelectMonthRangeType } from '../../../atoms/dropdowns/SelectMonthRange';
import PayslipSummary from './PayslipSummary';
import { ReactComponent as NoPayslipIcon } from '../../../../assets/images/icons/empty.svg';
import useAuth from '../../../../hooks/auths/useAuth';
import SelectMonthRange from '../../../atoms/dropdowns/SelectMonthRange';
import useLoading from '../../../../hooks/utils/useLoading';
import Spinner from '../../../atoms/Spinner';
import PrintPayslipModal from './PrintPayslipModal';
import useMyPayslips from '../../../../hooks/my/payslip/useMyPayslips';
import { getCurrentDatePeriod } from '../../../../utils/datePeriod';
import UserMyPayslipConcern from './payslipConcern/UserMyPayslipConcern';
import time from '../../../../utils/time';

const UserMyPayslip = () => {
  const { loggedUser } = useAuth();
  const { isLoading, setLoading } = useLoading();
  const { myPayslipsState, getMyPayslips } = useMyPayslips();
  const [selectedMonthRange, setSelectedMonthRange] = useState<SelectMonthRangeType>(
    getCurrentDatePeriod(),
  );

  const handleMonthRangeChange = (payPeriodRange: SelectMonthRangeType) => {
    setSelectedMonthRange(payPeriodRange);
  };

  const onMonthRangeChange = async () => {
    if (!selectedMonthRange) return;

    try {
      setLoading(true);
      await getMyPayslips({
        employeeNumber: loggedUser?.employeeNumber,
        payPeriodFrom: [selectedMonthRange.value.split(',')[0]],
        payPeriodTo: [selectedMonthRange.value.split(',')[1]],
      });
    } catch (error) {
      toast.error('An error occurred while fetching payslips. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onMonthRangeChange();
    // eslint-disable-next-line
  }, [selectedMonthRange]);

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      <Form>
        <div className="payslip-management">
          <div className="my-payslip-container">
            <div className="my-payslip-wrapper">
              <h1>My Payslip</h1>
              {/* Contents */}
              <div className="my-payslip-content-wrapper">
                <div className="my-payslip-content__header">
                  <div className="select">
                    <SelectMonthRange
                      id="payperiod"
                      name="payperiod"
                      className=" text-white rounded-md text-[0.65rem]"
                      onChange={(value) => handleMonthRangeChange(value as SelectMonthRangeType)}
                      onSelect={(value) => handleMonthRangeChange(value as SelectMonthRangeType)}
                      value={selectedMonthRange}
                    />
                  </div>
                  {myPayslipsState.payslips.length > 0 && (
                    <div className=" place-content-center">
                      <PrintPayslipModal monthRange={selectedMonthRange?.value ?? ''} />
                    </div>
                  )}
                </div>
                <h2>CXNET Payslip Generator</h2>
                {/* payslip */}
                <div className="my-payslip-content__body">
                  {myPayslipsState.payslips.length > 0 ? (
                    <>
                      {myPayslipsState.payslips.map((payslip) => (
                        <PayslipSummary key={payslip.id} payslip={payslip} />
                      ))}
                    </>
                  ) : (
                    <div className="if-empty">
                      {isLoading.isLoading ? (
                        <Spinner />
                      ) : (
                        <div className="if-empty__container">
                          <NoPayslipIcon className="icon" />
                          <span className="text">No payslip available for this period.</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <UserMyPayslipConcern
            emailFrom={loggedUser?.emailWork}
            emailSubject={`Payslip Concern - ${time.today()}`}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default UserMyPayslip;
