import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Team from '../../constants/teams/team.interface';

export type GetAllTeamsQueryParams = ApiGetAllBaseQueryParams;

const get = async (id: Team['id'], state: StateHeaders): Promise<ApiBaseResponse<Team>> =>
  apiFetch<ApiBaseResponse<Team>>(`teams/${id}`, { state })
    .then((response: ApiBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllTeamsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Team>> =>
  apiFetch<ApiGetAllBaseResponse<Team>>(
    `teams?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<Team, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Team> | void> =>
  apiFetch<ApiBaseResponse<Team>>('teams', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (data: Team, state: StateHeaders): Promise<ApiBaseResponse<Team> | void> =>
  apiFetch<ApiBaseResponse<Team>>(`teams/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<Team, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Team> | void> =>
  apiFetch<ApiBaseResponse<Team>>(`teams/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (id: Team['id'], state: StateHeaders): Promise<ApiBaseResponse<Team> | void> =>
  apiFetch<ApiBaseResponse<Team>>(`teams/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const removeDepartmentFromTeam = async (
  data: AtLeast<Team, 'id' | 'departmentId'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Team> | void> => {
  apiFetch<ApiBaseResponse<Team>>(`teams/${data.id}/departments/${data.departmentId}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });
};

const getAllTeamEmployees = async (
  id: Team['id'],
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Team>> =>
  apiFetch<ApiGetAllBaseResponse<Team>>(`teams/${id}/employees`, { state })
    .then((response: ApiGetAllBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const updateTeamLeader = async (
  data: AtLeast<Team, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Team> | void> =>
  apiFetch<ApiBaseResponse<Team>>(`teams/${data.id}/team-leader`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Team>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  removeDepartmentFromTeam,
  getAllTeamEmployees,
  updateTeamLeader,
} as const;
