import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useDebounce } from 'use-debounce';
import { useLocation } from 'react-router-dom';
import { AnyAction } from '@reduxjs/toolkit';
import { Tooltip } from 'antd';
import { GetAllPayrollQueryParams } from '../../../../../store/services/payroll/payrollServices';
import SelectCompany from '../../../../atoms/dropdowns/SelectCompany';
import Company from '../../../../../store/constants/companies/company.interface';
import SelectEmployee from '../../../../atoms/dropdowns/SelectEmployee';
import Employee from '../../../../../store/constants/employees/employee.interface';
import useCompanies from '../../../../../hooks/companies/useCompanies';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import PayrollSidebarCompanyTeams from './payrollSidebarMenu/PayrollSidebarCompanyTeams';
import SelectMonthRange, {
  SelectMonthRangeType,
} from '../../../../atoms/dropdowns/SelectMonthRange';
import usePayrolls from '../../../../../hooks/payroll/usePayrolls';
import useTeams from '../../../../../hooks/teams/useTeams';
import SearchSvg from '../../../../../assets/images/icons/search.svg';
import ApiGetAllBaseQueryParams from '../../../../../store/constants/utils/api-get-all-base-query-params.interface';
import { ReactComponent as MultiEmployeeIcon } from '../../../../../assets/images/icons/user-two.svg';
import useLoading from '../../../../../hooks/utils/useLoading';
import updateAddressParams from '../../../../../utils/updateAddressParams';
import { formatDateShort } from '../../../../../utils/datePeriod';

interface SidebarFormValues {
  selectedCompany: Company;
  search?: string;
  selectedEmployees?: Employee[];
  selectedMonthRange: SelectMonthRangeType;
}

const PayrollSidebarCreateView: React.FC = () => {
  const location = useLocation();
  const { setLoading } = useLoading();
  const { companiesState, getAll: getAllCompanies } = useCompanies();
  const { getAll: getAllTeams } = useTeams();
  const { getEmployeePayrolls, setPayrollState, payrollsState, getPayslips } = usePayrolls();
  const [search, setSearch] = useState<string | undefined>('');

  const [debouncedEmployeeSearch] = useDebounce(search, 2000);

  const [open, setOpen] = useState(true);
  const [multiEmployee, setMultiEmployee] = useState(false);
  const [selectedMonthRange, setSelectedMonthRange] = useState<SelectMonthRangeType>();
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>();

  const initialParams: SidebarFormValues = {
    selectedCompany: selectedCompany as Company,
    search: '',
    selectedEmployees: selectedEmployees as Employee[],
    selectedMonthRange: selectedMonthRange as SelectMonthRangeType,
  };

  const actions: {
    [key: string]: (
      params?: GetAllPayrollQueryParams | ApiGetAllBaseQueryParams,
    ) => Promise<AnyAction>;
  } = {
    create: getEmployeePayrolls,
    view: getPayslips,
  };

  const [paramsState, setParamsState] = useState<SidebarFormValues>(initialParams);

  const updateParamsState = <T extends string | SelectMonthRangeType | Company | Employee[]>(
    key: keyof typeof paramsState,
    value: T,
  ) => {
    setParamsState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    const value = typeof e === 'string' ? e : e.currentTarget.value;
    setSearch(value);
    updateParamsState('search', value);
  };

  const handleMonthRangeChange = (payPeriodRange: SelectMonthRangeType) => {
    setSelectedMonthRange(payPeriodRange);
    updateParamsState('selectedMonthRange', payPeriodRange);
  };

  const handleCompanyChange = (company: Company) => {
    setSelectedCompany(company);
    updateParamsState('selectedCompany', company);
  };

  const handleEmployeesChange = (employees?: Employee[]) => {
    setSelectedEmployees(employees);
    if (employees) updateParamsState('selectedEmployees', employees);
  };

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearch(e.currentTarget.value);
    }
  };

  const fetchPayrollData = async () => {
    setLoading(true);

    if (!initialParams.selectedCompany || !initialParams.selectedMonthRange) {
      setLoading(false);
      return;
    }

    try {
      const currentViewType = location.pathname.split('/')[2] || '';
      const action = actions[currentViewType];

      const employeeNumbers = selectedEmployees?.map((employee) => employee.employeeNumber);

      const newPayrollParams = {
        ...payrollsState.payrollParams,
        search: debouncedEmployeeSearch,
        companyId: selectedCompany?.id,
        teamId: payrollsState.payrollParams.teamId,
        employeeNumbers:
          employeeNumbers && employeeNumbers?.length > 0 ? employeeNumbers : undefined,
        payPeriod: {
          from: selectedMonthRange?.value.split(',')[0],
          to: selectedMonthRange?.value.split(',')[1],
        },
      };

      setPayrollState({
        ...payrollsState,
        payrollParams: {
          ...newPayrollParams,
        },
      });

      updateAddressParams({
        search: debouncedEmployeeSearch,
        payPeriodFrom: selectedMonthRange?.value.split(',')[0],
        payPeriodTo: selectedMonthRange?.value.split(',')[1],
      });

      await action(newPayrollParams);
    } catch (error) {
      console.error('Failed to fetch data', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPayrollState({
      ...payrollsState,
      payrollParams: {
        ...payrollsState.payrollParams,
        teamId: undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    const { companies } = companiesState;
    if (companies && companies.length > 0) {
      const [company] = companies;
      setSelectedCompany({
        ...company,
        label: company.name,
        value: company,
      } as Company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesState.companies]);

  useEffect(() => {
    fetchPayrollData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedEmployeeSearch,
    selectedCompany,
    selectedEmployees,
    selectedMonthRange,
    payrollsState.payrollParams.teamId,
    payrollsState.payrollParams.awol,
    payrollsState.payrollParams.authorizedCause,
    payrollsState.payrollParams.dropTraining,
    payrollsState.payrollParams.endOfContract,
    payrollsState.payrollParams.floating,
    payrollsState.payrollParams.inactive,
    payrollsState.payrollParams.lastPayIncentives,
    payrollsState.payrollParams.resigned,
    payrollsState.payrollParams.resignedWithCase,
    payrollsState.payrollParams.suspended,
    payrollsState.payrollParams.terminated,
  ]);

  useOnLoad(() => {
    setLoading(true);
    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    const { search: querySearch, payPeriodFrom, payPeriodTo } = params;

    if (querySearch) {
      handleSearch(querySearch);
    }

    if (payPeriodFrom && payPeriodTo) {
      setSelectedMonthRange({
        label: `${formatDateShort(payPeriodFrom)} - ${formatDateShort(payPeriodTo)}`,
        value: `${payPeriodFrom},${payPeriodTo}`,
      });
    }

    setPayrollState({
      ...payrollsState,
      payrollParams: {
        ...payrollsState.payrollParams,
        search: search || '',
        payPeriod: {
          from: payPeriodFrom || selectedMonthRange?.value.split(',')[0],
          to: payPeriodTo || selectedMonthRange?.value.split(',')[1],
        },
      },
    });

    Promise.all([getAllCompanies(), getAllTeams()]).then(() => {
      setLoading(false);
    });
  });

  return (
    <div className="flex overflow-hidden">
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form
          className={`payroll-sidebar bg-[#343434] text-white duration-200 ${
            open ? 'w-80' : 'w-10'
          }`}
        >
          <div className="shadow-lg p-1 border border-solid border-[#2c2b2a] flex justify-between ">
            <span
              className={`my-auto flex gap-5 text-xs text-[#f05825] font-semibold ${
                open ? 'block' : 'hidden'
              }`}
            >
              Filters
              <Tooltip
                title={`${
                  multiEmployee ? 'Disable multi select employee' : 'Enable multi select employee'
                }`}
                mouseEnterDelay={0.5}
              >
                <MultiEmployeeIcon
                  onClick={() => {
                    if (search !== '' || selectedEmployees) {
                      setSearch(undefined);
                      handleEmployeesChange(undefined);
                    }
                    setMultiEmployee(!multiEmployee);
                  }}
                  className={`w-4 h-4 cursor-pointer ${
                    multiEmployee
                      ? 'payroll-sidebar-filter-enabled'
                      : 'payroll-sidebar-filter-disabled'
                  }`}
                />
              </Tooltip>
            </span>
            <button
              type="button"
              onClick={() => setOpen(!open)}
              className="p-1 rounded-md hover:bg-[#2c2b2a]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#f05825"
                className={`w-4 h-4 duration-300 ${open ? 'block' : 'transform -scale-x-100'}`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                />
              </svg>
            </button>
          </div>
          <div className={`m-3 ${open ? 'hidden' : 'block'}`}>
            <span className="payroll-filter-title text-xs font-semibold text-[#f05825]">
              Filters
            </span>
          </div>

          <div className={`duration-300 m-1 ${open ? 'block' : 'hidden'}`}>
            <SelectCompany
              id="company"
              name="company"
              className="w-full text-white rounded-md text-[0.65rem]"
              onChange={(value) => handleCompanyChange(value as Company)}
              value={selectedCompany}
            />
          </div>

          <div className={`duration-300 m-1 ${open ? 'block' : 'hidden'}`}>
            {multiEmployee ? (
              <SelectEmployee
                id="employeeIds"
                name="employeeIds"
                isMulti
                isClearable
                isSearchable
                className="w-full text-white rounded-md text-[0.65rem]"
                onChange={(value) => handleEmployeesChange(value as Employee[])}
                value={selectedEmployees}
              />
            ) : (
              <div className="relative">
                <input
                  type="text"
                  id="employeeName"
                  name="employeeName"
                  className="payroll-sidebar__input"
                  placeholder="Search Employee"
                  value={search}
                  onKeyDown={onSearchKeyDown}
                  onChange={handleSearch}
                />
                <img
                  style={{ filter: 'invert(0) invert(1)' }}
                  className="absolute w-4 h-4 transform -translate-y-1/2 top-1/2 right-2 search-body_payroll"
                  src={SearchSvg}
                  alt="Search Employee"
                />
              </div>
            )}
          </div>
          <div className={`duration-300 m-1 ${open ? 'block' : 'hidden'}`}>
            <SelectMonthRange
              id="payperiod"
              name="payperiod"
              className=" w-full text-white rounded-md text-[0.65rem]"
              onChange={(value) => handleMonthRangeChange(value as SelectMonthRangeType)}
              onSelect={(value) => handleMonthRangeChange(value as SelectMonthRangeType)}
              value={selectedMonthRange as SelectMonthRangeType}
            />
          </div>
          <div className={`duration-300 overflow-hidden ${open ? 'block' : 'hidden'}`}>
            <PayrollSidebarCompanyTeams company={selectedCompany} />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default PayrollSidebarCreateView;
