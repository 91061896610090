import { useState } from 'react';
import useLeaves from './useLeaves';
import useOnLoad from '../utils/useOnLoad';
import useMyLeaves from './useMyLeaves';

const useLeave = (id: string) => {
  const {
    get,
    leavesState: { leaves },
  } = useLeaves();
  const { myLeavesState } = useMyLeaves();
  const [loading, setLoading] = useState(true);

  const leaveId = parseInt(id, 10);
  const currentLeave = leaves.find((leave) => leave.id === leaveId);

  const leaveNature = currentLeave
    ? myLeavesState.leaveNatures.find((nature) => nature.id === currentLeave.leaveNatureId)
    : undefined;

  const employmentScheduleType = currentLeave
    ? myLeavesState.employmentScheduleType.find(
        (scheduleType) => scheduleType.id === Number(currentLeave.employmentScheduleTypeId),
      )
    : undefined;

  const leaveCurrentData = currentLeave
    ? {
        ...currentLeave,
        leaveNature,
        employmentScheduleType,
      }
    : undefined;

  useOnLoad(() => {
    if (currentLeave) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    get(leaveId).then(() => {
      setLoading(false);
    });
  });

  return {
    loading,
    leaveCurrentData,
  };
};

export default useLeave;
