import { ColumnsType } from 'antd/es/table';
import type { EmploymentPayroll } from '../../../../../../store/reducers/payroll/payrollsReducer';

const LastPayColumn = (): ColumnsType<Partial<EmploymentPayroll>> => {
  const columns: ColumnsType<Partial<EmploymentPayroll>> = [
    {
      title: 'ID',
      width: 60,
      dataIndex: 'id',
      fixed: 'left',
      className: 'first-column',
      align: 'center',
      key: 'id',
    },
    {
      title: 'Name',
      width: 100,
      dataIndex: 'name',
      fixed: 'left',
      align: 'center',
      key: 'name',
    },
    {
      title: 'Pay period',
      width: 80,
      dataIndex: 'payPeriod',
      fixed: 'left',
      align: 'center',
      key: 'payPeriod',
    },
    {
      title: 'Team',
      dataIndex: 'team',
      width: 80,
      fixed: 'left',
      align: 'center',
      key: 'team',
    },
    {
      title: 'Gross Pay',
      dataIndex: 'grossPay',
      width: 80,
      fixed: 'left',
      align: 'center',
      key: 'grossPay',
    },
    {
      title: 'Daily Rate',
      dataIndex: 'dailyRate',
      width: 80,
      fixed: 'left',
      align: 'center',
      key: 'dailyRate',
    },
    {
      title: 'Days Work',
      dataIndex: 'daysWork',
      width: 60,
      fixed: 'left',
      align: 'center',
      key: 'daysWork',
    },
    {
      title: 'Monthly Basic',
      dataIndex: 'monthlyBasic',
      width: 80,
      fixed: 'left',
      align: 'center',
      key: 'monthlyBasic',
    },
    {
      title: 'Taxable',
      key: 'taxableIncome',
      className: 'scroll-first-column',
      children: [
        {
          title: 'Total Taxable',
          dataIndex: 'taxableIncome',
          className: 'scroll-first-column',
          width: 150,
          key: 'taxableIncome',
        },
      ],
    },
    {
      title: 'Allowances',
      key: 'totalAllowances',
      className: 'scroll-column',
      children: [
        {
          title: 'Total Allowances',
          dataIndex: 'totalAllowances',
          className: 'scroll-column',
          width: 150,
          key: 'totalAllowances',
        },
      ],
    },
    {
      title: 'Deductions',
      key: 'totalDeductions',
      className: 'scroll-column bottom-line-column',
      children: [
        {
          title: 'Total Deductions',
          dataIndex: 'totalDeductions',
          className: 'scroll-column',
          width: 150,
          key: 'totalDeductions',
        },
        {
          title: 'Taxable Income',
          dataIndex: 'taxableIncome',
          className: 'scroll-column',
          width: 150,
          key: 'taxableIncome',
        },
      ],
    },
    {
      title: 'Incomes',
      key: 'status',
      className: 'scroll-column',
      children: [
        {
          title: 'Status',
          dataIndex: 'status',
          className: 'scroll-column',
          width: 100,
          key: 'status',
        },
        {
          title: 'Taxes',
          dataIndex: 'taxes',
          className: 'scroll-column',
          width: 150,
          key: 'taxes',
        },
        {
          title: 'Gross Income',
          dataIndex: 'grossIncome',
          className: 'scroll-column',
          width: 150,
          key: 'grossIncome',
        },
        {
          title: 'Net Income',
          dataIndex: 'netIncome',
          className: 'scroll-column',
          width: 150,
          key: 'netIncome',
        },
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          className: 'scroll-column',
          width: 150,
          key: 'startDate',
        },
      ],
    },
    {
      title: 'Details',
      key: 'accountNumber',
      className: 'scroll-last-column',
      children: [
        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          className: 'scroll-column',
          width: 150,
          key: 'accountNumber',
        },
        {
          title: 'Employment Type',
          dataIndex: 'employmentType',
          width: 150,
          key: 'employmentType',
        },
      ],
    },
  ];
  return columns;
};

export default LastPayColumn;
