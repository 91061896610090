import React from 'react';
import { Dialog, DialogBody, DialogFooter, DialogHeader, Tooltip } from '@material-tailwind/react';
import { toast } from 'react-toastify';
import DeleteSvg from '../../assets/images/icons/delete.svg';
import InfoSvg from '../../assets/images/icons/info.svg';

interface DeleteConfirmationBannerMessage {
  pending: string;
  success: string;
  error: string;
}

export interface DeleteConfirmationBannerProps<T, R> {
  index?: number;
  className?: string;
  label?: string;
  data: T;
  message: DeleteConfirmationBannerMessage;
  onDeleteConfirm: (object: T, key?: number) => R;
}

const DeleteConfirmationBanner = <T, R>({
  index = undefined,
  className = undefined,
  label = undefined,
  data,
  message,
  onDeleteConfirm,
}: DeleteConfirmationBannerProps<T, Promise<R>>) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const deleteConfirmed = (object: T) => {
    const deleteDispatch = new Promise<void>((resolve, reject) => {
      onDeleteConfirm(object, index)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject(object);
        })
        .finally(() => {
          setOpen(false);
        });
    });

    return toast.promise(deleteDispatch, message);
  };

  return (
    <>
      <Tooltip content="Archive" className="bg-[#343434]">
        <button className="textButton" type="button" onClick={handleOpen}>
          <img
            className={`w-5 h-5 trash-icon ${className ?? ''}`}
            src={DeleteSvg}
            alt={`Delete ${label}`}
          />
        </button>
      </Tooltip>

      <Dialog
        size="xs"
        className="bg-[#343434] px-5 py-2 rounded-2xl"
        open={open}
        handler={handleOpen}
      >
        <DialogHeader className="p-3 text-base text-white">
          <div className="flex gap-3">
            <img className="medium-icon" src={InfoSvg} alt={`Info for deletion of ${{ label }}`} />
            You are about to delete {label}.
          </div>
        </DialogHeader>
        <DialogBody className="p-3 text-base text-white">
          <div className="flex flex-col gap-3">
            <p className="text-sm">
              Are you sure you want to archive this item? This action cannot be undone.
            </p>
          </div>
        </DialogBody>

        <DialogFooter>
          <div className="flex w-full justify-evenly gap-7">
            <button
              type="button"
              onClick={() => deleteConfirmed(data)}
              className="z-10 flex items-center justify-center px-4 py-2 font-bold rounded-full confirm-button"
            >
              YES
            </button>

            <button
              type="button"
              onClick={handleOpen}
              className="z-10 flex items-center justify-center px-4 py-2 font-bold rounded-full cancel-button"
            >
              NO
            </button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DeleteConfirmationBanner;
