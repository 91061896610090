import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import overtimeServices, {
  ExportOvertimeQueryParams,
  GetAllOvertimesQueryParams,
} from '../../services/attendances/overtimeServices';
import EmployeeOvertime from '../../constants/employees/attendances/employee-overtime.interface';

const get = createAsyncThunk(
  'attendances/overtimes/get',
  async (
    id: EmployeeOvertime['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await overtimeServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'attendances/overtimes/post',
  async (
    data: Omit<EmployeeOvertime, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await overtimeServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'attendances/overtimes/put',
  async (
    data: AtLeast<EmployeeOvertime, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await overtimeServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'attendances/overtimes/remove',
  async (
    id: EmployeeOvertime['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await overtimeServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAllOvertimes = createAsyncThunk(
  'getAllOvertimes',
  async (
    {
      page = 1,
      limit = 50,
      search = '',
      status = undefined,
      teamId = undefined,
      duration = undefined,
      employeeNumber = undefined,
    }: GetAllOvertimesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await overtimeServices.getAllOvertimes(
        {
          page,
          limit,
          search,
          status,
          teamId,
          duration,
          employeeNumber,
        },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const approveEmployeeOvertime = createAsyncThunk(
  'attendances/overtimes/approve',
  async (
    data: AtLeast<EmployeeOvertime, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmployeeOvertime> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await overtimeServices.approveEmployeeOvertime(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const exportOvertime = createAsyncThunk(
  'export/overtime',
  async ({ teamId }: ExportOvertimeQueryParams, { getState }): Promise<Blob | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await overtimeServices.exportOvertime({ teamId }, state);
      // eslint-disable-next-line
    } catch (err: any) {
      // The validation error that is stored in the authReducer state error
      throw new Error(err.message);
    }
  },
);

export default {
  get,
  post,
  put,
  remove,
  getAllOvertimes,
  approveEmployeeOvertime,
  exportOvertime,
} as const;
