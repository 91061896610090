import React from 'react';
import { Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react';
import { ReactComponent as Money } from '../../../../../assets/images/icons/money.svg';
import { ReactComponent as PlusFolder } from '../../../../../assets/images/icons/plus-folder.svg';
import { ReactComponent as Calendar } from '../../../../../assets/images/icons/calendar.svg';

const PayrollTableContextMenuPayroll: React.FC = () => {
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <div>
      <Menu open={openMenu} handler={setOpenMenu} allowHover placement="right-start">
        <MenuHandler className="menu-handler">
          <div className="rest">
            <Money className="w-4 h-4 text-[#f2f2f2]" />
            <span className="text-xs text-white">Payroll Menu</span>
          </div>
        </MenuHandler>
        <div className="menu-list-container">
          <MenuList className="menu-list ml-52">
            <div className="focus:outline-none">
              <ul>
                <li>
                  <MenuItem className="flex items-center gap-5 pl-2 menu-item">
                    <PlusFolder className="w-4 h-4 text-[#f2f2f2]" />
                    <Typography className="menu-title">Add 13th Month Pay</Typography>
                  </MenuItem>
                </li>
                <li>
                  <MenuItem className="flex items-center gap-5 pl-2 menu-item">
                    <Calendar className="w-4 h-4 text-[#f2f2f2]" />
                    <Typography className="menu-title">13th Month History</Typography>
                  </MenuItem>
                </li>
              </ul>
            </div>
          </MenuList>
        </div>
      </Menu>
    </div>
  );
};

export default PayrollTableContextMenuPayroll;
