import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import {
  UserActivityState,
  userActivityActions,
} from '../../store/reducers/utils/userActivitiesReducer';

const useUserActivity = () => {
  const dispatch = useAppDispatch();
  const userActivityState: UserActivityState = useAppSelector((state) => state.userActivity);

  const setUserActivity = useCallback(
    (activity: UserActivityState) => {
      dispatch(userActivityActions.setUserActivity(activity));
    },
    [dispatch],
  );

  const resetUserActivity = useCallback(() => {
    dispatch(userActivityActions.resetUserActivity());
  }, [dispatch]);

  return { userActivityState, setUserActivity, resetUserActivity };
};

export default useUserActivity;
