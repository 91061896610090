import { toast } from 'react-toastify';
import { AnyAction } from '@reduxjs/toolkit';
import { ToastPromiseParams } from 'react-toastify/dist/core/toast';

export interface ToastNotification {
  action: Promise<AnyAction>;
  onError?: (response: AnyAction) => void;
  onSuccess?: (response: AnyAction) => void;
  onFulfilled?: () => void;
  toastPromiseMessages: ToastPromiseParams;
}

const toastNotification = async ({
  action,
  onError,
  onSuccess,
  onFulfilled,
  toastPromiseMessages,
}: ToastNotification) => {
  const toastPromise = new Promise((resolve, reject) => {
    action
      .then((response) => {
        if (response.payload && response.payload.error) {
          reject(response.payload.message?.error);
          if (onError) onError(response);
        } else if (response.type.includes('rejected')) {
          reject(response.payload?.message);
          if (onError) onError(response);
        } else {
          resolve(response);
          if (onSuccess) onSuccess(response);
        }
      })
      .catch((error) => {
        reject(error);
        if (onError) onError(error);
      })
      .finally(() => {
        if (onFulfilled) onFulfilled();
      });
  });

  await toast.promise(toastPromise, toastPromiseMessages);
};

export default toastNotification;
