import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import EmploymentRole from '../../constants/employments/roles/employment-role.interface';
import EmploymentRoleActions from '../../actions/employments/employmentRoleActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';

export interface EmploymentRolesState {
  employmentRoles: EmploymentRole[];
}

const initialState: EmploymentRolesState = {
  employmentRoles: [],
};

const getEmploymentRoleData = (employmentRole: Partial<EmploymentRole>) => ({
  ...employmentRole,
});

const employmentRolesSlice = createSlice({
  name: 'employmentRoles',
  initialState,
  reducers: {
    resetEmploymentRolesState: () => ({
      ...initialState,
    }),
    setEmploymentRolesState: (state, action: PayloadAction<Partial<EmploymentRolesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertEmploymentRoles: (state, action: PayloadAction<Partial<EmploymentRole>>) => {
      const data: EmploymentRole = getEmploymentRoleData(action.payload) as EmploymentRole;

      if (typeof data.id === 'undefined') return;

      state.employmentRoles = upsertData(state.employmentRoles, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(EmploymentRoleActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<EmploymentRole>;
      consoleLog('EMPLOYMENT_ROLES REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.employmentRoles = upsertData(state.employmentRoles, data);
      }
    });
    builder.addCase(EmploymentRoleActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_ROLES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmploymentRole>;

      if (status === 503) return;

      if (data) {
        state.employmentRoles = data;
      }
    });
    builder.addCase(EmploymentRoleActions.get.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_ROLES REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<EmploymentRole>;

      if (typeof data.id === 'undefined') return;
      state.employmentRoles = upsertData(state.employmentRoles, data);
    });
    builder.addCase(EmploymentRoleActions.put.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_ROLES REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<EmploymentRole>;

      if (typeof data.id === 'undefined') return;
      state.employmentRoles = upsertData(state.employmentRoles, data);
    });
    builder.addCase(EmploymentRoleActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_ROLES REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<EmploymentRole>;

      if (typeof data.id === 'undefined') return;
      state.employmentRoles = state.employmentRoles.filter(
        (employmentRole) => employmentRole.id !== data.id,
      );
    });
  },
});

export const employmentRolesReducerActions = employmentRolesSlice.actions;
export default employmentRolesSlice.reducer;
