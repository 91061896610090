import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Holiday from '../../constants/holidays/holiday.interface';
import holidayServices, {
  GetAllHolidaysQueryParams,
} from '../../services/holidays/holidayServices';

const get = createAsyncThunk(
  'holiday/get',
  async (
    id: Holiday['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Holiday> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'holiday/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllHolidaysQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Holiday> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayServices.getAll(
        {
          page,
          limit,
          search,
        },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'holiday/post',
  async (
    data: Omit<Holiday, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Holiday> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'holiday/put',
  async (
    data: AtLeast<Holiday, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Holiday> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'holiday/remove',
  async (
    id: Holiday['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Holiday> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
