import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { Typography } from '@material-tailwind/react';
import { Link, useParams } from 'react-router-dom';
import ClientManagerListRow from './ClientManagersListRow';
import TextWithIcons from '../../molecules/TextWithIcons';
import useDateTime from '../../../hooks/utils/useDateTime';
import consoleLog from '../../../utils/consoleLog';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import ClientManagerDynamicForm from './ClientManagerDynamicForm';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import useClient from '../../../hooks/clients/useClient';
import ClientManager from '../../../store/constants/clients/client-manager.interface';
import useClientManagers from '../../../hooks/clients/useClientManagers';
import useClients from '../../../hooks/clients/useClients';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions = '' | 'department' | 'role' | 'employee' | 'dateStart' | 'dateEnd';

const ClientManagersList: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const { currentClient: client, loading } = useClient(id as string);

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(false);

  const { getAll } = useClients();
  const { remove } = useClientManagers();

  const { getDateTime, getDateAsMoment } = useDateTime();

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const clientManagers = client?.managers
    ?.filter((manager) => {
      if (!manager.dateEnd) {
        return true;
      }

      const dateEnd = new Date(manager.dateEnd);

      return dateEnd > new Date();
    })
    .sort((a: ClientManager, b: ClientManager) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'department') {
        if (!a.department) return -sortOrder;
        if (!b.department) return sortOrder;

        return sortOrder * a.department.name.localeCompare(b.department.name);
      }

      if (sortOption === 'role') {
        if (!a.employmentRole) return -sortOrder;
        if (!b.employmentRole) return sortOrder;

        return sortOrder * a.employmentRole.name.localeCompare(b.employmentRole.name);
      }

      if (sortOption === 'employee') {
        if (!a.userInformation) return -sortOrder;
        if (!b.userInformation) return sortOrder;

        return sortOrder * a.userInformation.fullName.localeCompare(b.userInformation.fullName);
      }

      if (sortOption === 'dateStart') {
        return (
          sortOrder *
          getDateAsMoment(new Date(a.dateStart)).diff(getDateAsMoment(new Date(b.dateStart)))
        );
      }
      if (sortOption === 'dateEnd') {
        if (!a.dateEnd) return -sortOrder;
        if (!b.dateEnd) return sortOrder;

        return (
          sortOrder *
          getDateAsMoment(new Date(a.dateEnd)).diff(getDateAsMoment(new Date(b.dateEnd)))
        );
      }

      return sortOrder * (b.id - a.id);
    });

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  if (loading) return null;

  if (!client) return null;

  const handleDelete = async (manager: ClientManager) => {
    remove(manager).then(() => {
      getAll();
    });
  };

  return (
    <div className="client-managers-form-container space-y-10">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/clients">
          <Typography className="crumb">Clients</Typography>
        </Link>
        <Link to={`/admin/clients/${client.id}`}>
          <Typography className="crumb">{client.name}</Typography>
        </Link>
        <span className="current-crumb">Managers</span>
      </Breadcrumbs>
      <div className="client-managers">
        <div className="flex justify-between ">
          <Typography className="muiTypography--modifiedH4">Client Managers</Typography>
        </div>
        <div className="organisms-client-manager-list__list">
          <div className="client-manager-list-header table-header">
            <button
              type="button"
              onClick={() => sortByHeader('department', false)}
              className="flex items-center gap-3"
            >
              <TextWithIcons
                className="flex"
                variant="h6"
                iconRight={
                  sortOrderBy ? (
                    <Arrow
                      className={`sort-arrow-icon ${sortOption === 'department' ? '' : 'hidden'}`}
                    />
                  ) : (
                    <Arrow
                      className={`sort-arrow-icon ${
                        sortOption === 'department' ? 'sort-arrow-rotate-icon' : 'hidden'
                      }`}
                    />
                  )
                }
              >
                <div className="flex gap-5">Department</div>
              </TextWithIcons>
            </button>
            <button
              type="button"
              onClick={() => sortByHeader('role', false)}
              className="flex items-center gap-3"
            >
              <TextWithIcons
                className="flex"
                variant="h6"
                iconRight={
                  sortOrderBy ? (
                    <Arrow className={`sort-arrow-icon ${sortOption === 'role' ? '' : 'hidden'}`} />
                  ) : (
                    <Arrow
                      className={`sort-arrow-icon ${
                        sortOption === 'role' ? 'sort-arrow-rotate-icon' : 'hidden'
                      }`}
                    />
                  )
                }
              >
                <div className="flex gap-5">Role</div>
              </TextWithIcons>
            </button>
            <button
              type="button"
              onClick={() => sortByHeader('employee', false)}
              className="flex items-center gap-3"
            >
              <TextWithIcons
                className="flex"
                variant="h6"
                iconRight={
                  sortOrderBy ? (
                    <Arrow
                      className={`sort-arrow-icon ${sortOption === 'employee' ? '' : 'hidden'}`}
                    />
                  ) : (
                    <Arrow
                      className={`sort-arrow-icon ${
                        sortOption === 'employee' ? 'sort-arrow-rotate-icon' : 'hidden'
                      }`}
                    />
                  )
                }
              >
                <div className="flex gap-5">Employee</div>
              </TextWithIcons>
            </button>
            <button
              type="button"
              onClick={() => sortByHeader('dateStart', false)}
              className="flex items-center gap-3"
            >
              <TextWithIcons
                className="flex"
                variant="h6"
                iconRight={
                  sortOrderBy ? (
                    <Arrow
                      className={`sort-arrow-icon ${sortOption === 'dateStart' ? '' : 'hidden'}`}
                    />
                  ) : (
                    <Arrow
                      className={`sort-arrow-icon ${
                        sortOption === 'dateStart' ? 'sort-arrow-rotate-icon' : 'hidden'
                      }`}
                    />
                  )
                }
              >
                <div className="flex gap-5">Date start</div>
              </TextWithIcons>
            </button>
            <button
              type="button"
              onClick={() => sortByHeader('dateEnd', false)}
              className="flex items-center gap-3"
            >
              <TextWithIcons
                className="flex"
                variant="h6"
                iconRight={
                  sortOrderBy ? (
                    <Arrow
                      className={`sort-arrow-icon ${sortOption === 'dateEnd' ? '' : 'hidden'}`}
                    />
                  ) : (
                    <Arrow
                      className={`sort-arrow-icon ${
                        sortOption === 'dateEnd' ? 'sort-arrow-rotate-icon' : 'hidden'
                      }`}
                    />
                  )
                }
              >
                <div className="flex gap-5">Date end</div>
              </TextWithIcons>
            </button>
            <TextWithIcons variant="h6" className=" justify-self-center">
              <div className="flex gap-5">Actions</div>
            </TextWithIcons>
          </div>

          <ClientManagerDynamicForm client={client} />
          {clientManagers && clientManagers?.length !== 0 ? (
            <VariableSizeList
              itemCount={clientManagers.length}
              itemSize={getItemSize}
              height={window.innerHeight - 400}
              width="100%"
              className="no-scrollbars parent-container"
            >
              {({ index, style }) => (
                <ClientManagerListRow
                  data={clientManagers[index]}
                  className={`${index % 2 === 0 ? 'stripped' : ''}`}
                  style={style}
                  getDateTime={getDateTime}
                  onEditClick={() => consoleLog('EDIT CLIENT MANAGER')}
                  onDeleteClick={handleDelete}
                />
              )}
            </VariableSizeList>
          ) : (
            <EmptyListMessage />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientManagersList;
