import { useState } from 'react';
import useOnLoad from '../utils/useOnLoad';
import useUsers from './useUsers';
import useUserTeams from '../teams/useUserTeams';
import useUserPositionsAssignment from './useUserPositionsAssignment';

const useUser = (id: string) => {
  const {
    get,
    usersState: { users },
  } = useUsers();
  const [loading, setLoading] = useState(true);

  const { getUser: getUserTeams } = useUserTeams();
  const { getUsers: getUserPositions } = useUserPositionsAssignment();

  const userId = parseInt(id, 10);
  const currentUser = users.find((user) => user.id === userId);

  useOnLoad(() => {
    setLoading(true);
    get(userId).then((data) => {
      setLoading(false);
      if (data.payload.data.id) {
        getUserTeams(data.payload.data?.employment?.employeeNumber);
        getUserPositions(data.payload.data?.employment?.employeeNumber);
      }
    });
  });

  return {
    loading,
    currentUser,
  };
};

export default useUser;
