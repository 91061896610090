import React, { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import EmploymentStatus from '../../../../store/constants/employments/employment-status.interface';
import UserEmployment from '../../../../store/constants/employees/employee.interface';
import useUsers from '../../../../hooks/users/useUsers';
import SelectEmploymentStatus from '../../../atoms/dropdowns/SelectEmploymentStatus';
import SelectEmployee from '../../../atoms/dropdowns/SelectEmployee';
import AtLeast from '../../../../types/atleast';
import User from '../../../../store/constants/users/user.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import useUserPositionsAssignment from '../../../../hooks/users/useUserPositionsAssignment';
import { ReactComponent as PencilIcon } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/icons/check-solid.svg';

interface EmploymentInfoProps {
  isLoading?: boolean;
  user: User;
}

const toastPromiseMessages = {
  pending: 'Processing Employment Information',
  success: 'User Employment is successfully recorded.',
  error: 'An error was encountered during the process. Check the data and try Again.',
};

const EmploymentInfo: React.FC<EmploymentInfoProps> = ({ isLoading = true, user }) => {
  const { put } = useUsers();

  const { getUserPosition } = useUserPositionsAssignment();
  const userPosition = getUserPosition(user.employment?.employeeNumber);

  const [showButtonAction, setShowButtonAction] = useState(false);
  const [showInputAction, setShowInputAction] = useState(false);

  const [selectedEmploymentStatus, setSelectedEmploymentStatus] = useState<
    EmploymentStatus | undefined
  >({
    ...user.employment?.employmentStatus,
    value: user.employment?.employmentStatus?.id,
    label: user.employment?.employmentStatus?.name,
  } as EmploymentStatus);

  const [selectedReferredBy, setSelectedReferredBy] = useState<
    UserEmployment['referredBy'] | undefined
  >({
    ...user.employment?.referredBy,
    value: user.employment?.referredBy?.employeeNumber,
    label: user.employment?.referredBy?.userInformation.fullName,
  } as UserEmployment);

  const handleSubmit = async (data: AtLeast<User, 'employment'>) => {
    const values = {
      ...data,
      employment: {
        ...data.employment,
        employmentStatusId: selectedEmploymentStatus?.id,
        referredByEmployeeNumber: selectedReferredBy?.employeeNumber,
      },
    };

    if (user.id) {
      await toastNotification({
        action: put(values as User),
        onFulfilled: () => {
          setShowButtonAction(!showButtonAction);
          setShowInputAction(!showInputAction);
        },
        toastPromiseMessages,
      });
    }
  };

  const onEditClick = () => {
    setShowButtonAction(!showButtonAction);
    setShowInputAction(!showInputAction);
  };

  const onCancel = () => {
    setShowButtonAction(false);
    setShowInputAction(false);
  };

  if (isLoading) return null;

  return (
    <Formik
      initialValues={{
        id: user.id,
        employment: {
          ...user.employment,
          dateStart: user.employment?.dateStart ?? new Date(),
        } as UserEmployment,
      }}
      onSubmit={handleSubmit}
    >
      <Form className="rounded-xl shadow-xl user-update__form-wrapper">
        <div className="flex items-center mb-5">
          <div className="user-update__form-heading">
            <Typography variant="h4" className="user-update__form-title">
              Employment
            </Typography>
            <span className="user-update__form-sub-title">Information</span>
          </div>

          {!isLoading && (
            <div className="ml-auto">
              {!showButtonAction ? (
                <Tooltip content="Edit">
                  <IconButton
                    onClick={onEditClick}
                    size="sm"
                    className="bg-primary-gray rounded-none"
                  >
                    <PencilIcon className="w-5 h-5 stroke-white" />
                  </IconButton>
                </Tooltip>
              ) : (
                <div className="flex flex-row-reverse gap-2">
                  <Tooltip content="Cancel">
                    <IconButton
                      onClick={onCancel}
                      size="sm"
                      className="bg-primary-gray rounded-none"
                    >
                      <CloseIcon className="w-5 h-5 stroke-red-900" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Save">
                    <IconButton type="submit" size="sm" className="bg-primary-gray rounded-none">
                      <CheckIcon className="w-5 h-5 stroke-green-800" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-5">
          {/* <Field type="hidden" id="employment.id" name="employment.id" /> */}
          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.employeeNumber"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Employee Number
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="number"
                  id="employment.employeeNumber"
                  name="employment.employeeNumber"
                  placeholder="Employee Number"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                  required
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.employeeNumber || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.employeeNumber" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.emailWork"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Work Email
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="email"
                  id="employment.emailWork"
                  name="employment.emailWork"
                  placeholder="@awesomecx.com"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.emailWork || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.emailWork" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.employmentStatusId"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Employment Status
            </label>
            <div className="w-full">
              {showInputAction ? (
                <SelectEmploymentStatus
                  id="employment.employmentStatus"
                  name="employment.employmentStatus"
                  value={selectedEmploymentStatus}
                  onChange={(value) => setSelectedEmploymentStatus(value as EmploymentStatus)}
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {selectedEmploymentStatus?.name ??
                    (user.employment?.employmentStatus?.name || '- -')}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.employmentStatusId" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.dateStart"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Date Start
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="date"
                  id="employment.dateStart"
                  name="employment.dateStart"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                  required
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.dateStart || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.dateStart" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.dateEnd"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Date End
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="date"
                  id="employment.dateEnd"
                  name="employment.dateEnd"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <Typography className="capitalize ml-5 text-sm text-white" as={undefined}>
                  {user.employment?.dateEnd || '- -'}
                </Typography>
              )}
            </div>
            <ErrorMessage name="employment.dateEnd" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.previousEmployer"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Previous Employer
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="employment.previousEmployer"
                  name="employment.previousEmployer"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.previousEmployer || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.previousEmployer" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.referredBy"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Referred by
            </label>
            <div className="w-full">
              {showInputAction ? (
                <SelectEmployee
                  id="employment.referredBy"
                  name="employment.referredBy"
                  value={selectedReferredBy}
                  onChange={(value) => setSelectedReferredBy(value as UserEmployment)}
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {selectedReferredBy?.userInformation?.fullName || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.referredBy" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.referralRate"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Referral Rate
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="number"
                  id="employment.referralRate"
                  name="employment.referralRate"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.referralRate || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.referralRate" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="employment.signInBonus"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Sign-in Bonus
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="number"
                  id="employment.signInBonus"
                  name="employment.signInBonus"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.employment?.signInBonus || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="employment.signInBonus" component="div" />
          </div>

          {!showInputAction && (
            <div className="flex flex-col gap-2 mb-2">
              <label
                htmlFor="employment.nigthDiff"
                className="mt-2 mr-8 text-xs uppercase text-gray-600"
              >
                Night Differential
              </label>
              <div className="w-full">
                <span className="capitalize ml-5 text-sm text-white">
                  {userPosition?.employmentPosition?.isNdEligible === true ? 'Yes' : 'No'}
                </span>
              </div>
              <ErrorMessage name="employment.signInBonus" component="div" />
            </div>
          )}
        </div>
      </Form>
    </Formik>
  );
};

export default EmploymentInfo;
