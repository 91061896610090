import React from 'react';

const BillingExportTable = () => (
  <div className="border border-solid border-[#343434] rounded-lg shadow-lg">
    <div className="p-3 w-full bg-[#343434] rounded-t-lg flex items-center">
      <span className="text-gray-200 font-bold">Select Clients for export</span>
    </div>
    <div className="p-5">
      <div className="table p-2">
        <span className="text-gray-200 text-sm">table</span>
      </div>
    </div>
  </div>
);

export default BillingExportTable;
