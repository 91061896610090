import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import TransactionFeed from '../../constants/my/transaction-feed.interface';

export type GetAllTransactionFeedsQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: TransactionFeed['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<TransactionFeed>> =>
  apiFetch<ApiBaseResponse<TransactionFeed>>(`transactions/${id}`, { state })
    .then((response: ApiBaseResponse<TransactionFeed>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllTransactionFeedsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<TransactionFeed>> =>
  apiFetch<ApiGetAllBaseResponse<TransactionFeed>>(
    `transactions?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<TransactionFeed>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<TransactionFeed, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<TransactionFeed> | void> =>
  apiFetch<ApiBaseResponse<TransactionFeed>>('transactions', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<TransactionFeed>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: TransactionFeed,
  state: StateHeaders,
): Promise<ApiBaseResponse<TransactionFeed> | void> =>
  apiFetch<ApiBaseResponse<TransactionFeed>>(`transactions/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<TransactionFeed>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<TransactionFeed, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<TransactionFeed> | void> =>
  apiFetch<ApiBaseResponse<TransactionFeed>>(`transactions/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<TransactionFeed>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: TransactionFeed['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<TransactionFeed> | void> =>
  apiFetch<ApiBaseResponse<TransactionFeed>>(`transactions/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<TransactionFeed>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const isRead = async (
  data: TransactionFeed,
  state: StateHeaders,
): Promise<ApiBaseResponse<TransactionFeed> | void> =>
  apiFetch<ApiBaseResponse<TransactionFeed>>(`transactions/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<TransactionFeed>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  isRead,
} as const;
