import { useState } from 'react';
import useTeams from './useTeams';
import useOnLoad from '../utils/useOnLoad';

const useTeam = (id: string) => {
  const {
    get,
    teamsState: { teams },
  } = useTeams();
  const [loading, setLoading] = useState(true);

  const teamId = parseInt(id, 10);
  const currentTeam = teams.find((team) => team.id === teamId);

  useOnLoad(() => {
    if (currentTeam) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    get(teamId).then(() => {
      setLoading(false);
    });
  });

  return {
    loading,
    currentTeam,
  };
};

export default useTeam;
