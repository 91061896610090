import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useScheduleTypes from '../../../hooks/schedule/useScheduleTypes';
import EmploymentScheduleType from '../../../store/constants/employments/schedules/employment-schedule-type.interface';

export interface SelectEmploymentScheduleTypeProps
  extends Partial<AutoSearchSelectProps<EmploymentScheduleType>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectEmploymentScheduleType: React.FC<SelectEmploymentScheduleTypeProps> = ({
  id,
  name,
  placeholder = 'Select Employment Schedule Type',
  className = '',
  ...props
}) => {
  const { scheduleTypesState, getAll } = useScheduleTypes();

  useOnLoad(() => {
    getAll().then();
  });

  const scheduleTypes = scheduleTypesState.scheduleTypes
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((scheduleType) => ({
      ...scheduleType,
      value: scheduleType.id,
      label: `${scheduleType?.name}`,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-employment-schedule-type ${className}`}
      placeholder={placeholder}
      data={scheduleTypes}
    />
  );
};

export default SelectEmploymentScheduleType;
