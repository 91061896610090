import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog, DialogBody } from '@material-tailwind/react';
import ScheduleType from '../../../store/constants/employments/schedules/employment-schedule-type.interface';
import useScheduleTypes from '../../../hooks/schedule/useScheduleTypes';
import toastNotification from '../../../hooks/utils/toastNotification';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as Clock } from '../../../assets/images/icons/clock.svg';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import useOnLoad from '../../../hooks/utils/useOnLoad';

export interface ScheduleTypeFormProps {
  scheduleType?: ScheduleType;
  onClose: () => void;
}

const ScheduleTypeFormModal: React.FC<ScheduleTypeFormProps> = ({
  scheduleType = undefined,
  onClose,
}) => {
  const { post, put, getAll } = useScheduleTypes();

  const [scheduleTypeForm, setScheduleTypeForm] = useState<ScheduleType | undefined>();
  const [open, setOpen] = useState(false);
  // const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setScheduleTypeForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setScheduleTypeForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<ScheduleType>) => {
    // setLoading(true);
    if (scheduleTypeForm) {
      await toastNotification({
        action: put({
          ...scheduleTypeForm,
          ...values,
        } as Partial<ScheduleType>),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: async () => {
          await getAll();
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Schedule Type information is successfully updated.',
          error: 'Error on updating Schedule Type information.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as ScheduleType),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: async () => {
          await getAll();
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Schedule Type information is successfully posted.',
          error: 'Error on updating Schedule Type information.',
        },
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (scheduleType) {
      setOpen(true);
      setScheduleTypeForm({ ...scheduleType });
    }
  }, [scheduleType]);

  useOnLoad(() => {
    getAll().then();
  });

  /*
    To do
    @fixed scheduler put-request, always throws an error even it is successfull.
    
  */

  return (
    <>
      <button type="button" className="button button--state-default gap-2" onClick={handleOpen}>
        <Add className="w-5 h-5" />
        Add schedule type
      </button>

      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none py-7 px-6 scheduler-type-dialog"
      >
        <div className="mb-3 flex gap-2">
          <Clock className="w-8 h-auto scheduler-icon" />
          <h1 className="mt-1">Update Schedule</h1>
        </div>

        <DialogBody divider className="p-0">
          <div className="form-containers">
            <Formik initialValues={{ ...scheduleTypeForm }} onSubmit={handleSubmit}>
              <Form>
                <div className="mt-4 form-section">
                  <div className="flex flex-col justify-center w-full">
                    <div className="mb-4">
                      <label htmlFor="name" className="block mb-2">
                        Type Name
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Schedule type name"
                        className="w-full p-2 bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                      />
                      <ErrorMessage name="name" component="div" />
                    </div>

                    <div className="mb-4">
                      <label htmlFor="daysPerWeek" className="block mb-2">
                        Days per week
                      </label>
                      <Field
                        type="number"
                        min={1}
                        max={7}
                        id="daysPerWeek"
                        name="daysPerWeek"
                        className="w-full p-2 bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                        placeholder="1-7"
                        required
                      />
                      <ErrorMessage name="daysPerWeek" component="div" />
                    </div>

                    <div className="mb-4">
                      <label htmlFor="daysPerMonth" className="block mb-2">
                        Days per month
                      </label>
                      <Field
                        type="number"
                        min={1}
                        max={31}
                        id="daysPerMonth"
                        name="daysPerMonth"
                        className="w-full p-2 bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                        placeholder="1-31"
                        required
                      />
                      <ErrorMessage name="daysPerMonth" component="div" />
                    </div>

                    <div className="mb-4">
                      <label htmlFor="hours" className="block mb-2">
                        Hours
                      </label>
                      <Field
                        type="number"
                        min={1}
                        max={31}
                        id="hours"
                        name="hours"
                        className="w-full p-2 bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                        placeholder="1-24"
                        required
                      />
                      <ErrorMessage name="hours" component="div" />
                    </div>

                    <div className="mb-4">
                      <label htmlFor="breakLength" className="block mb-2">
                        Break
                      </label>
                      <Field
                        type="number"
                        min={1}
                        max={31}
                        id="breakLength"
                        name="breakLength"
                        className="w-full p-2 bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                        placeholder="1-24"
                        required
                      />
                      <ErrorMessage name="breakLength" component="div" />
                    </div>
                  </div>
                </div>
                {/* Buttons */}
                <div className="flex justify-between px-6 mt-1 loader-button-wrapper">
                  <div className="flex justify-center items-center">
                    <ButtonAction
                      type="submit"
                      className="flex justify-center items-center rounded-md shadow-lg loader-submit-button scheduler-type-submit"
                      // isLoading={loading}
                      hasSpinnerText={false}
                      isSubmit
                    />
                  </div>
                  <div className="flex justify-end w-full">
                    <button
                      type="button"
                      onClick={() => handleClose()}
                      className="p-2 scheduler-type-cancel"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ScheduleTypeFormModal;
