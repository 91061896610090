import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import User from '../../constants/users/user.interface';
import userServices, { GetAllUsersQueryParams } from '../../services/users/userServices';

const get = createAsyncThunk(
  'user/get',
  async (
    id: User['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<User> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getContact = createAsyncThunk(
  'user/getContact',
  async (
    id: User['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<AtLeast<User, 'contact' | 'address'>> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.getContact(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getEmployment = createAsyncThunk(
  'user/getEmployment',
  async (
    id: User['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<AtLeast<User, 'employment'>> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.getEmployment(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getBank = createAsyncThunk(
  'user/getBank',
  async (
    id: User['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<AtLeast<User, 'bank'>> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.getBank(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAccountNumber = createAsyncThunk(
  'user/getAccountNumber',
  async (
    id: User['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<AtLeast<User, 'accountNumber'>> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.getAccountNumber(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getTeams = createAsyncThunk(
  'user/getTeams',
  async (
    id: User['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<User> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'user/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllUsersQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<User> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'user/post',
  async (
    data: Omit<User, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<User> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'user/put',
  async (
    data: AtLeast<User, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<User> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'user/remove',
  async (
    id: User['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<User> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default {
  get,
  getContact,
  getEmployment,
  getBank,
  getAccountNumber,
  getAll,
  getTeams,
  post,
  put,
  remove,
} as const;
