import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import { generateDatePeriodOptions } from '../../../utils/datePeriod';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';

export type SelectMonthRangeType = {
  value: string;
  label: string;
};

interface SelectMonthRangeProps<T> extends Partial<AutoSearchSelectProps<T>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  onSelect: (value: SelectMonthRangeType | SelectMonthRangeType[]) => void;
}

const SelectMonthRange: React.FC<
  SelectMonthRangeProps<SelectMonthRangeType | SelectMonthRangeType[]>
> = ({ id, name, placeholder = 'Select a date range', className = '', onSelect, ...props }) => {
  const options = generateDatePeriodOptions();

  const normalizeDate = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  useOnLoad(() => {
    if (options.length > 0) {
      const currentDate = normalizeDate(new Date());

      const defaultOption = options.find(({ value }) => {
        const [start, end] = value.split(',');
        const startDate = normalizeDate(new Date(start));
        const endDate = normalizeDate(new Date(end));

        return currentDate >= startDate && currentDate <= endDate;
      });

      if (defaultOption) onSelect(defaultOption);
    }
  });

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`modal-select ${className}`}
      placeholder={placeholder}
      data={options}
    />
  );
};

export default SelectMonthRange;
