import { useAppDispatch, useAppSelector } from '../../store/store';
import attendanceActions from '../../store/actions/attendances/attendanceActions';
import {
  attendancesReducerActions,
  AttendanceState,
} from '../../store/reducers/attendances/attendancesReducer';
import EmployeeAttendance from '../../store/constants/employees/attendances/employee-attendance.interface';
import EmployeeOvertime from '../../store/constants/employees/attendances/employee-overtime.interface';
import EmployeeTimeOutDetail from '../../store/constants/employees/attendances/employee-Timeout-detail.interface';
import AtLeast from '../../types/atleast';
import {
  ExportAttendanceQueryParams,
  GetAllAttendanceQueryParams,
} from '../../store/services/attendances/attendanceServices';

const useAttendance = () => {
  const dispatch = useAppDispatch();
  const attendanceState: AttendanceState = useAppSelector((state) => state.attendances);

  const resetAttendanceState = () => {
    dispatch(attendancesReducerActions.resetAttendanceState());
  };

  const setAttendanceState = (state: Partial<AttendanceState>) => {
    dispatch(attendancesReducerActions.setAttendanceState(state));
  };

  const get = async (id: EmployeeAttendance['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.getAll({}));

  const getAllMyAttendance = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.getAllMyAttendance({}));

  const getMyTimeInDetail = attendanceState.myAttendance?.employeeAttendanceDetails;

  const post = async (data: Omit<EmployeeAttendance, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.post(data));

  const remove = async (id: EmployeeAttendance['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.remove(id));

  const timeIn = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.timeIn());

  const timeBreak = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.timeBreak());

  const timeOut = async (data: Partial<EmployeeTimeOutDetail>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.timeOut(data));

  const underTime = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.underTime());

  const overtimeTimeIn = async (data: Partial<EmployeeOvertime>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.overtimeTimein(data));

  const overtimeTimeOut = async (data: AtLeast<EmployeeOvertime, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.overtimeTimeOut(data));

  const myOvertimeRemove = async (id: EmployeeOvertime['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.myOvertimeRemove(id));

  const getAllMyOvertimes = async (params = {} as GetAllAttendanceQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.getAllMyOvertimes(params));

  const exportAttendance = async (params = {} as ExportAttendanceQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.exportAttendance(params));
  const getMyCurrentOvertime = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(attendanceActions.getMyCurrentOvertime());

  return {
    resetAttendanceState,
    attendanceState,
    setAttendanceState,
    get,
    getMyTimeInDetail,
    getMyCurrentOvertime,
    getAll,
    post,
    remove,
    timeIn,
    timeOut,
    timeBreak,
    underTime,
    overtimeTimeIn,
    overtimeTimeOut,
    myOvertimeRemove,
    getAllMyAttendance,
    getAllMyOvertimes,
    exportAttendance,
  };
};

export default useAttendance;
