import React from 'react';

const BillingTitlesTable = () => (
  <div className="w-full list-of-reports mt-5">
    <div className="border border-solid border-[#343434] rounded-lg shadow-lg">
      <div className="p-3 w-full bg-[#343434] rounded-t-lg flex items-center">
        <span className="text-gray-200 font-bold">Manage Employee Billing Titles</span>
        <div className="ml-auto flex gap-5">
          <button
            type="button"
            className="bg-[#f05825] py-1 px-10 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg text-sm"
            disabled
          >
            Manage Titles
          </button>
          <button
            type="button"
            className="bg-[#f05825] py-1 px-10 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg text-sm"
            disabled
          >
            Manage Alias
          </button>
        </div>
      </div>
      <div className="p-5">
        <div className="table p-2">
          <span className="text-gray-200 text-sm">table</span>
        </div>
      </div>
    </div>
  </div>
);

export default BillingTitlesTable;
