import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Bank from '../../constants/banks/bank.interface';
import bankServices, { GetAllBanksQueryParams } from '../../services/banks/bankServices';

const get = createAsyncThunk(
  'bank/get',
  async (
    id: Bank['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Bank> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await bankServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'bank/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllBanksQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Bank> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await bankServices.getAll(
        {
          page,
          limit,
          search,
        },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'bank/post',
  async (
    data: Omit<Bank, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Bank> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await bankServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'bank/put',
  async (
    data: AtLeast<Bank, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Bank> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await bankServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'bank/remove',
  async (
    id: Bank['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Bank> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await bankServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
