import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentTypeServices, {
  GetAllEmploymentTypeQueryParams,
} from '../../services/employments/employmentTypeServices';
import EmploymentType from '../../constants/employments/employment-type.interface';

const get = createAsyncThunk(
  'employmentType/get',
  async (
    id: EmploymentType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await EmploymentTypeServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'employmentType/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllEmploymentTypeQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmploymentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await EmploymentTypeServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'employmentType/post',
  async (
    data: Omit<EmploymentType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await EmploymentTypeServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'employmentType/put',
  async (
    data: AtLeast<EmploymentType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await EmploymentTypeServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'employmentType/remove',
  async (
    id: EmploymentType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await EmploymentTypeServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
