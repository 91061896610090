import React from 'react';

const LeaveSkeletalLoader = () => (
  <div className="pl-[33rem] pt-6 flex w-full h-full skeleton-leave-loader">
    <div className="main-skeleton">
      <div className="h-full w-full px-10 py-6 skeleton-element">
        {/* title */}
        <div className="w-full flex justify-center">
          <div className="mt-4 skeleton-title" />
        </div>
        {/* credits */}
        <div className="w-full flex items-center mt-4 gap-2">
          <div className="rounded-full skeletal-circle" />
          <div className="skeletal-small" />
        </div>
        {/* body */}
        <div className="w-full flex gap-6">
          {/* first */}
          <div className="mt-8 first-skeletal-wrapper">
            <div className="skeletal-field" />
            <div className="w-6/12 skeletal-field" />
            <div className="w-6/12 skeletal-field" />
          </div>
          {/* second */}
          <div className="mt-8 second-skeletal-wrapper">
            <div className="skeletal-field" />
            <div className="skeletal-field" />
            <div className="w-3/4 skeletal-field" />
            <div className="skeletal-huge-field" />
            <div className="w-full flex justify-end">
              <div className="w-1/4 button-skeletal" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="right-sidebar-skeleton px-10">
      <div className="h-full w-full skeleton-element">
        <div className="w-full flex items-center pt-5 px-4 justify-between">
          <div className="skeletal-medium" />
          <div className="rounded-full skeletal-circle" />
        </div>
      </div>
    </div>
  </div>
);

export default LeaveSkeletalLoader;
