import React, { useMemo } from 'react';
import { Table, ConfigProvider } from 'antd';
import { Spinner } from '@material-tailwind/react';
import type { SwitchStates } from '../PayrollToolOptions';
import getViewPayslipColumns from './PayrollViewPayslipTableColumns';
import { ReactComponent as Money } from '../../../../../assets/images/icons/money.svg';
import usePayrolls from '../../../../../hooks/payroll/usePayrolls';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import useUserEmployments from '../../../../../hooks/users/useUserEmployments';
import useLoading from '../../../../../hooks/utils/useLoading';

interface PayrollViewPayslipTableProps {
  switchStates?: SwitchStates;
}

const showTotal = (totalCount: number, range: [number, number]) => (
  <div className="ant-custom-show-total">{`Displaying ${range[0]}-${range[1]} of ${totalCount} items`}</div>
);

const PayrollViewPayslipTable: React.FC<PayrollViewPayslipTableProps> = ({ switchStates = {} }) => {
  const { isLoading, setLoading } = useLoading();
  const { payrollsState } = usePayrolls();
  const { getUserEmploymentByEmployeeNumber } = useUserEmployments();
  // @TODO commented right click functionality
  //   const [visible, setVisible] = useState(false);
  //   const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  //   const [selectedRowData, setSelectedRowData] = useState<Partial<Payslip> | null>(null);

  //   const handleMenuClose = () => setVisible(false);

  const handleContextMenu = (
    e: React.MouseEvent,
    // @TODO commented right click functionality
    // rowData: Partial<Payslip>
  ) => {
    if (e.button === 2) {
      e.preventDefault();
      // @TODO commented right click functionality
      //   setVisible(true);
      //   setMenuPosition({ top: e.clientY, left: e.clientX });
      //   setSelectedRowData(rowData);
    }
  };

  const viewPayslipColumns = useMemo(
    () => getViewPayslipColumns(switchStates, getUserEmploymentByEmployeeNumber),
    // eslint-disable-next-line
    [switchStates],
  );
  const tableData = useMemo(
    () => payrollsState.payslips.map((payslip) => ({ ...payslip, key: payslip.id })),
    [payrollsState.payslips],
  );

  const titleContent = useMemo(
    () => (
      <div className="flex items-center justify-between px-5">
        <div className="flex items-center gap-2">
          <Money className="w-5 h-auto" />
          <span className="capitalize">View Payslips</span>
        </div>
      </div>
    ),
    [],
  );

  useOnLoad(() => {
    setLoading(true);

    // @TODO commented right click functionality
    // window.addEventListener('click', handleMenuClose);
    // return () => {
    //   window.removeEventListener('click', handleMenuClose);
    // };
    setLoading(false);
  });

  return (
    <div className="px-2 payroll-container-parent">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#3e3e3e !important',
            },
          },
        }}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-40">
            <span className="text-md text-white">No payslip data.</span>
          </div>
        )}
      >
        <Table
          loading={{
            spinning: isLoading.isLoading,
            indicator: <Spinner height="1rem" color="deep-orange" />,
          }}
          className="payroll-table-content"
          onRow={() =>
            // @TODO commented right click functionality
            // rowData: Partial<Payslip>
            ({
              onContextMenu: (event) => {
                event.preventDefault();
                handleContextMenu(
                  event,
                  // @TODO commented right click functionality
                  // , rowData
                );
              },
            })
          }
          columns={viewPayslipColumns}
          dataSource={tableData}
          size="small"
          tableLayout="auto"
          scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
          title={() => titleContent}
          pagination={{
            position: ['bottomRight'],
            defaultPageSize: 20,
            showTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50'],
          }}
        />
      </ConfigProvider>
      {/* @TODO commented right click functionality */}
      {/* {visible && (
        <div
          className="context-menu"
          style={{
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
            position: 'fixed',
            zIndex: 9999,
          }}
        >
          <PayrollTableContextMenu
            handleMenuClose={handleMenuClose}
            record={selectedRowData as Payslip}
          />
        </div>
      )} */}
    </div>
  );
};

export default PayrollViewPayslipTable;
