import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import TextWithIcons from '../../molecules/TextWithIcons';
import SearchSvg from '../../../assets/images/icons/search.svg';
import LoanType from '../../../store/constants/loans/loan-type.interface';
import LoanTypeFormModal from './LoanTypeFormModal';
import useHolidayTypes from '../../../hooks/loans/useLoanTypes';
import consoleLog from '../../../utils/consoleLog';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import LoanTypeListRow from './LoanTypeListRow';

const LoanTypeList: React.FC = () => {
  const [selectedLoanType, setSelectedLoanType] = useState<LoanType | undefined>();
  const [searchValue, setSearchValue] = useState('');
  const { loanTypesState, getAll, remove } = useHolidayTypes();

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (loan: LoanType) => {
    setSelectedLoanType(loan);
  };

  const onDeleteClick = async (loan: LoanType) => {
    remove(loan.id).then((r) => {
      consoleLog('DELETE LOAN TYPE', r);
    });
  };

  const onClose = () => setSelectedLoanType(undefined);

  const itemSizes: {
    [key: number]: number;
  } = {};

  const loans = loanTypesState.loanTypes;
  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full h-full">
      <div className="max-w-[100%]">
        <div className="flex justify-between items-center pb-5">
          <LoanTypeFormModal
            className="w-40 rounded-lg shadow-lg muiButton--primary"
            loanType={selectedLoanType}
            onClose={onClose}
          />
          <div className="relative w-60">
            <input
              onKeyDown={onSearchKeyDown}
              type="text"
              className="search-body__input search-bar"
              placeholder="Search loan type"
              onChange={handleSearch}
            />
            <img
              style={{ filter: 'invert(0) invert(1)' }}
              className="search-body__icon"
              src={SearchSvg}
              alt="Search for loan"
            />
          </div>
        </div>
        <div className="p-2 border-b-2 loan-type-list-header header-items text-slate-600">
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Loan Type</div>
          </TextWithIcons>
          <TextWithIcons className="justify-self-center" variant="h6">
            <div className="flex gap-5">Internal</div>
          </TextWithIcons>
          <TextWithIcons className="justify-self-center" variant="h6">
            <div className="flex gap-5">Indefinite</div>
          </TextWithIcons>
          <TextWithIcons className="justify-self-center" variant="h6">
            <div className="flex gap-5">Action</div>
          </TextWithIcons>
        </div>
        <VariableSizeList
          itemCount={loans.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) =>
            loans[index] && (
              <LoanTypeListRow
                data={loans[index]}
                className={`${index % 2 === 0 ? 'stripped' : ''}`}
                style={style}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            )
          }
        </VariableSizeList>
      </div>
    </div>
  );
};

export default LoanTypeList;
