import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentPositionRate from '../../constants/employments/positions/employment-position-rate.interface';

export type GetAllPositionRatesQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: EmploymentPositionRate['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPositionRate>> =>
  apiFetch<ApiBaseResponse<EmploymentPositionRate>>(`employments/positions/rates/${id}`, { state })
    .then((response: ApiBaseResponse<EmploymentPositionRate>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllPositionRatesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmploymentPositionRate>> =>
  apiFetch<ApiGetAllBaseResponse<EmploymentPositionRate>>(
    `employments/positions/rates?limit=${limit}&page=${page}${
      search !== '' ? `&search=${search}` : ''
    }`,
    {
      state,
    },
  )
    .then((response: ApiGetAllBaseResponse<EmploymentPositionRate>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<EmploymentPositionRate, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPositionRate> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPositionRate>>('employments/positions/rates', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmploymentPositionRate>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: EmploymentPositionRate,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPositionRate> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPositionRate>>(`employments/positions/rates/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmploymentPositionRate>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<EmploymentPositionRate, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPositionRate> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPositionRate>>(`employments/positions/rates/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmploymentPositionRate>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: EmploymentPositionRate['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPositionRate> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPositionRate>>(`employments/positions/rates/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<EmploymentPositionRate>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
