import React, { useMemo, useState } from 'react';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import toastNotification from '../../../../../hooks/utils/toastNotification';
import ButtonAction from '../../../../atoms/buttons/ButtonAction';
import { ExpectedAttendanceTime } from '../../../../../store/reducers/attendances/attendancesReducer';
import useTimer from '../../../../../hooks/utils/useTimer';
import { timerToTimeString } from '../../../../../utils/convertTimes';
import formatTimeDuration from '../../../../../utils/formatTimeDuration';
import UserAttendanceModal from '../UserAttendanceModal';
import useTheme from '../../../../../hooks/utils/useTheme';

interface UnderTimeProps {
  handleOnClose?: () => void;
  expectedTimeOut: ExpectedAttendanceTime;
}

export interface UndertimeFormValues {
  id?: number;
  reason: string;
  category: string;
}

const UserOvertimePast: React.FC<UnderTimeProps> = ({
  expectedTimeOut,
  handleOnClose = () => {},
}) => {
  const { theme } = useTheme();
  const { timeOut, getMyTimeInDetail, overtimeTimeIn } = useAttendance();

  const [isLoading, setIsLoading] = useState<{ overtime: boolean; timeout: boolean }>({
    overtime: false,
    timeout: false,
  });

  // if expected time out is changed, the parsed date will be updated
  let parsedDate = useMemo(() => {
    if (!expectedTimeOut.date) return null;
    const [datePart, timePart] = expectedTimeOut.date.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hour, minute, second);
  }, [expectedTimeOut.date]);

  // duration of overtime is 3 hours by default
  const { hours, minutes, seconds } = useTimer({
    otDuration: 10800,
    initialTime: parsedDate || undefined,
  });

  const duration = timerToTimeString(hours, minutes, seconds);

  // Timeout function
  const handleTimeoutClick = async () => {
    setIsLoading((prevState) => ({ ...prevState, timeout: true }));
    await toastNotification({
      action: timeOut({}),
      onSuccess: () => {
        parsedDate = null;
        handleOnClose();
      },
      onError: () => {},
      onFulfilled: () => {
        setIsLoading((prevState) => ({ ...prevState, timeout: false }));
      },
      toastPromiseMessages: {
        pending: 'Processing...',
        success: 'User has successfully timed out.',
        error: 'Error submitting timeout.',
      },
    });
  };

  // Timeout with past overtime action
  const handleOvertimeClick = async () => {
    setIsLoading((prevState) => ({ ...prevState, overtime: true }));

    await toastNotification({
      action: overtimeTimeIn({
        overtimeTimeIn: parsedDate || undefined,
        overtimeTimeOut: new Date(),
        duration,
      }),
      onSuccess: () => {
        timeOut({});
        parsedDate = null;
        handleOnClose();
      },
      onError: () => {},
      onFulfilled: () => {
        setIsLoading((prevState) => ({ ...prevState, overtime: false }));
      },
      toastPromiseMessages: {
        pending: 'Processing...',
        success: `Overtime submitted for ${duration}.`,
        error: 'Error submitting overtime.',
      },
    });
  };

  return (
    <UserAttendanceModal
      heading={
        <>
          <h1 className={`${theme}`}>
            You are currently{' '}
            <span
              className={`${
                getMyTimeInDetail?.isLate
                  ? 'dialog-prompt-sub-header__text-late'
                  : 'dialog-prompt-sub-header__text-ontime'
              }`}
            >
              Timed in
            </span>
            .
          </h1>
          <h1>
            {`${formatTimeDuration({
              hours,
              minutes,
              seconds,
            })}`}{' '}
            past your shift.
          </h1>
        </>
      }
    >
      <div className="flex items-center justify-center gap-6">
        <ButtonAction
          className="action-button__submit"
          text="Time out"
          hasSpinnerText={false}
          disabled={isLoading.overtime}
          isLoading={isLoading.timeout}
          onClick={handleTimeoutClick}
        />

        {minutes > 2 && (
          <ButtonAction
            className="action-button__submit"
            text="Overtime"
            hasSpinnerText={false}
            disabled={isLoading.timeout}
            isLoading={isLoading.overtime}
            onClick={handleOvertimeClick}
          />
        )}
      </div>
    </UserAttendanceModal>
  );
};

export default UserOvertimePast;
