import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface DialogBoxProps {
  title?: string;
  content?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
}

export interface ModalsState {
  dialogBox?: string;
  dialogBoxProps?: DialogBoxProps;
}

const initialState: ModalsState = {
  dialogBox: undefined,
  dialogBoxProps: undefined,
};

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    resetModalsState: () => ({
      ...initialState,
    }),
    setModalsState: (state, action: PayloadAction<ModalsState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { resetModalsState, setModalsState } = modalsSlice.actions;
export default modalsSlice.reducer;
