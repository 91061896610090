import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import HolidayType from '../../../store/constants/holidays/holiday-type.interface';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';

export interface HolidayListRowProps {
  regularHolidayCount: number;
  className?: string;
  style: React.CSSProperties;
  data: HolidayType;
  onEditClick: (holiday: HolidayType) => void;
  onDeleteClick: (holiday: HolidayType) => Promise<unknown>;
}

const HolidayListTypeRow: React.FC<HolidayListRowProps> = ({
  className = '',
  data,
  onEditClick,
  onDeleteClick,
  regularHolidayCount = 1,
  ...props
}: HolidayListRowProps) => {
  const deleteMessage = {
    pending: 'Pending',
    success: `Holiday Type ${data.name} is successfully deleted.`,
    error: 'Error on deleting Holiday Type.',
  };

  // @TODO
  // 1. Editing data not working error
  // 2. Delete not working

  return (
    <div
      {...props}
      className={`${className} user-holiday-type-data table-list-data flex items-center`}
    >
      <div>{data.name}</div>
      <div>{data.ratePercentage}</div>

      <div className="space-x-6 action-buttons justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
        </Tooltip>
        {data.ratePercentage === 100 && regularHolidayCount === 1 ? (
          <span className="w-5 h-auto" />
        ) : (
          <DeleteConfirmationBanner
            data={data}
            label={data.name}
            message={deleteMessage}
            onDeleteConfirm={onDeleteClick}
          />
        )}
      </div>
    </div>
  );
};

export default HolidayListTypeRow;
