import React, { useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-datepicker';
import { ErrorMessage, Form, Formik } from 'formik';
import { Dialog, DialogHeader, DialogBody, IconButton } from '@material-tailwind/react';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';

interface GenerateAlphalistProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const GenerateAlphalist: React.FC<GenerateAlphalistProps> = ({ isOpen, setOpen }) => {
  const [selectedYear, setSelectedYear] = useState<string>('');

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    moment().startOf('year').toDate(),
    new Date(),
  ]);

  return (
    <Dialog
      size="xs"
      open={isOpen}
      handler={setOpen}
      className="bg-[#343434] shadow-none p-10 modal-size"
    >
      <div className="m-2 p-0 absolute flex top-0 right-0">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={() => setOpen(false)}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex gap-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1}
          stroke="white"
          className="modal-icons"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>

        <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
          Generate alphalist
        </DialogHeader>
      </div>
      <DialogBody divider className="p-0">
        <div className="">
          <div className="">
            <Formik initialValues={{}} onSubmit={() => {}}>
              <Form className="">
                {/* FIRST SECTION */}
                <div className="mt-4 mb-4 form-section">
                  <div className="flex flex-col justify-center w-full">
                    <div className="mb-6">
                      <label htmlFor="company" className="modal-labels block mb-2 text-gray-200">
                        Date range
                      </label>
                      <div className="datePicker-main-parent">
                        <DateRangePicker
                          className="rounded-lg datepicker-list-layout datepicker-list-date bg-[#2c2b2a]"
                          startDate={dateRange[0]}
                          endDate={dateRange[1]}
                          onChange={(update) => {
                            setDateRange(update);
                          }}
                          selectsRange
                          isClearable
                          placeholderText="Select Date Range..."
                        />
                      </div>
                      <ErrorMessage name="company" component="div" />
                    </div>

                    <select
                      name="employeeStatus"
                      id="employeeStatus"
                      className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
                      value={selectedYear}
                      onChange={(value) => setSelectedYear(value.target.value)}
                    >
                      <option value="active">Active</option>
                      <option value="resigned">Resigned</option>
                    </select>
                  </div>
                </div>
                {/* Buttons */}
                <div className="flex space-x-2 w-max">
                  <div className="flex justify-center w-full">
                    <button
                      type="button"
                      className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                    >
                      Download
                    </button>
                  </div>
                  <div className="flex justify-center w-max">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
};
export default GenerateAlphalist;
