import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spinner, Typography } from '@material-tailwind/react';
import { VariableSizeList } from 'react-window';
import Team from '../../../store/constants/teams/team.interface';
import useUserTeams from '../../../hooks/teams/useUserTeams';
import Breadcrumbs from '../Breadcrumbs';
import consoleLog from '../../../utils/consoleLog';
import TextWithIcons from '../TextWithIcons';
import useUser from '../../../hooks/users/useUser';
import EmptyListMessage from '../../atoms/EmptyListMessage';

import UserEmploymentTeamRow from './Row/UserEmploymentTeamRow';
import EmploymentRole from '../../../store/constants/employments/roles/employment-role.interface';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import UserEmploymentTeam from '../../../store/constants/employees/teams/employee-team.interface';
import type { EmployeeTeamTransferReason } from '../../../store/constants/employees/teams/employee-team-transfer-reason';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import toastNotification from '../../../hooks/utils/toastNotification';
import UserTeamAssignmentTestForm from './UserTeamAssignmentTestForm';

export const initialFormValues = {
  id: 0,
  employeeNumber: undefined,
  employmentRole: undefined,
  team: undefined,
  immediateSupervisor: undefined,
  dateStart: undefined,
  dateEnd: undefined,
  transferReasonId: undefined,
};

const UserTeamAssignment: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const {
    userEmploymentsStates: { transferReasons },
  } = useUserEmployments();
  const { currentUser: user, loading } = useUser(id as string);

  const { getUserTeams, getAll, remove, put, post } = useUserTeams();

  const [formError, setFormError] = useState('');
  const [selectedUser, setSelectedUser] = useState<UserEmployment | null>();
  const [selectedTeam, setSelectedTeam] = useState<Team | null>();
  const [selectedEmploymentRole, setSelectedEmploymentRole] = useState<EmploymentRole | null>();
  const [selectedTeamTransferReason, setSelectedTeamTransferReason] =
    useState<EmployeeTeamTransferReason | null>();

  const [selectedUserEmploymentTeam, setSelectedUserEmploymentTeam] = useState<
    UserEmploymentTeam | undefined
  >();

  const handleReset = () => {
    setSelectedTeam(null);
    setSelectedEmploymentRole(null);
    setSelectedUser(null);
    setSelectedTeamTransferReason(null);
  };

  const handleSubmit = async (values: UserEmploymentTeam) => {
    setFormError('');

    const userEmploymentTeamId = selectedUserEmploymentTeam?.id;

    if (userEmploymentTeamId) {
      const data = {
        ...values,
        id: userEmploymentTeamId,
        teamId: selectedTeam?.id,
        team: selectedTeam?.name,
        employmentRole: selectedEmploymentRole?.name,
        employmentRoleId: selectedEmploymentRole?.id,
        teamLeader: selectedUser?.id,
        teamLeaderEmployeeNumber: selectedUser?.employeeNumber,
        transferReasonId: selectedTeamTransferReason?.id,
      };

      await toastNotification({
        action: put(data as UserEmploymentTeam),
        onSuccess: () => {
          handleReset();
          setFormError('');
        },
        onError: (response) => {
          setFormError(response.payload.message);
        },
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: `Updating ${user?.firstName} ${user?.lastName}...`,
          success: `${selectedTeam?.name} as ${selectedEmploymentRole?.name} is successfully updated.`,
          error: `Error updating ${user?.firstName} ${user?.lastName}! Try again.`,
        },
      });
    } else {
      const data = {
        ...values,
        employeeNumber: user?.employment?.employeeNumber,
        teamId: selectedTeam?.id,
        team: selectedTeam,
        employmentRole: selectedEmploymentRole?.name,
        employmentRoleId: selectedEmploymentRole?.id,
        teamLeader: selectedUser?.id,
        teamLeaderEmployeeNumber: selectedUser?.employeeNumber,
        transferReasonId: selectedTeamTransferReason?.id,
      };

      await toastNotification({
        action: post(data as UserEmploymentTeam),
        onSuccess: () => {
          handleReset();
          setFormError('');
        },
        onError: (response) => {
          setFormError(response.payload.message);
        },
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: `Adding ${user?.firstName} ${user?.lastName}...`,
          success: `${user?.firstName} ${user?.lastName} is added as ${selectedEmploymentRole?.name} in ${selectedTeam?.name} is successfully added.`,
          error: `Error adding ${user?.firstName} ${user?.lastName}! Try again.`,
        },
      });
    }
    handleReset();
  };

  const onEditClick = (team: UserEmploymentTeam) => {
    setSelectedUserEmploymentTeam(team);

    setSelectedTeam({
      ...team?.team,
      value: team?.team?.id,
      label: team?.team?.name,
    } as Team);

    setSelectedEmploymentRole({
      ...team?.employmentRole,
      value: team?.employmentRole?.id,
      label: team?.employmentRole?.name,
    } as EmploymentRole);

    setSelectedUser({
      ...team?.teamLeader,
      value: team?.teamLeader?.employeeNumber,
      label: team.teamLeader?.userInformation.fullName,
    } as UserEmployment);

    const userEmploymentTransferReasons = transferReasons
      .filter((transferReason) => transferReason.id === team.transferReasonId)
      .map((transferReason: EmployeeTeamTransferReason) => ({
        ...transferReason,
        value: transferReason.id,
        label: transferReason.name,
      }));

    setSelectedTeamTransferReason({
      ...userEmploymentTransferReasons[0],
    });

    consoleLog('SELECTED REASONS:', userEmploymentTransferReasons);
  };

  const onDeleteClick = async (team: UserEmploymentTeam) => remove(team.id);

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 80; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  const employmentTeam = getUserTeams(user?.employment?.employeeNumber);

  const countEmployeeTeam = employmentTeam ? employmentTeam.length : 0;
  const listUserTeam = employmentTeam.length ? employmentTeam : [];

  useOnLoad(() => {
    getAll({
      employeeNumber: user?.employment?.employeeNumber,
    }).then();
  });

  return (
    <div className="w-full">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/user/manage">
          <Typography className="crumb">Users</Typography>
        </Link>

        <span className="crumb">{user?.employment?.username}</span>
        <Link to="/admin/teams">
          <span className="crumb">Teams</span>
        </Link>

        <span className="current-crumb capitalize">
          {user?.firstName} {user?.lastName}
        </span>
      </Breadcrumbs>
      <div className="employee-incase-of-emergency-info-form p-4 border border-[#2e2d2c] bg-[#252423] border-solid rounded-xl shadow-2xl mr-5">
        <Typography variant="h4" className="text-[#f05825] mb-5 w-full">
          Team Assignment
          <hr className="rounded-md h-0.8 opacity-10 mt-3" />
        </Typography>
        <div className="flex justify-between p-5 mx-auto form-section">
          <div className="w-3/4 font-bold first-section text-[#f05825] mr-10">
            <div className="p-2 border-b-2 grid grid-cols-7 header-items">
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Team</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Role In The Team</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Team Leader</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Date Start</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Date End</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Reason</div>
              </TextWithIcons>
              <TextWithIcons className="justify-self-center" variant="h6">
                <div className="flex gap-7">Actions</div>
              </TextWithIcons>
            </div>

            {listUserTeam.length !== 0 && !loading ? (
              <VariableSizeList
                itemCount={countEmployeeTeam}
                itemSize={getItemSize}
                height={window.innerHeight - 400}
                width="100%"
                className="no-scrollbars"
              >
                {({ index, style }) => (
                  <UserEmploymentTeamRow
                    data={listUserTeam[index]}
                    style={style}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                  />
                )}
              </VariableSizeList>
            ) : (
              <div>
                {loading && (
                  <div className="grid justify-center mx-auto p-5">
                    <Spinner />
                  </div>
                )}
                <EmptyListMessage />
              </div>
            )}
          </div>
          {/* END FIRST SECTION */}

          <div className="w-1/4 first-section ">
            <UserTeamAssignmentTestForm
              handleSubmit={handleSubmit}
              handleReset={handleReset}
              formError={formError}
              selectedTeam={selectedTeam as Team}
              setSelectedTeam={setSelectedTeam as React.Dispatch<React.SetStateAction<Team | null>>}
              selectedEmploymentRole={selectedEmploymentRole as EmploymentRole}
              setSelectedEmploymentRole={
                setSelectedEmploymentRole as React.Dispatch<
                  React.SetStateAction<EmploymentRole | null>
                >
              }
              selectedUser={selectedUser as UserEmployment}
              setSelectedUser={
                setSelectedUser as React.Dispatch<React.SetStateAction<UserEmployment | null>>
              }
              selectedTeamTransferReason={selectedTeamTransferReason as EmployeeTeamTransferReason}
              setSelectedTeamTransferReason={
                setSelectedTeamTransferReason as React.Dispatch<
                  React.SetStateAction<EmployeeTeamTransferReason | null>
                >
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTeamAssignment;
