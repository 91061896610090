import React from 'react';
import { DialogBody, DialogHeader } from '@material-tailwind/react';
import dateToDateString from '../../../../hooks/utils/dateToDateString';
import useRealTimeDate from '../../../../hooks/utils/useRealTimeDate';
import useTheme from '../../../../hooks/utils/useTheme';

interface UserAttendanceModalProps {
  heading?: React.ReactNode;
  hasHeading?: boolean;
  children: React.ReactNode;
}

const UserAttendanceModal: React.FC<UserAttendanceModalProps> = ({
  children,
  heading = undefined,
  hasHeading = true,
}) => {
  const { theme } = useTheme();
  const currentDateTime = useRealTimeDate();
  return (
    <div className="prompt-container">
      {hasHeading && (
        <>
          <DialogHeader className={`dialog-prompt-header ${theme}`}>
            {dateToDateString(currentDateTime).dateTime}
          </DialogHeader>
          <DialogHeader className={`dialog-prompt-sub-header ${theme}`}>{heading}</DialogHeader>
        </>
      )}
      <DialogBody>{children}</DialogBody>
    </div>
  );
};

export default UserAttendanceModal;
