import React from 'react';
import TabbedContent, { Tab } from '../../../molecules/TabbedContent';
import PositionManageList from './PositionManageList';
import PositionRateList from '../rates/PositionRateList';
import ManagementHeading from '../../../atoms/ManagementHeading';

const tabs: Tab[] = [
  { title: 'Positions', content: <PositionManageList /> },
  { title: 'Position rates', content: <PositionRateList /> },
];

const PositionManagePage: React.FC = () => (
  <div className="organisms-position-management">
    <div className="organisms-position-management__content">
      <div className="organisms-position-management__heading">
        <ManagementHeading title="Position" />
      </div>
      <TabbedContent tabs={tabs} />
    </div>
  </div>
);

export default PositionManagePage;
