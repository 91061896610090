import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useEmploymentTypes from '../../../hooks/employment/useEmploymentTypes';
import EmploymentType from '../../../store/constants/employments/employment-type.interface';

export interface EmploymentTypeProps extends Partial<AutoSearchSelectProps<EmploymentType>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectEmploymentType: React.FC<EmploymentTypeProps> = ({
  id,
  name,
  placeholder = 'Select Employment Type',
  className = '',
  ...props
}) => {
  const { employmentTypesState, getAll } = useEmploymentTypes();

  useOnLoad(() => {
    getAll().then();
  });

  const employmentTypes = employmentTypesState.employmentTypes
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((employmentType) => ({
      ...employmentType,
      value: employmentType.id,
      label: employmentType.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-employment-type modal-select ${className}`}
      placeholder={placeholder}
      data={employmentTypes}
    />
  );
};

export default SelectEmploymentType;
