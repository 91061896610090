import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  List,
  ListItem,
  ListItemPrefix,
} from '@material-tailwind/react';
import { ReactComponent as DownArrow } from '../../assets/images/icons/chevron-down.svg';
import { ReactComponent as ServerStack } from '../../assets/images/icons/server-stack.svg';

export interface Option {
  name?: string;
  linkTo: string;
  icon: React.ReactNode;
  subOptions?: Option[];
}

export interface AccordionMenuContentProps {
  menuTitle: string;
  options: Option[];
  accordionIcon?: React.ReactNode;
  openNumber: number;
  open: number;
  handleOpen: (value: number) => void;
}

interface IconProps {
  id: number; // Change the type to match the actual type of `id`
  open: number; // Change the type to match the actual type of `open`
}

const Icon: React.FC<IconProps> = ({ id, open }) => (
  <DownArrow
    className={`mx-auto sidebar-icon transition-transform ${id === open ? 'rotate-180' : ''}`}
  />
);

const AdminAccordionMenu: React.FC<AccordionMenuContentProps> = ({
  menuTitle,
  options,
  openNumber,
  open,
  handleOpen,
  accordionIcon = undefined,
}) => {
  const location = useLocation();
  const pathArray = location.pathname.split('/');

  const [alwaysOpen, setAlwaysOpen] = React.useState<boolean[]>(Array(options.length).fill(false));

  const handleAlwaysOpen = (index: number) => {
    setAlwaysOpen((prevOpen) => {
      const newStates = [...prevOpen];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  useEffect(() => {
    if (open !== openNumber) {
      setAlwaysOpen(Array(options.length).fill(false));
    }
  }, [open, openNumber, options.length]);

  return (
    <Accordion
      open={open === openNumber || alwaysOpen[openNumber] || false}
      icon={<Icon id={openNumber} open={open} />}
      className="sidebar-borders"
    >
      <ListItem className="p-0 ">
        <AccordionHeader
          onClick={() => handleOpen(openNumber)}
          className={`flex items-center gap-2 p-2 py-1 border-b-0 cursor-pointer hover:bg-[#f05825] active:bg-[#f05825]/50 rounded-lg ${
            open === openNumber ? 'bg-[#f05825]/50' : ''
          }`}
        >
          <ListItemPrefix>{accordionIcon}</ListItemPrefix>
          <span className="sidebar-text mr-auto font-normal py-[.15rem]">{menuTitle}</span>
        </AccordionHeader>
      </ListItem>
      <AccordionBody className="py-1 bg-[#363638] rounded-lg mt-2">
        <List className="p-0">
          {options.map((option, index) =>
            option.subOptions ? (
              <Accordion
                key={option.name}
                onClick={() => handleAlwaysOpen(index)}
                open={alwaysOpen[index]}
                icon={
                  // <div className="p-1">
                  <DownArrow
                    onClick={() => handleAlwaysOpen(index)}
                    className={`transition-transform sidebar-icon ${
                      alwaysOpen[index] ? 'rotate-180' : ''
                    }`}
                  />
                  // </div>
                }
                className="z-10 sidebar-borders"
              >
                <ListItem className="p-0">
                  <AccordionHeader
                    className={`flex items-center justify-start gap-2 p-2 py-1 border-b-0 cursor-pointer hover:bg-[#f05825] active:bg-[#f05825] rounded-lg ${
                      option.name && pathArray.includes(option.name.toLocaleLowerCase())
                        ? 'bg-[#f05825]'
                        : ''
                    }`}
                  >
                    <Link
                      to={option.linkTo}
                      className="w-full flex gap-2 font-normal py-[.15rem] sidebar-text"
                    >
                      <ListItemPrefix>
                        <ServerStack className="sidebar-icon" />
                      </ListItemPrefix>
                      <span>{option.name}</span>
                    </Link>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody className="py-1 bg-[#363638] rounded-lg mt-2">
                  <List className="p-0 ">
                    {option.subOptions.map((subOption) => (
                      <Link
                        key={subOption.name}
                        className="w-full ml-4"
                        to={subOption.linkTo}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                      >
                        <ListItem
                          className={`sidebar-borders hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825] rounded-lg transition-all duration-300 py-2 px-2 ${
                            subOption.name && pathArray.includes(subOption.name.toLocaleLowerCase())
                              ? 'bg-[#f05825]'
                              : ''
                          }`}
                        >
                          <div className="flex items-center gap-2 p-0 text-gray-200">
                            <ListItemPrefix className="sidebar-icon">
                              {subOption.icon}
                            </ListItemPrefix>

                            <span className="sidebar-text">{subOption.name}</span>
                          </div>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </AccordionBody>
              </Accordion>
            ) : (
              <Link
                key={option.name}
                className="w-full"
                to={option.linkTo}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <ListItem
                  className={`sidebar-borders hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825] rounded-lg transition-all duration-300 py-2 px-2 ${
                    location.pathname.toLowerCase().startsWith(option.linkTo.toLowerCase())
                      ? 'bg-[#f05825]/50'
                      : ''
                  }`}
                >
                  <div className="flex items-center gap-2 p-0 text-gray-200">
                    <ListItemPrefix className="sidebar-icon">{option.icon}</ListItemPrefix>

                    <span className="sidebar-text">{option.name}</span>
                  </div>
                </ListItem>
              </Link>
            ),
          )}
        </List>
      </AccordionBody>
    </Accordion>
  );
};

export default AdminAccordionMenu;
