import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import TransactionFeed from '../../constants/my/transaction-feed.interface';
import transactionFeedServices, {
  GetAllTransactionFeedsQueryParams,
} from '../../services/my/transactionFeedServices';

const get = createAsyncThunk(
  'transactionFeed/get',
  async (
    id: TransactionFeed['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<TransactionFeed> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await transactionFeedServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'transactionFeed/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllTransactionFeedsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<TransactionFeed> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await transactionFeedServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'transactionFeed/post',
  async (
    data: Omit<TransactionFeed, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<TransactionFeed> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await transactionFeedServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'transactionFeed/put',
  async (
    data: AtLeast<TransactionFeed, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<TransactionFeed> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await transactionFeedServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'transactionFeed/remove',
  async (
    id: TransactionFeed['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<TransactionFeed> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await transactionFeedServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const isRead = createAsyncThunk(
  'transactionFeed/isRead',
  async (
    data: TransactionFeed,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<TransactionFeed> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await transactionFeedServices.isRead(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove, isRead } as const;
