import { useAppDispatch, useAppSelector } from '../../../store/store';
import companyBranchActions from '../../../store/actions/companies/companyBranchActions';
import {
  companyBranchesReducerActions,
  CompanyBranchesState,
} from '../../../store/reducers/companies/companyBranchesReducer';
import CompanyBranch from '../../../store/constants/companies/branches/company-branch.interface';

const useCompanyBranches = () => {
  const dispatch = useAppDispatch();
  const companyBranchesState: CompanyBranchesState = useAppSelector((state) => state.companyBranch);

  const resetCompanyBranchState = () => {
    dispatch(companyBranchesReducerActions.resetCompanyBranchesState());
  };

  const setCompanyBranchState = (state: Partial<CompanyBranchesState>) => {
    dispatch(companyBranchesReducerActions.setCompanyBranchesState(state));
  };

  const get = async (id: CompanyBranch['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyBranchActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyBranchActions.getAll({}));

  const post = async (data: Omit<CompanyBranch, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyBranchActions.post(data));

  const put = async (data: Partial<CompanyBranch>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyBranchActions.put(data));

  const remove = async (id: CompanyBranch['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyBranchActions.remove(id));

  return {
    resetCompanyBranchState,
    companyBranchesState,
    setCompanyBranchState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useCompanyBranches;
