import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Chip, Typography } from '@material-tailwind/react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import Breadcrumbs from '../../../molecules/Breadcrumbs';
import consoleLog from '../../../../utils/consoleLog';
// import useTeams from '../../../../hooks/teams/useTeams';
// import Team from '../../../../store/constants/teams/team.interface';
import TextWithIcons from '../../../molecules/TextWithIcons';
import useDateTime from '../../../../hooks/utils/useDateTime';
import EmploymentPosition from '../../../../store/constants/employments/positions/employment-position.interface';
import usePositions from '../../../../hooks/positions/usePositions';
import ClientPositionPageRow from './ClientPositionPageRow';
import ClientPageAddFormModal from './ClientPageAddFormModal';
import SearchSvg from '../../../../assets/images/icons/search.svg';
import useClients from '../../../../hooks/clients/useClients';
import Client from '../../../../store/constants/clients/client.interface';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions = '' | 'id' | 'client' | 'employees' | 'department' | 'dateStart';

const ClientPositionPage: React.FC = () => {
  const { getDateTime } = useDateTime();
  // const { teamsState, getAll: getAllTeams } = useTeams();
  const { clientsState, getAll: getAllClients } = useClients();
  const { positionsState, getAll: getAllPositions } = usePositions();
  // const [thisTeam, setThisTeam] = useState<Team | undefined>();
  const [thisPosition, setThisPosition] = useState<EmploymentPosition | undefined>();
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);
  const location = useLocation();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const clients = clientsState.clients
    .filter((client: Client) => {
      const searchValues = debouncedSearchValue.toLowerCase();

      return (
        client.name.toLowerCase().includes(searchValues) ||
        client.id.toString().includes(searchValues) ||
        client.managers
          ?.map((manager) => manager.userInformation?.fullName)
          .toString()
          .includes(searchValues) ||
        client.managers
          ?.map((manager) => manager.department?.name)
          .toString()
          .includes(searchValues)
      );
    })
    // .sort((a, b) => b.id - a.id);
    .sort((a, b) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'id') {
        // return sortOrder * a.name.localeCompare(b.name);
        return sortOrder * (a.id - b.id);
      }

      if (sortOption === 'employees') {
        // return sortOrder * a.name.localeCompare(b.name);
        return sortOrder * (a.id - b.id);
      }

      return sortOrder * (a.id - b.id);
    });

  consoleLog('clients', clients);

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    // getAllUsers().then();
    // getAllTeams().then();
    getAllPositions().then();
    getAllClients().then();

    // const teamId = location.pathname.split('/')[3];
    // const team = teamsState.teams.find((t) => t.id === parseInt(teamId, 10));
    const positionId = location.pathname.split('/')[3];
    const positions = positionsState.positions.find((p) => p.id === parseInt(positionId, 10));
    // setThisTeam(team);
    setThisPosition(positions);
  });

  // temporary fix for duplicate data
  const filterDuplicates = (data: Client[]) => {
    const filteredData = data.filter(
      (client, index, self) => index === self.findIndex((t) => t.id === client.id),
    );
    return filteredData;
  };

  const uniqueData = filterDuplicates(clients);

  return (
    <div className="team-manage-form-container">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/positions">
          <Typography className="crumb">Positions</Typography>
        </Link>
        <span className="flex items-center gap-2 ">
          <Typography className="crumb">{thisPosition?.name ?? ''}</Typography>
        </span>
        <span className="flex items-center gap-2 ">
          <Typography className="current-crumb">Clients</Typography>
          <Chip className="chip-style" value={clientsState.clients.length} variant="ghost" />
        </span>
      </Breadcrumbs>
      <div className="pr-10 main-team-assignment-section">
        <div className="organisms-position-management__heading management-heading">
          <Typography variant="h3" className="management-heading__secondary">
            Client Team Position
          </Typography>
        </div>
        <div className="flex justify-between py-3">
          <ClientPageAddFormModal onClose={() => {}} />
          <div className="search-body">
            <input
              onKeyDown={onSearchKeyDown}
              type="text"
              className="search-body__input search-bar"
              placeholder="Search Client Team"
              onChange={handleSearch}
            />
            <img
              style={{ filter: 'invert(0) invert(1)' }}
              className="search-body__icon"
              src={SearchSvg}
              alt="Search for Client Position"
            />
          </div>
        </div>
        <div className="client-positions-list-header header-items">
          <button
            type="button"
            onClick={() => sortByHeader('id', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'id' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'id' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">ID</div>
            </TextWithIcons>
          </button>
          {/* <TextWithIcons variant="h6">
            <div className="flex gap-5">ID</div>
          </TextWithIcons> */}
          <button
            type="button"
            onClick={() => sortByHeader('client', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'client' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'client' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Client</div>
            </TextWithIcons>
          </button>
          {/* <TextWithIcons variant="h6">
            <div className="flex gap-5">Client</div>
          </TextWithIcons> */}
          <button
            type="button"
            onClick={() => sortByHeader('employees', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'employees' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'employees' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Employees</div>
            </TextWithIcons>
          </button>
          {/* <TextWithIcons variant="h6">
            <div className="flex gap-5">Employees</div>
          </TextWithIcons> */}
          <button
            type="button"
            onClick={() => sortByHeader('department', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'department' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'department' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Department</div>
            </TextWithIcons>
          </button>
          {/* <TextWithIcons variant="h6">
            <div className="flex gap-5">Department</div>
          </TextWithIcons> */}
          <button
            type="button"
            onClick={() => sortByHeader('dateStart', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'dateStart' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'dateStart' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Date Start</div>
            </TextWithIcons>
          </button>
          {/* <TextWithIcons variant="h6">
            <div className="flex gap-5">Date Start</div>
          </TextWithIcons> */}
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Action</div>
          </TextWithIcons>
        </div>
        <VariableSizeList
          itemCount={uniqueData.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <ClientPositionPageRow
              data={uniqueData[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              getDateTime={getDateTime}
            />
          )}
        </VariableSizeList>
      </div>
    </div>
  );
};

export default ClientPositionPage;
