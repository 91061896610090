import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Export } from '../../../assets/images/icons/export-file.svg';
import { ReactComponent as ClientTeams } from '../../../assets/images/icons/user-two.svg';
import { ReactComponent as ClientEmployees } from '../../../assets/images/icons/user-three.svg';
import { ReactComponent as Manager } from '../../../assets/images/icons/happy-face.svg';
import Client from '../../../store/constants/clients/client.interface';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';

export interface ClientListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Client;
  getDateTime: (date: Date) => string;
  onEditClick: (client: Client) => void;
  onDeleteClick: (client: Client) => Promise<unknown>;
  updateClientDateEnd: (client: Client) => void;
}

const ClientListRow: React.FC<ClientListRowProps> = ({
  className = '',
  data,
  getDateTime,
  onEditClick,
  onDeleteClick,
  updateClientDateEnd,
  ...props
}: ClientListRowProps) => {
  const navigate = useNavigate();

  const handleViewClientEmployeesList = () => {
    navigate(`/admin/clients/${data.id}/employees`);
  };
  const handleViewClientTeamsList = () => {
    navigate(`/admin/clients/${data.id}/teams-list`);
  };
  const handleViewClientManagersList = () => {
    navigate(`/admin/clients/${data.id}/managers`);
  };

  const deleteMessage = {
    pending: 'Processing',
    success: 'Client information deleted.',
    error: 'Error on adding Client information.',
  };

  const gsd = data.managers?.find((cm) => cm.employmentRole?.code === 'gsd');

  return (
    <div
      {...props}
      role="button"
      className={`${className} client-list-data table-list-data flex items-center`}
    >
      <div>{data.name}</div>
      <button
        type="button"
        className={`text-left w-fit ${data.teamCount ? ' cursor-default' : 'cursor-pointer'}`}
        onClick={() => {
          if (data.teamCount === 0) return;

          handleViewClientTeamsList();
        }}
      >
        {data.teamCount}
      </button>
      <div>{data.fte}</div>
      <div>{gsd && gsd.userInformation && gsd.userInformation.fullName}</div>
      <div>{data.dateStart}</div>
      <div>
        <button
          type="button"
          className={`text-left w-fit ${data.dateEnd ? 'cursor-default' : ''}`}
          onClick={() => {
            if (data.dateEnd) return;

            updateClientDateEnd(data);
          }}
        >
          {data.dateEnd ? data.dateEnd : '✖️'}
        </button>
      </div>
      <div className="space-x-6 action-buttons justify-self-center">
        <button className="flex gap-2 textButton" type="button" onClick={() => onEditClick(data)}>
          <Tooltip content="Edit" className="tooltip">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </Tooltip>
        </button>
        <Tooltip content="Client teams list" className="tooltip">
          <button
            className="flex gap-2 textButton"
            type="button"
            onClick={handleViewClientTeamsList}
          >
            <ClientTeams className="w-5 h-5" />
          </button>
        </Tooltip>
        <Tooltip content="Client employees list" className="tooltip">
          <button
            className="flex gap-2 textButton"
            type="button"
            onClick={handleViewClientEmployeesList}
          >
            <ClientEmployees className="w-5 h-5" />
          </button>
        </Tooltip>
        <Tooltip content="Client managers list" className="tooltip">
          <button
            className="flex gap-2 textButton"
            type="button"
            onClick={handleViewClientManagersList}
          >
            <Manager className="w-5 h-5" />
          </button>
        </Tooltip>
        <Tooltip content={`Export ${data.name} attendance`} className="tooltip">
          <button className=" gap-2 textButton hidden" type="button">
            <Export className="w-5 h-5" />
          </button>
        </Tooltip>
        <Tooltip content="OPS Export" className="tooltip">
          <button className=" gap-2 textButton hidden" type="button">
            <Export className="w-5 h-5" />
          </button>
        </Tooltip>
        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default ClientListRow;
