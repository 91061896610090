import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment/moment';
import { ReactComponent as TeamLeader } from '../../../assets/images/icons/person.svg';
import Client from '../../../store/constants/clients/client.interface';
import useClients from '../../../hooks/clients/useClients';
import toastNotification from '../../../hooks/utils/toastNotification';

export interface ClientDateEndModalParams {
  client?: Client;
  resetSort?: () => void;
  onClose?: () => void;
}

const ClientDateEndModal: React.FC<ClientDateEndModalParams> = ({
  client = undefined,
  resetSort = () => {},
  onClose = () => {},
}) => {
  const [clientDateEndForm, setClientDateEndForm] = useState<Client | undefined>();
  const [open, setOpen] = useState(false);

  const { put } = useClients();

  const handleClose = () => {
    setClientDateEndForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setClientDateEndForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<Client>) => {
    await toastNotification({
      action: put({
        ...clientDateEndForm,
        ...values,
      } as Partial<Client>),
      onSuccess: () => {
        resetSort();
      },
      onError: (response) => {
        console.log(response);
      },
      onFulfilled: () => {
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Client information is successfully updated.',
        error: 'Error on updating Client information.',
      },
    });

    handleClose();
    setOpen(false);
  };

  useEffect(() => {
    if (client) {
      setOpen(true);
      setClientDateEndForm(client);
    }
  }, [client]);

  return (
    <Dialog
      size="xs"
      open={open}
      handler={handleOpen}
      className="bg-[#343434] shadow-none p-10 modal-size"
    >
      <div className="m-2 p-0 absolute flex top-0 right-0">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            className="h-5 w-5"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </IconButton>
      </div>
      <div className="flex gap-3">
        <TeamLeader className="modal-icons" />
        <DialogHeader className="modal-header mt-1 p-0 text-gray-200">Client date end</DialogHeader>
      </div>
      <DialogBody divider className="p-0">
        <Formik
          initialValues={{
            ...clientDateEndForm,
            dateEnd: client?.dateEnd
              ? moment(client?.dateEnd).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            {/* FORM SECTION */}
            <div className="mt-4 mb-4 form-section">
              <div className="flex flex-col justify-center w-full">
                <div className="mb-6">
                  <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                    Client name
                  </label>
                  <h2 className="field-value text-gray-200">{client?.name}</h2>
                </div>
                <div className="mb-6">
                  <label htmlFor="dateEnd" className="block mb-2 text-gray-200 modal-labels">
                    Date end
                  </label>
                  <div className="date-end-field">
                    <Field
                      type="date"
                      id="dateEnd"
                      name="dateEnd"
                      className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                    />
                  </div>
                  <ErrorMessage name="dateEnd" component="div" />
                </div>
              </div>
            </div>
            {/* END OF FORM SECTION */}
            {/* Buttons */}
            <div className="flex space-x-2 w-max">
              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                >
                  Submit
                </button>
              </div>
              <div className="flex justify-center w-max">
                <button
                  type="button"
                  onClick={handleClose}
                  className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                >
                  Close
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export default ClientDateEndModal;
