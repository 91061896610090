/* eslint-disable react/require-default-props */
import React from 'react';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import countDaysExcluding from '../../../../utils/countTotalDaysWithExclusions';

export interface LeaveListRowProps {
  data: LeaveApplication;
  dateString: string;
  excludedDateData: string;
  employeeName: string;
  position?: string;
  department?: string;
}

const LeaveFormRequestData: React.FC<LeaveListRowProps> = ({
  data,
  dateString,
  excludedDateData,
  employeeName,
  position = '',
  department = '',
}) => {
  const convertedExcludedDates = data.excludedDates.map((dateFormat) => new Date(dateFormat));
  const selectedDays =
    data.dateStart &&
    data.dateEnd &&
    countDaysExcluding(new Date(data.dateStart), new Date(data.dateEnd), convertedExcludedDates);

  const cellStyle = {
    padding: '.7rem .8rem',
    border: '1px solid black',
  };

  const TopLeftRight = {
    padding: '.7rem .8rem',
    borderTop: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  };

  const TopRight = {
    padding: '.7rem .8rem',
    borderTop: '1px solid black',
    borderRight: '1px solid black',
  };

  const TopBottomRight = {
    padding: '.7rem .8rem',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
  };

  const BottomLeftRight = {
    padding: '.7rem .8rem',
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  };

  const BottomRight = {
    padding: '.7rem .8rem',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
  };

  const SpanMarginBottom = {
    fontSize: '.8rem',
    marginBottom: '.5rem',
  };

  const h2Font = {
    fontSize: '.8rem',
    fontWeight: 600,
    paddingRight: '.2rem',
  };

  const spanFont = {
    fontSize: '.8rem',
    fontWeight: 600,
    fontStyle: 'italic',
    borderBottom: '1px solid black',
  };

  return (
    <div
      style={{
        margin: 'auto',
        fontSize: '0.6rem',
        borderTop: '1px solid black',
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
        height: '100%',
        width: '750px',
      }}
    >
      <div
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          display: 'flex',
          height: '100%',
        }}
      >
        <h1
          style={{
            marginBottom: '3.5rem',
            marginTop: '5rem',
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          APPLICATION FOR LEAVE
        </h1>
        {/* First container */}
        <div style={{ width: '100%', marginBottom: '1rem', padding: '0 .7rem' }}>
          <div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '.8rem',
              }}
            >
              <div
                style={{
                  flexBasis: 'flex: 2',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <h2 style={{ ...h2Font }}>EMPLOYEE NAME:</h2>
                <span style={{ ...spanFont }}>{employeeName}</span>
              </div>
              <div
                style={{
                  flexBasis: 'flex: 1',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '3rem',
                }}
              >
                <h2 style={{ ...h2Font }}>ID #:</h2>
                <span style={{ ...spanFont }}>{data.id}</span>
              </div>
              <div
                style={{
                  flexBasis: 'flex: 1',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <h2 style={{ ...h2Font }}>DATE FILED:</h2>
                <span style={{ ...spanFont }}>{dateString}</span>
              </div>
            </div>

            <div style={{ width: '100%', display: 'flex', marginBottom: '.8rem' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '45%',
                }}
              >
                <h2 style={{ ...h2Font }}>DEPARTMENT:</h2>
                <span style={{ ...spanFont }}>{department}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1.5rem',
                  width: '65%',
                }}
              >
                <h2 style={{ ...h2Font, marginLeft: '3rem' }}>POSITION:</h2>
                <span style={{ ...spanFont }}>{position || ''}</span>
              </div>
            </div>

            <div style={{ width: '100%', display: 'flex', marginBottom: '.8rem' }}>
              <div style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
                <h2 style={{ ...h2Font }}>DATE:</h2>
                <span style={{ ...spanFont }}>{data.dateStart}</span>
              </div>
              <div style={{ display: 'flex', width: '25%', alignItems: 'center' }}>
                <h2 style={{ ...h2Font }}>TOTAL DAYS:</h2>
                <span style={{ ...spanFont }}>{selectedDays}</span>
              </div>
              <div style={{ display: 'flex', width: '25%', alignItems: 'center' }}>
                <h2 style={{ ...h2Font }}>TOTAL HOURS:</h2>
                {/* <span style={{ ...spanFont }}>12</span> */}
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%', marginBottom: '.8rem' }}>
              {excludedDateData && (
                <>
                  <h2 style={{ ...h2Font }}>EXCLUDED DATE(S):</h2>
                  <span style={{ ...spanFont }}>{excludedDateData || ''}</span>
                </>
              )}
            </div>
          </div>

          {/* Nature */}
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <h2 style={{ paddingRight: '.8rem' }}>NATURE: </h2>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>
              [ {data.leaveNature?.nature.toLowerCase() === 'sick leave' ? '✔️' : ' '} ] Sick Leave
            </span>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>
              [ {data.leaveNature?.nature.toLowerCase() === 'vacation' ? '✔️' : ' '} ] Vacation
            </span>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>
              [ {data.leaveNature?.nature.toLowerCase() === 'bereavement' ? '✔️' : ' '} ]
              Bereavement
            </span>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>
              [ {data.leaveNature?.nature.toLowerCase() === 'emergency' ? '✔️' : ' '} ] Emergency
            </span>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>[ ] Maternity</span>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>[ ] Paternity</span>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>[ ] Unpaid</span>
            <span style={{ fontSize: '.8rem', paddingRight: '.5rem' }}>[ ] Others</span>
          </div>

          {/* Reason */}
          <div style={{ display: 'flex', gap: '.5rem', marginBottom: '.7rem' }}>
            <h2 style={{ fontSize: '.8rem', fontWeight: '600' }}>Reason:</h2>
            <p style={{ borderBottom: '1px solid black', width: '80%', fontSize: '.8rem' }}>
              {data.notes}
            </p>
          </div>
        </div>
        {/* Second whole Container */}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          {/* First */}
          <div
            style={{
              flexBasis: '33%',
              border: '1px solid black',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '.5rem 0rem',
            }}
          >
            <h2 style={{ display: 'flex', justifyContent: 'center' }}>FOR WORKFORCE</h2>
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                marginBottom: '3rem',
                padding: '0rem .4rem',
              }}
            >
              <span style={{ fontSize: '.8rem' }}>[ ] WITH SLOT</span>
              <span style={{ fontSize: '.8rem' }}>[ ] W/OUT SLOT</span>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <span
                style={{
                  flexBasis: '65%',
                  borderTop: '1px solid black',
                  marginRight: '.6rem',
                  textAlign: 'center',
                }}
              >
                SIGNATURE OVER PRINTED NAME
              </span>
              <span style={{ flexBasis: '35%', borderTop: '1px solid black', textAlign: 'center' }}>
                DATE
              </span>
            </div>
          </div>
          {/* second */}
          <div
            style={{
              flexBasis: '33%',
              border: '1px solid black',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '.5rem 0rem',
            }}
          >
            <div>
              <h2 style={{ display: 'flex', justifyContent: 'center' }}>FOR DIRECT SUPERVISOR</h2>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <span
                style={{
                  flexBasis: '65%',
                  borderTop: '1px solid black',
                  marginRight: '.6rem',
                  textAlign: 'center',
                }}
              >
                SIGNATURE OVER PRINTED NAME
              </span>
              <span style={{ flexBasis: '35%', borderTop: '1px solid black', textAlign: 'center' }}>
                DATE
              </span>
            </div>
          </div>
          {/* Third */}
          <div
            style={{
              flexBasis: '33%',
              border: '1px solid black',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '.5rem 0rem',
            }}
          >
            <h2 style={{ display: 'flex', justifyContent: 'center' }}>REQUESTED BY:</h2>
            <div style={{ display: 'flex', width: '100%' }}>
              <span
                style={{
                  flexBasis: '65%',
                  borderTop: '1px solid black',
                  marginRight: '.6rem',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    bottom: '1.6rem',
                    fontSize: '.8rem',
                    fontWeight: '600',
                  }}
                >
                  {employeeName}
                </span>
                SIGNATURE OVER PRINTED NAME
              </span>
              <span
                style={{
                  flexBasis: '35%',
                  borderTop: '1px solid black',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                <span
                  style={{
                    position: 'absolute',
                    bottom: '1.6rem',
                    right: '.7rem',
                    fontSize: '.7rem',
                    fontWeight: '600',
                  }}
                >
                  {dateString}
                </span>
                DATE
              </span>
            </div>
          </div>
        </div>
        {/* Third Whole container */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            gap: '.8rem',
            padding: '0 .8rem',
          }}
        >
          {/* hr */}
          <div
            style={{
              flexBasis: '75%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '.5rem 0rem',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{ width: '25%' }}>
                <h2 style={{ display: 'flex', justifyContent: 'center', marginBottom: '.7rem' }}>
                  FOR HR
                </h2>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '.8rem' }}>WITH PAY: [ ]</span>
                  <span style={{ fontSize: '.8rem' }}>W/OUT PAY: [ ]</span>
                </div>
              </div>
              <div
                style={{
                  width: '75%',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gridTemplateRows: 'repeat(3, auto)',
                }}
              >
                <div style={{ ...TopLeftRight }} />
                <div style={{ ...TopRight, textAlign: 'center' }}>EARNED HOURS</div>
                <div style={{ ...TopRight, textAlign: 'center' }}>USED</div>
                <div style={{ ...TopRight, textAlign: 'center' }}>UNPAID</div>
                <div style={{ ...TopRight, textAlign: 'center' }}>CREDITS</div>

                <div style={{ ...cellStyle }}>SL</div>
                <div style={{ ...TopBottomRight }} />
                <div style={{ ...TopBottomRight }} />
                <div style={{ ...TopBottomRight }} />
                <div style={{ ...TopBottomRight }} />

                <div style={{ ...BottomLeftRight }}>VL</div>
                <div style={{ ...BottomRight }} />
                <div style={{ ...BottomRight }} />
                <div style={{ ...BottomRight }} />
                <div style={{ ...BottomRight }} />
              </div>
            </div>
          </div>
          {/* Verified by */}
          <div
            style={{
              flexBasis: '25%',
              borderLeft: '1px solid black',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <h2 style={{ display: 'flex', justifyContent: 'center' }}>VERIFIED BY:</h2>
              <div style={{ display: 'flex', width: '100%' }}>
                <span
                  style={{
                    flexBasis: '65%',
                    borderTop: '1px solid black',
                    marginRight: '.6rem',
                    textAlign: 'center',
                  }}
                >
                  SIGNATURE OVER PRINTED NAME
                </span>
                <span
                  style={{ flexBasis: '35%', borderTop: '1px solid black', textAlign: 'center' }}
                >
                  DATE
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Fourth Whole Container */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              width: '50%',
              padding: '1rem',
              border: '1px solid black',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h2 style={{ padding: '.5rem 0' }}>INSTRUCTIONS:</h2>
              <span style={{ ...SpanMarginBottom }}>
                1. To be accomplished in 3 copies (1 for HR, 1 for Employee, 1 Supervisor)
              </span>
              <span style={{ ...SpanMarginBottom }}>
                2. Leave form should be signed by Direct Supervisor.
              </span>
              <span style={{ ...SpanMarginBottom }}>
                3. Get approval from Workforce / Client for available slots.
              </span>
              <span style={{ ...SpanMarginBottom }}>
                4. Get Department Head&apos;s recommendation and/or approval.
              </span>
              <span style={{ ...SpanMarginBottom }}>5. Leave credits to be filled up by HR.</span>
              <span style={{ ...SpanMarginBottom }}>6. Plot your leaves in Osnet.</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '50%',
              padding: '1rem',
              borderTop: '1px solid black',
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
            }}
          >
            <h2 style={{ padding: '.5rem 0' }}>FOR SICK LEAVE APPLICATION:</h2>
            <span style={{ ...SpanMarginBottom }}>
              1. An employee must call in at least two (2) hours before shift for non-csr and 24 hrs
              prior for CSRs.
            </span>
            <span style={{ ...SpanMarginBottom }}>
              2. Must be completed ON the first day upon return to work after the SL.
            </span>
            <span style={{ ...SpanMarginBottom }}>
              3. Anything filed later is a violation of the attendance policy.
            </span>
            <span style={{ ...SpanMarginBottom }}>
              4. A Medical certificate from an accredited clinic is required for Sick Leave
              application for more than 1 day.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveFormRequestData;
