import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import HolidayType from '../../constants/holidays/holiday-type.interface';
import holidayTypeActions from '../../actions/holidays/holidayTypeActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface HolidayTypesState {
  holidayTypes: HolidayType[];
}

const initialState: HolidayTypesState = {
  holidayTypes: [],
};

const getHolidayTypeData = (holidayType: Partial<HolidayType>) => ({
  ...holidayType,
});

const upsertItems = (holidayTypes: HolidayType[], ...data: HolidayType[]) => {
  const dataObject: { [id: number]: HolidayType } = {};
  holidayTypes.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((holidayType) => {
    if (!dataObject[holidayType.id]) {
      dataObject[holidayType.id] = holidayType;
    } else if (dataObject[holidayType.id]) {
      dataObject[holidayType.id] = { ...dataObject[holidayType.id], ...holidayType };
    }
  });

  return Object.values(dataObject);
};

const holidayTypesSlice = createSlice({
  name: 'holidayTypes',
  initialState,
  reducers: {
    resetHolidayTypesState: () => ({
      ...initialState,
    }),
    setHolidayTypesState: (state, action: PayloadAction<Partial<HolidayTypesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertHolidayType: (state, action: PayloadAction<Partial<HolidayType>>) => {
      const data: HolidayType = getHolidayTypeData(action.payload) as HolidayType;

      if (typeof data.id === 'undefined') return;

      state.holidayTypes = upsertItems(state.holidayTypes, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(holidayTypeActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<HolidayType>;
      consoleLog('HOLIDAYS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.holidayTypes = upsertItems(state.holidayTypes, data);
      }
    });
    builder.addCase(holidayTypeActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<HolidayType>;

      if (status === 503) return;

      if (data) {
        state.holidayTypes = data;
      }
    });
    builder.addCase(holidayTypeActions.get.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<HolidayType>;

      if (typeof data.id === 'undefined') return;
      state.holidayTypes = upsertItems(state.holidayTypes, data);
    });
    builder.addCase(holidayTypeActions.put.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<HolidayType>;

      if (typeof data.id === 'undefined') return;
      state.holidayTypes = upsertItems(state.holidayTypes, data);
    });
    builder.addCase(holidayTypeActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<HolidayType>;

      if (typeof data.id === 'undefined') return;
      state.holidayTypes = state.holidayTypes.filter((holidayType) => holidayType.id !== data.id);
    });
  },
});

export const holidayTypesReducerActions = holidayTypesSlice.actions;
export default holidayTypesSlice.reducer;
