import React, { FC } from 'react';
import UserProfileStatsCard from '../molecules/UserProfileStatsCard';
import { ProfileStatsCardItemProps } from '../atoms/UserProfileStatsCardItem';
import CardFlexContent, { CardItems } from '../atoms/CardFlexContent';
import User from '../../store/constants/users/user.interface';
import { ReactComponent as BriefCase } from '../../assets/images/icons/briefcase.svg';
import { ReactComponent as Heart } from '../../assets/images/icons/heart.svg';
import { ReactComponent as BellAlert } from '../../assets/images/icons/bell-alert.svg';
import { ReactComponent as World } from '../../assets/images/icons/world.svg';
import useOnLoad from '../../hooks/utils/useOnLoad';
import useMyLeaves from '../../hooks/leave/useMyLeaves';
import LeaveApplication from '../../store/constants/leaves/leave-application.interface';
import useAuth from '../../hooks/auths/useAuth';

interface ProfileCardProps {
  user: User;
}

const ProfileCard: FC<ProfileCardProps> = ({ user }: ProfileCardProps) => {
  const {
    authState: { userInformation },
  } = useAuth();
  const { myLeavesState, getMyAll } = useMyLeaves();

  const countLeaveByType = (leaveType: string) => {
    if (!myLeavesState.myLeaves) return 0;

    const currentYear = new Date().getFullYear();

    return myLeavesState.myLeaves.filter(
      (leave: LeaveApplication) =>
        leave.employeeNumber === userInformation?.employeeNumber &&
        leave.leaveNature?.nature.trim().toLowerCase() === leaveType.toLowerCase() &&
        leave.dateApproved &&
        new Date(leave.dateStart).getFullYear() === currentYear,
    ).length;
  };

  const leaveInformation: ProfileStatsCardItemProps[] = [
    {
      icon: <BriefCase className="w-7 h-auto" />,
      numerator: countLeaveByType('Bereavement'),
      denominator: 12,
      label: 'Bereavement',
    },
    {
      icon: <Heart className="w-7 h-auto" />,
      numerator: countLeaveByType('Sick leave'),
      denominator: 12,
      label: 'Sick leave',
    },
    {
      icon: <World className="w-7 h-auto" />,
      numerator: countLeaveByType('Vacation'),
      denominator: 12,
      label: 'Vacation leave',
    },
    {
      icon: <BellAlert className="w-7 h-auto" />,
      numerator: countLeaveByType('Emergency'),
      denominator: 12,
      label: 'Emergency leave',
    },
  ];

  const personalInformation: CardItems[] = [
    {
      title: 'First Name',
      name: `${user?.firstName ?? '--'}`,
    },
    {
      title: 'Last Name',
      name: `${user?.lastName ?? '--'}`,
    },
    {
      title: 'Middle Name',
      name: `${user?.middleName ?? '--'}`,
    },
    {
      title: 'Suffix',
      name: `${user?.suffixName ?? '--'}`,
    },
    {
      title: 'Gender',
      name: `${user?.gender ?? '--'}`,
    },
    {
      title: 'Nickname',
      name: `${user?.nickName ?? '--'}`,
    },
    {
      title: 'Maiden name',
      name: `${user?.maidenName ?? '--'}`,
    },
    {
      title: 'Contact number',
      name: `${user?.contactNumber ?? '--'}`,
    },
  ];

  const companyInformation: CardItems[] = [
    {
      title: 'Employee Number',
      name: `${user?.employment?.employeeNumber ?? '--'}`,
    },
    {
      title: 'OS Email',
      name: `${user?.employment?.emailWork ?? '--'}`,
    },
    {
      title: 'Branch / Site',
      name: '--',
    },
    {
      title: 'Department',
      name: `${user?.employment?.department?.name ?? '--'}`,
    },
    {
      title: 'Team',
      name: `${user?.employment?.teams ?? '--'}`,
    },
    {
      title: 'Position',
      name: `${user?.employment?.positions ?? '--'}`,
    },
    {
      title: 'Status',
      name: `${user?.employment?.employmentStatus?.name ?? '--'}`,
    },
    {
      title: 'OSnet Account Type',
      name: '--',
    },
    {
      title: 'Date Started',
      name: `${user?.employment?.dateStart ?? '--'}`,
    },
  ];

  const governmentIDInformation: CardItems[] = [
    {
      title: 'HDMF',
      name: `${user?.accountNumber?.hdmf ?? '--'}`,
    },
    {
      title: 'PHIC',
      name: `${user?.accountNumber?.phic ?? '--'}`,
    },
    {
      title: 'SSS',
      name: `${user?.accountNumber?.sss ?? '--'}`,
    },
    {
      title: 'TIN',
      name: `${user?.accountNumber?.tin ?? '--'}`,
    },
  ];

  const emergencyInformation: CardItems[] = [
    {
      title: 'Please Contact',
      name: `${user?.contact?.emergencyContactName ?? '--'}`,
    },
    {
      title: 'Relationship',
      name: `${user?.contact?.emergencyContactRelation ?? '--'}`,
    },
    {
      title: 'Address',
      name: `${user?.contact?.emergencyContactAddress ?? '--'}`,
    },
    {
      title: 'Contact #',
      name: `${user?.contact?.emergencyContactNumber ?? '--'}`,
    },
  ];

  const vaccinationInformation: CardItems[] = [
    {
      title: '',
      name: '',
    },
  ];

  const healthDocuments: CardItems[] = [
    {
      title: '',
      name: '',
    },
  ];

  const nurseStationVisits: CardItems[] = [
    {
      title: '',
      name: '',
    },
  ];

  useOnLoad(() => {
    getMyAll().then();
  });

  return (
    <div className="user-tab-container">
      <UserProfileStatsCard
        name="time-off-balance"
        title="Time-off balance"
        items={leaveInformation}
      />
      <div className="mb-4">
        <h2 className="card-title mb-2">Personal Information</h2>
        <CardFlexContent items={personalInformation} />
      </div>
      <div className="mb-4">
        <h2 className="card-title mb-2">Company Information</h2>
        <CardFlexContent items={companyInformation} />
      </div>
      <div className="mb-4">
        <h2 className="card-title mb-2">Government ID Information</h2>
        <CardFlexContent items={governmentIDInformation} />
      </div>
      <div className="mb-4">
        <h2 className="card-title mb-2">Emergency</h2>
        <CardFlexContent items={emergencyInformation} />
      </div>
      <div className="mb-4">
        <h2 className="card-title mb-2">Vaccinations</h2>
        <CardFlexContent items={vaccinationInformation} />
      </div>
      <div className="mb-4">
        <h2 className="card-title mb-2">Health Documents</h2>
        <CardFlexContent items={healthDocuments} />
      </div>
      <div className="mb-4">
        <h2 className="card-title mb-2">Nurse Station Visits</h2>
        <CardFlexContent items={nurseStationVisits} />
      </div>
    </div>
  );
};

export default ProfileCard;
