import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import ApigetAllCalendarEventsBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import calendarServices, {
  GetAllCalendarEventsQueryParams,
} from '../../services/calendar/calendarServices';
import EmployeeCalendar from '../../constants/calendar/employee-calendar.interface';

const getAllCalendarEvents = createAsyncThunk(
  'calendar/getAllCalendarEvents',
  async (
    { page = 1, limit = 50, search = '', startDate, endDate }: GetAllCalendarEventsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApigetAllCalendarEventsBaseResponse<EmployeeCalendar> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await calendarServices.getAllCalendarEvents(
        { page, limit, search, startDate, endDate },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { getAllCalendarEvents } as const;
