import { useAppDispatch, useAppSelector } from '../../store/store';
import departmentActions from '../../store/actions/departments/departmentActions';
import {
  DepartmentsState,
  departmentsReducerActions,
} from '../../store/reducers/departments/departmentsReducer';
import Department from '../../store/constants/departments/department.interface';

const useDepartments = () => {
  const dispatch = useAppDispatch();
  const departmentsState: DepartmentsState = useAppSelector((state) => state.departments);

  const resetDepartmentState = () => {
    dispatch(departmentsReducerActions.resetDepartmentsState());
  };

  const setDepartmentState = (state: Partial<DepartmentsState>) => {
    dispatch(departmentsReducerActions.setDepartmentsState(state));
  };

  const get = async (departmentId: Department['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(departmentActions.get(departmentId));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(departmentActions.getAll({}));

  const post = async (departmentData: Omit<Department, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(departmentActions.post(departmentData));

  const put = async (department: Partial<Department>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(departmentActions.put(department));

  const remove = async (id: Department['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(departmentActions.remove(id));

  return {
    resetDepartmentState,
    departmentsState,
    setDepartmentState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useDepartments;
