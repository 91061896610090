import React from 'react';
import LabelledToggleSwitch from '../../../../molecules/LabelledToggleSwitch';
// eslint-disable-next-line import/no-cycle
import type { SwitchStates } from '../PayrollToolOptions';

interface OptionsContentProps {
  switchStates: SwitchStates;
  handleParamsSwitchesChange: (id: string, checked: boolean) => void;
}

const PayrollHideColumns: React.FC<OptionsContentProps> = ({
  switchStates,
  handleParamsSwitchesChange,
}) => (
  <div className="payroll-create-hide-columns">
    <div className="payroll-create-hide-columns__switches">
      <LabelledToggleSwitch
        id="taxable"
        label="Taxable"
        isChecked={switchStates.taxable}
        onChange={(checked) => handleParamsSwitchesChange('taxable', checked)}
      />
      <LabelledToggleSwitch
        id="incentiveCompany"
        label="Incentive company"
        isChecked={switchStates.incentiveCompany}
        onChange={(checked) => handleParamsSwitchesChange('incentiveCompany', checked)}
      />
      <LabelledToggleSwitch
        id="allowances"
        label="Allowances"
        isChecked={switchStates.allowances}
        onChange={(checked) => handleParamsSwitchesChange('allowances', checked)}
      />
      <LabelledToggleSwitch
        id="incentiveClient"
        label="Incentive client"
        isChecked={switchStates.incentiveClient}
        onChange={(checked) => handleParamsSwitchesChange('incentiveClient', checked)}
      />
      <LabelledToggleSwitch
        id="deductions"
        label="Deductions"
        isChecked={switchStates.deductions}
        onChange={(checked) => handleParamsSwitchesChange('deductions', checked)}
      />
    </div>
    <div className="flex self-end justify-center">
      <span className="text-xs"> Hide columns</span>
    </div>
  </div>
);

export default PayrollHideColumns;
