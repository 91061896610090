interface ItemWithId {
  id: number;
}

const upsertData = <T extends ItemWithId>(items: T[], ...data: T[]) => {
  const dataObject: {
    [id: number]: T;
  } = {};
  items.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((item: T) => {
    if (!dataObject[item.id]) {
      dataObject[item.id] = item;
    } else if (dataObject[item.id]) {
      dataObject[item.id] = { ...dataObject[item.id], ...item };
    }
  });

  return Object.values(dataObject);
};

export default upsertData;
