import React, { useState } from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Typography,
} from '@material-tailwind/react';
import useRealTimeDate from '../../../../hooks/utils/useRealTimeDate';
import useGreetingTime from '../../../../hooks/utils/useGreetingTime';
import useOnLoad from '../../../../hooks/utils/useOnLoad';

const UserNoScheduleModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const currentDateTime = useRealTimeDate();
  const greetingTime = useGreetingTime();

  useOnLoad(() => {
    setOpen(true);
  });

  return (
    <Dialog
      size="xs"
      open={open}
      handler={() => setOpen(false)}
      className="dialog dialog--no-schedule rounded-xl p-3"
    >
      <DialogHeader className="dialog__header flex justify-center">
        <div className="dialog__header-content">
          <span className="uppercase text-xs">
            {moment(currentDateTime).format('MMMM YYYY hh:mm A')}
          </span>
        </div>
      </DialogHeader>
      <DialogBody className="dialog__body">
        <div className="flex flex-col justify-center dialog__body-content">
          <Typography className="muiTypography--headline5 mb-5">
            {`Hello👋,  Awesome ${greetingTime}!`}
          </Typography>
          <p className="dialog__message">
            Per our record you are
            <span> not scheduled to work </span>
            at this time. If this is wrong, please contact your Direct Supervisor or OSNET Team for
            schedule update.
          </p>
          <Typography className="muiTypography--headline6">Thanks</Typography>
        </div>
      </DialogBody>
      <DialogFooter className="dialog__footer">
        <Button
          variant="text"
          onClick={() => setOpen(false)}
          className="dialog__close-button no-schedule--close"
        >
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default UserNoScheduleModal;
