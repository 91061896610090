import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import type {
  ExpectedTimes,
  Modals,
} from '../../constants/employees/attendances/attendance-actions.interface';
import EmployeeAttendance from '../../constants/employees/attendances/employee-attendance.interface';
import attendanceActions from '../../actions/attendances/attendanceActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import AttendanceDetail from '../../constants/employees/attendances/employee-attendance-detail.interface';
import AttendanceType from '../../constants/employees/attendances/attendance-type.interface';
import EmployeeOvertime from '../../constants/employees/attendances/employee-overtime.interface';
import upsertData from '../../../utils/upsertData';
import LeaveApplication from '../../constants/leaves/leave-application.interface';
import UserEmploymentSchedules from '../../constants/employees/schedules/employee-schedule.interface';

export interface ExpectedAttendanceTime {
  date: string;
  timezone_type: number;
  timezone: string;
}

export interface AttendanceState {
  attendances: EmployeeAttendance[];
  myAttendance?: EmployeeAttendance;
  myTimeInDetail?: AttendanceDetail;
  myOverTimeDetail?: EmployeeOvertime;
  myCurrentOvertime?: EmployeeOvertime;
  myCurrentSchedule?: UserEmploymentSchedules;
  myOvertimes: EmployeeOvertime[];
  expectedTimes?: ExpectedTimes;
  activeLeaveApplication?: LeaveApplication;
  attendanceType?: AttendanceType[];
  modals?: Modals;
}

const initialState: AttendanceState = {
  myOverTimeDetail: undefined,
  myTimeInDetail: undefined,
  myAttendance: undefined,
  myCurrentSchedule: undefined,
  attendances: [],
  myOvertimes: [],
  myCurrentOvertime: undefined,
  expectedTimes: {
    timeIn: undefined,
    timeOut: undefined,
  },
  modals: {
    showTimeInModal: undefined,
    showOvertimeModal: undefined,
    showNoScheduleModal: undefined,
    showActiveLeaveModal: undefined,
  },
  activeLeaveApplication: undefined,
  attendanceType: [
    {
      id: 1,
      attendanceTypeName: 'OFF',
    },
    {
      id: 2,
      attendanceTypeName: 'Present',
    },
    {
      id: 3,
      attendanceTypeName: 'Absent',
    },
    {
      id: 4,
      attendanceTypeName: 'Leave',
    },
  ],
};

const attendanceSlice = createSlice({
  name: 'attendances',
  initialState,
  reducers: {
    resetAttendanceState: () => ({
      ...initialState,
    }),
    setAttendanceState: (state, action: PayloadAction<Partial<AttendanceState>>) => ({
      ...state,
      ...action.payload,
    }),
    resetActiveAttendanceAction: (state) => {
      if (state.modals) {
        state.modals.showActiveLeaveModal = undefined;
        state.modals.showNoScheduleModal = undefined;
      }
      if (state.expectedTimes) {
        state.expectedTimes.timeIn = undefined;
      }
      state.myAttendance = undefined;
      state.myOverTimeDetail = undefined;
      state.myCurrentSchedule = undefined;
    },
    upsertAttendance: (state, { payload }) => {
      const { data, status } = payload as ApiBaseResponse<EmployeeAttendance>;

      if (status === StatusCodes.OK) return;

      state.attendances = upsertData<EmployeeAttendance>(state.attendances, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(attendanceActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('ATTENDANCE REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmployeeAttendance>;

      if (status !== StatusCodes.OK) return;

      if (data) {
        state.attendances = data;
      }
    });

    builder.addCase(attendanceActions.get.fulfilled, (state, { payload }) => {
      consoleLog('ATTENDANCE REDUCER', 'GET', payload);

      const { data, status } = payload as ApiBaseResponse<EmployeeAttendance>;

      if (status !== StatusCodes.OK) return;

      state.attendances = upsertData<EmployeeAttendance>(state.attendances, data);
    });
    builder.addCase(attendanceActions.timeIn.fulfilled, (state, { payload }) => {
      consoleLog('ATTENDANCE REDUCER', 'TIME IN', payload);

      const { data } = payload as ApiBaseResponse<{
        attendances?: EmployeeAttendance;
        activeLeaveApplication?: LeaveApplication;
        showActiveLeaveModal?: boolean;
        nextSchedule?: UserEmploymentSchedules;
        attendancesAction: {
          expectedTimeOut?: ExpectedAttendanceTime;
          expectedTimeIn?: ExpectedAttendanceTime;
          showTimeInModal?: boolean;
          showNoScheduleModal?: boolean;
          showActiveLeaveModal?: boolean;
        };
      }>;

      if (data.attendances) {
        state.myAttendance = data.attendances as EmployeeAttendance;
      }

      if (data.nextSchedule) {
        state.myCurrentSchedule = data.nextSchedule;
      }

      state.expectedTimes = {
        timeOut: data.attendancesAction.expectedTimeOut,
        timeIn: data.attendancesAction.expectedTimeIn,
      };

      state.modals = {
        ...state.modals,
        showTimeInModal: data.attendancesAction.showTimeInModal,
        showNoScheduleModal: data.attendancesAction.showNoScheduleModal,
        showActiveLeaveModal: data.attendancesAction.showActiveLeaveModal,
      };

      state.activeLeaveApplication = data.activeLeaveApplication;
    });

    builder.addCase(attendanceActions.timeOut.fulfilled, (state, { payload }) => {
      consoleLog('ATTENDANCE REDUCER', 'TIME OUT', payload);

      const { data, status } = payload as ApiBaseResponse<{
        attendance?: EmployeeAttendance;
        nextSchedule?: UserEmploymentSchedules;
      }>;

      if (status !== StatusCodes.OK) return;

      if (data) {
        state.myAttendance = data.attendance;
        state.myCurrentSchedule = data.nextSchedule;
      }
      if (state.expectedTimes) {
        state.expectedTimes.timeIn = undefined;
        state.expectedTimes.timeOut = undefined;
      }
      if (state.modals) {
        state.modals.showTimeInModal = undefined;
      }
    });

    builder.addCase(attendanceActions.underTime.fulfilled, (state, { payload }) => {
      consoleLog('ATTENDANCE REDUCER', 'UNDER TIME', payload);

      const { status } = payload as ApiBaseResponse<undefined>;

      if (status !== StatusCodes.OK) return;

      if (state.expectedTimes) {
        state.expectedTimes.timeIn = undefined;
        state.expectedTimes.timeOut = undefined;
      }
      if (state.modals) {
        state.modals.showTimeInModal = undefined;
      }
    });

    builder.addCase(attendanceActions.myOvertimeRemove.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'REMOVE', payload);

      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.NO_CONTENT) return;

      state.myOvertimes = state.myOvertimes.filter((overtime) => overtime.id !== data.id);
    });

    builder.addCase(attendanceActions.overtimeTimein.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<EmployeeOvertime>;
      consoleLog('OVERTIME-IN REDUCER', 'OVERTIME-IN', data);

      if (state.modals) {
        state.modals.showTimeInModal = undefined;
      }
      if (data) {
        state.myOverTimeDetail = data;
      }
    });

    builder.addCase(attendanceActions.overtimeTimeOut.fulfilled, (state, { payload }) => {
      consoleLog('ATTENDANCE REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.OK) return;

      state.myOvertimes = upsertData<EmployeeOvertime>(state.myOvertimes, data);
    });

    builder.addCase(attendanceActions.getAllMyAttendance.fulfilled, (state, { payload }) => {
      const { status, data } = payload as ApiGetAllBaseResponse<EmployeeAttendance>;

      if (status !== StatusCodes.OK) return;

      if (Array.isArray(data)) {
        if (!data) return;
        state.attendances = data;
        state.myTimeInDetail = data[0].employeeAttendanceDetails;
      }
    });

    builder.addCase(attendanceActions.getAllMyOvertimes.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.OK) return;

      if (data) {
        state.myOvertimes = data;
      }
    });

    builder.addCase(attendanceActions.getMyCurrentOvertime.fulfilled, (state, { payload }) => {
      consoleLog('OVERTIMES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiBaseResponse<EmployeeOvertime>;

      if (status !== StatusCodes.OK) return;

      if (data) {
        state.myOverTimeDetail = data;
      }
    });
  },
});

export const attendancesReducerActions = attendanceSlice.actions;
export default attendanceSlice.reducer;
