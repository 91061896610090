import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Client from '../../constants/clients/client.interface';
import ClientActions from '../../actions/clients/clientActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import clientManagersActions from '../../actions/clients/clientManagerActions';
import ClientManager from '../../constants/clients/client-manager.interface';

export type ClientManagersDefaultRole = 'csd' | 'as' | 'om' | 'som' | 'am' | 'director';

export interface ClientsState {
  clients: Client[];
  clientEmployees: Client['employees'][];
}

const initialState: ClientsState = {
  clients: [],
  clientEmployees: [],
};

const getClientData = (client: Partial<Client>) => ({
  ...client,
});

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    resetClientsState: () => ({
      ...initialState,
    }),
    setClientsState: (state, action: PayloadAction<Partial<ClientsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertClient: (state, action: PayloadAction<Partial<Client>>) => {
      const data: Client = getClientData(action.payload) as Client;

      if (typeof data.id === 'undefined') return;

      state.clients = upsertData(state.clients, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ClientActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Client>;
      consoleLog('CLIENTS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.clients = upsertData(state.clients, data);
      }
    });
    builder.addCase(ClientActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Client>;

      if (status === 503) return;

      if (data) {
        state.clients = data;
      }
    });
    builder.addCase(ClientActions.get.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Client>;

      if (typeof data.id === 'undefined') return;
      state.clients = upsertData(state.clients, data);
    });
    builder.addCase(ClientActions.put.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<Client>;

      if (typeof data.id === 'undefined') return;

      state.clients = upsertData(state.clients, data);
    });
    builder.addCase(ClientActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Client>;

      if (typeof data.id === 'undefined') return;
      state.clients = state.clients.filter((client) => client.id !== data.id);
    });

    builder.addCase(clientManagersActions.put.fulfilled, (state, { payload }) => {
      consoleLog('CLIENT MANAGERS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<ClientManager>;

      if (typeof data.id === 'undefined') return;

      const currentClient = state.clients.find((client) => client.id !== data.clientId);
      state.clients = state.clients.filter((client) => client.id !== data.id);

      if (currentClient?.managers) {
        currentClient.managers = currentClient.managers.filter((manager) => manager.id !== data.id);
        currentClient.managers.push(data);
        state.clients.push(currentClient);
      }
    });

    builder.addCase(clientManagersActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('CLIENT MANAGERS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<ClientManager>;

      if (typeof data.id === 'undefined') return;

      const currentClient = state.clients.find((client) => client.id !== data.clientId);
      state.clients = state.clients.filter((client) => client.id !== data.id);

      if (currentClient?.managers) {
        currentClient.managers = currentClient.managers.filter((manager) => manager.id !== data.id);
        state.clients.push(currentClient);
      }
    });
    builder.addCase(ClientActions.getAllClientEmployees.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'GET-ALL-CLIENT-EMPLOYEES', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Client['employees']>;

      if (status === 503) return;

      if (data) {
        state.clientEmployees = data;
      }
    });
  },
});

export const clientsReducerActions = clientsSlice.actions;
export default clientsSlice.reducer;
