import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuth from '../../hooks/auths/useAuth';
import PayrollCreateManagement from '../organisms/payroll/payrollModule/payslip/PayrollCreateManagement';
import PayslipViewManagement from '../organisms/payroll/payrollModule/payslip/PayslipViewManagement';
import ThirteenthMonthMonthly from '../organisms/payroll/payrollModule/13month/monthly/ThirteenthMonthMonthly';
import ThirteenthMonthSemiAnnual from '../organisms/payroll/payrollModule/13month/semiAnnual/ThirteenthMonthSemiAnnual';
import LastPay from '../organisms/payroll/payrollModule/lastPay/showLastPay/Lastpay';
import LastPayGenerate from '../organisms/payroll/payrollModule/lastPay/generateNew/LastPayGenerate';
import PayrollDefaultView from '../organisms/payroll/payrollModule/payslip/PayrollDefaultView';
import PayrollNavigation from '../organisms/payroll/payrollModule/PayrollNavigation';
import ManageIncentivesPage from '../organisms/payroll/payrollModule/others/manageIncentives/ManageIncentivesPage';

const PayrollTemplate: React.FC = () => {
  const { authState } = useAuth();

  if (!authState.token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="">
      <PayrollNavigation />
      <div className="w-full h-full payroll-template-wrapper">
        <Routes>
          <Route path="/" element={<PayrollDefaultView />} />
          <Route path="view" element={<PayslipViewManagement />} />
          <Route path="create" element={<PayrollCreateManagement />} />
          <Route path="13month/monthly" element={<ThirteenthMonthMonthly />} />
          <Route path="13month/semi-annual" element={<ThirteenthMonthSemiAnnual />} />
          <Route path="last-pay" element={<LastPay />} />
          <Route path="last-pay/generate" element={<LastPayGenerate />} />
          <Route path="incentive/add" element={<ManageIncentivesPage />} />
          <Route path="*" element={<Navigate replace to="/newsfeed" />} />
        </Routes>
      </div>
    </div>
  );
};

export default PayrollTemplate;
