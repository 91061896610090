import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { Typography } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import Team from '../../../store/constants/teams/team.interface';
import TextWithIcons from '../../molecules/TextWithIcons';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import consoleLog from '../../../utils/consoleLog';
import AttendanceListRow from './AttendancePageListRow';
import SelectTeam from '../../atoms/dropdowns/SelectTeam';
import SelectDepartment from '../../atoms/dropdowns/SelectDepartment';
import Department from '../../../store/constants/departments/department.interface';
import useTeams from '../../../hooks/teams/useTeams';

const AttendancePageList: React.FC = () => {
  const { getAll, teamsState } = useTeams();

  const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>();
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();

  const teams = teamsState.teams
    .filter((team: Team) => team.fte !== 0)
    .filter((team: Team) => {
      if (selectedDepartment) {
        return team.department?.id === selectedDepartment.id;
      }
      return true;
    })
    .filter((team: Team) => {
      if (selectedTeam) {
        return team.id === selectedTeam.id;
      }
      return true;
    });
  const onGenerateClick = async () => {
    getAll().then((r) => {
      consoleLog('DELETE POSITION', r);
    });
  };

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    const itemHeight = 50;
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full organisms-attendance-list pr-5">
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="flex space-x-10">
            <div className="w-full ">
              <div className="w-full space-y-2">
                <Typography className="muiTypography--headline6">Select Department</Typography>
                <SelectDepartment
                  id="department"
                  name="department"
                  value={selectedDepartment}
                  onChange={(value) => setSelectedDepartment(value as Team)}
                  hasSearchIcon
                  isSearchable
                  isClearable
                />
              </div>
            </div>
            <div className="space-y-2 w-full">
              <Typography className="muiTypography--headline6">Search Team</Typography>
              <SelectTeam
                id="team"
                name="team"
                value={selectedTeam}
                onChange={(value) => setSelectedTeam(value as Team)}
                hasSearchIcon
                isSearchable
                isClearable
              />
            </div>
          </div>
        </Form>
      </Formik>
      <div className="p-2 border-b-2 attendance-list-header header-items">
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Team</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Department</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Team Leader</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">FTE</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={teams.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <AttendanceListRow
            data={teams[index]}
            className={`${index % 2 === 0 ? 'stripped' : ''}`}
            style={style}
            onGenerateClick={onGenerateClick}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default AttendancePageList;
