import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import UserActions from '../../actions/users/userActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import AtLeast from '../../../types/atleast';
import User from '../../constants/users/user.interface';
import UserGender from '../../constants/users/user-gender.enum';

export interface UserGenderOption {
  value: UserGender;
  label: string;
}

export interface UserRelation {
  id?: number;
  value: string;
  label: string;
}

export interface UsersState {
  users: User[];
  userGenders: UserGenderOption[];
  userRelation: UserRelation[];
}

const initialState: UsersState = {
  users: [],
  userGenders: [
    {
      value: UserGender.MALE,
      label: 'Male',
    },
    {
      value: UserGender.FEMALE,
      label: 'Female',
    },
  ],
  userRelation: [
    {
      id: 1,
      value: 'Sister',
      label: 'Sister',
    },
    {
      id: 2,
      value: 'Brother',
      label: 'Brother',
    },
    {
      id: 3,
      value: 'Mother',
      label: 'Mother',
    },
    {
      id: 4,
      value: 'Father',
      label: 'Father',
    },
    {
      id: 5,
      value: 'Daughter',
      label: 'Daughter',
    },
    {
      id: 6,
      value: 'Son',
      label: 'Son',
    },
    {
      id: 7,
      value: 'Aunt',
      label: 'Aunt',
    },
    {
      id: 8,
      value: 'Uncle',
      label: 'Uncle',
    },
    {
      id: 9,
      value: 'Niece',
      label: 'Niece',
    },
    {
      id: 10,
      value: 'Nephew',
      label: 'Nephew',
    },
    {
      id: 11,
      value: 'Cousin (female)',
      label: 'Cousin (female)',
    },
    {
      id: 12,
      value: 'Cousin (male)',
      label: 'Cousin (male)',
    },
    {
      id: 13,
      value: 'Grandmother',
      label: 'Grandmother',
    },
    {
      id: 14,
      value: 'Grandfather',
      label: 'Grandfather',
    },
    {
      id: 15,
      value: 'Granddaughter',
      label: 'Granddaughter',
    },
    {
      id: 16,
      value: 'Grandson',
      label: 'Grandson',
    },
    {
      id: 17,
      value: 'Stepsister',
      label: 'Stepsister',
    },
    {
      id: 18,
      value: 'Stepbrother',
      label: 'Stepbrother',
    },
    {
      id: 19,
      value: 'Stepmother',
      label: 'Stepmother',
    },
    {
      id: 20,
      value: 'Stepfather',
      label: 'Stepfather',
    },
    {
      id: 21,
      value: 'Stepdaughter',
      label: 'Stepdaughter',
    },
    {
      id: 22,
      value: 'Stepson',
      label: 'Stepson',
    },
    {
      id: 23,
      value: 'Sister-in-law',
      label: 'Sister-in-law',
    },
    {
      id: 24,
      value: 'Brother-in-law',
      label: 'Brother-in-law',
    },
    {
      id: 25,
      value: 'Mother-in-law',
      label: 'Mother-in-law',
    },
    {
      id: 26,
      value: 'Father-in-law',
      label: 'Father-in-law',
    },
    {
      id: 27,
      value: 'Daughter-in-law',
      label: 'Daughter-in-law',
    },
    {
      id: 28,
      value: 'Son-in-law',
      label: 'Son-in-law',
    },
    {
      id: 29,
      value: 'Sibling (gender neutral)',
      label: 'Sibling (gender neutral)',
    },
    {
      id: 30,
      value: 'Parent (gender neutral)',
      label: 'Parent (gender neutral)',
    },
    {
      id: 31,
      value: 'Child (gender neutral)',
      label: 'Child (gender neutral)',
    },
    {
      id: 32,
      value: 'Sibling of parent (gender neutral)',
      label: 'Sibling of parent (gender neutral)',
    },
    {
      id: 33,
      value: 'Child of sibling (gender neutral)',
      label: 'Child of sibling (gender neutral)',
    },
    {
      id: 34,
      value: 'Cousin (gender neutral)',
      label: 'Cousin (gender neutral)',
    },
    {
      id: 35,
      value: 'Grandparent (gender neutral)',
      label: 'Grandparent (gender neutral)',
    },
    {
      id: 36,
      value: 'Grandchild (gender neutral)',
      label: 'Grandchild (gender neutral)',
    },
    {
      id: 37,
      value: 'Step sibling (gender neutral)',
      label: 'Step sibling (gender neutral)',
    },
    {
      id: 38,
      value: 'Step parent (gender neutral)',
      label: 'Step parent (gender neutral)',
    },
    {
      id: 39,
      value: 'Step child (gender neutral)',
      label: 'Step child (gender neutral)',
    },
    {
      id: 40,
      value: 'Sibling-in-law (gender neutral)',
      label: 'Sibling-in-law (gender neutral)',
    },
    {
      id: 41,
      value: 'Parent-in-law (gender neutral)',
      label: 'Parent-in-law (gender neutral)',
    },
    {
      id: 42,
      value: 'Child-in-law (gender neutral)',
      label: 'Child-in-law (gender neutral)',
    },
    {
      id: 43,
      value: 'Spouse',
      label: 'Spouse',
    },
    {
      id: 44,
      value: 'Partner',
      label: 'Partner',
    },
  ],
};

const getUserData = (user: Partial<User>) => ({
  ...user,
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsersState: () => ({
      ...initialState,
    }),
    setUsersState: (state, action: PayloadAction<Partial<UsersState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertUser: (state, action: PayloadAction<Partial<User>>) => {
      const data: User = getUserData(action.payload) as User;

      if (typeof data.id === 'undefined') return;

      state.users = upsertData(state.users, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UserActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<User>;
      consoleLog('USERS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.users = upsertData(state.users, data);
      }
    });
    builder.addCase(UserActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<User>;

      if (status === 503) return;

      if (data) {
        state.users = data;
      }
    });
    builder.addCase(UserActions.get.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<User>;

      if (!data || typeof data.id === 'undefined') return;
      state.users = upsertData(state.users, data);
    });

    builder.addCase(UserActions.getContact.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'GET CONTACT', payload);

      const { data } = payload as ApiBaseResponse<AtLeast<User, 'id' | 'contact' | 'address'>>;

      if (!data || typeof data.id === 'undefined') return;
      state.users = upsertData(state.users, data as User);
    });

    builder.addCase(UserActions.getEmployment.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'GET EMPLOYMENT', payload);

      const { data } = payload as ApiBaseResponse<AtLeast<User, 'id' | 'employment'>>;

      if (!data || typeof data.id === 'undefined') return;
      state.users = upsertData(state.users, data as User);
    });

    builder.addCase(UserActions.getBank.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'GET BANK', payload);

      const { data } = payload as ApiBaseResponse<AtLeast<User, 'id' | 'bank'>>;

      if (!data || typeof data.id === 'undefined') return;
      state.users = upsertData(state.users, data as User);
    });

    builder.addCase(UserActions.getAccountNumber.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'GET ACCOUNT NUMBER', payload);

      const { data } = payload as ApiBaseResponse<AtLeast<User, 'id' | 'accountNumber'>>;

      if (!data || typeof data.id === 'undefined') return;
      state.users = upsertData(state.users, data as User);
    });

    builder.addCase(UserActions.put.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<User>;

      if (typeof data.id === 'undefined') return;
      state.users = upsertData(state.users, data);
    });
    builder.addCase(UserActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('USERS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<User>;

      if (typeof data.id === 'undefined') return;
      state.users = state.users.filter((user) => user.id !== data.id);
    });
  },
});

export const usersReducerActions = usersSlice.actions;
export default usersSlice.reducer;
