import { useAppDispatch, useAppSelector } from '../../store/store';
import bankActions from '../../store/actions/banks/bankActions';
import { banksReducerActions, BanksState } from '../../store/reducers/banks/banksReducer';
import Bank from '../../store/constants/banks/bank.interface';

const useBanks = () => {
  const dispatch = useAppDispatch();
  const banksState: BanksState = useAppSelector((state) => state.banks);

  const resetBankState = () => {
    dispatch(banksReducerActions.resetBanksState());
  };

  const setBankState = (state: Partial<BanksState>) => {
    dispatch(banksReducerActions.setBanksState(state));
  };

  const get = async (bankId: Bank['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(bankActions.get(bankId));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(bankActions.getAll({}));

  const post = async (bankData: Omit<Bank, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(bankActions.post(bankData));

  const put = async (bank: Partial<Bank>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(bankActions.put(bank));

  const remove = async (id: Bank['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(bankActions.remove(id));

  return {
    resetBankState,
    banksState,
    setBankState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useBanks;
