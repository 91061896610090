import React from 'react';
import BillingGroupsTable from './groups/BillingGroupsTable';
import ManagementHeading from '../../atoms/ManagementHeading';

const BillingGroups = () => (
  <div className="m-2 mr-20">
    <div className="m-2 title">
      <ManagementHeading title="Billing Group" subtitle="Management" />
    </div>
    <div className="">
      <BillingGroupsTable />
    </div>
  </div>
);

export default BillingGroups;
