import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Card,
  List,
  // ListItem,
  // ListItemPrefix,
  Spinner,
} from '@material-tailwind/react';
import ImageWithDefault from '../../../atoms/ImageWithDefault';
import useAuth from '../../../../hooks/auths/useAuth';
import AdminAccordionMenu from '../../../molecules/AdminAccordionMenu';
import LinkMenu from '../../../atoms/LinkMenu';
import useUser from '../../../../hooks/users/useUser';
import useAccordionMenu from '../../../../hooks/utils/useAccordionMenu';
import { adminMenu, payrollMenu, reportsMenu, billingMenu } from './SidebarMenuOptions';
import { ReactComponent as Home } from '../../../../assets/images/icons/home-solid.svg';
import AccordionMenu from '../../../molecules/AccordionMenu';

const AdminPageSidebar: React.FC = () => {
  const { openedItems, toggleItem } = useAccordionMenu();
  const { loggedUser } = useAuth();
  const { currentUser, loading } = useUser((loggedUser?.userId ?? '').toString());

  const userDataImage = {
    ...currentUser,
    imageProfileImage:
      'https://images.pexels.com/photos/653429/pexels-photo-653429.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  };

  const employeeName = currentUser?.employment?.currentPosition?.employmentPosition.name;
  const employeeTier = currentUser?.employment?.currentPosition?.tier;

  // const navigate = useNavigate();
  const pathArray = useLocation().pathname.split('/');

  // const onLogoutClick = () => {
  //   navigate('/logout');
  // };

  const singleMenuClick = () => {
    openedItems.forEach((openedItem) => {
      toggleItem(openedItem);
    });
  };

  return (
    <div>
      <div className="hidden lg:block">
        <Card className="shadow-xl sidebar-card-wrapper">
          <div className="account-profile-information">
            <div className="flex space-x-3">
              <div className="cursor-pointer rounded-full sidebar-image-wrapper">
                {loading ? (
                  <div className="h-full w-full flex justify-center items-center">
                    <Spinner height="1rem" color="deep-orange" />
                  </div>
                ) : (
                  <Link to="/my/profile">
                    <ImageWithDefault
                      className="h-full w-full sidebar-profile-image"
                      src={userDataImage.imageProfileImage}
                      defaultImageUrl="/assets/images/profile/default.png"
                      alt={`${loggedUser?.fullName.toUpperCase()} Profile Image`}
                    />
                  </Link>
                )}
              </div>
              <div className="flex justify-center m-auto cursor-pointer sidebar-main-info">
                <Link to="/my/profile">
                  <div className="flex gap-1 sidebar-fullname-wrapper">
                    <span className="sidebar-lastname">{loggedUser?.lastName}, </span>
                    <span className="sidebar-firstname">{loggedUser?.firstName}</span>
                  </div>
                  <div className="sidebar-user-position">
                    {employeeTier ? (
                      <span className="">{`${employeeName} ${employeeTier}`}</span>
                    ) : (
                      <span />
                    )}
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="h-full bg-[#2C2C2E]">
            <List className="adminside-menu-content bg-[#2C2C2E]">
              <LinkMenu
                menuTitle="Dashboard"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="sidebar-icon"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.045 6.954a2.75 2.75 0 0 1 .217-.678L2.53 3.58A2.75 2.75 0 0 1 5.019 2h9.962a2.75 2.75 0 0 1 2.488 1.58l1.27 2.696c.101.216.174.444.216.678A1 1 0 0 1 19 7.25v1.5a2.75 2.75 0 0 1-2.75 2.75H3.75A2.75 2.75 0 0 1 1 8.75v-1.5a1 1 0 0 1 .045-.296Zm2.843-2.736A1.25 1.25 0 0 1 5.02 3.5h9.962c.484 0 .925.28 1.13.718l.957 2.032H14a1 1 0 0 0-.86.49l-.606 1.02a1 1 0 0 1-.86.49H8.236a1 1 0 0 1-.894-.553l-.448-.894A1 1 0 0 0 6 6.25H2.932l.956-2.032Z"
                      clipRule="evenodd"
                    />
                    <path d="M1 14a1 1 0 0 1 1-1h4a1 1 0 0 1 .894.553l.448.894a1 1 0 0 0 .894.553h3.438a1 1 0 0 0 .86-.49l.606-1.02A1 1 0 0 1 14 13h4a1 1 0 0 1 1 1v2a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-2Z" />
                  </svg>
                }
                pathArray={pathArray}
                toPath="/admin/dashboard"
                onClick={singleMenuClick}
              />
              {/* ADMIN */}
              <AdminAccordionMenu
                menuTitle="Admin Manager"
                openNumber={2}
                options={adminMenu}
                open={openedItems.includes(2) ? 2 : 0}
                handleOpen={() => toggleItem(2)}
                accordionIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="sidebar-icon"
                  >
                    <path d="M16.555 5.412a8.028 8.028 0 0 0-3.503-2.81 14.899 14.899 0 0 1 1.663 4.472 8.547 8.547 0 0 0 1.84-1.662ZM13.326 7.825a13.43 13.43 0 0 0-2.413-5.773 8.087 8.087 0 0 0-1.826 0 13.43 13.43 0 0 0-2.413 5.773A8.473 8.473 0 0 0 10 8.5c1.18 0 2.304-.24 3.326-.675ZM6.514 9.376A9.98 9.98 0 0 0 10 10c1.226 0 2.4-.22 3.486-.624a13.54 13.54 0 0 1-.351 3.759A13.54 13.54 0 0 1 10 13.5c-1.079 0-2.128-.127-3.134-.366a13.538 13.538 0 0 1-.352-3.758ZM5.285 7.074a14.9 14.9 0 0 1 1.663-4.471 8.028 8.028 0 0 0-3.503 2.81c.529.638 1.149 1.199 1.84 1.66ZM17.334 6.798a7.973 7.973 0 0 1 .614 4.115 13.47 13.47 0 0 1-3.178 1.72 15.093 15.093 0 0 0 .174-3.939 10.043 10.043 0 0 0 2.39-1.896ZM2.666 6.798a10.042 10.042 0 0 0 2.39 1.896 15.196 15.196 0 0 0 .174 3.94 13.472 13.472 0 0 1-3.178-1.72 7.973 7.973 0 0 1 .615-4.115ZM10 15c.898 0 1.778-.079 2.633-.23a13.473 13.473 0 0 1-1.72 3.178 8.099 8.099 0 0 1-1.826 0 13.47 13.47 0 0 1-1.72-3.178c.855.151 1.735.23 2.633.23ZM14.357 14.357a14.912 14.912 0 0 1-1.305 3.04 8.027 8.027 0 0 0 4.345-4.345c-.953.542-1.971.981-3.04 1.305ZM6.948 17.397a8.027 8.027 0 0 1-4.345-4.345c.953.542 1.971.981 3.04 1.305a14.912 14.912 0 0 0 1.305 3.04Z" />
                  </svg>
                }
              />
              {/* REPORTS */}
              <AdminAccordionMenu
                menuTitle="Reports Manager"
                openNumber={3}
                options={reportsMenu}
                open={openedItems.includes(3) ? 3 : 0}
                handleOpen={() => toggleItem(3)}
                accordionIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="sidebar-icon"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 2.75A.75.75 0 0 1 1.75 2h16.5a.75.75 0 0 1 0 1.5H18v8.75A2.75 2.75 0 0 1 15.25 15h-1.072l.798 3.06a.75.75 0 0 1-1.452.38L13.41 18H6.59l-.114.44a.75.75 0 0 1-1.452-.38L5.823 15H4.75A2.75 2.75 0 0 1 2 12.25V3.5h-.25A.75.75 0 0 1 1 2.75ZM7.373 15l-.391 1.5h6.037l-.392-1.5H7.373Zm7.49-8.931a.75.75 0 0 1-.175 1.046 19.326 19.326 0 0 0-3.398 3.098.75.75 0 0 1-1.097.04L8.5 8.561l-2.22 2.22A.75.75 0 1 1 5.22 9.72l2.75-2.75a.75.75 0 0 1 1.06 0l1.664 1.663a20.786 20.786 0 0 1 3.122-2.74.75.75 0 0 1 1.046.176Z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
              />

              {/* PAYROLL */}
              <AdminAccordionMenu
                menuTitle="Payroll Manager"
                openNumber={4}
                options={payrollMenu}
                open={openedItems.includes(4) ? 4 : 0}
                handleOpen={() => toggleItem(4)}
                accordionIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="sidebar-icon"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4Zm12 4a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM4 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm13-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM1.75 14.5a.75.75 0 0 0 0 1.5c4.417 0 8.693.603 12.749 1.73 1.111.309 2.251-.512 2.251-1.696v-.784a.75.75 0 0 0-1.5 0v.784a.272.272 0 0 1-.35.25A49.043 49.043 0 0 0 1.75 14.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
              />

              <AccordionMenu
                menuTitle="Billing Manager"
                openNumber={22}
                options={billingMenu}
                open={openedItems.includes(22) ? 22 : 0}
                handleOpen={() => toggleItem(22)}
                accordionIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="sidebar-icon"
                  >
                    <path
                      fillRule="evenodd"
                      d="M.99 5.24A2.25 2.25 0 0 1 3.25 3h13.5A2.25 2.25 0 0 1 19 5.25l.01 9.5A2.25 2.25 0 0 1 16.76 17H3.26A2.267 2.267 0 0 1 1 14.74l-.01-9.5Zm8.26 9.52v-.625a.75.75 0 0 0-.75-.75H3.25a.75.75 0 0 0-.75.75v.615c0 .414.336.75.75.75h5.373a.75.75 0 0 0 .627-.74Zm1.5 0a.75.75 0 0 0 .627.74h5.373a.75.75 0 0 0 .75-.75v-.615a.75.75 0 0 0-.75-.75H11.5a.75.75 0 0 0-.75.75v.625Zm6.75-3.63v-.625a.75.75 0 0 0-.75-.75H11.5a.75.75 0 0 0-.75.75v.625c0 .414.336.75.75.75h5.25a.75.75 0 0 0 .75-.75Zm-8.25 0v-.625a.75.75 0 0 0-.75-.75H3.25a.75.75 0 0 0-.75.75v.625c0 .414.336.75.75.75H8.5a.75.75 0 0 0 .75-.75ZM17.5 7.5v-.625a.75.75 0 0 0-.75-.75H11.5a.75.75 0 0 0-.75.75V7.5c0 .414.336.75.75.75h5.25a.75.75 0 0 0 .75-.75Zm-8.25 0v-.625a.75.75 0 0 0-.75-.75H3.25a.75.75 0 0 0-.75.75V7.5c0 .414.336.75.75.75H8.5a.75.75 0 0 0 .75-.75Z"
                      clipRule="evenodd"
                    />
                  </svg>
                }
              />

              <hr className="mt-3 mb-3 rounded-md border-1 opacity-10 sidebar-borders" />

              <LinkMenu
                menuTitle="Return to Home"
                icon={<Home className="sidebar-icon" />}
                pathArray={pathArray}
                toPath="/user/home"
                onClick={singleMenuClick}
              />

              {/* <ListItem className="sidebar-borders flex items-center gap-2 p-2 cursor-pointer hover:bg-[#f05825] focus:bg-[#f05825] hidden">
              <ListItem className="sidebar-borders items-center gap-2 p-2 cursor-pointer hover:bg-[#f05825] focus:bg-[#f05825] hidden">
                <ListItemPrefix className="sidebar-icon">
                  <LogoutIcon className="sidebar-icon" />
                </ListItemPrefix>
                <button
                  className="flex w-full h-full flex-start"
                  type="button"
                  onClick={onLogoutClick}
                >
                  <span className="sidebar-text">Log Out</span>
                </button>
              </ListItem> */}
            </List>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AdminPageSidebar;
