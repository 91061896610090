import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import NurseStationVisit from '../../../constants/my/healthTracker/nurse-station-visit.interface';
import nurseStationVisitServices, {
  GetAllNurseStationVisitsQueryParams,
} from '../../../services/my/healthTracker/nurseStationVisitServices';

const get = createAsyncThunk(
  'nurseStationVisit/get',
  async (
    id: NurseStationVisit['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<NurseStationVisit> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await nurseStationVisitServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'nurseStationVisit/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllNurseStationVisitsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<NurseStationVisit> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await nurseStationVisitServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'nurseStationVisit/post',
  async (
    data: Omit<NurseStationVisit, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<NurseStationVisit> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await nurseStationVisitServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'nurseStationVisit/put',
  async (
    data: AtLeast<NurseStationVisit, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<NurseStationVisit> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await nurseStationVisitServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'nurseStationVisit/remove',
  async (
    id: NurseStationVisit['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<NurseStationVisit> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await nurseStationVisitServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
