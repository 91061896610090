import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { ErrorMessage, Form, Formik } from 'formik';
import { VariableSizeList } from 'react-window';
import { Spinner } from '@material-tailwind/react';
import DateRangePicker from 'react-datepicker';
import { useDebounce } from 'use-debounce';
import LeaveListRow from './LeaveListRow';
import Team from '../../../../store/constants/teams/team.interface';
import Department from '../../../../store/constants/departments/department.interface';
import SortableTableHeader, { HeaderOption } from '../../../molecules/SortableTableHeader';
import LeaveListFormModal from './LeaveListFormModal';
import useLeaves from '../../../../hooks/leave/useLeaves';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import SelectDepartment from '../../../atoms/dropdowns/SelectDepartment';
import SelectTeam from '../../../atoms/dropdowns/SelectTeam';
import SelectLeaveStatusOption from '../../../atoms/dropdowns/SelectLeaveStatusOption';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import SelectEmployee from '../../../atoms/dropdowns/SelectEmployee';
import UserEmployment from '../../../../store/constants/employees/employee.interface';
import LeaveStatusOption from '../../../../store/constants/leaves/leave-status-option';
import { Duration } from '../../../../store/services/leave/leaveServices';
import EmptyListMessage from '../../../atoms/EmptyListMessage';
import useDateTime from '../../../../hooks/utils/useDateTime';
import useVariableItemSize from '../../../../hooks/utils/useVariableItemSize';
import { ReactComponent as Search } from '../../../../assets/images/icons/search.svg';

interface LeavesFilterState {
  selectedTeam: Team | undefined;
  selectedDepartment: Department | undefined;
  selectedLeaveList: LeaveApplication | undefined;
  selectedUserEmployment: UserEmployment | undefined;
  selectedStatus: LeaveStatusOption | undefined;
  isLoading: boolean;
}

type SortByHeaderOptions = '' | keyof LeaveApplication | 'name';
type CustomTypeLeaveApplication = LeaveApplication & {
  name?: string;
};

const LeaveList: React.FC = () => {
  const location = useLocation();
  const { getDate } = useDateTime();
  const { leavesState, getAll: getAllLeaves, setLeaveState, remove } = useLeaves();
  const { getItemSize } = useVariableItemSize();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  // @todo: this is a test for when data is passed from another component
  // eslint-disable-next-line
  const foreignData = location.state?.record;
  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const { getUserEmploymentByEmployeeNumber } = useUserEmployments();

  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [LeavesFilterState, setLeavesFilterState] = useState<LeavesFilterState>({
    selectedTeam: undefined,
    selectedLeaveList: undefined,
    selectedDepartment: undefined,
    selectedUserEmployment: undefined,
    selectedStatus: { value: 'Pending', label: 'Pending' },
    isLoading: false,
  });

  const {
    selectedTeam,
    selectedLeaveList,
    selectedDepartment,
    selectedUserEmployment,
    selectedStatus,
    isLoading,
  } = LeavesFilterState;

  // eslint-disable-next-line
  const [debouncedSearchValue] = useDebounce(searchValue, 1500);

  const getFirstDayOfCurrentMonth = () => moment().startOf('month').toDate();
  const getLastDayOfNextMonth = () => moment().add(2, 'months').endOf('month').toDate();
  const defaultStartDate = getFirstDayOfCurrentMonth();
  const defaultEndDate = getLastDayOfNextMonth();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [dateStart, dateEnd] = dateRange;

  const duration = {} as Duration;
  duration.dateStart = dateStart ? getDate(dateStart) : undefined;
  duration.dateEnd = dateEnd ? getDate(dateEnd) : undefined;

  const leaves = leavesState.leaves
    .filter((leave: LeaveApplication) => !!leave)
    .filter((leave) => {
      const searchKeywords = debouncedSearchValue?.toLowerCase().split(' ');

      const keywordMatches = (keyword: string) => {
        const keywords = keyword.split(' ');
        let found = false;
        keywords.forEach((search) => {
          if (
            leave.employeeNumber.toString().toLowerCase().includes(search) ||
            getUserEmploymentByEmployeeNumber(leave.employeeNumber)
              ?.userInformation.fullName.toLowerCase()
              .includes(search) ||
            leave.notes.toLowerCase().includes(search) ||
            leave.leaveNature?.nature.toLowerCase().includes(search) ||
            leave.status.toLowerCase().includes(search)
          ) {
            found = true;
          }
        });
        return found;
      };

      return searchKeywords?.every(keywordMatches);
    })
    .sort((a: LeaveApplication, b: LeaveApplication) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'name') {
        const aName =
          getUserEmploymentByEmployeeNumber(
            a.employeeNumber,
          )?.userInformation.fullName.toLowerCase() ?? '';
        const bName =
          getUserEmploymentByEmployeeNumber(
            b.employeeNumber,
          )?.userInformation.fullName.toLowerCase() ?? '';
        return sortOrder * aName.localeCompare(bName);
      }

      if (sortOption === 'team') return sortOrder * a.team.name.localeCompare(b.team.name);

      if (sortOption === 'dateCreated')
        return sortOrder * (new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());

      if (sortOption === 'dateStart')
        return sortOrder * (new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime());

      if (sortOption === 'leaveNature') {
        if (!a.leaveNatureId) return -sortOrder;
        if (!b.leaveNatureId) return sortOrder;

        const aNature = leavesState.leaveNatures.find((nature) => nature.id === a.leaveNatureId);
        const bNature = leavesState.leaveNatures.find((nature) => nature.id === b.leaveNatureId);

        if (!aNature || !bNature) return 0;
        const aNatureString = aNature?.nature ?? '';
        const bNatureString = bNature?.nature ?? '';

        return sortOrder * aNatureString.localeCompare(bNatureString);
      }

      if (sortOption === 'notes') return sortOrder * a.notes.localeCompare(b.notes);

      if (sortOption === 'status') return sortOrder * a.status.localeCompare(b.status);

      const dateA = new Date(a.dateCreated).getTime();
      const dateB = new Date(b.dateCreated).getTime();

      return dateB - dateA;
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onLeaveModalClose = () => {
    setLeavesFilterState({ ...LeavesFilterState, selectedLeaveList: undefined });
  };

  const manageLeaveList = (leave: LeaveApplication) => {
    setLeavesFilterState({ ...LeavesFilterState, selectedLeaveList: leave });
  };

  const handleDelete = async (leave: LeaveApplication) => {
    remove(leave.id).then();
  };

  const headers: HeaderOption<CustomTypeLeaveApplication>[] = [
    { value: 'name', label: 'Name' },
    { value: 'team', label: 'Team' },
    { value: 'dateCreated', label: 'Date Filed' },
    { value: 'dateStart', label: 'Date of leave' },
    { value: 'leaveNature', label: 'Nature' },
    { value: 'notes', label: 'Reason' },
    { value: 'status', label: 'Status', center: true },
  ];

  useEffect(() => {
    setLeavesFilterState({ ...LeavesFilterState, isLoading: true });
    setLeaveState({ leaves: [] });
    const formattedStartDate = dateStart ? getDate(dateStart) : undefined;
    const formattedEndDate = dateEnd ? getDate(dateEnd) : undefined;

    getAllLeaves({
      employeeNumber: selectedUserEmployment?.employeeNumber,
      teamId: selectedTeam?.id,
      status: selectedStatus?.value,
      departmentId: selectedDepartment?.id,
      ...(formattedEndDate && {
        duration: {
          dateStart: formattedStartDate,
          dateEnd: formattedEndDate,
        },
      }),
    }).finally(() => setLeavesFilterState({ ...LeavesFilterState, isLoading: false }));
    // eslint-disable-next-line
  }, [
    selectedUserEmployment,
    selectedTeam,
    selectedDepartment,
    selectedStatus,
    dateStart,
    dateEnd,
  ]);

  return (
    <div className="organisms-leave-list px-5 w-full">
      <div className="flex justify-center pb-5">
        <LeaveListFormModal
          leave={selectedLeaveList as LeaveApplication}
          onClose={onLeaveModalClose}
        />
        <div className="gap-5 items-center w-full">
          <Formik
            initialValues={{
              ...selectedTeam,
              ...selectedDepartment,
            }}
            onSubmit={() => {}}
          >
            <Form>
              {/* First Content */}
              <div className="mb-4 leave-content-wrapper">
                {/* Department */}
                <div className="leave-department-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Department search</h2>
                  <div className="w-full department-search-wrapper">
                    <SelectDepartment
                      id="department"
                      name="department"
                      value={selectedDepartment}
                      onChange={(value) =>
                        setLeavesFilterState({
                          ...LeavesFilterState,
                          selectedDepartment: value as Department,
                        })
                      }
                      hasSearchIcon
                      isSearchable
                      isClearable
                    />
                  </div>
                </div>
                {/* Team */}
                <div className="w-full leave-team-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Team search</h2>
                  <div className="w-full team-search-wrapper">
                    <SelectTeam
                      id="team"
                      name="team"
                      value={selectedTeam}
                      onChange={(value) =>
                        setLeavesFilterState({
                          ...LeavesFilterState,
                          selectedTeam: value as Team,
                        })
                      }
                      hasSearchIcon
                      isSearchable
                      isClearable
                    />
                  </div>
                </div>
                {/* Employee Search */}
                <div className="w-full leave-search-wrapper">
                  <label htmlFor="employee" className="w-fit pl-1 mb-1 block">
                    Employee search
                  </label>
                  <div className="w-full employee-search-wrapper">
                    <SelectEmployee
                      id="employee"
                      name="employee"
                      placeholder="Select employee"
                      value={selectedUserEmployment}
                      onChange={(value) =>
                        setLeavesFilterState({
                          ...LeavesFilterState,
                          selectedUserEmployment: value as UserEmployment,
                        })
                      }
                      hasSearchIcon
                      isSearchable
                      isClearable
                    />
                    <ErrorMessage name="employee" component="div" />
                  </div>
                </div>
              </div>
              <div className="leave-content-wrapper">
                {/* Date picker */}
                <div className="w-full leave-date-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Date of Leave</h2>
                  <div className="w-full h-fit date-wrapper datePicker-main-parent">
                    <DateRangePicker
                      className="rounded-lg datepicker-list-layout datepicker-list-date"
                      startDate={dateStart}
                      endDate={dateEnd}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      selectsRange
                      isClearable
                      placeholderText="Select Date Range..."
                    />
                  </div>
                </div>
                {/* Status */}
                <div className="w-full leave-status-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Status</h2>
                  <div className="w-full status-search-wrapper">
                    <SelectLeaveStatusOption
                      id="status"
                      name="status"
                      value={selectedStatus as LeaveStatusOption}
                      onChange={(value) => {
                        setLeavesFilterState({
                          ...LeavesFilterState,
                          selectedStatus: value as unknown as LeaveStatusOption,
                        });
                      }}
                      isClearable
                      hasSearchIcon
                    />
                  </div>
                </div>
                {/* Leave Search */}
                <div className="w-full leave-search-wrapper">
                  <label htmlFor="leave" className="w-fit pl-1">
                    Leave search
                  </label>
                  <div className="w-full search-input-wrapper relative">
                    <input
                      onKeyDown={onSearchKeyDown}
                      type="text"
                      className="general-leave-search-input search-bar"
                      placeholder="Search Leave"
                      onChange={handleSearch}
                    />
                    <Search className="h-4 w-4 general-leave-search__icon" />
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <SortableTableHeader
        classNames="py-2 px-3 leave-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg"
        sortOption={sortOption}
        sortOrderBy={sortOrderBy}
        headers={headers}
        sortByHeader={sortByHeader}
      />

      {isLoading && (
        <div className="w-full p-5 flex justify-center">
          <Spinner color="deep-orange" className="mr-2" />
        </div>
      )}

      {!isLoading && leaves.length === 0 ? (
        <EmptyListMessage />
      ) : (
        <VariableSizeList
          itemCount={leaves.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <LeaveListRow
              data={leaves[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              manageLeaveList={manageLeaveList}
              onDeleteClick={handleDelete}
            />
          )}
        </VariableSizeList>
      )}
    </div>
  );
};

export default LeaveList;
