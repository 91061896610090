import { useAppDispatch, useAppSelector } from '../../store/store';
import {
  userEmploymentsReducerActions,
  UserEmploymentsState,
} from '../../store/reducers/users/userEmploymentsReducer';
import UserEmploymentActions from '../../store/actions/users/employments/userEmploymentActions';
import { UpdatePasswordParams } from '../../store/services/users/employments/userEmploymentServices';
import UserEmployment from '../../store/constants/employees/employee.interface';

const useUserEmployments = () => {
  const dispatch = useAppDispatch();
  const userEmploymentsStates: UserEmploymentsState = useAppSelector(
    (state) => state.userEmployments,
  );

  const resetUserEmploymentState = () => {
    dispatch(userEmploymentsReducerActions.resetUserEmploymentsState());
  };

  const setUserEmploymentState = (state: Partial<UserEmploymentsState>) => {
    dispatch(userEmploymentsReducerActions.setUserEmploymentsState(state));
  };

  const get = async (data: UserEmployment) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(UserEmploymentActions.get(data));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(UserEmploymentActions.getAll({}));

  const updatePassword = async (data: UpdatePasswordParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(UserEmploymentActions.updatePassword(data));

  const suspendAccount = async (EmployeeNumber: UserEmployment['employeeNumber'] | undefined) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(UserEmploymentActions.suspendAccount(EmployeeNumber));

  const activateAccount = async (EmployeeNumber: UserEmployment['employeeNumber'] | undefined) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(UserEmploymentActions.activateAccount(EmployeeNumber));

  const getUserEmploymentByEmployeeNumber = (
    employeeNumber: UserEmployment['employeeNumber'] | undefined,
  ) =>
    userEmploymentsStates.userEmployments.find(
      (userEmployment) => userEmployment.employeeNumber === employeeNumber,
    );

  const getEmployeeByEmployeeNumber = (employeeNumber: UserEmployment['employeeNumber']) =>
    userEmploymentsStates.userEmployments.find(
      (employee) => employee.employeeNumber === employeeNumber,
    );

  const getEmployeeNumberByUserId = (UserId: UserEmployment['userId']) =>
    userEmploymentsStates.userEmployments.find((employee) => employee.userId === UserId);

  return {
    resetUserEmploymentState,
    setUserEmploymentState,
    userEmploymentsStates,
    get,
    getAll,
    updatePassword,
    suspendAccount,
    activateAccount,
    getUserEmploymentByEmployeeNumber,
    getEmployeeByEmployeeNumber,
    getEmployeeNumberByUserId,
  };
};

export default useUserEmployments;
