import React from 'react';
import UserCalendar from '../UserCalendar';

const handBookContent = `
<section class="content">

        <!-- Default box -->
        <div class="box">

            <div class="box-body">

                <div class="panel" style="background: rgba(255, 255, 255, -0.3); -webkit-box-shadow: unset;">
                    <div class="panel-body">

                    <p style="text-align: justify;  margin-bottom: 10px;"><span style="color: rgb(255, 102, 0); font-size: 19pt;"><strong>TEAM HANDBOOK&nbsp;</strong></span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>INTRODUCTION</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Welcome to the <span style="color: #ff6600;"><strong>Awesome OS</strong> </span>Team!</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">At Awesome OS we believe that our Team Members that represent the company are the most vital factors to the company’s success. This means YOU!</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Why do we work? We all work for the experience and to get paid a salary. Our clients essentially pay for our salaries, our offices, our outings and events. If we keep our clients happy and <u>continue to make their lives easier</u>, they will keep paying our salaries. It's a simple formula for success.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">AwesomeOS will try to provide you the tools and skills necessary to keep our service at a high level. In addition, you are encouraged to be proactive in developing and growing your talents. Making our clients' HAPPY is the ultimate goal at AwesomeOS.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">This Team Handbook is a guide for everyone in understanding the policies, guidelines and norms of the company. &nbsp;Our policies are based on our core values and of course on local law requirements. You will find that in general, we treat every Team Member as a Team of adults and professionals.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">We encourage you to use this handbook as often as you want. If you have concerns or clarifications, the People Team will be more than willing to help.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The policies outlined below are subject to change at the sole discretion of AwesomeOS. </span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">WE make changes <u>constantly</u> to make ourselves better. Please check this Handbook periodically for any updates or changes.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Welcome to AwesomeOS!</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>PART I: HOW DID WE START?</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS, formerly known as Offsourcing Philippines Inc, which was started in 2006 in the city of Los Angeles, California and Philippines. Originally servicing only sign manufacturing companies, AwesomeOS, has been able to diversify into other industries including animation, business administration, contact center support, fashion consultancy, photo enhancement, and web development.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong><u>Mission:</u></strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><em>“Make the client's life easier.”</em></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">As long as we can keep making the clients' lives easier, they will continue with our services (as long as financially possible). Nobody would pay us even 1 PHP if we make their life more challenging or difficult. When was the last time you paid someone to make your life harder?</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Every decision we make with our clients should consider this mission.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong><u>Three AwesomeOS Values:</u></strong></span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Our first responsibility is to provide quality service to our <span style="color: #ff6600;"><strong>Clients</strong>.</span> </span></li>
</ol>
<ul style="text-align: justify;">
<li><span style="color: #dddddd;">We are a service company. The service is our product. Our product must add value for our customers.</span></li>
<li><span style="color: #dddddd;">We must strive to be equivalent or better than our competitors, the in-house employee. We must be world class!</span></li>
<li><span style="color: #dddddd;">If we take care of the Clients, they will take care of us!</span></li>
<li><span style="color: #dddddd;">2. Our second responsibility is to our <span style="color: #ff6600;"><strong>Team Members</strong>.&nbsp;</span></span></li>
</ul>
<ul style="text-align: justify;">
<li><span style="color: #dddddd;">Everyone's ideas and concerns will be heard. There is always room for improvement.</span></li>
<li><span style="color: #dddddd;">Management must care about their Team Members.</span></li>
<li><span style="color: #dddddd;">If we take care of each other, then the entire Team wins.</span></li>
</ul>
<ol style="text-align: justify;" start="3">
<li><span style="color: #dddddd;">Our third responsibility is Loyalty to <span style="color: #ff6600;"><strong>AwesomeOS</strong>.</span></span></li>
</ol>
<ul style="text-align: justify;">
<li><span style="color: #dddddd;">We must consider AwesomeOS as a whole and that its success will depend on all of us.</span></li>
<li><span style="color: #dddddd;">Without AwesomeOS, we all don't have jobs.</span></li>
<li><span style="color: #dddddd;">If we take care of AwesomeOS, then AwesomeOS will take care of us!</span></li>
</ul>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong> If we remember these Values in every decision we make, success will come naturally to everyone.</strong> </span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>RAP</strong></span></p>
<ul style="list-style-type: disc; text-align: justify;">
<li><span style="color: #dddddd;">Responsibility – Perform at the best of your abilities.</span></li>
<li style="text-align: justify;"><span style="color: #dddddd;">Accountability – Be accountable for your actions, even if it was a mistake.</span></li>
<li style="text-align: justify;"><span style="color: #dddddd;">Professionalism – Maintain world class service.</span></li>
</ul>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>PART II: GENERAL CONDUCT</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The following Conditions of Employment are designed to establish a clear guideline as Team Members of AwesomeOS. These company by-laws were made considering all Three AwesomeOS Values and the Labor Code of the Philippines.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>A. OFFICE RULES</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Our office is our second home, so we expect our Team Members to maintain a clean and safe work environment for everyone.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;</strong>Here are some rules to follow:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">No eating food while you are inside the production floor.&nbsp;</span></li>
<li><span style="color: #dddddd;">Only Spill proof containers can be used at the workstations. (Team Members are responsible for any damage caused to the PCs by food or spilled beverages.</span></li>
<li><span style="color: #dddddd;">No Downloading or streaming of music files or videos during work hours, including breaks unless authorized by a supervisor and/or Manager for work related purposes or for the client. Our internet bandwidth is vital to providing great service. Teammates cannot download/upload important files if bandwidth is used up.</span></li>
<li><span style="color: #dddddd;">Using or surfing online sites (Facebook, Twitter, Online shops, etc.) should ONLY be used during breaks AND ARE ONLY ALLOWED WITHIN THE DESIGNATED AREAS. Please do not distract other people who are working.</span></li>
<li><span style="color: #dddddd;">Be mindful of loud and offensive music and/or noise. Please understand that you are sharing the office with many others.</span></li>
<li><span style="color: #dddddd;">Refrain from playing online games and other PC games during work hours. In addition, using PCs during your days off for personal use is not allowed. This is not an internet café. If you need to render overtime during a day off, please provide a notice of approval from your Team Lead. Turn off the PC when not in use.</span></li>
<li><span style="color: #dddddd;">Visiting, accessing and/or propagating pornographic links/sites, images, videos, films and literatures is STRICTLY PROHIBITED.</span></li>
<li><span style="color: #dddddd;">Team Members are not allowed to sleep, intentionally or unintentionally, during working hours because it affects the performance of providing services and the confidence of your client/s towards the company.&nbsp; Feel free to take a power nap during your authorized break in designated areas.</span></li>
<li><span style="color: #dddddd;">We expect our Team Members to be dressed as if clients are in the office. We also EXPECT our team members to refrain from wearing sexually provocative attire to promote a safe and healthy working environment.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #dddddd;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;To be more specific the following are not allowed:</span></p>
<ul style="text-align: justify;">
<li><span style="color: #dddddd;">No wearing of skimpy/tight shorts</span></li>
<li><span style="color: #dddddd;">No wearing of rubber flip-flops or slippers.</span></li>
<li><span style="color: #dddddd;">No wearing of hanging tops, sandos, basketball jerseys</span></li>
</ul>
<ol style="text-align: justify;" start="10">
<li><span style="color: #dddddd;">CLEAN AS YOU GO. Clean up after yourself. Do not leave trash at the work stations. Clean your own dishes.</span></li>
<li><span style="color: #dddddd;">Visitors must register with the guard and are only allowed at the lobby and outside areas. A valid ID must be presented before entering the premises. No Visitors are allowed in the production floor</span></li>
<li><span style="color: #dddddd;">Team Members from different accounts are not allowed to go inside the production floor/s of other accounts without official business concerns. Loafing, loitering, malingering, wasting time inside the production floor, administrative offices while on break, and/or duty is not allowed.</span></li>
<li><span style="color: #dddddd;">No cell phones or electronic devices in designated areas – production floor, training rooms, etc.</span></li>
<li><span style="color: #dddddd;">No selling or soliciting of any products or services in office premises. This is a distraction to many people.</span></li>
<li><span style="color: #dddddd;">Kids are welcome to drop by, but this is not a daycare. If there is a need for the child to stay longer, please secure an approval from the People Team. Please find someone to take care of your kids during work hours. The company is not liable for any incident/accident that may involve the child while inside the premises.</span></li>
<li><span style="color: #dddddd;">Always wear your company ID within the office premises.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #dddddd;">No Borrowing or swapping of company IDs. In case of lost/damaged company ID cards, please follow this process.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>LOST ID CARD</strong> – Please immediately inform the Security personnel and send a request for replacement to <a style="color: #dddddd;" href="mailto:hr@offsourcing.com">hris@awesomeos.com</a>.&nbsp; Secure a notarized affidavit of loss to support your request. A temporary ID will be provided until the new one becomes available. There will be processing charge of Php 105.00 for your new ID card.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>DAMAGED ID CARD</strong> – If your card is damaged and essential information is non verifiable (torn, tampered, etc.), please inform the Security personnel and send a request for replacement to <a style="color: #dddddd;" href="mailto:hr@offsourcing.com">hris@awesomeos.com</a>. Surrender the damaged item and a temporary ID will be provided until the new one becomes available. There will be processing charge of Php 105.00 for your new ID card.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>B. CONFLICT OF INTEREST&nbsp;&nbsp;</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Engaging or working for a competitor or taking a similar job in the same or similar industry as our client or AwesomeOS is strictly prohibited. This is a specific clause that AwesomeOS agrees to with each and every client. If we break this term on our contract, the client can cancel their relationship with us, meaning the entire team loses their job. As you can see, it's serious.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Violation of this policy will be dealt with in accordance to the provisions in our Code of Conduct</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">It's fine if you might have a small sideline business, but it’s not ok if it conflicts with our client’s or Awesome OS’s line of business. If you are unsure if you are in violation of this policy, please discuss with the People Team. Better safe than sorry!</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>&nbsp;C.&nbsp;</strong><strong>EQUAL EMPLOYMENT OPPORTUNITY </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS offers an equal employment opportunity and does not discriminate with regards to race, color, religion, sex, age, disability or any other protected status.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The company prohibits discrimination based on the following factors including but not limited to gender, gender identity, genetic characteristics or information, race, color, national origin, ancestry, religion, creed, physical or mental disability, medical condition, marital status, veteran status, sexual orientation, age, and any other basis protected by law.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Team Members are not expected to respect everyone, but we ask that we don't disrespect each other, in particular, views and beliefs in religious faith and politics.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The same condition applies with US clients who uphold equal rights and equal opportunity for everybody. It's best to just stay away from these topics with our clients.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>D. HARASSMENT </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS, is committed to providing a safe, respectful and collegial work environment free from harassment, discrimination, and retaliation regarding all anti-harassment and discrimination laws.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Harassment consists of unwelcome conduct - verbal, physical, or visual that creates an intimidating, offensive, or hostile working environment, or that interferes with work performance. It is not necessary that there be tangible or economic threats or job consequences. This policy also applies to any harassment that may have consequences in the workplace, whether or not the harassment takes place during work hours or on company property. For example, this policy applies to harassment on business trips, company off-site meetings, and business-related social events.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The anti-harassment policy applies to all Team Members involved in business operations and prohibits harassment by any Team Members, including managers. This policy also applies to vendors, clients, independent contractors, and applicants for positions within the company. If harassment occurs on the job by someone not employed by the company, the procedures in this policy should be followed as if the harasser were a team member of the company. We expect everyone in the company to support this policy and that all relationships among individuals&nbsp; be professional and free of unlawful bias, prejudice, and harassment.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>&nbsp;E.&nbsp;</strong><strong>SEXUAL HARASSMENT </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS believes that Team Members should be afforded the opportunity to work in an environment free of sexual harassment. No Team Member, either male or female, should be subjected verbally or physically to unsolicited and unwelcome sexual overtures or conduct.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>WHAT IS SEXUAL HARASSMENT</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Sexual harassment refers to behavior that is not welcome, that is personally offensive, debilitates morale and, therefore, interferes with work effectiveness. Such behavior may be in the form of unwanted physical, verbal or visual sexual advances, requests for sexual favors, and other sexually oriented conduct which is offensive or objectionable to the recipient.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The following are examples of the types of verbal, visual/non-verbal, and physical conduct that may create a hostile and offensive work environment and may constitute sexual harassment, but not limited to:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Sexual prank, or repeated teasing, joke, or innuendo, in person, via email, or chat forums.</span></li>
<li><span style="color: #dddddd;">Touching or grabbing of a sexual nature</span></li>
<li><span style="color: #dddddd;">Verbal abuse of a sexual nature</span></li>
<li><span style="color: #dddddd;">Repeatedly standing too close or brushing up against a person</span></li>
<li><span style="color: #dddddd;">Repeatedly asking another Team Member to socialize during off-duty hours when the Team Member has said,”no”or indicated that he/she is not interested</span></li>
<li><span style="color: #dddddd;">Giving of gifts or leaving objects that are sexually suggestive</span></li>
<li><span style="color: #dddddd;">Making or posting sexually demeaning or offensive picture, cartoon or other materials in the workplace</span></li>
<li><span style="color: #dddddd;">Off-duty, unwelcome conduct of a sexual nature that affects the work environment</span></li>
<li><span style="color: #dddddd;">Eliciting sexual favor as a condition for hiring, re-employment, work assignment, compensation, promotions and other conditions for employment.</span></li>
<li><span style="color: #dddddd;">Offensive flirtations, off-color/sex-oriented "jokes," "kidding" or "teasing," making derogatory or sexual comments, whistling and cat calls, referring to another as "doll," "babe," "honey," or similar names, and suggestive or insulting sounds.</span></li>
<li><span style="color: #dddddd;">Discussion of one's own sexual problems or experiences, e.g., "My sexual relationship with my wife is unsatisfactory"; or, "I've been so lonely for companionship"; or, "I really scored Saturday night." Discussions of or questions about another's sexual experiences, e.g., "Are you sleeping with her/him?" "How's your sex life?"</span></li>
<li><span style="color: #dddddd;">Repeated and unwelcomed comments about another's appearance or clothing.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #dddddd;">In a work-related or employment environment, sexual harassment is committed when:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">The sexual favor is made as a condition in the hiring or in the employment, re-employment, or continued employment of said individual, or in granting said individual favorable compensation, terms of conditions, promotions, or privileges; or the refusal to grant the sexual favor results in limiting, segregating or classifying the Team Member which in any way would discriminate, deprive or diminish employment opportunities or otherwise adversely affect said Team Member;</span></li>
<li><span style="color: #dddddd;">The above acts would impair the Team Member’s rights or privileges under existing labor laws; or</span></li>
<li><span style="color: #dddddd;">The above acts would result in an intimidating, hostile, or offensive environment for the Team Member.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>EMPLOYER’S RESPONSIBILITY&nbsp;</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS undertakes to provide its officers and Team Members a work environment free of sexual harassment by management personnel, by Team Members and by others with whom officers and Team Members must interact in the course of their employment in Awesome OS.&nbsp; Sexual harassment is unlawful and is a violation of company policy.&nbsp; Awesome OS is responsible for taking immediate corrective action to stop sexual harassment in the workplace and for promptly investigating any allegation of work‑related sexual harassment.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS will not tolerate any behavior that amounts to sexual harassment and any officer or Team Member found to have committed sexual harassment shall be subjected to disciplinary action, up to and including dismissal</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>COMPLAINT PROCEDURE</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Any Team Member, who experiences any act of sexual harassment in the workplace, shall report the same immediately to the Grievance and Investigation Team. They may also report acts of sexual harassment to any other member of management or ownership.&nbsp; All allegations of sexual harassment will be quickly investigated and the identity of the officer or Team Member &nbsp;shall remain confidential and that of any witnesses and the alleged harasser will be protected against unnecessary disclosure. When the investigation is completed, all parties will be informed of the outcome of the investigation.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">A Grievance Team shall be constituted and shall be composed of the management and the employees’ representative to receive complaints, investigate and hear sexual harassment cases. The Team shall develop its own settlement and disposition of sexual harassment cases. The Team shall also develop and implement programs to increase understanding and awareness about sexual harassment.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">If you feel that you are victim of sexual harassment, please report it immediately to the People Team by sending an email to <a style="color: #dddddd;" href="mailto:hr@offsourcing.com">peopleteam@awesomeos.com</a></span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>&nbsp;F.&nbsp;</strong><strong>SOCIAL MEDIA </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">While Awesome OS encourages its team members to enjoy and make good use of their off-duty time, certain activities on the part of team members may become a concern if they have the effect of impairing the work of any other Team Member. In the area of social media (print, broadcast, digital, and online services such as Facebook, LinkedIn, Instagram, and Twitter, among others), Team Members may use such media in any way they choose as long as such use does not produce the adverse consequences noted below. For this reason, AwesomeOS reminds its Team Members that the following guidelines apply in their use of social media, both on and off duty:</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Please do not publish any personal information about yourself, another Team Member of the organization, the company, a client, or a client in any public medium (print, broadcast, digital, or online) that:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">has the potential or effect of involving the Team Member, their coworkers, or the company in any kind of dispute or conflict with other Team Member or third parties;</span></li>
<li><span style="color: #dddddd;">interferes with the work of any Team Member;</span></li>
<li><span style="color: #dddddd;">creates a harassing, demeaning, or hostile working environment for any Team Member;</span></li>
<li><span style="color: #dddddd;">disrupts the smooth and orderly flow of work within the office, or the delivery of services to the company's clients;</span></li>
<li><span style="color: #dddddd;">harms the goodwill and reputation of the company among its clients or in the community at large; or</span></li>
<li><span style="color: #dddddd;">tends to place in doubt the reliability, trustworthiness, or sound judgment of the person who is the subject of the information.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #dddddd;">The Team Member responsible for such issues will be subject to disciplinary action, up to and potentially including termination of employment, depending upon the severity and repeat nature of the offense. How a Team Member uses social media is not a matter of concern as long as it is consistent with the above guidelines.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>PART III. HEALTH, SAFETY AND SANITATION STANDARDS</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The facility where Team Members are assigned have emergency procedures and an evacuation plan to follow in the event of fire or other disaster. This plan is prominently posted in common areas and bulletin boards on each floor of the facility. Exits, fire extinguishers and first aid kits are located on each floor. All team members are expected to familiarize themselves with the location of such equipment.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">In any emergency, Team Members should follow alarms or other alerts to evacuate the building or area near the premises. Team Members should always follow the basic evacuation procedures and remember that personal safety is paramount and takes precedence.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS requires all Team Members to take care of the office facilities by ensuring compliance of the general, health and safety rules.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Here are some rules:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Only authorized Team Members can operate or adjust office facilities, such as but not limited to, ACs, TVs, lights, Xbox, karaoke machine, projectors, speakers, etc.</span></li>
<li><span style="color: #dddddd;">Medical Supplies and Equipment should only be released by our company nurses and/or People Team.</span></li>
<li><span style="color: #dddddd;">Please be mindful of our electricity costs. All electronic gadgets are only allowed to be plugged in at the lobby ONLY. Flat or Curling irons and blow driers are only allowed to be used during company events</span></li>
<li><span style="color: #dddddd;">Team Members who are sent home by the nurses are required to immediately leave the premises.</span></li>
<li><span style="color: #dddddd;">Fire extinguishers, fire alarms, elevator alarms are provided for safety. Please do not press any emergency buttons out of curiosity.</span></li>
<li><span style="color: #dddddd;">Team members are not allowed to hangout/ take a nap or leave things in the emergency or fire exits.</span></li>
<li><span style="color: #dddddd;">Team Members are required to attend emergency drills, such as fire, earthquake and other safety drills.</span></li>
<li><span style="color: #dddddd;">During an emergency, all Team Members are required to follow the instructions from the assigned safety officers and follow the evacuation plan.</span></li>
<li><span style="color: #dddddd;">Team Members are required participate in the annual physical, and medical exams conducted by the company.Smoking,&nbsp;</span></li>
<li><span style="color: #dddddd;">&nbsp;Smoking, including e-cigarrtes is strictly prohibited within the company premises. Please smoke at the designated areas.&nbsp;</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong><u>&nbsp;A.&nbsp;</u></strong><strong><u>DRUGS AND ALCOHOL</u></strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">We realize that the misuse of drugs and alcohol can impair a Team Member’s health and productivity. Also, drug and alcohol problems may result in unsafe working conditions for all.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Any Team Member involved in the unlawful use, sale, manufacture, dispensing or possession of controlled substances, illicit drugs, and alcohol within the office premises, or working under the influence of such substance, will be subject to disciplinary action, up to and including immediate termination.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">We can drink and have fun but be sober during work!</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>PART IV. ATTENDANCE AND LEAVE STANDARDS </strong></span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>&nbsp;A.&nbsp;</strong><strong>ATTENDANCE AND PUNCTUALITY </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">We are a “results-oriented” organization and we treat our Team Members as mature and responsible professionals. The company uses an online software called OSNet to monitor daily time in and time out of all Members. Team Members are required to use their OSNet accounts for all matters related to attendance.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Please note that your client may have a stricter attendance policy. If this is the case, you will be notified of the policy and there will be a separate sign off for the client’s policy.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong><u>Tardiness</u></strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Tardiness is defined as a Team Member reporting for work,but not logged in at his/her assigned work station at the scheduled start time.&nbsp; Tardiness begins when a Team Member logs in to the work station (1) minute past the scheduled start time. Three instances of tardiness will be considered habitual.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong><u>&nbsp;</u><u>Excused Absence</u></strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Absence approved by the Team Supervisor and/or client at least 24 hours in advance. Make sure to submit the approval of your absence.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong><u>Unexcused Absence</u></strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Absence without prior notification and approval from the Team Supervisor , Manager and/or the Client. Three instances of absences will be considered excessive.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>Sanctions of Tardiness/Unexcused Absences </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">** These infractions never reset. **</span></p>
<div style="text-align: justify;"><span style="color: #dddddd;">1<sup>st</sup> Infraction &nbsp;&nbsp; : Incident Report and Coaching by the Team Supervisor</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">2<sup>nd</sup> Infraction&nbsp;&nbsp; : Incident Report and Coaching by the Team Supervisor and Floor Manager</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">3<sup>rd</sup> Infraction&nbsp;&nbsp; : Incident Report and Coaching by the People Team</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">4<sup>th</sup> Infraction&nbsp;&nbsp; : Disciplinary Action (Suspension/ Termination)</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">5<sup>th</sup> Infraction&nbsp;&nbsp; : Dismissal</span></div>
<p style="text-align: justify;"><span style="color: #dddddd;"><em><u>&nbsp;</u></em><strong><span style="color: #ff6600;"><u>No Call No Show (NCNS)</u>&nbsp;</span>&nbsp;</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Failure to notify within four (4) hours from start of shift for being absent will result in an NCNS.&nbsp;&nbsp; Legitimate reasons may include, but not limited to, medical and family emergencies.&nbsp; Incidents of NCNS for two (2) consecutive days or more will automatically result in removal from the program and/or company.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;</strong><strong>Sanctions of No Call No Show (NCNS)</strong> <em>(unless documents are presented)</em>: ** These infractions never reset. **</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">1<sup>st</sup> Infraction &nbsp;&nbsp; : Final Warning/ Coaching with People Team and Team Supervisor</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">2<sup>nd</sup> Infraction&nbsp;&nbsp; : Suspension/Termination</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;</strong><span style="color: #ff6600;"><strong>Critical Working Days </strong></span></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS has the right to declare Critical Working Days. During these days, Team Members are not allowed to be absent from work or a proper sanction may apply.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>Red Line Days</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">AwesomeOS has the right to declare Red Line Days. These days are usually during special events that threaten our attendance rate. During these days, Team Members are not allowed to be absent from work or a proper sanction may apply.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">&nbsp;<strong><em>Sanctions for Critical/Red Line Days:</em></strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><em>&nbsp;</em><em>Any absence during declared Critical Days and/or Red Line Days will be treated as a Level 2 offense. </em></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><em>&nbsp;</em>1st offense&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - Written Warning/ Disqualification from Attendance and Performance Incentive &nbsp;&nbsp;</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">2nd offense&nbsp;&nbsp;&nbsp;&nbsp; - Final Written Warning, Disqualification from Attendance and Performance Incentive and/or Suspension up to Termination</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">3rd offense&nbsp;&nbsp;&nbsp;&nbsp; - Suspension to Termination</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;</strong>Violations to this policy will be subject to sanction as stated in the Code of Conduct.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The following general rules will apply to situations when a Team Member cannot report to work on time or will be absent for the day:</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>Call In Guidelines:</strong></span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">The Team Member must inform the People Team , Workforce, or Team Supervisor at least 2 hours before scheduled shift. Email, phone work best or what has been agreed upon by the Team Supervisor.</span></li>
<li><span style="color: #dddddd;">If a mobile phone was used to send an SMS, make sure to secure a confirmation SMS in return. Some SMS may not go through.</span></li>
<li><span style="color: #dddddd;">Team Supervisors are responsible to communicate to the clients with the absence or tardiness. If your Team Leader or immediate supervisor doesn't hear from you, they need to come up with an excuse to the client.&nbsp; Please don't make them cover for you.</span></li>
<li><span style="color: #dddddd;">Despite the nature of its urgency, Team Members are still required to immediately advise their Team Supervisor/or People Team of the emergency absence. If there are no communications and/or a legitimate reason is not provided within 4 hours after the start of shift, the absence will be considered as No Call No Show (NCNS) and a suspension without pay may occur.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>&nbsp;B.&nbsp;</strong><strong>LEAVE APPLICATIONS </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">As a policy, Paid and Unpaid Leaves of Absence (except for emergency leaves) should be filed via OSnet with your Team Leader fourteen (14) days in advance. This is intended to provide the Team Leader with sufficient time to seek for Client’s approval and manage priorities by redistributing the workload.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Leaves of Absence should be endorsed by the Team Leader to the People Team for proper review prior to approval.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The People Team should be provided with a hard copy of the approved Leave of Absence by the Team Leader.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>C. EMERGENCY LEAVE OF ABSENCE </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Despite the nature of its urgency, Team Members are still required to immediately advise their Team Supervisor or The People Team of the emergency absence. If there are no communications and a legitimate reason is not provided within 24 hours, the absence will be considered as a “No Call No Show” and a suspension without pay may occur.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>D. MEDICAL CERTIFICATE </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Medical Certificates are required for emergency absence/s due to sickness. &nbsp;Don’t waste your time getting a fake Medical Certificate if you weren’t sick. A more severe penalty will occur for falsifying documents.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>E. MATERNITY AND PATERNITY LEAVES </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">You are adding a new family member!! Congratulations!</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">A pregnant woman Team Member who has rendered at least six months of service for the last twelve months of work shall be entitled maternity leave of at least two weeks prior to the expected date of delivery and at least four weeks after normal delivery, with full pay. The benefit is <strong>105 days</strong> for normal/caesarian delivery and <strong>60 days</strong> for miscarriages &amp; emergency termination of pregnancy. Additional <strong>15 days</strong> paid leave will be given if the female worker qualifies as a solo parent.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Any additional time needed may be granted, but will fall under Unpaid Leave of Absence. Unused leave credits may also be used.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><span style="color: rgb(230, 126, 35);"><strong>Paternity leaves</strong></span> can be filed for <strong>7 days</strong> with full pay. The following are the conditions:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Father is legally married and cohabiting with his legitimate wife;</span></li>
<li><span style="color: #dddddd;">Father’s wife is pregnant or has delivered a child (or suffered a miscarriage);</span></li>
<li><span style="color: #dddddd;">Must be of the first four deliveries;</span></li>
<li><span style="color: #dddddd;">2-week notice to the People Team</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;<span style="color: #ff6600;">F.&nbsp;</span></strong><span style="color: #ff6600;"><strong>BEREAVEMENT LEAVE </strong></span></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The company understands the deep impact that death can have on an individual or a family, therefore additional leave is granted to our team members for making funeral arrangements, attending the funeral and burial, paying respects to the family at a wake or visitation, dealing with the deceased’s possessions and will, and any ancillary matters that a Team Member must address when a loved one dies.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>Immediate family member -&nbsp;</strong>Immediate family member includes Spouse, parent, child, sibling, grandparent, grandchild or the spouse’s parent. Team Members are allowed to take 3 days bereavement with full pay and 2 days unpaid.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>Outside Immediate family </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Conditions:</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">- How close the association is between the Team Member and the other person?</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">- Is the employee responsible for any aspects of the ceremonies around the death?</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">- Does the Team Member have any cultural responsibilities they need to fulfil in respect of &nbsp;the death?</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Team Members are allowed to take 1 day bereavement with full pay and 2 days unpaid.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>G. SOLO PARENT LEAVE (</strong><strong>RA NO. 8972)</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;</strong>Team Members who fall under the definition of solo parents and who have rendered service of at least one year are entitled to&nbsp;7 paid working days of leave to attend to their parental duties. Team Member must present a solo parent ID issued by the DSWD (Department of Social Welfare and Development)</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>H. VIOLENCE AGAINTS WOMEN AND THEIR CHILDREN (VAWC R.A 9262)</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;</strong>In addition to other paid leaves, a woman Team Member who suffered from physical, sexual, psychological harm including threats of such acts, battery, assault, coercion, harassment or arbitrary deprivation of liberty may take extra 10 days leave with full pay. A certification from the Barangay and Women’s desk is required upon application of this benefit.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong><span style="color: #ff6600;">PART V. PERFORMANCE STANDARDS</span> </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Awesome OS would like to stress the importance of exceeding client and customer expectations. Clients expect a lot, but if we can exceed their expectations, they will stick with us in the long term. Customers also expect a lot and will inform our clients of any performance issues, both good and bad. If we can keep both clients and customers happy, then we can provide long-term careers.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>A. PERFORMANCE EVALUATION </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><strong>&nbsp;</strong>All Team Members will initially be given a 3-6 month probationary contract. &nbsp;Please take note that during this time, your client has the right to remove you from the account on or before the maturity of the employment contract if the performance does not meet expectations or for any other reason. Performance is based on the account’s <strong><u>KPI and/or the following evaluation standards</u></strong>.</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Attendance</span></li>
<li><span style="color: #dddddd;">Productivity</span></li>
<li><span style="color: #dddddd;">Quality</span></li>
<li><span style="color: #dddddd;">Adherence to Policies</span></li>
<li><span style="color: #dddddd;">Client’s Feedback</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #dddddd;">Team Leaders must be responsible enough to give&nbsp; feedback and an evaluation to a Team Member prior to the end of the probationary contract. A negative evaluation result may be strong grounds for placing the Team Member under Performance Improvement Plan (PIP), non-renewal of contract, or separation.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>&nbsp;B.&nbsp;</strong><strong>WORK FROM HOME (WFH)</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Work from home <strong><u>is not allowed</u></strong> unless approved by the <strong><u>Client/and or Management. </u></strong>In the instance where WFH is requested, the following conditions must be met prior to approval.</span></p>
<ul style="text-align: justify;">
<li><span style="color: #dddddd;">Stable internet connection, with screenshot of speedtest</span></li>
<li><span style="color: #dddddd;">Available for all scheduled meeting and or coaching</span></li>
<li><span style="color: #dddddd;">Follow the standard schedule for time in and time out.</span></li>
<li><span style="color: #dddddd;">Reach required performance metrics per day, if applicable</span></li>
<li><span style="color: #dddddd;">Submit End Of Day report</span></li>
</ul>
<p style="text-align: justify;"><span style="color: #dddddd;">Team Members who work from home are no longer qualified for Night differential pay and Overtime pay. Management may discontinue WFH privilege to any Team Member as it deems appropriate. Work-from-home is a privilege. Negative behaviors such as continual tardiness, loss of focus, decrease in productivity, and not responding to the client or Teammates during work hours will result in losing work from home privilege.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>C. HIRING AND TRANSFERS</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Again, AwesomeOS offers an equal employment opportunity and does not discriminate with regards to race, color, religion, sex, age, disability or any other protected status.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><u>a. Types of Employment</u></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">&nbsp; Awesome OS, Inc. recognizes two (2) types of employment categories:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Administration Team Member perform activities that directly supports the operations of AwesomeOS (Operations/Administration Manager, People Team positions, Bookkeeping, etc.) and&nbsp;&nbsp;</span></li>
</ol>
<ol style="text-align: justify;" start="2">
<li><span style="color: #dddddd;">Technical Team Member who are assigned to do a project or activity with a client (Technical Designer, Animators, Developers, Image&nbsp;Enhancement, Contact Center, Fashion Consultant, etc.)</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #dddddd;"><em><u>Administration Team Member</u></em></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">All Administration Team Members follow a 6-month probationary period. An assessment will done at the end of the 5<sup>th &nbsp;</sup>month in order to determine how the Team Member performs against established standards. Probationary Team Member with an unfavorable assessment will immediately be separated before the end of the 6<sup>th</sup> month.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;"><em><u>Technical Team Member</u></em></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The Technical Team Member follows a 3-level employment stage:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;"><u>Level 1</u>: All selected candidates will undergo two (2) - four (4) weeks training/orientation in order to test the candidate's attitude and adaptability to the work challenge and environment. During the training, the candidate will receive a training allowance similar to their normal pay. An assessment will be done at the end of the training period and only successful candidates will be allowed to proceed to Level 2.&nbsp; &nbsp; &nbsp;&nbsp;</span></li>
</ol>
<ol style="text-align: justify;" start="2">
<li><span style="color: #dddddd;"><u>Level 2</u>: Successful candidates will sign a Fixed Term or Project Employment contract depending on the availability of the work or clients.&nbsp;&nbsp;</span></li>
</ol>
<ol style="text-align: justify;" start="3">
<li><span style="color: #dddddd;"><u>Level 3</u>: Those with favorable Level 2 performance may be eligible for longer term contracts depending on the availability of the work or clients.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>TEAM MEMBER TRANSFER </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">AwesomeOS, in compliance to the standards of the labor law, practices Team Member transfers due to reasonable business decisions. A notice shall be given to the team member/s prior to the effective date of transfer.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>PART VI. IT &amp; INFORMATION SECURITY STANDARDS </strong></span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>A</strong>.&nbsp;<strong>Internet, E-mail and Computer Usage Policy </strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The use of Awesome OS automation systems, including computers, and all forms of internet/intranet access, is for company business and for authorized purposes only. Brief and occasional personal use of the electronic mail system or the Internet is acceptable as long as it is not excessive or inappropriate, occurs during personal time (lunch or other breaks), and does not result in expense or harm to the Company or otherwise violate any policies. Use is defined as "excessive" if it interferes with normal job functions, responsiveness, or the ability to perform daily job activities. Just be smart about it.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The following activities should also be avoided at all times:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Sending chain letters or participating in any way in the creation or transmission of unsolicited commercial e-mail ("spam") that is unrelated to legitimate Company purposes;</span></li>
<li><span style="color: #dddddd;">Engaging in business or personal activities that is non-work-related, including excessive use of instant messaging and chat rooms;</span></li>
<li><span style="color: #dddddd;">Accessing networks, servers, drives, folders, or files to which the employee has not been granted access or authorization;</span></li>
<li><span style="color: #dddddd;">Making unauthorized copies of Company files or other Company data;</span></li>
<li><span style="color: #dddddd;">Destroying, deleting, erasing, or concealing Company files or other Company data, or otherwise making such files or data unavailable or inaccessible to the Company or to other authorized users of Company systems;</span></li>
<li><span style="color: #dddddd;">Engaging in unlawful or malicious activities;</span></li>
<li><span style="color: #dddddd;">Deliberately propagating any virus, worm, Trojan horse, trap-door program code, or other code or file designed to disrupt, disable, impair, or otherwise harm either the Company's networks or systems or those of any other individual or entity;</span></li>
<li><span style="color: #dddddd;">Causing congestion, disruption, disablement, alteration, or impairment of Company networks or systems;</span></li>
<li><span style="color: #dddddd;">Defeating or attempting to defeat security restrictions on company systems and applications.</span></li>
</ol>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>&nbsp;B.&nbsp;</strong><strong>Ownership and Access of Electronic Mail, Internet Access, and Computer Files; No Expectation of Privacy</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The Company owns the rights to all data and files in any computer, network, or other information system used in the Company and to all data and files sent or received using any company system or using the Company's access to any computer network, to the extent that such rights are not superseded by applicable laws relating to intellectual property. The Company also reserves the right to monitor electronic mail messages (including personal/private/instant messaging systems) and their content, as well as any and all use by employees of the Internet and of computer equipment used to create, view, or access e-mail and Internet content. Employees must be aware that the electronic mail messages sent and received using Company equipment or Company-provided Internet access, including web-based messaging systems used with such systems or access, are not private and are subject to viewing, downloading, inspection, release, and archiving by Company officials at all times. The Company has the right to inspect any and all files stored in private areas of the network or on individual computers or storage media in order to assure compliance with Company policies and state and federal laws. No employee may access another employee's computer, computer files, or electronic mail messages without prior authorization from either the employee or an appropriate Company official.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>C. Confidentiality of Electronic Mail</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">It is a violation of Company policy for any employee, including system administrators and supervisors, to access electronic mail and computer systems files to satisfy curiosity about the affairs of others, unless such access is directly related to that employee's job duties. Employees found to have engaged in such activities will be subject to disciplinary action.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>D. Policy Statement for Internet/Intranet Browser(s)</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">The Internet is to be used to further the Company's mission, to provide effective service of the highest quality to the Company's customers and staff, and to support other direct job-related purposes.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">Employees are individually liable for any and all damages incurred as a result of violating company security policy, copyright, and licensing agreements.</span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">All Company policies and procedures apply to employees' conduct on the Internet, especially, but not exclusively, relating to: intellectual property, confidentiality, company information dissemination, standards of conduct, misuse of company resources, anti-harassment, and information and data security.</span></p>
<p style="text-align: justify;"><span style="color: #ff6600;"><strong>E. Use of Office Equipment, Chat/Instant Messaging, Email, Social Networking / Internet Sites</strong></span></p>
<p style="text-align: justify;"><span style="color: #dddddd;">&nbsp;&nbsp;&nbsp;Office equipment was purchased for work-related activities. The following rules are designed to ensure the proper use and maintenance of this equipment:</span></p>
<ol style="text-align: justify;">
<li><span style="color: #dddddd;">Office equipment should not be used for personal use or removed from the office unless permitted or approved by IT Department.</span></li>
<li><span style="color: #dddddd;">Installation of software on OS computers without permission from the IT Department is not allowed.</span></li>
<li><span style="color: #dddddd;">Downloading of illegal files from the internet (including music and video files) are not allowed. Personal files should be kept on external storage (like flash drive or external drive). There is a computer task force looking to close offices that have illegal software in their computers. We can't afford to be closed for long periods of times when clients can look for similar services elsewhere.</span></li>
<li><span style="color: #dddddd;">When communicating via Skype chat rooms; try to keep your language at PG-13 or better; personal attacks should be avoided. (In person conversations are best. Video chats if you can't meet. Things can be misinterpreted via chat.)</span></li>
<li><span style="color: #dddddd;">AwesomeOS email address should be used for business purpose only.</span></li>
<li><span style="color: #dddddd;">You should check your assigned email address a few times a day to check important messages from your assigned client or Team Members.</span></li>
<li><span style="color: #dddddd;">AwesomeOS reserves the rights to monitor, access, read, retrieve, and disclose all communications made by chat and email.</span></li>
<li><span style="color: #dddddd;">Posting (Facebook, Twitter, Youtube, etc.) of any work created for a client is strictly prohibited as this violates our signed contract with them. Clients would have every right to cancel their relationship with us. The entire team would lose their job. This is another serious offense that may result in immediate dismissal.</span></li>
<li><span style="color: #dddddd;">Posting (Facebook, Twitter, Youtube) of work-related complaints is also prohibited. If you have a problem with someone, deal with them directly. We are a team. You are encouraged to take any issues up with your Team Leader or the People Team representative if you need help.</span></li>
<li><span style="color: #dddddd;">Yammer accounts and other client initiated chatrooms will be used to easily receive Alerts and Company announcements. Posting of personal photos, jokes, and quotes not relevant to work should be avoided.</span></li>
</ol>
<p><span style="color: #ff6600;"><strong>PART VII: COMPENSATION AND BENEFITS </strong></span></p>
<p><span style="color: #ff6600;"><strong>A. COMPENSATION</strong></span></p>
<p><span style="color: #dddddd;">Hourly-based – These are Team Members that are paid per hour. Overtimes and Night Differentials are paid for, but Under-time or lates are not paid for. Those in Customer Service positions are typically hourly-based.</span></p>
<p><span style="color: #dddddd;">Task-based – These are Team Members that are paid a standard monthly rate. Task-based employees do not receive Night Time differential, but do receive Overtime pay as described below. Holiday pay is not required for task-based Team Members, but Offsourcing has decided to pay Holiday pay anyways.</span></p>
<p><span style="color: #dddddd;"><strong><u><span style="color: #ff6600;">a. 13th month</span> -&nbsp;</u></strong>13th month pay is based on your Basic Pay and is paid out in 2 halves - once in June and once before December 24.</span></p>
<p><span style="color: #dddddd;"><strong><u><span style="color: #ff6600;">b. Night Time Differential</span>-</u></strong>Team members will receive Night time differential of 10% of the Basic Pay for any hours worked between 10pm-6am.</span></p>
<p><span style="color: #dddddd;"><strong><u><span style="color: #ff6600;">c. Overtime</span> -&nbsp;</u></strong>As a general rule, all overtime <strong>should be pre-approved</strong> by the team supervisor, manager, and/or the clients. <strong>OTs that are not approved will be considered unpaid</strong>.</span></p>
<p><span style="color: #dddddd;">Hourly-based: AwesomeOS provides overtime pay based on your Gross Hourly Rate.</span></p>
<p><span style="color: #dddddd;">Task-based: Those who are task-based will receive OT pay when it is preapproved from the client. Overtime pay is based on of your Gross Hourly Rate.</span></p>
<p><span style="color: #dddddd;"><strong><u><span style="color: #ff6600;">d. Rest Day Overtime (RDOT)</span> -&nbsp;</u></strong>We try our best to limit Rest Day Overtimes as much as possible. But in case it is necessary to deliver great client services and the RDOT is preapproved from the client, RDOT is calculated as 130% of your Gross Hourly Rate.</span></p>
<p><span style="color: #ff6600;"><strong><u>e. Time of Payment </u></strong></span></p>
<p><span style="color: #dddddd;">Team Members are paid twice a month; Once on the 10th of each month and once on the 25th of each month. The payment on the 10th will be for work completed from the 16th - 30th of each month. The payment on the 25th will be for work completed from the 1st - 15th of each month. &nbsp;&nbsp;&nbsp;</span></p>
<p><span style="color: #ff6600;"><strong>B. HOLIDAYS </strong></span></p>
<p><span style="color: #dddddd;">Be aware that most of our clients will not give us a day off for Filipino Holidays. We will do our best to push the clients for the holidays listed in <strong>BOLD</strong>, (but no guarantees).</span></p>
<p><span style="color: #dddddd;">Work performed on Holidays is paid 200% of Gross Daily Pay. If unworked, 100%. Team Members who are absent (not including Paid Leaves of Absence) on the day immediately preceding a regular holiday will NOT be qualified for Holiday Pay, if the Holiday is unworked.</span></p>
<p><span style="color: #dddddd;">Work performed on Special Days (noted with a *) are paid 130% of Gross Daily Pay. If unworked, no pay.</span></p>
<blockquote>
<div style="text-align: justify;"><br><span style="color: #dddddd;"><strong>January 1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;New Year’s Day</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">Moving Date&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Chinese New Year*</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">Thursday before Easter&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Maundy Thursday &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;"><strong>Friday before Easter&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Good Friday</strong></span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">Saturday before Easter&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Black Saturday*</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">April 9&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Araw ng Kagitingan</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">May 1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Labor Day</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">June 12&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Independence Day</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">August 21&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Ninoy Aquino Day*</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">Last Monday of August&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;National Heroes Day</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">Moving Date&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Eid’l Fitr</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;"><strong>November 1&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;All Saints Day*</strong></span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">November 30 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bonifacio Day</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">December 24&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Additional Special Holiday*</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;"><strong>December 25&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Christmas Day</strong></span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">December 26&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Additional Special Holiday*</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;">December 30 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rizal Day</span></div>
<div style="text-align: justify;"><span style="color: #dddddd;"><strong>December 31&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;New Years’ Eve*</strong></span></div>
</blockquote>
<p><span style="color: #dddddd;">In addition, some of our clients may give us the following US holidays as days off. Please note that there is no additional Holiday pay if you work or don’t work during these days.</span></p>
<div><span style="color: #dddddd;"><strong>Last Monday in May&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Memorial Day</strong></span></div>
<div><span style="color: #dddddd;"><strong>July 4&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Independence Day</strong></span></div>
<div><span style="color: #dddddd;"><strong>First Monday in September&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Labor Day &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></span></div>
<div><span style="color: #dddddd;"><strong>4<sup>th</sup> Thursday in November&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Thanksgiving</strong></span></div>
<div>&nbsp;</div>
<div><span style="color: #dddddd;">In situations when the payday falls on a non-working day (Saturday, Sunday or &nbsp;&nbsp;holiday) the payday shall be moved on the day immediately.</span></div>
<p><span style="color: #ff6600;"><strong>C. BENEFITS AND PRIVILEGES</strong></span></p>
<p><span style="color: #ff6600;"><strong><u>a. Statutory Benefits</u></strong></span></p>
<p><span style="color: #dddddd;">All Team Members of Awesome OS regardless of category are given all mandatory benefits required under the law after 1 month of employment. One month is needed to submit applications for the benefits.</span></p>
<p><span style="color: #ff6600;"><strong><u>b. Company-initiated Benefits</u></strong></span></p>
<p><span style="color: #dddddd;">On top of the mandatory benefits, Awesome OS extends the following benefits:</span><br><br></p>
<ol>
<li><span style="color: #dddddd;"><strong><span style="color: #ff6600;">Paid Leaves</span> -&nbsp;</strong>Philippines law requires only 5 paid leaves a year. However, our Team Members are allowed to accumulate up to 12 Paid Leaves per year.Team Members will earn one (1) Paid Leave after every month. However, the acquired leaves can only be used after the first three months from the start date. Any leaves taken before the initial three month period are non-paid and will be deducted from pay.Unused leave credits cannot be exchanged for cash. You cannot accumulate more than the maximum number of leaves. This is to ensure you take some time off - spend it with friends, family or just take a break.</span></li>
</ol>
<p><span style="color: #dddddd;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Team members are encouraged to use their leave credits within the year. We are only human and need to recharge once in a while.</span></p>
<ol start="2">
<li><span style="color: #ff6600;"><strong>Referral Bonus</strong></span></li>
</ol>
<p><span style="color: #dddddd;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; A referral bonus is given to any Team Member who refers another Team Member who has served for more than 6 months.</span></p>
<div><span style="color: #dddddd;">Qualification</span></div>
<div>
<ol style="list-style-type: lower-alpha;">
<li><span style="color: #dddddd;">Referrer must be an official employee from the date of Employee Information Sheet is submitted.</span></li>
<li><span style="color: #dddddd;">There cannot be any changes to the referrer after submission of Employee Information Sheet.</span></li>
<li><span style="color: #dddddd;">Putting the name of a batch mate is NOT allowed unless they were on a different account and currently being re-profiled.</span></li>
<li><span style="color: #dddddd;">Employee Information Sheet must be personally submitted (NO PROXY allowed). Signature must be affixed on the sheet in order to qualify.</span></li>
</ol>
</div>
<ol start="3">
<li><span style="color: #ff6600;"><strong>Cash Advances</strong></span></li>
</ol>
<p><span style="color: #dddddd;">&nbsp; &nbsp; &nbsp; We have given the opportunity for Cash Advances but please understand this is for EMERGENCY SITUATIONS only. Awesome OS is not a bank so do not depend on Awesome OS as your bank.</span></p>
<ol style="list-style-type: lower-alpha;">
<li><span style="color: #dddddd;">Cash Advances will only be allowed once every six months.</span></li>
<li><span style="color: #dddddd;">Must be a Team Member for over 6 months.</span></li>
<li><span style="color: #dddddd;">Cash Advances are allowed for Medical or Emergency purposes ONLY. Must provide proof.</span></li>
<li><span style="color: #dddddd;">Maximum Cash Advance allowed is Php 5,000.00 only</span></li>
<li><span style="color: #dddddd;">Cash Advances will be paid back within 1-2 months.&nbsp;</span></li>
</ol>
<ol start="4">
<li><span style="color: #ff6600;"><strong>HMO</strong></span></li>
</ol>
<p><span style="color: #dddddd;">&nbsp;We understand that Philhealth coverage is not the best, especially when getting attended to at public hospitals. We have implemented our HMO program with <strong>Intellicare</strong> to give our Teammates better peace of mind. HMO coverage will be implemented as follows:</span></p>
<ol style="list-style-type: lower-alpha;">
<li><span style="color: #dddddd;">3 Months of Service (Customer Service Representative): Bronze Coverage (50k MBL)</span></li>
<li><span style="color: #dddddd;">6 Months of Service (Internal Teams and Technical Teams): Bronze Coverage (50K MBL)</span></li>
<li><span style="color: #dddddd;">2 years of Service: Silver Coverage (75K MBL)</span></li>
</ol>
<ul>
<li><span style="color: #dddddd;">Anyone can upgrade their coverage, but they will pay the difference.</span></li>
<li><span style="color: #dddddd;">Anyone can purchase coverage for any dependents (hierarchy), including Dental Plans</span></li>
<li><span style="color: #dddddd;">Any upgrades or additional coverage will be initially paid for by AwesomeOS Payment back to AwesomeOS will be based on the pricing provided by Intellicare.</span></li>
</ul>
<p><span style="color: #dddddd;">Please take note of the following when adding dependents.</span></p>
<ul>
<li><span style="color: #dddddd;">Adult Dependents up to 65 years old</span></li>
<li><span style="color: #dddddd;">Children from 15 days up to 21 years old</span></li>
</ul>
<p><span style="color: #ff6600;"><strong>HIERARCHY OF DEPENDENTS</strong></span></p>
<p><span style="color: #ff6600;"><strong>a. For Married Employees</strong></span></p>
<p><span style="color: #dddddd;">&nbsp; First Priority: Spouse</span></p>
<p><span style="color: #dddddd;">&nbsp; Second Priority: Child/Children, if any (eldest to youngest in that order</span></p>
<p><span style="color: #ff6600;"><strong>b. For Single Employees</strong></span></p>
<p><span style="color: #dddddd;">First Priority: Parents (anyone ahead of the other)</span></p>
<p><span style="color: #dddddd;">Second Priority: Brothers and Sisters (eldest to youngest in that order)</span></p>
<p><span style="color: #dddddd;">Note: Dependent’s Plan Type should be same with the Principal’s Type or one (1)&nbsp; plan lower only</span></p>
<ol start="5">
<li><span style="color: #ff6600;"><strong>Discounts at Local Establishments - Awesome Perks </strong></span></li>
</ol>
<p><span style="color: #dddddd;">Awesome OS will establish partnerships with local establishments to offer discounts to our Team. Please reach out to the People Team to know about the details.</span></p>
<ol start="6">
<li><span style="color: #ff6600;"><strong>Health and Wellness</strong></span></li>
</ol>
<p><span style="color: #dddddd;">We promote healthy living in order to excel at the workplace. Being sick makes us less productive and it may cause us to use all our leaves. We recommend at least 20 minutes of physical activity 3 days a week. It can be as simple as 20 minutes of walking!</span></p>
<ol style="list-style-type: lower-alpha;">
<li><span style="color: #dddddd;">Basketball – We rent a court for our Team Members for 2 hours every Monday.</span></li>
<li><span style="color: #dddddd;">Badminton – We rent a court every so often for those interested.</span></li>
<li><span style="color: #dddddd;">Volleyball – We rent a court every Saturday for those interested</span></li>
</ol>
<p><span style="color: #dddddd;"><strong>&nbsp;<span style="color: #ff6600;">D.&nbsp;</span></strong><span style="color: #ff6600;"><strong>CHANGE OF PERSONAL DATA </strong></span></span></p>
<p><span style="color: #dddddd;">Any change in the employee’s personal data (name, address, status, contact number, dependents, etc.) must be reported to the People Team department in order to update his/her personal record. Benefits or taxes might need to be recalculated.</span></p>
<p><span style="color: #ff6600;"><strong>PART VIII. </strong><strong>RESIGNATIONS, TERMINATION, DUE PROCESS</strong></span></p>
<p><span style="color: #ff6600;"><strong><u>A. Resignations</u></strong></span></p>
<p><span style="color: #dddddd;">We hate to see people go. We always strive to make AwesomeOS a place where you want to work at. But, we understand if you have better opportunities elsewhere.</span></p>
<p><span style="color: #dddddd;">We would also like exit interviews to see how AwesomeOS can improve as a company.</span></p>
<p><span style="color: #dddddd;">Employees who want to resign must submit a resignation letter <u>at least</u> thirty (30) days in advance.</span></p>
<p><span style="color: #dddddd;">This 30-calendar day notice will give us sufficient time to look for a suitable replacement or manage the workload among Team members so that commitments with clients will not be jeopardized.</span></p>
<p><span style="color: #dddddd;">&nbsp;In addition, this will give the employee sufficient time to process his/her employment clearance, which is a requirement for the release of the employee’s last pay.</span></p>
<p><span style="color: #ff6600;"><strong>Non Compliance of the 30-Calendar Day Notice</strong></span></p>
<p><span style="color: #dddddd;">Failure to comply with the 30-calendar day notice may hold the Team Member liable for damages and will be tagged as AWOL. Immediate may only be allowed for medical reasons provided with unfit to work medical certificate.&nbsp;</span></p>
<p><span style="color: #dddddd;">Those who disappear and go “No Call No Show” (NCNS) for over 2 days will automatically be terminated from the team. Certificate of Employment and final paycheck will not be given. Legitimate reasons for NCNS may be considered on a case-to-case basis. Legitimate reasons may include, but are not limited to, medical emergencies and family emergencies.</span></p>
<p><br><span style="color: #ff6600;"><strong><u>B. Termination/Dismissal</u></strong></span></p>
<p><span style="color: #dddddd;">As much as Awesome OS would like to retain its Team Members, it may exercise its right to terminate any Team Member for any of the following causes (details are described in the Code of Conduct and the Labor Code of the Philippines):</span></p>
<ol>
<li><span style="color: #dddddd;">Serious Misconduct</span></li>
<li><span style="color: #dddddd;">Gross and habitual neglect of assigned duties and responsibilities</span></li>
<li><span style="color: #dddddd;">Breach of trust</span></li>
<li><span style="color: #dddddd;">Commission of a crime or offense against the employer, immediate family member of the employer and duly authorized representative of the employee</span></li>
<li><span style="color: #dddddd;">Analogous causes to the foregoing</span></li>
<li><span style="color: #dddddd;">Causes detailed in the Code of Conduct</span></li>
</ol>
<p><span style="color: #ff6600;">&nbsp;<strong> C.</strong>&nbsp;<strong><u>Due Process</u></strong></span></p>
<p><span style="color: #dddddd;">In compliance to Due Process, any Team Member who has violated any provision in the Code of Conduct will be given an opportunity to present his/her justification or explanation. This written justification together with the incident report will be used by the People Team in reviewing the case and its corresponding result based on the Code of Conduct.</span></p>
<p><span style="color: #dddddd;"><em>&nbsp; &nbsp; &nbsp; We always want to hear both sides of the story first.</em></span></p>
<p><span style="color: #dddddd;"><em><u>&nbsp;Procedure</u></em></span></p>
<ol>
<li><span style="color: #dddddd;">The Team Leader, immediate supervisor, or personnel from HR submits to People Team an incident report (IR) of the violation committed by his/her Team Member. The IR must provide detailed information of the facts and circumstances that will serve as basis for the charge.</span></li>
<li><span style="color: #dddddd;">The People Team issues a First Notice addressed to the Team Member requiring him/her to explain in writing within five (5) calendar days from the receipt of the notice in relation to the alleged violation.</span></li>
<li><span style="color: #dddddd;">The People Team reviews the submitted justification/explanation letter and conducts an investigation to gather more information that can facilitate the objective and speedy disposition of the case. A meeting will be held with the Team Member which will allow the team member to be heard and to rebut any evidence presented. The meeting can also be used to settle the case amicably.</span><br><br></li>
<li><span style="color: #dddddd;">The People Team informs the Team Leader of the resolution and issues the second notice to the Team Member to implement the disciplinary action, if necessary.</span></li>
</ol>
<p><span style="color: #ff6600;"><strong><u>D. Preventive Suspension </u></strong></span></p>
<p><span style="color: #dddddd;">For the purpose of investigation depending on the nature of the alleged violation, the employee may be placed under preventive suspension not exceeding 30 days while the investigation is&nbsp;on-going. This is normally done when the continued presence of the employee in the workplace can jeopardize the operation.</span></p>
<p><span style="color: #dddddd;">The following cases are subject to preventive suspension but not limited to:</span></p>
<ul>
<li>
<div><span style="color: #dddddd;">Dishonesty</span></div>
</li>
<li>
<div><span style="color: #dddddd;">Conflict of Interest</span></div>
</li>
<li>
<div><span style="color: #dddddd;">Theft</span></div>
</li>
<li>
<div><span style="color: #dddddd;">Fraud</span></div>
</li>
<li>
<div><span style="color: #dddddd;">Violation of Zero Tolerance Policy</span></div>
</li>
<li><span style="color: #dddddd;">Rudeness, Unprofessional (formally known as Inappropriate) behavior by an FC</span></li>
<li><span style="color: #dddddd;">Disconnection of Contact without any notice or intentional disconnection</span></li>
<li><span style="color: #dddddd;">Failure to comply or adhere with an escalation request</span></li>
<li><span style="color: #dddddd;">Manipulation of System and Software to misinterpret or falsify results to provide service and/or avoid contact</span></li>
</ul>
<p><span style="color: #ff6600;"><strong>E. Violations</strong></span></p>
<p><span style="color: #dddddd;">Team Members who see, learn of, or suspect any violation of the conditions stated above, should immediately report to the People Team.</span></p>
<p><span style="color: #ff6600;"><strong>F. Civil/Criminal Cases</strong></span></p>
<p><span style="color: #dddddd;">Team Members who are discharged for grave offenses are terminated without prejudice&nbsp;to the filing of appropriate civil /criminal cases against them.</span></p>
<p><span style="color: #ff6600;"><strong>G. Complaint/Grievance Procedure</strong></span></p>
<p><span style="color: #dddddd;">Team members who have a job-related issue, question, or complaint should first discuss it with their respective Team Leaders.</span></p>
<p><span style="color: #dddddd;">If the issue cannot be resolved at this level, please go to the The People Team. If the issue is extremely serious, you can directly send an email to <a style="color: #dddddd;" href="mailto:hr@offsourcing.com">hr@offsourcing.com</a></span></p>
<p><span style="color: #dddddd;">All concerns will be treated with <u>confidentiality</u>.</span></p>
<p><span style="color: rgb(255, 102, 0); font-size: 1.6rem;"><strong>CODE OF CONDUCT&nbsp;</strong></span></p>
<p style="color: #dddddd;">The objective of this Code of Conduct is to establish a clear guideline in implementing sanctions in a progressive manner. We want to avoid processing these warnings as much as possible. We are in the business of providing services to our clients, <u>not writing up people all day</u> . At the same time, we must ensure we keep a healthy and positive environment for all of us to work at.</p>
<p style="color: #dddddd;">As part of a family, AwesomeOS will always exhaust all possible means to retain our employees. Discharge or dismissal is always the last option that we will take unless absolutely necessary.</p>
<p><span style="color: #ff6600;"><strong>Dismissal from Employment</strong></span></p>
<p><span style="color: #dddddd;">Dismissal is an involuntary form of separation carried out by AwesomeOS, due to just and justifiable cause, i.e. the employee's breach of the company Code of Conduct or for grounds stipulated in the Philippine Labor Code:</span></p>
<ol>
<li><span style="color: #dddddd;">Serious misconduct or willful disobedience by the employee of the orders of his / her employer or representative in connection with their work; (PERFORMANCE / INSUBORDINATION)</span></li>
<li><span style="color: #dddddd;">Gross and habitual neglect by the employee of their duties; (PERFORMANCE)</span></li>
<li><span style="color: #dddddd;">Fraud or willful breach by the employee of the trust reposed to them by their employer or duly authorized representative; (DISHONESTY)</span></li>
<li><span style="color: #dddddd;">Commission of a crime or offense by the employee against the person of their employer or any immediate member of their family or his duly authorized representatives; (CRIME)</span></li>
<li><span style="color: #dddddd;">Other causes analogous to the above.</span></li>
</ol>
<p><span style="color: #dddddd;"><strong><u>Types of Offenses</u></strong></span></p>
<p><span style="color: #dddddd;"><strong>Level 1 Offenses</strong></span></p>
<p><span style="color: #dddddd;">An offense which is considered minor, and in terms of:</span></p>
<ol style="list-style-type: lower-alpha;">
<li><span style="color: #dddddd;">Has no detrimental impact on the business and does not significantly decrease the effectiveness of the team, but in some ways affects professionalism and the company.</span></li>
<li><span style="color: #dddddd;">Does not cause any financial loss</span></li>
<li><span style="color: #dddddd;">Has minimal impact to client, company, and employee security</span></li>
<li><span style="color: #dddddd;">Does not hurt any person physically</span></li>
<li><span style="color: #dddddd;">Does not involve a question of integrity</span></li>
</ol>
<p><span style="color: #dddddd;">** Corrective action – Merits a warning at first occurrence</span></p>
<p><span style="color: #dddddd;"><strong>Level 2 Offenses</strong></span></p>
<p><span style="color: #dddddd;">A serious offense which, in terms of:</span></p>
<ol style="list-style-type: lower-alpha;">
<li><span style="color: #dddddd;">Causes delay in operations, decreases the productivity of the team. It also seriously compromises team effectiveness and relationships which may result in the team not being able to complete an assigned project or task, or lead to dissatisfaction of the client with the service rendered.</span></li>
<li><span style="color: #dddddd;">Causes financial losses to the company</span></li>
<li><span style="color: #dddddd;">Action that has a high potential of compromising the security of clients, AwesomeOS, and other employees</span></li>
<li><span style="color: #dddddd;">Poses a real safety hazard and creates a possible occasion for injury</span></li>
</ol>
<p><span style="color: #dddddd;">** Corrective action – Merits a suspension either at first occurrence or after a second occurrence of a similar offense.</span></p>
<p><span style="color: #dddddd;"><strong>Level 3 Offenses</strong></span></p>
<p><span style="color: #dddddd;">An extremely serious offense that merits dismissal at first instance:</span></p>
<ol>
<li><span style="color: #dddddd;">Disrupts continuity of work / operations and / or cause AwesomeOS to lose its client(s)</span></li>
<li><span style="color: #dddddd;">Causes financial losses to the company</span></li>
<li><span style="color: #dddddd;">A critical offense that has compromised the safety of customer information, the integrity of the client or AwesomeOS, and/or the security of other employees.</span></li>
<li><span style="color: #dddddd;">A breach or violation that results in physical harm to anyone</span></li>
</ol>
<p><span style="color: #dddddd;">** Corrective action – Merits an immediate dismissal.</span></p>
<p>&nbsp;</p>
<table style="width: 732px;">
<tbody>
<tr>
<td style="width: 368px;" colspan="3" width="351">
<p><span style="color: #dddddd;"><strong>SANCTIONS FOR OFFENSES</strong></span></p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 51px;" colspan="2" width="33">&nbsp;</td>
<td style="width: 317px;" width="317">&nbsp;</td>
<td style="width: 221px;" width="221">&nbsp;</td>
<td style="width: 77px;" width="77">&nbsp;</td>
<td style="width: 56px;" width="56">&nbsp;</td>
<td style="width: 10px;" width="0">&nbsp;</td>
</tr>
<tr>
<td style="width: 368px;" colspan="3" width="351">
<p><span style="color: #dddddd;"><strong>LEVEL 1</strong></span></p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p>&nbsp;</p>
</td>
<td style="width: 16px;" width="8">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p><span style="color: #dddddd;"><sub>1</sub>st</span></p>
</td>
<td style="width: 333px;" colspan="2" width="325">
<p><span style="color: #dddddd;">offense</span></p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Written Warning</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 368px;" colspan="3" width="351">
<p><span style="color: #dddddd;">Subsequent offenses</span></p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Suspension; 3 – 30 days;</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p>&nbsp;</p>
</td>
<td style="width: 16px;" width="8">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Maximum of Discharge</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 51px;" colspan="2" width="33">&nbsp;</td>
<td style="width: 317px;" width="317">&nbsp;</td>
<td style="width: 221px;" width="221">&nbsp;</td>
<td style="width: 77px;" width="77">&nbsp;</td>
<td style="width: 56px;" width="56">&nbsp;</td>
<td style="width: 10px;" width="0">&nbsp;</td>
</tr>
<tr>
<td style="width: 368px;" colspan="3" width="351">
<p><span style="color: #dddddd;"><strong>LEVEL 2</strong></span></p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p>&nbsp;</p>
</td>
<td style="width: 16px;" width="8">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p><span style="color: #dddddd;"><sub>1</sub>st</span></p>
</td>
<td style="width: 333px;" colspan="2" width="325">
<p><span style="color: #dddddd;">offense</span></p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Written Warning; Maximum of</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p>&nbsp;</p>
</td>
<td style="width: 16px;" width="8">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Suspension; 3 – 5 days;</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 51px;" colspan="2" width="33">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 368px;" colspan="3" width="351">
<p><span style="color: #dddddd;">Subsequent offenses</span></p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Suspension; 3 – 30 days;</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p>&nbsp;</p>
</td>
<td style="width: 16px;" width="8">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Maximum of Discharge</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 51px;" colspan="2" width="33">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 368px;" colspan="3" width="351">
<p><span style="color: #dddddd;"><strong>LEVEL 3</strong></span></p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p>&nbsp;</p>
</td>
<td style="width: 16px;" width="8">
<p>&nbsp;</p>
</td>
<td style="width: 317px;" width="317">
<p>&nbsp;</p>
</td>
<td style="width: 221px;" width="221">
<p>&nbsp;</p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td style="width: 35px;" width="25">
<p><span style="color: #dddddd;"><sub>1</sub>st</span></p>
</td>
<td style="width: 333px;" colspan="2" width="325">
<p><span style="color: #dddddd;">offense</span></p>
</td>
<td style="width: 221px;" width="221">
<p><span style="color: #dddddd;">Dismissal</span></p>
</td>
<td style="width: 77px;" width="77">
<p>&nbsp;</p>
</td>
<td style="width: 56px;" width="56">
<p>&nbsp;</p>
</td>
<td style="width: 10px;" width="0">
<p>&nbsp;</p>
</td>
</tr>
</tbody>
</table>
<p><span style="color: #ff6600;"><strong>TABLE OF OFFENSES AND SANCTIONS</strong></span></p>
<p><span style="color: #dddddd;"><strong><span style="color: #ff6600;">OFFENSE AGAINST CONDUCT</span> </strong></span></p>
<table width="667">
<tbody>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #ff6600;"><strong>OFFENSES</strong></span></p>
</td>
<td width="79">
<p><span style="color: #ff6600;"><strong>Type pf Sanction </strong></span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Violation to General Office Rules </strong></span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Discourtesy and Use of Disrespectful Language</strong></span></p>
<p><span style="color: #dddddd;">Discourtesy either by acts or words, using disrespectful or offensive language against customers, employees, visitors and guests.</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Engaging in Any Form of Gambling or Game of Chance</strong></span></p>
<p><span style="color: #dddddd;">Engaging in any form of gambling of chance inside Company premises or while on duty outside Company premises.</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Assaulting or Inflicting Bodily Harm</strong></span></p>
<p><span style="color: #dddddd;">Assaulting, inflicting or fighting within Company premises resulting in any kind of injury of any violent behavior, wielding any form of weaponry, even if no harm was actually inflicted to another, whether employees, visitors, vendors and guests, or outside Company premises while on official duty, except in case of defense of one’s self or another against unprovoked</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Shouting, Threatening, Intimidating, Insulting or Quarreling</strong></span></p>
<p><span style="color: #dddddd;">Shouting, threatening, intimidating, insulting, quarreling or inciting to quarrel with an employee, visitor, vendor, or guest within or outside work premises.</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;">Harassment</span></p>
<p>&nbsp;</p>
<p><span style="color: #dddddd;">Including, but not limited to derogatory racial, ethnic, religious age, sexual orientation, or other inappropriate remarks, slurs, or jokes.</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Sexual Harassment</strong></span></p>
<p><span style="color: #dddddd;">As provided for by law</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;">Blackmailing, extortion or coercion of any teammate, client or the company for personal favor.</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Use or Sale of Prohibited Drugs</strong></span></p>
<p><span style="color: #dddddd;">Using, possessing of, or pushing, selling or supplying of prohibited or dangerous drugs and their derivatives to guests, suppliers, outside agencies, officers, and other employers</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>&nbsp;</strong></span></p>
<p><span style="color: #dddddd;"><strong>Insubordination</strong></span></p>
<p><span style="color: #dddddd;">Willfully defying or disregarding Company authority. Disobeying, delaying or non-execution of any official system/procedure or any direct order or instruction written or verbal (with witnesses), that results tore-work disruption, prejudice, work stoppage, slowdown, delay, accident, injuries, damages or loss.</span></p>
</td>
<td width="79">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="546">
<p><span style="color: #dddddd;"><strong>Refusing to Carry Out Instructions</strong></span></p>
<p><span style="color: #dddddd;">Refusing to carry out verbal or written instructions, policies and procedures or standard operating procedures or any legitimate order issued by the Affinity management</span></p>
</td>
<td width="79">
<p>&nbsp;</p>
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">&nbsp;</td>
<td width="546">&nbsp;</td>
<td width="79">&nbsp;</td>
</tr>
<tr>
<td width="41">&nbsp;</td>
<td width="546">&nbsp;</td>
<td width="79">&nbsp;</td>
</tr>
</tbody>
</table>
<p><span style="color: #ff6600;"><strong>&nbsp;</strong><strong>OFFENSE AGAINST HEALTH, SAFETY AND SANITATION STANDARDS</strong></span></p>
<table width="667">
<tbody>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Smoking in non-smoking areas</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Loitering while UNFIT TO WORK </strong></span></p>
<p><span style="color: #dddddd;">Loitering around the company premises after having been advised to go home by the Company Accredited Physician.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Destruction of Property</strong></span></p>
<p><span style="color: #dddddd;">Intentionally destroying or damaging of property, records, or information of Company, employees, visitors, vendors and guests.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Engaging Safety</strong></span></p>
<p><span style="color: #dddddd;">Endangering safety of employees, visitors, vendors and guests or unnecessary disruption of operations whether verbal or physical causing alarm, discomfort and scandal.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Tampering with Fire Equipment</strong></span></p>
<p><span style="color: #dddddd;">Using, removing or tampering with fire protection equipment for purposes other than firefighting.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Working under the Influence of Liquor</strong></span></p>
<p><span style="color: #dddddd;">Working under the influence of liquor within Company premises or work area not authorized by the Management and/or the client.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Working under the Influence of Drugs</strong></span></p>
<p><span style="color: #dddddd;">Working under the influence of drugs or other controlled substances within the Company premises or work area.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Entering/Using Restricted Areas</strong></span></p>
<p><span style="color: #dddddd;">Unauthorized entry or use of restricted areas or premises, i.e., server room, production floor, and the like.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Refusing to Comply with Prescribed/Control Procedures</strong></span></p>
<p><span style="color: #dddddd;">Failure to comply or conformed with prescribed or control procedures such as but not limited to, prescribed procedures on accidents, safety or security regulations that may prejudice employees, visitors, vendors, and guests and/or Company property.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Disregard of Security Standards</strong></span></p>
<p><span style="color: #dddddd;">Refusing to submit to security personnel in the conduct of their duties, i.e., baggage inspection, body search, vehicle inspection, inquiry on a certain investigation, frisking etc.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Entering by Force</strong></span></p>
<p><span style="color: #dddddd;">Entering or attempting to enter company premises by force, intimidation or deception.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Refusing to Comply with Sanitation Rules</strong></span></p>
<p><span style="color: #dddddd;">Refusing to comply with sanitation rules or improper use of sanitation facilities, i.e., non-flushing of toilets, spitting, urinating at improper receptacles or areas, littering and performing other unsanitary acts.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Refusing to Submit to Required Medical Examination, provide a written consent to testing and/or providing false or tampered specimen</strong>.</span></p>
<p><span style="color: #dddddd;">Refusing to submit to the required Medical Dental, Physical and other health examinations, i.e., prohibited drugs, HIV, Hepatitis, Sexual Transmitted Diseases that are required by the Company; refusing to provide a written consent for the testing and/or providing a tampered specimen.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Refusing to Comply with Government Requirements</strong></span></p>
<p><span style="color: #dddddd;">Refusing to comply with the national and local government requirements as required by the Company, i.e., health certificates, police clearance, etc.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Failure to Comply with Prescribed/Control Procedures</strong></span></p>
<p><span style="color: #dddddd;">Failure to comply or conform with prescribed or control procedures such as but not limited to, standard operating procedures on accidents, safety or security regulations that may prejudice employees, visitors, vendors and guests.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Failure to Comply with Security Standards</strong></span></p>
<p><span style="color: #dddddd;">Failure to submit security personnel in the conduct of their duties, i.e., baggage inspection, body search, finger printing, inquiry on a certain investigation, frisking, etc.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Hiding a Contagious Disease </strong></span></p>
<p><span style="color: #dddddd;">Hiding a known or Contagious disease or major illness which include but not limited to Tuberculosis, and Hepatitis, Sexually Transmitted Diseases, which may endanger the health or life of guests, suppliers, officers and other employees.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Unauthorized Visitors in the Office </strong></span></p>
<p><span style="color: #dddddd;">Allowing visitors to go inside the production floor, conference room, training rooms without HR’s approval.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Bringing Inside Work Area/Storing of Personal Belongings</strong>.</span></p>
<p><span style="color: #dddddd;">Bringing inside Production Area/work area or storing personal belongings in other lockers or areas not assigned to the employee or personnel which includes but not limited to cellphones, IPods, MP3 etc</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Carrying Guns or Dangerous Weapons</strong></span></p>
<p><span style="color: #dddddd;">Unauthorized carrying, bringing or attempting to bring firearms, ammunitions, explosives, sharp or deadly weapons within Company premises</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="color: #dddddd;"><strong><span style="color: #ff6600;">OFFENSE AGAINST PRODUCTIVITY</span> </strong></span></p>
<table width="667">
<tbody>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Prolonging Break or Rest Periods</strong></span></p>
<p><span style="color: #dddddd;">Prolonging break or rest periods or taking over break .&nbsp;</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Taking Meal Breaks at Unauthorized times</strong></span></p>
<p><span style="color: #dddddd;">Not following schedule break time or taking meals prior to break time without approval by supervisor/manager.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Leaving Duty Area/s or going Outside Company premises</strong></span></p>
<p>&nbsp;</p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Extending Leave Periods</strong></span></p>
<p><span style="color: #dddddd;">Continuing to go on leave despite disapproval by the supervisor/manager or extending original period previously – authorized vacation or sick-leave without prior authorization or justifiable reason.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Failure to Log In or Out</strong></span></p>
<p><span style="color: #dddddd;">Failure to record attendance by not logging in or out on OSnet and other client tools.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Sleeping while on the Job&nbsp; </strong></span></p>
<p><span style="color: #dddddd;">Sleeping during working hours (Intentional or Unintentional)</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Habitual Tardiness</strong></span></p>
<p><span style="color: #dddddd;">Three occurrences of tardiness in a month whether consecutive or not</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Excessive Absences</strong></span></p>
<p><span style="color: #dddddd;">Excessive absence, which refers to absence from work without prior notice to the Team Supervisor, and or HR for three days whether consecutive or not.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Careless Deviation Failure to Comply with Company Policies and Procedures</strong></span></p>
<p><span style="color: #dddddd;">Failure to comply or conform to company policies and procedures promulgated from time to time.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Inefficiency at Work&nbsp; </strong></span></p>
<p><span style="color: #dddddd;">Inefficiency or failure to complete assigned tasks</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Performing Below Expectations</strong></span></p>
<p><span style="color: #dddddd;">Failure of employee to perform according to performance standards set by the Company whether during probationary or regular status.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Unauthorized Leave </strong></span></p>
<p><span style="color: #dddddd;">Unauthorized leave (no valid reason and/or permission from team leader, manager and/or the client.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>No Call No Show </strong></span></p>
<p><span style="color: #dddddd;">Absence Without Official leave/and or Failure to notify within the 4 hours of the shift.&nbsp;</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Abandonment of Post </strong></span></p>
<p><span style="color: #dddddd;">Leaving duty area/s or going outside Company premises during working hours without proper authorization and relief.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Gross and Habitual Negligence of Duty </strong></span></p>
<p><span style="color: #dddddd;">Inefficiency or poor quality of work as documented through established standards</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">&nbsp;</td>
<td width="554">&nbsp;</td>
<td width="72">&nbsp;</td>
</tr>
</tbody>
</table>
<p><span style="color: #ff6600;"><strong>OFFENSE AGAINST INFORMATION AND SECURITY</strong></span></p>
<table width="667">
<tbody>
<tr>
<td width="41">&nbsp;</td>
<td width="554">&nbsp;</td>
<td width="72">&nbsp;</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Violation to the Internet, E-mail, and computer usage Policy</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Using or attempting to use a teammate’s computer or intranet account without authorization. </strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Unauthorized Access to Computer Files </strong></span></p>
<p><span style="color: #dddddd;">Attempting to gain unauthorized access and privileges by defeating the network security (hacking), bypassing proxy servers, stealing/sharing network passwords, accessing classified system, folders and documents and exploiting system and vulnerabilities.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Willful introduction or computer viruses, worms, Trojan horses and unauthorized programs or objects into computer systems</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Attempted vandalism of equipment or software such as physical destruction of computer equipment, destruction of cabling and network infrastructures, and destruction and alteration of system files and application. This also includes denial-of-service attacks perpetrated by end-users.</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Attempts to gain unauthorized access and privileges by defeating network security (hacking),Bypassing proxy servers, stealing/sharing network passwords, accessing classified system folders and documents, and exploiting system weaknesses and vulnerabilities.</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Providing copies of software to any unauthorized persons</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Deleting, reading, copying or modifying files and/or data belonging to other users without their prior consent.</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Installing software on any company equipment unless authorized to do so.</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Breach of the intellectual property laws through the use of unlicensed software (software not approved by IT) and the distribution and use of illegally obtained media files such as MP3s and movies.</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Taking, copying, printing and/or otherwise duplicating the company’s proprietary information and intellectual property, even after an employee has left the organization can result in corresponding administrative, civil or criminal liability.</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Exploring/Browsing of pornographic materials and adult websites, playing computer games, downloading any unauthorized/non-business related programs, engaging in commercial activities on the Internet such as online-shopping, chatting (unless authorized by management), using email to send unsolicited junk mail such as chain letters and/or pornographic pictures, forging or attempting to forge email messages, disguising or attempting to disguise identity when sending email.</strong></span></p>
<p><span style="color: #dddddd;"><strong>&nbsp;</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style="color: #ff6600;"><strong>OFFENSE AGAINST PROPERTY </strong></span></p>
<table width="667">
<tbody>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Failure to return on agreed date any borrowed item or equipment owned by company.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Unauthorized Moving of Company Property</strong></span></p>
<p><span style="color: #dddddd;">Changing/removing keyboard function keys, keyboard, headsets, documents, from one station to another without proper authority/permission</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">1</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Unauthorized Possession of Company Property </strong></span></p>
<p><span style="color: #dddddd;">Unauthorized possession or use of company property, supplies or resources or any property owned by or belonging to another employee.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Unauthorized Bringing Into or Taking Out Any Article from Company Premises </strong></span></p>
<p><span style="color: #dddddd;">Bringing into or taking out From Company premises any article without proper authorization and security pass.&nbsp; (Affinity reserves the right to inspect personal items, packages or bags carried by an employee or personnel in or out of the company premises).</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Unauthorized Sale of Company Property </strong></span></p>
<p><span style="color: #dddddd;">Engaging in unauthorized sale of Company property, supplies, medicines or resources or any property owned by or belonging to another employee.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Defacing Company Property </strong></span></p>
<p><span style="color: #dddddd;"><strong>Willfully defacing or damaging company property by adding stickers, picti</strong></span></p>
</td>
<td width="72">
<p>&nbsp;</p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Vandalism</strong></span></p>
<p><span style="color: #dddddd;">Writing on walls, work stations, comfort rooms, locker rooms, elevators, machines, equipment or any Company property or premises.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Robbery, Theft, Pilferage and Misappropriation of Funds </strong></span></p>
<p><span style="color: #dddddd;">Robbery, Theft, pilferage, misappropriation of funds or its attempt in any form or manner</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">&nbsp;</td>
<td width="554">&nbsp;</td>
<td width="72">&nbsp;</td>
</tr>
</tbody>
</table>
<p><span style="color: #ff6600;"><strong>OFFENSE AGAINST INTEGRITY </strong></span></p>
<table width="667">
<tbody>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Failure to submit on time necessary pre-employment documents and update information pertaining to the employee</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Failure to complete within the specified time employment requirements such as submission of clearances , IRs, Coaching Forms, Leave Forms.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">2</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Allowing others to use their ID or login credentials, including but not limited to. Osnet logins and account’s tools.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Altering own or another Employee’s Attendance Record </strong></span></p>
<p><span style="color: #dddddd;">Unauthorized Alteration of own or another attendance employee’s attendance records, timing in for another employee or maliciously removing the attendance records from its proper place.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Borrowing, Accepting or Soliciting from Suppliers/Customers </strong></span></p>
<p><span style="color: #dddddd;">Borrowing, accepting, or soliciting money, gifts, commission, materials, or anything of value from guests, suppliers, outside agencies, officers or other employees with which the Company has a business relationship or personal gain or benefit.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Engaging in Outside work of Professional Practice</strong></span></p>
<p><span style="color: #dddddd;">Engaging in outside work professional practice which directly or indirectly becomes a business competitor of the Company adversely affects the image of the Company or takes undue advantage of his employment in the company.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Betrayal of Trust and Confidence </strong></span></p>
<p><span style="color: #dddddd;">Concealment of facts, and/or Unauthorized disclosure of Confidential information which includes but not limited Company Records, trade secret, formula, financial information or statement, and other Company documents, to other companies or person.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Falsification of Documents </strong></span></p>
<p><span style="color: #dddddd;">Falsifying any document, record or information, which include but not limited to personnel, financial, or production record, voucher, receipt, time sheet, application form, or presenting such falsified document, records or information in any study, inquiry research, investigation or proceeding in which the company is involved.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>False Testimony </strong></span></p>
<p><span style="color: #dddddd;">Giving false testimonies/information to avoid responsibility to distort the true facts to give undue advantage to one’s self or another.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<table width="667">
<tbody>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>SABOTAGE</strong> Willful acts which adversely affect the operations of AwesomeOS.&nbsp; &nbsp;including but not limited to:</span></p>
<p><span style="color: #dddddd;">-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Spying or obtaining information, or including other to the same, from the organization’s confidential records for the purpose of discrediting the organization and/or transmitting the same to other’s prejudice of the organization.</span></p>
<p><span style="color: #dddddd;">-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Making false or malicious statements concerning AwesomeOS.&nbsp;</span></p>
<p><span style="color: #dddddd;">-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Revealing or issuing, without authority, confidential information, data and/or documents regarding operations or trade secrets of the organization to third parties and/or employees who are not authorized.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Engaging in a criminal act, whether or not it is related to job performance, when filed in court.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;">Tampering or manipulating of statistics, scores, records, equipment or software</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Libel/Slander</strong></span></p>
<p><span style="color: #dddddd;">Making libelous, malicious and false utterances or statements, publications or engaging in gossip which tends to cause dishonor or embarrassment to the company, employees, visitors, vendors and guests.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">&nbsp;</td>
<td width="554">&nbsp;</td>
<td width="72">&nbsp;</td>
</tr>
</tbody>
</table>
<p><span style="color: #ff6600;"><strong>WILLFULL BREACH OF TRUST </strong></span></p>
<table width="667">
<tbody>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Fraudulent Acts </strong></span></p>
<p><span style="color: #dddddd;">Obtaining or Attempting to obtain materials through fraudulent order and collusion with the person doing so, in which the Company has suffered or strands to suffer monetary loss. Improperly withdrawing Company Supplies, Records, Equipment, tools or other assets from the company without proper authorization.</span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Theft</strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Dishonesty </strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Disloyalty </strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
<tr>
<td width="41">
<p>&nbsp;</p>
</td>
<td width="554">
<p><span style="color: #dddddd;"><strong>Offense against employer, any immediate family members of any teammate, or any other authorized representatives: Assault, Disrespect, or defamation. </strong></span></p>
</td>
<td width="72">
<p><span style="color: #dddddd;">3</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p><span style="color: #dddddd;">WOW!! That was a lot of information. Please ask if you have any questions.</span></p>
<p><span style="color: rgb(230, 126, 35);"><strong>Welcome to Awesome OS Family! </strong></span></p>
<p style="text-align: justify;">&nbsp;</p>
<p style="text-align: justify;">&nbsp;</p>
                </div>
                </div>

            </div><!-- /.box-body -->

            <div class="box-footer" style="text-align: center; padding-bottom: 40px; display: block" id="handbook-footer">
                <input type="hidden" value="4894" id="handbook-confirmmed-by">
                <br> 
                <label style="cursor: pointer; font-size: 17px; display: flex; justify-content: space-around;">
                    <input type="checkbox" id="handbook-checkbox">
                    <p  style="color: #dddddd;">I acknowledge that I have read, understand, and agree to the terms and conditions of the handbook.</p>
                </label><br><br>
                <button type="button" class="btn btn-primary" id="submit-confirmation" style="display: none;">Submit Confirmation</button>
            </div><!-- /.box-footer-->
        </div><!-- /.box -->

    </section>
`;

const Handbook: React.FC = () => {
  // Create a new DOMParser
  const parser = new DOMParser();

  // Parse the HTML string into a DOM document
  const doc = parser.parseFromString(handBookContent, 'text/html');

  return (
    <div className="flex user-handbook-parent user-newsfeed-test">
      <div className="container-handbook">
        <div className="w-full pb-3">
          <h1 className="my-2">Offsourcing Official Handbook</h1>
        </div>
        <div className="handbook-second-container flex-1">
          <div
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: doc.querySelector('section.content')?.innerHTML ?? '',
            }}
          />
        </div>
      </div>
      <div className="calendar-wrapper  mt-[1.5rem]">
        <UserCalendar />
      </div>
    </div>
  );
};

export default Handbook;
