import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import UserEmploymentTeam from '../../../constants/employees/teams/employee-team.interface';
import userEmploymentTeamsServices, {
  GetAllUserEmploymentTeamsQueryParams,
} from '../../../services/users/employments/userEmploymentTeamsServices';
import consoleLog from '../../../../utils/consoleLog';

const get = createAsyncThunk(
  'userEmploymentTeam/get',
  async (
    id: UserEmploymentTeam['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentTeam> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentTeamsServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'userEmploymentTeam/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllUserEmploymentTeamsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<UserEmploymentTeam> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentTeamsServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'userEmploymentTeam/post',
  async (
    data: Omit<UserEmploymentTeam, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentTeam> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentTeamsServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'userEmploymentTeam/put',
  async (
    data: AtLeast<UserEmploymentTeam, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentTeam> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentTeamsServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'userEmploymentTeam/remove',
  async (
    id: UserEmploymentTeam['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentTeam> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentTeamsServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getUser = createAsyncThunk(
  'userEmploymentTeam/getUser',
  async (
    id: UserEmploymentTeam['employeeNumber'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentTeam> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      const data = await userEmploymentTeamsServices.getUser(id, state);
      consoleLog('DATA GET USER:', id);
      consoleLog('DATA GET USER:', data);
      return data;
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove, getUser } as const;
