import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentRole from '../../constants/employments/roles/employment-role.interface';
import employmentRoleService, {
  GetAllEmploymentRoleQueryParams,
} from '../../services/employments/employmentRoleServices';

const get = createAsyncThunk(
  'employmentRole/get',
  async (
    id: EmploymentRole['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentRole> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentRoleService.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'employmentRole/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllEmploymentRoleQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmploymentRole> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentRoleService.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'employmentRole/post',
  async (
    data: Omit<EmploymentRole, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentRole> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentRoleService.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'employmentRole/put',
  async (
    data: AtLeast<EmploymentRole, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentRole> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentRoleService.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'employmentRole/remove',
  async (
    id: EmploymentRole['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentRole> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await employmentRoleService.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
