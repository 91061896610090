import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useDepartments from '../../../hooks/departments/useDepartments';
import Department from '../../../store/constants/departments/department.interface';

export interface SelectDepartmentProps extends Partial<AutoSearchSelectProps<Department>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectDepartment: React.FC<SelectDepartmentProps> = ({
  id,
  name,
  placeholder = 'Select Department',
  className = '',
  ...props
}) => {
  const { departmentsState, getAll } = useDepartments();

  useOnLoad(() => {
    getAll().then();
  });

  const departments = departmentsState.departments
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((department) => ({
      ...department,
      value: department.id,
      label: department.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-department modal-select ${className}`}
      isSearchable
      placeholder={placeholder}
      data={departments}
    />
  );
};

export default SelectDepartment;
