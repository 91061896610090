import React from 'react';
import { Typography } from '@material-tailwind/react';
import moment from 'moment';
import useHolidays from '../../../hooks/holidays/useHolidays';
import useOnLoad from '../../../hooks/utils/useOnLoad';

interface HolidayType {
  id: number;
  name: string;
  image?: string;
  dateCelebrated: Date;
}

const monthHolidayImages = [
  'https://images.pexels.com/photos/209786/pexels-photo-209786.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  'https://images.pexels.com/photos/4799242/pexels-photo-4799242.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  'https://images.pexels.com/photos/902288/pexels-photo-902288.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  'https://images.pexels.com/photos/1076240/pexels-photo-1076240.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  'https://images.pexels.com/photos/12595750/pexels-photo-12595750.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  'https://images.pexels.com/photos/588266/pexels-photo-588266.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
];

const HolidaysInMonth: React.FC = () => {
  const currentDate = moment();
  const { holidaysState, getAll } = useHolidays();

  const holidaysThisMonth: HolidayType[] = holidaysState.holidays
    .filter((holiday) => {
      const holidayDate = moment(holiday.dateCelebrated);
      return holidayDate.month() === currentDate.month();
    })
    .map((holiday) => ({
      id: holiday.id,
      name: holiday.name,
      dateCelebrated: holiday.dateCelebrated,
    }))
    .sort((a, b) => moment(a.dateCelebrated).diff(moment(b.dateCelebrated)));

  const holidaysWithRandomImage = holidaysThisMonth.map((holiday) => {
    const randomIndex = Math.floor(Math.random() * monthHolidayImages.length);
    return {
      ...holiday,
      image: monthHolidayImages[randomIndex],
    };
  });

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full p-2 rounded-xl shadow-lg holidays-month-container mt-[135px]">
      <div className="p-1">
        <Typography variant="paragraph" className="font-semibold text-[#f05825] text-lg">
          Holidays in this Month
        </Typography>
      </div>
      <div className="p-2 w-full holidays-month-content overflow-scroll">
        <div className="p-2 m-1 mb-3 rounded-lg shadow-md holidays-wrapper">
          <div className="relative holiday-image-container">
            <img
              src="https://images.pexels.com/photos/1656564/pexels-photo-1656564.jpeg"
              alt="holiday-1"
              className="rounded-lg holiday-month-image"
            />
            <div className="dark-overlay" />
            <span className="rounded-md holiday-name ">December 25, 2023</span>
          </div>
        </div>
        {holidaysWithRandomImage.map((holiday) => (
          <div
            key={holiday.id}
            className="p-2 m-1 mb-3 rounded-lg shadow-md holidays-wrapper hidden"
          >
            <div className="relative holiday-image-container">
              <img
                src={holiday.image}
                alt={`${holiday.id}`}
                className="rounded-lg holiday-month-image"
              />
              <div className="dark-overlay" />
              <span className="rounded-md holiday-name">{holiday.name}</span>
              <span className="rounded-md holiday-date">
                {moment(holiday.dateCelebrated).format('MMMM D, YYYY')}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HolidaysInMonth;
