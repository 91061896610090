import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import LeaveApplication from '../../constants/leaves/leave-application.interface';
import Team from '../../constants/teams/team.interface';
import Department from '../../constants/departments/department.interface';
import LeaveStatusOption from '../../constants/leaves/leave-status-option';
import toURLParams from '../../../utils/toURLParams';
import UserEmployment from '../../constants/employees/employee.interface';

export interface Duration {
  dateStart: string | undefined;
  dateEnd: string | undefined;
}

export interface GetAllLeavesQueryParams extends ApiGetAllBaseQueryParams {
  employeeNumber?: UserEmployment['employeeNumber'];
  teamId?: Team['id'];
  departmentId?: Department['id'];
  status?: LeaveStatusOption['value'];
  duration?: Duration;
}

const get = async (
  id: LeaveApplication['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication>> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`leaves/applications/${id}`, { state })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  {
    page = 1,
    limit = 50,
    search = '',
    employeeNumber = undefined,
    teamId = undefined,
    departmentId = undefined,
    status = undefined,
    duration = undefined,
  }: GetAllLeavesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<LeaveApplication>> =>
  apiFetch<ApiGetAllBaseResponse<LeaveApplication>>(
    `leaves/applications?${
      toURLParams({
        limit,
        page,
        search,
        employeeNumber,
        teamId,
        departmentId,
        status,
        // TODO: 1. Must pass only duration
        ...(duration && {
          'duration[dateStart]': duration.dateStart,
          'duration[dateEnd]': duration.dateEnd,
        }),
      }) ?? ''
    }`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<LeaveApplication, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>('leaves/applications', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: LeaveApplication,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`leaves/applications/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<LeaveApplication, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`leaves/applications/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: LeaveApplication['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`leaves/applications/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const approve = async (
  data: AtLeast<LeaveApplication, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`leaves/applications/${data.id}/approve`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const deny = async (
  data: AtLeast<LeaveApplication, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LeaveApplication> | void> =>
  apiFetch<ApiBaseResponse<LeaveApplication>>(`leaves/applications/${data.id}/deny`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LeaveApplication>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  approve,
  deny,
} as const;
