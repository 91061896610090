import React from 'react';
import { Switch } from '@material-tailwind/react';

interface ToggleSwitchProps {
  id: string;
  label: string;
  isChecked: boolean;
  onChange: (value: boolean) => void;
}

const LabelledToggleSwitch: React.FC<ToggleSwitchProps> = ({ id, label, isChecked, onChange }) => (
  <div className="flex justify-between gap-2">
    <label className="toggle-label" htmlFor={id}>
      {label}
    </label>
    <Switch
      color="orange"
      id={id}
      name={id}
      ripple={false}
      checked={isChecked}
      onChange={(e) => onChange(e.target.checked)}
      className="payroll-toggle"
      containerProps={{
        className: 'payroll-toggle-container',
      }}
      circleProps={{
        className: 'payroll-toggle__circle',
      }}
    />
  </div>
);

export default LabelledToggleSwitch;
