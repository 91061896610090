import React from 'react';
import { PayslipSummaryProps } from './PayslipSummary';

const contentLabel = {
  border: '1px solid rgba(0, 0, 0, 0.3)',
  padding: '8px',
  fontWeight: '400',
};

const contentData = {
  textAlign: 'right' as const,
  border: '1px solid rgba(0, 0, 0, 0.3)',
  padding: '8px',
  fontWeight: '400',
};

const stripeColor = {
  backgroundColor: '#f8f8f8',
};

// @todo Replace any with Payslip interface when interface details is finalized
// eslint-disable-next-line
const payslipSummary = (payslip: any) => (
  <table
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      border: '1px solid rgba(0, 0, 0, 0.3)',
    }}
  >
    <tbody>
      <tr style={{ ...stripeColor }}>
        <td
          colSpan={2}
          align="center"
          style={{ border: '1px solid rgba(0, 0, 0, 0.3)', padding: '8px' }}
        >
          <b>Payslip Summary</b>
        </td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Name</td>
        <td style={{ ...contentData }}>
          {payslip.first_name} {payslip.last_name}
        </td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Employment Number</td>
        <td style={{ ...contentData, ...stripeColor }}>{payslip.employeeNumber}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Days Worked</td>
        <td style={{ ...contentData, ...stripeColor }}>{payslip.workedDays}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Days on Leave</td>
        <td style={{ ...contentData }}>0.00</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Daily Gross Rate</td>
        <td style={{ ...contentData, ...stripeColor }}>{payslip.daysPerMonth}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Daily Basic Rate</td>
        <td style={{ ...contentData }}>{payslip.dailyRate}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Gross Income</td>
        <td style={{ ...contentData, ...stripeColor }}>21,555.17</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Net Income:</td>
        <td style={{ ...contentData, fontWeight: 'bold' }}>20,807.65</td>
      </tr>
    </tbody>
  </table>
);

// @todo Replace any with Payslip interface when interface details is finalized
// eslint-disable-next-line
const taxableIncome = (payslip: any) => (
  <table
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      border: '1px solid rgba(0, 0, 0, 0.3)',
    }}
  >
    <tbody>
      <tr style={{ ...stripeColor }}>
        <td
          colSpan={2}
          align="center"
          style={{ border: '1px solid rgba(0, 0, 0, 0.3)', padding: '8px' }}
        >
          <b>Taxable Income</b>
        </td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Basic Pay</td>
        <td style={{ ...contentData }}>{payslip.monthlyBasic}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Holiday Overtime Pay</td>
        <td style={{ ...contentData, ...stripeColor }}>3,424</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Holiday Pay</td>
        <td style={{ ...contentData }}>32,432</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Night Diff Regular Holiday Overtime</td>
        <td style={{ ...contentData, ...stripeColor }}>45.12</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Night Diff Regular Overtime</td>
        <td style={{ ...contentData }}>34.21</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Productivity</td>
        <td style={{ ...contentData, ...stripeColor }}>3,213.43</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Regular Overtime Pay</td>
        <td style={{ ...contentData }}>3,244.77</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Sub Total:</td>
        <td style={{ ...contentData, fontWeight: 'bold', ...stripeColor }}>21,435.17</td>
      </tr>
    </tbody>
  </table>
);

// @todo Replace any with Payslip interface when interface details is finalized
// eslint-disable-next-line
const noneTaxables = (payslip: any) => (
  <table
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      border: '1px solid rgba(0, 0, 0, 0.3)',
    }}
  >
    <tbody>
      <tr style={{ ...stripeColor }}>
        <td
          colSpan={2}
          align="center"
          style={{ border: '1px solid rgba(0, 0, 0, 0.3)', padding: '8px' }}
        >
          <b>Non-Taxable Income</b>
        </td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Clothing Allowance</td>
        <td style={{ ...contentData }}>{payslip.clothingAllowance}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Gifts</td>
        <td style={{ ...contentData, ...stripeColor }}>{payslip.giftAllowance}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Laundry Allowance</td>
        <td style={{ ...contentData }}>245.45</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Meal Allowance</td>
        <td style={{ ...contentData, ...stripeColor }}>{payslip.mealAllowance}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Medical</td>
        <td style={{ ...contentData }}>256.23</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Other Benefits</td>
        <td style={{ ...contentData, ...stripeColor }}>414.43</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Rice Allowance</td>
        <td style={{ ...contentData }}>{payslip.riceAllowance}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Sub Total:</td>
        <td style={{ ...contentData, ...stripeColor }}>23,123.17</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Gross Pay:</td>
        <td style={{ ...contentData, fontWeight: 'bold' }}>23,807.65</td>
      </tr>
    </tbody>
  </table>
);

// @todo Replace any with Payslip interface when interface details is finalized
// eslint-disable-next-line
const deductions = (payslip: any) => (
  <table
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      border: '1px solid rgba(0, 0, 0, 0.3)',
    }}
  >
    <tbody>
      <tr style={{ ...stripeColor }}>
        <td
          colSpan={2}
          align="center"
          style={{ border: '1px solid rgba(0, 0, 0, 0.3)', padding: '8px' }}
        >
          <b>Deductions</b>
        </td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>PAGIBIG</td>
        <td style={{ ...contentData }}>{payslip.pagibig}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>PHIC</td>
        <td style={{ ...contentData, ...stripeColor }}>{payslip.phic}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>SSS</td>
        <td style={{ ...contentData }}>{payslip.sss}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel, ...stripeColor }}>Tax</td>
        <td style={{ ...contentData, ...stripeColor }}>{payslip.tax}</td>
      </tr>
      <tr>
        <td style={{ ...contentLabel }}>Total Deductions:</td>
        <td style={{ ...contentData, fontWeight: 'bold' }}>1,088.5</td>
      </tr>
    </tbody>
  </table>
);

const PrintPayslip: React.FC<PayslipSummaryProps> = ({ payslip }) => (
  <div
    style={{
      margin: '0px',
      paddingTop: '2rem',
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '12px',
    }}
  >
    <h1
      style={{
        color: '#f05825',
        fontSize: '1.8rem',
        textAlign: 'center',
        paddingBottom: '1.2rem',
        fontWeight: '400',
      }}
    >
      Awesome CX
    </h1>

    {/* First container */}
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
      }}
    >
      {/* Payslip Details */}
      <h2
        style={{
          color: '#f05825',
          fontSize: '1.2rem',
          textAlign: 'center',
          padding: '0 0 2rem 0.9rem',
          fontWeight: '400',
        }}
      >
        Payslip for {payslip.start_date} - {payslip.end_date}
      </h2>
    </div>

    {/* Second container */}
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
      }}
    >
      {/* Payslip Summary */}
      <div
        style={{
          padding: '0 0.4rem 0 0.8rem ',
          width: '50%',
          float: 'left',
        }}
      >
        {payslipSummary(payslip)}
      </div>

      {/* Taxable Income */}
      <div
        style={{
          padding: '0 0.8rem 0 0.4rem ',
          width: '50%',
          float: 'right',
          marginBottom: '3rem',
        }}
      >
        {taxableIncome(payslip)}
      </div>
    </div>

    {/* Second container */}
    <div
      style={{
        display: 'flex',
        gap: '0.5rem',
      }}
    >
      {/* Non-Taxable Pay and Allowances: */}
      <div
        style={{
          padding: '0 0.4rem 0 0.8rem ',
          width: '50%',
          float: 'left',
        }}
      >
        {noneTaxables(payslip)}
      </div>

      {/* Deductions */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 0.8rem 0 0.4rem ',
          width: '50%',
          height: '100%',
          float: 'right',
        }}
      >
        {deductions(payslip)}
        <div
          style={{
            paddingLeft: '0.9rem',
            marginTop: '5.5rem',
            textAlign: 'right',
          }}
        >
          <div style={{ clear: 'both' }} />
          <p>Subaldo, Issahmae D. </p>
          <p>Payroll Administrator</p>
        </div>
      </div>
    </div>

    <div style={{ clear: 'both' }} />
  </div>
);

export default PrintPayslip;
