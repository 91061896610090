import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import UserEmploymentPosition from '../../constants/employees/positions/employee-position.interface';
import User from '../../constants/users/user.interface';
import userPositionsActions from '../../actions/users/employments/userEmploymentPositionsActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

interface AutoCompletePosition {
  data: User[];
  currentPage: number;
}

export interface UserPositionsState {
  userPositions: UserEmploymentPosition[];
  positions: {
    [positionCode: string]: AutoCompletePosition;
  };
}

const initialState: UserPositionsState = {
  userPositions: [],
  positions: {},
};

const getUserPositionsData = (userPositions: Partial<UserEmploymentPosition>) => ({
  ...userPositions,
});

const upsertItems = (
  userPositions: UserEmploymentPosition[],
  ...data: UserEmploymentPosition[]
) => {
  const dataObject: { [id: number]: UserEmploymentPosition } = {};
  userPositions.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((userPosition) => {
    if (userPosition && !dataObject[userPosition.id]) {
      dataObject[userPosition.id] = userPosition;
    } else if (userPosition && dataObject[userPosition.id]) {
      dataObject[userPosition.id] = { ...dataObject[userPosition.id], ...userPosition };
    }
  });

  return Object.values(dataObject);
};

const userPositionsSlice = createSlice({
  name: 'userPositions',
  initialState,
  reducers: {
    resetUserPositionsState: () => ({
      ...initialState,
    }),
    setUserPositionsState: (state, action: PayloadAction<Partial<UserPositionsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertUserPositions: (state, action: PayloadAction<Partial<UserEmploymentPosition>>) => {
      const data: UserEmploymentPosition = getUserPositionsData(
        action.payload,
      ) as UserEmploymentPosition;

      if (data) return;

      state.userPositions = upsertItems(state.userPositions, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userPositionsActions.post.fulfilled, (state, { payload }) => {
      const { data, status } = payload as ApiBaseResponse<UserEmploymentPosition>;
      consoleLog('USER POSITIONS REDUCER', 'POST', data);

      if (status !== StatusCodes.CREATED) return;

      if (data) {
        state.userPositions = upsertItems(state.userPositions, data);
      }
    });
    builder.addCase(userPositionsActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('USER POSITIONS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<UserEmploymentPosition>;

      if (status === 503) return;

      if (data) {
        state.userPositions = data;
      }
    });
    builder.addCase(
      userPositionsActions.getAllUsersByEmploymentPosition.fulfilled,
      (state, { payload }) => {
        consoleLog('USER POSITIONS REDUCER', 'GET-ALL', payload);

        const { data } = payload as ApiBaseResponse<UserEmploymentPosition>;

        if (data) return;
        state.userPositions = upsertItems(state.userPositions, data);
      },
    );
    builder.addCase(userPositionsActions.get.fulfilled, (state, { payload }) => {
      consoleLog('USER POSITIONS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentPosition>;

      if (data) return;
      state.userPositions = upsertItems(state.userPositions, data);
    });

    builder.addCase(userPositionsActions.getUsers.fulfilled, (state, { payload }) => {
      consoleLog('USER POSITIONS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentPosition>;

      if (data) return;
      state.userPositions = upsertItems(state.userPositions, data);
    });

    builder.addCase(userPositionsActions.put.fulfilled, (state, { payload }) => {
      consoleLog('USER POSITIONS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentPosition>;

      if (data) return;
      state.userPositions = upsertItems(state.userPositions, data);
    });
    builder.addCase(userPositionsActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('USER POSITIONS REDUCER', 'REMOVE', payload);

      const { data, status } = payload as ApiBaseResponse<UserEmploymentPosition>;

      if (status !== StatusCodes.NO_CONTENT) return;

      state.userPositions = state.userPositions.filter(
        (userPosition) => userPosition.id !== data.id,
      );
    });
    builder.addCase(userPositionsActions.updateEmployeePosition.fulfilled, (state, { payload }) => {
      consoleLog('USER POSITIONS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentPosition>;

      if (data) return;
      state.userPositions = upsertItems(state.userPositions, data);
    });
  },
});

export const userPositionsReducerActions = userPositionsSlice.actions;
export default userPositionsSlice.reducer;
