import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import ScheduleType from '../../constants/employments/schedules/employment-schedule-type.interface';
import scheduleTypeServices, {
  GetAllScheduleTypesQueryParams,
} from '../../services/schedule/scheduleTypeServices';

const get = createAsyncThunk(
  'scheduleType/get',
  async (
    id: ScheduleType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ScheduleType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleTypeServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'scheduleType/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllScheduleTypesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<ScheduleType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleTypeServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'scheduleType/post',
  async (
    data: Omit<ScheduleType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ScheduleType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleTypeServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'scheduleType/put',
  async (
    data: AtLeast<ScheduleType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ScheduleType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleTypeServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'scheduleType/remove',
  async (
    id: ScheduleType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ScheduleType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleTypeServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
