import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog, DialogHeader, DialogBody, IconButton } from '@material-tailwind/react';
import HolidayType from '../../../store/constants/holidays/holiday-type.interface';
import useHolidayTypes from '../../../hooks/holidays/useHolidayTypes';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';
import { ReactComponent as Gift } from '../../../assets/images/icons/gift.svg';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import toastNotification from '../../../hooks/utils/toastNotification';

export interface HolidayTypeFormProps {
  holidayType?: HolidayType;
  className?: string;
  onClose: () => void;
}

const HolidayTypeFormModal: React.FC<HolidayTypeFormProps> = ({
  holidayType = undefined,
  className = undefined,
  onClose,
}) => {
  const [holidayTypeForm, setHolidayTypeForm] = useState<HolidayType | undefined>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { post, put } = useHolidayTypes();

  const handleClose = () => {
    setHolidayTypeForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setHolidayTypeForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<HolidayType>) => {
    setLoading(true);
    if (holidayTypeForm) {
      await toastNotification({
        action: put({
          ...holidayTypeForm,
          ...values,
        }),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Holiday Type is successfully updated.',
          error: 'Error on updating Holiday Type.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as HolidayType),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Holiday Type is successfully added.',
          error: 'Error on adding Holiday Type.',
        },
      });
    }

    setLoading(false);
    setOpen(false);
  };

  useEffect(() => {
    if (holidayType) {
      setOpen(true);
      setHolidayTypeForm(holidayType);
    }
  }, [holidayType]);

  // @TODO
  // 1. web console error changing uncontrolled input to controlled. undefined to defined value

  return (
    <>
      <button
        type="button"
        className={` ${className} button button--state-default gap-2`}
        onClick={handleOpen}
      >
        <Add className="w-5 h-5" />
        Add holiday type
      </button>

      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="shadow-none p-10 modal-size holiday-dialog-container"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <Close className="w-5 h-auto" />
          </IconButton>
        </div>
        <div className="flex gap-3">
          <Gift className="icons w-10 h-auto" />
          <DialogHeader className="p-0 mt-1 modal-header">Enter Holiday Type</DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <Formik initialValues={{ ...holidayTypeForm }} onSubmit={handleSubmit}>
              <Form>
                <div className="mt-4 mb-5 form-section">
                  <div className="flex flex-col justify-center w-full">
                    <div className="mb-6">
                      <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                        Holiday Type
                      </label>
                      <div className="">
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                          placeholder="Regular, Special..."
                          required
                        />
                      </div>
                      <ErrorMessage name="name" component="div" />
                    </div>

                    <div className="mb-4">
                      <label
                        htmlFor="ratePercentage"
                        className="block mb-2 text-gray-200 modal-labels"
                      >
                        Rate %
                      </label>
                      <div className="">
                        <Field
                          type="number"
                          // min={100}
                          id="ratePercentage"
                          name="ratePercentage"
                          className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                          placeholder="100"
                          required
                        />
                      </div>
                      <ErrorMessage name="rate" component="div" />
                    </div>
                  </div>
                </div>

                <div className="flex space-x-2 w-max">
                  <div className="flex justify-center w-full">
                    <ButtonAction
                      type="submit"
                      className="modal-buttons flex justify-center items-center p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                      isLoading={loading}
                      hasSpinnerText={false}
                      isSubmit
                    />
                  </div>
                  <div className="flex justify-center w-max">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default HolidayTypeFormModal;
