import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { IconButton, Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import useUserForm from '../../../hooks/users/useUserForm';
import UploadImageButton from '../../atoms/UploadImageButton';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import AtLeast from '../../../types/atleast';
import User from '../../../store/constants/users/user.interface';
import SelectTimezone from '../../atoms/dropdowns/SelectTimezone';
import Timezone from '../../../store/constants/timezones/timezone.interface';
import SelectGender from '../../atoms/dropdowns/SelectGender';
import UserGender from '../../../store/constants/users/user-gender.enum';
import { UserGenderOption } from '../../../store/reducers/users/usersReducer';
import ImageWithDefault from '../../atoms/ImageWithDefault';
import { ReactComponent as PersonIcon } from '../../../assets/images/icons/person.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/icons/phone.svg';
import { ReactComponent as BirthDayIcon } from '../../../assets/images/icons/cake.svg';

interface UserBasicInfoFormProps {
  submitButton: React.RefObject<HTMLButtonElement>;
}

const UserBasicInfoForm: React.FC<UserBasicInfoFormProps> = ({ submitButton }) => {
  const navigate = useNavigate();
  const {
    userFormState: { user },
    setUserFormState,
    isLastStep,
    handleNext,
  } = useUserForm();
  const [file, setFile] = useState<File | undefined>();
  const [formError, setFormError] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [selectedGender, setSelectedGender] = useState<UserGenderOption | undefined>(
    user.gender
      ? {
          value: user.gender,
          label: user.gender,
        }
      : undefined,
  );
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone | undefined>({
    ...user.timezone,
    value: user.timezone?.code,
    label: user.timezone?.code,
  } as Timezone);

  const handleSubmit = async (values: AtLeast<User, 'bank'>) => {
    setUserFormState({
      user: {
        ...user,
        ...values,
        timezoneCode: selectedTimezone?.code,
        timezone: selectedTimezone,
        gender: selectedGender?.value,
        profilePic: user.profilePic,
      },
    });
    setFormError('');
    handleNext();
  };

  const onUserListClick = () => {
    navigate('/admin/user/manage');
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserFormState({
      user: {
        ...user,
        profilePic: event.target.files?.[0],
      },
    });
  };

  const handlePreSubmit = () => {
    setUserFormState({ nextStep: undefined });

    if (submitButton.current) submitButton.current.click();
  };

  useEffect(() => {
    if (user.profilePic instanceof File) {
      setFile(user.profilePic);
    } else {
      setFile(undefined);
    }
  }, [user.profilePic]);

  useOnLoad(() => {
    setLoaded(true);
  });

  if (!loaded) return null;

  return (
    <div>
      {formError && <div className="form-error">{formError}</div>}
      <Formik initialValues={user} onSubmit={handleSubmit}>
        <Form className="p-5 pb-0 mt-5 mb-5 border border-solid border-[#2e2d2c] bg-[#252423] rounded-xl shadow-2xl employee-basic-info-form">
          <Typography variant="h4" className="text-[#f05825] flex gap-2">
            <span className="text-gray-200">Basic</span>
            Information
          </Typography>
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-5 mx-auto form-section">
            {/* FIRST SECTION */}
            <div className="first-section p-5">
              <div className="flex flex-col gap-2 mb-3 ">
                <label htmlFor="profilePic" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Profile Picture
                </label>
                <div className="relative place-self-center">
                  <div className="flex flex-col w-max">
                    <div>
                      <ImageWithDefault
                        file={file}
                        id="profile-pic"
                        className=" w-36 h-36 border-2 rounded-[100%]"
                        defaultImageUrl="/assets/images/profile/default.png"
                        alt={`${user?.firstName?.toUpperCase()} ${user?.lastName?.toUpperCase()} Profile Image`}
                      />
                    </div>
                    <div className="flex justify-end">
                      <IconButton className="icon-button flex items-center justify-center w-8 h-8 overflow-hidden rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                          />
                        </svg>

                        <UploadImageButton
                          className="round-image-button"
                          handleImageChange={handleImageChange}
                        />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="firstName"
                  className="block mt-2 mr-8 text-xs uppercase text-gray-600"
                >
                  First Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter First Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    required
                    autoComplete="off"
                  />
                  <ErrorMessage name="firstName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="middleName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Middle Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="middleName"
                    name="middleName"
                    placeholder="Enter Middle Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                  <ErrorMessage name="middleName" component="div" />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="lastName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Last Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter Last Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    required
                    autoComplete="off"
                  />
                  <ErrorMessage name="lastName" component="div" />
                </div>
              </div>
            </div>
            {/* END FIRST SECTION */}

            {/* SECOND SECTION */}
            <div className="second-section p-5">
              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="suffixName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Suffix
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="suffixName"
                    name="suffixName"
                    placeholder="Enter Suffix Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="suffixName" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="nickName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Nickname
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="nickName"
                    name="nickName"
                    placeholder="Enter Nickname"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="nickName" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="contactNumber"
                  className="mt-2 mr-8 text-xs uppercase text-gray-600"
                >
                  Contact Number
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PhoneIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="contactNumber"
                    name="contactNumber"
                    placeholder="Enter Contact Number"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="Contact Number" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="emailPersonal"
                  className="mt-2 mr-8 text-xs uppercase text-gray-600"
                >
                  Personal Email
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>
                  <Field
                    type="email"
                    id="emailPersonal"
                    name="emailPersonal"
                    placeholder="Enter Personal Email"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    required
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="emailPersonal" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="gender" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                  Gender
                </label>
                <div className="select-form-field">
                  <SelectGender
                    id="gender"
                    name="gender"
                    value={selectedGender}
                    onChange={(value) => setSelectedGender(value as UserGenderOption)}
                  />
                </div>
                <ErrorMessage name="gender" component="div" />
              </div>

              {selectedGender && selectedGender.value === UserGender.FEMALE && (
                <div className="flex flex-col gap-2 mb-5">
                  <label htmlFor="maidenName" className="mt-2 mr-8 text-xs uppercase text-gray-600">
                    Maiden Name
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <PersonIcon className="w-5 h-5 text-[#252423]" />
                    </div>
                    <Field
                      type="text"
                      id="maidenName"
                      name="maidenName"
                      placeholder="Maiden Name"
                      className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                      autoComplete="off"
                    />
                  </div>
                  <ErrorMessage name="maidenName" component="div" />
                </div>
              )}
            </div>
            {/* END SECOND SECTION */}

            {/* THIRD SECTION */}
            <div className="third-section p-5">
              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="dateBirth" className="mr-8 mt-2 text-xs uppercase text-gray-600">
                  Birth Date
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <BirthDayIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="date"
                    id="dateBirth"
                    name="dateBirth"
                    placeholder="Birth Date"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                  />
                </div>
                <ErrorMessage name="dateBirth" component="div" />
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="timezone" className="mr-8 mt-2 text-xs uppercase text-gray-600">
                  Timezone
                </label>
                <div className="select-field-form">
                  <SelectTimezone
                    id="timezone"
                    name="timezone"
                    value={selectedTimezone}
                    onChange={(value) => setSelectedTimezone(value as Timezone)}
                  />
                </div>
                <ErrorMessage name="timezone" component="div" />
              </div>

              {/* END THIRD SECTION */}
            </div>
          </div>

          <div className="flex justify-between w-full p-2">
            <button
              type="button"
              onClick={onUserListClick}
              className="textButton flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11 17l-5-5m0 0l5-5m-5 5h12"
                />
              </svg>
              <Typography className="ml-2 font-bold">Back to user list</Typography>
            </button>
            <button
              onClick={handlePreSubmit}
              type="button"
              disabled={isLastStep}
              className="muiButton--primary rounded-lg shadow-lg hover:bg-[#bb451d]"
            >
              Next
            </button>
          </div>
          <button ref={submitButton} type="submit" className="invisible">
            Next
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default UserBasicInfoForm;
