import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import TransactionFeed from '../../constants/my/transaction-feed.interface';
import TransactionFeedActions from '../../actions/my/transactionFeedActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';

export interface TransactionFeedsState {
  transactionFeeds: TransactionFeed[];
}

const initialState: TransactionFeedsState = {
  transactionFeeds: [
    {
      id: 1,
      name: 'Osnet Payroll',
      description:
        'We would like to inform you that an amount of 10,000.00 Php has been credited to your account as 1st Half of 13th Month Pay.',
      dateRead: new Date('2023-11-24T18:44:42'),
      dateShared: new Date('2023-11-26'),
      isRead: false,
    },
    {
      id: 2,
      name: 'Casting a spell',
      description:
        'Luctus lacinia morbi ac varius vel felis curae erat diam imperdiet, cum magna nunc fusce malesuada convallis vehicula aenean suspendisse.',
      dateRead: new Date('2023-04-26T12:34:23'),
      dateShared: new Date('2023-05-09'),
      isRead: false,
    },
    {
      id: 3,
      name: 'Betrayal',
      description:
        'Id rather love a million times and have my heart broken every time, than to hold a permanently empty heart forever.',
      dateRead: new Date('2079-12-30T01:00:00'),
      dateShared: new Date('2080-01-01'),
      isRead: false,
    },
    {
      id: 4,
      name: 'The Ghost Uchiha',
      description:
        'Wake up to reality! Nothing ever goes as planned in this accursed world. The longer you live, the more you realize that the only things that truly exist in this reality are merely pain.',
      dateRead: new Date('2007-02-15T10:12:22'),
      dateShared: new Date('2007-03-09'),
      isRead: false,
    },
    {
      id: 5,
      name: 'Meizen',
      description:
        'We would like to inform you that an amount of 200,000.00 Php has been credited to your account as 1st Half of 13th Month Pay, enjoy and goodluck.',
      dateRead: new Date('2026-02-15T10:12:22'),
      dateShared: new Date('2026-03-09'),
      isRead: true,
    },
    {
      id: 6,
      name: 'Mitsu',
      description: 'Eros interdum vivamus cursus duis faucibus taciti, viverra ultricies.',
      dateRead: new Date('2023-05-23'),
      dateShared: new Date('2023-05-09'),
      isRead: true,
    },
    {
      id: 7,
      name: 'Saizu',
      description:
        'Dignissim ligula nibh tempus rutrum cras molestie platea dictumst pellentesque ornare senectus, convallis ridiculus accumsan nascetur praesent diam aenean ac mattis sodales.',
      dateRead: new Date('2023-04-26'),
      dateShared: new Date('2023-05-10'),
      isRead: true,
    },
    {
      id: 8,
      name: 'Annoymous Person',
      description: 'There is an ocean of silence between us… and I am drowning in it.',
      dateRead: new Date('2079-12-30'),
      dateShared: new Date('2099-05-05'),
      isRead: true,
    },
  ],
};

const getTransactionFeedData = (transactionFeed: Partial<TransactionFeed>) => ({
  ...transactionFeed,
});

const transactionFeedsSlice = createSlice({
  name: 'transactionFeeds',
  initialState,
  reducers: {
    resetTransactionFeedsState: () => ({
      ...initialState,
    }),
    setTransactionFeedsState: (state, action: PayloadAction<Partial<TransactionFeedsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertTransactionFeed: (state, action: PayloadAction<Partial<TransactionFeed>>) => {
      const data: TransactionFeed = getTransactionFeedData(action.payload) as TransactionFeed;

      if (typeof data.id === 'undefined') return;

      state.transactionFeeds = upsertData(state.transactionFeeds, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(TransactionFeedActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<TransactionFeed>;
      consoleLog('TRANSACTION_FEED REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.transactionFeeds = upsertData(state.transactionFeeds, data);
      }
    });
    builder.addCase(TransactionFeedActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('TRANSACTION_FEED REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<TransactionFeed>;

      if (status === 503) return;

      if (data) {
        state.transactionFeeds = data;
      }
    });
    builder.addCase(TransactionFeedActions.get.fulfilled, (state, { payload }) => {
      consoleLog('TRANSACTION_FEED REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<TransactionFeed>;

      if (typeof data.id === 'undefined') return;
      state.transactionFeeds = upsertData(state.transactionFeeds, data);
    });
    builder.addCase(TransactionFeedActions.put.fulfilled, (state, { payload }) => {
      consoleLog('TRANSACTION_FEED REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<TransactionFeed>;

      if (typeof data.id === 'undefined') return;
      state.transactionFeeds = upsertData(state.transactionFeeds, data);
    });
    builder.addCase(TransactionFeedActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('TRANSACTION_FEED REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<TransactionFeed>;

      if (typeof data.id === 'undefined') return;
      state.transactionFeeds = state.transactionFeeds.filter(
        (transactionFeed) => transactionFeed.id !== data.id,
      );
    });
    builder.addCase(TransactionFeedActions.isRead.fulfilled, (state, { payload }) => {
      consoleLog('TRANSACTION_FEED REDUCER', 'IS_READ', payload);

      const { data } = payload as ApiBaseResponse<TransactionFeed>;

      if (typeof data.id === 'undefined') return;
      state.transactionFeeds = state.transactionFeeds.filter(
        (transactionFeed) => transactionFeed.id !== data.id,
      );
    });
  },
});

export const transactionFeedsReducerActions = transactionFeedsSlice.actions;
export default transactionFeedsSlice.reducer;
