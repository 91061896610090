import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogBody, IconButton } from '@material-tailwind/react';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import useCalculateTimeDuration from '../../../../hooks/utils/useCalculateTimeDuration';
import useOvertimes from '../../../../hooks/attendances/useOvertimes';
import toastNotification from '../../../../hooks/utils/toastNotification';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as Edit } from '../../../../assets/images/icons/edit.svg';

export interface OvertimeApproveFormModalProps {
  overtime: EmployeeOvertime;
  onClose: () => void;
}

export interface OvertimeEditFormType {
  timeIn: string;
  timeOut: string;
  date: Date | string;
  duration: string;
}

export interface ExtendedEmployeeOvertime extends EmployeeOvertime, OvertimeEditFormType {}

const OvertimeEditForm: React.FC<OvertimeApproveFormModalProps> = ({ overtime, onClose }) => {
  const { put } = useOvertimes();
  const { duration, setDuration, calculateDuration } = useCalculateTimeDuration();
  const { getEmployeeByEmployeeNumber } = useUserEmployments();
  const employee = getEmployeeByEmployeeNumber(overtime?.employeeNumber);

  const maxDate = moment().format('YYYY-MM-DD');
  const overtimeTimeInDate = moment(overtime?.overtimeTimeIn as Date).format('YYYY-MM-DD');

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleFormEditSubmit = async (values: Partial<ExtendedEmployeeOvertime>) => {
    setLoading(true);

    const { timeIn, timeOut, date, id } = values;
    const overtimeTimeIn = moment(`${date} ${timeIn}`, 'YYYY-MM-DD HH:mm').format(
      'YYYY-MM-DD HH:mm:ss',
    );
    let overtimeTimeOut = moment(`${date} ${timeOut}`, 'YYYY-MM-DD HH:mm').format(
      'YYYY-MM-DD HH:mm:ss',
    );

    if (moment(overtimeTimeOut).isBefore(overtimeTimeIn)) {
      overtimeTimeOut = moment(date).add(1, 'day').format('YYYY-MM-DD');
      overtimeTimeOut = moment(`${overtimeTimeOut} ${timeOut}`, 'YYYY-MM-DD HH:mm').format(
        'YYYY-MM-DD HH:mm:ss',
      );
    }

    const updatedValues = {
      id,
      timeIn: overtimeTimeIn,
      timeOut: overtimeTimeOut,
      duration,
    };

    await toastNotification({
      action: put(updatedValues),
      onSuccess: () => {
        setLoading(false);
        setOpen(false);
        onClose();
      },
      onError: () => {},
      onFulfilled: async () => {
        setLoading(false);
        setOpen(false);
        onClose();
      },
      toastPromiseMessages: {
        pending: 'Processing Employee Overtime.',
        success: 'Employee Overtime is successfully updated.',
        error: 'An error has occurred, while updating Employees Overtime.',
      },
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    onClose();
  };

  useEffect(() => {
    if (overtime) {
      setOpen(true);
      setDuration(overtime.duration || '');
    }
  }, [overtime, setDuration]);

  return (
    <Dialog
      size="sm"
      open={open}
      handler={handleOpen}
      className="shadow-none overtime-dialog__container overtime-content__edit-form"
    >
      <div className="absolute top-0 right-0 flex p-0 m-2">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex items-center gap-2 pb-2 overtime-content__wrapper">
        <Edit className="w-5 h-5 overtime-content__icon" />
        <h1 className="overtime-content__heading">Edit Overtime ?</h1>
      </div>
      <DialogBody divider className="p-0">
        <Formik
          initialValues={{
            id: overtime?.id,
            timeIn: overtime?.overtimeTimeIn ? moment(overtime.overtimeTimeIn).format('HH:mm') : '',
            timeOut: overtime?.overtimeTimeOut
              ? moment(overtime.overtimeTimeOut).format('HH:mm')
              : '',
            date: overtime?.dateCreated ? overtimeTimeInDate : '',
            duration: overtime?.duration || '',
          }}
          onSubmit={handleFormEditSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="w-full">
              <div className="flex justify-center gap-3 w-full my-5">
                <div className="overtime-first-container">
                  {/* Employee Name */}
                  <div className="mb-3">
                    <h2 className="block mb-2 font-semibold overtime-content__title">
                      Employee Name
                    </h2>
                    <p className="flex items-center w-full p-2 rounded-md shadow-lg overtime-content__field disabled-field">
                      {employee?.userInformation.firstName}, {employee?.userInformation.lastName}
                    </p>
                  </div>
                  {/* Time-in */}
                  <div className="mb-3">
                    <label
                      htmlFor="timeIn"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      TIME IN
                    </label>
                    <div className="flex gap-2 justify-center items-center">
                      <Field
                        type="time"
                        id="timeIn"
                        name="timeIn"
                        placeholder="Time in"
                        className="w-full p-2 rounded-md shadow-lg overtime-content__field"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('timeIn', e.target.value);
                          if (e.target.value && values.timeOut) {
                            calculateDuration(e.target.value, values.timeOut);
                          }
                        }}
                      />
                      <ErrorMessage name="timeIn" component="div" />
                    </div>
                  </div>
                  {/* Duration */}
                  <div className="mb-3">
                    <h2 className="block mb-2 font-semibold overtime-content__title">Duration</h2>
                    <p className="w-full p-2 rounded-md shadow-lg overtime-content__field disabled-field">
                      <span className="pl-1">{duration}</span>
                    </p>
                  </div>
                </div>
                {/* second container */}
                <div className="overtime-second-container">
                  {/* Overtime Date  */}
                  <div className="mb-3">
                    <label
                      htmlFor="date"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      Date
                    </label>
                    <Field
                      type="date"
                      id="date"
                      name="date"
                      max={maxDate}
                      placeholder="Date Start"
                      className="w-full p-2 rounded-md shadow-lg overtime-content__field"
                    />
                    <ErrorMessage name="date" component="div" />
                  </div>
                  {/* Time-Out */}
                  <div className="mb-3">
                    <label
                      htmlFor="timeOut"
                      className="block mb-2 font-semibold overtime-content__title"
                    >
                      TIME OUT
                    </label>
                    <div className="flex gap-2 justify-center items-center">
                      <Field
                        type="time"
                        id="timeOut"
                        name="timeOut"
                        placeholder="Time out"
                        className="w-full p-2 rounded-md shadow-lg overtime-content__field"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('timeOut', e.target.value);
                          if (e.target.value && values.timeIn) {
                            calculateDuration(values.timeIn, e.target.value);
                          }
                        }}
                      />
                      <ErrorMessage name="timeOut" component="div" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between w-full px-1 pb-5 overtime-content__button-wrapper">
                <ButtonAction
                  type="submit"
                  className="shadow-lg overtime-content__button"
                  isLoading={loading}
                  hasSpinnerText={false}
                  isSubmit
                />
                <button
                  type="button"
                  onClick={handleClose}
                  className="p-2  rounded-lg shadow-lg overtime-content__button-close overtime--close "
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export default OvertimeEditForm;
