import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  List,
  ListItem,
  ListItemPrefix,
} from '@material-tailwind/react';
import { ReactComponent as DownArrow } from '../../assets/images/icons/chevron-down.svg';
import { ReactComponent as ServerStack } from '../../assets/images/icons/server-stack.svg';

export interface Option {
  name?: string;
  linkTo: string;
  icon: React.ReactNode;
  subOptions?: Option[];
}

export interface AccordionMenuContentProps {
  menuTitle: string;
  options: Option[];
  accordionIcon?: React.ReactNode;
  openNumber: number;
  open: number;
  handleOpen: (value: number) => void;
}

interface IconProps {
  id: number; // Change the type to match the actual type of `id`
  open: number; // Change the type to match the actual type of `open`
}

const Icon: React.FC<IconProps> = ({ id, open }) => (
  <DownArrow
    className={`mx-auto sidebar--accordion-icon transition-transform ${
      id === open ? 'rotate-180' : ''
    }`}
  />
);

const AccordionMenu: React.FC<AccordionMenuContentProps> = ({
  menuTitle,
  options,
  openNumber,
  open,
  handleOpen,
  accordionIcon = undefined,
}) => {
  const location = useLocation();
  const pathArray = location.pathname.split('/');

  const [alwaysOpen, setAlwaysOpen] = React.useState<boolean[]>(Array(options.length).fill(false));

  const handleAlwaysOpen = (index: number) => {
    setAlwaysOpen((prevOpen) => {
      const newStates = [...prevOpen];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  useEffect(() => {
    if (open !== openNumber) {
      setAlwaysOpen(Array(options.length).fill(false));
    }
  }, [open, openNumber, options.length]);

  return (
    <Accordion
      open={open === openNumber || alwaysOpen[openNumber] || false}
      icon={<Icon id={openNumber} open={open} />}
      className="sidebar sidebar__borders"
    >
      {/* Accordion Header */}
      <ListItem className="p-0">
        <AccordionHeader
          onClick={() => handleOpen(openNumber)}
          className={`sidebar__accordion-header ${
            open === openNumber ? 'sidebar--active-header' : ''
          }`}
        >
          <ListItemPrefix>{accordionIcon}</ListItemPrefix>
          <span className="sidebar__accordion-text mr-auto font-normal py-[.15rem]">
            {menuTitle}
          </span>
        </AccordionHeader>
      </ListItem>
      {/* Accordion Body || Link Menu-Content */}
      {/* <AccordionBody className="py-1 rounded-lg mt-2 bg-[#434240]"> */}
      <AccordionBody className="py-1 bg-[#242426] rounded-lg mt-2">
        <List className="p-0">
          {options.map((option, index) =>
            option.subOptions ? (
              <Accordion
                key={option.name}
                onClick={() => handleAlwaysOpen(index)}
                open={alwaysOpen[index]}
                icon={
                  <div className="p-1">
                    <DownArrow
                      onClick={() => handleAlwaysOpen(index)}
                      className={`transition-transform sidebar--accordion-icon ${
                        alwaysOpen[index] ? 'rotate-180' : ''
                      }`}
                    />
                  </div>
                }
                className="z-10 sidebar-borders"
              >
                <ListItem className="p-0">
                  <AccordionHeader
                    className={`sidebar__accordion-header ${
                      option.name && pathArray.includes(option.name.toLocaleLowerCase())
                        ? 'sidebar--active-header'
                        : ''
                    }`}
                  >
                    <Link
                      to={option.linkTo}
                      className="w-full flex gap-2 font-normal py-[.15rem] sidebar__accordion-text"
                    >
                      <ListItemPrefix>
                        <ServerStack className="sidebar--accordion-icon" />
                      </ListItemPrefix>
                      <span>{option.name}</span>
                    </Link>
                  </AccordionHeader>
                </ListItem>
                <AccordionBody className="py-1 bg-[#434240] rounded-lg mt-2">
                  <List className="p-0 ">
                    {option.subOptions.map((subOption) => (
                      <Link
                        key={subOption.name}
                        className="w-full ml-4"
                        to={subOption.linkTo}
                        onClick={() => {
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}
                      >
                        <ListItem
                          className={`transition-all duration-200 sidebar__accordion-header
                            ${
                              subOption.name &&
                              pathArray.includes(subOption.name.toLocaleLowerCase())
                                ? 'sidebar--active-header'
                                : ''
                            }`}
                        >
                          <div className="flex items-center gap-2 p-0">
                            <ListItemPrefix className="sidebar--accordion-icon">
                              {subOption.icon}
                            </ListItemPrefix>

                            <span className="sidebar__accordion-text">{subOption.name}</span>
                          </div>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </AccordionBody>
              </Accordion>
            ) : (
              <Link
                key={option.name}
                className="w-full"
                to={option.linkTo}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <ListItem
                  className={`transition-all duration-300 sidebar__accordion-header 
                    ${
                      location.pathname.toLowerCase().startsWith(option.linkTo.toLowerCase())
                        ? 'sidebar--active-header transition-all duration-300 active:bg-[#f05825]/50 focus:bg-[#f05825]/50'
                        : ''
                    }`}
                >
                  <div className="flex items-center gap-2 p-0">
                    <ListItemPrefix className="sidebar--accordion-icon">
                      {option.icon}
                    </ListItemPrefix>

                    <span className="sidebar__accordion-text">{option.name}</span>
                  </div>
                </ListItem>
              </Link>
            ),
          )}
        </List>
      </AccordionBody>
    </Accordion>
  );
};

export default AccordionMenu;
