import Payroll from '../../store/constants/payroll/payroll.interface';
import {
  PayrollsState,
  payrollsReducerActions,
} from '../../store/reducers/payroll/payrollsReducer';
import payrollActions from '../../store/actions/payroll/payrollActions';
import { GetAllPayrollQueryParams } from '../../store/services/payroll/payrollServices';
import { useAppDispatch, useAppSelector } from '../../store/store';
import Company from '../../store/constants/companies/company.interface';

const usePayrolls = () => {
  const dispatch = useAppDispatch();
  const payrollsState: PayrollsState = useAppSelector((state) => state.payroll);

  const resetPayrollState = () => {
    dispatch(payrollsReducerActions.resetPayrollsState());
  };

  const setPayrollState = (state: Partial<PayrollsState>) => {
    dispatch(payrollsReducerActions.setPayrollsState(state));
  };

  const get = async (id: Payroll['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getAll({}));

  const getCompanyITR = async (id: Company['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getCompanyITR(id));

  const getAllLatesUndertime = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getAllLatesUndertime({}));

  const getLastpays = async (params = {} as GetAllPayrollQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getLastpays(params));

  const getPayPeriods = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getPayPeriods({}));

  const getEmployeePayrolls = async (params = {} as GetAllPayrollQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getEmployeePayrolls(params));

  const getEmployeeGross = async (
    employeeGrossParams = {} as {
      scheduleTypeId: number | undefined;
      monthlyGross: number;
      basicGross: number;
    },
  ) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getEmployeeGross(employeeGrossParams));

  const getPayslips = async (params = {} as GetAllPayrollQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.getPayslips(params));

  const post = async (data: Omit<Payroll, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.post(data));

  const createPayslip = async (data: GetAllPayrollQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.createPayslips(data));

  const addIncentives = async (data: GetAllPayrollQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.addIncentives(data));

  const generateThirteenMonth = async (payPeriod = {} as GetAllPayrollQueryParams['payPeriod']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.generateThirteenMonth(payPeriod));

  const put = async (payroll: Partial<Payroll>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.put(payroll));

  const updateTeamPHICBasic = async (payroll: Partial<Payroll>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.put(payroll));

  const updateTeamPHICGross = async (payroll: Partial<Payroll>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.put(payroll));

  const remove = async (id: Payroll['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(payrollActions.remove(id));

  return {
    get,
    put,
    post,
    getAll,
    remove,
    getPayPeriods,
    getEmployeePayrolls,
    getPayslips,
    getLastpays,
    payrollsState,
    addIncentives,
    createPayslip,
    getCompanyITR,
    setPayrollState,
    resetPayrollState,
    updateTeamPHICGross,
    updateTeamPHICBasic,
    getAllLatesUndertime,
    generateThirteenMonth,
    getEmployeeGross,
  };
};

export default usePayrolls;
