import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Dialog, DialogBody, IconButton } from '@material-tailwind/react';
import { toast } from 'react-toastify';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import consoleLog from '../../../../utils/consoleLog';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as Info } from '../../../../assets/images/icons/info.svg';

export interface OvertimeEndEmployeeOvertimeProps {
  overtime: EmployeeOvertime;
  onClose: () => void;
}

const OvertimeEndEmployeeOvertime: React.FC<OvertimeEndEmployeeOvertimeProps> = ({
  overtime = undefined,
  onClose,
}) => {
  const [endOverTimeData, setEndOverTimeData] = useState<EmployeeOvertime | undefined>();
  const [open, setOpen] = React.useState(false);
  const currentTime = moment();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const calculateOvertimeTimeout = (overtimeValue: EmployeeOvertime): string | undefined => {
    if (!overtimeValue) return undefined;
    const { overtimeTimeIn, duration } = overtimeValue;
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    const overtimeDate = moment(overtimeTimeIn)
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .add(seconds, 'seconds');
    return overtimeDate.format('YYYY-MM-DD HH:mm:ss');
  };

  const handleEndEmployeeOvertime = () => {
    const calculatedOvertimeTimeout = calculateOvertimeTimeout(overtime as EmployeeOvertime);
    const overtimeTimeIn = moment(overtime?.overtimeTimeIn);
    const overtimeDuration = moment.duration(overtime?.duration);
    const overtimeEndTime = overtimeTimeIn.clone().add(overtimeDuration);

    const endOvertimeForm = {
      ...endOverTimeData,
      overtimeTimeOut: calculatedOvertimeTimeout,
    };

    consoleLog('overtime-out', endOvertimeForm);

    if (currentTime.isBefore(overtimeEndTime)) {
      toast.error('The Employees Overtime is still in progress.');
      setOpen(false);
      onClose();
      return;
    }

    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if (overtime) {
      setOpen(true);
      setEndOverTimeData(overtime);
    }
  }, [overtime]);

  return (
    <Dialog
      size="sm"
      open={open}
      handler={handleOpen}
      className="shadow-none overtime-dialog__container"
    >
      <div className="absolute top-0 right-0 flex p-0 m-2">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex items-center gap-2 pb-4 overtime-manage-wrapper">
        <Info className="w-4 h-4" />
        <h1>End Overtime ?</h1>
      </div>
      <DialogBody divider className="p-0">
        <Formik
          initialValues={{
            ...overtime,
          }}
          onSubmit={() => {}}
        >
          <Form className="w-full">
            <div className="my-5">
              <div className="flex justify-between px-2">
                <button
                  className="button__end-overtime"
                  type="button"
                  onClick={handleEndEmployeeOvertime}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleClose}
                  className="button__close-end-overtime overtime--close"
                >
                  Close
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export default OvertimeEndEmployeeOvertime;
