import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
// import SelectMonthRange from '../../../../../atoms/dropdowns/SelectMonthRange';
import ThirteenthMonthSemiAnnualTable from './ThirteenthMonthSemiAnnualTable';
// import consoleLog from '../../../../../../utils/consoleLog';

const ThirteenMonthSemiAnnualToolOptions: React.FC = () => {
  const [open, setOpen] = useState(true);
  // @todo return to this when this feature is implemented.
  // const [selectedMonth, setSelectedMonth] = useState<string>();

  // const handleMonthRangeChange = (periodRange: string) => {
  //   setSelectedMonth(periodRange);
  //   consoleLog('selected', selectedMonth);
  // };

  return (
    <div>
      <div className="w-full">
        <Accordion
          open={open}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="#f05825"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
              />
            </svg>
          }
        >
          <div className="w-full">
            <div className="p-2 hover:bg-[#343434] rounded-none border-2 border-solid border-t-[#2c2b2a] border-b-[#2c2b2a] border-r-transparent border-l-transparent">
              <AccordionHeader
                onClick={() => setOpen(!open)}
                className="border-b-0 p-0 bg-[rgb(44, 43, 42) focus:bg-[rgb(44, 43, 42)"
              >
                <span className="mr-auto text-xs font-normal text-white">Options</span>
              </AccordionHeader>
            </div>
            <div className="contain-tools">
              <div className="divider-content">
                <AccordionBody className="w-full px-2 py-2 ">
                  <div className="w-full flex gap-5 py-1 ">
                    <div className="flex items-center">
                      <div className="pr-2 text-white text-[.7rem]">Period:</div>
                      {/* <SelectMonthRange open={open} onSelect={handleMonthRangeChange} /> */}
                    </div>

                    <div className="flex  items-center ml-5 gap-3">
                      <button
                        type="button"
                        className="p-1 px-2 hover:bg-[#f05825] transition-all duration-300 ease-in-out group rounded-md border border-solid border-[#f05825]"
                      >
                        <span className="flex items-center gap-1 justify-center place-self-center text-xs text-[#f2f2f2]">
                          AOS
                        </span>
                      </button>
                      <button
                        type="button"
                        className="p-1 px-2 hover:bg-[#f05825] transition-all duration-300 ease-in-out group rounded-md border border-solid border-[#f05825]"
                      >
                        <span className="flex items-center gap-1 justify-center place-self-center text-xs text-[#f2f2f2]">
                          OS NONTS
                        </span>
                      </button>
                      <button
                        type="button"
                        className="p-1 px-2 hover:bg-[#f05825] transition-all duration-300 ease-in-out group rounded-md border border-solid border-[#f05825]"
                      >
                        <span className="flex items-center gap-1 justify-center place-self-center text-xs text-[#f2f2f2]">
                          OS TS
                        </span>
                      </button>
                    </div>
                  </div>
                </AccordionBody>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
      <ThirteenthMonthSemiAnnualTable />
    </div>
  );
};

export default ThirteenMonthSemiAnnualToolOptions;
