import { useAppDispatch, useAppSelector } from '../../store/store';
import clientActions from '../../store/actions/clients/clientActions';
import { clientsReducerActions, ClientsState } from '../../store/reducers/clients/clientsReducer';
import Client from '../../store/constants/clients/client.interface';
import { GetAllClientsQueryParams } from '../../store/services/clients/clientServices';

const useClients = () => {
  const dispatch = useAppDispatch();
  const clientsState: ClientsState = useAppSelector((state) => state.clients);

  const resetClientState = () => {
    dispatch(clientsReducerActions.resetClientsState());
  };

  const setClientState = (state: Partial<ClientsState>) => {
    dispatch(clientsReducerActions.setClientsState(state));
  };

  const get = async (clientId: Client['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.get(clientId));

  const getAll = async (data = {} as GetAllClientsQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.getAll(data));

  const post = async (clientData: Omit<Client, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.post(clientData));

  const put = async (client: Partial<Client>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.put(client));

  const remove = async (id: Client['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.remove(id));

  const getAllClientEmployees = async (clientId: Client['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientActions.getAllClientEmployees(clientId));

  return {
    resetClientState,
    clientsState,
    setClientState,
    get,
    getAll,
    getAllClientEmployees,
    post,
    put,
    remove,
  };
};

export default useClients;
