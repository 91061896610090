import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useSchedules from '../../../hooks/schedule/useSchedules';
import useScheduleTypes from '../../../hooks/schedule/useScheduleTypes';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectEmploymentScheduleType from '../../atoms/dropdowns/SelectEmploymentScheduleType';
import SelectDays from '../../atoms/dropdowns/SelectDays';
import SelectTemporary from '../../atoms/dropdowns/SelectTemporary';
import EmployeeSchedule from '../../../store/constants/employees/schedules/employee-schedule.interface';
import {
  ScheduleDaysOption,
  ScheduleTempOption,
} from '../../../store/reducers/schedules/schedulesReducer';
import toastNotification from '../../../hooks/utils/toastNotification';
import ButtonAction from '../../atoms/buttons/ButtonAction';
// import EmploymentScheduleType from '../../../store/constants/employments/schedules/employment-schedule-type.interface';

export interface SchedulerFormProps {
  data?: EmployeeSchedule;
  onSubmit?: () => void;
}

const SchedulerAddForm: React.FC<SchedulerFormProps> = ({
  data = undefined,
  onSubmit = () => {},
}) => {
  const { getAll: getAllScheduleTypes } = useScheduleTypes();
  const { post, schedulesState } = useSchedules();

  const [userEmploymentScheduleForm, setUserEmploymentScheduleForm] = useState<
    EmployeeSchedule | undefined
  >();
  const [selectedEmploymentScheduleType, setSelectedEmploymentScheduleType] = useState<
    EmployeeSchedule | undefined
  >();

  const [selectedDays, setSelectedDays] = useState<ScheduleDaysOption | undefined>();
  const [selectedTemp, setSelectedTemp] = useState<ScheduleTempOption | undefined>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: Partial<EmployeeSchedule>) => {
    setLoading(true);
    if (userEmploymentScheduleForm) {
      await toastNotification({
        action: post(values as EmployeeSchedule),
        onSuccess: () => {
          setLoading(false);
        },
        onError: () => {},
        onFulfilled: () => {
          setLoading(false);
          onSubmit();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Schedule information is successfully added.',
          error: 'Error on adding Schedule information.',
        },
      });
    }
    onSubmit();
  };

  const handleClose = () => {
    onSubmit();
  };

  useEffect(() => {
    if (data) {
      setUserEmploymentScheduleForm({
        ...data,
      });
    }
  }, [data]);

  useOnLoad(() => {
    getAllScheduleTypes().then();
  });

  return (
    <div>
      <Formik
        initialValues={{
          ...userEmploymentScheduleForm,
          employeeNumber: schedulesState.currentEmployeeNumber,
          scheduleType: userEmploymentScheduleForm?.scheduleType,
          temp: userEmploymentScheduleForm?.temp,
        }}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <div className="flex justify-between w-full gap-5 p-2">
              <div className="flex flex-col items-center sched-type-field">
                <Typography className="schedule-popover__heading">Schedule type:</Typography>
                <SelectEmploymentScheduleType
                  id="scheduleType"
                  name="scheduleType"
                  className="w-full text-xs placeholder:text-xs"
                  menuPlacement="auto"
                  value={selectedEmploymentScheduleType?.scheduleType}
                  onChange={(value) => setSelectedEmploymentScheduleType(value as EmployeeSchedule)}
                  isClearable
                />
              </div>
              <div className="flex flex-col items-center days-field">
                <Typography className="schedule-popover__heading">Days:</Typography>
                <SelectDays
                  id="days"
                  name="days"
                  value={selectedDays}
                  className="w-full text-xs placeholder:text-xs"
                  onChange={(value) => setSelectedDays(value as ScheduleDaysOption)}
                  isMulti
                  menuPlacement="auto"
                />
                <ErrorMessage name="days" component="div" />
              </div>
              <div className="flex flex-col items-center time-field">
                <Typography className="schedule-popover__heading">Time:</Typography>
                <div className="flex h-full gap-3">
                  <Field
                    type="time"
                    id="timeIn"
                    name="timeIn"
                    className="w-full rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                    required
                  />
                  <Field
                    type="time"
                    id="timeOut"
                    name="timeOut"
                    className="w-full rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                    required
                  />
                </div>
                <ErrorMessage name="timeIn" component="div" />
                <ErrorMessage name="timeOut" component="div" />
              </div>
              <div className="flex flex-col items-center break-field">
                <Typography className="schedule-popover__heading">Break:</Typography>
                <Field
                  type="number"
                  id="break"
                  name="break"
                  className="w-full h-full rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                  required
                />
                <ErrorMessage name="break" component="div" />
              </div>

              <div className="flex flex-col items-center temp-field">
                <Typography className="schedule-popover__heading">Temporary:</Typography>
                <SelectTemporary
                  id="temporary"
                  name="temporary"
                  className="w-full text-xs placeholder:text-xs"
                  menuPlacement="auto"
                  value={selectedTemp}
                  onChange={(value) => setSelectedTemp(value as ScheduleTempOption)}
                  isClearable
                />
                <ErrorMessage name="temporary" component="div" />
              </div>
              <div className="flex flex-col items-center effective-field">
                <Typography className="schedule-popover__heading">Date Start:</Typography>
                <Field
                  type="date"
                  id="dateStart"
                  name="dateStart"
                  value={formik.values.dateStart}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full h-full rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                  required
                />
                <ErrorMessage name="dateStart" component="div" />
              </div>

              <div className="flex flex-col items-center expiry-field">
                <Typography className="schedule-popover__heading">Date End:</Typography>
                <Field
                  type="date"
                  id="dateEnd"
                  name="dateEnd"
                  className="w-full h-full rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                />
                <ErrorMessage name="dateEnd" component="div" />
              </div>
              <div className="flex items-center gap-3 form-tools">
                <Tooltip content="Save" className="bg-[#343434]">
                  <div className="flex justify-center w-max">
                    <ButtonAction
                      type="submit"
                      className=" p-2 rounded-md shadow-lg button-submit-scheduler"
                      isLoading={loading}
                      hasSpinnerText={false}
                      isSubmit
                    />
                  </div>
                </Tooltip>
                <Tooltip content="Delete" className="bg-[#343434]">
                  <button type="button" className="p-2 textButton" onClick={handleClose}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </Tooltip>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SchedulerAddForm;
