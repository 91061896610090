import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import UserEmploymentSchedules from '../../constants/employees/schedules/employee-schedule.interface';
import scheduleActions from '../../actions/users/employments/userEmploymentScheduleActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import Temp from '../../constants/utils/affirmation.enum';
import Days from '../../constants/utils/days.interface';

export interface ScheduleTempOption {
  value: Temp;
  label: string;
}

export interface ScheduleDaysOption {
  value: Days;
  label: string;
}
export interface SchedulesState {
  schedules: UserEmploymentSchedules[];
  scheduleTempOption: ScheduleTempOption[];
  scheduleDaysOption: ScheduleDaysOption[];
  isViewEmployeeSchedule: boolean;
  currentEmployeeSchedule?: UserEmploymentSchedules;
  currentEmployeeNumber?: number;
}

const initialState: SchedulesState = {
  schedules: [],
  scheduleTempOption: [
    {
      value: Temp.YES,
      label: 'Yes',
    },
    {
      value: Temp.NO,
      label: 'No',
    },
  ],
  scheduleDaysOption: [
    {
      value: Days.MONDAY,
      label: 'Monday',
    },
    {
      value: Days.TUESDAY,
      label: 'Tuesday',
    },
    {
      value: Days.WEDNESDAY,
      label: 'Wednesday',
    },
    {
      value: Days.THURSDAY,
      label: 'Thursday',
    },
    {
      value: Days.FRIDAY,
      label: 'Friday',
    },
    {
      value: Days.SATURDAY,
      label: 'Saturday',
    },
    {
      value: Days.SUNDAY,
      label: 'Sunday',
    },
  ],
  isViewEmployeeSchedule: false,
  currentEmployeeSchedule: undefined,
  currentEmployeeNumber: undefined,
};

const getScheduleData = (schedule: Partial<UserEmploymentSchedules>) => ({
  ...schedule,
});

const upsertItems = (schedules: UserEmploymentSchedules[], ...data: UserEmploymentSchedules[]) => {
  const dataObject: { [id: number]: UserEmploymentSchedules } = {};
  schedules.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((schedule) => {
    if (!dataObject[schedule.id]) {
      dataObject[schedule.id] = schedule;
    } else if (dataObject[schedule.id]) {
      dataObject[schedule.id] = { ...dataObject[schedule.id], ...schedule };
    }
  });

  return Object.values(dataObject);
};

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    resetSchedulesState: () => ({
      ...initialState,
    }),
    setSchedulesState: (state, action: PayloadAction<Partial<SchedulesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertSchedule: (state, action: PayloadAction<Partial<UserEmploymentSchedules>>) => {
      const data: UserEmploymentSchedules = getScheduleData(
        action.payload,
      ) as UserEmploymentSchedules;

      if (typeof data.id === 'undefined') return;

      state.schedules = upsertItems(state.schedules, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(scheduleActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<UserEmploymentSchedules>;
      consoleLog('SCHEDULES REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.schedules = upsertItems(state.schedules, data);
      }
    });
    builder.addCase(scheduleActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<UserEmploymentSchedules>;

      if (status === 503) return;

      if (data) {
        state.schedules = data;
      }
    });
    builder.addCase(scheduleActions.get.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULES REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentSchedules>;

      if (typeof data.id === 'undefined') return;
      state.schedules = upsertItems(state.schedules, data);
    });
    builder.addCase(scheduleActions.put.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULES REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentSchedules>;

      if (typeof data.id === 'undefined') return;
      state.schedules = upsertItems(state.schedules, data);
    });
    builder.addCase(scheduleActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULES REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentSchedules>;

      if (typeof data.id === 'undefined') return;
      state.schedules = state.schedules.filter((schedule) => schedule.id !== data.id);
    });
    builder.addCase(scheduleActions.getCurrentSchedule.fulfilled, (state, { payload }) => {
      consoleLog('SCHEDULES REDUCER', 'GET CURRENT SCHEDULE', payload);

      const { status, data } = payload as ApiBaseResponse<UserEmploymentSchedules>;

      if (status === 503) return;

      state.currentEmployeeSchedule = data;
    });
  },
});

export const schedulesReducerActions = schedulesSlice.actions;
export default schedulesSlice.reducer;
