import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { Link, useParams } from 'react-router-dom';
import { Chip, Tooltip, Typography } from '@material-tailwind/react';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import TextWithIcons from '../../molecules/TextWithIcons';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useDateTime from '../../../hooks/utils/useDateTime';
import useTeams from '../../../hooks/teams/useTeams';
import Team from '../../../store/constants/teams/team.interface';
import ClientTeamListRow from './ClientTeamListRow';
import useClient from '../../../hooks/clients/useClient';
import SearchSvg from '../../../assets/images/icons/search.svg';
import TeamFormModal from '../team/TeamFormModal';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';
import ClientTeamReassignModal from './ClientTeamReassignModal';
import EmptyListMessage from '../../atoms/EmptyListMessage';

type SortByHeaderOptions = '' | 'team' | 'fte' | 'leader' | 'department';

const ClientTeamList: React.FC = () => {
  const { getDateTime } = useDateTime();

  const { teamsState, getAll: getAllTeams, remove } = useTeams();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState({
    isOpen: false,
    team: {} as Team,
  });

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const { id } = useParams<{
    id: string;
  }>();

  const { currentClient: client, loading } = useClient(id as string);

  useOnLoad(() => {
    // getAllUsers().then();
    getAllTeams().then();
  });

  if (loading) return null;

  if (!client) return null;

  const clientTeams = teamsState.teams
    .filter((team: Team) => team.client?.name.toLowerCase().startsWith(client.name.toLowerCase()))
    .filter((team: Team) => team.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()))
    .sort((a: Team, b: Team) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'team') {
        if (sortOrderBy) {
          return a.name.localeCompare(b.name);
        }
        return b.name.localeCompare(a.name);
      }

      if (sortOption === 'leader') {
        if (sortOrderBy) {
          return a.teamLeader?.userInformation?.fullName.localeCompare(
            b.teamLeader?.userInformation?.fullName,
          );
        }
        return b.teamLeader?.userInformation?.fullName.localeCompare(
          a.teamLeader?.userInformation?.fullName,
        );
      }

      if (sortOption === 'department') {
        if (!a.department) return -sortOrder;
        if (!b.department) return sortOrder;

        return sortOrder * a.department.name.localeCompare(b.department.name);
      }

      return sortOrder * (b.id - a.id);
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const onEditClick = (team: Team) => setSelectedTeam(team);

  const onTeamModalClose = () => setSelectedTeam(undefined);

  const onReassignClick = async (team: Team) => setIsReassignModalOpen({ isOpen: true, team });

  const onDeleteClick = async (team: Team) => remove(team.id);

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  return (
    <div className="organisms-client-employee-list w-full">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/clients">
          <Typography className="crumb">Clients</Typography>
        </Link>
        <Link to={`/admin/clients/${client.id}`}>
          <Typography className="crumb">{client.name}</Typography>
        </Link>
        <span className=" flex items-center gap-2">
          <Typography className="current-crumb">Teams</Typography>{' '}
          <Chip className="chip-style" value={clientTeams.length} variant="ghost" />
        </span>
      </Breadcrumbs>
      <div className="management-heading">
        <Typography variant="h3" className="management-heading__secondary">
          Client Teams Management
        </Typography>
      </div>
      <div className="client-employee-list-container pr-10">
        <div className="flex justify-between pb-5 space-x-10">
          <TeamFormModal
            className="rounded-lg shadow-lg muiButton--primary"
            team={selectedTeam}
            teamClient={client}
            onClose={onTeamModalClose}
          />

          {isReassignModalOpen.isOpen ? (
            <ClientTeamReassignModal
              team={isReassignModalOpen.team}
              teamClient={client}
              onClose={onTeamModalClose}
            />
          ) : null}

          <div className="search-body">
            <input
              onKeyDown={onSearchKeyDown}
              type="text"
              className="search-body__input search-bar"
              placeholder="Search client team..."
              onChange={handleSearch}
            />
            <img
              style={{ filter: 'invert(0) invert(1)' }}
              className="search-body__icon"
              src={SearchSvg}
              alt="Search for Client team"
            />
          </div>
        </div>
        <div className="client-team-list-header table-header">
          <button
            type="button"
            onClick={() => sortByHeader('team', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'team' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'team' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Team</div>
            </TextWithIcons>
          </button>
          <TextWithIcons variant="h6">
            <Tooltip content="Full time equivalent" className="bg-[#343434]">
              <div className="flex gap-5">FTE</div>
            </Tooltip>
          </TextWithIcons>
          <button
            type="button"
            onClick={() => sortByHeader('leader', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'leader' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'leader' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Leader</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('department', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'department' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'department' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Department</div>
            </TextWithIcons>
          </button>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Email</div>
          </TextWithIcons>
          <TextWithIcons variant="h6" className=" justify-self-center">
            <div className="flex gap-5">Action</div>
          </TextWithIcons>
        </div>

        {clientTeams && clientTeams.length !== 0 ? (
          <VariableSizeList
            itemCount={clientTeams.length}
            itemSize={getItemSize}
            height={window.innerHeight - 400}
            width="100%"
            className="no-scrollbars"
          >
            {({ index, style }) => (
              <ClientTeamListRow
                data={clientTeams[index]}
                className={`${index % 2 === 0 ? 'stripped' : ''}`}
                style={style}
                getDateTime={getDateTime}
                setSelectedTeam={() => setSelectedTeam(clientTeams[index])}
                onEditClick={onEditClick}
                onReassignClick={onReassignClick}
                onDeleteClick={onDeleteClick}
              />
            )}
          </VariableSizeList>
        ) : (
          <EmptyListMessage />
        )}
      </div>
    </div>
  );
};

export default ClientTeamList;
