import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import EmploymentStatus from '../../constants/employments/employment-status.interface';
import EmploymentStatusActions from '../../actions/employments/employmentStatusActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';

export interface EmploymentStatusState {
  employmentStatus: EmploymentStatus[];
}

const initialState: EmploymentStatusState = {
  employmentStatus: [],
};

const getEmploymentStatusData = (employmentStatus: Partial<EmploymentStatus>) => ({
  ...employmentStatus,
});

const employmentStatusSlice = createSlice({
  name: 'employmentStatus',
  initialState,
  reducers: {
    resetEmploymentStatusState: () => ({
      ...initialState,
    }),
    setEmploymentStatusState: (state, action: PayloadAction<Partial<EmploymentStatusState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertEmploymentStatus: (state, action: PayloadAction<Partial<EmploymentStatus>>) => {
      const data: EmploymentStatus = getEmploymentStatusData(action.payload) as EmploymentStatus;

      if (typeof data.id === 'undefined') return;

      state.employmentStatus = upsertData(state.employmentStatus, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(EmploymentStatusActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_STATUS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmploymentStatus>;

      if (status === 503) return;

      if (data) {
        state.employmentStatus = data;
      }
    });
    builder.addCase(EmploymentStatusActions.get.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_ROLES REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<EmploymentStatus>;

      if (typeof data.id === 'undefined') return;
      state.employmentStatus = upsertData(state.employmentStatus, data);
    });
  },
});

export const employmentStatusReducerActions = employmentStatusSlice.actions;
export default employmentStatusSlice.reducer;
