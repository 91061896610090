import React, { useEffect, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from 'formik';
import TextWithIcons from '../../molecules/TextWithIcons';
import consoleLog from '../../../utils/consoleLog';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import UserEmploymentSchedules from '../../../store/constants/employees/schedules/employee-schedule.interface';
import Team from '../../../store/constants/teams/team.interface';
import ScheduleListRow from './SchedulerListRow';
import useSchedules from '../../../hooks/schedule/useSchedules';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import SchedulerFormModal from './SchedulerEditFormModal';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import SelectTeam from '../../atoms/dropdowns/SelectTeam';
import SelectEmployee from '../../atoms/dropdowns/SelectEmployee';

type SortByHeaderOptions = '' | 'employeeNumber' | 'employeeName' | 'teamName';

const Scheduler: React.FC = () => {
  const navigate = useNavigate();
  const { schedulesState, setScheduleState, getAll: getAllSchedules, remove } = useSchedules();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const [selectedSchedule, setSelectedSchedule] = useState<UserEmploymentSchedules | undefined>();
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
  const [selectedEmployee, setSelectedEmployee] = useState<UserEmployment | undefined>();

  const [searchValue, setSearchValue] = useState<string | undefined>('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const { userEmploymentsStates, getAll: getAllUserEmployees } = useUserEmployments();

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const schedules = userEmploymentsStates.userEmployments
    .filter((schedule: UserEmployment) => {
      if (!schedule) return false;

      if (schedule.employeeNumber === 0 || schedule.employeeNumber === null) return false;

      // schedule.id.toString().toLowerCase().startsWith(debouncedSearchValue.toLowerCase());
      const searchKeywords = debouncedSearchValue?.toLowerCase().split(' ');

      const keywordMatches = (keyword: string) => {
        const keywords = keyword.split(' ');
        let found = false;
        keywords.forEach((search) => {
          if (
            schedule.employeeNumber.toString().toLowerCase().includes(search) ||
            schedule?.userInformation.fullName.toLowerCase().includes(search)
          ) {
            found = true;
          }
        });
        return found;
      };

      // Check if any of the keywords match in any column
      return searchKeywords?.every(keywordMatches);
    })
    .sort((a: UserEmployment, b: UserEmployment) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'employeeNumber') {
        return sortOrder * a.employeeNumber - b.employeeNumber;
      }

      if (sortOption === 'employeeName') {
        const x = a?.userInformation.fullName;
        const y = b?.userInformation.fullName;

        return x && y
          ? sortOrder * x.localeCompare(y)
          : sortOrder * b.employeeNumber - a.employeeNumber;
      }

      if (sortOption === 'teamName') {
        return sortOrder * b.employeeNumber - a.employeeNumber;
      }

      return sortOrder * b.employeeNumber - a.employeeNumber;
    });

  const handleSearch = (search: string | undefined) => {
    setSearchValue(search);
  };

  const onViewClick = (schedule: UserEmployment) => {
    setScheduleState({ ...schedulesState, currentEmployeeNumber: schedule.employeeNumber });
    navigate(`/admin/scheduler/${schedule.employeeNumber}/employees`);
  };

  const onDeleteClick = (schedule: UserEmployment) => {
    remove(schedule.id).then((r) => {
      consoleLog('DELETE SCHEDULE', r);
    });
  };

  const onScheduleModalClose = () => {
    setSelectedSchedule(undefined);
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    const itemHeight = 50;
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAllSchedules().then();
    getAllUserEmployees().then();
  });

  useEffect(() => {
    if (selectedEmployee !== undefined && selectedEmployee?.userInformation?.fullName) {
      handleSearch(selectedEmployee?.userInformation?.fullName);
    } else {
      handleSearch('');
    }
  }, [selectedEmployee]);

  // const uniqueData = filterDuplicates(schedules);

  return (
    <div className="w-full organisms-client-list">
      <div className="flex justify-between w-full gap-20 pb-5">
        <SchedulerFormModal schedule={selectedSchedule} onClose={onScheduleModalClose} />
        <Formik initialValues={{ ...selectedTeam }} onSubmit={() => {}}>
          <Form className="flex justify-between w-full gap-20">
            <div className="w-full space-y-2">
              <Typography className="muiTypography--headline6">Team</Typography>

              <SelectTeam
                id="team"
                name="team"
                value={selectedTeam}
                onChange={(value) => setSelectedTeam(value as Team)}
                hasSearchIcon
                isSearchable
                isClearable
              />
              <ErrorMessage name="team" component="div" />
            </div>
            <div className="w-full space-y-2">
              <Typography className="muiTypography--headline6">Employee</Typography>
              <SelectEmployee
                id="employee"
                name="employee"
                placeholder="Select employee"
                value={selectedEmployee}
                onChange={(value) => setSelectedEmployee(value as UserEmployment)}
                hasSearchIcon
                isSearchable
                isClearable
              />
              <ErrorMessage name="employee" component="div" />
            </div>
          </Form>
        </Formik>
      </div>
      <div className="schedule-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        <button
          type="button"
          onClick={() => sortByHeader('employeeNumber', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Employee #</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow
              className={`sort-arrow-icon ${sortOption === 'employeeNumber' ? '' : 'hidden'}`}
            />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'employeeNumber' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('employeeName', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Employee Name</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'employeeName' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'employeeName' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('teamName', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Team</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'teamName' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'teamName' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Schedule</div>
        </TextWithIcons>
        <TextWithIcons className="justify-self-center" variant="h6">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>
      {schedules.length !== 0 ? (
        <VariableSizeList
          itemCount={schedules.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <ScheduleListRow
              data={schedules[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              onViewClick={onViewClick}
              onDeleteClick={onDeleteClick}
            />
          )}
        </VariableSizeList>
      ) : (
        <EmptyListMessage />
      )}
    </div>
  );
};

export default Scheduler;
