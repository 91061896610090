import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemPrefix, Spinner } from '@material-tailwind/react';
import ImageWithDefault from '../../../atoms/ImageWithDefault';
import useUser from '../../../../hooks/users/useUser';
import useAuth from '../../../../hooks/auths/useAuth';
import LinkMenu from '../../../atoms/LinkMenu';
import AccordionMenu from '../../../molecules/AccordionMenu';
import AdminSidebar from './AdminSidebarUnused';
import useAccordionMenu from '../../../../hooks/utils/useAccordionMenu';
import { personalMenu } from './SidebarMenuOptions';
import { ReactComponent as Home } from '../../../../assets/images/icons/home-solid.svg';
import { ReactComponent as Person } from '../../../../assets/images/icons/person-solid.svg';
import { ReactComponent as Admin } from '../../../../assets/images/icons/person-circle-solid.svg';
import { ReactComponent as Handbook } from '../../../../assets/images/icons/handbook-solid.svg';
import { ReactComponent as Briefcase } from '../../../../assets/images/icons/briefcase-solid.svg';
import { ReactComponent as Newspaper } from '../../../../assets/images/icons/newspaper-solid.svg';
import { ReactComponent as Phone } from '../../../../assets/images/icons/phone-solid.svg';
import { ReactComponent as Cog } from '../../../../assets/images/icons/cog-solid.svg';
import { ReactComponent as Logout } from '../../../../assets/images/icons/arrow-left-end.svg';

const SideMenu: React.FC = () => {
  const { loggedUser } = useAuth();
  const { currentUser, loading } = useUser((loggedUser?.userId ?? '').toString());
  const navigate = useNavigate();

  const employeeName = currentUser?.employment?.currentPosition?.employmentPosition.name;
  const employeeTier = currentUser?.employment?.currentPosition?.tier;

  const onLogoutClick = () => {
    navigate('/logout');
  };

  const { openedItems, toggleItem } = useAccordionMenu();

  const pathArray = useLocation().pathname.split('/');

  const userDataImage = {
    ...currentUser,
    imageProfileImage:
      'https://images.pexels.com/photos/653429/pexels-photo-653429.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  };

  const singleMenuClick = () => {
    openedItems.forEach((openedItem) => {
      toggleItem(openedItem);
    });
  };

  return (
    <div className="sidemenu-container rounded-md p-2 mt-[5.5rem]">
      <div className="p-1 rounded-lg bg-[#242426] shadow-lg  sidemenu-style-old">
        <div className="account-profile-information">
          <div className="flex space-x-3">
            <div className="cursor-pointer rounded-full sidebar-image-wrapper">
              {loading ? (
                <div className="h-full w-full flex justify-center items-center">
                  <Spinner height="1rem" color="deep-orange" />
                </div>
              ) : (
                <Link to="/my/profile">
                  <ImageWithDefault
                    className="h-full w-full sidebar-profile-image"
                    src={userDataImage.imageProfileImage}
                    defaultImageUrl="/assets/images/profile/default.png"
                    alt={`${loggedUser?.fullName.toUpperCase()} Profile Image`}
                  />
                </Link>
              )}
            </div>
            <div className="flex justify-center m-auto cursor-pointer sidebar-main-info">
              <Link to="/my/profile">
                <div className="flex gap-1 sidebar-fullname-wrapper">
                  <span className="sidebar-lastname">{loggedUser?.lastName}, </span>
                  <span className="sidebar-firstname">{loggedUser?.firstName}</span>
                </div>
                <div className="sidebar-user-position flex flex-col gap-1">
                  <span>{employeeName ? `${employeeName} ${employeeTier}` : ''}</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <List>
        <ListItem className="sidebar-borders p-0">
          <LinkMenu
            menuTitle="Home"
            icon={<Home className="my-sidebar-icon" />}
            pathArray={pathArray}
            toPath="/user/newsfeed"
            onClick={singleMenuClick}
          />
        </ListItem>

        <AccordionMenu
          menuTitle="Personal"
          openNumber={1}
          options={personalMenu}
          open={openedItems.includes(1) ? 1 : 0}
          handleOpen={() => toggleItem(1)}
          accordionIcon={<Person className="my-sidebar-icon" />}
        />

        <ListItem className="admin-menu-sidebar sidebar-borders p-0 hidden">
          <AdminSidebar />
        </ListItem>

        <ListItem className="sidebar-borders p-0">
          <LinkMenu
            menuTitle="Admin"
            icon={<Admin className="my-sidebar-icon" />}
            pathArray={pathArray}
            toPath="/admin/dashboard"
          />
        </ListItem>

        <ListItem className="sidebar-borders p-0">
          <LinkMenu
            menuTitle="Handbook"
            icon={<Handbook className="my-sidebar-icon" />}
            pathArray={pathArray}
            toPath="/user/handbook"
          />
        </ListItem>

        <ListItem className="sidebar-borders p-0">
          <LinkMenu
            menuTitle="Workforce"
            icon={<Briefcase className="my-sidebar-icon" />}
            pathArray={pathArray}
            toPath="#"
            // toPath="https://awesomeos.org/app"
            // exter
          />
        </ListItem>

        <ListItem className="sidebar-borders p-0">
          <LinkMenu
            menuTitle="IT Policies"
            icon={<Newspaper className="my-sidebar-icon" />}
            pathArray={pathArray}
            toPath="/user/policies"
          />
        </ListItem>

        <ListItem className="sidebar-borders p-0">
          <LinkMenu
            menuTitle="Contact Information"
            icon={<Phone className="my-sidebar-icon" />}
            pathArray={pathArray}
            toPath="/user/contact-information"
          />
        </ListItem>

        <ListItem className="sidebar-borders p-0">
          <LinkMenu
            menuTitle="Troubleshooting"
            icon={<Cog className="my-sidebar-icon" />}
            pathArray={pathArray}
            toPath="/user/osnet/troubleshooting"
          />
        </ListItem>

        <ListItem
          onClick={onLogoutClick}
          className={`sidebar-borders flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825]
        }`}
        >
          <ListItemPrefix>
            <Logout className="my-sidebar-icon" />
          </ListItemPrefix>
          <span className="sidebar-text">Log Out</span>
        </ListItem>
      </List>
    </div>
  );
};

export default SideMenu;
