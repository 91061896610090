import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import Loan from '../../../store/constants/loans/loan.interface';
import useLoans from '../../../hooks/loans/useLoans';
import { ReactComponent as LoanIcon } from '../../../assets/images/icons/loan.svg';
import SelectLoanType from '../../atoms/dropdowns/SelectLoanType';
import SelectEmployee from '../../atoms/dropdowns/SelectEmployee';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import LoanType from '../../../store/constants/loans/loan-type.interface';
import toastNotification from '../../../hooks/utils/toastNotification';
import consoleLog from '../../../utils/consoleLog';

export interface LoanFormProps {
  loan?: Loan;
  className?: string;
  onClose: () => void;
}

const LoanFormModal: React.FC<LoanFormProps> = ({
  loan = undefined,
  className = undefined,
  onClose,
}) => {
  const [loanForm, setLoanForm] = useState<Loan | undefined>(loan);
  // const [selectedUserEmployment, setSelectedUserEmployment] = useState<UserEmployment | null>();
  // const [selectedLoanType, setSelectedLoanType] = useState<LoanType | null>();
  const [open, setOpen] = React.useState(false);
  const { post, put, getAll } = useLoans();

  const handleClose = () => {
    setLoanForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setLoanForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<Loan>) => {
    if (loanForm) {
      await toastNotification({
        action: put(values),
        onSuccess: () => {
          handleClose();
        },
        onError: (response) => {
          setLoanForm(undefined);
          consoleLog('Error', response);
        },
        onFulfilled: async () => {
          await getAll({});
          setLoanForm(undefined);
          handleClose();
        },
        toastPromiseMessages: {
          pending: `Updating Loan for Employee ${values.employee?.userInformation.fullName}`,
          success: 'Loan is successfully updated.',
          error: 'Error on updating Loan.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as Loan),
        onSuccess: () => {
          handleClose();
        },
        onError: (response) => {
          consoleLog('Error', response);
        },
        onFulfilled: async () => {
          await getAll({});
          handleClose();
        },
        toastPromiseMessages: {
          pending: `Creating Loan for Employee ${values.employee?.userInformation.fullName}`,
          success: 'Loan is successfully added.',
          error: 'Error on adding Loan.',
        },
      });
    }
    handleClose();
  };

  useEffect(() => {
    if (loan) {
      setOpen(true);
      setLoanForm(loan);
    }
  }, [loan]);

  return (
    <div>
      <div className="">
        <button type="button" onClick={handleOpen} className={className}>
          Create Loan
        </button>
      </div>
      <Dialog
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-12 md-modal-size"
      >
        <div className="m-2 p-0 absolute flex top-0 right-0">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-2">
          <LoanIcon className="modal-icons" stroke="white" />
          <DialogHeader className="modal-header mt-1 p-0 text-gray-200">Create Loan</DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <Formik
              initialValues={{
                ...loanForm,
                id: loanForm?.id || 0,
                employeeNumber: loanForm?.employeeNumber,
                loanTypeId: loanForm?.loanTypeId || 0,
                dateStart: loanForm?.dateStart || new Date(),
                dateGranted: loanForm?.dateGranted || new Date(),
                dateCancelled: loanForm?.dateCancelled || new Date(),
                isPaid: loanForm?.isPaid || 0,
                cancelledBy: loanForm?.cancelledBy || undefined,
                employee: loanForm?.employee
                  ? ({
                      ...loanForm?.employee,
                      label: loanForm?.employee?.userInformation.fullName,
                      value: loanForm?.employee.userInformation.fullName,
                    } as UserEmployment)
                  : undefined,
                loanType: loanForm?.loanType
                  ? ({
                      ...loanForm?.loanType,
                      label: loanForm?.loanType?.name,
                      value: loanForm?.loanType,
                    } as LoanType)
                  : undefined,
                amount: loanForm?.amount || 0,
                interest: loanForm?.interest || 0,
                amountDue: loanForm?.amountDue || 0,
                termsToPay: loanForm?.termsToPay || 0,
                currentTerm: loanForm?.currentTerm || 0,
                amountRemaining: loanForm?.amountRemaining || 0,
              }}
              onSubmit={handleSubmit}
              // onSubmit={(values, { resetForm }: FormikHelpers<Loan>) => {
              //   handleSubmit(values); // Call the provided handleSubmit function

              //   // Optionally, you can reset the form here
              //   resetForm();
              // }}
            >
              {(formik) => (
                <Form className="">
                  <div className="mt-4 mb-3 form-section">
                    <div className="flex justify-between">
                      <div className="first-section w-[45%]">
                        <div className="mb-6">
                          <label htmlFor="name" className="modal-labels block mb-2 text-gray-200">
                            Employee Name
                          </label>
                          <div className="">
                            <SelectEmployee
                              id="employee"
                              name="employee"
                              placeholder="Select employee"
                              value={formik.values.employee}
                              setFieldValue={formik.setFieldValue}
                              onChange={(value) => {
                                formik.setFieldValue('employee', value);
                              }}
                              hasSearchIcon
                              isSearchable
                              isClearable
                            />
                            <ErrorMessage name="employee" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="emailDistro"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Loan Type
                          </label>
                          <div className="">
                            <SelectLoanType
                              id="loanType"
                              name="loanType"
                              placeholder="Select"
                              value={formik.values.loanType}
                              setFieldValue={formik.setFieldValue}
                              isSearchable
                              isClearable
                            />
                            <ErrorMessage name="loanType" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="teamLeader"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Amount
                          </label>
                          <div className="">
                            <Field
                              type="number"
                              id="amount"
                              name="amount"
                              placeholder="Amount"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="amount" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label htmlFor="client" className="modal-labels block mb-2 text-gray-200">
                            Interest
                          </label>
                          <div className="">
                            <Field
                              type="number"
                              id="interest"
                              name="interest"
                              placeholder="Interest"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="interest" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="location"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Date Granted
                          </label>
                          <div className="">
                            <Field
                              type="date"
                              id="dateGranted"
                              name="dateGranted"
                              placeholder="Amount Remaining"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="dateGranted" component="div" />
                          </div>
                        </div>
                      </div>

                      <div className="second-section w-[45%]">
                        <div className="mb-6">
                          <label
                            htmlFor="department"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Terms to Pay
                          </label>
                          <div className="">
                            <Field
                              type="number"
                              id="termsToPay"
                              name="termsToPay"
                              placeholder="Terms to Pay"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="termsToPay" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="company"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Amount Due
                          </label>
                          <div className="">
                            <Field
                              type="number"
                              id="amountDue"
                              name="amountDue"
                              placeholder="Amount Due"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="amountDue" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="location"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Current Term
                          </label>
                          <div className="">
                            <Field
                              type="number"
                              id="currentTerm"
                              name="currentTerm"
                              placeholder="Current Term"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="currentTerm" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="location"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Amount Remaining
                          </label>
                          <div className="">
                            <Field
                              type="number"
                              id="amountRemaining"
                              name="amountRemaining"
                              placeholder="Amount Remaining"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="amountRemaining" component="div" />
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="location"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Date Started
                          </label>
                          <div className="">
                            <Field
                              type="date"
                              id="dateStart"
                              name="dateStart"
                              value={formik.values.dateStart}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="dateStart" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-2 w-max">
                    <div className="flex justify-center w-max">
                      <button
                        type="submit"
                        className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="flex justify-center w-full">
                      <button
                        type="button"
                        onClick={() => handleClose()}
                        className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default LoanFormModal;
