import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import HmoDependentActions from '../../actions/my/transactionFeedActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import HmoDependentType from '../../constants/my/hmo-dependent.interface';

export interface HmoDependentsState {
  hmoDependents: HmoDependentType[];
}

const initialState: HmoDependentsState = {
  hmoDependents: [
    {
      id: 1,
      accountNumber: '2706-1232130055-01',
      plan: 'RP',
      fullName: 'Anonymous Person',
      premiumClass: 7643.12,
      expiry: new Date('2025-08-13'),
      classification: 'FREE DEPENDENT',
    },
    {
      id: 2,
      accountNumber: '1243-45421334055-78',
      plan: 'RP',
      fullName: 'Spongebob',
      premiumClass: 7888.12,
      expiry: new Date('2025-12-13'),
      classification: 'VOLUNTARY DEPENDENT',
    },
    {
      id: 3,
      accountNumber: '9875-4352234367-67',
      plan: 'SG',
      fullName: 'Uchiha Madara',
      premiumClass: 5465.12,
      expiry: new Date('2078-12-08'),
      classification: 'ONE MAN ARMY',
    },
  ],
};

const getHmoDependentData = (hmoDependent: Partial<HmoDependentType>) => ({
  ...hmoDependent,
});

const hmoDependentsSlice = createSlice({
  name: 'hmoDependents',
  initialState,
  reducers: {
    resetHmoDependentsState: () => ({
      ...initialState,
    }),
    setHmoDependentsState: (state, action: PayloadAction<Partial<HmoDependentsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertHmoDependent: (state, action: PayloadAction<Partial<HmoDependentType>>) => {
      const data: HmoDependentType = getHmoDependentData(action.payload) as HmoDependentType;

      if (typeof data.id === 'undefined') return;

      state.hmoDependents = upsertData(state.hmoDependents, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HmoDependentActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<HmoDependentType>;
      consoleLog('HMO_DEPENDENT REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.hmoDependents = upsertData(state.hmoDependents, data);
      }
    });
    builder.addCase(HmoDependentActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('HMO_DEPENDENT REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<HmoDependentType>;

      if (status === 503) return;

      if (data) {
        state.hmoDependents = data;
      }
    });
    builder.addCase(HmoDependentActions.get.fulfilled, (state, { payload }) => {
      consoleLog('HMO_DEPENDENT REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<HmoDependentType>;

      if (typeof data.id === 'undefined') return;
      state.hmoDependents = upsertData(state.hmoDependents, data);
    });
    builder.addCase(HmoDependentActions.put.fulfilled, (state, { payload }) => {
      consoleLog('HMO_DEPENDENT REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<HmoDependentType>;

      if (typeof data.id === 'undefined') return;
      state.hmoDependents = upsertData(state.hmoDependents, data);
    });
    builder.addCase(HmoDependentActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('HMO_DEPENDENT REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<HmoDependentType>;

      if (typeof data.id === 'undefined') return;
      state.hmoDependents = state.hmoDependents.filter(
        (hmoDependent) => hmoDependent.id !== data.id,
      );
    });
  },
});

export const hmoDependentsReducerActions = hmoDependentsSlice.actions;
export default hmoDependentsSlice.reducer;
