import React from 'react';
import AccountInfoTable from './tables/AccountInfoTable';

const AccountInfoReportPage: React.FC = () => (
  <div className="w-full list-of-reports mt-5">
    <div className="block">
      <div className="w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg">
        <div className="p-3 w-full bg-[#f05825] rounded-t-lg">
          <span className="text-gray-200 font-bold">Account Info Report</span>
        </div>
        <div className="p-5">
          <div className="flex flex-col gap-2 mb-5">
            <label htmlFor="account" className="text-gray-200 text-xs">
              Account
            </label>
            <input
              type="text"
              className="bg-[#343434] w-40 rounded-md border border-solid border-[#434343] px-2"
            />
          </div>
          <hr className="rounded-md  mr-5 border-1 opacity-10" />
          <div className="flex mt-5">
            <div className="flex flex-col gap-5">
              <div className="flex gap-2 items-center">
                <label htmlFor="entries" className="text-gray-200 text-xs">
                  Show
                </label>
                <input
                  type="text"
                  className="bg-[#343434] w-14 rounded-md border border-solid border-[#434343] px-2"
                />
                <label htmlFor="entries" className="text-gray-200 text-xs">
                  Entries
                </label>
              </div>
            </div>
            <div className="ml-auto">
              <button
                type="button"
                className="p-2 py-1 bg-[#f05825] text-white text-sm rounded-md shadow-md"
              >
                Export All
              </button>
            </div>
          </div>
          <div className="border border-solid border-[#343434] mt-5 shadow-lg">
            <AccountInfoTable />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AccountInfoReportPage;
