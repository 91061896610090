import { useAppDispatch, useAppSelector } from '../../store/store';
import holidayActions from '../../store/actions/holidays/holidayActions';
import {
  HolidaysState,
  holidaysReducerActions,
} from '../../store/reducers/holidays/holidaysReducer';
import Holiday from '../../store/constants/holidays/holiday.interface';

const useHolidays = () => {
  const dispatch = useAppDispatch();
  const holidaysState: HolidaysState = useAppSelector((state) => state.holidays);

  const resetHolidayState = () => {
    dispatch(holidaysReducerActions.resetHolidaysState());
  };

  const setHolidayState = (state: Partial<HolidaysState>) => {
    dispatch(holidaysReducerActions.setHolidaysState(state));
  };

  const get = async (id: Holiday['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayActions.getAll({}));

  const post = async (data: Omit<Holiday, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayActions.post(data));

  const put = async (data: Partial<Holiday>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayActions.put(data));

  const remove = async (id: Holiday['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayActions.remove(id));

  return {
    resetHolidayState,
    holidaysState,
    setHolidayState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useHolidays;
