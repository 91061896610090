import { useAppDispatch, useAppSelector } from '../../store/store';
import { SettingsState, settingsReducerActions } from '../../store/reducers/utils/settingsReducer';

const useTheme = () => {
  const dispatch = useAppDispatch();
  const { theme }: SettingsState = useAppSelector((state) => state.settings);

  const setTheme = (themeSetting: SettingsState['theme']) => {
    dispatch(settingsReducerActions.setSettingsState({ theme: themeSetting }));
  };

  const handleThemeChange = () => {
    if (theme === 'light') {
      setTheme('dark');
    }
    if (theme === 'dark') {
      setTheme('light');
    }
  };

  return {
    theme,
    setTheme,
    handleThemeChange,
  };
};

export default useTheme;
