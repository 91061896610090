const countDaysExcluding = (startDate: Date, endDate: Date, excludedDates: Date[]) => {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

  // Calculate the time difference in days
  const timeDifference = (endDate.getTime() - startDate.getTime()) / oneDay;

  let totalDays = timeDifference + 1; // Include the start date

  // Subtract the days for each excluded date
  excludedDates.forEach((excludedDate) => {
    const timeDiff = (excludedDate.getTime() - startDate.getTime()) / oneDay;
    if (timeDiff >= 0 && timeDiff <= timeDifference) {
      totalDays -= 1;
    }
  });

  return totalDays;
};

export default countDaysExcluding;
