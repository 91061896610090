import React from 'react';
import { Typography } from '@material-tailwind/react';
import SchedulerViewContent from './SchedulerViewContent';
import useSchedules from '../../../../hooks/schedule/useSchedules';

const SchedulerView: React.FC = () => {
  const {
    schedulesState: { isViewEmployeeSchedule },
  } = useSchedules();

  return (
    <div className="">
      {isViewEmployeeSchedule && (
        <div className="w-[100%] p-2 rounded-xl bg-[#282727] shadow-lg">
          <div className="p-1">
            <Typography variant="paragraph" className="font-semibold text-[#f05825] text-lg">
              Schedule Viewer 2
            </Typography>
          </div>
          <div className="p-2 w-[100%]">
            <div className="">
              <SchedulerViewContent />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulerView;
