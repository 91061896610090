import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import Company from '../../constants/companies/company.interface';
import CompanyActions from '../../actions/companies/companyActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface CompaniesState {
  companies: Company[];
}

const initialState: CompaniesState = {
  companies: [],
};

const getCompanyData = (company: Partial<Company>) => ({
  ...company,
});

const upsertItems = (companies: Company[], ...data: Company[]) => {
  const dataObject: { [id: number]: Company } = {};
  companies.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((company) => {
    if (!dataObject[company.id]) {
      dataObject[company.id] = company;
    } else if (dataObject[company.id]) {
      dataObject[company.id] = { ...dataObject[company.id], ...company };
    }
  });

  return Object.values(dataObject);
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    resetCompaniesState: () => ({
      ...initialState,
    }),
    setCompaniesState: (state, action: PayloadAction<Partial<CompaniesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertCompany: (state, action: PayloadAction<Partial<Company>>) => {
      const data: Company = getCompanyData(action.payload) as Company;

      if (typeof data.id === 'undefined') return;

      state.companies = upsertItems(state.companies, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CompanyActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Company>;
      consoleLog('CLIENTS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.companies = upsertItems(state.companies, data);
      }
    });
    builder.addCase(CompanyActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Company>;

      if (status === 503) return;

      if (data) {
        state.companies = data;
      }
    });
    builder.addCase(CompanyActions.get.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Company>;

      if (typeof data.id === 'undefined') return;
      state.companies = upsertItems(state.companies, data);
    });
    builder.addCase(CompanyActions.put.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<Company>;

      if (status !== StatusCodes.OK) return;
      state.companies = upsertItems(state.companies, data);
    });
    builder.addCase(CompanyActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('CLIENTS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Company>;

      if (typeof data.id === 'undefined') return;
      state.companies = state.companies.filter((company) => company.id !== data.id);
    });
  },
});

export const companiesReducerActions = companiesSlice.actions;
export default companiesSlice.reducer;
