import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import LoanType from '../../constants/loans/loan-type.interface';
import loanTypeActions from '../../actions/loans/loanTypeActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface LoanTypesState {
  loanTypes: LoanType[];
}

const initialState: LoanTypesState = {
  loanTypes: [],
};

const getLoanTypeData = (loanType: Partial<LoanType>) => ({
  ...loanType,
});

const upsertItems = (loanTypes: LoanType[], ...data: LoanType[]) => {
  const dataObject: { [id: number]: LoanType } = {};
  loanTypes.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((loanType) => {
    if (!dataObject[loanType.id]) {
      dataObject[loanType.id] = loanType;
    } else if (dataObject[loanType.id]) {
      dataObject[loanType.id] = { ...dataObject[loanType.id], ...loanType };
    }
  });

  return Object.values(dataObject);
};

const loanTypesSlice = createSlice({
  name: 'loanTypes',
  initialState,
  reducers: {
    resetLoanTypesState: () => ({
      ...initialState,
    }),
    setLoanTypesState: (state, action: PayloadAction<Partial<LoanTypesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertLoanType: (state, action: PayloadAction<Partial<LoanType>>) => {
      const data: LoanType = getLoanTypeData(action.payload) as LoanType;

      if (typeof data.id === 'undefined') return;

      state.loanTypes = upsertItems(state.loanTypes, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loanTypeActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<LoanType>;
      consoleLog('LOANS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.loanTypes = upsertItems(state.loanTypes, data);
      }
    });
    builder.addCase(loanTypeActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<LoanType>;

      if (status === 503) return;

      if (data) {
        state.loanTypes = data;
      }
    });
    builder.addCase(loanTypeActions.get.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<LoanType>;

      if (typeof data.id === 'undefined') return;
      state.loanTypes = upsertItems(state.loanTypes, data);
    });
    builder.addCase(loanTypeActions.put.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<LoanType>;

      if (typeof data.id === 'undefined') return;
      state.loanTypes = upsertItems(state.loanTypes, data);
    });
    builder.addCase(loanTypeActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<LoanType>;

      if (typeof data.id === 'undefined') return;
      state.loanTypes = state.loanTypes.filter((loanType) => loanType.id !== data.id);
    });
  },
});

export const loanTypesReducerActions = loanTypesSlice.actions;
export default loanTypesSlice.reducer;
