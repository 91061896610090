import React from 'react';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import useUploadFileAttachment from '../../../../hooks/utils/useUploadFileAttachment';
import { ReactComponent as UploadFile } from '../../../../assets/images/icons/upload-file.svg';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';

export interface HmoUploadStatementOfAccountType {
  hmoUploadStatementOfAccount: File | [];
}

interface HmoUploadStatementOfAccountProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const HmoUploadStatementOfAccount: React.FC<HmoUploadStatementOfAccountProps> = ({
  isOpen,
  setOpen,
}) => {
  const { selectedFile, setSelectedFile, errorMessage, handleFileChange } =
    useUploadFileAttachment();

  const acceptedFiles =
    'application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpeg, image/png, image/jpg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  const initialState: HmoUploadStatementOfAccountType = {
    hmoUploadStatementOfAccount: [],
  };

  const handleClose = () => {
    setSelectedFile('');
    setOpen(false);
  };

  const handleSubmit = (
    values: HmoUploadStatementOfAccountType,
    { setSubmitting }: FormikHelpers<HmoUploadStatementOfAccountType>,
  ) => {
    const data = {
      ...values,
      hmoUploadStatementOfAccount: selectedFile,
    };

    // eslint-disable-next-line
    console.log('Your Hmo File is Uploaded Successfully', data);

    setSubmitting(false);
    setSelectedFile('');
    setOpen(false);
  };

  return (
    <Dialog
      size="xs"
      open={isOpen}
      handler={setOpen}
      className="bg-[#343434] shadow-none p-10 modal-size"
    >
      <div className="m-2 p-0 absolute flex top-0 right-0">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex gap-3">
        <UploadFile className="modal-icons text-[#f2f2f2]" />
        <DialogHeader className="modal-header mt-1 p-0 text-gray-200">Upload Hmo SOA</DialogHeader>
      </div>
      <DialogBody divider className="p-0">
        <div className="">
          <div className="">
            <Formik initialValues={initialState} onSubmit={handleSubmit}>
              <Form className="">
                {/* FIRST SECTION */}
                <div className="mt-4 mb-4 form-section">
                  <div className="flex flex-col justify-center w-full">
                    <div className="mb-6">
                      <div className="">
                        <input
                          type="file"
                          id="hmoUploadStatementOfAccount"
                          name="hmoUploadStatementOfAccount"
                          accept={acceptedFiles}
                          onChange={handleFileChange}
                          className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                        />
                      </div>
                      <div className="text-red-500">{errorMessage && errorMessage}</div>
                      <ErrorMessage name="hmoUploadStatementOfAccount" component="div" />
                    </div>
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex space-x-2 w-max">
                  <div className="flex justify-center w-full">
                    <button
                      type="submit"
                      className={`modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md ${
                        (!selectedFile || errorMessage) && 'opacity-50 cursor-not-allowed'
                      }`}
                      disabled={!selectedFile || !!errorMessage}
                    >
                      Submit
                    </button>
                  </div>

                  <div className="flex justify-center w-max">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default HmoUploadStatementOfAccount;
