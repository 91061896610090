import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import Button from '@material-tailwind/react/components/Button';

export interface Tab {
  urlPath?: string;
  title: string;
  content: ReactNode;
}

export interface TabbedContentProps {
  tabs: Tab[];
  currentActiveTab?: number;
}

const TabbedContent: React.FC<TabbedContentProps> = ({ tabs, currentActiveTab = 0 }) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<number>(currentActiveTab);

  const handleTabClick = (index: number) => {
    setActiveTab(index);

    if (tabs[index]?.urlPath) navigate(tabs[index].urlPath as string);
  };

  return (
    <div className="tabbed-content w-full">
      <div className="border-b">
        {tabs.map((tab, index) => (
          <button
            type="button"
            key={tab.title}
            className={`tabbed-content-tabs px-4 py-2 ${
              index === activeTab ? ' rounded-none  active-tab' : ''
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      <div className="pt-5 pr-6">{tabs[activeTab]?.content}</div>
    </div>
  );
};

export default TabbedContent;
