import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import Team from '../../../store/constants/teams/team.interface';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';

export interface TeamListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Team;
  getDateTime: (date: Date) => string;
  onEditClick: (team: Team) => void;
  onDeleteClick: (team: Team) => Promise<unknown>;
}

const TeamListRow: React.FC<TeamListRowProps> = ({
  className = '',
  data,
  getDateTime,
  onEditClick,
  onDeleteClick,
  ...props
}: TeamListRowProps) => {
  const navigate = useNavigate();

  const handleOnTeamAssignmentClick = () => {
    navigate(`/admin/teams/${data.id}/employees`);
  };

  const deleteMessage = {
    pending: 'Pending',
    success: 'Team information is successfully deleted.',
    error: 'Error on deleting Team information.',
  };

  return (
    <div {...props} className={`${className} team-list-data table-list-data flex items-center`}>
      <div>{data.name}</div>
      {/**
       * @todo
       * waiting for api that gets of members in team
       */}
      <div>{data.fte}</div>
      <div>{data.client?.name}</div>
      <div>{data.department?.name}</div>
      <div>{data.teamLeader?.userInformation?.fullName}</div>
      <div>{data.dateUpdated && getDateTime(data.dateUpdated)}</div>

      <div className="action-buttons space-x-6 justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
        </Tooltip>

        <Tooltip content="Assignment" className="bg-[#343434]">
          <button
            className="textButton"
            type="button"
            onClick={() => handleOnTeamAssignmentClick()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
              />
            </svg>
          </button>
        </Tooltip>

        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default TeamListRow;
