import { EffectCallback, useEffect } from 'react';

/**
 * A custom function that imitates the onLoad useEffect
 *
 * @param onLoadFunc
 */
export default function useOnLoad(onLoadFunc: EffectCallback) {
  // eslint-disable-next-line
  useEffect(onLoadFunc, []);
}
