import apiFetch, { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import NurseStationVisit from '../../../constants/my/healthTracker/nurse-station-visit.interface';

export type GetAllNurseStationVisitsQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: NurseStationVisit['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<NurseStationVisit>> =>
  apiFetch<ApiBaseResponse<NurseStationVisit>>(`nurse/station/visit${id}`, { state })
    .then((response: ApiBaseResponse<NurseStationVisit>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllNurseStationVisitsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<NurseStationVisit>> =>
  apiFetch<ApiGetAllBaseResponse<NurseStationVisit>>(
    `nurse/station/visit?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<NurseStationVisit>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<NurseStationVisit, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<NurseStationVisit> | void> =>
  apiFetch<ApiBaseResponse<NurseStationVisit>>('nurse/station/visit', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<NurseStationVisit>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: NurseStationVisit,
  state: StateHeaders,
): Promise<ApiBaseResponse<NurseStationVisit> | void> =>
  apiFetch<ApiBaseResponse<NurseStationVisit>>(`nurse/station/visit/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<NurseStationVisit>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<NurseStationVisit, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<NurseStationVisit> | void> =>
  apiFetch<ApiBaseResponse<NurseStationVisit>>(`nurse/station/visit/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<NurseStationVisit>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: NurseStationVisit['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<NurseStationVisit> | void> =>
  apiFetch<ApiBaseResponse<NurseStationVisit>>(`nurse/station/visit/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<NurseStationVisit>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
