import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Client from '../../constants/clients/client.interface';
import clientServices, { GetAllClientsQueryParams } from '../../services/clients/clientServices';

const get = createAsyncThunk(
  'client/get',
  async (
    id: Client['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Client> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'client/getAll',
  async (
    {
      page = 1,
      limit = 50,
      search = '',
      dateStartRange = undefined,
      dateEndRange = undefined,
      isActive = 1,
    }: GetAllClientsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Client> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientServices.getAll(
        { page, limit, search, dateStartRange, dateEndRange, isActive },
        state,
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'client/post',
  async (
    data: Omit<Client, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Client> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientServices.post(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'client/put',
  async (
    data: AtLeast<Client, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Client> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientServices.put(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'client/remove',
  async (
    id: Client['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Client> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientServices.remove(id, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const getAllClientEmployees = createAsyncThunk(
  'client/getAllEmployees',
  async (
    id: Client['id'],
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Client> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await clientServices.getAllClientEmployees(id, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, getAllClientEmployees, post, put, remove } as const;
