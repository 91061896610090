import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';

export interface EmploymentTier {
  label: string;
  value: string;
}

export interface EmploymentTierProps extends Partial<AutoSearchSelectProps<EmploymentTier>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const employmentsTiers: EmploymentTier[] = [
  { value: 'I', label: 'I' },
  { value: 'II', label: 'II' },
];

const SelectEmploymentTier: React.FC<EmploymentTierProps> = ({
  id,
  name,
  placeholder = 'Select Tier',
  className = '',
  ...props
}) => (
  <Field
    {...props}
    component={SelectAutoSearch}
    id={id}
    name={name}
    className={`select-employment-tier ${className}`}
    placeholder={placeholder}
    data={employmentsTiers}
    isSearchable
  />
);

export default SelectEmploymentTier;
