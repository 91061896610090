import React from 'react';
import { Typography } from '@material-tailwind/react';

const TABLE_HEAD = ['Posts', 'Reacts', 'Comments', 'Status'];

const TABLE_ROWS = [
  {
    post: 'Post 51',
    reacts: '1k',
    comments: '33',
    status: 'Published',
  },
  {
    post: 'Post 50',
    reacts: '549',
    comments: '23',
    status: 'Published',
  },
  {
    post: 'Post 49',
    reacts: '233',
    comments: '10',
    status: 'Published',
  },
  {
    post: 'Post 48',
    reacts: '522',
    comments: '3',
    status: 'Unpublished',
  },
  {
    post: 'Post 47',
    reacts: '1.2k',
    comments: '78',
    status: 'Unpublished',
  },
];

const RecentPublish = () => (
  <div className="overflow-hidden rounded-b-xl bg-[#2c2c2e]">
    <table className="w-full min-w-max table-auto text-center">
      <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th key={head} className="border-b border-[#2c2b2a] bg-[#2c2c2e] p-4">
              <Typography variant="h6" color="white" className="font-bold leading-none">
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {TABLE_ROWS.map(({ post, reacts, comments, status }) => (
          <tr key={post} className="odd:bg-[#323235] text-center">
            <td className="p-4">
              <Typography variant="small" color="white" className="font-normal">
                {post}
              </Typography>
            </td>
            <td className="p-4">
              <Typography variant="small" color="white" className="font-normal">
                {reacts}
              </Typography>
            </td>
            <td className="p-4">
              <Typography variant="small" color="white" className="font-normal">
                {comments}
              </Typography>
            </td>
            <td className="p-4">
              <Typography
                as="a"
                variant="small"
                color="white"
                className={`font-medium px-3 py-1 rounded-full inline-block cursor-pointer hover:opacity-80 ${
                  status === 'Published' ? 'bg-[#f05825]' : 'bg-[#b53000]'
                }`}
              >
                {status}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default RecentPublish;
