import { useAppDispatch, useAppSelector } from '../../store/store';
import employmentRoleActions from '../../store/actions/employments/employmentRoleActions';

import {
  employmentRolesReducerActions,
  EmploymentRolesState,
} from '../../store/reducers/employments/employmentRolesReducer';
import EmploymentRoles from '../../store/constants/employments/roles/employment-role.interface';

const useEmploymentRoles = () => {
  const dispatch = useAppDispatch();
  const employmentRolesState: EmploymentRolesState = useAppSelector(
    (state) => state.employmentRoles,
  );

  const resetEmploymentRolesState = () => {
    dispatch(employmentRolesReducerActions.resetEmploymentRolesState());
  };

  const setEmploymentRolesState = (state: Partial<EmploymentRolesState>) => {
    dispatch(employmentRolesReducerActions.setEmploymentRolesState(state));
  };

  const get = async (employmentRoleId: EmploymentRoles['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentRoleActions.get(employmentRoleId));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentRoleActions.getAll({}));

  const post = async (employmentRoleData: Omit<EmploymentRoles, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentRoleActions.post(employmentRoleData));

  const put = async (employmentRole: Partial<EmploymentRoles>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentRoleActions.put(employmentRole));

  const remove = async (id: EmploymentRoles['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentRoleActions.remove(id));

  const findRoleById = (id: EmploymentRoles['id']) =>
    employmentRolesState.employmentRoles.find((employmentRole) => employmentRole.id === id);

  return {
    resetEmploymentRolesState,
    employmentRolesState,
    setEmploymentRolesState,
    get,
    getAll,
    post,
    put,
    remove,
    findRoleById,
  };
};

export default useEmploymentRoles;
