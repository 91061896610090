import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import ResultDocumentType from '../../../constants/my/healthTracker/result-document';
import resultDocumentServices, {
  GetAllResultDocumentsQueryParams,
} from '../../../services/my/healthTracker/resultDocumentsServices';

const get = createAsyncThunk(
  'resultDocument/get',
  async (
    id: ResultDocumentType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ResultDocumentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await resultDocumentServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'resultDocument/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllResultDocumentsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<ResultDocumentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await resultDocumentServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'resultDocument/post',
  async (
    data: Omit<ResultDocumentType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ResultDocumentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await resultDocumentServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'resultDocument/put',
  async (
    data: AtLeast<ResultDocumentType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ResultDocumentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await resultDocumentServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'resultDocument/remove',
  async (
    id: ResultDocumentType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<ResultDocumentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await resultDocumentServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
