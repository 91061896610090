import React from 'react';
import LabelledToggleSwitch from '../../../../molecules/LabelledToggleSwitch';
// eslint-disable-next-line import/no-cycle
import { SwitchStates } from '../payslip/PayslipToolOptions';

interface OptionsContentProps {
  switchStates: SwitchStates;
  handleParamsSwitchesChange: (id: string, checked: boolean) => void;
}

const PayrollViewHideColumns: React.FC<OptionsContentProps> = ({
  switchStates,
  handleParamsSwitchesChange,
}) => (
  <div className="payroll-view-hide-column">
    <div className="payroll-view-hide-column__switches">
      <LabelledToggleSwitch
        id="taxable"
        label="Taxable"
        isChecked={switchStates.taxable}
        onChange={(checked) => handleParamsSwitchesChange('taxable', checked)}
      />

      <LabelledToggleSwitch
        id="allowances"
        label="Allowances"
        isChecked={switchStates.allowances}
        onChange={(checked) => handleParamsSwitchesChange('allowances', checked)}
      />

      <LabelledToggleSwitch
        id="deductions"
        label="Deductions"
        isChecked={switchStates.deductions}
        onChange={(checked) => handleParamsSwitchesChange('deductions', checked)}
      />
    </div>
    <div className="flex self-end justify-center">
      <span className="text-xs">Hide columns</span>
    </div>
  </div>
);

export default PayrollViewHideColumns;
