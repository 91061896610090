import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChatBubble } from '../../../../../assets/images/icons/chat-bubble.svg';

interface UserMyPayslipConcernProps {
  emailFrom?: string;
  emailSubject?: string;
  emailBody?: string;
}

const UserMyPayslipConcern: React.FC<UserMyPayslipConcernProps> = ({
  emailFrom = undefined,
  emailSubject = undefined,
  emailBody = undefined,
}) => {
  const emailTo = 'payroll@awesomecx.com';
  const emailToQuery = `mailto:${emailTo}?subject=${encodeURIComponent(
    emailSubject || 'Payslip Concern',
  )}&body=${encodeURIComponent(emailBody || '')}&reply-to=${encodeURIComponent(emailFrom || '')}`;

  return (
    <div className="payslip-concern-container">
      <div className="shadow-sm payslip-concern-wrapper">
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-center">Payslip Concern</h2>
            <div className="mt-5 shadow-md payslip-emailto">
              <p className="text-center">
                {' '}
                Please send an e-mail to{' '}
                <a
                  className="email-link"
                  href="mailto:payroll@awesomecx.com?subject=Payslip%20Concern"
                >
                  payroll@awesomecx.com
                </a>{' '}
                for any queries.
              </p>
            </div>
            <div className="w-full flex justify-center payslip-button-wrapper">
              <Link
                to={emailToQuery}
                className="flex items-center gap-1 rounded-lg payslip-concern-button"
              >
                <ChatBubble className="h-4 w-auto payslip-icon-mirror" />
                <span>Payslip Concern</span>
              </Link>
            </div>
          </div>
          <div className="pt-3 administrator-payslip">
            <div className="text-center">
              <p>Subaldo, Issahmae D.</p>
              <p>Payroll Administrator</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMyPayslipConcern;
