import moment from 'moment';

const calculateGreetingTime = (hours: number): string => {
  if (hours >= 5 && hours < 12) {
    return 'Morning';
  }
  if (hours >= 12 && hours < 17) {
    return 'Afternoon';
  }
  return 'Evening';
};

const useGreetingTime = () => {
  const currentDateTime = new Date();
  const greetingTime = calculateGreetingTime(moment(currentDateTime).hour());
  return greetingTime;
};

export default useGreetingTime;
