import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useCompanyBranches from '../../../hooks/companies/branches/useCompanyBranches';
import CompanyBranch from '../../../store/constants/companies/branches/company-branch.interface';
import Company from '../../../store/constants/companies/company.interface';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';

export interface SelectCompanyBranchProps extends Partial<AutoSearchSelectProps<CompanyBranch>> {
  id: string;
  name: string;
  selectedCompany?: Company;
  setSelectedCompany?: (value?: Company) => void;
  shouldValidate?: boolean;
  placeholder?: string;
  className?: string;
}

const SelectCompanyBranch: React.FC<SelectCompanyBranchProps> = ({
  id,
  name,
  selectedCompany = undefined,
  setSelectedCompany = undefined,
  value = undefined,
  onChange = undefined,
  setFieldValue = undefined,
  shouldValidate = false,
  placeholder = 'Select Company Branch',
  className = '',
  ...props
}) => {
  const { companyBranchesState, getAll } = useCompanyBranches();

  useOnLoad(() => {
    getAll().then();
  });

  const companyBranches = companyBranchesState.companyBranches
    .slice()
    .filter((companyBranch) => !selectedCompany || selectedCompany.id === companyBranch?.company.id)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((companyBranch) => ({
      ...companyBranch,
      value: companyBranch.id,
      label: companyBranch.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-company-branch modal-select ${className}`}
      value={value}
      isSearchable
      placeholder={placeholder}
      data={companyBranches}
      onChange={(newValue?: CompanyBranch) => {
        if (onChange) onChange(newValue);

        if (setFieldValue) setFieldValue(name, newValue, shouldValidate);

        if (setSelectedCompany && newValue?.company)
          setSelectedCompany({
            ...newValue.company,
            value: newValue.company.id,
            label: newValue.company.name,
          } as Company);
      }}
    />
  );
};

export default SelectCompanyBranch;
