import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { Radio, RadioChangeEvent } from 'antd';
import { ReactComponent as PrintIcon } from '../../../../assets/images/icons/print.svg';
import PrintPayslipButton from './PrintPayslipButton';
import useMyPayslips from '../../../../hooks/my/payslip/useMyPayslips';
import SelectMonthRange, { SelectMonthRangeType } from '../../../atoms/dropdowns/SelectMonthRange';
import useAuth from '../../../../hooks/auths/useAuth';

interface PrintPayslipModalProps {
  monthRange: string;
  onClose?: () => void;
  className?: string;
}

type PrintFilter = '' | 'current' | 'multiple';

const PrintPayslipModal: React.FC<PrintPayslipModalProps> = ({
  monthRange,
  onClose = () => {},
  className = '',
}) => {
  const { myPayslipsState, printMyPayslips, resetPayslipToPrint } = useMyPayslips();
  const { loggedUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [printFilter, setPrintFilter] = useState<PrintFilter>('');
  const [selectedMonthRanges, setSelectedMonthRanges] = useState<SelectMonthRangeType[]>();
  const { employeeNumber } = loggedUser ?? {};

  const handleMonthRangeChange = useCallback((value: SelectMonthRangeType[]) => {
    setSelectedMonthRanges(value);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handlePrintFilterChange = useCallback((e: RadioChangeEvent) => {
    setPrintFilter(e.target.value as PrintFilter);
  }, []);

  useEffect(() => {
    if (printFilter === 'current') {
      printMyPayslips({
        employeeNumber,
        payPeriodFrom: [monthRange.split(',')[0]],
        payPeriodTo: [monthRange.split(',')[1]],
      });
    } else if (
      printFilter === 'multiple' &&
      selectedMonthRanges &&
      selectedMonthRanges.length > 0
    ) {
      const payPeriodFrom = selectedMonthRanges.map((range) => range.value.split(',')[0]);
      const payPeriodTo = selectedMonthRanges.map((range) => range.value.split(',')[1]);
      printMyPayslips({ employeeNumber, payPeriodFrom, payPeriodTo });
    } else {
      resetPayslipToPrint();
    }
    // eslint-disable-next-line
  }, [printFilter, selectedMonthRanges]);

  return (
    <>
      <button type="button" className={`print-payslip__button ${className}`} onClick={handleOpen}>
        <PrintIcon className="w-5 h-5" />
        Print payslip
      </button>
      <Dialog
        size="xs"
        open={isOpen}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="m-2 p-0 absolute flex top-0 right-0">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-3">
          <DialogHeader className="modal-header mt-1 p-0 text-gray-200">Print payslip</DialogHeader>
        </div>
        <DialogBody divider className="p-0 flex flex-col gap-5 pt-5">
          <Radio.Group
            className="flex flex-col gap-3"
            onChange={handlePrintFilterChange}
            value={printFilter}
          >
            <div>
              <Radio name="current" value="current" checked={printFilter === 'current'}>
                <div className="flex flex-col">
                  <p className="muiTypography--headline7">Current payslip</p>
                </div>
              </Radio>
              <p className="muiTypography--subtitle2 !text-gray-700 ml-6">({monthRange})</p>
            </div>

            <div>
              <Radio name="multiple" value="multiple" checked={printFilter === 'multiple'}>
                <div className="flex flex-col gap-3 ">
                  <p className="muiTypography--headline7">Custom</p>
                </div>
              </Radio>
              <div className={`${printFilter === 'multiple' ? 'block' : 'hidden'}`}>
                <SelectMonthRange
                  isDisabled={printFilter === 'current' || !printFilter}
                  id="payperiod_custom"
                  name="payperiod_custom"
                  isMulti
                  isClearable
                  isSearchable
                  className=" mt-3 text-white rounded-md text-[0.65rem]"
                  onChange={(value) => handleMonthRangeChange(value as SelectMonthRangeType[])}
                  onSelect={() => {}}
                  value={selectedMonthRanges as SelectMonthRangeType[]}
                />
              </div>
            </div>
          </Radio.Group>
          <PrintPayslipButton
            disabled={!printFilter}
            payslips={myPayslipsState.payslipToPrint ?? []}
          />
        </DialogBody>
      </Dialog>
    </>
  );
};

export default PrintPayslipModal;
