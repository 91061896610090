import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import PayslipConcern from '../../constants/my/payslip-concern.interface';

export type GetAllPayslipConcernsQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: PayslipConcern['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<PayslipConcern>> =>
  apiFetch<ApiBaseResponse<PayslipConcern>>(`payslip/concern/${id}`, { state })
    .then((response: ApiBaseResponse<PayslipConcern>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllPayslipConcernsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<PayslipConcern>> =>
  apiFetch<ApiGetAllBaseResponse<PayslipConcern>>(
    `payslip/concern?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<PayslipConcern>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<PayslipConcern, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<PayslipConcern> | void> =>
  apiFetch<ApiBaseResponse<PayslipConcern>>('payslip/concern', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<PayslipConcern>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: PayslipConcern,
  state: StateHeaders,
): Promise<ApiBaseResponse<PayslipConcern> | void> =>
  apiFetch<ApiBaseResponse<PayslipConcern>>(`payslip/concern/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<PayslipConcern>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<PayslipConcern, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<PayslipConcern> | void> =>
  apiFetch<ApiBaseResponse<PayslipConcern>>(`payslip/concern/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<PayslipConcern>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: PayslipConcern['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<PayslipConcern> | void> =>
  apiFetch<ApiBaseResponse<PayslipConcern>>(`payslip/concern/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<PayslipConcern>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
