import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import PayslipConcern from '../../../../../store/constants/my/payslip-concern.interface';
import { ReactComponent as DeleteSvg } from '../../../../../assets/images/icons/delete.svg';

export interface PayslipConcernListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: PayslipConcern;
  getDateTime: (date: Date) => string;
}

const PayslipConcernListRow: React.FC<PayslipConcernListRowProps> = ({
  className = '',
  data,
  getDateTime,
  ...props
}: PayslipConcernListRowProps) => {
  const handleDelete = () => {};

  return (
    <div
      {...props}
      className={`${className} payslip-concern-list-data table-list-data flex items-center text-left cursor-pointer`}
    >
      <div>{data.id}</div>
      <div>{data.Subject}</div>
      <div>{data.Contents}</div>
      <div>{data.Status}</div>
      <div>{data.Date && getDateTime(data.Date)}</div>
      <div className="flex gap-4 items-center justify-center">
        <Tooltip content="Remove" className="tooltip">
          <button className="flex gap-4" type="button" onClick={handleDelete}>
            <DeleteSvg className="w-6 h-6 trash-icon" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default PayslipConcernListRow;
