import React, { HTMLAttributes } from 'react';

export interface CardItems {
  title: string;
  name: string;
}

export interface CardItemsProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  items: CardItems[];
}

const CardFlexContent: React.FC<CardItemsProps> = ({
  className = '',
  title = undefined,
  items,
  ...otherProps
}) => (
  <div className={`card-flex-content card-row rounded-lg shadow-lg ${className}`} {...otherProps}>
    {title && <h3>{title}</h3>}
    <div className="grid grid-flow-row grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {items.map((item) => (
        <div key={item.title} className="flex flex-col">
          <span className="card-row-title">{item.title}</span>
          <span className="card-row-name">{item.name}</span>
        </div>
      ))}
    </div>
  </div>
);

export default CardFlexContent;
