import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Typography } from '@material-tailwind/react';
import { VariableSizeList } from 'react-window';
import useUserForm from '../../../hooks/users/useUserForm';
import Team from '../../../store/constants/teams/team.interface';
import EmploymentRole from '../../../store/constants/employments/roles/employment-role.interface';
import SelectTeamLeader from '../../atoms/dropdowns/SelectTeamLeader';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import SelectEmploymentRole from '../../atoms/dropdowns/SelectEmploymentRole';
import SelectTeam from '../../atoms/dropdowns/SelectTeam';
import TextWithIcons from '../TextWithIcons';
import UserEmploymentTeamRow from './Row/UserEmploymentTeamRow';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import UserEmploymentTeam from '../../../store/constants/employees/teams/employee-team.interface';
import SelectUserEmploymentTransferReason from '../../atoms/dropdowns/SelectUserEmploymentTransferReason';
import { EmployeeTeamTransferReason } from '../../../store/constants/employees/teams/employee-team-transfer-reason';
import defaultValue from '../../../store/constants/utils/default-value.const';
import { UserFormState } from '../../../store/reducers/users/userFormReducer';
import useUserEmployments from '../../../hooks/users/useUserEmployments';

const initialFormValue: Partial<UserEmploymentTeam> = {
  team: undefined,
  employmentRole: undefined,
  teamLeader: undefined,
  dateStart: defaultValue.dateToday,
  transferReason: undefined,
};

interface UserEmploymentTeamAssignmentFormProps {
  submitButton: React.RefObject<HTMLButtonElement>;
}

const UserEmploymentTeamAssignmentForm: React.FC<UserEmploymentTeamAssignmentFormProps> = ({
  submitButton,
}) => {
  const {
    userFormState: { user },
    setUserFormState,
    handlePrev,
    handleNext,
  } = useUserForm();

  const {
    userEmploymentsStates: { transferReasons },
  } = useUserEmployments();

  const [formError, setFormError] = useState('');
  const [selectedTeamLeader, setSelectedTeamLeader] = useState<UserEmployment | undefined>();
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
  const [selectedEmploymentRole, setSelectedEmploymentRole] = useState<
    EmploymentRole | undefined
  >();
  const [selectedTeamTransferReason, setSelectedTeamTransferReason] =
    useState<EmployeeTeamTransferReason>();

  const [selectedUserEmploymentTeam, setSelectedUserEmploymentTeam] = useState<
    UserEmploymentTeam | undefined
  >();

  const handleResetForm = () => {
    setSelectedTeam(undefined);
    setSelectedEmploymentRole(undefined);
    setSelectedTeamLeader(undefined);
    setSelectedTeamTransferReason(undefined);
    setSelectedUserEmploymentTeam(undefined);
  };

  const handleSubmit = (values: Partial<UserEmploymentTeam>) => {
    setFormError('');
    // console.log('VALUES!!!', values);

    const userEmploymentTeamId = selectedUserEmploymentTeam?.id;
    // console.log('TEAMS', userEmploymentTeamId);

    const countUserTeam = user.employment?.teams.length;
    let tempAssignTeamId = 0;
    if (countUserTeam) {
      const userTeam = user.employment?.teams.slice(-1);
      if (userTeam?.length) {
        tempAssignTeamId = userTeam[0]?.id;
      }
    }

    if (user?.employment?.teams) {
      setSelectedUserEmploymentTeam(values as UserEmploymentTeam);
      const teams = user.employment.teams.filter((team) => team.id !== userEmploymentTeamId);
      // console.log('TEAMS', teams);
      teams.push({
        id: tempAssignTeamId + 1,
        team: selectedTeam,
        employmentRole: selectedEmploymentRole,
        teamLeader: selectedTeamLeader,
        transferReason: selectedTeamTransferReason,
        transferReasonId: selectedTeamTransferReason?.id,
        dateStart: values.dateStart,
      });
      setUserFormState({
        user: {
          ...user,
          employment: {
            ...user.employment,
            teams,
          },
        },
      });
    } else {
      setUserFormState({
        user: {
          ...user,
          employment: {
            ...user.employment,
            teams: [...(user.employment?.teams ?? [])].concat([
              {
                id: tempAssignTeamId + 1,
                team: selectedTeam,
                employmentRole: selectedEmploymentRole,
                teamLeader: selectedTeamLeader,
                transferReason: selectedTeamTransferReason,
                transferReasonId: selectedTeamTransferReason?.id,
                dateEnd: values.dateEnd,
                dateStart: values.dateStart,
              },
            ] as UserEmploymentTeam[]),
          },
        },
      } as UserFormState);
    }

    // handleResetForm();
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 80; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  const onEditClick = (data: UserEmploymentTeam) => {
    if (user?.employment?.teams) {
      // const teams = user.employment.teams.filter((team) => team.id !== data.id);
      setSelectedUserEmploymentTeam(data);
    }

    setSelectedTeam({
      ...data?.team,
      value: data?.team?.id,
      label: data?.team?.name,
    } as Team);

    setSelectedEmploymentRole({
      ...data?.employmentRole,
      value: data?.employmentRole?.id,
      label: data?.employmentRole?.name,
    } as EmploymentRole);

    setSelectedTeamLeader({
      ...data?.teamLeader,
      value: data?.teamLeader?.employeeNumber,
      label: data.teamLeader?.userInformation.fullName,
    } as UserEmployment);

    const userEmploymentTransferReasons = transferReasons
      .filter((transferReason) => transferReason.id === data.transferReasonId)
      .map((transferReason: EmployeeTeamTransferReason) => ({
        ...transferReason,
        value: transferReason.id,
        label: transferReason.name,
      }));

    setSelectedTeamTransferReason({
      ...userEmploymentTransferReasons[0],
    });

    /*
    if (user?.employment?.teams) {
      const teams = user.employment.teams.filter((team) => team.id !== data.id);
      teams.push(data);

      setUserFormState({
        user: {
          ...user,
          employment: {
            ...user.employment,
            teams,
          },
        },
      });
    }
    */
  };

  const onDeleteClick = async (data: UserEmploymentTeam) => {
    if (user?.employment?.teams) {
      const teams = user.employment.teams.filter((team) => team.id !== data.id);
      setUserFormState({
        user: {
          ...user,
          employment: {
            ...user.employment,
            teams,
          },
        },
      });
    }
  };

  const handlePreSubmit = () => {
    setUserFormState({ nextStep: undefined });

    if (submitButton.current) submitButton.current.click();
  };

  return (
    <div className="w-full">
      <div className="mb-5">
        <Typography variant="h4" className="text-[#f05825] flex gap-2 mb-5">
          <span className="text-gray-200">Team</span>
          Assignment
        </Typography>
      </div>
      <div className="flex justify-between p-2 mx-auto form-section">
        {/* FIRST SECTION */}
        <div className="w-full font-bold first-section text-[#f05825]">
          <div className="font-bold first-section text-[#f05825] mr-10 ">
            <div className="grid grid-cols-7 p-2 border-b-2 header-items">
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Team</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Role In The Team</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Team Leader</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Date Start</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Date End</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Reason</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-7">Actions</div>
              </TextWithIcons>
            </div>
            {user?.employment?.teams ? (
              <VariableSizeList
                itemCount={user.employment.teams.length}
                itemSize={getItemSize}
                height={window.innerHeight - 400}
                width="100%"
                className="no-scrollbars"
              >
                {({ index, style }) => (
                  <div>
                    {user?.employment?.teams && (
                      <UserEmploymentTeamRow
                        data={user.employment.teams[index]}
                        style={style}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                      />
                    )}
                  </div>
                )}
              </VariableSizeList>
            ) : (
              <EmptyListMessage />
            )}
          </div>
        </div>

        {/* END FIRST SECTION */}

        {/* SECOND SECTION */}
        {formError && <div className="form-error">{formError}</div>}
        <Formik initialValues={initialFormValue} onSubmit={handleSubmit}>
          <Form className="user-employment-team-assignment-form p-4 mt-5 mb-5 border border-[#2e2d2c] bg-[#252423] border-solid rounded-xl shadow-2xl w-1/3">
            <div className="w-full first-section">
              <div className="mb-8">
                <div className="w-full mb-2">
                  <label htmlFor="team" className="block mt-2 text-xs text-gray-300 uppercase">
                    Team
                  </label>
                </div>
                <div className="w-full">
                  <SelectTeam
                    id="team"
                    name="team"
                    value={selectedTeam}
                    onChange={(value) => setSelectedTeam(value as Team)}
                    required
                  />
                </div>
                <ErrorMessage name="team" component="div" />
              </div>

              <div className="mb-8">
                <div className="w-full mb-2">
                  <label
                    htmlFor="employmentRole"
                    className="block mt-2 text-xs text-gray-300 uppercase"
                  >
                    Employee Role In The Team
                  </label>
                </div>
                <div className="flex-grow">
                  <SelectEmploymentRole
                    id="employmentRole"
                    name="employmentRole"
                    value={selectedEmploymentRole}
                    onChange={(value) => setSelectedEmploymentRole(value as EmploymentRole)}
                  />
                </div>
                <ErrorMessage name="employmentRole" component="div" />
              </div>

              <div className="mb-8">
                <div className="w-full mb-2">
                  <label
                    htmlFor="teamLeader"
                    className="block mt-2 text-xs text-gray-300 uppercase"
                  >
                    Supervisor In The Team
                  </label>
                </div>
                <div className="flex-grow">
                  <SelectTeamLeader
                    id="teamLeader"
                    name="teamLeader"
                    value={selectedTeamLeader}
                    onChange={(value) => setSelectedTeamLeader(value as UserEmployment)}
                    isSearchable
                    isClearable
                  />
                </div>
                <ErrorMessage name="teamLeader" component="div" />
              </div>

              <div className="mb-8">
                <div className="w-full mb-2">
                  <label htmlFor="dateStart" className="block mt-2 text-xs text-gray-300 uppercase">
                    Date Start
                  </label>
                </div>
                <div className="flex-grow">
                  <Field
                    type="date"
                    id="dateStart"
                    name="dateStart"
                    placeholder="Date Start"
                    className="w-full p-2 bg-[#393836] border border-[#2e2d2c] rounded-lg shadow-lg focus:border-t-blue-500 focus:border-blue-500 ring-4 ring-transparent focus:ring-blue-500/20 shadow-blue-gray-900/5 placeholder:text-blue-gray-600 text-white"
                  />
                </div>
                <ErrorMessage name="dateStart" component="div" />
              </div>

              <div className="mb-8">
                <div className="w-full mb-2">
                  <label
                    htmlFor="teamLeaderEmployeeNumber"
                    className="block mt-2 text-xs text-gray-300 uppercase"
                  >
                    Transfer Reason
                  </label>
                </div>
                <div className="flex-grow">
                  <SelectUserEmploymentTransferReason
                    id="transferReason"
                    name="transferReason"
                    value={selectedTeamTransferReason}
                    onChange={(value) =>
                      setSelectedTeamTransferReason(value as EmployeeTeamTransferReason)
                    }
                  />
                </div>
                <ErrorMessage name="transferReasonId" component="div" />
              </div>

              <div className="flex justify-between w-full">
                <button
                  type="submit"
                  className="text-gray-200 text-sm bg-[#f05825] p-2 px-4 rounded-lg hover:bg-[#f01222] hover:shadow-lg"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleResetForm}
                  className="text-[#f05825] text-sm p-2 px-4 rounded-lg hover:bg-[#2c2b2a] hover:shadow-lg"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </Formik>
        {/* END SECOND SECTION */}
      </div>

      <div className="flex justify-between w-full p-5">
        <button
          type="button"
          onClick={handlePrev}
          className="rounded-lg shadow-lg muiButton--primary hover:bg-orange-500"
        >
          Previous
        </button>
        <button
          type="button"
          onClick={handlePreSubmit}
          className="flex items-center mr-2 textButton"
        >
          Go to summary
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
          </svg>
        </button>
      </div>
      <button ref={submitButton} onClick={handleNext} type="button" className="invisible">
        Next
      </button>
    </div>
  );
};

export default UserEmploymentTeamAssignmentForm;
