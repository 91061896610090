import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Incentive from '../../constants/my/incentives.interface';
import incentiveServices, {
  GetAllIncentivesQueryParams,
} from '../../services/my/incentivesServices';

const get = createAsyncThunk(
  'incentive/get',
  async (
    id: Incentive['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Incentive> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await incentiveServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'incentive/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllIncentivesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Incentive> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await incentiveServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'incentive/post',
  async (
    data: Omit<Incentive, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Incentive> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await incentiveServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'incentive/put',
  async (
    data: AtLeast<Incentive, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Incentive> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await incentiveServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'incentive/remove',
  async (
    id: Incentive['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Incentive> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await incentiveServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
