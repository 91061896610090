import { useAppDispatch, useAppSelector } from '../../../store/store';
import resultDocumentActions from '../../../store/actions/my/healthTracker/resultDocumentActions';
import {
  ResultDocumentsState,
  resultDocumentsReducerActions,
} from '../../../store/reducers/my/healthTracker/resultDocumentsReducer';
import ResultDocumentType from '../../../store/constants/my/healthTracker/result-document';

const useResultDocuments = () => {
  const dispatch = useAppDispatch();
  const resultDocumentsState: ResultDocumentsState = useAppSelector(
    (state) => state.resultDocuments,
  );

  const resetResultDocumentState = () => {
    dispatch(resultDocumentsReducerActions.resetResultDocumentsState());
  };

  const setResultDocumentState = (state: Partial<ResultDocumentsState>) => {
    dispatch(resultDocumentsReducerActions.setResultDocumentsState(state));
  };

  const get = async (id: ResultDocumentType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(resultDocumentActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(resultDocumentActions.getAll({}));

  const post = async (data: Omit<ResultDocumentType, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(resultDocumentActions.post(data));

  const put = async (data: Partial<ResultDocumentType>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(resultDocumentActions.put(data));

  const remove = async (id: ResultDocumentType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(resultDocumentActions.remove(id));

  return {
    resetResultDocumentState,
    resultDocumentsState,
    setResultDocumentState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useResultDocuments;
