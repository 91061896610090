import { useAppDispatch, useAppSelector } from '../../store/store';
import leaveActions from '../../store/actions/leave/leaveActions';
import { leavesReducerActions, LeavesState } from '../../store/reducers/leaves/leavesReducer';
import LeaveApplication from '../../store/constants/leaves/leave-application.interface';
import { GetAllLeavesQueryParams } from '../../store/services/leave/leaveServices';

const useLeaves = () => {
  const dispatch = useAppDispatch();
  const leavesState: LeavesState = useAppSelector((state) => state.leaves);

  const resetLeaveState = () => {
    dispatch(leavesReducerActions.resetLeavesState());
  };

  const setLeaveState = (state: Partial<LeavesState>) => {
    dispatch(leavesReducerActions.setLeavesState(state));
  };

  const get = async (leaveId: LeaveApplication['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(leaveActions.get(leaveId));

  const getAll = async (params = {} as GetAllLeavesQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(leaveActions.getAll(params));

  const post = async (leaveData: Omit<LeaveApplication, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(leaveActions.post(leaveData));

  const put = async (leave: Partial<LeaveApplication>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(leaveActions.put(leave));

  const remove = async (id: LeaveApplication['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(leaveActions.remove(id));

  const approve = async (leave: Partial<LeaveApplication>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(leaveActions.approve(leave));

  const deny = async (leave: Partial<LeaveApplication>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(leaveActions.deny(leave));

  return {
    resetLeaveState,
    leavesState,
    setLeaveState,
    get,
    getAll,
    post,
    put,
    remove,
    approve,
    deny,
  };
};

export default useLeaves;
