import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-tailwind/react';
import { ExpectedAttendanceTime } from '../../../../../store/reducers/attendances/attendancesReducer';
import UserOvertimeModal from './UserOvertimeModal';
import useTheme from '../../../../../hooks/utils/useTheme';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';
// import useAttendanceUndertime from '../../../../hooks/attendances/useAttendanceUndertime';

interface UserTimeInProps {
  expectedTimeIn?: ExpectedAttendanceTime;
}

const UserOverTime: React.FC<UserTimeInProps> = () => {
  // const { hasUndertime } = useAttendanceUndertime();
  const { theme } = useTheme();
  const [showOvertime, setShowOvertime] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleCloseModal = () => {
    setOpen(false);
    setShowOvertime(false);
  };

  const handleShowOvertimeModal = () => {
    setOpen(true);
    setShowOvertime(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={handleShowOvertimeModal}
        className="user-attendance-action__button"
        // disabled={hasUndertime}
      >
        <span className="user-attendance-action-button__heading-text">Overtime</span>
      </button>

      <Dialog
        open={open}
        size="xs"
        handler={handleCloseModal}
        className={`user-attendance-action__prompt-container ${theme}`}
      >
        <div className="close-prompt-button">
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={() => {
              setOpen(false);
              setShowOvertime(false);
            }}
          >
            <Close className="h-5 w-5" />
          </IconButton>
        </div>
        {showOvertime && (
          <UserOvertimeModal
            handleOnClose={() => {
              setOpen(false);
              setShowOvertime(false);
              setShowOvertime(false);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default UserOverTime;
