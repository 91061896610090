import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import Team from '../../constants/teams/team.interface';
import Employee from '../../constants/employees/employee.interface';
import TeamActions from '../../actions/teams/teamActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface TeamsState {
  teams: Team[];
  teamEmployees: Employee[];
}

const initialState: TeamsState = {
  teams: [],
  teamEmployees: [],
};

const getTeamData = (team: Partial<Team>) => ({
  ...team,
});

const upsertItems = (teams: Team[], ...data: Team[]) => {
  const dataObject: { [id: number]: Team } = {};
  teams.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((team) => {
    if (!dataObject[team.id]) {
      dataObject[team.id] = team;
    } else if (dataObject[team.id]) {
      dataObject[team.id] = { ...dataObject[team.id], ...team };
    }
  });

  return Object.values(dataObject);
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    resetTeamsState: () => ({
      ...initialState,
    }),
    setTeamsState: (state, action: PayloadAction<Partial<TeamsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertTeam: (state, action: PayloadAction<Partial<Team>>) => {
      const data: Team = getTeamData(action.payload) as Team;

      if (typeof data.id === 'undefined') return;

      state.teams = upsertItems(state.teams, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(TeamActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Team>;
      consoleLog('TEAMS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.teams = upsertItems(state.teams, data);
      }
    });
    builder.addCase(TeamActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('TEAMS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Team>;

      if (status === 503) return;

      if (data) {
        state.teams = data;
      }
    });
    builder.addCase(TeamActions.get.fulfilled, (state, { payload }) => {
      consoleLog('TEAMS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Team>;

      if (typeof data.id === 'undefined') return;
      state.teams = upsertItems(state.teams, data);
    });
    builder.addCase(TeamActions.put.fulfilled, (state, { payload }) => {
      consoleLog('TEAMS REDUCER', 'PUT', payload);

      const { data, status } = payload as ApiBaseResponse<Team>;

      if (status !== StatusCodes.OK) return;
      state.teams = upsertItems(state.teams, data);
    });
    builder.addCase(TeamActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('TEAMS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Team>;

      if (typeof data.id === 'undefined') return;
      state.teams = state.teams.filter((team) => team.id !== data.id);
    });
    builder.addCase(TeamActions.getAllTeamEmployees.fulfilled, (state, { payload }) => {
      consoleLog('TEAMS REDUCER', 'GET-ALL-TEAM-EMPLOYEES', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Employee>;

      if (status === 503) return;

      if (data) {
        state.teamEmployees = data;
      }
    });
    builder.addCase(TeamActions.updateTeamLeader.fulfilled, (state, { payload }) => {
      consoleLog('TEAMS REDUCER', 'UPDATE-TEAM-LEADER', payload);

      const { data } = payload as ApiBaseResponse<Team>;

      if (typeof data.id === 'undefined') return;
      state.teams = upsertItems(state.teams, data);
    });
    builder.addCase(TeamActions.removeDepartmentFromTeam.fulfilled, (state, { payload }) => {
      consoleLog('TEAMS REDUCER', 'REMOVE-TEAM-DEPARTMENT', payload);

      const { data } = payload as ApiBaseResponse<Team>;

      if (typeof data.id === 'undefined') return;
      state.teams = upsertItems(state.teams, data);
    });
  },
});

export const teamsReducerActions = teamsSlice.actions;
export default teamsSlice.reducer;
