import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentPositionRate from '../../constants/employments/positions/employment-position-rate.interface';
import positionRatesServices, {
  GetAllPositionRatesQueryParams,
} from '../../services/positions/positionRateServices';

const get = createAsyncThunk(
  'positionRate/get',
  async (
    id: EmploymentPositionRate['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPositionRate> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionRatesServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'positionRate/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllPositionRatesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmploymentPositionRate> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionRatesServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'positionRate/post',
  async (
    data: Omit<EmploymentPositionRate, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPositionRate> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionRatesServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'positionRate/put',
  async (
    data: AtLeast<EmploymentPositionRate, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPositionRate> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionRatesServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'positionRate/remove',
  async (
    id: EmploymentPositionRate['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPositionRate> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionRatesServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
