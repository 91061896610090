import React from 'react';
import { ReactComponent as Awesomecx } from '../../assets/images/awesomecx_animation.svg';

const LoadingScreen = () => (
  <div className="flex items-center justify-center h-screen">
    <Awesomecx className="w-80 h-80" />
  </div>
);

export default LoadingScreen;
