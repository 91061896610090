import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import User from '../../../constants/users/user.interface';
import UserEmploymentPosition from '../../../constants/employees/positions/employee-position.interface';
import userEmploymentPositionsServices, {
  GetAllUserEmploymentPositionsQueryParams,
} from '../../../services/users/employments/userEmploymentPositionsServices';

const get = createAsyncThunk(
  'userEmploymentPosition/get',
  async (
    id: UserEmploymentPosition['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'userEmploymentPosition/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllUserEmploymentPositionsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<UserEmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAllUsersByEmploymentPosition = createAsyncThunk(
  'userEmploymentPosition/getAllUsersByEmploymentPosition',
  async (
    positionId: UserEmploymentPosition['employmentPositionId'],
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<User> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.getAllUsersByEmploymentPosition(
        positionId,
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getUsers = createAsyncThunk(
  'userEmploymentPosition/getUsers',
  async (
    id: UserEmploymentPosition['employeeNumber'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.getUsers(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'userEmploymentPosition/post',
  async (
    data: Omit<UserEmploymentPosition, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'userEmploymentPosition/put',
  async (
    data: AtLeast<UserEmploymentPosition, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'userEmploymentPosition/remove',
  async (
    id: UserEmploymentPosition['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const updateEmployeePosition = createAsyncThunk(
  'userEmploymentPosition/updateEmployeePosition',
  async (
    data: AtLeast<UserEmploymentPosition, 'employeeNumber'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentPositionsServices.updateEmployeePosition(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default {
  get,
  getAll,
  getAllUsersByEmploymentPosition,
  updateEmployeePosition,
  getUsers,
  post,
  put,
  remove,
} as const;
