import React from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: 'Jan',
    resigned: 0,
    hired: 0,
  },
  {
    name: 'March',
    resigned: 10,
    hired: 12,
  },
  {
    name: 'Jun',
    resigned: 5,
    hired: 12,
  },
  {
    name: 'Aug',
    resigned: 6,
    hired: 16,
  },
  {
    name: 'Oct',
    resigned: 9,
    hired: 20,
  },
  {
    name: 'Dec',
    resigned: 0,
    hired: 8,
  },
  {
    name: 'Jan',
    resigned: 0,
    hired: 5,
  },
];

const AnalyticsCard = () => (
  <div className="w-full h-full flex items-center justify-center">
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 50, // adjusted right margin to match left margin
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="hired" stroke="#82ca9d" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="resigned" stroke="#f05825" />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default AnalyticsCard;
