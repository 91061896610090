import React from 'react';
import moment from 'moment';
import { Tooltip } from '@material-tailwind/react';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import useOvertimes from '../../../../hooks/attendances/useOvertimes';
import DeleteConfirmationBanner from '../../../molecules/DeleteConfirmationBanner';
import formatTimeDuration from '../../../../utils/formatTimeDuration';
import { ReactComponent as Document } from '../../../../assets/images/icons/document-text.svg';

interface OvertimesListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: EmployeeOvertime;
  onDeleteClick: (leave: EmployeeOvertime) => Promise<unknown>;
}

const OvertimesListRow: React.FC<OvertimesListRowProps> = ({
  className = '',
  data,
  onDeleteClick,
  ...props
}: OvertimesListRowProps) => {
  const { overtimeState } = useOvertimes();
  const [hours, minutes, seconds] = data.duration
    ? data.duration.split(':').map(Number)
    : [0, 0, 0];

  const overtimeTimeInDate = moment(data.overtimeTimeIn as Date).format('YYYY-MM-DD');
  const overtimeTimeInTime = moment(data.overtimeTimeIn as Date).format('hh:mm A');
  const overtimeTimeOutTime = moment(data.overtimeTimeOut as Date).format('hh:mm A');

  const overtimeTypeId =
    typeof data.overtimeType === 'string' ? parseInt(data.overtimeType, 10) : undefined;
  const overtimeType =
    overtimeTypeId !== undefined
      ? overtimeState?.overtimeType.find((overtime) => overtime.id === overtimeTypeId)
      : undefined;

  let labelMessage;

  if (data.overtimeTimeOut) {
    labelMessage = `Your Pending Overtime\n${overtimeTimeInDate}, ${overtimeTimeInTime} - ${overtimeTimeOutTime}`;
  } else {
    labelMessage = `Your Pending Overtime\n${overtimeTimeInDate}, ${overtimeTimeInTime}`;
  }

  const deleteMessage = {
    pending: 'Pending Request...',
    success: `Your Overtime ${overtimeTimeInDate} ${overtimeTimeInTime}-${overtimeTimeOutTime} was successfully deleted.`,
    error: `Error has occurred on deleting Overtime ${overtimeTimeInDate} ${overtimeTimeInTime} - ${overtimeTimeOutTime}.`,
  };

  return (
    <div
      {...props}
      className={`${className} flex items-center p-2 overtimes-list-data table-list-data`}
    >
      <div>{overtimeTimeInDate}</div>
      <div>{overtimeTimeInTime}</div>
      <div>{data.overtimeTimeOut ? overtimeTimeOutTime : ''}</div>
      <div>
        {formatTimeDuration({
          hours,
          minutes,
          seconds,
        })}
      </div>
      <div>{overtimeType?.overtimeName}</div>
      <div className="w-full h-full flex justify-center">
        {data.approvedBy ? (
          <span className="flex justify-center items-center my-overtime-approved">Approved</span>
        ) : (
          <span className="flex justify-center items-center my-overtime-pending">Pending</span>
        )}
      </div>
      <div className="space-x-2 action-buttons justify-self-center">
        <Tooltip content="Details" className="tooltip shadow-sm">
          <button className="textButton" type="button">
            <Document className="w-6 h-auto" />
          </button>
        </Tooltip>
        {!data.approvedBy && (
          <DeleteConfirmationBanner
            data={data}
            className="w-6 h-6"
            message={deleteMessage}
            label={labelMessage}
            onDeleteConfirm={onDeleteClick}
          />
        )}
      </div>
    </div>
  );
};

export default OvertimesListRow;
