import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Holiday from '../../constants/holidays/holiday.interface';
import holidayActions from '../../actions/holidays/holidayActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface HolidaysState {
  holidays: Holiday[];
}

const initialState: HolidaysState = {
  holidays: [],
};

const getHolidayData = (holiday: Partial<Holiday>) => ({
  ...holiday,
});

const upsertItems = (holidays: Holiday[], ...data: Holiday[]) => {
  const dataObject: { [id: number]: Holiday } = {};
  holidays.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((holiday) => {
    if (!dataObject[holiday.id]) {
      dataObject[holiday.id] = holiday;
    } else if (dataObject[holiday.id]) {
      dataObject[holiday.id] = { ...dataObject[holiday.id], ...holiday };
    }
  });

  return Object.values(dataObject);
};

const holidaysSlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    resetHolidaysState: () => ({
      ...initialState,
    }),
    setHolidaysState: (state, action: PayloadAction<Partial<HolidaysState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertHoliday: (state, action: PayloadAction<Partial<Holiday>>) => {
      const data: Holiday = getHolidayData(action.payload) as Holiday;

      if (typeof data.id === 'undefined') return;

      state.holidays = upsertItems(state.holidays, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(holidayActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Holiday>;
      consoleLog('HOLIDAYS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.holidays = upsertItems(state.holidays, data);
      }
    });
    builder.addCase(holidayActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Holiday>;

      if (status === 503) return;

      if (data) {
        state.holidays = data;
      }
    });
    builder.addCase(holidayActions.get.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Holiday>;

      if (typeof data.id === 'undefined') return;
      state.holidays = upsertItems(state.holidays, data);
    });
    builder.addCase(holidayActions.put.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<Holiday>;

      if (typeof data.id === 'undefined') return;
      state.holidays = upsertItems(state.holidays, data);
    });
    builder.addCase(holidayActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('HOLIDAYS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Holiday>;

      if (typeof data.id === 'undefined') return;
      state.holidays = state.holidays.filter((holiday) => holiday.id !== data.id);
    });
  },
});

export const holidaysReducerActions = holidaysSlice.actions;
export default holidaysSlice.reducer;
