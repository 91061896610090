import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import HolidayType from '../../constants/holidays/holiday-type.interface';
import holidayTypeServices, {
  GetAllHolidayTypesQueryParams,
} from '../../services/holidays/holidayTypeServices';

const get = createAsyncThunk(
  'holidayType/get',
  async (
    id: HolidayType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HolidayType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayTypeServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'holidayType/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllHolidayTypesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<HolidayType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayTypeServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'holidayType/post',
  async (
    data: Omit<HolidayType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HolidayType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayTypeServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'holidayType/put',
  async (
    data: AtLeast<HolidayType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HolidayType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayTypeServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'holidayType/remove',
  async (
    id: HolidayType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HolidayType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await holidayTypeServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
