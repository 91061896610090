import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import CalendarActions from '../../actions/calendar/calendarActions';
import ApigetAllCalendarEventsBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import EmployeeCalendar from '../../constants/calendar/employee-calendar.interface';

export interface CalendarsStateType {
  calendar: EmployeeCalendar[];
  currentMonthView?: string;
}

const initialState: CalendarsStateType = {
  calendar: [],
  currentMonthView: '',
};

const calendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {
    resetCalendarsState: () => ({
      ...initialState,
    }),
    setCalendarsState: (state, action: PayloadAction<Partial<CalendarsStateType>>) => ({
      ...state,
      ...action.payload,
    }),
    setCurrentMonthView: (state, action: PayloadAction<string>) => {
      state.currentMonthView = action.payload;
    },
    resetCurrentMonthView: (state) => {
      state.currentMonthView = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CalendarActions.getAllCalendarEvents.fulfilled, (state, { payload }) => {
      consoleLog('Calendar REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApigetAllCalendarEventsBaseResponse<EmployeeCalendar>;

      if (status !== StatusCodes.OK) return;

      if (data) {
        state.calendar = data;
      }
    });
  },
});

export const calendarsReducerActions = calendarsSlice.actions;
export default calendarsSlice.reducer;
