import { useAppDispatch } from '../../store/store';
import clientManagersActions from '../../store/actions/clients/clientManagerActions';

import ClientManager from '../../store/constants/clients/client-manager.interface';
import Client from '../../store/constants/clients/client.interface';

const useClientManagers = () => {
  const dispatch = useAppDispatch();

  const get = async (data: ClientManager) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientManagersActions.get(data));

  const getAll = async (data: Client) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientManagersActions.getAll({ data }));

  const post = async (data: Omit<ClientManager, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientManagersActions.post(data));

  const put = async (data: Partial<ClientManager>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientManagersActions.put(data));

  const remove = async (data: ClientManager) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(clientManagersActions.remove(data));

  return {
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useClientManagers;
