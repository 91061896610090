import React from 'react';
import { ListItem, ListItemPrefix } from '@material-tailwind/react';

const AdminFeedsCard = () => (
  <div className="p-2 grid grid-cols-1 gap-2">
    <ListItem
      className="flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825]"
      onClick={() => {}}
    >
      <ListItemPrefix>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="sidebar-icon"
        >
          <path
            fillRule="evenodd"
            d="M1 11.27c0-.246.033-.492.099-.73l1.523-5.521A2.75 2.75 0 0 1 5.273 3h9.454a2.75 2.75 0 0 1 2.651 2.019l1.523 5.52c.066.239.099.485.099.732V15a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3.73Zm3.068-5.852A1.25 1.25 0 0 1 5.273 4.5h9.454a1.25 1.25 0 0 1 1.205.918l1.523 5.52c.006.02.01.041.015.062H14a1 1 0 0 0-.86.49l-.606 1.02a1 1 0 0 1-.86.49H8.236a1 1 0 0 1-.894-.553l-.448-.894A1 1 0 0 0 6 11H2.53l.015-.062 1.523-5.52Z"
            clipRule="evenodd"
          />
        </svg>
      </ListItemPrefix>
      <span className="sidebar-text">New Notification</span>
      <span className="ml-auto sidebar-text opacity-50">1 weeks ago</span>
    </ListItem>
    <ListItem
      className="flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825]"
      onClick={() => {}}
    >
      <ListItemPrefix>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="sidebar-icon"
        >
          <path
            fillRule="evenodd"
            d="M1 11.27c0-.246.033-.492.099-.73l1.523-5.521A2.75 2.75 0 0 1 5.273 3h9.454a2.75 2.75 0 0 1 2.651 2.019l1.523 5.52c.066.239.099.485.099.732V15a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3.73Zm3.068-5.852A1.25 1.25 0 0 1 5.273 4.5h9.454a1.25 1.25 0 0 1 1.205.918l1.523 5.52c.006.02.01.041.015.062H14a1 1 0 0 0-.86.49l-.606 1.02a1 1 0 0 1-.86.49H8.236a1 1 0 0 1-.894-.553l-.448-.894A1 1 0 0 0 6 11H2.53l.015-.062 1.523-5.52Z"
            clipRule="evenodd"
          />
        </svg>
      </ListItemPrefix>
      <span className="sidebar-text">New Notification</span>
      <span className="ml-auto sidebar-text opacity-50">2 weeks ago</span>
    </ListItem>
    <ListItem
      className="flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825]"
      onClick={() => {}}
    >
      <ListItemPrefix>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="sidebar-icon"
        >
          <path
            fillRule="evenodd"
            d="M1 11.27c0-.246.033-.492.099-.73l1.523-5.521A2.75 2.75 0 0 1 5.273 3h9.454a2.75 2.75 0 0 1 2.651 2.019l1.523 5.52c.066.239.099.485.099.732V15a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3.73Zm3.068-5.852A1.25 1.25 0 0 1 5.273 4.5h9.454a1.25 1.25 0 0 1 1.205.918l1.523 5.52c.006.02.01.041.015.062H14a1 1 0 0 0-.86.49l-.606 1.02a1 1 0 0 1-.86.49H8.236a1 1 0 0 1-.894-.553l-.448-.894A1 1 0 0 0 6 11H2.53l.015-.062 1.523-5.52Z"
            clipRule="evenodd"
          />
        </svg>
      </ListItemPrefix>
      <span className="sidebar-text">New Notification</span>
      <span className="ml-auto sidebar-text opacity-50">1 years ago</span>
    </ListItem>
  </div>
);

export default AdminFeedsCard;
