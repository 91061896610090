import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import LeaveActions from '../../actions/leave/leaveActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import EmploymentScheduleType from '../../constants/employments/schedules/employment-schedule-type.interface';
import LeaveNature from '../../constants/leaves/leave-nature.interface';
import AuthActions from '../../actions/auths/authActions';
import { AuthLoginResponse } from '../../services/auths/authServices';
import LeaveApplication from '../../constants/leaves/leave-application.interface';
import LeaveStatusOption from '../../constants/leaves/leave-status-option';

export interface LeavesState {
  leaveCredits: number;
  leaves: LeaveApplication[];
  employmentScheduleType: EmploymentScheduleType[];
  leaveNatures: LeaveNature[];
  leaveStatusOptions: LeaveStatusOption[];
}

const initialState: LeavesState = {
  leaveCredits: 0,
  leaves: [],
  employmentScheduleType: [
    {
      id: 1,
      name: 'Regular',
      daysPerWeek: 5,
      daysPerMonth: 21.75,
      hours: 9,
      breakLength: 1,
    },
    {
      id: 2,
      name: '4x11',
      daysPerWeek: 4,
      daysPerMonth: 17.42,
      hours: 11,
      breakLength: 1,
    },
    {
      id: 3,
      name: '6x8',
      daysPerWeek: 6,
      daysPerMonth: 26,
      hours: 9,
      breakLength: 2,
    },
    {
      id: 4,
      name: 'Consultant',
      daysPerWeek: 5,
      daysPerMonth: 21,
      hours: 4,
      breakLength: 1,
    },
  ],
  leaveNatures: [
    {
      id: 3,
      nature: 'Bereavement',
      paidBy: 0,
      isDeductible: 0,
      adminOnly: 1,
      baseDaysNotice: 0,
    },
    {
      id: 8,
      nature: 'Sick',
      paidBy: 1,
      isDeductible: 1,
      adminOnly: 1,
      baseDaysNotice: 0,
    },
    {
      id: 11,
      nature: 'Vacation',
      paidBy: 1,
      isDeductible: 1,
      adminOnly: 1,
      baseDaysNotice: 7,
    },
    {
      id: 16,
      nature: 'Emergency',
      paidBy: 0,
      isDeductible: 1,
      adminOnly: 1,
      baseDaysNotice: 0,
    },
  ],
  leaveStatusOptions: [
    { label: 'All', value: undefined },
    { label: 'Pending', value: 'Pending' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' },
  ],
};

const getLeaveData = (leave: Partial<LeaveApplication>) => ({
  ...leave,
});

const leavesSlice = createSlice({
  name: 'leaves',
  initialState,
  reducers: {
    resetLeavesState: () => ({
      ...initialState,
    }),
    setLeavesState: (state, action: PayloadAction<Partial<LeavesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertLeave: (state, action: PayloadAction<Partial<LeaveApplication>>) => {
      const data: LeaveApplication = getLeaveData(action.payload) as LeaveApplication;

      if (typeof data.id === 'undefined') return;

      state.leaves = upsertData(state.leaves, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AuthActions.login.fulfilled, (state, { payload }) => {
      const { status, data } = payload as ApiBaseResponse<AuthLoginResponse>;

      if (status !== 200) return;

      state.leaveCredits = data.leaveCredits;
    });

    builder.addCase(LeaveActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<LeaveApplication>;
      consoleLog('LEAVES_REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.leaves = upsertData(state.leaves, data);
      }
    });

    builder.addCase(LeaveActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES_REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<LeaveApplication>;

      if (status === 503) return;

      if (data) {
        state.leaves = data;
      }
    });

    builder.addCase(LeaveActions.get.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES_REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;
      state.leaves = upsertData(state.leaves, data);
    });

    builder.addCase(LeaveActions.put.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES_REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;
      state.leaves = upsertData(state.leaves, data);
    });

    builder.addCase(LeaveActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES_REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;

      state.leaves = state.leaves.filter((leave) => leave.id !== data.id);
    });

    builder.addCase(LeaveActions.approve.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES_REDUCER', 'APPROVED', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;
      state.leaves = upsertData(state.leaves, data);
    });

    builder.addCase(LeaveActions.deny.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES_REDUCER', 'DENY', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;
      state.leaves = upsertData(state.leaves, data);
    });
  },
});

export const leavesReducerActions = leavesSlice.actions;
export default leavesSlice.reducer;
