import React from 'react';
import { Menu, MenuHandler, MenuList, MenuItem, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../../../assets/images/icons/arrow-sort.svg';
import { ReactComponent as Document } from '../../../../../assets/images/icons/document-text.svg';
import { ReactComponent as View } from '../../../../../assets/images/icons/eye.svg';

const menuData = {
  'Last pay': [
    {
      name: 'Generate new',
      icon: <Document className="w-4 h-4" />,
      linkTo: '/payroll/last-pay/generate',
    },
    {
      name: 'Show last pay',
      icon: <View className="w-4 h-4" />,
      linkTo: '/payroll/last-pay',
    },
  ],
};

const PayrollLastpayMenu: React.FC = () => {
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <div>
      {Object.entries(menuData).map(([title, items]) => (
        <Menu open={openMenu} handler={setOpenMenu} placement="bottom-start" allowHover key={title}>
          <MenuHandler className="menu-handler p-1 hover:bg-[#f05825] rounded-md">
            <div className="menu-handler__title">
              {items[0].icon}
              <span className="text-xs font-normal">{title}</span>
              <Arrow
                strokeWidth={1.5}
                className={`h-3.5 w-3.5 transition-transform ml-2 ${openMenu ? 'rotate-180' : ''}`}
              />
            </div>
          </MenuHandler>
          <div className="menu-list-container">
            <MenuList className="mt-1 menu-list">
              <div className="focus:outline-none">
                <ul>
                  {items.map((item) => (
                    <li key={item.name}>
                      <Link to={item.linkTo}>
                        <MenuItem className="flex items-center gap-5 pl-2 menu-item">
                          {item.icon}
                          <Typography className="menu-title">{item.name}</Typography>
                        </MenuItem>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </MenuList>
          </div>
        </Menu>
      ))}
    </div>
  );
};

export default PayrollLastpayMenu;
