import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import TextWithIcons from '../../molecules/TextWithIcons';
import ScheduleType from '../../../store/constants/employments/schedules/employment-schedule-type.interface';
import useScheduleTypes from '../../../hooks/schedule/useScheduleTypes';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import ScheduleTypeFormModal from './ScheduleTypeFormModal';
import ScheduleTypeListRow from './ScheduleTypeListRow';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';
import SearchSvg from '../../../assets/images/icons/search.svg';
import consoleLog from '../../../utils/consoleLog';

type SortByHeaderOptions = '' | 'name';

const ScheduleTypeList: React.FC = () => {
  const [selectedScheduleType, setSelectedScheduleType] = useState<ScheduleType | undefined>();

  const { scheduleTypesState, getAll, remove } = useScheduleTypes();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  // Use a state variable to track ascending or descending order
  const [sortOrderBy, setSortOrderBy] = useState(false);

  // Function to toggle the sorting order
  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const schedules = scheduleTypesState.scheduleTypes
    .filter((schedule: ScheduleType) =>
      schedule.name.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()),
    )
    .sort((a: ScheduleType, b: ScheduleType) => {
      const defaultOrder = b.id - a.id;

      if (sortOption === 'name') {
        a.name.localeCompare(a.name);
      }

      return defaultOrder;
    });

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = false) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (schedule: ScheduleType) => {
    setSelectedScheduleType(schedule);
  };

  const onDeleteClick = async (schedule: ScheduleType) => {
    remove(schedule.id).then((r) => {
      consoleLog('Schedule Type deleted', r);
    });
  };

  const onClose = () => setSelectedScheduleType(undefined);

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full organisms-schedule-list">
      <div className="flex justify-between pb-5">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search schedule type"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Team"
          />
        </div>
        <ScheduleTypeFormModal scheduleType={selectedScheduleType} onClose={onClose} />
      </div>
      <div className=" schedule-type-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        <button
          type="button"
          onClick={() => sortByHeader('name', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'name' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'name' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Name</div>
          </TextWithIcons>
        </button>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Days/Week</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Days/Month</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Hours</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Break Length</div>
        </TextWithIcons>

        <TextWithIcons className="justify-self-center" variant="h6">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>

      {schedules.length === 0 && <EmptyListMessage />}

      {schedules.length !== 0 && (
        <VariableSizeList
          itemCount={schedules.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <ScheduleTypeListRow
              data={schedules[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          )}
        </VariableSizeList>
      )}
    </div>
  );
};

export default ScheduleTypeList;
