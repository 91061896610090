import React from 'react';
import { Spinner, Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import useHmo from '../../../../hooks/hmo/useHmo';
import HmoUploadStatementOfAccount from './HmoUploadStatementOfAccount';

interface HmoOptionsProps {
  searchValue: string | number;
}

const HmoSettings = ({ searchValue }: HmoOptionsProps) => {
  const { reports } = useHmo();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [hmoUploadStatementOfAccount, setHmoUploadStatementOfAccount] = React.useState(false);

  const generate = async () => {
    try {
      setIsLoading(true);
      await reports(searchValue);
    } catch (err) {
      // eslint-disable-next-line
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <HmoUploadStatementOfAccount
        isOpen={hmoUploadStatementOfAccount}
        setOpen={setHmoUploadStatementOfAccount}
      />
      <Menu open={openMenu} handler={setOpenMenu} placement="bottom-end" allowHover>
        <MenuHandler>
          <button
            type="button"
            className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.4em"
              viewBox="0 0 512 512"
              className="cogs__icon"
            >
              <path
                fill="white"
                d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
              />
            </svg>
          </button>
        </MenuHandler>
        <MenuList className="hidden grid-cols-1 gap-3 overflow-visible lg:grid bg-[#434240]">
          <ul>
            <li>
              <MenuItem
                className="hover:bg-[#383838] flex flex-col gap-1"
                onClick={() => setHmoUploadStatementOfAccount(true)}
              >
                <div className="flex gap-1">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 24 24">
                    <path
                      fill="#f79220"
                      stroke="#f79220"
                      strokeWidth="1"
                      d="M18,9h-1.5C16.223877,9,16,9.223877,16,9.5s0.223877,0.5,0.5,0.5H18c1.1040039,0.0014038,1.9985962,0.8959961,2,2v7c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-7c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2.5C8.776123,10,9,9.776123,9,9.5S8.776123,9,8.5,9H6c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v7c0.0018311,1.6561279,1.3438721,2.9981689,3,3h12c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-7C20.9981689,10.3438721,19.6561279,9.0018311,18,9z M8.8623047,6.3447266L11.5,3.7069702v13.7935791C11.5001831,17.7765503,11.723999,18.0001831,12,18h0.0006104c0.2759399-0.0001831,0.4995728-0.223999,0.4993896-0.5V3.7069702l2.6376953,2.6377563c0.0936279,0.0939331,0.2208862,0.1466675,0.3535156,0.1464844c0.1325684,0,0.2597046-0.0526733,0.3534546-0.1464233c0.1952515-0.1951904,0.1953125-0.5117798,0.000061-0.7070923l-3.4909058-3.4909058c-0.0462036-0.0462646-0.1015625-0.0828247-0.1629028-0.1082153c-0.0580444-0.0239258-0.1194458-0.0354004-0.1812134-0.0366211C12.0062866,2.0018921,12.003418,2,12,2c-0.0023804,0-0.0043945,0.0012817-0.0067749,0.0013428C11.930481,2.0021973,11.8678589,2.0141602,11.80896,2.0385742c-0.0612793,0.0253296-0.1164551,0.0618896-0.1625977,0.1080322L8.1552734,5.6377563C8.1528931,5.6400757,8.1505127,5.6424561,8.1481323,5.6448975C7.954895,5.8421021,7.9580688,6.1586304,8.1552734,6.3518677S8.6690674,6.5419312,8.8623047,6.3447266z"
                    />
                  </svg>
                  <span className="mb-1 text-sm text-[#f05825] font-bold">Upload Files</span>
                </div>
                <span className="text-xs text-gray-200">Upload SOA</span>
              </MenuItem>
              <hr className="rounded-md h-0.2 opacity-10" />
              <MenuItem className="hover:bg-[#383838] flex flex-col gap-1" onClick={generate}>
                <div className="flex gap-1">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.3em"
                      viewBox="0 0 24 24"
                      id="download"
                    >
                      <path
                        fill="#f79220"
                        stroke="#f79220"
                        strokeWidth="1"
                        d="M8.1624146,13.6552734c-0.1972046,0.1932983-0.2003784,0.5098267-0.0071411,0.7070312l3.4912109,3.4912109C11.7401123,17.9474487,11.8673706,18.0001831,12,18c0.1326294,0.0001221,0.2598267-0.0525513,0.3535156-0.1464844l3.4912109-3.4912109c0.1904907-0.194397,0.1904907-0.5054321,0-0.6998291c-0.1932983-0.1972046-0.5098267-0.2004395-0.7070312-0.0072021L12.5,16.2929688V2.5C12.5,2.223877,12.276123,2,12,2s-0.5,0.223877-0.5,0.5v13.7929688l-2.6377563-2.6376953C8.6679077,13.4647827,8.3568115,13.4647827,8.1624146,13.6552734z M18,9h-1.5C16.223877,9,16,9.223877,16,9.5s0.223877,0.5,0.5,0.5H18c1.1040039,0.0014038,1.9985962,0.8959961,2,2v7c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-7c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2.5C8.776123,10,9,9.776123,9,9.5S8.776123,9,8.5,9H6c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v7c0.0018311,1.6561279,1.3438721,2.9981689,3,3h12c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-7C20.9981689,10.3438721,19.6561279,9.0018311,18,9z"
                      />
                    </svg>
                  )}
                  <span className="mb-1 text-sm text-[#f05825] font-bold">Download Files</span>
                </div>
                <span className="text-xs text-gray-200">Employee Balance</span>
              </MenuItem>
              <hr className="rounded-md h-0.2 opacity-10" />
              <MenuItem className="hover:bg-[#383838] flex flex-col gap-1">
                <div className="flex gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.3em"
                    viewBox="0 0 24 24"
                    id="download"
                  >
                    <path
                      fill="#f79220"
                      stroke="#f79220"
                      strokeWidth="1"
                      d="M8.1624146,13.6552734c-0.1972046,0.1932983-0.2003784,0.5098267-0.0071411,0.7070312l3.4912109,3.4912109C11.7401123,17.9474487,11.8673706,18.0001831,12,18c0.1326294,0.0001221,0.2598267-0.0525513,0.3535156-0.1464844l3.4912109-3.4912109c0.1904907-0.194397,0.1904907-0.5054321,0-0.6998291c-0.1932983-0.1972046-0.5098267-0.2004395-0.7070312-0.0072021L12.5,16.2929688V2.5C12.5,2.223877,12.276123,2,12,2s-0.5,0.223877-0.5,0.5v13.7929688l-2.6377563-2.6376953C8.6679077,13.4647827,8.3568115,13.4647827,8.1624146,13.6552734z M18,9h-1.5C16.223877,9,16,9.223877,16,9.5s0.223877,0.5,0.5,0.5H18c1.1040039,0.0014038,1.9985962,0.8959961,2,2v7c-0.0014038,1.1040039-0.8959961,1.9985962-2,2H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-7c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2.5C8.776123,10,9,9.776123,9,9.5S8.776123,9,8.5,9H6c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v7c0.0018311,1.6561279,1.3438721,2.9981689,3,3h12c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-7C20.9981689,10.3438721,19.6561279,9.0018311,18,9z"
                    />
                  </svg>
                  <span className="mb-1 text-sm text-[#f05825] font-bold">
                    Extract Deduction History
                  </span>
                </div>
                <span className="text-xs text-gray-200">Upload SOA</span>
              </MenuItem>
            </li>
          </ul>
        </MenuList>
      </Menu>
    </div>
  );
};

export default HmoSettings;
