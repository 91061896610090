import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import userActions from '../../store/actions/users/userActions';
import { usersReducerActions, UsersState } from '../../store/reducers/users/usersReducer';
import {
  UserSearchState,
  userSearchReducerActions,
} from '../../store/reducers/users/userSearchReducer';
import AtLeast from '../../types/atleast';
import User from '../../store/constants/users/user.interface';

const useUsers = () => {
  const dispatch = useAppDispatch();
  const usersState: UsersState = useAppSelector((state) => state.users);
  const userSearchState: UserSearchState = useAppSelector((state) => state.userSearch);

  const resetUserState = () => {
    dispatch(usersReducerActions.resetUsersState());
  };

  const setUserState = (state: Partial<UsersState>) => {
    dispatch(usersReducerActions.setUsersState(state));
  };

  const get = async (userId: User['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.get(userId));

  const getContact = async (userId: User['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.getContact(userId));

  const getEmployment = async (userId: User['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.getEmployment(userId));

  const getBank = async (userId: User['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.getBank(userId));

  const getAccountNumber = async (userId: User['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.getAccountNumber(userId));

  const getTeams = async (userId: User['id'] | undefined) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.getTeams(userId));

  const getAll = useCallback(
    async () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(userActions.getAll(userSearchState)),

    [userSearchState, dispatch],
  );

  const post = async (userData: Omit<User, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.post(userData));

  const put = async (userData: AtLeast<User, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userActions.put(userData));

  const resetUserSearchState = () => {
    dispatch(userSearchReducerActions.resetUserSearchState());
  };

  const search = useCallback(
    (state?: Partial<UserSearchState>) => {
      if (!state) {
        // eslint-disable-next-line no-param-reassign
        state = userSearchState;
      }

      dispatch(
        userSearchReducerActions.setUserSearchState({
          ...state,
          page:
            (state.limit && state.limit !== userSearchState.limit) ||
            state.search === '' ||
            state.search !== userSearchState.search
              ? 1
              : userSearchState.page + 1,
        }),
      );

      getAll().then();
    },
    [userSearchState, getAll, dispatch],
  );

  const getUserInState = (id: User['id']) => usersState.users.find((user) => user.id === id);

  return {
    resetUserState,
    resetUserSearchState,
    usersState,
    userSearchState,
    setUserState,
    get,
    getContact,
    getEmployment,
    getBank,
    getAccountNumber,
    getAll,
    getTeams,
    post,
    put,
    search,
    getUserInState,
  };
};

export default useUsers;
