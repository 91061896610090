import * as Yup from 'yup';

const createValidationSchema = (leaveCredits: number) =>
  Yup.object().shape({
    duration: Yup.array()
      .of(Yup.date().required('Please choose a date for the duration'))
      .test('endDate', 'The Start Date and End Date is Required.', (value) => {
        if (value) {
          const [endDate] = value;
          if (!endDate) {
            return false;
          }
        }
        return true;
      })
      .test(
        'duration',
        'Your application exceeded the total remaining leave credits.',
        (value, { parent }) => {
          if (parent.isPaid) {
            return value && value.length <= leaveCredits;
          }
          return true;
        },
      )
      .required('Please choose start and end dates for the duration'),
    isPaid: Yup.boolean().test(
      'isPaidValidation',
      'You have no remaining leave credits. Please choose UNPAID.',
      (value, { parent }) => {
        if (leaveCredits === 0) {
          if (parent.id === undefined) {
            return value === false;
          }
        }
        return true;
      },
    ),
    attachmentsFile: Yup.mixed()
      .required('Required!')
      .test('FILE_SIZE', 'File size exceed the maximum allowed size (2MB) ', (value) => {
        if (Array.isArray(value)) {
          return value.every((file) => file.size <= 2 * 1024 * 1024); // 2 MB
        }
        return value instanceof File && value.size <= 2 * 1024 * 1024;
      })
      .test(
        'FILE_TYPE',
        'Invalid file type, Please Upload (PNG, JPG, PDF, or DOC only)',
        (value) => {
          const allowedTypes = [
            'image/png',
            'image/jpeg',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ];
          if (Array.isArray(value)) {
            return value.every((file) => allowedTypes.includes(file.type));
          }
          return value instanceof File && allowedTypes.includes(value.type);
        },
      )
      .test('MAX_FILES', 'You can upload only up to three files!', (value) => {
        if (Array.isArray(value)) {
          return value.length <= 3;
        }
        return value instanceof FileList && value.length <= 3;
      }),
  });

export default createValidationSchema;
