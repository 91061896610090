import moment from 'moment-timezone';
import { SettingsState } from '../../store/reducers/utils/settingsReducer';
import { useAppSelector } from '../../store/store';

const useDateTime = () => {
  const { timezones, timezone, dateTimeFormat, dateFormat, timeFormat }: SettingsState =
    useAppSelector((state) => state.settings);

  const getDateTime = (date: Date) => moment(date).tz(timezone.timezone).format(dateTimeFormat);

  const getDate = (date: Date) => moment(date).tz(timezone.timezone).format(dateFormat);

  const getTime = (date: Date) => moment(date).tz(timezone.timezone).format(timeFormat);

  const getDateAsMoment = (date: Date) => moment(date).tz(timezone.timezone);

  // @todo This should be from API temporarily stored in reducer manually.
  const getTimezones = () => timezones;

  const getTimezone = (code: string) => timezones.find((t) => t.code === code)?.timezone;

  const defaultTimezone = timezones.find((t) => t.code === 'PHT');

  return {
    timezones,
    dateFormat,
    defaultTimezone,
    getTimezone,
    getTimezones,
    getDateAsMoment,
    getDateTime,
    getDate,
    getTime,
  };
};

export default useDateTime;
