import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import EmploymentPosition from '../../../../store/constants/employments/positions/employment-position.interface';
import DeleteConfirmationBanner from '../../../molecules/DeleteConfirmationBanner';
import EditSvg from '../../../../assets/images/icons/edit.svg';
import UserSvg from '../../../../assets/images/icons/user.svg';
import ClientSvg from '../../../../assets/images/icons/client.svg';

export interface PositionsListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: EmploymentPosition;
  onEditClick: (employment: EmploymentPosition) => void;
  onDeleteClick: (employment: EmploymentPosition) => Promise<unknown>;
}

const PositionManageListRow: React.FC<PositionsListRowProps> = ({
  className = '',
  data,
  onEditClick,
  onDeleteClick,
  ...props
}: PositionsListRowProps) => {
  const navigate = useNavigate();

  const handleOnUserPositionsClick = () => {
    navigate(`/admin/positions/${data.id}/user-positions`);
  };

  const handleOnClientPositionsClick = () => {
    navigate(`/admin/positions/${data.id}/client-positions`);
  };

  const deleteMessage = {
    pending: 'Pending',
    success: 'Position information is successfully deleted.',
    error: 'Error on deleting Position information.',
  };

  return (
    <div
      {...props}
      className={`${className} positions-list-data table-list-data flex items-center`}
    >
      <div>{data.name}</div>
      <div>{data.isOtEligible ? '✔️' : '✖️'}</div>
      <div>{data.isNdEligible ? '✔️' : '✖️'}</div>

      <div className="space-x-6 action-buttons justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <img className="edit-data-icon" src={EditSvg} alt={`Edit ${data.name}`} />
          </button>
        </Tooltip>

        <Tooltip content="Users" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={handleOnUserPositionsClick}>
            <img className="edit-data-icon" src={UserSvg} alt={`Edit ${data.name}`} />
          </button>
        </Tooltip>

        <Tooltip content="Clients" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={handleOnClientPositionsClick}>
            <img className="edit-data-icon" src={ClientSvg} alt={`Edit ${data.name}`} />
          </button>
        </Tooltip>

        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default PositionManageListRow;
