import React from 'react';
import { ReactComponent as DocumentIcon } from '../../../../assets/images/icons/document-text.svg';
import { ReactComponent as DeleteSvg } from '../../../../assets/images/icons/delete.svg';
import { ReactComponent as InvalidIcon } from '../../../../assets/images/icons/invalid-circle.svg';

interface FileUploadDisplayImageProps {
  files: File[];
  onDeleteFile: (fileName: string) => void;
  openImageModal: (index: number) => void;
}

const FileUploadDisplayImage: React.FC<FileUploadDisplayImageProps> = ({
  files,
  onDeleteFile,
  openImageModal,
}) => (
  <div className="upload-image__container">
    {files.map((file, index) => {
      const fileIcon = (
        <span className="w-full h-full">
          <DocumentIcon className="w-14 h-14 upload-image__document-icon" />
        </span>
      );

      const inValidIcon = (
        <span className="w-full h-full">
          <InvalidIcon className="w-14 h-14 upload-image__document-icon" />
        </span>
      );

      let content;

      if (
        file.type === 'application/pdf' ||
        file.type === 'application/msword' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        content = fileIcon;
      } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
        content = <img src={URL.createObjectURL(file)} alt={`${file.name}`} />;
      } else {
        content = inValidIcon;
      }

      return (
        <div key={file.name} className="w-full flex mb-2 upload-image__content-wrapper">
          <div className="upload-image__button-wrapper">
            <button
              type="button"
              tabIndex={0}
              onClick={() => {
                if (file.type === 'application/pdf') {
                  window.open(URL.createObjectURL(file), '_blank');
                } else if (
                  [
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  ].includes(file.type)
                ) {
                  const downloadLink = document.createElement('a');
                  downloadLink.href = URL.createObjectURL(file);
                  downloadLink.download = file.name;
                  downloadLink.click();
                } else if (file.type === 'image/jpeg' || file.type === 'image/png') {
                  openImageModal(index);
                }
              }}
            >
              {content}
            </button>
          </div>
          <div className="w-full flex items-center justify-between px-4 gap-3 upload-image__file-details">
            <p>{file.name}</p>
            <button type="button" onClick={() => onDeleteFile(file.name)} className="pb-2">
              <DeleteSvg className="w-6 h-6 trash-icon" />
            </button>
          </div>
        </div>
      );
    })}
  </div>
);

export default FileUploadDisplayImage;
