const dateToDateString = (date: Date) => {
  const optionsDateTime: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };

  const optionsDate: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'long',
    day: 'numeric',
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  const dateString = date.toLocaleDateString(undefined, optionsDate);
  const timeString = date.toLocaleTimeString(undefined, optionsTime);
  const dateTimeString = date.toLocaleDateString(undefined, optionsDateTime);

  return { date: dateString, time: timeString, dateTime: dateTimeString };
};

export default dateToDateString;
