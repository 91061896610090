import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import Company from '../../../store/constants/companies/company.interface';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';
import { ReactComponent as Edit } from '../../../assets/images/icons/edit.svg';

export interface CompanyListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Company;
  getDateTime: (date: Date) => string;
  onEditClick: (Company: Company) => void;
  onDeleteClick: (Company: Company) => Promise<unknown>;
}

const CompanyListRow: React.FC<CompanyListRowProps> = ({
  className = '',
  data,
  getDateTime,
  onEditClick,
  onDeleteClick,
  ...props
}: CompanyListRowProps) => {
  const deleteMessage = {
    pending: 'Pending',
    success: 'Company information is successfully deleted.',
    error: 'Error on deleting Company information.',
  };

  return (
    <div {...props} className={`${className} company-list-data table-list-data flex items-center`}>
      <div>{data.id}</div>
      <div>{data.name}</div>
      <div>{data.code}</div>
      <div>
        <a href={`tel: ${data.contact}`}>{data.contact}</a>
      </div>
      <div>{data.dateCreated && getDateTime(data.dateCreated)}</div>

      <div className="action-buttons space-x-4 justify-self-center">
        <Tooltip content="Edit">
          <button className="textButton mr-2" type="button" onClick={() => onEditClick(data)}>
            <Edit className="w-5 h-auto" />
          </button>
        </Tooltip>

        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default CompanyListRow;
