import React from 'react';
import { ReactComponent as Download } from '../../../../../assets/images/icons/download.svg';

const PayrollViewExcelExport: React.FC = () => (
  <div className="payroll-view-excel-export">
    <div className="payroll-view-excel-export__switches">
      <div className="payroll-view-excel-export__section">
        <button type="button" className="payroll-options-export-button">
          <Download className="w-5 h-5" />

          <span className="my-auto text-xs">Statutories</span>
        </button>
        <button type="button" className="payroll-options-export-button">
          <Download className="w-5 h-5" />

          <span className="my-auto text-xs">Bank Crediting</span>
        </button>
      </div>
      <div className="payroll-view-excel-export__section">
        <button type="button" className="payroll-options-export-button">
          <Download className="w-5 h-5" />

          <span className="my-auto text-xs">Payroll Details</span>
        </button>
        <button type="button" className="payroll-options-export-button">
          <Download className="w-5 h-5" />

          <span className="my-auto text-xs">Payroll Details w/ Incentives (OS)</span>
        </button>
        <button type="button" className="payroll-options-export-button">
          <Download className="w-5 h-5" />

          <span className="my-auto text-xs">Payroll Details w/ Incentives (AOS)</span>
        </button>
      </div>
    </div>
    <div className="flex justify-center">
      <span className="text-xs">Excel export</span>
    </div>
  </div>
);

export default PayrollViewExcelExport;
