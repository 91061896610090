import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import UserEmploymentPosition from '../../../../store/constants/employees/positions/employee-position.interface';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import usePositionRates from '../../../../hooks/positions/usePositionRates';

export interface PositionListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: UserEmploymentPosition;
  getDateTime: (date: Date) => string;
  handleDateEndClick: (data: UserEmploymentPosition) => void;
}

const PositionEmployeeListRow: React.FC<PositionListRowProps> = ({
  className = '',
  data,
  getDateTime,
  handleDateEndClick,
  ...props
}: PositionListRowProps) => {
  const { getEmployeeByEmployeeNumber } = useUserEmployments();
  const { getUserPositionRate } = usePositionRates();

  const employee = getEmployeeByEmployeeNumber(data.employeeNumber as number);
  const rate = getUserPositionRate(data.employmentPositionRatesId as number);
  const navigate = useNavigate();

  const handleOnPositionAssignmentClick = () => {
    navigate(`/admin/user/manage/${employee?.userId}/position`);
  };

  return (
    <div
      {...props}
      className={`${className} user-positions-data table-list-data flex justify-center`}
    >
      <div>{data.employeeNumber}</div>
      <div>
        <button type="button" onClick={() => handleOnPositionAssignmentClick()}>
          {employee?.userInformation.firstName} {employee?.userInformation.lastName}
        </button>
      </div>
      <div>{data.tier ?? ''}</div>
      {/* @todo to be improved when assigning of rates of fixed */}
      {rate ? (
        <div>{`₱ ${rate?.gross.toLocaleString('en-US', { style: 'decimal' }) ?? ''}`}</div>
      ) : (
        <div>{}</div>
      )}
      <div>{data.isProbation ? 'Yes' : 'No'}</div>
      <div>{moment(data.dateStart).format('MMMM D, YYYY')}</div>
      <div>
        <button
          type="button"
          className={`text-left w-fit ${data.dateEnd ? 'cursor-default' : ''}`}
          onClick={() => {
            if (data.dateEnd) return;
            handleDateEndClick(data);
          }}
        >
          {data.dateEnd ? moment(data.dateEnd).format('MMMM D, YYYY') : '✖️'}
        </button>
      </div>
    </div>
  );
};

export default PositionEmployeeListRow;
