import React, { useEffect, useState } from 'react';
import { Dialog, IconButton } from '@material-tailwind/react';
import { ExpectedAttendanceTime } from '../../../../../store/reducers/attendances/attendancesReducer';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import UserUndertimeModal from './UserUndertime';
import useTimeRemaining from '../../../../../hooks/utils/useTimeRemaining';
import useAppInitialization from '../../../../../hooks/utils/useAppInitialization';
import UserOvertimePastModal from '../userOvertime/UserOvertimePast';
import Spinner from '../../../../atoms/Spinner';
import useTheme from '../../../../../hooks/utils/useTheme';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';

interface UserTimeOutProps {
  expectedTimeOut?: ExpectedAttendanceTime;
}

let timer: string | number | NodeJS.Timeout | undefined;

const UserTimeOut: React.FC<UserTimeOutProps> = ({
  expectedTimeOut = undefined,
}: UserTimeOutProps) => {
  const { timeRemaining, calculateTimeRemaining, setTimeRemaining, getTimeRemainingFormat } =
    useTimeRemaining(expectedTimeOut);
  const { init } = useAppInitialization();
  const { theme } = useTheme();

  const [showUnderTime, setShowUnderTime] = useState(false);
  const [showOvertime, setShowOvertime] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogOut = () => {
    setOpen(!open);

    if (timeRemaining?.timeDiff) {
      if (timeRemaining.timeDiff < 0) {
        setShowOvertime(true);
        setShowUnderTime(false);
      } else if (timeRemaining.timeDiff > 0) {
        setShowUnderTime(true);
        setShowOvertime(false);
      }
    }
  };

  useOnLoad(() => {
    if (init && expectedTimeOut) {
      timer = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
    }

    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (timeRemaining?.timeDiff !== undefined) {
      if (timeRemaining.timeDiff < 0) {
        setShowContent(true);
      } else if (timeRemaining.timeDiff > 0) {
        setShowContent(false);
      }
    }
  }, [timeRemaining?.timeDiff]);

  return (
    <div className="">
      <button
        type="button"
        onClick={handleLogOut}
        className="user-attendance-action__button"
        disabled={!timeRemaining?.timeDiff}
      >
        {!timeRemaining?.timeDiff ? (
          <div className="button user-attendance-action__button">
            <Spinner />
          </div>
        ) : (
          <>
            <span className="user-attendance-action-button__heading-text">time out</span>
            {timeRemaining?.timeDiff && timeRemaining.timeDiff > 0 ? (
              <span className="user-attendance-action-button__time">
                {getTimeRemainingFormat()}
              </span>
            ) : (
              <span className="user-attendance-action-button__logout-text">
                {showContent ? 'Overtime' : 'Logout'}
              </span>
            )}
          </>
        )}
      </button>
      <Dialog
        open={open}
        size="xs"
        handler={handleLogOut}
        animate={{
          mount: {
            transition: 'ease-in duration-800',
          },
          unmount: {
            transition: 'ease-out duration-800',
          },
        }}
        className={`user-attendance-action__prompt-container ${theme}`}
      >
        <div className="close-prompt-button">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={() => setOpen(false)}>
            <Close className="h-5 w-5" />
          </IconButton>
        </div>
        {showUnderTime && (
          <UserUndertimeModal timeRemaining={timeRemaining} handleOnClose={() => setOpen(false)} />
        )}
        {showOvertime && (
          <UserOvertimePastModal
            // eslint-disable-next-line
            expectedTimeOut={expectedTimeOut!}
            handleOnClose={() => setOpen(false)}
          />
        )}
      </Dialog>
    </div>
  );
};

export default UserTimeOut;
