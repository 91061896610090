import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import CompanyBranch from '../../constants/companies/branches/company-branch.interface';
import CompanyBranchActions from '../../actions/companies/companyBranchActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface CompanyBranchesState {
  companyBranches: CompanyBranch[];
}

const initialState: CompanyBranchesState = {
  companyBranches: [],
};

const getCompanyBranchData = (companyBranch: Partial<CompanyBranch>) => ({
  ...companyBranch,
});

const upsertItems = (companyBranches: CompanyBranch[], ...data: CompanyBranch[]) => {
  const dataObject: { [id: number]: CompanyBranch } = {};
  companyBranches.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((companyBranch) => {
    if (!dataObject[companyBranch.id]) {
      dataObject[companyBranch.id] = companyBranch;
    } else if (dataObject[companyBranch.id]) {
      dataObject[companyBranch.id] = { ...dataObject[companyBranch.id], ...companyBranch };
    }
  });

  return Object.values(dataObject);
};

const companyBranchesSlice = createSlice({
  name: 'companyBranches',
  initialState,
  reducers: {
    resetCompanyBranchesState: () => ({
      ...initialState,
    }),
    setCompanyBranchesState: (state, action: PayloadAction<Partial<CompanyBranchesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertCompanyBranch: (state, action: PayloadAction<Partial<CompanyBranch>>) => {
      const data: CompanyBranch = getCompanyBranchData(action.payload) as CompanyBranch;

      if (typeof data.id === 'undefined') return;

      state.companyBranches = upsertItems(state.companyBranches, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CompanyBranchActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<CompanyBranch>;
      consoleLog('COMPANY BRANCH REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.companyBranches = upsertItems(state.companyBranches, data);
      }
    });
    builder.addCase(CompanyBranchActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('COMPANY BRANCH REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<CompanyBranch>;

      if (status === 503) return;

      if (data) {
        state.companyBranches = data;
      }
    });
    builder.addCase(CompanyBranchActions.get.fulfilled, (state, { payload }) => {
      consoleLog('COMPANY BRANCH REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<CompanyBranch>;

      if (typeof data.id === 'undefined') return;
      state.companyBranches = upsertItems(state.companyBranches, data);
    });
    builder.addCase(CompanyBranchActions.put.fulfilled, (state, { payload }) => {
      consoleLog('COMPANY BRANCH REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<CompanyBranch>;

      if (typeof data.id === 'undefined') return;
      state.companyBranches = upsertItems(state.companyBranches, data);
    });
    builder.addCase(CompanyBranchActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('COMPANY BRANCH REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<CompanyBranch>;

      if (typeof data.id === 'undefined') return;
      state.companyBranches = state.companyBranches.filter(
        (companyBranch) => companyBranch.id !== data.id,
      );
    });
  },
});

export const companyBranchesReducerActions = companyBranchesSlice.actions;
export default companyBranchesSlice.reducer;
