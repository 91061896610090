import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import UserEmployment from '../../constants/employees/employee.interface';
import EmploymentRole from '../../constants/employments/roles/employment-role.interface';
import UserInformation from '../../constants/users/user-information.interface';
import ACL from '../../constants/utils/acl.interface';

export interface AuthLoginParams {
  username: string;
  password: string;
}

export interface AuthLoginResponse {
  employeeNumber: UserEmployment['employeeNumber'];
  userInformation: UserInformation;
  email: string;
  username: string;
  acl: ACL;
  role: EmploymentRole['name'];
  token: string;
  leaveCredits: number;
}

export type AuthRefreshAccessTokenResponse = string;

const login = async (
  loginProps: AuthLoginParams,
  state: StateHeaders,
): Promise<ApiBaseResponse<AuthLoginResponse> | unknown> =>
  apiFetch<ApiBaseResponse<AuthLoginResponse>>('auth/login', {
    state,
    options: {
      method: 'POST',
      body: loginProps,
    },
  })
    .then((response: ApiBaseResponse<AuthLoginResponse>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((error) => {
      throw new Error(error.message);
    });

const refreshToken = async (
  state: StateHeaders,
): Promise<ApiBaseResponse<AuthRefreshAccessTokenResponse> | void> =>
  apiFetch<ApiBaseResponse<AuthRefreshAccessTokenResponse>>('auth/refresh', {
    state,
    options: {
      method: 'POST',
    },
  })
    .then((response: ApiBaseResponse<AuthRefreshAccessTokenResponse>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  login,
  refreshToken,
} as const;
