import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import moment from 'moment';
import TextWithIcons from '../../molecules/TextWithIcons';
import Holiday from '../../../store/constants/holidays/holiday.interface';
import HolidayFormModal from './HolidayFormModal';
import HolidayListRow from './HolidayListRow';
import useHolidays from '../../../hooks/holidays/useHolidays';
import consoleLog from '../../../utils/consoleLog';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useDateTime from '../../../hooks/utils/useDateTime';
import SearchSvg from '../../../assets/images/icons/search.svg';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions = '' | 'id' | 'name' | 'byDate' | 'byDayOfWeek' | 'byHolidayType';

const HolidayList: React.FC = () => {
  const [selectedHoliday, setSelectedHoliday] = useState<Holiday | undefined>();

  const { holidaysState, getAll, remove } = useHolidays();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const { getDateAsMoment } = useDateTime();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');

  // Use a state variable to track ascending or descending order
  const [sortOrderBy, setSortOrderBy] = useState(false);

  // Function to toggle the sorting order
  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const getDayOfWeekNumericValue = (date: Date) => {
    const dayOfWeek = moment(date).format('dddd');
    // Map day of the week to numerical values as needed
    switch (dayOfWeek) {
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;
      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      case 'Sunday':
        return 7;
      default:
        return 0; // Default to 0 for unknown day
    }
  };

  const holidays = holidaysState.holidays
    .filter((holiday: Holiday) => {
      if (!holiday) return false;
      const searchKeywords = debouncedSearchValue.toLowerCase().split(' ');

      // Function to check if a keyword is present in any column
      const keywordMatches = (keyword: string) => {
        const formattedDate = getDateAsMoment(holiday.dateCelebrated)
          .format('MMMM DD, YYYY')
          .toLowerCase();
        const formattedDay = getDateAsMoment(holiday.dateCelebrated).format('dddd').toLowerCase();
        const keywords = keyword.split(' ');
        let found = false;
        keywords.forEach((search) => {
          if (
            holiday.name.toLowerCase().includes(search) ||
            holiday.holidayType?.name.toLowerCase().includes(search) ||
            formattedDate.includes(search) ||
            formattedDay.includes(search)
          ) {
            found = true;
          }
        });
        return found;
      };

      // Check if any of the keywords match in any column
      return searchKeywords.every(keywordMatches);
    })
    .sort((a: Holiday, b: Holiday) => {
      let comparison = b.id - a.id;

      if (sortOption === 'id') {
        comparison = b.id - a.id;
      }

      if (sortOption === 'name') {
        comparison = a.name.localeCompare(b.name);
      }

      if (sortOption === 'byDate') {
        // Assuming you have a getDateAsMoment function to parse and compare dates
        comparison = getDateAsMoment(a.dateCelebrated).diff(getDateAsMoment(b.dateCelebrated));
      }

      if (sortOption === 'byDayOfWeek') {
        // Sort by day of the week (e.g., Monday, Tuesday)
        const dayOfWeekA = getDayOfWeekNumericValue(a.dateCelebrated);
        const dayOfWeekB = getDayOfWeekNumericValue(b.dateCelebrated);
        comparison = dayOfWeekA - dayOfWeekB; // Compare numerical values
      }

      if (sortOption === 'byHolidayType') {
        const holidayA = a.holidayType?.name || '';
        const holidayB = b.holidayType?.name || '';
        comparison = holidayA.localeCompare(holidayB);
      }

      if (sortOrderBy) {
        comparison *= -1;
      }

      if (comparison === 0) {
        comparison = getDateAsMoment(a.dateCelebrated).diff(getDateAsMoment(b.dateCelebrated));
      }

      return comparison; // Default sorting
    });

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = false) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const handleOnDataChange = () => {
    setSortOption('id');
    setSortOrderBy(false);
  };

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (holiday: Holiday) => {
    setSelectedHoliday(holiday);
  };

  const onDeleteClick = async (holiday: Holiday) => {
    remove(holiday.id).then((r) => {
      consoleLog('DELETE HOLIDAY', r);
    });
  };

  const onHolidayModalClose = () => setSelectedHoliday(undefined);

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  // @TODO
  // 1. When editing data table will sort by id oldest - newest, when refreshed revert back to newest to oldest.

  return (
    <div className="w-full mt-2 organisms-holiday-list">
      <div className="flex justify-between pb-5">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search holiday"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Holiday"
          />
        </div>{' '}
        <HolidayFormModal
          holiday={selectedHoliday}
          onClose={onHolidayModalClose}
          resetSort={handleOnDataChange}
        />
      </div>
      <div className="user-holiday-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        <button
          type="button"
          onClick={() => sortByHeader('name', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'name' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'name' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Name</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('byDate', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'byDate' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'byDate' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Date</div>
          </TextWithIcons>
        </button>

        <button
          type="button"
          onClick={() => sortByHeader('byDayOfWeek', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'byDayOfWeek' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'byDayOfWeek' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Day</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('byHolidayType', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'byHolidayType' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'byHolidayType' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Holiday type</div>
          </TextWithIcons>
        </button>
        <TextWithIcons variant="h6">Action</TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={holidays.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) =>
          holidays[index] && (
            <HolidayListRow
              data={holidays[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          )
        }
      </VariableSizeList>
    </div>
  );
};

export default HolidayList;
