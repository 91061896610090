import React from 'react';
import { ModalProps } from '../../../store/constants/calendar/calendar.interface';
import { ReactComponent as Exclamation } from '../../../assets/images/icons/exclamation-circle.svg';

const CalendarModal: React.FC<ModalProps> = ({ content, onClose, isVisible }) => (
  <div className={`modal-container ${isVisible ? 'open' : ''}`}>
    <div className="modal">
      <div className="my-auto">
        {content.attendanceRanges.map((range) => {
          const [timeIn, timeOut] = range.split(' - ');
          const eventColor = content.isLate ? 'isLateLightBlue' : 'isNotLateGreen';
          const eventLabel = content.isLate ? 'Late' : 'Attendance';

          return (
            <div key={range} className="flex items-center gap-1 attendance-section">
              <span
                className={`${eventColor} w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color`}
              />
              <p>
                {eventLabel}: {timeIn} - {timeOut}
              </p>
            </div>
          );
        })}
        {content.absent && (
          <div className="flex items-center gap-1 wrapper-section-calendar">
            <span className="w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color absent-content" />
            <p className="flex items-center gap-1">
              <span>{content.absent}</span>
              <Exclamation className="h-4 w-auto pb-[0.1rem] flex-shrink-0" />
            </p>
          </div>
        )}
        {content.undertime && (
          <div className="flex items-center gap-1 wrapper-section-calendar">
            <span className="w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color undertime-content" />
            <p>{content.undertime}</p>
          </div>
        )}
        {content.holiday &&
          content.holiday.map((holiday) => (
            <div key={holiday} className="flex items-center gap-1 wrapper-section-calendar">
              <span className="w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color holiday-content" />
              <p>{holiday}</p>
            </div>
          ))}
        {content.overtime &&
          content.overtime.map((overtime) => (
            <div key={overtime} className="flex items-center gap-1 wrapper-section-calendar">
              <span className="w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color overtime-content" />
              <p>{overtime}</p>
            </div>
          ))}
        {content.leaveApproved && (
          <div className="flex items-center gap-1 wrapper-section-calendar">
            <span className="w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color leave-approved-content" />
            <p>{content.leaveApproved}</p>
          </div>
        )}
        {content.leaveDenied && (
          <div className="flex items-center gap-1 wrapper-section-calendar">
            <span className="w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color leave-denied-content" />
            <p>{content.leaveDenied}</p>
          </div>
        )}
        {content.leavePending && (
          <div className="flex items-center gap-1 wrapper-section-calendar">
            <span className="w-[.4rem] h-[.4rem] rounded-full flex-shrink-0 event-color leave-pending-content" />
            <p>{content.leavePending}</p>
          </div>
        )}
        {content.noEvents && (
          <div className="noEvents-section">
            <p>{content.noEvents}</p>
          </div>
        )}
      </div>
      <div className="fc-button-wrapper">
        <button type="button" className="fc-closeModal" onClick={onClose}>
          X
        </button>
      </div>
    </div>
  </div>
);

export default CalendarModal;
