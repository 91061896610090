import { useAppDispatch, useAppSelector } from '../../store/store';
import loanActions from '../../store/actions/loans/loanActions';
import { loansReducerActions, LoansState } from '../../store/reducers/loans/loansReducer';
import Loan from '../../store/constants/loans/loan.interface';
import { GetAllLoanQueryParams } from '../../store/services/loans/loanServices';
// import { GetAllLoanQueryParams } from '../../store/services/loans/loanServices';

const useLoans = () => {
  const dispatch = useAppDispatch();
  const loansState: LoansState = useAppSelector((state) => state.loans);

  const resetLoansState = () => {
    dispatch(loansReducerActions.resetLoanState());
  };

  const setLoansState = (state: Partial<LoansState>) => {
    dispatch(loansReducerActions.setLoansState(state));
  };

  const get = async (id: Loan['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.get(id));

  const getAllTest = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.getAllTest({}));

  const getAll = async ({
    employeeNumber = undefined,
    departmentId = undefined,
    loanTypeId = undefined,
  }: GetAllLoanQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.getAll({ employeeNumber, departmentId, loanTypeId }));

  const post = async (data: Omit<Loan, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.post(data));

  const put = async (data: Partial<Loan>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.put(data));

  const remove = async (id: Loan['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.remove(id));

  return {
    resetLoansState,
    loansState,
    setLoansState,
    get,
    getAll,
    getAllTest,
    post,
    put,
    remove,
  };
};

export default useLoans;
