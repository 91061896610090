import React, { HTMLAttributes, useState } from 'react';
import DatePicker from 'react-datepicker';
import useRealTimeDate from '../../hooks/utils/useRealTimeDate';
import ProfileStatsCardItem, { ProfileStatsCardItemProps } from '../atoms/UserProfileStatsCardItem';
import { ReactComponent as Calendar } from '../../assets/images/icons/calendar.svg';

export interface ProfileStatsCardProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  name: string;
  items: ProfileStatsCardItemProps[];
}

const UserProfileStatsCard: React.FC<ProfileStatsCardProps> = ({
  title = '',
  name,
  className = '',
  items,
  ...otherProps
}) => {
  const [startDate, setStartDate] = useState<Date>(useRealTimeDate());
  return (
    <div className={`user-profile-stats-card-wrapper ${className}`} {...otherProps}>
      <div className="profile-stats-card-header pb-1">
        <h5 className="">{title}</h5>
        <div className="flex gap-1 items-center">
          <h6 className="muiTypography--headline7 pt-1">Date:</h6>
          <div className="flex items-center datePicker-main-parent">
            <DatePicker
              className="date-picker focus:outline-none w-[7.4rem] cursor-pointer"
              id="user-date"
              name="user-date"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              dateFormat="MMMM d, yyyy"
              showPopperArrow={false}
              popperPlacement="bottom"
              popperClassName="m-0"
              onFocus={(e) => e.target.blur()}
              autoComplete="off"
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [25, -2],
                  },
                },
              ]}
            />
            {/* <img src="https://placehold.co/20" alt="arrow-down" /> */}
            <label htmlFor="user-date" className="pl-1 cursor-pointer">
              <Calendar className="w-5 h-5 icons" />
            </label>
          </div>
        </div>
      </div>
      <div className="profile-stats-card">
        {items.map((item) => (
          <ProfileStatsCardItem
            key={item.label}
            icon={item.icon}
            numerator={item.numerator}
            denominator={item.denominator}
            label={item.label}
          />
        ))}
      </div>
    </div>
  );
};

export default UserProfileStatsCard;
