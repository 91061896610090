import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import HmoPrincipalActions from '../../actions/my/transactionFeedActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import HmoPrincipalType from '../../constants/my/hmo-principal.interface';

export interface HmoPrincipalsState {
  hmoPrincipals: HmoPrincipalType[];
}

const initialState: HmoPrincipalsState = {
  hmoPrincipals: [
    {
      id: 1,
      accountNumber: '2706-12301100055-54',
      plan: 'RP',
      premiumClass: 1232.0,
      principal: 'Principal',
    },
    {
      id: 2,
      accountNumber: '2706-12300230056-78',
      plan: 'HP',
      premiumClass: 345435.0,
      principal: 'Paulo',
    },
    {
      id: 3,
      accountNumber: '6573-23200230056-11',
      plan: 'SS',
      premiumClass: 768678.0,
      principal: 'Haker',
    },
  ],
};

const getHmoPrincipalData = (hmoPrincipal: Partial<HmoPrincipalType>) => ({
  ...hmoPrincipal,
});

const hmoPrincipalsSlice = createSlice({
  name: 'hmoPrincipals',
  initialState,
  reducers: {
    resetHmoPrincipalsState: () => ({
      ...initialState,
    }),
    setHmoPrincipalsState: (state, action: PayloadAction<Partial<HmoPrincipalsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertHmoPrincipal: (state, action: PayloadAction<Partial<HmoPrincipalType>>) => {
      const data: HmoPrincipalType = getHmoPrincipalData(action.payload) as HmoPrincipalType;

      if (typeof data.id === 'undefined') return;

      state.hmoPrincipals = upsertData(state.hmoPrincipals, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HmoPrincipalActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<HmoPrincipalType>;
      consoleLog('HMO_PRINCIPAL REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.hmoPrincipals = upsertData(state.hmoPrincipals, data);
      }
    });
    builder.addCase(HmoPrincipalActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('HMO_PRINCIPAL REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<HmoPrincipalType>;

      if (status === 503) return;

      if (data) {
        state.hmoPrincipals = data;
      }
    });
    builder.addCase(HmoPrincipalActions.get.fulfilled, (state, { payload }) => {
      consoleLog('HMO_PRINCIPAL REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<HmoPrincipalType>;

      if (typeof data.id === 'undefined') return;
      state.hmoPrincipals = upsertData(state.hmoPrincipals, data);
    });
    builder.addCase(HmoPrincipalActions.put.fulfilled, (state, { payload }) => {
      consoleLog('HMO_PRINCIPAL REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<HmoPrincipalType>;

      if (typeof data.id === 'undefined') return;
      state.hmoPrincipals = upsertData(state.hmoPrincipals, data);
    });
    builder.addCase(HmoPrincipalActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('HMO_PRINCIPAL REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<HmoPrincipalType>;

      if (typeof data.id === 'undefined') return;
      state.hmoPrincipals = state.hmoPrincipals.filter(
        (hmoPrincipal) => hmoPrincipal.id !== data.id,
      );
    });
  },
});

export const hmoPrincipalsReducerActions = hmoPrincipalsSlice.actions;
export default hmoPrincipalsSlice.reducer;
