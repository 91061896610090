import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import LeaveApplication from '../../constants/leaves/leave-application.interface';
import myLeaveServices, { GetAllLeavesQueryParams } from '../../services/my/myLeaveServices';

const getMy = createAsyncThunk(
  'leaves/applications/getMy',
  async (
    id: LeaveApplication['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myLeaveServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getMyAll = createAsyncThunk(
  'leaves/applications/getMyAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllLeavesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myLeaveServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const myPost = createAsyncThunk(
  'my/leaves/applications/myPost',
  async (
    data: Omit<LeaveApplication, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myLeaveServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const myPut = createAsyncThunk(
  'my/leaves/application/myPut',
  async (
    data: AtLeast<LeaveApplication, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myLeaveServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const myRemove = createAsyncThunk(
  'my/leaves/application/myRemove',
  async (
    id: LeaveApplication['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myLeaveServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const uploadAttachments = createAsyncThunk(
  'leaves/applications/uploadAttachments',
  async (
    { formData, leaveId }: { formData: FormData; leaveId: number },
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<LeaveApplication> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await myLeaveServices.uploadAttachments(formData, state, leaveId);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default {
  getMy,
  getMyAll,
  myPost,
  myPut,
  myRemove,
  uploadAttachments,
} as const;
