import React, { useEffect, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import { monthsShort } from 'moment';
import TextWithIcons from '../../molecules/TextWithIcons';
import useUserEmployment from '../../../hooks/users/useUserEmployment';
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/arrow-with-tail-down.svg';
import { ReactComponent as OntimeIcon } from '../../../assets/images/icons/check-circle.svg';
import { ReactComponent as LateIcon } from '../../../assets/images/icons/exclamation-circle.svg';
import { ReactComponent as AbsentIcon } from '../../../assets/images/icons/close-circle.svg';
import { ReactComponent as AttendanceIcon } from '../../../assets/images/icons/person.svg';
import { ReactComponent as LeaveIcon } from '../../../assets/images/icons/globe.svg';
import EmployeeTimelogPageListRow from './EmployeeTimelogPageListRow';
import EmployeeAttendance from '../../../store/constants/employees/attendances/employee-attendance.interface';
import time from '../../../utils/time';
import Breadcrumbs from '../../molecules/Breadcrumbs';

type SortByHeaderOptions = '' | 'id' | 'dateShift' | 'timeIn' | 'timeOut' | 'status';

const EmployeeTimelogPageList: React.FC = () => {
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState<{ value: string; label: string }>({
    value: time.currentMonth(),
    label: time.currentMonth(),
  });
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

  const { id } = useParams<{
    id: string;
  }>();

  const { currentUserEmployment, loading } = useUserEmployment(id as string);

  useEffect(() => {
    if (!currentUserEmployment) return;
    const monthOptions = [];
    const startYear = parseInt(currentUserEmployment.dateStart.split('-')[0], 10);
    const startMonth = parseInt(currentUserEmployment.dateStart.split('-')[1], 10);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const monthNames = monthsShort();

    for (let y = startYear; y <= currentYear; y += 1) {
      const monthStart = y === startYear ? startMonth : 1;
      const monthEnd = y === currentYear ? currentMonth : 12;

      for (let m = monthStart; m <= monthEnd; m += 1) {
        monthOptions.push({
          value: `${y}-${m.toString().padStart(2, '0')}`,
          label: `${monthNames[m - 1]} ${y}`,
        });
      }
    }

    setOptions(monthOptions);
  }, [currentUserEmployment]);

  if (loading) return null;

  if (!currentUserEmployment) return null;

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const userAttendances = currentUserEmployment.attendances
    .filter((attendance: EmployeeAttendance) => {
      const attendanceDate = attendance.dateShift;

      if (selectedMonth.value === 'all-time') return true;

      if (time.today() === attendanceDate) return false;

      if (selectedMonth.value !== attendanceDate.split('-').slice(0, 2).join('-')) {
        return false;
      }

      return true;
    })
    .sort((a: EmployeeAttendance, b: EmployeeAttendance) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'id') {
        return sortOrder * a.id - b.id;
      }

      if (sortOption === 'dateShift') {
        return sortOrder * a.dateShift.localeCompare(b.dateShift);
      }

      return sortOrder * (a.id - b.id);
    });

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  const totalAttendances = userAttendances.length;
  const onTimeAttendances = userAttendances.filter(
    (attendance) => attendance.employeeAttendanceDetails.isLate !== true,
  ).length;
  const lateAttendances = totalAttendances - onTimeAttendances;

  return (
    <div className="w-full px-5 organisms-timelog-list">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/attendance/logs">
          <Typography className="crumb">Time logs</Typography>
        </Link>

        <span className="current-crumb capitalize">
          {currentUserEmployment.userInformation.fullName}
        </span>
      </Breadcrumbs>
      <div className="flex justify-around">
        <div className="flex gap-3">
          <AttendanceIcon className="attendanceIcon" />
          <div>
            <p className="muiTypography--headline7 !text-gray-600">Total attendance</p>
            <p className="muiTypography--headline5">{totalAttendances}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <OntimeIcon className="onTimeIcon" />
          <div>
            <p className="muiTypography--headline7 !text-gray-600">On time</p>
            <p className="muiTypography--headline5">{onTimeAttendances}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <LateIcon className="lateIcon" />
          <div>
            <p className="muiTypography--headline7 !text-gray-600">Lates</p>
            <p className="muiTypography--headline5">{lateAttendances}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <AbsentIcon className="absentIcon" />
          <div>
            <p className="muiTypography--headline7 !text-gray-600">Absences</p>
            <p className="muiTypography--headline5">0</p>
          </div>
        </div>
        <div className="flex gap-3">
          <LeaveIcon className="leaveIcon" />
          <div>
            <p className="muiTypography--headline7 !text-gray-600">Leaves</p>
            <p className="muiTypography--headline5">0</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-5">
        <div className="flex gap-5">
          <select
            name="dateRange"
            id="dateRange"
            className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
            value={selectedMonth.value}
            onChange={(e) => {
              setSelectedMonth({ value: e.target.value, label: e.target.value });
            }}
          >
            <option value="all-time">All time</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="timelog-list-header header-items">
        <button
          type="button"
          onClick={() => sortByHeader('id', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">ID</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <ArrowIcon className={`sort-arrow-icon ${sortOption === 'id' ? '' : 'hidden'}`} />
          ) : (
            <ArrowIcon
              className={`sort-arrow-icon ${
                sortOption === 'id' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('dateShift', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Date shift</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <ArrowIcon
              className={`sort-arrow-icon ${sortOption === 'dateShift' ? '' : 'hidden'}`}
            />
          ) : (
            <ArrowIcon
              className={`sort-arrow-icon ${
                sortOption === 'dateShift' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('timeIn', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Time in</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <ArrowIcon className={`sort-arrow-icon ${sortOption === 'timeIn' ? '' : 'hidden'}`} />
          ) : (
            <ArrowIcon
              className={`sort-arrow-icon ${
                sortOption === 'timeIn' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('timeOut', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Time out</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <ArrowIcon className={`sort-arrow-icon ${sortOption === 'timeOut' ? '' : 'hidden'}`} />
          ) : (
            <ArrowIcon
              className={`sort-arrow-icon ${
                sortOption === 'timeOut' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        {/* status is whether the attendance is
         * on time
         * late
         * absent
         */}
        <button
          type="button"
          onClick={() => sortByHeader('status', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Status</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <ArrowIcon className={`sort-arrow-icon ${sortOption === 'status' ? '' : 'hidden'}`} />
          ) : (
            <ArrowIcon
              className={`sort-arrow-icon ${
                sortOption === 'status' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <TextWithIcons variant="h6" className="flex justify-center">
          <span className="flex gap-5">Action</span>
        </TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={userAttendances?.length}
        itemSize={getItemSize}
        height={window.innerHeight - 300}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <EmployeeTimelogPageListRow
            data={userAttendances[index]}
            className={`${index % 2 === 0 ? 'stripped' : ''}`}
            style={style}
            onGenerateClick={() => {}}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default EmployeeTimelogPageList;
