import React from 'react';
import TextWithIcons from './TextWithIcons';
import { ReactComponent as Arrow } from '../../assets/images/icons/arrow-with-tail-down.svg';

export interface HeaderOption<T> {
  value: keyof T;
  label: string;
  defaultOrder?: boolean;
  center?: boolean;
  disable?: boolean;
}

export interface SortableTableHeaderProps<T> {
  sortOption: keyof T | '';
  sortOrderBy: boolean;
  headers: HeaderOption<T>[];
  sortByHeader: (field: keyof T, defaultOrder?: boolean) => void;
  classNames?: string;
}

const SortableTableHeader = <T,>({
  sortOption,
  sortOrderBy,
  headers,
  sortByHeader,
  classNames = '',
}: SortableTableHeaderProps<T>) => (
  <div className={`table-header header-items ${classNames}`}>
    {headers.map((header) => (
      <button
        key={header.value as string}
        type="button"
        onClick={() => sortByHeader(header.value, header.defaultOrder ?? false)}
        className={`flex items-center gap-3 ${header.center ? 'justify-center' : ''}`}
        disabled={header.disable}
      >
        <span className="flex items-center gap-2">
          <TextWithIcons variant="h6">
            <span className="flex gap-5">{header.label}</span>
          </TextWithIcons>

          {sortOption === header.value ? (
            <Arrow
              className={`sort-arrow-icon
              ${sortOption === header.value ? '' : 'hidden'} 
              ${sortOrderBy ? '' : 'sort-arrow-rotate-icon'} 
            `}
            />
          ) : (
            !header.disable && <span className="w-4 h-auto" />
          )}
        </span>
      </button>
    ))}
    <TextWithIcons variant="h6" className="flex justify-center">
      <span className="flex gap-5">Action</span>
    </TextWithIcons>
  </div>
);

export default SortableTableHeader;
