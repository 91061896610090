import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/auths/useAuth';
import ACL from '../../store/constants/utils/acl.interface';
import useOnLoad from '../../hooks/utils/useOnLoad';
import LoadingScreen from './LoadingScreen';

export type ProtectedRouteProps = {
  accessibleTo?: ACL | ACL[];
};

const ProtectedRoute = ({ accessibleTo = undefined }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const {
    authState: { token, acl },
  } = useAuth();

  const [isAccessible, setIsAccessible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (!token) {
      setIsAccessible(false);
      setIsLoading(false);
    } else if (acl) {
      setIsAccessible(
        !accessibleTo || acl === 'superadmin' || (accessibleTo && accessibleTo.includes(acl)),
      );
      setIsLoading(false);
    } else {
      setIsAccessible(false);
      setIsLoading(false);
    }
  }, [accessibleTo, acl, token]);

  useOnLoad(() => {
    if (!token && !isLoading) navigate('/login');
  });

  if (isLoading) return <LoadingScreen />;

  if (!isAccessible) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
