import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Client from '../../constants/clients/client.interface';
import toURLParams from '../../../utils/toURLParams';

export interface DateRange {
  from: string | undefined;
  to: string | undefined;
}

export interface GetAllClientsQueryParams extends ApiGetAllBaseQueryParams {
  dateStartRange?: DateRange;
  dateEndRange?: DateRange;
  isActive?: number | null;
}

const get = async (id: Client['id'], state: StateHeaders): Promise<ApiBaseResponse<Client>> =>
  apiFetch<ApiBaseResponse<Client>>(`clients/${id}`, { state })
    .then((response: ApiBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  {
    page = 1,
    limit = 10,
    search = '',
    dateStartRange = undefined,
    dateEndRange = undefined,
    isActive = 1,
  }: GetAllClientsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Client>> =>
  apiFetch<ApiGetAllBaseResponse<Client>>(
    `clients?${toURLParams({
      limit,
      page,
      search,
      ...(dateStartRange && {
        'dateStart[from]': dateStartRange.from,
        'dateStart[to]': dateStartRange.to,
      }),
      ...(dateEndRange && {
        'dateEnd[from]': dateEndRange.from,
        'dateEnd[to]': dateEndRange.to,
      }),
      isActive,
    })}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<Client, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Client> | void> =>
  apiFetch<ApiBaseResponse<Client>>('clients', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (data: Client, state: StateHeaders): Promise<ApiBaseResponse<Client> | void> =>
  apiFetch<ApiBaseResponse<Client>>(`clients/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<Client, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Client> | void> =>
  apiFetch<ApiBaseResponse<Client>>(`clients/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: Client['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<Client> | void> =>
  apiFetch<ApiBaseResponse<Client>>(`clients/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const checkClientName = async (
  name: Client['name'],
  state: StateHeaders,
): Promise<ApiBaseResponse<Client>> =>
  apiFetch<ApiBaseResponse<Client>>(`clients/check?name=${name}`, { state })
    .then((response: ApiBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllClientEmployees = async (
  id: Client['id'],
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Client>> =>
  apiFetch<ApiGetAllBaseResponse<Client>>(`clients/${id}/employees`, { state })
    .then((response: ApiGetAllBaseResponse<Client>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  getAllClientEmployees,
  post,
  patch,
  put,
  remove,
  checkClientName,
} as const;
