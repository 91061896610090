import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import consoleLog from '../../../utils/consoleLog';

export interface WebsocketState {
  websocket?: WebSocket;
}

const initialState: WebsocketState = {
  websocket: undefined,
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    websocketConnect: (state, action: PayloadAction<string>) => {
      if (!state.websocket) {
        state.websocket = new WebSocket(action.payload);

        state.websocket.onopen = () => {
          state.websocket?.send('WebSocket connected');
        };

        state.websocket.onmessage = (event) => {
          consoleLog('WEBSOCKET', event);
        };

        state.websocket.onclose = () => {
          state.websocket = undefined;
        };
      }
    },
    websocketSend: (state, action: PayloadAction<string>) => {
      if (state.websocket && state.websocket.readyState === WebSocket.OPEN) {
        state.websocket.send(action.payload);
      }
    },
  },
});

export const websocketReducerActions = websocketSlice.actions;

export default websocketSlice.reducer;
