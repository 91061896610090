/* eslint-disable react/require-default-props */
import React, { forwardRef, Ref } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ReactComponent as Print } from '../../../../assets/images/icons/print.svg';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import LeaveFormRequestData from './LeaveFormRequestData';
import useDateTime from '../../../../hooks/utils/useDateTime';

interface LeaveFormPrintType {
  dataToPrint: LeaveApplication;
  convertedDateToString: string;
  employeeName: string;
  position?: string;
  department?: string;
}

const LeaveFormPrint = forwardRef(
  (
    {
      dataToPrint,
      convertedDateToString,
      employeeName,
      position = '',
      department = '',
    }: LeaveFormPrintType,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { getDate } = useDateTime();

    const excludedDateData =
      dataToPrint.excludedDates &&
      dataToPrint.excludedDates.length > 0 &&
      dataToPrint.excludedDates[0] &&
      dataToPrint.excludedDates[dataToPrint.excludedDates.length - 1] &&
      `${getDate(dataToPrint.excludedDates[0])} - ${getDate(
        dataToPrint.excludedDates[dataToPrint.excludedDates.length - 1],
      )}`;

    const handlePrint = () => {
      const componentToPrint = (
        <LeaveFormRequestData
          data={dataToPrint}
          dateString={convertedDateToString as string}
          employeeName={employeeName as string}
          excludedDateData={excludedDateData as string}
          position={position}
          department={department}
        />
      );
      const componentHtml = ReactDOMServer.renderToString(componentToPrint);

      const printWindow = window.open('', '_blank');

      if (printWindow) {
        const printDocument = `
        <html>
          <head>
            <title>Leave Request Form</title>
            <style type="text/css" media="print">
              * {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
              }
              @page
              {
                size: auto;
                margin: 0mm;
              }
              body
              {
                background-color:#FFFFFF;
                border: solid 1px black ;
                height: 100%;
                width: 100%;
              }
            </style>
          </head>
          <body>
            ${componentHtml}
          </body>
        </html>
      `;

        printWindow.document.write(printDocument);
        printWindow.document.close();
        printWindow.print();
      }
    };

    return (
      <button className="textButton" type="button" onClick={handlePrint} ref={ref}>
        <Print className="w-6 h-auto" />
      </button>
    );
  },
);

export default LeaveFormPrint;
