import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useLeaves from '../../../hooks/leave/useLeaves';
import LeaveStatusOption from '../../../store/constants/leaves/leave-status-option';

export interface SelectStatusProps extends Partial<AutoSearchSelectProps<LeaveStatusOption>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectLeaveStatusOption: React.FC<SelectStatusProps> = ({
  id,
  name,
  placeholder = 'Select Status',
  className = '',
  ...props
}) => {
  const { leavesState } = useLeaves();

  const leaveStatus = leavesState.leaveStatusOptions.slice().map((status) => ({
    ...status,
    value: status.value,
    label: status.label,
  }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-leave modal-select ${className}`}
      placeholder={placeholder}
      data={leaveStatus}
      isSearchable
    />
  );
};

export default SelectLeaveStatusOption;
