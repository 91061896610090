import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Incentives from '../../constants/my/incentives.interface';

export type GetAllIncentivesQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: Incentives['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<Incentives>> =>
  apiFetch<ApiBaseResponse<Incentives>>(`incentives/${id}`, { state })
    .then((response: ApiBaseResponse<Incentives>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllIncentivesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Incentives>> =>
  apiFetch<ApiGetAllBaseResponse<Incentives>>(
    `incentives?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Incentives>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<Incentives, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Incentives> | void> =>
  apiFetch<ApiBaseResponse<Incentives>>('incentives', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Incentives>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: Incentives,
  state: StateHeaders,
): Promise<ApiBaseResponse<Incentives> | void> =>
  apiFetch<ApiBaseResponse<Incentives>>(`incentives/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Incentives>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<Incentives, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Incentives> | void> =>
  apiFetch<ApiBaseResponse<Incentives>>(`incentives/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Incentives>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: Incentives['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<Incentives> | void> =>
  apiFetch<ApiBaseResponse<Incentives>>(`incentives/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Incentives>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
