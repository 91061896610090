import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Hmo from '../../constants/hmo/hmo.interface';
import hmoServices, { GetAllHmosQueryParams } from '../../services/hmo/hmoServices';

const get = createAsyncThunk(
  'hmo/get',
  async (id: Hmo['id'], { getState, rejectWithValue }): Promise<ApiBaseResponse<Hmo> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'hmo/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllHmosQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Hmo> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'hmo/post',
  async (
    data: Omit<Hmo, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Hmo> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'hmo/put',
  async (
    data: AtLeast<Hmo, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Hmo> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'hmo/remove',
  async (id: Hmo['id'], { getState, rejectWithValue }): Promise<ApiBaseResponse<Hmo> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const reports = createAsyncThunk(
  'hmo/reports',
  async ({ search }: { search: string | number }, { getState }): Promise<Blob> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoServices.reports({ search }, state);
      // eslint-disable-next-line
    } catch (err: any) {
      // The validation error that is stored in the authReducer state error
      throw new Error(err.message);
    }
  },
);

export default { get, getAll, post, put, remove, reports } as const;
