import { useAppDispatch, useAppSelector } from '../../store/store';
import overtimeActions from '../../store/actions/attendances/overtimeActions';
import {
  overtimesReducerActions,
  OvertimeState,
} from '../../store/reducers/attendances/overtimesReducer';
import {
  ExportOvertimeQueryParams,
  GetAllOvertimesQueryParams,
} from '../../store/services/attendances/overtimeServices';
import EmployeeOvertime from '../../store/constants/employees/attendances/employee-overtime.interface';

const useOvertimes = () => {
  const dispatch = useAppDispatch();
  const overtimeState: OvertimeState = useAppSelector((state) => state.overtimes);

  const resetOvertimeState = () => {
    dispatch(overtimesReducerActions.resetOvertimeState());
  };

  const setOvertimeState = (state: Partial<OvertimeState>) => {
    dispatch(overtimesReducerActions.setOvertimeState(state));
  };

  const get = async (id: EmployeeOvertime['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.getAll({}));

  const post = async (data: Omit<EmployeeOvertime, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.post(data));

  const put = async (data: Partial<EmployeeOvertime>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.put(data));

  const remove = async (id: EmployeeOvertime['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.remove(id));

  const getAllOvertimes = async (params = {} as GetAllOvertimesQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.getAllOvertimes(params));

  const approveEmployeeOvertime = async (data: Partial<EmployeeOvertime>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.approveEmployeeOvertime(data));

  const exportOvertime = async (params = {} as ExportOvertimeQueryParams) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(overtimeActions.exportOvertime(params));

  return {
    resetOvertimeState,
    overtimeState,
    setOvertimeState,
    get,
    getAll,
    post,
    put,
    remove,
    getAllOvertimes,
    approveEmployeeOvertime,
    exportOvertime,
  };
};

export default useOvertimes;
