import { useState, useEffect } from 'react';

interface TimeElapsed {
  timeDiff: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface UseTimerProps {
  initialTime?: Date;
  otDuration?: number;
}

const useTimer = ({ initialTime = new Date(), otDuration = 0 }: UseTimerProps) => {
  const [timeElapsed, setTimeElapsed] = useState<TimeElapsed>({
    timeDiff: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const durationInSeconds = otDuration;
    const initialTimeInSeconds = new Date(initialTime).getTime() / 1000;
    const currentTimeInSeconds = new Date().getTime() / 1000;
    const elapsed = currentTimeInSeconds - initialTimeInSeconds;

    const updateElapsedTime = (newElapsed: number) => {
      setTimeElapsed((prevState) => ({
        ...prevState,
        timeDiff: newElapsed,
        days: Math.floor(newElapsed / (3600 * 24)),
        hours: Math.floor((newElapsed % (3600 * 24)) / 3600),
        minutes: Math.floor((newElapsed % 3600) / 60),
        seconds: Math.floor(newElapsed % 60),
      }));
    };

    if (elapsed >= durationInSeconds) {
      updateElapsedTime(durationInSeconds);
    } else {
      updateElapsedTime(elapsed);
      const timer = setInterval(() => {
        const clockTimeInSeconds = new Date().getTime() / 1000;
        const updatedElapsed = clockTimeInSeconds - initialTimeInSeconds;
        if (updatedElapsed >= durationInSeconds) {
          clearInterval(timer);
          updateElapsedTime(durationInSeconds);
        } else {
          updateElapsedTime(updatedElapsed);
        }
      }, 1000);
      return () => clearInterval(timer);
    }

    return undefined;
  }, [initialTime, otDuration]);

  return timeElapsed;
};

export default useTimer;
