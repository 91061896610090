import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { Link, useParams } from 'react-router-dom';
import { Chip, Typography } from '@material-tailwind/react';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import TextWithIcons from '../../molecules/TextWithIcons';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useTeams from '../../../hooks/teams/useTeams';
import Team from '../../../store/constants/teams/team.interface';
import DepartmentTeamListRow from './DepartmentTeamListRow';
import SearchSvg from '../../../assets/images/icons/search.svg';
import useDepartment from '../../../hooks/departments/useDepartment';
import useDepartments from '../../../hooks/departments/useDepartments';
import DepartmentTeamFormModal from './DepartmentTeamFormModal';
import DepartmentTeamConfirmation from './DepartmentTeamConfirmation';

const DepartmentTeamList: React.FC = () => {
  const { teamsState, getAll: getAllTeams, removeDepartmentFromTeam } = useTeams();
  const { getAll: getAllDepartments } = useDepartments();

  const [searchValue, setSearchValue] = useState('');
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(undefined);
  const [departmentTeamData, setDepartmentTeamData] = useState<Team | undefined>(undefined);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const { id } = useParams<{
    id: string;
  }>();

  const { currentDepartment: department, loading } = useDepartment(id as string);

  useOnLoad(() => {
    getAllTeams().then();
    getAllDepartments().then();
  });

  if (loading) return null;

  if (!department) return null;

  const departmentTeams = teamsState.teams
    .filter((team: Team) =>
      team.department?.name.toLowerCase().startsWith(department.name.toLowerCase()),
    )
    .filter((team: Team) => team.name.toLowerCase().includes(debouncedSearchValue.toLowerCase()))
    .sort((a: Team, b: Team) => b.id - a.id);

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const DepartmentTeam = (team: Team) => {
    setDepartmentTeamData(team);
    openConfirmationModal();
  };

  /**
   * @todo
   *
   * need to create a removal functionality on department from a team
   *
   */

  const handleRemoveDepartmentTeam = (team: Team) => {
    removeDepartmentFromTeam(team).then();
  };

  const onTeamModalClose = () => setSelectedTeam(undefined);
  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  return (
    <div className="organisms-department-employee-list w-full">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/departments">
          <Typography className="crumb">Departments</Typography>
        </Link>
        <Typography className="crumb">{department.name}</Typography>
        <span className=" flex items-center gap-2">
          <Typography className="current-crumb">Teams</Typography>{' '}
          <Chip className="chip-style" value={departmentTeams.length} variant="ghost" />
        </span>
      </Breadcrumbs>
      <div className="department-employee-list-container pr-10">
        <div className="flex justify-between pb-5 space-x-10">
          <DepartmentTeamFormModal
            className="rounded-lg shadow-lg muiButton--primary"
            team={selectedTeam}
            teamDepartment={department}
            onClose={onTeamModalClose}
          />
          <DepartmentTeamConfirmation
            open={confirmationModalOpen}
            onClose={closeConfirmationModal}
            handleDeleteTeam={handleRemoveDepartmentTeam}
            departmentTeamData={departmentTeamData as Team}
          />
          <div className="search-body">
            <input
              onKeyDown={onSearchKeyDown}
              type="text"
              className="search-body__input search-bar"
              placeholder="Search department team..."
              onChange={handleSearch}
            />
            <img
              style={{ filter: 'invert(0) invert(1)' }}
              className="search-body__icon"
              src={SearchSvg}
              alt="Search for Department team"
            />
          </div>
        </div>
        <div className="department-team-list-header table-header">
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Team</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">FTE</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Actions</div>
          </TextWithIcons>
        </div>

        <VariableSizeList
          itemCount={departmentTeams.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <DepartmentTeamListRow
              data={departmentTeams[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              setSelectedTeam={() => setSelectedTeam(departmentTeams[index])}
              DepartmentTeam={DepartmentTeam}
            />
          )}
        </VariableSizeList>
      </div>
    </div>
  );
};

export default DepartmentTeamList;
