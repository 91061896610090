import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useLoanTypes from '../../../hooks/loans/useLoanTypes';
import LoanType from '../../../store/constants/loans/loan-type.interface';

export interface SelectLoanTypeProps extends Partial<AutoSearchSelectProps<LoanType>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectLoanType: React.FC<SelectLoanTypeProps> = ({
  id,
  name,
  placeholder = 'Select Loan Type',
  className = '',
  ...props
}) => {
  const { loanTypesState, getAll } = useLoanTypes();

  useOnLoad(() => {
    getAll().then();
  });

  const loanTypes = loanTypesState.loanTypes
    .slice()
    .sort((a, b) => b.id - a.id)
    .map((loanType) => ({
      ...loanType,
      value: loanType.id,
      label: loanType.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-loan-type modal-select ${className}`}
      isSearchable
      placeholder={placeholder}
      data={loanTypes}
    />
  );
};

export default SelectLoanType;
