import React from 'react';
import { ConfigProvider, Table } from 'antd';
import usePayrolls from '../../../../../../hooks/payroll/usePayrolls';
import getThirteenthMonthSemiAnnualTableColumns from './ThirteenthMonthSemiAnnualTableColumn';
import { ReactComponent as Calendar } from '../../../../../../assets/images/icons/calendar.svg';

const ThirteenthMonthSemiAnnualTable: React.FC = () => {
  const { payrollsState } = usePayrolls();
  const thirteenthMonthSemiAnnualTableColumns = getThirteenthMonthSemiAnnualTableColumns();

  const showTotal = (totalCount: number, range: [number, number]) => (
    <div className="ant-custom-show-total">{`Displaying ${range[0]}-${range[1]} of ${totalCount} items`}</div>
  );

  const titleContent = () => (
    <div className="flex items-center justify-between px-5">
      <div className="flex items-center gap-2">
        <Calendar className="w-5 h-auto" />
        <span>Semi Annual</span>
      </div>
      <span>Employee List</span>
    </div>
  );

  return (
    <div className="px-2 payroll-container-parent thirteenth-container">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              /* here is your component tokens */
              rowHoverBg: '#3e3e3e !important',
            },
          },
        }}
      >
        <Table
          className="payroll-table-content"
          columns={thirteenthMonthSemiAnnualTableColumns}
          dataSource={payrollsState.employmentPayrolls.map((item) => ({ ...item, key: item.id }))}
          size="small"
          tableLayout="auto"
          scroll={{ x: 'max-content', y: 'calc(100vh - 19rem)' }}
          title={() => titleContent()}
          pagination={{
            position: ['bottomRight'],
            defaultPageSize: 20,
            showTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50'],
          }}
        />
      </ConfigProvider>
    </div>
  );
};

export default ThirteenthMonthSemiAnnualTable;
