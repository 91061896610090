import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import SubmitSvg from '../../../../assets/images/icons/submit.svg';
import SchedulerAddForm from '../SchedulerAddForm';
import useSchedules from '../../../../hooks/schedule/useSchedules';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import DeleteConfirmationBanner from '../../../molecules/DeleteConfirmationBanner';
import EmployeeSchedule from '../../../../store/constants/employees/schedules/employee-schedule.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';

export interface SchedulerFormProps {
  data?: EmployeeSchedule;
}

const SchedulerViewContent: React.FC<SchedulerFormProps> = ({ data = undefined }) => {
  const deleteMessage = {
    pending: 'Pending',
    success: 'User schedule is successfully deleted.',
    error: 'Error on deleting user schedule.',
  };

  const { post, put, schedulesState, getAll, remove } = useSchedules();

  const [userEmploymentScheduleForm, setUserEmploymentScheduleForm] = useState<
    EmployeeSchedule | undefined
  >();

  const onDeleteClick = async (schedule: EmployeeSchedule) => remove(schedule.id);

  const handleSubmit = async (values: Partial<EmployeeSchedule>) => {
    if (userEmploymentScheduleForm) {
      await toastNotification({
        action: put({
          ...userEmploymentScheduleForm,
          ...values,
        } as Partial<EmployeeSchedule>),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Schedule information is successfully updated.',
          error: 'Error on updating Schedule information.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as EmployeeSchedule),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Schedule information is successfully added.',
          error: 'Error on adding Schedule information.',
        },
      });
    }
  };

  const [showAddScheduleForm, setShowAddScheduleForm] = useState(false);
  const [showEditTools, setShowEditTools] = useState(false);
  const [showInputAction, setShowInputAction] = useState(false);

  const handleShowAddScheduleForm = () => {
    setShowAddScheduleForm(true);
    setShowEditTools(false);
    setShowInputAction(false);

    if (showAddScheduleForm) {
      setShowAddScheduleForm(false);
    }
  };

  const handleShowEditTools = () => {
    setShowEditTools(true);
    setShowInputAction(true);
    setShowAddScheduleForm(false);

    if (showEditTools && showInputAction) {
      setShowEditTools(false);
      setShowInputAction(false);
    }
  };

  useEffect(() => {
    if (data) {
      setUserEmploymentScheduleForm({
        ...data,
      });
    }
  }, [data]);

  const employeeNumber = schedulesState.currentEmployeeSchedule?.employeeNumber;

  useOnLoad(() => {
    getAll({ employeeNumber });
  });

  // @todo
  // 1. delete modifications
  // 2. wrong data for delete
  return (
    <div className="scheduler-view-content">
      <div className="flex gap-5">
        <Tooltip content="Add schedule" className="bg-[#343434]">
          <button type="button" className="textButton" onClick={handleShowAddScheduleForm}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
            </svg>
          </button>
        </Tooltip>
        <Tooltip content="Edit" className="bg-[#343434]">
          <button type="button" className="textButton" onClick={handleShowEditTools}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
        </Tooltip>
      </div>
      <div className="scheduler-form-edit">
        <Formik
          initialValues={{
            ...userEmploymentScheduleForm,
            employeeNumber: schedulesState.currentEmployeeSchedule?.employeeNumber,
          }}
          onSubmit={handleSubmit}
        >
          <Form className="sample-form">
            {schedulesState.schedules
              ?.filter((schedule) => schedule.employeeNumber === employeeNumber)
              .map((employeeSchedule) => (
                <div className="bg-[#343434] shadow-lg rounded-lg mt-5">
                  {showEditTools && (
                    <div className="ml-2">
                      <DeleteConfirmationBanner
                        data={userEmploymentScheduleForm?.id}
                        label={userEmploymentScheduleForm?.id as unknown as string}
                        message={deleteMessage}
                        onDeleteConfirm={async () => onDeleteClick}
                      />
                      <Tooltip content="Save" className="bg-[#343434]">
                        <button type="submit" className="p-2 textButton">
                          <img
                            className="w-5 h-5 trash-icon"
                            src={SubmitSvg}
                            alt={`Delete ${employeeSchedule}`}
                          />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                  <div className="flex gap-8 p-2">
                    <div className="scheduler-field">
                      <label htmlFor="days">
                        <Typography className="schedule-popover__heading">Days:</Typography>
                      </label>
                      {showInputAction ? (
                        <Field
                          type="text"
                          id="days"
                          name="days"
                          className="w-20 rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                          required
                        />
                      ) : (
                        <Typography className="schedule-popover__content">
                          {userEmploymentScheduleForm?.days ?? 'N/A'}
                        </Typography>
                      )}

                      <ErrorMessage name="days" component="div" />
                    </div>
                    <div className="scheduler-field">
                      <label htmlFor="time">
                        <Typography className="schedule-popover__heading">Time:</Typography>
                      </label>
                      {showInputAction ? (
                        <Field
                          type="text"
                          id="time"
                          name="time"
                          className="w-16 rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                          required
                        />
                      ) : (
                        <Typography className="schedule-popover__content">
                          {userEmploymentScheduleForm?.timeIn ?? 'N/A'}
                        </Typography>
                      )}

                      <ErrorMessage name="time" component="div" />
                    </div>
                    <div className="scheduler-field">
                      <label htmlFor="break">
                        <Typography className="schedule-popover__heading">Break:</Typography>
                      </label>
                      {showInputAction ? (
                        <Field
                          type="text"
                          id="break"
                          name="break"
                          className="w-10 rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                          required
                        />
                      ) : (
                        <Typography className="schedule-popover__content">
                          {userEmploymentScheduleForm?.break ?? 'N/A'}
                        </Typography>
                      )}

                      <ErrorMessage name="break" component="div" />
                    </div>

                    <div className="scheduler-field">
                      <label htmlFor="scheduleType">
                        <Typography className="schedule-popover__heading">
                          Schedule type:
                        </Typography>
                      </label>
                      {
                        // @todo use SelectScheduleType
                        showInputAction ? (
                          <Field
                            type="text"
                            id="scheduleType"
                            name="scheduleType"
                            className="w-16 rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                            required
                          />
                        ) : (
                          <Typography className="schedule-popover__content">
                            {userEmploymentScheduleForm?.scheduleTypeId ?? 'N/A'}
                          </Typography>
                        )
                      }

                      <ErrorMessage name="scheduleType" component="div" />
                    </div>
                    <div className="scheduler-field">
                      <label htmlFor="temporary">
                        <Typography className="schedule-popover__heading">Temporary:</Typography>
                      </label>
                      {showInputAction ? (
                        <Field
                          type="text"
                          id="temporary"
                          name="temporary"
                          className="w-10 rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                          required
                        />
                      ) : (
                        <Typography className="schedule-popover__content">
                          {userEmploymentScheduleForm?.temp ?? 'N/A'}
                        </Typography>
                      )}

                      <ErrorMessage name="temporary" component="div" />
                    </div>
                    <div className="scheduler-field">
                      <label htmlFor="effective">
                        <Typography className="schedule-popover__heading">Effective:</Typography>
                      </label>
                      {showInputAction ? (
                        <Field
                          type="date"
                          id="effective"
                          name="effective"
                          value={userEmploymentScheduleForm?.dateStart}
                          className="w-24 rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                          required
                        />
                      ) : (
                        <Typography className="schedule-popover__content">
                          {userEmploymentScheduleForm?.dateStart ?? 'N/A'}
                        </Typography>
                      )}

                      <ErrorMessage name="effective" component="div" />
                    </div>

                    <div className="scheduler-field">
                      <label htmlFor="expiry">
                        <Typography className="schedule-popover__heading">Expiry:</Typography>
                      </label>
                      {showInputAction ? (
                        <Field
                          type="date"
                          id="expiry"
                          name="expiry"
                          value={userEmploymentScheduleForm?.dateEnd}
                          className="w-24 rounded-md bg-[#343434] border border-gray-600 text-[0.65rem] text-gray-300 p-1"
                          required
                        />
                      ) : (
                        <Typography className="schedule-popover__content">
                          {userEmploymentScheduleForm?.dateEnd ?? 'N/A'}
                        </Typography>
                      )}

                      <ErrorMessage name="expiry" component="div" />
                    </div>
                  </div>
                </div>
              ))}
          </Form>
        </Formik>
      </div>

      {showAddScheduleForm && (
        <div className="mt-10">
          <div className="bg-[#343434] shadow-lg rounded-lg p-1">
            <SchedulerAddForm />
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulerViewContent;
