import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import PayslipConcernActions from '../../actions/my/transactionFeedActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import PayslipConcern from '../../constants/my/payslip-concern.interface';

export interface PayslipConcernsState {
  payslipConcerns: PayslipConcern[];
}

const initialState: PayslipConcernsState = {
  payslipConcerns: [
    {
      id: 1,
      Subject: 'Monthly Payslip',
      Contents: 'Your monthly payslip details.',
      Payperiod: 1,
      Status: 'Pending',
      Date: new Date(),
    },
    {
      id: 2,
      Subject: 'Bonus Information',
      Contents: 'Details about the bonus payment.',
      Payperiod: 2,
      Status: 'Approved',
      Date: new Date(),
    },
    {
      id: 3,
      Subject: 'Tax Deductions',
      Contents: 'Information about tax deductions for this period.',
      Payperiod: 3,
      Status: 'Rejected',
      Date: new Date(),
    },
  ],
};

const getPayslipConcernData = (payslipConcern: Partial<PayslipConcern>) => ({
  ...payslipConcern,
});

const payslipConcernsSlice = createSlice({
  name: 'payslipConcerns',
  initialState,
  reducers: {
    resetPayslipConcernsState: () => ({
      ...initialState,
    }),
    setPayslipConcernsState: (state, action: PayloadAction<Partial<PayslipConcernsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertPayslipConcern: (state, action: PayloadAction<Partial<PayslipConcern>>) => {
      const data: PayslipConcern = getPayslipConcernData(action.payload) as PayslipConcern;

      if (typeof data.id === 'undefined') return;

      state.payslipConcerns = upsertData(state.payslipConcerns, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PayslipConcernActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<PayslipConcern>;
      consoleLog('PAYSLIP_CONCERN REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.payslipConcerns = upsertData(state.payslipConcerns, data);
      }
    });
    builder.addCase(PayslipConcernActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('PAYSLIP_CONCERN REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<PayslipConcern>;

      if (status === 503) return;

      if (data) {
        state.payslipConcerns = data;
      }
    });
    builder.addCase(PayslipConcernActions.get.fulfilled, (state, { payload }) => {
      consoleLog('PAYSLIP_CONCERN REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<PayslipConcern>;

      if (typeof data.id === 'undefined') return;
      state.payslipConcerns = upsertData(state.payslipConcerns, data);
    });
    builder.addCase(PayslipConcernActions.put.fulfilled, (state, { payload }) => {
      consoleLog('PAYSLIP_CONCERN REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<PayslipConcern>;

      if (typeof data.id === 'undefined') return;
      state.payslipConcerns = upsertData(state.payslipConcerns, data);
    });
    builder.addCase(PayslipConcernActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('PAYSLIP_CONCERN REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<PayslipConcern>;

      if (typeof data.id === 'undefined') return;
      state.payslipConcerns = state.payslipConcerns.filter(
        (payslipConcern) => payslipConcern.id !== data.id,
      );
    });
  },
});

export const payslipConcernsReducerActions = payslipConcernsSlice.actions;
export default payslipConcernsSlice.reducer;
