import React from 'react';
import UserTimeInModal from './userTimeIn/UserTimeInModal';
import UserNoScheduleModal from './UserNoScheduleModal';
import useAttendance from '../../../../hooks/attendances/useAttendance';
import useOvertime from '../../../../hooks/attendances/useOvertime';
import UserAttendanceActiveLeave from './userActiveLeave/UserAttendanceActiveLeave';

const UserScheduleNotificationModals: React.FC = () => {
  const { attendanceState } = useAttendance();
  const { overtimeDuration } = useOvertime();

  if (overtimeDuration.hours > 0 || overtimeDuration.minutes > 0) {
    return null;
  }

  return (
    <>
      {attendanceState.modals?.showTimeInModal && <UserTimeInModal />}

      {attendanceState.modals?.showNoScheduleModal && !attendanceState.myOverTimeDetail && (
        <UserNoScheduleModal />
      )}

      {attendanceState.modals?.showActiveLeaveModal && (
        <UserAttendanceActiveLeave
          activeLeaveApplication={attendanceState.activeLeaveApplication}
        />
      )}
    </>
  );
};

export default UserScheduleNotificationModals;
