import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-tailwind/react';
import { ExpectedAttendanceTime } from '../../../../../store/reducers/attendances/attendancesReducer';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import useAppInitialization from '../../../../../hooks/utils/useAppInitialization';
import useTimeRemaining from '../../../../../hooks/utils/useTimeRemaining';
import UserOvertimeModal from '../userOvertime/UserOvertimeModal';
import useTheme from '../../../../../hooks/utils/useTheme';
import Spinner from '../../../../atoms/Spinner';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';

interface UserNextTimeInProps {
  expectedTimeIn?: ExpectedAttendanceTime;
}

let timer: string | number | NodeJS.Timeout | undefined;

const UserNextTimeIn: React.FC<UserNextTimeInProps> = ({
  expectedTimeIn = undefined,
}: UserNextTimeInProps) => {
  const { init } = useAppInitialization();
  const { theme } = useTheme();
  const { timeRemaining, calculateTimeRemaining, setTimeRemaining, getTimeRemainingFormat } =
    useTimeRemaining(expectedTimeIn);

  const [showOvertime, setShowOvertime] = useState(false);
  const [flipped, setFlipped] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogOut = () => {
    setShowOvertime(false);
    setFlipped(false);
    setOpen(!open);
  };

  const handleOvertime = () => {
    setShowOvertime(!showOvertime);
    setFlipped(true);
    setOpen(!open);
  };

  useOnLoad(() => {
    if (init && expectedTimeIn) {
      timer = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
    }

    return () => clearInterval(timer);
  });

  return (
    <>
      <button
        type="button"
        onClick={handleOvertime}
        disabled={!timeRemaining?.timeDiff}
        className={`attendance-next-time-in__button ${!timeRemaining?.timeDiff ? 'no-hover' : ''}`}
      >
        <span className={`user-attendance-action__wrapper ${flipped ? 'clicked' : ''}`}>
          <span className="flex flex-col user-attendance-action__side front--time-in">
            {!timeRemaining?.timeDiff ? (
              <Spinner />
            ) : (
              <>
                <span className="user-attendance-action__heading-text">NEXT TIME IN</span>
                {timeRemaining?.timeDiff && timeRemaining.timeDiff > 0 && (
                  <span className="user-attendance-action__heading-text">
                    {getTimeRemainingFormat()}
                  </span>
                )}
              </>
            )}
          </span>
          <span className="user-attendance-action__side back--overtime">
            <span className="user-attendance-action__heading-text">OVERTIME</span>
          </span>
        </span>
      </button>

      <Dialog
        open={open}
        size="xs"
        handler={handleLogOut}
        className={`user-attendance-action__prompt-container ${theme}`}
      >
        <div className="close-prompt-button">
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={() => {
              setOpen(false);
              setShowOvertime(false);
              setFlipped(false);
            }}
          >
            <Close className="h-5 w-5" />
          </IconButton>
        </div>
        {showOvertime && (
          <UserOvertimeModal
            handleOnClose={() => {
              setOpen(false);
              setShowOvertime(false);
              setFlipped(false);
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default UserNextTimeIn;
