import React, { useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-datepicker';
import { ErrorMessage, Form, Formik } from 'formik';
import { Dialog, DialogHeader, DialogBody, IconButton } from '@material-tailwind/react';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';
import usePayrolls from '../../../../../hooks/payroll/usePayrolls';
import { ReactComponent as QuestionMark } from '../../../../../assets/images/icons/question-mark-circle.svg';

interface GenerateThirteenthMonthProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const GenerateThirteenthMonth: React.FC<GenerateThirteenthMonthProps> = ({ isOpen, setOpen }) => {
  const { generateThirteenMonth } = usePayrolls();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    moment().startOf('year').toDate(),
    new Date(),
  ]);

  const generateThirteenthMonth = () => {
    generateThirteenMonth({
      to: moment(dateRange[0]).format('YYYY-MM-DD'),
      from: moment(dateRange[1]).format('YYYY-MM-DD'),
    });
    setOpen(false);
  };

  return (
    <Dialog
      size="xs"
      open={isOpen}
      handler={setOpen}
      className="bg-[#343434] shadow-none p-10 modal-size"
    >
      <div className="m-2 p-0 absolute flex top-0 right-0">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={() => setOpen(false)}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex gap-3">
        <QuestionMark className="modal-icons text-[#f2f2f2]" />

        <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
          Generate 13th Month Pay
        </DialogHeader>
      </div>
      <DialogBody divider className="p-0">
        <div className="">
          <div className="">
            <Formik initialValues={{}} onSubmit={() => {}}>
              <Form className="">
                {/* FIRST SECTION */}
                <div className="mt-4 mb-4 form-section">
                  <div className="flex flex-col justify-center w-full">
                    <div className="mb-6">
                      <div className="datePicker-main-parent">
                        <DateRangePicker
                          className="rounded-lg datepicker-list-layout datepicker-list-date bg-[#2c2b2a]"
                          startDate={dateRange[0]}
                          endDate={dateRange[1]}
                          onChange={(update) => {
                            setDateRange(update);
                          }}
                          selectsRange
                          isClearable
                          placeholderText="Select Date Range..."
                        />
                      </div>
                      <ErrorMessage name="company" component="div" />
                    </div>
                  </div>
                </div>
                {/* Buttons */}
                <div className="flex space-x-2 w-max">
                  <div className="flex justify-center w-full">
                    <button
                      onClick={() => generateThirteenthMonth()}
                      type="button"
                      className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                    >
                      Generate
                    </button>
                  </div>
                  <div className="flex justify-center w-max">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
};
export default GenerateThirteenthMonth;
