import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Company from '../../constants/companies/company.interface';

export type GetAllCompaniesQueryParams = ApiGetAllBaseQueryParams;

const get = async (id: Company['id'], state: StateHeaders): Promise<ApiBaseResponse<Company>> =>
  apiFetch<ApiBaseResponse<Company>>(`companies/${id}`, { state })
    .then((response: ApiBaseResponse<Company>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllCompaniesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Company>> =>
  apiFetch<ApiGetAllBaseResponse<Company>>(
    `companies?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Company>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<Company, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Company> | void> =>
  apiFetch<ApiBaseResponse<Company>>('companies', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Company>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: Company,
  state: StateHeaders,
): Promise<ApiBaseResponse<Company> | void> =>
  apiFetch<ApiBaseResponse<Company>>(`companies/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Company>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<Company, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Company> | void> =>
  apiFetch<ApiBaseResponse<Company>>(`companies/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Company>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: Company['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<Company> | void> =>
  apiFetch<ApiBaseResponse<Company>>(`companies/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Company>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
