import apiFetch, { StateHeaders } from '../../../../utils/apiFetch';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import UserEmploymentPosition from '../../../constants/employees/positions/employee-position.interface';
import ApiGetAllBaseQueryParams from '../../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import AtLeast from '../../../../types/atleast';
import User from '../../../constants/users/user.interface';

export type GetAllUserEmploymentPositionsQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: UserEmploymentPosition['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentPosition>> =>
  apiFetch<ApiBaseResponse<UserEmploymentPosition>>(`employees/positions/${id}`, { state })
    .then((response: ApiBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllUserEmploymentPositionsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<UserEmploymentPosition>> =>
  apiFetch<ApiGetAllBaseResponse<UserEmploymentPosition>>(
    `employees/positions?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllUsersByEmploymentPosition = async (
  positionId: UserEmploymentPosition['employmentPositionId'],
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<User>> =>
  apiFetch<ApiGetAllBaseResponse<User>>(`employees/positions/${positionId}/users`, { state })
    .then((response: ApiGetAllBaseResponse<User>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getUsers = async (
  id: UserEmploymentPosition['employeeNumber'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentPosition>> =>
  apiFetch<ApiBaseResponse<UserEmploymentPosition>>(`employees/positions/getUser/${id}`, { state })
    .then((response: ApiBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);
      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<UserEmploymentPosition, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentPosition>>('employees/positions', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: UserEmploymentPosition,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentPosition>>(`employees/positions/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<UserEmploymentPosition, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentPosition>>(`employees/positions/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: UserEmploymentPosition['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentPosition>>(`employees/positions/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const updateEmployeePosition = async (
  data: AtLeast<UserEmploymentPosition, 'employeeNumber'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<UserEmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<UserEmploymentPosition>>(`employees/positions/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<UserEmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  getAllUsersByEmploymentPosition,
  updateEmployeePosition,
  getUsers,
  post,
  patch,
  put,
  remove,
} as const;
