import React, { HTMLAttributes } from 'react';

export interface ProfileStatsCardItemProps extends HTMLAttributes<HTMLDivElement> {
  // temporary, original= icon: string;
  icon: React.ReactNode;
  numerator: number;
  denominator: number;
  label: string;
}

const ProfileStatsCardItem: React.FC<ProfileStatsCardItemProps> = ({
  className = '',
  icon,
  numerator,
  denominator,
  label,
}: ProfileStatsCardItemProps) => (
  <div
    className={`user-profile-stats-card-item flex items-center rounded-lg shadow-lg ${className}`}
  >
    {/* temporary, original = <img src={icon} alt={label} /> */}
    <span>{icon}</span>

    <div>
      <div className="user-profile-card-wrapper">
        <span className="user-profile-stats-card-item-numerator">{numerator}</span> /{' '}
        <span className="user-profile-stats-card-item-denomenator">{denominator}</span>
      </div>
      <h4 className="user-profile-stats-card-item-label">{label}</h4>
    </div>
  </div>
);

export default ProfileStatsCardItem;
