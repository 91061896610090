import React from 'react';
import AttendancePageList from './AttendancePageList';
import ManagementHeading from '../../atoms/ManagementHeading';

const AttendancePage: React.FC = () => (
  <div className="w-full flex">
    <div className="w-full flex flex-col">
      <div className="m-2 title">
        <ManagementHeading title="Attendance" subtitle="Report" />
      </div>
      <div className="organisms-attendance-management w-full">
        <AttendancePageList />
      </div>
    </div>
  </div>
);

export default AttendancePage;
