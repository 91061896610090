import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Typography } from '@material-tailwind/react';
import useUserForm from '../../../hooks/users/useUserForm';
import Bank from '../../../store/constants/banks/bank.interface';
import User from '../../../store/constants/users/user.interface';
import SelectEmploymentType from '../../atoms/dropdowns/SelectEmploymentType';
import EmploymentType from '../../../store/constants/employments/employment-type.interface';
import SelectBank from '../../atoms/dropdowns/SelectBank';
import UserBanks from '../../../store/constants/users/user-bank.interface';
import Employee from '../../../store/constants/employees/employee.interface';
import EmployeeSalaryDetail from '../../../store/constants/employees/salaries/employee-salary-details.interface';
import EmploymentScheduleType from '../../../store/constants/employments/schedules/employment-schedule-type.interface';
import SelectEmploymentScheduleType from '../../atoms/dropdowns/SelectEmploymentScheduleType';
import { ReactComponent as PersonIcon } from '../../../assets/images/icons/person.svg';

interface UserEmploymentSummaryProps {
  submitButton: React.RefObject<HTMLButtonElement>;
}

const UserEmploymentPayrollForm: React.FC<UserEmploymentSummaryProps> = ({ submitButton }) => {
  const {
    userFormState: { user },
    setUserFormState,
    isFirstStep,
    isLastStep,
    handlePrev,
    handleNext,
  } = useUserForm();

  const [formError, setFormError] = useState('');

  const [selectedBank, setSelectedBank] = useState<Bank | undefined>(
    user.bank?.bank
      ? ({
          ...user.bank?.bank,
          value: user?.bank?.bank?.id,
          label: user?.bank?.bank?.name,
        } as Bank)
      : undefined,
  );

  const [selectedEmploymentType, setSelectedEmploymentType] = useState<EmploymentType | undefined>(
    user?.employment?.employmentType
      ? ({
          ...user?.employment?.employmentType,
          value: user?.employment?.employmentType?.id,
          label: user?.employment?.employmentType?.name,
        } as EmploymentType)
      : undefined,
  );

  const [selectedEmploymentScheduleType, setSelectedEmploymentScheduleType] = useState<
    EmploymentScheduleType | undefined
  >(
    user.employment?.activeScheduleType?.name
      ? ({
          ...user.employment.activeScheduleType,
          value: user.employment?.salaryDetail?.employmentScheduleType,
          label: user.employment?.salaryDetail?.employmentScheduleType?.name,
        } as EmploymentScheduleType)
      : undefined,
  );

  const handleSubmit = (values: Partial<User>) => {
    setFormError('');

    const data = {
      ...user,
      ...values,
      ...(selectedBank && {
        bank: {
          ...values.bank,
          bankId: selectedBank.id,
          bank: selectedBank,
        } as UserBanks,
      }),
      ...(selectedEmploymentType && {
        employment: {
          ...values.employment,
          employmentType: selectedEmploymentType,
          activeScheduleType: selectedEmploymentScheduleType,
          salaryDetail: {
            ...values.employment?.salaryDetail,
            employeeNumber: user.employment?.employeeNumber,
            employmentScheduleType: {
              id: selectedEmploymentScheduleType?.id,
              name: selectedEmploymentScheduleType?.name,
              value: selectedEmploymentScheduleType?.id,
              label: selectedEmploymentScheduleType?.name,
            } as Partial<EmploymentScheduleType>,
            basicGross: values.employment?.salaryDetail?.basicGross,
            monthlyGross: values.employment?.salaryDetail?.monthlyGross,
          } as EmployeeSalaryDetail,
        } as Employee,
      }),
    };

    setUserFormState({
      user: data,
    });
    handleNext();
  };

  const handlePreSubmit = () => {
    setUserFormState({ nextStep: undefined });

    if (submitButton.current) submitButton.current.click();
  };

  return (
    <div>
      {formError && <div className="form-error">Test</div>}

      <Formik
        initialValues={
          {
            ...user,
            employment: {
              ...user.employment,
              salaryDetail: {
                ...user.employment?.salaryDetail,
                dateStart:
                  user.employment?.salaryDetail?.dateStart ??
                  new Date().toISOString().split('T')[0],
                dateEnded: user.employment?.salaryDetail?.dateEnded ?? '',
                monthlyGross: user.employment?.salaryDetail?.monthlyGross ?? '',
                basicGross: user.employment?.salaryDetail?.basicGross ?? '',
              },
            },
          } as User
        }
        onSubmit={handleSubmit}
      >
        <Form className="p-5 pb-0 mt-5 mb-5 border border-[#2e2d2c] bg-[#252423] border-solid rounded-xl shadow-2xl employee-offsourcing-info-form w-full">
          <Typography variant="h4" className="text-[#f05825] mb-5 mt-4 w-full text-right hidden">
            Payroll Information
            <hr className="rounded-md h-0.8 opacity-10 mt-3" />
          </Typography>
          <Typography variant="h4" className="text-[#f05825] flex gap-2">
            <span className="text-gray-200">Payroll</span>
            Information
          </Typography>

          <div className="grid grid-cols-1 xl:grid-cols-3 gap-5 p-5 mx-auto w-full form-section">
            {/* FIRST SECTION */}
            <div className="first-section p-5">
              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="employmentTypeId" className=" text-xs uppercase text-gray-600">
                  Employment Type
                </label>
                <div className="select-field-form">
                  <SelectEmploymentType
                    id="employment.employmentType"
                    name="employment.employmentType"
                    value={selectedEmploymentType}
                    onChange={(value) => setSelectedEmploymentType(value as EmploymentType)}
                  />
                </div>
                <ErrorMessage name="employmentTypeId" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="employment.salaryDetail.dateStart"
                  className="text-xs uppercase text-gray-600"
                >
                  Date Start
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="date"
                    id="employment.salaryDetail.dateStart"
                    name="employment.salaryDetail.dateStart"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                  />
                </div>
                <ErrorMessage name="employment.salaryDetail.dateStart" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="employment.salaryDetail.dateEnded"
                  className="text-xs uppercase text-gray-600"
                >
                  Date End
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="date"
                    id="employment.salaryDetail.dateEnded"
                    name="employment.salaryDetail.dateEnded"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                  />
                </div>
                <ErrorMessage name="employment.salaryDetail.dateEnded" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="employmentScheduleType"
                  className="mr-8 text-xs uppercase text-gray-600"
                >
                  Employment Schedule Type
                </label>
                <div className="select-field-form">
                  <SelectEmploymentScheduleType
                    id="employment.salaryDetail.employmentScheduleType"
                    name="employment.salaryDetail.employmentScheduleType"
                    value={selectedEmploymentScheduleType}
                    isClearable
                    onChange={(value) =>
                      setSelectedEmploymentScheduleType(value as EmploymentScheduleType)
                    }
                    required
                  />
                </div>
                <ErrorMessage
                  name="employment.salaryDetail.employmentScheduleType"
                  component="div"
                />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="monthlyGross" className="text-xs uppercase text-gray-600">
                  Monthly Gross
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </div>
                  <Field
                    type="number"
                    step=".01"
                    id="employment.salaryDetail.monthlyGross"
                    name="employment.salaryDetail.monthlyGross"
                    placeholder="Enter Monthly Gross"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="employment.salaryDetail.monthlyGross" component="div" />
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="basicGross" className="text-xs uppercase text-gray-600">
                  Basic
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="number"
                    step=".01"
                    id="employment.salaryDetail.basicGross"
                    name="employment.salaryDetail.basicGross"
                    placeholder="Enter Basic Salary"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="employment.salaryDetail.basicGross" component="div" />
              </div>
            </div>
            {/* END FIRST SECTION */}

            {/* SECOND SECTION */}
            <div className="second-section p-5">
              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="accountNumber.sss" className="text-xs uppercase text-gray-600">
                  SSS
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="accountNumber.sss"
                    name="accountNumber.sss"
                    placeholder="Enter SSS Number"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="accountNumber.sss" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="accountNumber.phic" className="text-xs uppercase text-gray-600">
                  PHIC
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="accountNumber.phic"
                    name="accountNumber.phic"
                    placeholder="Enter PHIC"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="accountNumber.phic" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="accountNumber.tin" className="text-xs uppercase text-gray-600">
                  TIN
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="accountNumber.tin"
                    name="accountNumber.tin"
                    placeholder="Enter TIN"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="accountNumber.tin" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="accountNumber.hdmf" className="text-xs uppercase text-gray-600">
                  HDMF
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="accountNumber.hdmf"
                    name="accountNumber.hdmf"
                    placeholder="Enter HDMF Number"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-gray-200"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="accountNumber.hdmf" component="div" />
              </div>
            </div>
            {/* END SECOND SECTION */}

            {/* THIRD SECTION */}
            <div className="third-section p-5">
              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="bankId" className="text-xs uppercase text-gray-600">
                  Bank
                </label>
                <div className="select-field-form">
                  <SelectBank
                    id="bankId"
                    name="bankId"
                    value={selectedBank}
                    isClearable
                    onChange={(value) => setSelectedBank(value as Bank)}
                    required
                  />
                </div>
                <ErrorMessage name="bankId" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="accountNumber" className="text-xs uppercase text-gray-300">
                  Account Number
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="bank.accountNumber"
                    name="bank.accountNumber"
                    placeholder="Enter Account Number"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="bank.accountNumber" component="div" />
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="bank.accountName" className="text-xs uppercase text-gray-600">
                  Account Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="bank.accountName"
                    name="bank.accountName"
                    placeholder="Enter Account Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="bank.accountName" component="div" />
              </div>
            </div>
            {/* END THIRD SECTION */}
          </div>

          <div className="flex justify-between w-full p-2">
            <button
              type="button"
              onClick={handlePrev}
              disabled={isFirstStep}
              className="muiButton--primary rounded-lg shadow-lg hover:bg-orange-500"
            >
              Previous
            </button>
            <button
              onClick={handlePreSubmit}
              type="button"
              disabled={isLastStep}
              className="muiButton--primary rounded-lg shadow-lg hover:bg-orange-500"
            >
              Next
            </button>
          </div>
          <button ref={submitButton} type="submit" className="invisible">
            Next
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default UserEmploymentPayrollForm;
