import { useState } from 'react';
import useClients from './useClients';
import useOnLoad from '../utils/useOnLoad';

const useClient = (id: string) => {
  const {
    get,
    clientsState: { clients },
  } = useClients();
  const [loading, setLoading] = useState(true);

  const clientId = parseInt(id, 10);
  const currentClient = clients.find((client) => client.id === clientId);

  useOnLoad(() => {
    if (currentClient) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    get(clientId).then(() => {
      setLoading(false);
    });
  });

  return {
    loading,
    currentClient,
  };
};

export default useClient;
