import React from 'react';
import TabbedContent, { Tab } from '../../molecules/TabbedContent';
import HolidayList from './HolidayList';
import HolidayTypeList from './HolidayTypeList';
import HolidaysInMonth from './HolidaysInMonth';
import ManagementHeading from '../../atoms/ManagementHeading';

const tabs: Tab[] = [
  { title: 'List of Holidays', content: <HolidayList /> },
  { title: 'Holiday Type', content: <HolidayTypeList /> },
];

const HolidayManagement: React.FC = () => (
  <div className="flex h-full w-full gap-5 pr-3 organisms-holidays-management">
    <div className="w-full flex flex-col">
      <div className="m-2 title">
        <ManagementHeading title="Holiday" />
      </div>

      <div>
        <TabbedContent tabs={tabs} />
      </div>
    </div>
    <div className="hidden">
      <HolidaysInMonth />
    </div>
  </div>
);

export default HolidayManagement;
