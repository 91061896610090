import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentPosition from '../../constants/employments/positions/employment-position.interface';

export type GetAllPositionsQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: EmploymentPosition['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPosition>> =>
  apiFetch<ApiBaseResponse<EmploymentPosition>>(`employments/positions/${id}`, { state })
    .then((response: ApiBaseResponse<EmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllPositionsQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmploymentPosition>> =>
  apiFetch<ApiGetAllBaseResponse<EmploymentPosition>>(
    `employments/positions?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<EmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<EmploymentPosition, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPosition>>('employments/positions', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: EmploymentPosition,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPosition>>(`positions/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<EmploymentPosition, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPosition>>(`employments/positions/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: EmploymentPosition['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentPosition> | void> =>
  apiFetch<ApiBaseResponse<EmploymentPosition>>(`employments/positions/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<EmploymentPosition>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
