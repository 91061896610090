import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import MyLeaveActions from '../../actions/leave/myLeaveActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import EmploymentScheduleType from '../../constants/employments/schedules/employment-schedule-type.interface';
import LeaveNature from '../../constants/leaves/leave-nature.interface';
import AuthActions from '../../actions/auths/authActions';
import { AuthLoginResponse } from '../../services/auths/authServices';
import LeaveApplication from '../../constants/leaves/leave-application.interface';
import LeaveStatusOption from '../../constants/leaves/leave-status-option';
import LeaveFileAttachment from '../../constants/leaves/leave-file-attachment.interface';

export interface MyLeavesState {
  leaveCredits: number;
  myLeaves: LeaveApplication[];
  myCurrentLeaveApplication?: LeaveApplication;
  uploadFileAttachment: LeaveFileAttachment[];
  employmentScheduleType: EmploymentScheduleType[];
  leaveNatures: LeaveNature[];
  leaveStatusOptions: LeaveStatusOption[];
}

const initialState: MyLeavesState = {
  leaveCredits: 0,
  myLeaves: [],
  myCurrentLeaveApplication: undefined,
  uploadFileAttachment: [],
  employmentScheduleType: [
    {
      id: 1,
      name: 'Regular',
      daysPerWeek: 5,
      daysPerMonth: 21.75,
      hours: 9,
      breakLength: 1,
    },
    {
      id: 2,
      name: '4x11',
      daysPerWeek: 4,
      daysPerMonth: 17.42,
      hours: 11,
      breakLength: 1,
    },
    {
      id: 3,
      name: '6x8',
      daysPerWeek: 6,
      daysPerMonth: 26,
      hours: 9,
      breakLength: 2,
    },
    {
      id: 4,
      name: 'Consultant',
      daysPerWeek: 5,
      daysPerMonth: 21,
      hours: 4,
      breakLength: 1,
    },
  ],
  leaveNatures: [
    {
      id: 3,
      nature: 'Bereavement',
      paidBy: 0,
      isDeductible: 0,
      adminOnly: 1,
      baseDaysNotice: 0,
    },
    {
      id: 8,
      nature: 'Sick',
      paidBy: 1,
      isDeductible: 1,
      adminOnly: 1,
      baseDaysNotice: 0,
    },
    {
      id: 11,
      nature: 'Vacation',
      paidBy: 1,
      isDeductible: 1,
      adminOnly: 1,
      baseDaysNotice: 7,
    },
    {
      id: 16,
      nature: 'Emergency',
      paidBy: 0,
      isDeductible: 1,
      adminOnly: 1,
      baseDaysNotice: 0,
    },
  ],
  leaveStatusOptions: [
    { label: 'All', value: undefined },
    { label: 'Pending', value: 'Pending' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Denied', value: 'Denied' },
  ],
};

const getMyLeaveData = (leave: Partial<LeaveApplication>) => ({
  ...leave,
});

const myLeavesSlice = createSlice({
  name: 'leaves',
  initialState,
  reducers: {
    resetMyLeavesState: () => ({
      ...initialState,
    }),
    setMyLeavesState: (state, action: PayloadAction<Partial<MyLeavesState>>) => ({
      ...state,
      ...action.payload,
    }),
    resetMyCurrentLeaveApplication: (state) => {
      state.myCurrentLeaveApplication = undefined;
    },
    upsertLeave: (state, action: PayloadAction<Partial<LeaveApplication>>) => {
      const data: LeaveApplication = getMyLeaveData(action.payload) as LeaveApplication;

      if (typeof data.id === 'undefined') return;

      state.myLeaves = upsertData(state.myLeaves, data);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(AuthActions.login.fulfilled, (state, { payload }) => {
      const { status, data } = payload as ApiBaseResponse<AuthLoginResponse>;

      if (status !== 200) return;

      state.leaveCredits = data.leaveCredits;
    });

    builder.addCase(MyLeaveActions.myPost.fulfilled, (state, { payload }) => {
      const { data, status } = payload as ApiBaseResponse<LeaveApplication>;
      consoleLog('LEAVES REDUCER', 'POST', data);

      if (status !== StatusCodes.CREATED) return;

      if (data) {
        if (data.isPaid) state.leaveCredits -= data.leaveCount;

        state.myLeaves = upsertData(state.myLeaves, data);
        state.myCurrentLeaveApplication = data;
      }
    });

    builder.addCase(MyLeaveActions.getMyAll.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<LeaveApplication>;

      if (status === 503) return;

      if (data) {
        state.myLeaves = data;
      }
    });

    builder.addCase(MyLeaveActions.getMy.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;
      state.myLeaves = upsertData(state.myLeaves, data);
    });

    builder.addCase(MyLeaveActions.myPut.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;
      state.myLeaves = upsertData(state.myLeaves, data);
    });

    builder.addCase(MyLeaveActions.myRemove.fulfilled, (state, { payload }) => {
      consoleLog('LEAVES REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<LeaveApplication>;

      if (typeof data.id === 'undefined') return;
      state.myLeaves = state.myLeaves.filter((leave) => leave.id !== data.id);
    });

    builder.addCase(MyLeaveActions.uploadAttachments.fulfilled, (state, { payload }) => {
      consoleLog('UPLOAD REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<LeaveFileAttachment>;

      if (typeof data === 'undefined') return;
      state.uploadFileAttachment = upsertData(state.uploadFileAttachment, data);
    });
  },
});

export const leavesReducerActions = myLeavesSlice.actions;
export default myLeavesSlice.reducer;
