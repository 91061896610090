import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { Chip, Typography } from '@material-tailwind/react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import TextWithIcons from '../../molecules/TextWithIcons';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import EmployeeSchedule from '../../../store/constants/employees/schedules/employee-schedule.interface';
import useSchedules from '../../../hooks/schedule/useSchedules';
import ScheduleManagementListRow from './ScheduleManagementListRow';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import SchedulerFormModal from './SchedulerEditFormModal';

import SelectActiveStatus, { ActiveStatusOption } from '../../atoms/dropdowns/SelectActiveStatus';
import SchedulerTestAddForm from './SchedulerTestAddForm';

const ScheduleManagementList: React.FC = () => {
  const [selectedSchedule, setSelectedSchedule] = useState<EmployeeSchedule | undefined>();

  const { schedulesState: currentSchedule, getAll, remove } = useSchedules();
  const { getUserEmploymentByEmployeeNumber } = useUserEmployments();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedActiveStatus, setSelectedActiveStatus] = useState<ActiveStatusOption | undefined>({
    label: 'Active',
    value: 1,
  });

  const employeeNumberToFilter = currentSchedule.currentEmployeeNumber;

  const currentSchedules = currentSchedule.schedules
    .filter(
      (schedule: EmployeeSchedule) =>
        schedule.employeeNumber === employeeNumberToFilter &&
        schedule.id !== undefined &&
        schedule.id !== null &&
        schedule.id !== 0,
    )
    .sort((a: EmployeeSchedule, b: EmployeeSchedule) => {
      const defaultOrder = b.id - a.id;
      return defaultOrder;
    });

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  const handleDelete = async (schedule: EmployeeSchedule) => {
    remove(schedule.id).then();
  };

  const onEditClick = (schedule: EmployeeSchedule) => {
    setSelectedSchedule(schedule);
  };

  const onClose = () => setSelectedSchedule(undefined);

  useOnLoad(() => {
    setIsLoading(true);
    getAll({
      employeeNumber: currentSchedule.currentEmployeeNumber,
    }).then(() => {
      setIsLoading(false);
    });
  });

  return (
    <div className="schedule-management-form-container space-y-5">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/scheduler">
          <Typography className="crumb">Schedule</Typography>
        </Link>
        {currentSchedule.currentEmployeeNumber && (
          <span className="crumb">
            {
              getUserEmploymentByEmployeeNumber(currentSchedule.currentEmployeeNumber)
                ?.userInformation.fullName
            }
          </span>
        )}
        {currentSchedule.currentEmployeeNumber && (
          /**
           * @TODO
           * COUNT ONLY ACTIVE SCHEDULES
           */
          <span className=" flex items-center gap-2">
            <Typography className="current-crumb">Schedules</Typography>{' '}
            <Chip className="chip-style" value={currentSchedules.length} variant="ghost" />
          </span>
        )}
      </Breadcrumbs>
      <div className="schedule-management">
        <div className="flex justify-between ">
          <SchedulerFormModal schedule={selectedSchedule} onClose={onClose} />
          <Typography className="muiTypography--modifiedH4">
            Employee Schedule Management
          </Typography>
        </div>

        <div className="px-3">
          <div className="flex justify-between w-full gap-20 pb-5 pt-10">
            <Formik initialValues={{}} onSubmit={() => {}}>
              <Form className="flex w-1/4 order-last">
                <div className="order-last text-xs w-full">
                  <SelectActiveStatus
                    id="status"
                    name="status"
                    className="status-filter"
                    value={selectedActiveStatus as ActiveStatusOption}
                    onChange={(value) => {
                      setSelectedActiveStatus(value as ActiveStatusOption);
                    }}
                  />
                </div>
              </Form>
            </Formik>

            <div className="true-form">
              <SchedulerTestAddForm
                onClose={onClose}
                employeeNumber={currentSchedule.currentEmployeeNumber}
              />
            </div>
          </div>

          <div className="w-full font-bold first-section text-[#f05825] mr-10">
            <div className="p-2 border-b-2 grid grid-cols-11 header-items">
              <TextWithIcons variant="h6">
                <div className="flex gap-5">ID</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Type</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Days</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Time In</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Time Out</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Break</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Temp</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Date Start</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Date End</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Date Updated</div>
              </TextWithIcons>
              <TextWithIcons variant="h6" className=" justify-self-center">
                <div className="flex gap-5">Actions</div>
              </TextWithIcons>
            </div>

            {!isLoading &&
            currentSchedule.currentEmployeeNumber &&
            currentSchedules.length === 0 ? (
              <EmptyListMessage />
            ) : (
              <VariableSizeList
                itemCount={currentSchedules.length}
                itemSize={getItemSize}
                height={window.innerHeight - 400}
                width="100%"
                className="no-scrollbars"
              >
                {({ index, style }) => (
                  <ScheduleManagementListRow
                    data={currentSchedules[index]}
                    className={`${index % 2 === 0 ? 'stripped' : ''}`}
                    style={style}
                    onEditClick={onEditClick}
                    onDeleteClick={handleDelete}
                  />
                )}
              </VariableSizeList>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleManagementList;
