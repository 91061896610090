import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import Timezone from '../../../store/constants/timezones/timezone.interface';
import useDateTime from '../../../hooks/utils/useDateTime';

export interface SelectTimezoneProps extends Partial<AutoSearchSelectProps<Timezone>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectTimezone: React.FC<SelectTimezoneProps> = ({
  id,
  name,
  placeholder = 'Select Timezone',
  className = '',
  ...props
}) => {
  const { getTimezones } = useDateTime();

  useOnLoad(() => {
    // @TODO: Must be taken from API
    getTimezones();
  });

  const timezones = getTimezones()
    .slice()
    .map((timezone: Timezone) => ({
      ...timezone,
      value: timezone.code,
      label: timezone.code,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-timezone modal-select ${className}`}
      placeholder={placeholder}
      data={timezones}
    />
  );
};

export default SelectTimezone;
