import React from 'react';

const BillingExportFilters = () => (
  <div className="flex flex-col gap-5">
    <div className="first-filter bg-[#343434] rounded-lg shadow-lg  p-3 flex gap-10">
      <div className="flex flex-col gap-2">
        <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
          Select Range
        </label>
        <input
          type="month"
          className="bg-[#343434] w-56 p-1 rounded-md border border-solid border-[#434343] px-2 text-gray-200"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
          Export Billing by Client Title
        </label>
        <div className="flex gap-2 items-center">
          <button
            type="button"
            className="bg-[#f05825] py-1 px-5 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
          >
            Monthly
          </button>
          <button
            type="button"
            className="bg-[#f05825] py-1 px-5 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
          >
            Annual
          </button>
        </div>
      </div>
    </div>

    <div className="second-filter bg-[#343434] rounded-lg shadow-lg  p-3 flex flex-col gap-10">
      <div className=" flex gap-10">
        <div className="flex flex-col gap-2">
          <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
            Select Range
          </label>
          <input
            type="month"
            className="bg-[#343434] w-56 p-1 rounded-md border border-solid border-[#434343] px-2 text-gray-200"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
            Selected Client: 0
          </label>
          <button
            type="button"
            className="bg-[#2c2b2a] py-1 px-20 rounded-md hover:opacity-80 cursor-pointer text-gray-400 shadow-lg"
            disabled
          >
            Download
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
            Reset Filter and Download Link:
          </label>
          <button
            type="button"
            className="bg-[#b53000] py-1 px-20 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
          >
            Reset
          </button>
        </div>
      </div>
      <div className="flex gap-10">
        <div className="flex flex-col gap-2">
          <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
            Selected Export Style
          </label>
          <div className="flex justify-between gap-10">
            <button
              type="button"
              className="bg-[#f05825] py-1 px-20 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
              disabled
            >
              Export Client HC
            </button>
            <button
              type="button"
              className="bg-[#f05825] py-1 px-20 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
              disabled
            >
              Export Client HC by Titles
            </button>
            <button
              type="button"
              className="bg-[#f05825] py-1 px-20 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
              disabled
            >
              Export Client PTO
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <label htmlFor="account" className="text-gray-200 text-sm font-semibold">
              Export Daily HC:
            </label>
            <div className="flex gap-5">
              {' '}
              <button
                type="button"
                className="bg-[#f05825] py-1 px-20 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
                disabled
              >
                Monthly
              </button>
              <button
                type="button"
                className="bg-[#f05825] py-1 px-20 rounded-md hover:opacity-80 cursor-pointer text-gray-200 shadow-lg"
                disabled
              >
                Annual
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BillingExportFilters;
