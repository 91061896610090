import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-tailwind/react';
import consoleLog from '../../../../../utils/consoleLog';
import UserAttendanceActiveLeave from './UserAttendanceActiveLeave';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';

const UserOnLeave = () => {
  const { attendanceState } = useAttendance();
  const [showOnLeave, setShowOnLeave] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    consoleLog('click');
  };

  const handleLogOut = () => {
    setOpen(!open);
    setShowOnLeave(!showOnLeave);
  };

  const isPaid = `${
    attendanceState.activeLeaveApplication?.isPaid
      ? 'user-attendance-action__button--state-paid'
      : 'user-attendance-action__button--state-unpaid'
  }`;

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={`user-attendance-action__button ${isPaid}`}
      >
        <span className="user-attendance-action-button__heading-text">ON LEAVE</span>
      </button>

      <Dialog
        open={open}
        size="xs"
        handler={handleLogOut}
        className="user-attendance-action__prompt-container"
      >
        <div className="close-prompt-button">
          <IconButton
            color="blue-gray"
            size="sm"
            variant="text"
            onClick={() => {
              setOpen(false);
              setShowOnLeave(false);
            }}
          >
            <Close className="h-5 w-5" />
          </IconButton>
        </div>
        {showOnLeave && <UserAttendanceActiveLeave />}
      </Dialog>
    </>
  );
};

export default UserOnLeave;
