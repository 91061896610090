import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { checkbox, Switch, Tooltip, Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { SingleValue } from 'react-select';
import Client from '../../store/constants/clients/client.interface';
import Timezone from '../../store/constants/timezones/timezone.interface';
import ClientManager from '../../store/constants/clients/client-manager.interface';
import AtLeast from '../../types/atleast';
import HappyFace from '../../assets/images/icons/happy-face.svg';
import EmploymentRole from '../../store/constants/employments/roles/employment-role.interface';
import useEmploymentRoles from '../../hooks/employment/useEmploymentRoles';
import SelectEmployee from '../atoms/dropdowns/SelectEmployee';
import SelectTimezone from '../atoms/dropdowns/SelectTimezone';
import TwoPerson from '../../assets/images/icons/user-two.svg';
import useOnLoad from '../../hooks/utils/useOnLoad';
import ButtonAction from '../atoms/buttons/ButtonAction';
import Employee from '../../store/constants/employees/employee.interface';

interface SelectedUserByRole {
  [code: EmploymentRole['code']]: Employee;
}

interface ClientFormProps {
  client?: Client;
  onClose?: () => void;
  onSubmit: (
    values: Partial<Client>,
    formikHelpers: FormikHelpers<Partial<Client>>,
  ) => Promise<void>;
}

const ClientForm: React.FC<ClientFormProps> = ({
  client = {
    name: '',
    dateStart: moment().format('YYYY-MM-DD'),
    overtimeOffset: true,
  },
  onClose = undefined,
  onSubmit,
}) => {
  const navigate = useNavigate();

  const {
    getAll: getAllEmploymentRoles,
    employmentRolesState: { employmentRoles },
  } = useEmploymentRoles();

  const [loading, setLoading] = useState(false);

  const [overtimeOffset, setOvertimeOffset] = useState<Client['overtimeOffset']>(
    client.overtimeOffset,
  );

  const [selectedUsersByRole, setSelectedUserByRole] = useState<SelectedUserByRole | undefined>();

  const [selectedTimezone, setSelectedTimezone] = useState<Timezone | undefined>(
    client.timezone
      ? ({
          ...client.timezone,
          value: client.timezone.code,
          label: client.timezone.code,
        } as Timezone)
      : undefined,
  );

  const handleOvertimeOffsetChange = () => {
    setOvertimeOffset(!overtimeOffset);
  };

  const assignUserForRole = (
    code: EmploymentRole['code'],
    value?: SingleValue<Employee> | undefined,
  ) => {
    const assignedUsers = {
      ...selectedUsersByRole,
    };

    if (!value) delete assignedUsers[code];

    if (value) {
      assignedUsers[code] = value;
    }

    setSelectedUserByRole(assignedUsers);
  };

  const handleViewClientTeamList = () => {
    if (client) navigate(`/admin/clients/${client.id}/teams-list`);
  };

  const handleViewClientManagerList = () => {
    if (client) navigate(`/admin/clients/${client.id}/managers`);
  };

  const handleSubmit = (values: Partial<Client>, formikHelpers: FormikHelpers<Partial<Client>>) => {
    setLoading(true);
    const { setSubmitting } = formikHelpers;
    const clientManagers: AtLeast<ClientManager, 'employee'>[] = [];

    if (selectedUsersByRole) {
      Object.keys(selectedUsersByRole).forEach((code) => {
        const userEmployment = selectedUsersByRole[code];
        if (userEmployment) {
          clientManagers.push({
            employee: userEmployment,
            employmentRole: employmentRoles.find((employmentRole) => employmentRole.code === code),
          });
        }
      });
    }

    const data: Client = {
      ...values,
      clientManagers,
      timezone: selectedTimezone,
      overtimeOffset,
    } as Client;

    onSubmit(data, formikHelpers).then(() => {
      setLoading(false);
    });

    setSubmitting(false);
  };

  useOnLoad(() => {
    getAllEmploymentRoles();
  });

  return (
    <Formik initialValues={client} onSubmit={handleSubmit}>
      <Form>
        <div className="client-form-section">
          <div className="client-information space-y-5">
            <Typography className="muiTypography--modifiedH4">Client Information</Typography>
            <div className="client-information__details">
              <div className="labelled-field">
                <div className="field-content">
                  <label htmlFor="name" className="field-label">
                    Client Name
                  </label>
                  <div>
                    <Field
                      required
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Client Name"
                      className="field-input"
                    />
                    <ErrorMessage name="name" component="div" />
                  </div>
                </div>
              </div>
              <div className="labelled-field">
                <div className="field-content">
                  <label htmlFor="dateStart" className="field-label">
                    Date Start
                  </label>
                  <div>
                    <Field
                      required
                      type="date"
                      id="dateStart"
                      name="dateStart"
                      className="field-input"
                    />
                    <ErrorMessage name="dateStart" component="div" />
                  </div>
                </div>
              </div>
              <div className="labelled-field">
                <div className="field-content">
                  <label htmlFor="dateEnd" className="field-label">
                    Date End
                  </label>
                  <div>
                    <Field type="date" id="dateEnd" name="dateEnd" className="field-input" />
                    <ErrorMessage name="dateEnd" component="div" />
                  </div>
                </div>
              </div>
              <div className="labelled-field">
                <div className="field-content">
                  <label htmlFor="timezone" className="field-label">
                    Timezone
                  </label>
                  <div>
                    <SelectTimezone
                      id="timezone"
                      name="timezone"
                      value={selectedTimezone}
                      onChange={(value) => {
                        setSelectedTimezone(value as Timezone);
                      }}
                    />
                    <ErrorMessage name="timezone" component="div" />
                  </div>
                </div>
              </div>
              <div className="labelled-field">
                <div className="field-content">
                  <Field
                    type={checkbox}
                    id="overtimeOffset"
                    name="overtimeOffset"
                    component={Switch}
                    ripple={false}
                    // @todo current value is not passed on submit. forced checked is used instead
                    checked={overtimeOffset}
                    className="overtime-offset-toggle"
                    containerProps={{
                      className: 'overtime-offset-container',
                    }}
                    circleProps={{
                      className: 'overtime-offset-toggle__circle',
                    }}
                    onChange={handleOvertimeOffsetChange}
                    label={
                      <div>
                        <Typography className="field-label">Overtime offset</Typography>
                        <Typography className="field-description">
                          Toggle to enable overtime offset for this client.
                        </Typography>
                      </div>
                    }
                  />
                </div>
                <ErrorMessage name="overtimeOffset" component="Switch" />
              </div>
            </div>
          </div>

          {!client.id && (
            <div className="client-managers space-y-5">
              <Typography className="muiTypography--modifiedH4">Client Managers</Typography>
              <div className="client-managers__details">
                <div className="labelled-field">
                  <div className="field-content">
                    <label htmlFor="csd" className="field-label">
                      GSD
                    </label>
                    <div>
                      <SelectEmployee
                        id="gsd"
                        name="gsd"
                        allowedRolesByCode={['gsd']}
                        isClearable
                        isSearchable
                        menuPlacement="auto"
                        placeholder="Select GSD"
                        value={selectedUsersByRole?.gsd}
                        onChange={(value) => assignUserForRole('gsd', value as Employee)}
                      />
                      <ErrorMessage name="gsd" component="div" />
                    </div>
                  </div>
                </div>

                <div className="labelled-field">
                  <div className="field-content">
                    <label htmlFor="adminSpecialist" className="field-label">
                      Admin Specialist
                    </label>
                    <div>
                      <SelectEmployee
                        id="as"
                        name="as"
                        allowedRolesByCode={['as']}
                        isClearable
                        isSearchable
                        menuPlacement="auto"
                        placeholder="Select Admin Specialist"
                        value={selectedUsersByRole?.as}
                        onChange={(value) => assignUserForRole('as', value as Employee)}
                      />
                      <ErrorMessage name="adminSpecialist" component="div" />
                    </div>
                  </div>
                </div>

                <div className="labelled-field">
                  <div className="field-content">
                    <label htmlFor="operationManager" className="field-label">
                      Operation Manager
                    </label>
                    <div>
                      <SelectEmployee
                        id="om"
                        name="om"
                        allowedRolesByCode={['om']}
                        isClearable
                        isSearchable
                        menuPlacement="auto"
                        placeholder="Select Operations Manager"
                        value={selectedUsersByRole?.om}
                        onChange={(value) => assignUserForRole('om', value as Employee)}
                      />
                      <ErrorMessage name="operationManager" component="div" />
                    </div>
                  </div>
                </div>

                <div className="labelled-field">
                  <div className="field-content">
                    <label htmlFor="seniorOperationManager" className="field-label">
                      Senior Operation Manager
                    </label>
                    <div>
                      <SelectEmployee
                        id="som"
                        name="som"
                        allowedRolesByCode={['som']}
                        isClearable
                        isSearchable
                        menuPlacement="auto"
                        placeholder="Select Senior Operations Manager"
                        value={selectedUsersByRole?.som}
                        onChange={(value) => assignUserForRole('som', value as Employee)}
                      />
                      <ErrorMessage name="seniorOperationManager" component="div" />
                    </div>
                  </div>
                </div>

                <div className="labelled-field">
                  <div className="field-content">
                    <label htmlFor="accountManager" className="field-label">
                      Account Manager
                    </label>
                    <div>
                      <SelectEmployee
                        id="am"
                        name="am"
                        allowedRolesByCode={['am']}
                        isClearable
                        isSearchable
                        menuPlacement="auto"
                        placeholder="Select Accounts Manager"
                        value={selectedUsersByRole?.am}
                        onChange={(value) => assignUserForRole('am', value as Employee)}
                      />
                      <ErrorMessage name="accountManager" component="div" />
                    </div>
                  </div>
                </div>

                <div className="labelled-field">
                  <div className="field-content">
                    <label htmlFor="director" className="field-label">
                      Director
                    </label>
                    <div>
                      <SelectEmployee
                        id="director"
                        name="director"
                        allowedRolesByCode={['director']}
                        isClearable
                        isSearchable
                        menuPlacement="auto"
                        placeholder="Select Director"
                        value={selectedUsersByRole?.director}
                        onChange={(value) => assignUserForRole('director', value as Employee)}
                      />
                      <ErrorMessage name="director" component="div" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="form-buttons">
          <div className="button-container submit-cancel-buttons">
            <ButtonAction
              className="p-2 rounded-md shadow-lg muiButton--primary"
              isLoading={loading}
              text="Submit"
              hasSpinnerText={false}
              isSubmit
            />
            {onClose && (
              <button type="button" onClick={onClose} className="textButton">
                Close
              </button>
            )}
          </div>

          <div className="view-page-buttons">
            {client.id && (
              <Tooltip content="View client teams">
                <button
                  type="button"
                  onClick={handleViewClientTeamList}
                  className="view-client-teams-button"
                >
                  <img src={TwoPerson} className="client-icons" alt="view client teams" />
                </button>
              </Tooltip>
            )}
            {client.id && (
              <Tooltip content="View client managers">
                <button
                  type="button"
                  onClick={handleViewClientManagerList}
                  className="view-client-manager-button"
                >
                  <img src={HappyFace} className="client-icons" alt="view client managers" />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ClientForm;
