import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import UserActions from '../../actions/users/userActions';
import AtLeast from '../../../types/atleast';
import User from '../../constants/users/user.interface';
import defaultValue from '../../constants/utils/default-value.const';
import UserEmployment from '../../constants/employees/employee.interface';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';

export type UserFormPage = 'basic' | 'contact' | 'employment' | 'payroll' | 'teams' | 'summary';

export interface UserForm extends AtLeast<User, 'bank'> {
  profilePic?: File;
}

export enum UserFormSteps {
  BASIC_INFO = 0,
  CONTACT_INFO = 1,
  EMPLOYMENT_INFO = 2,
  EMPLOYMENT_PAYROLL = 3,
  EMPLOYMENT_TEAM_ASSIGNMENT = 4,
  SUMMARY = 5,
}

export enum UserFormStateAction {
  ADD = 'add',
  UPDATE = 'update',
}

export interface UserFormState {
  nextStep?: UserFormPage;
  action: UserFormStateAction;
  user: UserForm;
  activeStep: number;
}

const initialState: UserFormState = {
  nextStep: undefined,
  action: UserFormStateAction.ADD,
  user: {
    firstName: '',
    lastName: '',
    middleName: '',
    suffixName: '',
    nickName: '',
    maidenName: '',
    contactNumber: '',
    emailPersonal: '',
    gender: undefined,
    dateBirth: '',
    timezone: defaultValue.timezone,
    bank: {
      userId: undefined,
      bankId: undefined,
      bank: undefined,
      accountNumber: '',
      accountName: '',
    } as Partial<User['bank']> as User['bank'],
    contact: {
      userId: undefined,
      partnerFullName: '',
      emergencyContactNumber: '',
      emergencyContactName: '',
      emergencyContactRelation: '',
      emergencyContactAddress: '',
      motherName: '',
      fatherName: '',
    } as Partial<User['contact']> as User['contact'],
    address: {
      userId: undefined,
      address: '',
      district: '',
      city: '',
      country: 'Philippines',
      zip: '',
    } as Partial<User['address']> as User['address'],
    accountNumber: {
      userId: undefined,
      phic: '',
      tin: '',
      hdmf: '',
      sss: '',
    } as Partial<User['accountNumber']> as User['accountNumber'],
    employment: {
      userId: undefined,
      employeeNumber: undefined,
      acl: undefined,
      username: '',
      employmentStatus: { id: 1, name: 'Active' },
      attritionType: undefined,
      attritionReason: '',
      remarks: '',
      previousEmployer: '',
      emailWork: '',
      referredBy: undefined,
      referralRate: undefined,
      signInBonus: undefined,
      dateStart: defaultValue.dateToday,
      positions: [] as User['positions'],
      teams: [] as UserEmployment['teams'],
      salaryDetails: undefined,
      employmentType: defaultValue.employmentType,
    } as Partial<User['employment']> as User['employment'],
    profilePic: undefined,
  },
  activeStep: UserFormSteps.BASIC_INFO,
};

const userFormSlice = createSlice({
  name: 'userForm',
  initialState,
  reducers: {
    resetUserFormState: () => ({
      ...initialState,
    }),
    setUserFormState: (state, action: PayloadAction<Partial<UserFormState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(UserActions.post.fulfilled, () => ({
      ...initialState,
    }));
    builder.addCase(UserActions.put.fulfilled, (state, { payload }) => {
      const { status, data } = payload as ApiBaseResponse<User>;
      if (status !== 200) return;
      state.user = data;
    });
  },
});

export const userFormReducerActions = userFormSlice.actions;
export default userFormSlice.reducer;
