import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
import PayrollEmployeeListTable from './employeeTable/PayrollCreatePayslipTable';
import usePayrolls from '../../../../hooks/payroll/usePayrolls';
import { GetAllPayrollQueryParams } from '../../../../store/services/payroll/payrollServices';
import PayrollCreateExcelExport from './toolOptionsProperties/PayrollCreateExcelExport';
import PayrollCreateEmployeeSettings from './toolOptionsProperties/PayrollCreateEmployeeSettings';
import PayrollCreateHideColumns from './toolOptionsProperties/PayrollCreateHideColumns';

export interface SwitchStates {
  [key: string]: boolean;
}

const PayrollToolOptions: React.FC = () => {
  const [open, setOpen] = useState(true);
  const { setPayrollState, payrollsState } = usePayrolls();
  const [switchStates, setSwitchStates] = useState<SwitchStates>({
    // hide columns
    taxable: true,
    incentiveCompany: true,
    allowances: true,
    incentiveClient: true,
    deductions: true,
  });

  const handleTableSwitchChange = (id: string, checked: boolean) => {
    setSwitchStates((prevStates) => ({ ...prevStates, [id]: checked }));
  };

  const handleParamsSwitchesChange = (id: string, checked: boolean) => {
    const paramsToUpdate: Partial<GetAllPayrollQueryParams> = { [id]: checked };

    setPayrollState({
      ...payrollsState,
      payrollParams: {
        ...payrollsState.payrollParams,
        ...paramsToUpdate,
      },
    });
  };

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-1">
        <Accordion open={open}>
          <div className="p-2 hover:bg-[#343434] rounded-none border-2 border-solid border-t-[#2c2b2a] border-b-[#2c2b2a] border-r-transparent border-l-transparent">
            <AccordionHeader
              onClick={() => setOpen(!open)}
              className="border-b-0 p-0 bg-[rgb(44, 43, 42) focus:bg-[rgb(44, 43, 42)"
            >
              <span className="mr-auto text-xs font-normal text-white">Options</span>
            </AccordionHeader>
          </div>
          <AccordionBody className="px-2 py-1">
            <div className="flex items-center gap-5">
              <PayrollCreateHideColumns
                switchStates={switchStates}
                handleParamsSwitchesChange={handleTableSwitchChange}
              />
              <div className="separator" />
              <div className="group-two">
                <PayrollCreateEmployeeSettings
                  switchStates={switchStates}
                  handleParamsSwitchesChange={handleParamsSwitchesChange}
                />
              </div>
              <div className="separator" />
              <div className="excel-export-container">
                <PayrollCreateExcelExport
                  switchStates={switchStates}
                  handleSwitchChange={handleParamsSwitchesChange}
                  teamIds={payrollsState.payrollParams.teamId}
                />
              </div>
            </div>
          </AccordionBody>
        </Accordion>
      </div>
      <PayrollEmployeeListTable switchStates={switchStates} />
    </div>
  );
};

export default PayrollToolOptions;
