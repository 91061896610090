import React, { useEffect, useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Tooltip } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import DateRangePicker from 'react-datepicker';
import { DateRange } from '../../../store/services/clients/clientServices';
import ClientListRow from './ClientListRow';
import TextWithIcons from '../../molecules/TextWithIcons';
import useDateTime from '../../../hooks/utils/useDateTime';
import Client from '../../../store/constants/clients/client.interface';
import useClients from '../../../hooks/clients/useClients';
import consoleLog from '../../../utils/consoleLog';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import SearchSvg from '../../../assets/images/icons/search.svg';
import SelectActiveStatus, { ActiveStatusOption } from '../../atoms/dropdowns/SelectActiveStatus';
import ClientDateEndModal from './ClientDateEndModal';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';

type SortByHeaderOptions = '' | 'client' | 'fte' | 'teams' | 'gsd' | 'dateStart' | 'dateEnd';

const ClientList: React.FC = () => {
  const navigate = useNavigate();
  const { getDateTime, getDateAsMoment, getDate } = useDateTime();
  const { clientsState, getAll, remove } = useClients();

  const [searchValue, setSearchValue] = useState('');
  const [dateStartRange, setDateStartRange] = useState<[Date | null, Date | null]>([null, null]);
  const [dateEndRange, setDateEndRange] = useState<[Date | null, Date | null]>([null, null]);

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);
  const [debouncedDateStartRange] = useDebounce(dateStartRange, 2000);
  const [debouncedDateEndRange] = useDebounce(dateEndRange, 2000);

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);
  const [selectedActiveStatus, setSelectedActiveStatus] = useState<ActiveStatusOption | null>({
    label: 'Active',
    value: 1,
  });
  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const clients = clientsState.clients
    .filter((client: Client) =>
      client.name.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()),
    )
    .sort((a: Client, b: Client) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'client') {
        return sortOrder * a.name.localeCompare(b.name);
      }

      if (sortOption === 'gsd') {
        const getGsdManagerFullName = (client: Client) => {
          const gsdManager = client.managers?.find((cm) => cm.employmentRole?.code === 'gsd');
          return gsdManager?.userInformation?.fullName;
        };

        const aFullName = getGsdManagerFullName(a);
        const bFullName = getGsdManagerFullName(b);

        if (!aFullName) return -sortOrder;
        if (!bFullName) return sortOrder;

        return sortOrder * bFullName.localeCompare(aFullName);
      }

      if (sortOption === 'dateStart') {
        return (
          sortOrder *
          getDateAsMoment(new Date(a.dateStart)).diff(getDateAsMoment(new Date(b.dateStart)))
        );
      }
      if (sortOption === 'dateEnd') {
        return (
          sortOrder *
          getDateAsMoment(new Date(a.dateEnd)).diff(getDateAsMoment(new Date(b.dateEnd)))
        );
      }

      return sortOrder * (b.id - a.id);
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleStatusChange = (value: ActiveStatusOption) => {
    if (value?.label === 'Active') {
      setDateEndRange([null, null]);
    }
  };

  const onEditClick = (client: Client) => {
    consoleLog('LOG_THIS', 'EDIT CLIENT', client);
    navigate(`/admin/clients/${client.id}`);
  };

  const onDeleteClick = async (client: Client) => {
    remove(client.id).then((r) => {
      consoleLog('DELETE CLIENT', r);
    });
  };

  const onDateEndClick = (client: Client) => {
    setSelectedClient(client);
  };

  const handleAddClientClick = () => {
    navigate('/admin/clients/new');
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useEffect(() => {
    const formattedDateStartRange = debouncedDateStartRange.map((date) => {
      if (date) {
        return getDate(date);
      }
      return null;
    });

    const formattedDateEndRange = debouncedDateEndRange.map((date) => {
      if (date) {
        return getDate(date);
      }
      return null;
    });

    getAll({
      isActive: selectedActiveStatus?.value,
      ...(formattedDateStartRange[0] && {
        dateStartRange: {
          from: formattedDateStartRange[0],
          to: formattedDateStartRange[1],
        } as DateRange,
      }),
      ...(formattedDateEndRange[0] && {
        dateEndRange: {
          from: formattedDateEndRange[0],
          to: formattedDateEndRange[1],
        } as DateRange,
      }),
    }).then();
    // eslint-disable-next-line
  }, [selectedActiveStatus, debouncedDateStartRange, debouncedDateEndRange]);

  return (
    <div className="organisms-client-list">
      <div className="organisms-client-list__top-section ">
        <ClientDateEndModal client={selectedClient} onClose={() => setSelectedClient(undefined)} />

        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <div className="client-filters client-list-filter">
              <div className="w-full text-xs">
                <SelectActiveStatus
                  id="status"
                  name="status"
                  className="status-filter"
                  value={selectedActiveStatus as ActiveStatusOption}
                  onChange={(value) => {
                    handleStatusChange(value as ActiveStatusOption);
                    setSelectedActiveStatus(value as ActiveStatusOption);
                  }}
                />
              </div>
              <div className="date-filter datePicker-main-parent">
                <DateRangePicker
                  className="date-input"
                  startDate={dateStartRange[0]}
                  endDate={dateStartRange[1]}
                  onChange={(update) => {
                    setDateStartRange(update);
                  }}
                  selectsRange
                  isClearable
                  placeholderText="Select date start range..."
                />
              </div>

              <div className="date-filter datePicker-main-parent">
                <DateRangePicker
                  className="date-input"
                  startDate={dateEndRange[0]}
                  endDate={dateEndRange[1]}
                  onChange={(update) => {
                    setDateEndRange(update);
                  }}
                  selectsRange
                  isClearable
                  placeholderText="Select date end range..."
                  disabled={selectedActiveStatus?.label === 'Active'}
                />
              </div>

              <div className="search-body">
                <input
                  onKeyDown={onSearchKeyDown}
                  type="text"
                  className="search-body__input search-bar"
                  placeholder="Search client"
                  onChange={handleSearch}
                />
                <img
                  style={{ filter: 'invert(0) invert(1)' }}
                  className="search-body__icon"
                  src={SearchSvg}
                  alt="Search for Client"
                />
              </div>
            </div>
          </Form>
        </Formik>
        <button
          type="button"
          className="button button--state-default gap-2"
          onClick={handleAddClientClick}
        >
          <Add className="w-5 h-5" />
          Add client
        </button>
      </div>
      <div className="organisms-client-list__list">
        <div className="client-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
          <button
            type="button"
            onClick={() => sortByHeader('client', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'client' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'client' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Client</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('teams', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'teams' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'teams' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Teams</div>
            </TextWithIcons>
          </button>
          <TextWithIcons variant="h6">
            <Tooltip content="Full time equivalent" className="bg-[#343434]">
              <div className="client-list-header__item">FTE</div>
            </Tooltip>
          </TextWithIcons>
          <button
            type="button"
            onClick={() => sortByHeader('gsd', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'gsd' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'gsd' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">GSD</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('dateStart', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'dateStart' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'dateStart' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Date start</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('dateEnd', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'dateEnd' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'dateEnd' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Date end</div>
            </TextWithIcons>
          </button>
          <TextWithIcons variant="h6" className=" justify-self-center">
            <div className="flex gap-5">Action</div>
          </TextWithIcons>
        </div>

        {clients.length !== 0 ? (
          <VariableSizeList
            itemCount={clients.length}
            itemSize={getItemSize}
            height={window.innerHeight - 400}
            width="100%"
            className="no-scrollbars"
          >
            {({ index, style }) => (
              <ClientListRow
                data={clients[index]}
                className={`${index % 2 === 0 ? 'stripped' : ''}`}
                style={style}
                getDateTime={getDateTime}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                updateClientDateEnd={onDateEndClick}
              />
            )}
          </VariableSizeList>
        ) : (
          <EmptyListMessage />
        )}
      </div>
    </div>
  );
};

export default ClientList;
