import moment from 'moment/moment';
import Timezone from '../timezones/timezone.interface';
import EmploymentType from '../employments/employment-type.interface';
import LeaveNature from '../leaves/leave-nature.interface';
import EmploymentScheduleType from '../employments/schedules/employment-schedule-type.interface';

interface DefaultValueConst {
  timezone: Timezone;
  currentDate: Date;
  dateToday: string;
  employmentType: EmploymentType;
  leaveNature: LeaveNature;
  employmentScheduleType: EmploymentScheduleType;
}

const defaultValue: DefaultValueConst = {
  timezone: {
    id: 1,
    code: 'PHT',
    timezone: 'Asia/Manila',
    offset: 8,
  },
  currentDate: moment().toDate(),
  dateToday: moment().format('YYYY-MM-DD'),
  employmentType: {
    id: 5,
    name: 'Regular',
  },
  leaveNature: {
    adminOnly: 1,
    baseDaysNotice: 7,
    id: 11,
    isDeductible: 1,
    nature: 'Vacation',
    paidBy: 1,
  },
  employmentScheduleType: {
    id: 1,
    name: 'Regular',
    daysPerWeek: 5,
    daysPerMonth: 21.75,
    hours: 9,
    breakLength: 1,
  },
};

export default defaultValue;
