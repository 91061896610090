import { useState, useEffect } from 'react';

interface TimeDifference {
  hours: number;
  minutes: number;
  seconds: number;
}

const useTimeDifference = (
  targetTime: string | undefined,
  currentTime: string,
): TimeDifference | undefined => {
  const [timeDifference, setTimeDifference] = useState<TimeDifference>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!targetTime) return;

    const calculateTimeDifference = () => {
      const [targetHours, targetminutes, targetseconds] = targetTime.split(':').map(Number);
      const [currentHours, currentminutes, currentseconds] = currentTime.split(':').map(Number);

      let hoursDiff = targetHours - currentHours;
      let minutesDiff = targetminutes - currentminutes;
      let secondsDiff = targetseconds - currentseconds;

      if (secondsDiff < 0) {
        minutesDiff -= 1;
        secondsDiff += 60;
      }
      if (minutesDiff < 0) {
        hoursDiff -= 1;
        minutesDiff += 60;
      }
      if (hoursDiff < 0) {
        hoursDiff += 24;
      }

      setTimeDifference({
        hours: hoursDiff,
        minutes: minutesDiff,
        seconds: secondsDiff,
      });
    };

    calculateTimeDifference();

    const interval = setInterval(calculateTimeDifference, 1000);

    // eslint-disable-next-line
    return () => {
      clearInterval(interval);
    };
  }, [targetTime, currentTime]);

  return Object.values(timeDifference).every((value) => value === 0) ? undefined : timeDifference;
};

export default useTimeDifference;
