import { createSlice } from '@reduxjs/toolkit';
import LeaveFileAttachment from '../../constants/leaves/leave-file-attachment.interface';

export interface UploadFileAttachmentState {
  uploadFileAttachment: LeaveFileAttachment[];
}

const initialState: UploadFileAttachmentState = {
  uploadFileAttachment: [],
};

const uploadFileAttachmentSlice = createSlice({
  name: 'UploadFileAttachment',
  initialState,
  reducers: {
    setUploadFileAttachment: (state, action) => {
      state.uploadFileAttachment = action.payload;
    },
    resetUploadFileAttachment: (state) => {
      state.uploadFileAttachment = [];
    },
  },
});

export const uploadFileAttachmentActions = uploadFileAttachmentSlice.actions;
export default uploadFileAttachmentSlice.reducer;
