import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
import { ReactComponent as DownArrowTail } from '../../../../assets/images/icons/arrow-with-tail-down.svg';
import useResultDocuments from '../../../../hooks/my/healthTracker/useResultDocuments';
import useDateTime from '../../../../hooks/utils/useDateTime';
import useNurseStationVisits from '../../../../hooks/my/healthTracker/useNurseStationVisits';

export interface VaccinationRecord {
  id: number;
  name: string;
  nameShot: string;
  nextShot: string;
  status: string;
}

const dummyVaccineData: VaccinationRecord[] = [
  {
    id: 1,
    name: 'Person Jade',
    nameShot: 'Sinovac',
    nextShot: '2023-01-15',
    status: 'Pending',
  },
  {
    id: 2,
    name: 'Meizen',
    nameShot: 'Pfizer ',
    nextShot: '2023-02-10',
    status: 'Completed',
  },
  {
    id: 3,
    name: 'Metsu',
    nameShot: 'COVID-19',
    nextShot: '2023-03-05',
    status: 'Scheduled',
  },
  {
    id: 4,
    name: 'Saizu',
    nameShot: 'COVID-20',
    nextShot: '2023-09-12',
    status: 'Cancelled',
  },
];

const HealthTracker = () => {
  const { nurseStationVisitsState } = useNurseStationVisits();
  const { resultDocumentsState } = useResultDocuments();
  const { getDate } = useDateTime();

  const [openVaccine, setOpenVaccine] = useState(true);
  const [openDocuments, setOpenDocuments] = useState(true);
  const [openNurseVisits, setOpenNurseVisits] = useState(true);
  return (
    <div className="user-tab-container">
      <div className="user-health-container">
        <h1 className="flex justify-center items-center mb-4 user-profile-tab-title">
          Health Tracker
        </h1>
        <Accordion
          open={openVaccine}
          icon={
            <DownArrowTail
              className={`w-5 h-5 transition-transform user-accordion-icon ${
                openVaccine ? 'rotate-180' : ''
              }`}
            />
          }
        >
          {/* Vaccinations */}
          <div className="h-full mb-6">
            <AccordionHeader
              onClick={() => setOpenVaccine(!openVaccine)}
              className="border-none py-1 px-2 user-accordion-header"
            >
              <h2 className="accordion-header-title">Vaccinations</h2>
            </AccordionHeader>
            <AccordionBody className="p-2">
              <div className="h-full">
                {/* header */}
                <div className="vaccine-header-wrapper accordion-header-second">
                  <h3>Vaccine Name</h3>
                  <h3>Date of Shot</h3>
                  <h3>Next Shot</h3>
                  <h3>Status</h3>
                </div>
                {/* list */}
                <div className="user-payroll-accordion-wrapper no-scrollbars">
                  {dummyVaccineData.map((data, index) => (
                    <div
                      className={`${
                        index % 2 === 0 ? 'stripped' : ''
                      } vaccine-list-wrapper accordion-list-second`}
                      key={data.id}
                    >
                      <span>{data.name}</span>
                      <span>{data.nameShot}</span>
                      <span>{data.nextShot}</span>
                      <span>{data.status}</span>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionBody>
          </div>
        </Accordion>
        <Accordion
          open={openDocuments}
          icon={
            <DownArrowTail
              className={`w-5 h-5 transition-transform user-accordion-icon ${
                openDocuments ? 'rotate-180' : ''
              }`}
            />
          }
        >
          {/* Result Documents */}
          <div className="mb-6">
            <AccordionHeader
              onClick={() => setOpenDocuments(!openDocuments)}
              className="border-none py-1 px-2 user-accordion-header"
            >
              <h2 className="accordion-header-title">Result Documents</h2>
            </AccordionHeader>
            <AccordionBody className="p-2">
              <div className="documents-vacination-container">
                {/* header */}
                <div className="documents-header-wrapper accordion-header-second">
                  <h3>Date of Result</h3>
                  <h3>Type</h3>
                  <h3>Description</h3>
                </div>
                {/* list */}
                <div className="user-payroll-accordion-wrapper no-scrollbars">
                  {resultDocumentsState.resultDocuments.map((data, index) => (
                    <div
                      className={`${
                        index % 2 === 0 ? 'stripped' : ''
                      } documents-list-wrapper accordion-list-second`}
                      key={data.id}
                    >
                      <span>{getDate(data.dateOfResult)}</span>
                      <span>{data.type}</span>
                      <span>{data.description}</span>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionBody>
          </div>
        </Accordion>
        <Accordion
          open={openNurseVisits}
          icon={
            <DownArrowTail
              className={`w-5 h-5 transition-transform user-accordion-icon ${
                openNurseVisits ? 'rotate-180' : ''
              }`}
            />
          }
        >
          {/* Nurse Station Visits */}
          <div className="mb-6">
            <AccordionHeader
              onClick={() => setOpenNurseVisits(!openNurseVisits)}
              className="border-none py-1 px-2 user-accordion-header"
            >
              <h2 className="accordion-header-title">Nurse Station Visits</h2>
            </AccordionHeader>
            <AccordionBody className="p-2">
              <div className="nurse-vacination-container">
                {/* header */}
                <div className="nurse-header-wrapper accordion-header-second">
                  <h3>Date</h3>
                  <h3>Complains</h3>
                  <h3>Findings</h3>
                  <h3>Intervention</h3>
                  <h3>Nurse In-charge</h3>
                  <h3>Action Taken</h3>
                  <h3>Notes</h3>
                </div>
                {/* list */}
                <div className="user-payroll-accordion-wrapper no-scrollbars">
                  {nurseStationVisitsState.nurseStationVisits.map((data, index) => (
                    <div
                      className={`${
                        index % 2 === 0 ? 'stripped' : ''
                      } nurse-list-wrapper accordion-list-second`}
                      key={data.id}
                    >
                      <span>{getDate(data.date)}</span>
                      <span>{data.complains}</span>
                      <span>{data.findings}</span>
                      <span>{data.intervention}</span>
                      <span>{data.nurseInCharge}</span>
                      <span>{data.action}</span>
                      <span>{data.notes}</span>
                    </div>
                  ))}
                </div>
              </div>
            </AccordionBody>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default HealthTracker;
