import React from 'react';
import TimelogPageList from './TimelogPageList';
import ManagementHeading from '../../atoms/ManagementHeading';

const TimelogPage: React.FC = () => (
  <div className="w-full flex">
    <div className="w-full flex flex-col">
      <div className="m-2 title">
        <ManagementHeading title="Timelog" subtitle="Report" />
      </div>
      <div className="organisms-timelog-management w-full">
        <TimelogPageList />
      </div>
    </div>
  </div>
);

export default TimelogPage;
