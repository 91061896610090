import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuth from '../../hooks/auths/useAuth';
import SidebarHome from '../organisms/user/sidebar/SidebarHomeUnused';
import UserNavbar from '../organisms/user/navbar/UserHeader';
import UserMyProfile from '../organisms/user/employeeProfile/UserMyProfile';
import UserMyPayslip from '../organisms/my/payslips/UserMyPayslip';
import PayslipConcernManagement from '../organisms/my/payslips/payslipConcern/PayslipConcernManagement';
import OvertimeHistoryManagement from '../organisms/my/overtimes/OvertimesManagement';
import SideMenu from '../organisms/user/sidebar/SideMenu';
import LeaveAddForm from '../organisms/leave/leaveApplication/LeaveAddForm';
import LeaveEditForm from '../organisms/leave/leaveApplication/LeaveEditForm';
import MyLeaveHistory from '../organisms/my/leaveHistory/MyLeaveHistory';

const MyTemplate: React.FC = () => {
  const { authState } = useAuth();

  if (!authState.token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="parent-container">
      <UserNavbar />
      <div className="flex user-container">
        <div className="sidebar-wrapper hidden">
          <SidebarHome />
        </div>
        <div className="sidemenu-container">
          <div className="mt-[5.5rem]">
            <SideMenu />
          </div>
        </div>
        <div className="center-wrapper">
          <Routes>
            <Route path="/profile" element={<UserMyProfile />} />
            <Route path="/payslip" element={<UserMyPayslip />} />
            <Route path="/payslip/concern" element={<PayslipConcernManagement />} />
            <Route path="/overtime" element={<OvertimeHistoryManagement />} />
            <Route path="/leaves/applications" element={<LeaveAddForm />} />
            <Route path="/leaves/applications/:id" element={<LeaveEditForm />} />
            <Route path="/leave/history" element={<MyLeaveHistory />} />

            <Route path="*" element={<UserMyProfile />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default MyTemplate;
