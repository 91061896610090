import React, { useState } from 'react';
import moment from 'moment';
import { Dialog, DialogBody, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import consoleLog from '../../../../utils/consoleLog';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import SelectTeam from '../../../atoms/dropdowns/SelectTeam';
import Team from '../../../../store/constants/teams/team.interface';
import { ReactComponent as ThreeUsers } from '../../../../assets/images/icons/user-three.svg';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';
import useCalculateTimeDuration from '../../../../hooks/utils/useCalculateTimeDuration';

export interface OvertimeNewTeamFormProps {
  manageOpenModal: boolean;
  handleCloseModal: () => void;
}

export interface OvertimeNewTeamType {
  team: Team | undefined;
  date: Date | string;
  timeIn: string;
  timeOut: string;
  duration: string;
}

const OvertimeNewTeamForm: React.FC<OvertimeNewTeamFormProps> = ({
  manageOpenModal,
  handleCloseModal,
}) => {
  const { duration, setDuration, calculateDuration } = useCalculateTimeDuration();

  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
  const [loading, setLoading] = useState(false);

  const maxDate = moment().format('YYYY-MM-DD');

  const handleTeamFormSubmit = (values: OvertimeNewTeamType) => {
    setLoading(true);

    const formattedDate = moment(values.date).format('YYYY-MM-DD');
    const formattedTimeIn = moment(`${formattedDate} ${values.timeIn}`, 'YYYY-MM-DD hh:mmA').format(
      'YYYY-MM-DD HH:mm',
    );
    let formattedTimeOut = moment(`${formattedDate} ${values.timeOut}`, 'YYYY-MM-DD hh:mmA').format(
      'YYYY-MM-DD HH:mm',
    );

    // Check if time-out is on the same day or next day
    if (moment(formattedTimeOut).isBefore(formattedTimeIn)) {
      formattedTimeOut = moment(values.date).add(1, 'day').format('YYYY-MM-DD');
      formattedTimeOut = moment(
        `${formattedTimeOut} ${values.timeOut}`,
        'YYYY-MM-DD hh:mmA',
      ).format('YYYY-MM-DD HH:mm');
    }

    const overtimeTeamData: Omit<OvertimeNewTeamType, 'id'> = {
      overtimeTimeIn: formattedTimeIn,
      overtimeTimeOut: formattedTimeOut,
      team: selectedTeam?.name,
      duration,
    } as unknown as OvertimeNewTeamType;

    setLoading(false);
    consoleLog('submitted', overtimeTeamData);
    handleCloseModal();
    setSelectedTeam(undefined);
    setDuration('');
  };

  const handleClose = () => {
    setDuration('');
    handleCloseModal();
  };

  return (
    <Dialog
      size="sm"
      open={manageOpenModal}
      handler={handleCloseModal}
      className="shadow-none overtime-edit-form-wrapper"
    >
      <div className="absolute top-0 right-0 flex p-0 m-2">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex items-center gap-2 pb-2 overtime-manage-wrapper">
        <ThreeUsers className="w-5 h-5" />
        <h1>New Team Overtime ?</h1>
      </div>
      <DialogBody divider className="p-0">
        <Formik
          initialValues={{
            team: undefined,
            date: moment().format('YYYY-MM-DD'),
            timeIn: '',
            timeOut: '',
            duration: '',
          }}
          onSubmit={handleTeamFormSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="w-full">
              <div className="flex justify-center gap-3 w-full my-5">
                <div className="overtime-first-container">
                  {/* Team Search */}
                  <div className="w-full mb-3 overtime-list-wrapper">
                    <label htmlFor="timeIn" className="block mb-2 text-sm font-semibold -field">
                      Team
                    </label>
                    <div className="w-full">
                      <SelectTeam
                        id="team"
                        name="team"
                        value={selectedTeam}
                        onChange={(value) => setSelectedTeam(value as Team)}
                        hasSearchIcon
                        isSearchable
                        isClearable
                      />
                    </div>
                  </div>
                  {/* Time-in */}
                  <div className="mb-3">
                    <label
                      htmlFor="timeIn"
                      className="block mb-2 text-sm font-semibold overtime-title-field"
                    >
                      TIME IN
                    </label>
                    <div className="flex gap-2 justify-center items-center">
                      <Field
                        type="time"
                        id="timeIn"
                        name="timeIn"
                        placeholder="Time in"
                        className="w-full p-2 rounded-md shadow-lg overtime-field"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('timeIn', e.target.value);
                          calculateDuration(e.target.value, values.timeOut);
                        }}
                      />
                      <ErrorMessage name="timeIn" component="div" />
                    </div>
                  </div>
                  {/* Duration */}
                  <div className="mb-3">
                    <h2 className="block mb-2 text-sm font-semibold overtime-title-field">
                      Duration
                    </h2>
                    <p className="w-full p-2 rounded-md shadow-lg overtime-field disabled-field">
                      <span className="pl-1">{duration}</span>
                    </p>
                  </div>
                </div>

                <div className="overtime-second-container">
                  {/* Overtime Date  */}
                  <div className="mb-3">
                    <label
                      htmlFor="date"
                      className="block mb-2 text-sm font-semibold overtime-title-field"
                    >
                      Date
                    </label>
                    <Field
                      type="date"
                      id="date"
                      name="date"
                      max={maxDate}
                      placeholder="Date Start"
                      className="w-full p-2 rounded-md shadow-lg overtime-field"
                    />
                    <ErrorMessage name="date" component="div" />
                  </div>
                  {/* Time-Out */}
                  <div className="mb-3">
                    <label
                      htmlFor="timeOut"
                      className="block mb-2 text-sm font-semibold overtime-title-field"
                    >
                      TIME OUT
                    </label>
                    <div className="flex gap-2 justify-center items-center">
                      <Field
                        type="time"
                        id="timeOut"
                        name="timeOut"
                        placeholder="Time out"
                        className="w-full p-2 rounded-md shadow-lg overtime-field"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue('timeOut', e.target.value);
                          calculateDuration(values.timeIn, e.target.value);
                        }}
                      />
                      <ErrorMessage name="timeOut" component="div" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between w-full pb-5 scheduler-button-wrapper">
                <div className="flex justify-center w-max">
                  <ButtonAction
                    type="submit"
                    className=" p-2 rounded-md shadow-lg button-submit-scheduler"
                    isLoading={loading}
                    hasSpinnerText={false}
                    isSubmit
                  />
                </div>
                <button
                  type="button"
                  onClick={handleClose}
                  className="p-2  rounded-lg shadow-lg button-close-scheduler"
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export default OvertimeNewTeamForm;
