import { useState } from 'react';
import usePositions from '../positions/usePositions';
import useOnLoad from '../utils/useOnLoad';
import useUserPositionsAssignment from './useUserPositionsAssignment';

const useUserEmploymentPosition = (id: string) => {
  const {
    get,
    positionsState: { positions },
  } = usePositions();
  const { getAllUserEmploymentsPosition } = useUserPositionsAssignment();
  const [loading, setLoading] = useState(true);

  const positionId = parseInt(id, 10);
  const currentPosition = positions.find((position) => position.id === positionId);

  useOnLoad(() => {
    if (currentPosition) {
      setLoading(false);
    } else {
      setLoading(true);
    }

    get(positionId).then(() => {
      setLoading(false);
    });
    getAllUserEmploymentsPosition(positionId);
  });

  return {
    loading,
    currentPosition,
  };
};

export default useUserEmploymentPosition;
