import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ReactComponent as Edit } from '../../../assets/images/icons/edit.svg';
import { ReactComponent as TeamLeader } from '../../../assets/images/icons/person.svg';
import useTeams from '../../../hooks/teams/useTeams';
import Team from '../../../store/constants/teams/team.interface';
import SelectTeamLeader from '../../atoms/dropdowns/SelectTeamLeader';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import toastNotification from '../../../hooks/utils/toastNotification';

export interface TeamLeaderAssignmentModalParams {
  team?: Team;
  className?: string;
  resetSort?: () => void;
  onOpen: () => void;
  onClose: () => void;
}

const TeamLeaderAssignmentModal: React.FC<TeamLeaderAssignmentModalParams> = ({
  team = undefined,
  className = undefined,
  resetSort = () => {},
  onOpen,
  onClose,
}) => {
  const [teamLeaderAssignmentForm, setTeamLeaderAssignmentForm] = useState<Team | undefined>();
  const [open, setOpen] = React.useState(false);

  const { updateTeamLeader } = useTeams();

  const handleClose = () => {
    setTeamLeaderAssignmentForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setTeamLeaderAssignmentForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<Team>) => {
    await toastNotification({
      action: updateTeamLeader({ ...teamLeaderAssignmentForm, ...values } as Partial<Team>),
      onSuccess: () => {
        resetSort();
      },
      onError: () => {},
      onFulfilled: () => {
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Team leader information is successfully updated.',
        error: 'Error on updating Department information.',
      },
    });

    setOpen(false);
  };

  useEffect(() => {
    if (team) {
      setOpen(true);
      setTeamLeaderAssignmentForm(team);
    }
  }, [team]);

  return (
    <>
      <button type="button" className={className} onClick={() => onOpen()}>
        <Edit className="client-icons" />
      </button>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="m-2 p-0 absolute flex top-0 right-0">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-3">
          <TeamLeader className="modal-icons" />
          <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
            Team leader assignment
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{
                  ...teamLeaderAssignmentForm,
                  teamLeader: teamLeaderAssignmentForm?.teamLeader
                    ? ({
                        ...teamLeaderAssignmentForm?.teamLeader,
                        label: teamLeaderAssignmentForm?.teamLeader?.userInformation.fullName,
                        value: teamLeaderAssignmentForm?.teamLeader,
                      } as UserEmployment)
                    : undefined,
                }}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form>
                    {/* FORM SECTION */}
                    <div className="mt-4 mb-4 form-section">
                      <div className="flex flex-col justify-center w-full">
                        <div className="mb-6">
                          <label
                            htmlFor="teamLeader"
                            className="modal-labels block mb-2 text-gray-200"
                          >
                            Team leader
                          </label>
                          <SelectTeamLeader
                            id="teamLeader"
                            name="teamLeader"
                            isClearable
                            isSearchable
                            value={formik.values.teamLeader}
                            setFieldValue={formik.setFieldValue}
                          />
                          <ErrorMessage name="teamLeader" component="div" />
                        </div>

                        {team?.teamLeader.userInformation.fullName ===
                          formik.values.teamLeader?.userInformation.fullName ||
                        !team?.teamLeader ||
                        !formik.values.teamLeader ? (
                          <div className="mb-6">
                            <label
                              htmlFor="dateEnd"
                              className="block mb-2 text-gray-200 modal-labels"
                            >
                              Date end
                            </label>
                            <div className="">
                              <Field
                                type="date"
                                id="dateEnd"
                                name="dateEnd"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                                required
                              />
                            </div>
                            <ErrorMessage name="dateEnd" component="div" />
                          </div>
                        ) : (
                          <div className="mb-6">
                            <label
                              htmlFor="dateStart"
                              className="block mb-2 text-gray-200 modal-labels"
                            >
                              Date start
                            </label>
                            <div className="">
                              <Field
                                type="date"
                                id="dateStart"
                                name="dateStart"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                                required
                              />
                            </div>
                            <ErrorMessage name="dateStart" component="div" />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* END OF FORM SECTION */}
                    {/* Buttons */}
                    <div className="flex space-x-2 w-max">
                      <div className="flex justify-center w-full">
                        <button
                          type="submit"
                          className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="flex justify-center w-max">
                        <button
                          type="button"
                          onClick={handleClose}
                          className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default TeamLeaderAssignmentModal;
