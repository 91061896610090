import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog, DialogBody, IconButton } from '@material-tailwind/react';
import momentFormat from '../../../../utils/momentFormat';
import LeaveApplication, {
  LeaveStatus,
} from '../../../../store/constants/leaves/leave-application.interface';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import useLeaves from '../../../../hooks/leave/useLeaves';
import Airplane from '../../../../assets/images/icons/airplane.svg';
import CustomRangeDatePicker from '../../../atoms/datepickers/CustomRangeDatePicker';
import countDaysExcluding from '../../../../utils/countTotalDaysWithExclusions';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import toastNotification from '../../../../hooks/utils/toastNotification';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';

export interface LeaveListFormModalParams {
  leave: LeaveApplication;
  onClose: () => void;
}

const LeaveListFormRow: React.FC<LeaveListFormModalParams> = ({ leave, onClose }) => {
  const { getEmployeeByEmployeeNumber } = useUserEmployments();
  const { leavesState, remove, approve, deny } = useLeaves();

  const [leaveListForm, setLeaveListForm] = useState<LeaveApplication | undefined>();
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [denyLoading, setDenyLoading] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);

  const convertedExcludedDates = leave?.excludedDates.map((dateFormat) => new Date(dateFormat));

  const selectedDays =
    leave?.dateStart &&
    leave?.dateEnd &&
    countDaysExcluding(new Date(leave.dateStart), new Date(leave.dateEnd), convertedExcludedDates);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setLeaveListForm(undefined);
    setOpen(false);
    onClose();
  };

  const getLeaveStatus = () => {
    if (leaveListForm && leaveListForm.dateApproved) {
      return 'Approved';
    }
    if (leaveListForm && leaveListForm.dateDenied) {
      return 'Denied';
    }
    return 'Pending';
  };

  const handleDelete = async (data: LeaveApplication) => {
    setIsloading(true);
    await toastNotification({
      action: remove(data.id),
      onSuccess: () => {},
      onError: () => {},
      onFulfilled: () => {
        setIsloading(false);
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing your request...',
        success: 'Leave application has been successfully deleted.',
        error: 'An error occurred while deleting the leave application. Please try again.',
      },
    });
  };

  const handleSubmit = async (values: Partial<LeaveApplication>, status?: LeaveStatus) => {
    if (status === 'Approved') {
      setApproveLoading(true);
    } else {
      setDenyLoading(true);
    }

    const data = {
      ...values,
      employmentScheduleTypeId: Number(values.employmentScheduleTypeId),
      status,
    };

    try {
      await toastNotification({
        action:
          status === 'Approved'
            ? approve(data as LeaveApplication)
            : deny(data as LeaveApplication),
        onSuccess: () => {
          if (status === 'Approved') {
            setApproveLoading(false);
          } else {
            setDenyLoading(false);
          }
          handleClose();
        },
        onError: () => {},
        onFulfilled: async () => {},
        toastPromiseMessages: {
          pending: 'Processing',
          success: `Leave application has been ${status === 'Approved' ? 'Approved.' : 'Denied'}.`,
          error: `Error on ${status === 'Approved' ? 'Approving' : 'Denying'} leave application.`,
        },
      });
    } finally {
      if (status === 'Approved') {
        setApproveLoading(false);
      } else {
        setDenyLoading(false);
      }
    }

    setOpen(false);
  };

  const approvedByEmployeeNumber =
    typeof leaveListForm?.approvedByEmployeeNumber === 'string'
      ? parseInt(leaveListForm?.approvedByEmployeeNumber, 10)
      : leaveListForm?.approvedByEmployeeNumber;

  const employee = getEmployeeByEmployeeNumber(leaveListForm?.employeeNumber as number);
  const leaveNature = leavesState.leaveNatures.find(
    (nature) => nature.id === leaveListForm?.leaveNatureId,
  );

  useEffect(() => {
    if (leave) {
      setOpen(true);
      setLeaveListForm(leave);
    }
  }, [leave]);

  return (
    <div>
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="shadow-none leave-dialog-wrapper"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <Close className="w-5 h-auto" />
          </IconButton>
        </div>
        <div className="flex items-center gap-2">
          <img src={Airplane} className="leave-icon-form" alt="" />
          <h1 className="text-header">Leave Applications</h1>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <Formik
              initialValues={{
                ...leaveListForm,
                remarks: leaveListForm?.remarks ?? '',
              }}
              onSubmit={() => {}}
            >
              {({ values }) => (
                <Form className="w-full">
                  <div className="mt-4 mb-5 form-section">
                    <div className="flex gap-5">
                      {/* FIrst Container */}
                      <div className="flex flex-col justify-start w-full">
                        {/* Employee Name */}
                        <div className="mb-2 leave-field-wrapper">
                          <label htmlFor="employee" className="block mb-1 text-sm font-semibold">
                            Employee Name
                          </label>
                          <h2 className="field-value">{employee?.userInformation.fullName}</h2>
                        </div>
                        {/* Date Filed */}
                        <div className="mb-2 leave-field-wrapper">
                          <label htmlFor="dateFiled" className="block mb-1 text-sm font-semibold">
                            Date Filed
                          </label>
                          <h2 className="field-value">
                            {leaveListForm?.dateCreated &&
                              momentFormat(leaveListForm.dateCreated, 'MMMM DD, YYYY h:mm:ss A')}
                          </h2>
                        </div>
                        {/* Date Approved */}
                        {leaveListForm?.dateApproved && (
                          <div className="mb-2 leave-field-wrapper">
                            <label
                              htmlFor="dateApproved"
                              className="block mb-1 text-sm font-semibold"
                            >
                              Date Approved
                            </label>
                            <h2 className="field-value">
                              {leaveListForm?.dateApproved &&
                                momentFormat(
                                  leaveListForm.dateApproved,
                                  'MMMM DD, YYYY, h:mm:ss A',
                                )}
                            </h2>
                          </div>
                        )}
                        {/* Leave Date Duration */}
                        <div className="leave-field-wrapper">
                          <label htmlFor="leaveDuration">Date Of Leave</label>
                          <CustomRangeDatePicker
                            id="duration"
                            name="duration"
                            className="leave-history-duration"
                            placeholder="Leave Duration"
                            popperPlacement="left-end"
                            popperClassName="m-0"
                            popperModifiers={[
                              {
                                name: 'offset',
                                options: {
                                  offset: [200, 15],
                                },
                              },
                            ]}
                            excludedDates={convertedExcludedDates}
                            dateStart={new Date(leave?.duration[0])}
                            dateEnd={new Date(leave.duration[leave.duration.length - 1])}
                            readOnly
                          />
                        </div>
                        {/* Total Days */}
                        <div className="mb-2 leave-field-wrapper">
                          <label htmlFor="leaveCount" className="block mb-1 text-sm font-semibold">
                            Total Days
                          </label>
                          <h2 className="field-value">
                            {`${leaveListForm?.leaveCount && selectedDays} day(s)` ?? 'No Data'}
                          </h2>
                        </div>
                        {/* Employment Schedule Type */}
                        <div className="mb-2 leave-field-wrapper">
                          <label htmlFor="reason" className="block mb-1 text-sm font-semibold">
                            Schedule type
                          </label>
                          <Field
                            as="select"
                            id="employmentScheduleTypeId"
                            name="employmentScheduleTypeId"
                            disabled={
                              getLeaveStatus() === 'Denied' || getLeaveStatus() === 'Approved'
                            }
                            className="w-full leavelist-field leave-schedule-type"
                            required
                          >
                            {leavesState.employmentScheduleType.map((scheduleType) => (
                              <option
                                key={scheduleType.id}
                                value={scheduleType.id}
                                // selected={
                                //   leaveListForm?.employmentScheduleTypeId ===
                                //   String(scheduleType.id)
                                // }
                              >
                                {scheduleType.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                      {/* Second Container */}
                      <div className="flex flex-col justify-start w-full">
                        {/* status */}
                        <div className="mb-2 leave-field-wrapper">
                          <label htmlFor="status" className="block mb-1 text-sm font-semibold">
                            Status
                          </label>
                          <h2 className="field-value">
                            <span className={getLeaveStatus().toLowerCase()}>
                              {getLeaveStatus()}
                            </span>
                          </h2>
                        </div>
                        {/* Date Denied */}
                        {getLeaveStatus() === 'Denied' && (
                          <div className="mb-2 leave-field-wrapper">
                            <label
                              htmlFor="dateDenied"
                              className="block mb-1 text-sm font-semibold"
                            >
                              Date Denied
                            </label>
                            <h2 className="field-value">
                              {leaveListForm?.dateDenied &&
                                momentFormat(leaveListForm.dateDenied, 'MMMM DD, YYYY, h:mm:ss A')}
                            </h2>
                          </div>
                        )}
                        {/* Approved By */}
                        {getLeaveStatus() === 'Approved' && (
                          <div className="mb-2 leave-field-wrapper">
                            <label
                              htmlFor="approvedBy"
                              className="block mb-1 text-sm font-semibold"
                            >
                              Approve By
                            </label>
                            <h2 className="field-value">
                              {typeof approvedByEmployeeNumber === 'number' &&
                                `${
                                  getEmployeeByEmployeeNumber(approvedByEmployeeNumber)
                                    ?.userInformation.firstName
                                } 
                              ${
                                getEmployeeByEmployeeNumber(approvedByEmployeeNumber)
                                  ?.userInformation.lastName
                              }`}
                            </h2>
                          </div>
                        )}
                        {/* Nature */}
                        <div className="mb-2 leave-field-wrapper">
                          <label
                            htmlFor="leaveNature.nature"
                            className="block mb-1 text-sm font-semibold"
                          >
                            Nature
                          </label>
                          <h2 className="field-value">{leaveNature?.nature}</h2>
                        </div>
                        <div className="mb-2 leave-field-wrapper">
                          <label htmlFor="paid" className="block mb-1 text-sm font-semibold">
                            Paid
                          </label>
                          <h2 className="field-value">
                            {leaveListForm?.isPaid ? 'Paid' : 'Not Paid'}
                          </h2>
                        </div>
                        {/* Reason */}
                        {(leaveListForm?.dateApproved || leaveListForm?.dateDenied) && (
                          <div className="mb-2 leave-field-wrapper">
                            <label htmlFor="notes" className="block mb-1 text-sm font-semibold">
                              Reason
                            </label>
                            <h2 className="field-value">{leaveListForm?.notes ?? 'No Reason'}</h2>
                          </div>
                        )}
                        {/* Reviewed By */}
                        {leaveListForm?.status.toLowerCase() !== 'pending' && (
                          <div className="mb-2 leave-field-wrapper">
                            <label
                              htmlFor="reviewedBy"
                              className="block mb-1 text-sm font-semibold"
                            >
                              Reviewed By
                            </label>
                            <h2 className="field-value">
                              {leaveListForm?.reviewedBy &&
                                getEmployeeByEmployeeNumber(
                                  leaveListForm?.reviewedBy.employeeNumber,
                                )?.userInformation.fullName}
                            </h2>
                          </div>
                        )}
                        <div className="mb-2 leave-field-wrapper">
                          <label htmlFor="notes" className="block mb-1 text-sm font-semibold">
                            Attachments
                          </label>
                          <h2 className="field-value">
                            {leave?.attachments && leave.attachments.length > 0
                              ? `You have ${leave.attachments.length} ${
                                  leave.attachments.length === 1 ? 'Attachment' : 'Attachments'
                                }`
                              : 'No Attachments'}
                          </h2>
                        </div>
                        {/* Total Days */}
                        {leaveListForm?.status.toLowerCase() === 'reviewed' && (
                          <div className="mb-2 leave-field-wrapper">
                            <label htmlFor="totalDays" className="block mb-1 text-sm font-semibold">
                              Total Days
                            </label>
                            <h2 className="field-value">
                              {`${leaveListForm?.leaveCount} day(s)` ?? 'No Data'}
                            </h2>
                          </div>
                        )}
                        {/* Reason */}
                        {leaveListForm?.status.toLowerCase() === 'reviewed' && (
                          <div className="mb-2 leave-field-wrapper">
                            <label htmlFor="reason" className="block mb-1 text-sm font-semibold">
                              Reason
                            </label>
                            <div className="">
                              <Field
                                type="text"
                                id="reason"
                                name="reason"
                                placeholder="Reason"
                                className="w-full leavelist-field"
                                disabled
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* END FIRST SECTION */}

                    {/* Remarks */}
                    {getLeaveStatus() === 'Pending' && (
                      <div className="mb-2 leave-field-wrapper">
                        <label htmlFor="remarks" className="block mb-1 text-sm font-semibold">
                          Remarks
                        </label>
                        <div className="">
                          <Field
                            className="w-full leavelist-field leave-textarea"
                            as="textarea"
                            id="remarks"
                            name="remarks"
                            placeholder="Remarks..."
                          />
                          <ErrorMessage name="remarks" component="div" />
                        </div>
                      </div>
                    )}
                    {(leaveListForm?.dateApproved || leaveListForm?.dateDenied) && (
                      <div className="mb-2 leave-field-wrapper">
                        <label htmlFor="remarks" className="block mb-1 text-sm font-semibold">
                          Remarks
                        </label>
                        <div className="">
                          <Field
                            className="w-full leavelist-field leave-textarea"
                            as="textarea"
                            id="remarks"
                            name="remarks"
                            placeholder="Remarks..."
                            disabled={
                              getLeaveStatus() === 'Denied' || getLeaveStatus() === 'Approved'
                            }
                          />
                          <ErrorMessage name="remarks" component="div" />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex justify-between items-center">
                    {getLeaveStatus() === 'Pending' ? (
                      <div className="flex gap-4 w-full">
                        <ButtonAction
                          type="button"
                          className="flex justify-center items-center p-2 rounded-md shadow-lg leave-button-approved"
                          isLoading={approveLoading}
                          hasSpinnerText={false}
                          text="Approve"
                          onClick={() => handleSubmit(values, 'Approved')}
                          isSubmit
                        />
                        <ButtonAction
                          type="button"
                          className="flex justify-center items-center p-2 rounded-md shadow-lg leave-button-denied"
                          isLoading={denyLoading}
                          hasSpinnerText={false}
                          text="Deny"
                          onClick={() => handleSubmit(values, 'Denied')}
                          isSubmit
                        />
                      </div>
                    ) : (
                      <>
                        <div />
                        <div />
                      </>
                    )}

                    {getLeaveStatus() === 'Approved' ? (
                      <div className="flex w-full">
                        <ButtonAction
                          type="button"
                          className="flex justify-center items-center p-2 rounded-md shadow-lg leave-button-delete"
                          isLoading={isLoading}
                          hasSpinnerText={false}
                          text="Delete"
                          onClick={() => handleDelete(leaveListForm as LeaveApplication)}
                          isSubmit
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default LeaveListFormRow;
