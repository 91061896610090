import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import userEmploymentTeamsActions from '../../store/actions/users/employments/userEmploymentTeamsActions';
import {
  userTeamsReducerActions,
  UserTeamsState,
} from '../../store/reducers/teams/userTeamsReducer';
import UserEmploymentTeam from '../../store/constants/employees/teams/employee-team.interface';

const useUserTeams = () => {
  const dispatch = useDispatch();
  const userTeamsState: UserTeamsState = useSelector((state: RootState) => state.userTeams);

  const resetUserTeamsState = () => {
    dispatch(userTeamsReducerActions.resetUserTeamsState());
  };

  const setUserTeamsState = (state: Partial<UserTeamsState>) => {
    dispatch(userTeamsReducerActions.setUserTeamsState(state));
  };

  const getUserTeams = (employeeNumber: UserEmploymentTeam['employeeNumber'] | undefined) =>
    userTeamsState.userTeams
      .filter((userTeam) => userTeam.employeeNumber === employeeNumber)
      .sort((a, b) => {
        const defaultOrder = b.id - a.id;
        return defaultOrder;
      });

  const get = async (id: UserEmploymentTeam['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentTeamsActions.get(id));

  const getAll = async (search = {}) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentTeamsActions.getAll(search));

  const post = async (data: Omit<UserEmploymentTeam, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentTeamsActions.post(data));

  const put = async (data: Partial<UserEmploymentTeam>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentTeamsActions.put(data));

  const remove = async (id: UserEmploymentTeam['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentTeamsActions.remove(id));

  const getUser = async (id: UserEmploymentTeam['employeeNumber']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentTeamsActions.getUser(id));

  return {
    resetUserTeamsState,
    userTeamsState,
    setUserTeamsState,
    getUserTeams,
    get,
    getAll,
    post,
    put,
    remove,
    getUser,
  };
};

export default useUserTeams;
