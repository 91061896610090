import React, { useCallback, useEffect, useState } from 'react';
import { Persistor } from 'redux-persist/es/types';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/auths/useAuth';
import time from '../../utils/time';
import LogoutDialog from '../molecules/Dialogs/LogoutDialog';
import IdleDialog from '../molecules/Dialogs/IdleDialog';
import useAttendance from '../../hooks/attendances/useAttendance';
import useAppInitialization from '../../hooks/utils/useAppInitialization';
import useLoading from '../../hooks/utils/useLoading';

let interval: string | number | NodeJS.Timer | undefined;
let isUserActive = true;

interface UserActivityMonitoringProps {
  persistor: Persistor;
}

const UserActivityMonitoring = ({ persistor }: UserActivityMonitoringProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { authState, logout } = useAuth();
  const { isLoading } = useLoading();

  const [isIdle, setIsIdle] = useState(false);

  const [forcedLogout, setForcedLogout] = useState(false);

  const { setAttendanceState } = useAttendance();
  const { init, setInit, setInitDate, initDate } = useAppInitialization();

  const setUserActive = () => {
    isUserActive = true;
  };

  const checkUserActivity = useCallback(() => {
    if (isUserActive) {
      isUserActive = false;
    } else {
      setIsIdle(true);
    }
  }, []);

  const dialogHandler = () => {
    setIsIdle(false);
    navigate('/logout');
  };

  const onLogout = () => {
    logout();
    setIsIdle(false);
    navigate('/logout');
  };

  const onKeptLoggedIn = () => {
    if (authState.expireTime && authState.expireTime <= time()) {
      dialogHandler();
    } else {
      setIsIdle(false);
    }
  };

  useEffect(() => {
    if (authState.token && !interval) {
      document.addEventListener('mousemove', setUserActive);
      document.addEventListener('keydown', setUserActive);
      document.addEventListener('click', setUserActive);

      interval = setInterval(checkUserActivity, 1000 * 60 * 10); // check user activity every 10 minutes
      setIsIdle(false);
    } else if (!authState.token && interval) {
      document.removeEventListener('mousemove', setUserActive);
      document.removeEventListener('keydown', setUserActive);
      document.removeEventListener('click', setUserActive);

      clearInterval(interval);
    }
  }, [authState.token, checkUserActivity]);

  useEffect(() => {
    if (!authState.expireTime || !authState.token) {
      setForcedLogout(false);
    } else if (authState.expireTime <= time()) {
      setIsIdle(false);
      navigate('/logout');
    }
  }, [authState.expireTime, authState.token, isIdle, logout, navigate]);

  useEffect(() => {
    if (!authState.token) persistor.purge().then();
  }, [authState.token, persistor]);

  // If path is /logout, logout the user
  useEffect(() => {
    if (location.pathname === '/logout') {
      if (authState.token) {
        logout().then(() => {
          setInit(false);
          setAttendanceState({
            expectedTimes: {
              timeOut: undefined,
            },
          });
        });
      }
      navigate('/login');
    }
  }, [authState.token, location.pathname, logout, navigate, setAttendanceState, setInit]);

  // If token exists, redirect login form to newsfeed.
  useEffect(() => {
    if (init && location.pathname === '/login' && authState.token && !isLoading.isLoading) {
      navigate('/user/newsfeed');
    }
  }, [location.pathname, authState.token, isLoading.isLoading, navigate, init]);

  // set initialize to true in the application after login
  useEffect(() => {
    const currentDate = new Date().toDateString();

    if (initDate !== currentDate) {
      setInit(false);
      setInitDate(currentDate);
    }

    if (!init && authState.token && location.pathname !== '/logout') {
      setInit(true);
    }
  }, [init, authState.token, location.pathname, setInit, setInitDate, initDate]);

  return (
    <>
      <LogoutDialog isOpen={forcedLogout} handler={logout} />
      <IdleDialog
        isOpen={isIdle}
        handler={dialogHandler}
        onYes={onKeptLoggedIn}
        onLogout={onLogout}
      />
    </>
  );
};

export default UserActivityMonitoring;
