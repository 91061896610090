import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import { ScheduleTempOption } from '../../../store/reducers/schedules/schedulesReducer';
import useSchedules from '../../../hooks/schedule/useSchedules';

export interface SelectTemporaryProps extends Partial<AutoSearchSelectProps<ScheduleTempOption>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectTemporary: React.FC<SelectTemporaryProps> = ({
  id,
  name,
  placeholder = 'Yes/No',
  className = '',
  ...props
}) => {
  const {
    schedulesState: { scheduleTempOption },
  } = useSchedules();

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-temporary modal-select ${className}`}
      placeholder={placeholder}
      data={scheduleTempOption}
    />
  );
};

export default SelectTemporary;
