import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import moment from 'moment';
import UserEmploymentPosition from '../../../../store/constants/employees/positions/employee-position.interface';
import DeleteConfirmationBanner from '../../DeleteConfirmationBanner';
import { ReactComponent as EditICon } from '../../../../assets/images/icons/edit.svg';

export interface TeamListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: UserEmploymentPosition;
  getDate: (date: Date) => string;
  onEditClick: (positions: UserEmploymentPosition) => void;
  onDeleteClick: (positions: UserEmploymentPosition) => Promise<unknown>;
}

const UserEmploymentPositionRow = ({
  className = '',
  data,
  getDate,
  onEditClick,
  onDeleteClick,
  ...props
}: TeamListRowProps) => {
  const deleteMessage = {
    pending: 'Pending',
    success: 'User Employment Position is successfully deleted. ',
    error: 'Error',
  };

  return (
    <div {...props} className={`${className} grid grid-cols-5 list-data items-center p-2`}>
      <div className="">{data.employmentPosition?.name}</div>
      <div className="">{data.tier}</div>
      <div>{moment(data.dateStart).format('MMMM D, YYYY')}</div>
      <div>{data.dateEnd && moment(data.dateEnd).format('MMMM D, YYYY')}</div>
      <div className="action-buttons space-x-6 justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <EditICon className="w-5 h-5" />
          </button>
        </Tooltip>
        <DeleteConfirmationBanner
          data={data}
          label={data.employmentPosition?.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default UserEmploymentPositionRow;
