import React, { FC } from 'react';
import { Field } from 'formik';
import useLeaves from '../../../hooks/leave/useLeaves';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import LeaveNature from '../../../store/constants/leaves/leave-nature.interface';

export interface SelectLeaveNatureProps extends Partial<AutoSearchSelectProps<LeaveNature>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectLeaveNature: FC<SelectLeaveNatureProps> = ({
  id,
  name,
  placeholder = 'Select Leave Nature',
  className = '',
  ...props
}) => {
  const { leavesState } = useLeaves();

  const leaveNatures = leavesState.leaveNatures.slice().map((leaveNature) => ({
    ...leaveNature,
    value: leaveNature.id,
    label: leaveNature.nature,
  }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-leave-nature modal-select ${className}`}
      placeholder={placeholder}
      data={leaveNatures}
    />
  );
};

export default SelectLeaveNature;
