import { ColumnsType } from 'antd/es/table';
import type { EmploymentPayroll } from '../../../../../../store/reducers/payroll/payrollsReducer';

const ManageIncentivesColumn = (): ColumnsType<Partial<EmploymentPayroll>> => {
  const columns: ColumnsType<Partial<EmploymentPayroll>> = [
    {
      title: 'Employee ID',
      width: 60,
      dataIndex: 'id',
      className: 'first-column',
      align: 'center',
      key: 'id',
    },
    {
      title: 'First Name',
      width: 100,
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    },
    {
      title: 'Last Name',
      width: 100,
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    },
  ];
  return columns;
};

export default ManageIncentivesColumn;
