import React, { useEffect, useState } from 'react';

interface ImageWithDefaultProps extends React.HTMLAttributes<HTMLImageElement> {
  file?: File;
  className?: string;
  src?: string;
  defaultImageUrl: string;
  alt: string;
}

const ImageWithDefault: React.FC<ImageWithDefaultProps> = ({
  file = undefined,
  className = undefined,
  src = '',
  defaultImageUrl,
  alt,
  ...props
}) => {
  const [imageSrc, setImageSrc] = useState<string>(src);

  const handleImageError = () => {
    setImageSrc(defaultImageUrl);
  };

  useEffect(() => {
    if (src === '' && defaultImageUrl) {
      setImageSrc(defaultImageUrl);
    } else {
      setImageSrc(src);
    }
  }, [src, defaultImageUrl]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else if (src) {
      setImageSrc(src);
    }
  }, [file, src]);

  return (
    <img className={className} src={imageSrc} onError={handleImageError} alt={alt} {...props} />
  );
};

export default ImageWithDefault;
