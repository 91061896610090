import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LeaveApplicationForm from './LeaveApplicationForm';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import useMyLeaves from '../../../../hooks/leave/useMyLeaves';
import toastNotification from '../../../../hooks/utils/toastNotification';
import AtLeast from '../../../../types/atleast';
import momentFormat from '../../../../utils/momentFormat';

const LeaveAddForm: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { myPost, uploadAttachments, resetMyCurrentLeaveApplication } = useMyLeaves();

  const handleSubmit = async (data: AtLeast<LeaveApplication, 'duration' | 'excludedDates'>) => {
    if (loading) return;

    setLoading(true);
    const postData = {
      ...data,
      duration: data.duration.map((d) => momentFormat(d)),
      excludedDates: data.excludedDates.map((d) => momentFormat(d)),
    };

    try {
      await toastNotification({
        action: myPost(postData as unknown as LeaveApplication),
        onSuccess: async (response) => {
          // console.log('from_leave_upload_data', response)
          const fileData = data.attachmentsFile;
          // Check if there are files to upload to conduct the api
          if (fileData && fileData.length > 0) {
            const formData = new FormData();
            fileData.forEach((file) => {
              formData.append('attachmentsFile[]', file);
            });

            await toastNotification({
              action: uploadAttachments(formData, response.payload.data.id),
              onSuccess: () => {
                resetMyCurrentLeaveApplication();
              },
              toastPromiseMessages: {
                pending: 'Uploading files...',
                success: 'Files uploaded successfully!',
                error: {
                  render: ({ data: errMsg }) =>
                    typeof errMsg === 'string' ? errMsg : 'Failed to upload files.',
                },
              },
            });
          }
        },
        onFulfilled: () => {
          setLoading(false);
          navigate('/my/leave/history');
        },
        toastPromiseMessages: {
          pending: 'Processing your leave application...',
          success: 'Leave application is successfully submitted.',
          error: {
            render: ({ data: errMsg }) =>
              typeof errMsg === 'string' ? errMsg : 'Something went wrong! Try again.',
          },
        },
      });
    } catch (error) {
      setLoading(false);
    }
  };

  return <LeaveApplicationForm loading={loading} setLoading={setLoading} onSubmit={handleSubmit} />;
};

export default LeaveAddForm;
