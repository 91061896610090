import { Dialog, DialogBody } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import useDateTime from '../../../../hooks/utils/useDateTime';
// import LeaveFileAttachment from '../../../../store/constants/leaves/leave-file-attachment.interface';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import useLeaves from '../../../../hooks/leave/useLeaves';
import ButtonAction from '../../../atoms/buttons/ButtonAction';

interface LeaveReviewProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  handleSubmit: () => void;
  formData: LeaveApplication;
  selectedDays: number;
}

type DurationType =
  | {
      dateStart: Date;
      dateEnd: Date;
    }
  | Date[];

const LeaveReviewFormSubmit: React.FC<LeaveReviewProps> = ({
  open,
  loading,
  onClose,
  handleSubmit,
  formData,
  selectedDays,
}) => {
  const [formDataState, setFormDataState] = useState<LeaveApplication | undefined>(formData);
  const { getDate } = useDateTime();
  const {
    leavesState: { employmentScheduleType },
  } = useLeaves();

  const currentLeave = employmentScheduleType.find(
    (schedule) => schedule.id === formDataState?.employmentScheduleType?.id,
  );

  // const renderAttachments = (attachments: LeaveFileAttachment[] | undefined) => {
  //   if (!attachments || attachments.length === 0) {
  //     return <span className="empty-value">No Attachments</span>;
  //   }

  //   return (
  //     <span>
  //       You have {attachments.length} Attachment{attachments.length > 1 ? 's' : ''}
  //     </span>
  //   );
  // };

  const renderFormFields = () => {
    const durationArray: DurationType = formDataState?.duration || [];
    const leaveDuration =
      Array.isArray(durationArray) && durationArray.length > 0
        ? `${getDate(durationArray[0])} - 
          ${getDate(durationArray[durationArray.length - 1])}`
        : '';
    // const attachments = formDataState?.attachments || [];
    const isPaid = formDataState?.isPaid;
    const paidColor = isPaid ? 'paid-background' : 'unpaid-background';
    const leaveIdValue = formDataState?.id !== undefined ? formDataState.id : 'NEW';
    const excludedDays = formDataState?.excludedDates
      ? formDataState.excludedDates
          .filter((date) => date)
          .map((date) => getDate(date))
          .join(', ')
      : [];

    const fields = [
      {
        label: 'Leave ID',
        value: leaveIdValue,
        className: leaveIdValue === 'NEW' ? 'leave-new-background' : 'leave-existing-background',
      },
      { label: 'Nature', value: formDataState?.leaveNature?.nature },
      { label: 'Schedule Type', value: currentLeave?.name },
      { label: 'Paid', value: formDataState?.isPaid ? 'Yes' : 'No' },
      { label: 'Total Days', value: selectedDays },
      { label: 'Leave Duration', value: leaveDuration },
      { label: 'Excluded Days', value: excludedDays },
      // { label: 'Attachments', value: renderAttachments(attachments) },
    ];

    return (
      // Leave Applications Fields
      <div className="leave-review__field">
        {fields.map((field) => (
          <div className="rounded-md leave-wrapper" key={field.label}>
            <span>{field.label}</span>
            <div
              className={`value rounded-md
              ${field.label === 'Paid' ? paidColor : ''}
              ${field.label === 'Excluded Days' && excludedDays ? 'excluded-background' : ''}
              ${field.className || ''}`}
            >
              <p className="rounded-md">{field.value}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  useEffect(() => {
    setFormDataState(formData);
  }, [formData]);

  return (
    <div className="leave-review__container">
      <Dialog size="sm" open={open} handler={onClose} className="leave-review__dialog">
        <DialogBody className="text-lg font-semibold">
          <div className="flex flex-col justify-between leave-review__container-wrapper">
            <h1 className="mb-4 leave-review__heading-title">Review Leave Application</h1>
            {/* leave Field Container */}
            <div className="leave-review__content-wrapper">{renderFormFields()}</div>
            {/* Buttons */}
            <div className="flex justify-between mt-4 px-4 leave-review__button">
              <div className="flex justify-center items-center">
                <ButtonAction
                  type="submit"
                  className="leave-review__button--submit"
                  isLoading={loading}
                  hasSpinnerText={false}
                  onClick={handleConfirm}
                  isSubmit
                />
              </div>
              <div className="flex justify-center w-fit">
                <button type="button" onClick={onClose} className="leave-review__button--cancel">
                  Close
                </button>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default LeaveReviewFormSubmit;
