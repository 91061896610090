import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import LoanType from '../../constants/loans/loan-type.interface';

export type GetAllLoanTypesQueryParams = ApiGetAllBaseQueryParams;

const get = async (id: LoanType['id'], state: StateHeaders): Promise<ApiBaseResponse<LoanType>> =>
  apiFetch<ApiBaseResponse<LoanType>>(`loans/types/${id}`, { state })
    .then((response: ApiBaseResponse<LoanType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllLoanTypesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<LoanType>> =>
  apiFetch<ApiGetAllBaseResponse<LoanType>>(
    `loans/types?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<LoanType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<LoanType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LoanType> | void> =>
  apiFetch<ApiBaseResponse<LoanType>>('loans/types', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LoanType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: LoanType,
  state: StateHeaders,
): Promise<ApiBaseResponse<LoanType> | void> =>
  apiFetch<ApiBaseResponse<LoanType>>(`loans/types/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LoanType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<LoanType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<LoanType> | void> =>
  apiFetch<ApiBaseResponse<LoanType>>(`loans/types/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<LoanType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: LoanType['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<LoanType> | void> =>
  apiFetch<ApiBaseResponse<LoanType>>(`loans/types/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<LoanType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
