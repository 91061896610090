import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { VariableSizeList } from 'react-window';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import moment from 'moment';
import EmploymentPositionRate from '../../../store/constants/employments/positions/employment-position-rate.interface';
import EmploymentPosition from '../../../store/constants/employments/positions/employment-position.interface';
import UserEmploymentPosition from '../../../store/constants/employees/positions/employee-position.interface';
import SelectEmploymentTier, { EmploymentTier } from '../../atoms/dropdowns/SelectEmploymentTier';
import useUserPositionsAssignment from '../../../hooks/users/useUserPositionsAssignment';
import SelectEmploymentPosition from '../../atoms/dropdowns/SelectEmploymentPosition';
import useVariableItemSize from '../../../hooks/utils/useVariableItemSize';
import usePositionRates from '../../../hooks/positions/usePositionRates';
import UserEmploymentPositionRow from './Row/UserEmploymentPositionRow';
import toastNotification from '../../../hooks/utils/toastNotification';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import useUser from '../../../hooks/users/useUser';
import TextWithIcons from '../TextWithIcons';
import Breadcrumbs from '../Breadcrumbs';
import useDateTime from '../../../hooks/utils/useDateTime';
import ButtonAction from '../../atoms/buttons/ButtonAction';

const UserPositionAssignment: React.FC = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const { currentUser: user, loading } = useUser(id as string);
  const { getUserPositions, post, remove, put, getAll } = useUserPositionsAssignment();
  const { getRateByPositionId, getAll: getAllRates } = usePositionRates();
  const { getItemSize } = useVariableItemSize();
  const { getDate } = useDateTime();

  const [selectedUserEmploymentPosition, setSelectedUserEmploymentPosition] = useState<
    UserEmploymentPosition | undefined
  >();
  const [formError, setFormError] = useState('');
  const [rateErrorMessage, setRateErrorMessage] = useState('');
  const [selectedPosition, setSelectedPosition] = useState<EmploymentPosition | undefined>();
  const [positionRate, setPositionRate] = useState<EmploymentPositionRate>();
  const [enteredSalary, setEnteredSalary] = useState<number | undefined>(positionRate?.minimumRate);
  const [selectedTier, setSelectedTier] = useState<{ value: string; label: string } | undefined>();
  const [selectedDateStart, setSelectedDateStart] = useState<string>('');
  const [selectedDateEnd, setSelectedDateEnd] = useState<string>('');
  const [isloading, setIsLoading] = useState(false);

  const handleReset = () => {
    setSelectedPosition(undefined);
    setSelectedTier(undefined);
    setSelectedUserEmploymentPosition(undefined);
    setSelectedDateStart('');
    setSelectedDateEnd('');
  };

  const handleSubmit = async (values: Partial<UserEmploymentPosition>) => {
    setIsLoading(true);
    setFormError('');
    handleReset();

    const userEmploymentPositionId = selectedUserEmploymentPosition?.id;

    if (userEmploymentPositionId) {
      const data: Partial<UserEmploymentPosition> = {
        ...values,
        id: userEmploymentPositionId,
        employeeNumber: user?.employment?.employeeNumber,
        employmentPositionId: selectedPosition?.id,
        tier: selectedTier?.value,
        dateStart: selectedDateStart,
        dateEnd: selectedDateEnd && selectedDateEnd ? selectedDateEnd : undefined,
      };

      await toastNotification({
        action: put(data),
        onSuccess: () => {
          setFormError('');
          getAll();
        },
        onError: (response) => {
          setFormError(response.payload.message);
        },
        onFulfilled: () => {
          setIsLoading(false);
        },
        toastPromiseMessages: {
          pending: `Updating ${user?.firstName} ${user?.lastName} position...`,
          success: `${user?.firstName} ${user?.lastName} position is successfully updated.`,
          error: `Failed to update ${user?.firstName} ${user?.lastName} position! Try again.`,
        },
      });
    } else {
      const data = {
        ...values,
        employmentPositionId: selectedPosition?.id,
        employmentPositionRatesId: positionRate?.id,
        tier: selectedTier?.value,
        employeeNumber: user?.employment?.employeeNumber,
        dateStart: selectedDateStart,
        dateEnd: selectedDateEnd && selectedDateEnd ? selectedDateEnd : undefined,
      } as UserEmploymentPosition;

      await toastNotification({
        action: post(data),
        onSuccess: () => {
          handleReset();
          setFormError('');
          getAll();
        },
        onError: (response) => {
          setFormError(response.payload.message);
        },
        onFulfilled: () => {
          setIsLoading(false);
          handleReset();
        },
        toastPromiseMessages: {
          pending: `Assigning ${user?.firstName} ${user?.lastName} position...`,
          success: `${user?.firstName} ${user?.lastName} position is successfully assigned.`,
          error: `Failed to assign ${user?.firstName} ${user?.lastName} position! Try again.`,
        },
      });
    }
  };

  const onChangePosition = (value: EmploymentPosition) => {
    setRateErrorMessage('');
    setSelectedPosition(value);
    setPositionRate(getRateByPositionId(value.id));
  };

  const onEditClick = (positions: UserEmploymentPosition) => {
    setSelectedUserEmploymentPosition(positions);

    const dateStart = moment(positions?.dateStart).isValid()
      ? moment(positions?.dateStart).format('YYYY-MM-DD')
      : '';
    setSelectedDateStart(dateStart);

    const dateEnd = moment(positions?.dateEnd).isValid()
      ? moment(positions?.dateEnd).format('YYYY-MM-DD')
      : '';
    setSelectedDateEnd(dateEnd);

    setSelectedPosition({
      ...positions?.employmentPosition,
      value: positions?.employmentPosition?.id,
      label: positions?.employmentPosition?.name,
    } as EmploymentPosition);

    const tierValue = {
      value: positions?.tier,
      label: positions?.tier,
    };
    setSelectedTier(tierValue as { value: string; label: string });
  };

  const onDeleteClick = async (positions: UserEmploymentPosition) => {
    await remove(positions.id);
  };

  const employmentPositions = getUserPositions(user?.employment?.employeeNumber).sort(
    (a: UserEmploymentPosition, b: UserEmploymentPosition) => b.id - a.id,
  );

  const countEmployeePositions = employmentPositions ? employmentPositions.length : 0;
  const listPosition = employmentPositions?.length ? employmentPositions : [];

  useOnLoad(() => {
    getAll();
    getAllRates();
  });

  return (
    <div className="w-full">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/user/manage">
          <Typography className="crumb">Users</Typography>
        </Link>
        <span className="crumb">{user?.employment?.username}</span>
        <Link to="/admin/positions">
          <span className="crumb">Positions</span>
        </Link>

        <span className="flex items-center gap-2 ">
          <Typography className="capitalize current-crumb">
            {user?.firstName}
            {user?.lastName}
          </Typography>
        </span>
      </Breadcrumbs>

      <div className="p-4 border rounded-xl shadow-2xl mr-5 user-assignment__container">
        <Typography variant="h4" className="mb-5 w-full user-assignment__container-title">
          Position Assignment
          <hr className="rounded-md h-0.8 opacity-10 mt-3" />
        </Typography>
        <div className="flex justify-between p-5 mx-auto form-section">
          {/* FIRST SECTION */}
          <div className="w-3/4 font-bold first-section mr-10 ">
            <div className="grid grid-cols-5 p-2 border-b-2 header-items">
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Position</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Tier</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Date Start</div>
              </TextWithIcons>
              <TextWithIcons variant="h6">
                <div className="flex gap-5">Date End</div>
              </TextWithIcons>
              <TextWithIcons className="justify-self-center" variant="h6">
                <div className="flex gap-5">Action</div>
              </TextWithIcons>
            </div>

            {/* need to fix loader */}
            {/* {loading && (
              <div className="w-full p-5 flex justify-center">
                <Spinner color="deep-orange" className="mr-2" />
              </div>
            )} */}

            {listPosition.length === 0 && !loading ? (
              <EmptyListMessage />
            ) : (
              <VariableSizeList
                itemCount={countEmployeePositions}
                itemSize={getItemSize}
                height={window.innerHeight - 400}
                width="100%"
                className="no-scrollbars"
              >
                {({ index, style }) => (
                  <UserEmploymentPositionRow
                    data={listPosition[index]}
                    style={style}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                    getDate={getDate}
                  />
                )}
              </VariableSizeList>
            )}
          </div>
          {/* END FIRST SECTION */}

          {/* SECOND SECTION */}
          <div className="w-1/4 first-section">
            {formError && <div className="form-error">{formError}</div>}
            <Formik
              initialValues={{
                ...selectedPosition,
                employmentPosition: selectedPosition,
                tier: selectedTier?.value,
              }}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form className="p-2 px-5 rounded-xl shadow-2xl w-full user-assignment__form">
                  <div className="mb-8">
                    <div className="w-full mb-2">
                      <label
                        htmlFor="employmentPosition"
                        className="block mt-2 uppercase user-assignment__form-title"
                      >
                        Position
                      </label>
                    </div>
                    <div className="w-full">
                      <SelectEmploymentPosition
                        id="employmentPosition"
                        name="employmentPosition"
                        isSearchable
                        isClearable
                        allowWithRates
                        value={selectedPosition}
                        onChange={(value) => onChangePosition(value as EmploymentPosition)}
                      />
                    </div>
                    <ErrorMessage name="employmentPosition" component="div" />
                  </div>

                  <div className="mb-8">
                    <div className="w-full mb-2">
                      <label
                        htmlFor="tier"
                        className="block mt-2 uppercase user-assignment__form-title"
                      >
                        Tier
                      </label>
                    </div>
                    <div className="flex-grow">
                      <SelectEmploymentTier
                        id="tier"
                        name="tier"
                        className="select-employment-tier"
                        value={selectedTier as EmploymentTier}
                        onChange={(value) => {
                          formik.setFieldValue('tier', value);
                          setSelectedTier(value as EmploymentTier);
                        }}
                        isClearable
                        hasSearchIcon
                      />
                    </div>
                    <ErrorMessage name="tier" component="div" />
                  </div>

                  <div className="mb-8 form-with-number-input">
                    <div className="w-full mb-2">
                      <label
                        htmlFor="salary"
                        className="block mt-2 uppercase user-assignment__form-title"
                      >
                        Salary
                      </label>
                    </div>
                    <div className="relative">
                      <span className="text-white absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        ₱
                      </span>
                      <div className="flex flex-col">
                        <Field
                          type="number"
                          disabled={!selectedPosition}
                          id="salary"
                          name="salary"
                          className="block py-2 pl-10 pr-4 w-full rounded-lg shadow-lg user-assignment__form-field"
                          value={
                            selectedPosition && positionRate?.minimumRate
                              ? enteredSalary ?? positionRate.minimumRate
                              : undefined
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value) setEnteredSalary(parseInt(e.target.value, 10));
                          }}
                          onBlur={() => {
                            const errorMessage = `Salary must be between ₱${positionRate?.minimumRate} and ₱${positionRate?.maximumRate}`;
                            if (positionRate?.minimumRate && positionRate?.maximumRate) {
                              if (
                                enteredSalary === undefined ||
                                enteredSalary < positionRate?.minimumRate
                              ) {
                                setEnteredSalary(positionRate?.minimumRate);
                                if (enteredSalary !== undefined) setRateErrorMessage(errorMessage);
                              } else if (enteredSalary > positionRate?.maximumRate) {
                                setEnteredSalary(positionRate?.maximumRate);
                                setRateErrorMessage(errorMessage);
                              } else {
                                setRateErrorMessage('');
                              }
                            }
                          }}
                        />
                        <span className="text-white absolute flex items-center pl-3 pointer-events-none">
                          {rateErrorMessage && (
                            <span className="text-red-500 text-xs">{rateErrorMessage}</span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mb-8">
                    <div className="w-full mb-2">
                      <label
                        htmlFor="dateStart"
                        className="block mt-2 uppercase user-assignment__form-title"
                      >
                        Date Start
                      </label>
                    </div>
                    <div className="flex-grow">
                      <Field
                        type="date"
                        id="dateStart"
                        name="dateStart"
                        placeholder="Date Start"
                        value={selectedDateStart}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSelectedDateStart(e.target.value)
                        }
                        className="w-full p-2 rounded-lg shadow-lg user-assignment__form-field"
                      />
                    </div>
                    <ErrorMessage name="dateStart" component="div" />
                  </div>

                  <div className="mb-8">
                    <div className="w-full mb-2">
                      <label
                        htmlFor="dateEnd"
                        className="block mt-2 uppercase user-assignment__form-title"
                      >
                        Date End
                      </label>
                    </div>
                    <div className="flex-grow">
                      <Field
                        type="date"
                        id="dateEnd"
                        name="dateEnd"
                        placeholder="DateEnd"
                        value={selectedDateEnd}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSelectedDateEnd(e.target.value)
                        }
                        className="w-full p-2 rounded-lg shadow-lg user-assignment__form-field"
                      />
                    </div>
                    <ErrorMessage name="dateEnd" component="div" />
                  </div>

                  <div className="flex justify-between w-full p-5">
                    <ButtonAction
                      type="submit"
                      className="flex justify-center items-center rounded-md shadow-lg submit-loader-button"
                      disabled={!selectedPosition}
                      isLoading={isloading}
                      hasSpinnerText={false}
                      isSubmit
                    />
                    <button
                      type="button"
                      onClick={handleReset}
                      className="text-[#f05825] text-sm p-2 px-4 rounded-lg hover:bg-[#2c2b2a] hover:shadow-lg"
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* END SECOND SECTION */}
        </div>
      </div>
    </div>
  );
};

export default UserPositionAssignment;
