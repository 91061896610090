import React from 'react';
import moment from 'moment';
import useRealTimeDate from '../../../hooks/utils/useRealTimeDate';

const CurrentTimeDisplay: React.FC = () => {
  const currentDateTime = useRealTimeDate();

  return (
    <div className="justify-start items-center flex-col w-full current-time-wrapper">
      <div className="w-full date-wrapper">
        <div className="flex justify-center items-center first-wrapper">
          <span className="header-date"> {moment(currentDateTime).format('MMMM D, YYYY')} </span>
        </div>
        <div className="flex justify-between items-center second-wrapper">
          <span>{moment(currentDateTime).format('dddd')}</span>
          <span>{moment(currentDateTime).format('h:mm:ss A')}</span>
        </div>
      </div>
    </div>
  );
};

export default CurrentTimeDisplay;
