import React from 'react';
import PayrollSidebar from '../sidebar/PayrollSidebarCreateView';
import PayrollToolOptions from '../PayrollToolOptions';

const PayrollCreateManagement: React.FC = () => (
  <div className="flex h-full w-full organisms-payroll-management">
    <div className="flex h-full w-full">
      <div className="payroll-sidebar-wrapper">
        <PayrollSidebar />
      </div>
      <div className="w-full payroll-content-wrapper">
        {/**
         * @todo
         * refactor: use better naming for PayrollToolOptions
         *
         */}
        <PayrollToolOptions />
      </div>
    </div>
  </div>
);

export default PayrollCreateManagement;
