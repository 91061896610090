import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Loan from '../../constants/loans/loan.interface';
import loanActions from '../../actions/loans/loanActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';

export interface LoansState {
  loans: Loan[];
}

const initialState: LoansState = {
  loans: [],
};

const getLoanData = (loan: Partial<Loan>) => ({
  ...loan,
});

const upsertItems = (loans: Loan[], ...data: Loan[]) => {
  const dataObject: { [id: number]: Loan } = {};
  loans.forEach((item) => {
    dataObject[item.id] = item;
  });

  data.forEach((loan) => {
    if (!dataObject[loan.id]) {
      dataObject[loan.id] = loan;
    } else if (dataObject[loan.id]) {
      dataObject[loan.id] = { ...dataObject[loan.id], ...loan };
    }
  });

  return Object.values(dataObject);
};

const loanSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    resetLoanState: () => ({
      ...initialState,
    }),
    setLoansState: (state, action: PayloadAction<Partial<LoansState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertLoan: (state, action: PayloadAction<Partial<Loan>>) => {
      const data: Loan = getLoanData(action.payload) as Loan;

      if (typeof data.id === 'undefined') return;

      state.loans = upsertItems(state.loans, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loanActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<Loan>;
      consoleLog('LOANS REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.loans = upsertItems(state.loans, data);
      }
    });
    builder.addCase(loanActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Loan>;
      consoleLog('LOAN DATA', data);
      if (status === 503) return;

      if (data) {
        state.loans = data;
      }
    });
    builder.addCase(loanActions.get.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<Loan>;

      if (typeof data.id === 'undefined') return;
      state.loans = upsertItems(state.loans, data);
    });
    builder.addCase(loanActions.put.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<Loan>;

      if (typeof data.id === 'undefined') return;
      state.loans = upsertItems(state.loans, data);
    });
    builder.addCase(loanActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('LOANS REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<Loan>;

      if (typeof data.id === 'undefined') return;
      state.loans = state.loans.filter((loan) => loan.id !== data.id);
    });
  },
});

export const loansReducerActions = loanSlice.actions;
export default loanSlice.reducer;
