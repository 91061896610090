import { useAppDispatch, useAppSelector } from '../../store/store';
import teamActions from '../../store/actions/teams/teamActions';
import { teamsReducerActions, TeamsState } from '../../store/reducers/teams/teamsReducer';
import Team from '../../store/constants/teams/team.interface';

const useTeams = () => {
  const dispatch = useAppDispatch();
  const teamsState: TeamsState = useAppSelector((state) => state.teams);

  const resetTeamState = () => {
    dispatch(teamsReducerActions.resetTeamsState());
  };

  const setTeamState = (state: Partial<TeamsState>) => {
    dispatch(teamsReducerActions.setTeamsState(state));
  };

  const get = async (teamId: Team['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.get(teamId));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.getAll({}));

  const post = async (teamData: Omit<Team, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.post(teamData));

  const put = async (team: Partial<Team>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.put(team));

  const remove = async (id: Team['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.remove(id));

  const getAllTeamEmployees = async (teamId: Team['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.getAllTeamEmployees(teamId));

  const updateTeamLeader = async (team: Partial<Team>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.updateTeamLeader(team));

  const removeDepartmentFromTeam = async (team: Partial<Team>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(teamActions.removeDepartmentFromTeam(team));

  return {
    resetTeamState,
    teamsState,
    setTeamState,
    get,
    getAll,
    post,
    put,
    remove,
    getAllTeamEmployees,
    updateTeamLeader,
    removeDepartmentFromTeam,
  };
};

export default useTeams;
