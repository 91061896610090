import React, { useEffect, useState } from 'react';
import consoleLog from '../../../utils/consoleLog';
import { AccordionItems } from '../AccordionContent';
import useUserForm from '../../../hooks/users/useUserForm';
import useUsers from '../../../hooks/users/useUsers';
import AtLeast from '../../../types/atleast';
import User from '../../../store/constants/users/user.interface';
import ImageWithDefault from '../../atoms/ImageWithDefault';
import toastNotification from '../../../hooks/utils/toastNotification';
import SummaryCard from '../SummaryCard';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import usePayrolls from '../../../hooks/payroll/usePayrolls';

interface UserEmploymentSummaryFormProps {
  submitButton: React.RefObject<HTMLButtonElement>;
}

const UserEmploymentSummary: React.FC<UserEmploymentSummaryFormProps> = ({ submitButton }) => {
  const { post } = useUsers();
  const { getEmployeeGross } = usePayrolls();

  const {
    userFormState: { user },
    handlePrev,
    handleNext,
    // resetUserFormState,
  } = useUserForm();

  const handleSubmit = async (data: AtLeast<User, 'bank'>) => {
    consoleLog('LOG_THIS', 'post', data);
    // console.log('USER WHOLE DATA', data);
    await toastNotification({
      action: post(data as User),
      onSuccess: () => {
        // resetUserFormState();
        // navigate('/admin/user/manage');
      },
      onError: () => {
        // setFormError(response.payload.message);
      },
      onFulfilled: () => {
        // setIsLoading(false);
      },
      toastPromiseMessages: {
        pending: 'Sending data to server...',
        success: 'User is successfully created.',
        error: 'Something went wrong! Try again.',
      },
    });
  };

  const [file, setFile] = useState<File | undefined>();

  const bankDetailsItems: AccordionItems[] = [
    { name: 'Name', value: user.bank?.bank?.name ?? '' },
    { name: 'Gateway', value: user.bank?.bank?.gateway ?? '' },
    { name: 'Fee', value: (user.bank?.bank?.fee ?? 0).toString(10) },
    { name: 'Account Number', value: user.bank?.accountNumber ?? '' },
    { name: 'Account Name', value: user.bank?.accountName ?? '' },
  ];

  const basicInformationItems: AccordionItems[] = [
    { name: 'First Name ', value: user?.firstName ?? '' },
    { name: 'Middle Name', value: user?.middleName ?? '' },
    { name: 'Last Name', value: user?.lastName ?? '' },
    { name: 'Suffix', value: user?.suffixName ?? '' },
    { name: 'Nickname', value: user?.nickName ?? '' },
    { name: 'Gender', value: user?.gender ?? '' },
    { name: 'Timezone', value: user?.timezoneCode ?? '' },
  ];

  const contactInformationItems: AccordionItems[] = [
    { name: 'Email Address', value: user?.emailPersonal ?? '' },
    { name: 'Contact Number', value: user?.contactNumber ?? '' },
    { name: 'Partners Fullname', value: user?.contact?.partnerFullName ?? '' },
    { name: 'Mothers Name', value: user?.contact?.motherName ?? '' },
    { name: 'Fathers Name', value: user?.contact?.fatherName ?? '' },
    { name: 'Emergency Contact', value: user?.contact?.emergencyContactNumber ?? '' },
    { name: 'Name', value: user?.contact?.emergencyContactName ?? '' },
    { name: 'Relation', value: user?.contact?.emergencyContactRelation ?? '' },
    { name: 'Address', value: user?.contact?.emergencyContactAddress ?? '' },
  ];

  const addressItems: AccordionItems[] = [
    { name: 'Address', value: user?.address?.address ?? '' },
    { name: 'District', value: user?.address?.district ?? '' },
    { name: 'City', value: user?.address?.city ?? '' },
    { name: 'Country', value: user?.address?.country ?? '' },
    { name: 'Zip', value: user?.address?.zip ?? '' },
  ];

  const accountNumbersItems: AccordionItems[] = [
    { name: 'SSS', value: user?.accountNumber?.sss ?? '' },
    { name: 'PHIC', value: user?.accountNumber?.phic ?? '' },
    { name: 'TIN', value: user?.accountNumber?.tin ?? '' },
    { name: 'HDMF', value: user?.accountNumber?.hdmf ?? '' },
  ];

  const salaryDetailsItems: AccordionItems[] = [
    { name: 'Employment Type', value: user?.employment?.employmentType?.name ?? '' },
    { name: 'Date Start', value: (user?.employment?.salaryDetail?.dateStart ?? '').toString() },
    { name: 'Date End', value: (user?.employment?.salaryDetail?.dateEnded ?? '').toString() },
    {
      name: 'Monthly Gross',
      value: (user?.employment?.salaryDetail?.monthlyGross ?? 0).toString(10),
    },
    { name: 'Basic Salary', value: (user?.employment?.salaryDetail?.basicGross ?? 0).toString(10) },
  ];

  const employmentInformationItems: AccordionItems[] = [
    { name: 'Employee #', value: (user?.employment?.employeeNumber ?? 0).toString(10) },
    { name: 'Acl', value: (user?.employment?.acl ?? 0).toString(10) },
    { name: 'Username', value: user?.employment?.username ?? '' },
    { name: 'Status', value: user?.employment?.employmentStatus?.name ?? '' },
    { name: 'Attrition', value: (user?.employment?.attritionType?.id ?? 0).toString(10) },
    { name: 'Attrition Reason', value: user?.employment?.attritionReason ?? '' },
    { name: 'remarks', value: user?.employment?.remarks ?? '' },
    { name: 'Previous Employer', value: user?.employment?.previousEmployer ?? '' },
    { name: 'Work Email', value: user?.employment?.emailWork ?? '' },
    {
      name: 'Referred By',
      value: (user?.employment?.referredBy?.userInformation?.fullName ?? 0).toString(10),
    },
    { name: 'Referral Rate', value: (user?.employment?.referralRate ?? 0).toString(10) },
    { name: 'Sign In Bonus', value: (user?.employment?.signInBonus ?? 0).toString(10) },
  ];

  const teamDetails: AccordionItems[] = [
    {
      name: 'Team Name',
      value: user?.employment?.teams[0]?.team?.name ?? '',
    },
    {
      name: 'Role',
      value: user?.employment?.teams[0]?.employmentRole?.name ?? '',
    },
    {
      name: 'Team Leader',
      value: user?.employment?.teams[0]?.teamLeader
        ? user.employment.teams[0].teamLeader.userInformation.fullName
        : '',
    },
    {
      name: 'Date Start',
      value: user?.employment?.teams[0]?.dateStart ?? '',
    },
    {
      name: 'Date End',
      value: user?.employment?.teams[0]?.dateEnd ?? '',
    },
    {
      name: 'Reason',
      value: user?.employment?.teams[0]?.transferReason?.name ?? '',
    },
  ];

  const teamAssignmentItems: AccordionItems[] = teamDetails as [];

  useEffect(() => {
    if (user.profilePic instanceof File) {
      setFile(user.profilePic);
    } else {
      setFile(undefined);
    }
  }, [user.profilePic]);

  useOnLoad(() => {
    if (user.employment?.employeeNumber)
      getEmployeeGross({
        scheduleTypeId: user.employment?.activeScheduleType?.id,
        monthlyGross: user?.employment?.salaryDetail?.monthlyGross ?? 0,
        basicGross: user?.employment?.salaryDetail?.basicGross ?? 0,
      });
  });

  return (
    <div className="p-5 w-full">
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-5 justify-items-center">
          <SummaryCard
            heading="Basic"
            items={basicInformationItems}
            optionalComponent={
              <ImageWithDefault
                id="profile-pic"
                className="w-20 h-20 border-2 rounded-[100%]"
                defaultImageUrl="/assets/images/profile/default.png"
                file={file}
                alt={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
              />
            }
          />
          <SummaryCard heading="Contact" items={contactInformationItems} />
          <SummaryCard heading="Address" items={addressItems} />
          <SummaryCard heading="Employment" items={employmentInformationItems} />
          <SummaryCard heading="Salary" items={salaryDetailsItems} />
          <SummaryCard heading="Bank" items={bankDetailsItems} />
          <SummaryCard heading="Account" items={accountNumbersItems} />
          <SummaryCard heading="Team" items={teamAssignmentItems} />
        </div>

        <div className="py-10 flex justify-between w-full">
          <button
            type="button"
            onClick={handlePrev}
            className="muiButton--primary rounded-lg shadow-lg hover:bg-orange-500"
          >
            Previous
          </button>
          <button
            type="button"
            className="p-2 rounded-lg shadow-lg muiButton--primary"
            onClick={() => handleSubmit(user as AtLeast<User, 'bank'>)}
          >
            {user.id ? 'Update' : 'Create'}
          </button>
        </div>
        <button ref={submitButton} onClick={handleNext} type="button" className="invisible">
          Next
        </button>
      </div>
    </div>
  );
};

export default UserEmploymentSummary;
