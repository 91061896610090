import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setOvertimeDuration } from '../../store/reducers/attendances/overtimeReducer';
import useAttendance from './useAttendance';

const useOvertime = () => {
  const dispatch = useDispatch();
  const overtimeDuration = useSelector((state: RootState) => state.overtime.duration);
  const { attendanceState } = useAttendance();

  const resetOvertimeDuration = () => {
    dispatch(setOvertimeDuration({ hours: 0, minutes: 0 }));
  };

  const updateOvertimeDuration = useCallback(
    (hours: number, minutes: number) => {
      // Dispatch the action to update overtime duration
      dispatch(setOvertimeDuration({ hours, minutes }));
    },
    [dispatch],
  );

  const checkActiveOvertime = () => {
    const currentOvertime = attendanceState.myOverTimeDetail;

    if (currentOvertime && !currentOvertime.overtimeTimeOut) {
      // detect active overtime session, then update the duration
      const [hours, minutes] = currentOvertime.duration.split(':').map(Number);
      updateOvertimeDuration(hours, minutes);
    } else {
      resetOvertimeDuration();
    }
  };

  return { overtimeDuration, updateOvertimeDuration, resetOvertimeDuration, checkActiveOvertime };
};

export default useOvertime;
