import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@material-tailwind/react';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import ClientForm from '../../molecules/ClientForm';
import Client from '../../../store/constants/clients/client.interface';
import useClients from '../../../hooks/clients/useClients';
import toastNotification from '../../../hooks/utils/toastNotification';

const ClientAddForm: React.FC = () => {
  const navigate = useNavigate();

  const { post } = useClients();

  const handleClose = () => {
    navigate('/admin/clients');
  };

  const handleSubmit = async (data: Partial<Client>) => {
    await toastNotification({
      action: post(data as Client),
      onSuccess: () => {
        handleClose();
      },
      onError: () => {},
      onFulfilled: () => {
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Client information Added.',
        error: 'Error on adding Client information.',
      },
    });
  };

  return (
    <div className="client-manage-form-container">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/clients">
          <Typography className="crumb">Clients</Typography>
        </Link>
        <span className="current-crumb">New client</span>
      </Breadcrumbs>
      <ClientForm onClose={handleClose} onSubmit={handleSubmit} />
    </div>
  );
};
export default ClientAddForm;
