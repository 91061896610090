import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Company from '../../constants/companies/company.interface';
import companyServices, {
  GetAllCompaniesQueryParams,
} from '../../services/companies/companyServices';

const get = createAsyncThunk(
  'company/get',
  async (
    id: Company['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Company> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await companyServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'company/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllCompaniesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Company> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await companyServices.getAll({ page, limit, search }, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'company/post',
  async (
    data: Omit<Company, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Company> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await companyServices.post(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'company/put',
  async (
    data: AtLeast<Company, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Company> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await companyServices.put(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'company/remove',
  async (
    id: Company['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Company> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await companyServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
