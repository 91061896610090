import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import UserEmployment from '../../../constants/employees/employee.interface';
import userEmploymentServices, {
  UpdatePasswordParams,
} from '../../../services/users/employments/userEmploymentServices';
import ApiGetAllBaseQueryParams from '../../../constants/utils/api-get-all-base-query-params.interface';

const get = createAsyncThunk(
  'userEmployment/get',
  async (
    data: AtLeast<UserEmployment, 'username' | 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.get(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'userEmployment/getAll',
  async (
    { page = 1, limit = 50, search = '' }: ApiGetAllBaseQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'userEmployment/post',
  async (
    data: AtLeast<Omit<UserEmployment, 'id'>, 'username' | 'employeeNumber'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'userEmployment/put',
  async (
    data: AtLeast<UserEmployment, 'username' | 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'userEmployment/remove',
  async (
    data: UserEmployment,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.remove(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const updatePassword = createAsyncThunk(
  'userEmployment/changePassword',
  async (
    data: UpdatePasswordParams,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.updatePassword(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const suspendAccount = createAsyncThunk(
  'userEmployment/suspendAccount',
  async (
    data: UserEmployment,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.suspendAccount(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const activateAccount = createAsyncThunk(
  'userEmployment/activateAccount',
  async (
    data: UserEmployment,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmployment> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await userEmploymentServices.activateAccount(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default {
  get,
  getAll,
  post,
  put,
  remove,
  updatePassword,
  suspendAccount,
  activateAccount,
} as const;
