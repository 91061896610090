import React, { useEffect, useState } from 'react';
import { Typography, Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import momentFormat from '../../../../utils/momentFormat';
import LeaveFormConfirmation from './LeaveFormConfirmation';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import useMyLeaves from '../../../../hooks/leave/useMyLeaves';
import CustomRangeDatePicker from '../../../atoms/datepickers/CustomRangeDatePicker';
import countDaysExcluding from '../../../../utils/countTotalDaysWithExclusions';
import Document from '../../../../assets/images/icons/document-text.svg';
import toastNotification from '../../../../hooks/utils/toastNotification';
import { ReactComponent as Close } from '../../../../assets/images/icons/close.svg';

interface LeaveFormModalParams {
  leave: LeaveApplication;
  onClose: () => void;
}

const LeaveFormModalHistory: React.FC<LeaveFormModalParams> = ({ leave, onClose }) => {
  const { myRemove, myLeavesState } = useMyLeaves();
  const navigate = useNavigate();

  const [leaveForm, setLeaveForm] = useState<LeaveApplication | undefined>(leave);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const leaveNature = myLeavesState.leaveNatures.find(
    (nature) => nature.id === leave.leaveNatureId,
  );
  const convertedExcludedDates = leave.excludedDates.map((dateFormat) => new Date(dateFormat));

  const selectedDays =
    leave.dateStart &&
    leave.dateEnd &&
    countDaysExcluding(new Date(leave.dateStart), new Date(leave.dateEnd), convertedExcludedDates);

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const getLeaveStatus = () => {
    if (leaveForm && leaveForm.dateApproved) {
      return 'Approved';
    }
    if (leaveForm && leaveForm.dateDenied) {
      return 'Denied';
    }
    return 'Pending';
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleDeleteLeave = async (leaveHistory: LeaveApplication) => {
    setLoading(true);

    toastNotification({
      action: myRemove(leaveHistory.id),
      onFulfilled: () => {
        setLoading(false);
        closeConfirmationModal();
        setOpen(false);
      },
      toastPromiseMessages: {
        pending: 'Deleting leave application...',
        success: 'Leave application deleted successfully.',
        error: 'Something went wrong! Please try again.',
      },
    });
  };

  const handleConfirm = () => {
    handleDeleteLeave(leaveForm as LeaveApplication);
  };

  const updateLeaveApplication = () => {
    navigate(`/my/leaves/applications/${leaveForm?.id}`);
  };

  useEffect(() => {
    if (leave) {
      setOpen(true);
      setLeaveForm(leave);
    }
  }, [leave]);

  return (
    <div className="leave-modal-parent">
      <Dialog
        size="sm"
        open={open}
        handler={handleOpen}
        className="shadow-none leave-history-modal"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <Close className="w-5 h-auto" />
          </IconButton>
        </div>
        <div className="flex items-center justify-between icon-wrapper">
          <div className="flex items-center justify-between gap-2">
            <img src={Document} className="leave-icon-form" alt="" />
            <DialogHeader className="mt-1 p-0 text-lg text-gray-200 ">
              Leave Application
            </DialogHeader>
          </div>
        </div>
        <DialogBody divider className="p-0">
          <div className="mt-4 form-section">
            <div className="flex gap-5">
              {/* FIrst Container */}
              <div className="flex flex-col justify-start items-center w-full">
                {/* Date Filed */}
                <div className="leave-history-wrapper">
                  <label htmlFor="dateFiled" className="block mb-1 text-sm font-semibold">
                    Date Filed
                  </label>
                  <Typography className="field-value">
                    {leave.dateCreated && momentFormat(leave.dateCreated, 'MMM DD, YYYY hh:mm A')}
                  </Typography>
                </div>
                {/* Leave Duration */}
                <div className="leave-history-wrapper">
                  <label htmlFor="leaveDuration">Leave Duration</label>
                  <CustomRangeDatePicker
                    id="duration"
                    name="duration"
                    className="leave-history-duration"
                    placeholder="Leave Duration"
                    popperPlacement="left-end"
                    popperClassName="m-0"
                    popperModifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [150, 15],
                        },
                      },
                    ]}
                    excludedDates={convertedExcludedDates}
                    dateStart={new Date(leave.duration[0])}
                    dateEnd={new Date(leave.duration[leave.duration.length - 1])}
                    readOnly={false}
                  />
                </div>
                {/* Total Days */}
                <div className="leave-history-wrapper">
                  <label htmlFor="leaveCount" className="block mb-1 text-sm font-semibold">
                    Total Days
                  </label>
                  <Typography className="field-value">
                    {leave.leaveCount && `${selectedDays} day(s)`}
                  </Typography>
                </div>
                {/* Remarks */}
                {getLeaveStatus().toLowerCase() !== 'pending' && (
                  <div className="leave-history-wrapper">
                    <label htmlFor="remarks" className="block mb-1 text-sm font-semibold">
                      Remarks
                    </label>
                    <Typography className="field-value leave-remark">
                      {leave.remarks ? leave.remarks : 'No Remarks'}
                    </Typography>
                  </div>
                )}
                {/* Attachments */}
                {/* <div className="leave-history-wrapper">
                  <label htmlFor="file" className="block mb-1 text-sm font-semibold">
                    Attachments
                  </label>
                  <div className="flex justify-start">
                    <button
                      type="button"
                      className={` text-base text-white ${
                        leave?.attachments && leave.attachments.length > 0 ? '' : 'cursor-default'
                      }`}
                    >
                      <span className="field-attachment-value">
                        {leave?.attachments && leave.attachments.length > 0
                          ? `You have ${leave.attachments.length} ${
                              leave.attachments.length === 1 ? 'Attachment' : 'Attachments'
                            }`
                          : 'No Attachments'}
                      </span>
                    </button>
                  </div>
                </div> */}
              </div>

              {/* Second Container */}
              <div className="flex flex-col justify-start items-center w-full">
                {/* Status */}
                <div className="leave-history-wrapper">
                  <label htmlFor="status" className="block mb-1 text-sm font-semibold">
                    Status
                  </label>
                  <Typography className="field-value">
                    <span className={getLeaveStatus().toLowerCase()}>
                      {leaveForm && getLeaveStatus()}
                    </span>
                  </Typography>
                </div>
                {/* Nature */}
                <div className="leave-history-wrapper">
                  <label htmlFor="leaveNature.nature" className="block mb-1 text-sm font-semibold">
                    Nature
                  </label>
                  <Typography className="field-value">{leaveNature?.nature}</Typography>
                </div>
                {/* Paid */}
                <div className="leave-history-wrapper">
                  <label htmlFor="isPaid" className="block mb-1 text-sm font-semibold">
                    Paid
                  </label>
                  <Typography className="field-value">
                    {leave.isPaid ? 'Paid' : 'Not Paid'}
                  </Typography>
                </div>
                {/* Reason */}
                <div className="leave-history-wrapper">
                  <label htmlFor="notes" className="block mb-1 text-sm font-semibold">
                    Reason
                  </label>
                  <Typography className="field-value">
                    {leave.notes ? leave.notes : 'No Reason'}
                  </Typography>
                </div>
              </div>
            </div>
            {/* END FIRST SECTION */}
          </div>
          <div className="flex items-center justify-between gap-4 w-full leaveform-content-wrapper">
            {leaveForm && getLeaveStatus() === 'Pending' ? (
              <>
                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    className="shadow-lg update-button"
                    onClick={updateLeaveApplication}
                  >
                    Update
                  </button>
                  <button type="button" onClick={() => handleClose()} className="close-button">
                    Close
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={openConfirmationModal}
                    className="cancel-application"
                  >
                    Cancel Application
                  </button>
                </div>
              </>
            ) : (
              <div>
                <button type="button" onClick={() => handleClose()} className="close-button">
                  Close
                </button>
              </div>
            )}
          </div>
        </DialogBody>
      </Dialog>
      {/* confirmation */}
      <LeaveFormConfirmation
        open={confirmationModalOpen}
        onClose={closeConfirmationModal}
        onConfirm={handleConfirm}
        loading={loading}
      />
    </div>
  );
};

export default LeaveFormModalHistory;
