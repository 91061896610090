import React, { useState } from 'react';
import { ConfigProvider, Table } from 'antd';
import { ErrorMessage, Form, Formik, Field } from 'formik';
import { GetAllPayrollQueryParams } from '../../../../../../store/services/payroll/payrollServices';
import usePayrolls from '../../../../../../hooks/payroll/usePayrolls';
import getEmployeeIncentivesTable from './ManageIncentivesColumn';
import Team from '../../../../../../store/constants/teams/team.interface';
import SelectTeam from '../../../../../atoms/dropdowns/SelectTeam';
import consoleLog from '../../../../../../utils/consoleLog';
import toastNotification from '../../../../../../hooks/utils/toastNotification';

interface IncentivesFormValues {
  payPeriod: Date;
  cutoff: string;
  incentive: string;
  amount: number;
  status: string;
}

const ManageIncentivesPage: React.FC = () => {
  const { payrollsState, addIncentives } = usePayrolls();
  const getEmployeeIncentivesTableColumns = getEmployeeIncentivesTable();

  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();

  const showTotal = (totalCount: number, range: [number, number]) => (
    <div className="ant-custom-show-total-2">{`Displaying ${range[0]}-${range[1]} of ${totalCount} items`}</div>
  );

  const handleSubmitTeam = (values: { team: Team | string }) => {
    consoleLog('Selected Team:', values);
  };
  const handleSubmit = async (values: IncentivesFormValues) => {
    await toastNotification({
      // @todo dummy data type... change this later
      action: addIncentives({ values } as Partial<GetAllPayrollQueryParams>),
      onSuccess: () => {},
      onError: () => {},
      onFulfilled: () => {},
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Incentives is successfully added.',
        error: 'Error on adding incentives.',
      },
    });
  };

  return (
    <div className="flex items-center justify-center p-8">
      <div className="w-full max-w-[85%]">
        <div className="w-full mb-5">
          <span className="text-[#f05825] font-semibold text-[1.8rem]">
            Incentive Management Page
          </span>
        </div>
        <div className="w-full p-3 pb-5 bg-[#2c2b2a] rounded-md shadow-lg">
          <div className="p-3 w-full bg-[#f05825] rounded-t-md">
            <span className="text-[#f2f2f2]">Manage Incentive</span>
          </div>
          <div className="bg-[#343434] grid grid-cols-4 gap-10 p-5 rounded-b-md">
            <div className="col-span-1 form-container">
              <Formik initialValues={{ team: '' }} onSubmit={handleSubmitTeam}>
                <Form>
                  <div className="mb-5">
                    <label htmlFor="company" className="block mb-2 text-sm text-gray-200">
                      Team
                    </label>
                    <div className="w-full">
                      <SelectTeam
                        id="team"
                        name="team"
                        className="p-0 text-xs"
                        value={selectedTeam}
                        onChange={(value) => setSelectedTeam(value as Team)}
                        required
                      />
                    </div>
                    <ErrorMessage name="team" component="div" />
                  </div>
                </Form>
              </Formik>
              <Formik
                initialValues={{
                  payPeriod: new Date(),
                  cutoff: '',
                  incentive: '',
                  amount: 0,
                  status: '',
                }}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="form-section">
                    <div className="flex flex-col justify-center w-full">
                      <div className="mb-5">
                        <label htmlFor="payPeriod" className="block mb-2 text-sm text-gray-200">
                          Date
                        </label>
                        <Field
                          type="month"
                          name="payPeriod"
                          id="payPeriod"
                          className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
                        />
                      </div>
                      <div className="mb-5">
                        <label htmlFor="cutoff" className="block mb-2 text-sm text-gray-200">
                          Cut-Off
                        </label>
                        <select
                          name="cutoff"
                          id="cutoff"
                          className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
                        >
                          <option value="1st">1st</option>
                          <option value="2nd">2nd</option>
                        </select>
                      </div>
                      <div className="mb-5">
                        <label htmlFor="incentive" className="block mb-2 text-sm text-gray-200">
                          Incentives
                        </label>
                        <select
                          name="incentive"
                          id="incentive"
                          className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
                        >
                          <option value="active">-- Select all --</option>
                        </select>
                      </div>
                      <div className="mb-5">
                        <label htmlFor="amount" className="block mb-2 text-sm text-gray-200">
                          Amount
                        </label>
                        <Field
                          type="number"
                          name="amount"
                          id="amount"
                          className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
                        />
                      </div>
                      <div className="mb-5">
                        <label htmlFor="status" className="block mb-2 text-sm text-gray-200">
                          Status
                        </label>
                        <select
                          name="status"
                          id="status"
                          className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
                        >
                          <option value="active">Pending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* Buttons */}
                  <div className="flex mt-2 space-x-2 w-max">
                    <div className="flex justify-center w-full">
                      <button
                        type="submit"
                        className="p-2 rounded-md shadow-lg bg-[#478778] hover:bg-[#2aaa8acd] text-gray-200 text-xs"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="flex justify-center w-max">
                      <button
                        type="button"
                        className="p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-xs"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
            <div className="col-span-3 ">
              <div className="border border-solid border-[#3f3f3f] rounded-[0.450rem] pb-10">
                <div className="p-1 px-3 w-full bg-[#3e3e3e] rounded-t-md">
                  <span className="text-[#f2f2f2] text-sm">Select Employees</span>
                </div>

                <div className="p-3">
                  <div className="employee-table-container">
                    <ConfigProvider
                      theme={{
                        components: {
                          Table: {
                            /* here is your component tokens */
                            rowHoverBg: '#3e3e3e !important',
                          },
                        },
                      }}
                    >
                      <Table
                        className="payroll-table-content"
                        columns={getEmployeeIncentivesTableColumns}
                        dataSource={payrollsState.employmentPayrolls.map((item) => ({
                          ...item,
                          key: item.id,
                        }))}
                        size="small"
                        tableLayout="auto"
                        pagination={{
                          position: ['topLeft'],
                          defaultPageSize: 10,
                          showTotal,
                          showSizeChanger: true,
                          pageSizeOptions: ['10', '20', '30', '50'],
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageIncentivesPage;
