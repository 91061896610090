import React from 'react';
import { ListItem, ListItemPrefix } from '@material-tailwind/react';
import { Link } from 'react-router-dom';

export interface LinkMenuProps {
  menuTitle: string;
  icon?: React.ReactNode;
  pathArray: string[];
  toPath: string;
  onClick?: () => void;
  exter?: boolean;
}

const LinkMenu: React.FC<LinkMenuProps> = ({
  menuTitle,
  pathArray,
  toPath,
  icon = undefined,
  onClick = undefined,
  exter = false,
}) => {
  const target = exter ? '_blank' : undefined;
  return (
    <Link to={toPath} target={target}>
      <ListItem
        className={`sidebar-borders flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825] ${
          pathArray.includes(toPath.split('/')[2])
            ? 'bg-[#f05825]/20 active:bg-[#f05825]/20 focus:bg-[#f05825]/20 rounded-lg transition-all duration-300'
            : 'transition-all duration-300 rounded-lg'
        }`}
        onClick={onClick}
      >
        {icon && <ListItemPrefix>{icon}</ListItemPrefix>}
        <span className="sidebar-text">{menuTitle}</span>
      </ListItem>
    </Link>
  );
};

export default LinkMenu;
