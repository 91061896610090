import React from 'react';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from '@material-tailwind/react';

export type EmployeeClockOutModalProps = {
  open: boolean;
  handleOpen: () => void;
};

const EmployeeClockOutModal: React.FC<EmployeeClockOutModalProps> = ({ open, handleOpen }) => (
  <div>
    <Dialog size="sm" open={open} handler={handleOpen} className="bg-[#2c2b2a] p-2">
      <DialogHeader>
        <Typography variant="h3" className="text-[#f05825]">
          Employee Clock-out Status Export
        </Typography>
      </DialogHeader>
      <DialogBody>
        <div className="flex flex-col gap-2">
          <label htmlFor="account" className="text-gray-200 text-base font-semibold">
            Select Date Range
          </label>
          <input
            type="date"
            className="bg-[#343434] w-full p-1 rounded-md border border-solid border-[#434343] px-2 text-gray-200"
          />
          <span className="italic text-xs text-[#f05825]">
            Note: Please limit the date range to maximum of 31 days to avoid very slow export.
          </span>
        </div>
      </DialogBody>
      <DialogFooter className="flex gap-5">
        <button
          type="button"
          className="p-2 bg-[#f05825] text-white text-sm rounded-md shadow-md px-4"
        >
          Export
        </button>
        <button
          type="button"
          onClick={handleOpen}
          className="p-2 bg-[#f02000] text-white text-sm rounded-md shadow-md px-4"
        >
          Cancel
        </button>
      </DialogFooter>
    </Dialog>
  </div>
);

export default EmployeeClockOutModal;
