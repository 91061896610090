import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import EmploymentType from '../../constants/employments/employment-type.interface';
import EmploymentTypeActions from '../../actions/employments/employmentTypeActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';

export interface EmploymentTypesState {
  employmentTypes: EmploymentType[];
}

const initialState: EmploymentTypesState = {
  employmentTypes: [],
};

const getEmploymentTypeData = (employmentRole: Partial<EmploymentType>) => ({
  ...employmentRole,
});

const employmentTypesSlice = createSlice({
  name: 'employmentTypes',
  initialState,
  reducers: {
    resetEmploymentTypesState: () => ({
      ...initialState,
    }),
    setEmploymentTypesState: (state, action: PayloadAction<Partial<EmploymentTypesState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertEmploymentTypes: (state, action: PayloadAction<Partial<EmploymentType>>) => {
      const data: EmploymentType = getEmploymentTypeData(action.payload) as EmploymentType;

      if (typeof data.id === 'undefined') return;

      state.employmentTypes = upsertData(state.employmentTypes, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(EmploymentTypeActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<EmploymentType>;
      consoleLog('EMPLOYMENT_TYPES REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.employmentTypes = upsertData(state.employmentTypes, data);
      }
    });
    builder.addCase(EmploymentTypeActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_TYPES REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<EmploymentType>;

      if (status === 503) return;

      if (data) {
        state.employmentTypes = data;
      }
    });
    builder.addCase(EmploymentTypeActions.get.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_TYPES REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<EmploymentType>;

      if (typeof data.id === 'undefined') return;
      state.employmentTypes = upsertData(state.employmentTypes, data);
    });
    builder.addCase(EmploymentTypeActions.put.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_TYPES REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<EmploymentType>;

      if (typeof data.id === 'undefined') return;
      state.employmentTypes = upsertData(state.employmentTypes, data);
    });
    builder.addCase(EmploymentTypeActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('EMPLOYMENT_TYPES REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<EmploymentType>;

      if (typeof data.id === 'undefined') return;
      state.employmentTypes = state.employmentTypes.filter(
        (employmentRole) => employmentRole.id !== data.id,
      );
    });
  },
});

export const employmentTypesReducerActions = employmentTypesSlice.actions;
export default employmentTypesSlice.reducer;
