import React from 'react';
import ExportUp from '../../../assets/images/icons/export-up.svg';
import ClientList from './ClientList';
import ManagementHeading from '../../atoms/ManagementHeading';

const ClientManagement: React.FC = () => (
  <div className="organisms-client-management">
    <div className="organisms-client-management__content">
      <div className="m-2 organisms-client-management-content__heading">
        <ManagementHeading title="Client" />
        <button type="button" className="client-management-content-heading__export-button gap-3">
          <img src={ExportUp} className="client-icons" alt="Export" />
          <span>Export attendance for all clients</span>
        </button>
      </div>
      <div className="organisms-client-management-content__table-list">
        <ClientList />
      </div>
    </div>
  </div>
);

export default ClientManagement;
