import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import userTeamsActions from '../../actions/users/employments/userEmploymentTeamsActions';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import UserEmploymentTeam from '../../constants/employees/teams/employee-team.interface';

export interface UserTeamsState {
  userTeams: UserEmploymentTeam[];
  currentTeamEmployeeNumber?: number;
}

const initialState: UserTeamsState = {
  userTeams: [],
};

const getUserTeamsData = (userTeams: Partial<UserEmploymentTeam>) => ({
  ...userTeams,
});

const userTeamsSlice = createSlice({
  name: 'userTeams',
  initialState,
  reducers: {
    resetUserTeamsState: () => ({
      ...initialState,
    }),
    setUserTeamsState: (state, action: PayloadAction<Partial<UserTeamsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertUserTeams: (state, action: PayloadAction<Partial<UserEmploymentTeam>>) => {
      const data: UserEmploymentTeam = getUserTeamsData(action.payload) as UserEmploymentTeam;

      if (typeof data.id === 'undefined') return;

      state.userTeams = upsertData(state.userTeams, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userTeamsActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<UserEmploymentTeam>;
      consoleLog('USER Teams REDUCER', 'POST', data);

      // if (typeof data.id === 'undefined') return;

      if (data) {
        state.userTeams = upsertData(state.userTeams, data);
      }
    });
    builder.addCase(userTeamsActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('USER Teams REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<UserEmploymentTeam>;

      if (status === 503) return;

      if (data) {
        state.userTeams = data;
      }
    });
    builder.addCase(userTeamsActions.get.fulfilled, (state, { payload }) => {
      consoleLog('USER Teams REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentTeam>;

      if (typeof data.id === 'undefined') return;
      state.userTeams = upsertData(state.userTeams, data);
    });
    builder.addCase(userTeamsActions.put.fulfilled, (state, { payload }) => {
      consoleLog('USER Teams REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentTeam>;

      if (typeof data.id === 'undefined') return;
      state.userTeams = upsertData(state.userTeams, data);
    });
    builder.addCase(userTeamsActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('USER Teams REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<UserEmploymentTeam>;

      if (typeof data.id === 'undefined') return;
      state.userTeams = state.userTeams.filter((userTeam) => userTeam.id !== data.id);
    });
    builder.addCase(userTeamsActions.getUser.fulfilled, (state, { payload }) => {
      consoleLog('GET USER TEAMS REDUCER', 'GET', payload);

      const { data } = payload as ApiGetAllBaseResponse<UserEmploymentTeam>;

      if (data) {
        state.userTeams = data;
      }
    });
  },
});

export const userTeamsReducerActions = userTeamsSlice.actions;
export default userTeamsSlice.reducer;
