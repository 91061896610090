import React from 'react';
import UserCalendar from '../UserCalendar';
import { ReactComponent as Airplane } from '../../../../assets/images/icons/airplane.svg';
import UserScheduleNotificationModals from '../attendanceAction/UserScheduleNotificationModals';
import UserPostStatus from './UserPostStatus';

interface Comment {
  id: number;
  text: string;
}

interface Post {
  id: number;
  image: string;
  userName: string;
  createdAt: string;
  postContent: string;
  comment: Comment[];
}

const posts: Post[] = [
  {
    id: 1,
    image:
      'https://images.pexels.com/photos/1024248/pexels-photo-1024248.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    userName: 'Jichael Mackson',
    createdAt: '2 days ago',
    postContent:
      'congue vivamus per aptent metus sociis et blandit cursus magna pretium. ako si rowel michael joshua at nakasalalay saaking ang buong world ng One Piece, nagsimula aking lakbay nung namatay ang aking alamigas na si Daniel, lumusob ako sa planetang Namek para buuhin ang tinatawag nilang Soul Society at magin top Hunter ng Konoha.',
    comment: [
      {
        id: 1,
        text: 'Rowell Lorem ipsum dolor sit amet consectetur adipiscing losemer amet consectetur .',
      },
      { id: 2, text: 'Lorem ipsum dolorr.' },
      { id: 3, text: 'Sit amet consectetur adipiscing losemer.' },
    ],
  },
  {
    id: 2,
    image:
      'https://images.pexels.com/photos/15505179/pexels-photo-15505179/free-photo-of-two-women-sitting-at-large-table-with-laptops.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    userName: 'Bustin Jieber',
    createdAt: '1 week ago',
    postContent:
      'congue vivamus per aptent metus sociis et HInahanap ko ang batang ama na si ako at siya cursus magna pretium. Nam nisl dapibus sollicitudin, nascetur varius quam montes a auctor congue ante potenti, hac inceptos cum sed tristique volutpat posuere pulvinar nunc.',
    comment: [
      { id: 3, text: 'Lorem ipsum' },
      {
        id: 4,
        text: 'Curabitur sagittis potenti maecenas ut mi sociosqu parturient,faucibus diam condimentum suspendisse ad ullamcorper cras et, morbi in aliquam accumsan tempor phasellus.',
      },
    ],
  },
  {
    id: 3,
    image:
      'https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    userName: 'Leneral Huna',
    createdAt: '2 weeks ago',
    postContent:
      'a dis ornare nec est in ante. Senectus et eu rhoncus augue turpis erat eleifend ac fringilla sociosqu scelerisque pulvinar sodales justo ridiculus commodo sed, conubia nisi hendrerit vel natoque fermentum tristique morbi montes enim risus sociis',
    comment: [
      { id: 5, text: 'Lorem ipsum sagittis potenti' },
      { id: 6, text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      {
        id: 7,
        text: 'Wake up to reality. Nothing ever goes as planned in this world. The longer you live, the more you realize that only pain, suffering, and futility exist in this reality.',
      },
    ],
  },
];

const UserNewsFeed: React.FC = () => (
  <div className="user-newsfeed-test">
    <UserScheduleNotificationModals />
    <div className="calendar-wrapper mt-[1.5rem]">
      <UserCalendar />
    </div>
    <div className="newsfeed-wrapper">
      <div className="w-full mt-5 mb-5 title-wrapper">
        <UserPostStatus />
      </div>
      <div className="flex flex-col w-full gap-10 pb-8 h-fit">
        {posts.map((post) => (
          <div
            key={post.id}
            className="w-full shadow-lg rounded-xl bg-[#242426] posts-parent-container-oldstyle"
          >
            <div className="flex p-2 pb-0">
              <div className="flex items-center gap-3 p-2 comment-wrapper">
                <span className="w-8 h-8 rounded-full round-img" />
                <div className="flex flex-col">
                  <span className="user-name font-semibold">{post.userName}</span>
                  <span className="text-[0.65rem] text-gray-200">{post.createdAt}</span>
                </div>
              </div>

              <div className="ml-auto">
                <span className="text-gray-200 text-lg">...</span>
              </div>
            </div>
            <div className="p-4 pt-0">
              <span className="text-gray-200 text-sm">{post.postContent}</span>
            </div>
            <div className="">
              <img src={post.image} alt={`${post.userName}-${post.id}`} />
            </div>
            <div className="p-2">
              <div className="text-white text-lg">❤️ 12.9k</div>
              <div className="">
                <div className="flex flex-col gap-4 overflow-auto custom-scrollbar">
                  {post.comment.map((comment) => (
                    <div key={comment.id} className="flex gap-2 comment-users-wrapper w-[65%]">
                      <span className="w-7 h-7 mt-2 bg-[#aeafb5] rounded-full flex-shrink-0" />
                      <span className="p-2 text-xs rounded-lg comment flex flex-col gap-1">
                        <span className="user-name text-xs font-semibold">Lorem D. Epsum</span>
                        {comment.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex items-center p-1 post-action-wrapper mt-3">
                <span className="w-8 h-8 bg-[#aeafb5] rounded-full flex-shrink-0" />
                <input
                  className="w-full placeholder:text-[12px] placeholder:pl-[5px] outline-none h-10 text-sm pl-2 px-1 rounded-l-lg ml-2 bg-[#3c3b3b] text-[#E0E2EB] shadow-sm focus:outline-none focus:border-[#909194] focus:ring-[#909194] focus:ring-1"
                  type="text"
                  placeholder="Write a comment..."
                />
                <button
                  className="w-[40px] h-[40px] bg-[#353535] hover:opacity-80 transition-all duration-300 flex justify-center items-center rounded-r-lg flex-shrink-0"
                  type="button"
                >
                  <Airplane className="w-6 h-6 text-gray-400 " />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default UserNewsFeed;
