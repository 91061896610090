import moment from 'moment';

const timerToTimeString = (
  hours: number | undefined,
  minutes: number | undefined,
  seconds: number | undefined,
): string => {
  const format = (time: number | undefined) =>
    time !== undefined ? time.toString().padStart(2, '0') : '00';

  return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
};

const timeStringToTimer = (
  timeString: string,
): { hours: number; minutes: number; seconds: number } => {
  const [hoursStr, minutesStr, secondsStr] = timeString.split(':').map((str) => parseInt(str, 10));
  return {
    hours: hoursStr || 0,
    minutes: minutesStr || 0,
    seconds: secondsStr || 0,
  };
};

const formatTimeTo12HourString = (timeString: string | undefined): string => {
  if (!timeString) return '';
  return moment(timeString, 'HH:mm:ss').format('hh:mm A');
};

const formatTimeTo24HourString = (timeString: string | undefined): string => {
  if (!timeString) return '';
  return moment(timeString, 'hh:mm A').format('HH:mm:ss');
};

export { timerToTimeString, timeStringToTimer, formatTimeTo12HourString, formatTimeTo24HourString };
