import apiFetch, { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import Employee from '../../../constants/employees/employee.interface';

export interface UpdatePasswordParams {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

// @TODO: Needs to be setup
const get = async (
  data: AtLeast<Employee, 'username' | 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Employee>> =>
  apiFetch<ApiBaseResponse<Employee>>('employees', {
    state,
    options: {
      method: 'GET',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: ApiGetAllBaseQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<Employee>> =>
  apiFetch<ApiGetAllBaseResponse<Employee>>(
    `employees?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

// @TODO: Needs to be setup
const post = async (
  data: AtLeast<Omit<Employee, 'id'>, 'username' | 'employeeNumber'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Employee> | void> =>
  apiFetch<ApiBaseResponse<Employee>>('employees', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

// @TODO: Needs to be setup
const patch = async (
  data: Employee,
  state: StateHeaders,
): Promise<ApiBaseResponse<Employee> | void> =>
  apiFetch<ApiBaseResponse<Employee>>(`employees/${data.username}/employments/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

// @TODO: Needs to be setup
const put = async (
  data: AtLeast<Employee, 'username' | 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<Employee> | void> =>
  apiFetch<ApiBaseResponse<Employee>>(`employees/${data.username}/employments/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  data: Employee,
  state: StateHeaders,
): Promise<ApiBaseResponse<Employee> | void> =>
  apiFetch<ApiBaseResponse<Employee>>(`employees/${data.username}/employments/${data.id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const checkPassword = async (
  password: string,
  state: StateHeaders,
): Promise<ApiBaseResponse<boolean> | void> =>
  apiFetch<ApiBaseResponse<boolean>>('my/accounts/password', {
    state,
    options: {
      body: {
        password,
      },
      method: 'POST',
    },
  })
    .then((response: ApiBaseResponse<boolean>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const updatePassword = async (
  data: UpdatePasswordParams,
  state: StateHeaders,
): Promise<ApiBaseResponse<boolean> | void> =>
  apiFetch<ApiBaseResponse<boolean>>('my/accounts/password', {
    state,
    options: {
      body: data,
      method: 'PUT',
    },
  })
    .then((response: ApiBaseResponse<boolean>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const suspendAccount = async (
  data: Employee,
  state: StateHeaders,
): Promise<ApiBaseResponse<Employee> | void> =>
  apiFetch<ApiBaseResponse<Employee>>(`employees/suspendAccount/${data}`, {
    state,
    options: {
      method: 'PUT',
    },
  })
    .then((response: ApiBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const activateAccount = async (
  data: Employee,
  state: StateHeaders,
): Promise<ApiBaseResponse<Employee> | void> =>
  apiFetch<ApiBaseResponse<Employee>>(`employees/activateAccount/${data}`, {
    state,
    options: {
      method: 'PUT',
    },
  })
    .then((response: ApiBaseResponse<Employee>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
  checkPassword,
  updatePassword,
  suspendAccount,
  activateAccount,
} as const;
