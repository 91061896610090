import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import LoanType from '../../../store/constants/loans/loan-type.interface';
import useLoanTypes from '../../../hooks/loans/useLoanTypes';
import toastNotification from '../../../hooks/utils/toastNotification';

export interface LoanTypeFormProps {
  loanType?: LoanType;
  className?: string;
  onClose: () => void;
}

const LoanTypeFormModal: React.FC<LoanTypeFormProps> = ({
  loanType = undefined,
  className = undefined,
  onClose,
}) => {
  const [loanTypeForm, setLoanTypeForm] = useState<LoanType | undefined>();
  const [open, setOpen] = React.useState(false);

  const { post, put } = useLoanTypes();

  const handleClose = () => {
    setLoanTypeForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setLoanTypeForm(undefined);
    setOpen(true);
  };

  useEffect(() => {
    if (loanType) {
      setOpen(true);
      setLoanTypeForm(loanType);
    }
  }, [loanType]);

  const handleSubmit = async (values: Partial<LoanType>) => {
    if (loanTypeForm) {
      await toastNotification({
        action: put(values),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Loan Type is successfully updated.',
          error: 'Error on updating Loan Type.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as LoanType),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Loan Type is successfully added.',
          error: 'Error on adding Loan Type.',
        },
      });
    }

    setOpen(false);
  };

  // @TODO
  // 1. web console error changing uncontrolled input to controlled. undefined to defined value

  return (
    <>
      <div className="">
        <button type="button" onClick={handleOpen} className={className}>
          Add Loan Type
        </button>
      </div>

      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="w-5 h-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-3">
          <DialogHeader className="p-0 mt-1 text-gray-200 modal-header">
            Enter Loan Type
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik initialValues={{ ...loanTypeForm }} onSubmit={handleSubmit}>
                <Form>
                  <div className="mt-4 mb-5 form-section">
                    <div className="flex flex-col justify-center w-full">
                      <div className="mb-6">
                        <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                          Loan Type
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            placeholder="SSS, Cash, PAG-IBIG..."
                            required
                          />
                        </div>
                        <ErrorMessage name="name" component="div" />
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-2 w-max">
                    <div className="flex justify-center w-full">
                      <button
                        type="submit"
                        className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                      >
                        Submit
                      </button>
                    </div>
                    <div className="flex justify-center w-max">
                      <button
                        type="button"
                        onClick={handleClose}
                        className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default LoanTypeFormModal;
