import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useUserPositions from '../../../../hooks/positions/useUserPositions';
import SelectClient from '../../../atoms/dropdowns/SelectClient';
import SelectTeam from '../../../atoms/dropdowns/SelectTeam';
import Client from '../../../../store/constants/clients/client.interface';
import Team from '../../../../store/constants/teams/team.interface';
import EmployeePosition from '../../../../store/constants/employees/positions/employee-position.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import { ReactComponent as Add } from '../../../../assets/images/icons/plus.svg';

export interface ClientTeamAddModalParams {
  position?: EmployeePosition;
  clientTeam?: Client;
  teamClient?: Team;
  className?: string;
  resetSort?: () => void;
  onClose: () => void;
}

const ClientPageAddFormModal: React.FC<ClientTeamAddModalParams> = ({
  position = undefined,
  clientTeam = undefined,
  teamClient = undefined,
  className = undefined,
  resetSort = () => {},
  onClose,
}) => {
  const [employmentPositionForm, setEmploymentPositionForm] = useState<
    EmployeePosition | undefined
  >();

  const [open, setOpen] = React.useState(false);

  const { post, put } = useUserPositions();

  const handleClose = () => {
    setEmploymentPositionForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setEmploymentPositionForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<EmployeePosition>) => {
    if (employmentPositionForm) {
      const data: EmployeePosition = {
        ...employmentPositionForm,
        ...values,
      };
      await toastNotification({
        action: put(data),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Client Team information is successfully updated.',
          error: 'Error on updating Client Team information.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as EmployeePosition),
        onSuccess: () => {
          resetSort();
        },
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Client Team information is successfully added.',
          error: 'Error on adding Client Team information.',
        },
      });
    }

    setOpen(false);
  };

  useEffect(() => {
    if (position) {
      setOpen(true);
      setEmploymentPositionForm(position);
    }
  }, [position]);

  return (
    <>
      <Button className={` ${className} muiButton--primary add-button`} onClick={handleOpen}>
        <Add className="w-5 h-5" />
        Add Client Team Position
      </Button>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="w-5 h-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="white"
            className="modal-icons"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
            />
          </svg>

          <DialogHeader className="p-0 mt-1 text-gray-200 modal-header">
            Enter Client Team Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{
                  ...employmentPositionForm,
                  client: clientTeam
                    ? ({
                        ...clientTeam,
                        label: clientTeam?.name,
                        value: clientTeam,
                      } as Client)
                    : clientTeam,
                  team: teamClient
                    ? ({
                        ...teamClient,
                        label: teamClient?.name,
                        value: teamClient,
                      } as Team)
                    : teamClient,
                }}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form className="form-positions">
                    <div className="mt-4 mb-4 form-section">
                      <div className="flex flex-col justify-center w-full">
                        <div className="mb-4">
                          <label htmlFor="client" className="block mb-2 text-gray-200 modal-labels">
                            Client
                          </label>
                          <SelectClient
                            id="client"
                            name="client"
                            isClearable
                            isSearchable
                            value={formik.values.client}
                            setFieldValue={formik.setFieldValue}
                          />
                          <ErrorMessage name="client" component="div" />
                        </div>

                        <div className="mb-4">
                          <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                            Team
                          </label>
                          <div className="">
                            <SelectTeam
                              id="team"
                              name="team"
                              isClearable
                              isSearchable
                              value={formik.values.team}
                              setFieldValue={formik.setFieldValue}
                            />
                            <ErrorMessage name="team" component="div" />
                          </div>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="name" className="block mb-2 text-gray-200 modal-labels">
                            DateStart
                          </label>
                          <div className="">
                            <Field
                              type="date"
                              id="name"
                              name="name"
                              placeholder="Enter"
                              className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            />
                            <ErrorMessage name="name" component="div" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Buttons */}
                    <div className="flex space-x-2 w-max">
                      <div className="flex justify-center w-full">
                        <button
                          type="submit"
                          className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="flex justify-center w-max">
                        <button
                          type="button"
                          onClick={handleClose}
                          className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default ClientPageAddFormModal;
