import React, { FC, useState } from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import useBanks from '../../../hooks/banks/useBanks';
import Bank from '../../../store/constants/banks/bank.interface';

export interface BankInputSearchProps extends Partial<AutoSearchSelectProps<Bank>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectBank: FC<BankInputSearchProps> = ({
  id,
  name,
  placeholder = 'Select Bank',
  className = '',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { banksState, getAll } = useBanks();

  useOnLoad(() => {
    if (banksState.banks.length === 0) {
      setIsLoading(true);
      getAll().then(() => {
        setIsLoading(false);
      });
    }
  });

  const banks = isLoading
    ? [{ value: undefined, label: 'Loading...' }]
    : banksState.banks
        .slice()
        .sort((a, b) => (a.gateway > b.gateway ? 1 : -1))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((bank) => ({
          ...bank,
          value: bank.id,
          label: bank.name,
        }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-bank modal-select ${className}`}
      placeholder={placeholder}
      data={banks}
    />
  );
};

export default SelectBank;
