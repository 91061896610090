import React from 'react';
import { Breadcrumbs as MUITBreadcrumbs } from '@material-tailwind/react';

interface BreadcrumbsProps {
  className?: string;
  children: React.ReactNode;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ className = '', children }) => (
  <MUITBreadcrumbs
    className={`${className} bg-transparent`}
    separator={
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    }
    aria-label="breadcrumbs"
  >
    {children}
  </MUITBreadcrumbs>
);

export default Breadcrumbs;
