import React, { useState } from 'react';
import { Dialog, DialogHeader, DialogBody, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';
import { ReactComponent as Building } from '../../../../../assets/images/icons/building.svg';
import Company from '../../../../../store/constants/companies/company.interface';
import SelectCompany from '../../../../atoms/dropdowns/SelectCompany';
import usePayrolls from '../../../../../hooks/payroll/usePayrolls';

interface GenerateCompanyITRProps {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
}

const GenerateCompanyITR: React.FC<GenerateCompanyITRProps> = ({ isOpen, setOpen }) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<string>('');
  const { getCompanyITR } = usePayrolls();

  const generateCompanyITR = () => {
    if (selectedCompany) {
      getCompanyITR(selectedCompany.id);
    }
    // setOpen(false);
  };

  return (
    <Dialog
      size="xs"
      open={isOpen}
      handler={setOpen}
      className="bg-[#343434] shadow-none p-10 modal-size"
    >
      <div className="m-2 p-0 absolute flex top-0 right-0">
        <IconButton color="blue-gray" size="sm" variant="text" onClick={() => setOpen(false)}>
          <Close className="w-5 h-auto" />
        </IconButton>
      </div>
      <div className="flex gap-3">
        <Building className="modal-icons" stroke="white" />
        <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
          Generate company ITR
        </DialogHeader>
      </div>
      <DialogBody divider className="p-0">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            {/* FIRST SECTION */}
            <div className="mt-4 mb-4 form-section">
              <div className="flex flex-col justify-center w-full">
                <div className="mb-6">
                  <label htmlFor="company" className="modal-labels block mb-2 text-gray-200">
                    Company
                  </label>
                  <div className="">
                    <SelectCompany
                      id="department"
                      name="department"
                      isClearable
                      isSearchable
                      value={selectedCompany}
                      onChange={(value) => setSelectedCompany(value as Company)}
                    />
                  </div>
                  <ErrorMessage name="company" component="div" />
                </div>

                <select
                  name="year"
                  id="year"
                  className="w-full p-1 py-2 text-white rounded-md bg-[#2c2b2a] text-[0.65rem] search-bar_payroll"
                  value={selectedYear}
                  onChange={(value) => setSelectedYear(value.target.value)}
                >
                  <option value="2018">2018</option>
                </select>
              </div>
            </div>
            {/* Buttons */}
            <div className="flex space-x-2 w-max">
              <div className="flex justify-center w-full">
                <button
                  onClick={() => generateCompanyITR()}
                  type="button"
                  className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                >
                  Download
                </button>
              </div>
              <div className="flex justify-center w-max">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                >
                  Close
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </DialogBody>
    </Dialog>
  );
};
export default GenerateCompanyITR;
