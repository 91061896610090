import React, { useState } from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from '@material-tailwind/react';
import useRealTimeDate from '../../../../../hooks/utils/useRealTimeDate';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import useGreetingTime from '../../../../../hooks/utils/useGreetingTime';

const UserTimeInModal: React.FC = () => {
  const { setAttendanceState, attendanceState } = useAttendance();
  const currentDateTime = useRealTimeDate();
  const greetingTime = useGreetingTime();

  const [open, setOpen] = useState(false);
  const [quote, setQuote] = useState<JSX.Element | undefined>(undefined);

  const handleClose = () => {
    setAttendanceState({
      modals: {
        ...attendanceState.modals,
        showTimeInModal: false,
      },
    });
    setOpen(false);
  };

  const { minsAdvance, minsLate } = attendanceState.myAttendance?.employeeAttendanceDetails || {};

  const timeToDisplay = minsLate || minsAdvance;
  const duration = moment.duration(timeToDisplay);
  const totalSeconds = duration.asSeconds();

  let formattedTime = '';
  if (totalSeconds >= 60) {
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    formattedTime = `${hours > 0 ? `${hours} hour(s)` : ''} ${
      minutes > 0 ? `${minutes} minute(s)` : ''
    }`;
  }

  const quotes = [
    <p>
      Nothing is <b>Impossible</b>.<br />
      The word itself says <b>I&#x27;m Possible</b>
      <br />
      <span className="flex justify-end pr-3 mt-4 text-right italic">- Audrey Hepburn</span>
    </p>,
    <p>
      Our greatest weakness lies in giving up. The most certain way to success is always to try just
      one more time.
      <br />
      <span className="flex justify-end pr-3 mt-4 text-right italic">- Thomas Edison</span>
    </p>,
    <p>
      Embrace the challenges, conquer the unknown, and let the indomitable spirit of Joshua Andre be
      the guiding light on your heroic journey, turning every obstacle into a triumph.
      <br />
      <span className="flex justify-end pr-3 mt-4 text-right italic">- OSV2</span>
    </p>,
    <p>
      John Kael, a man who&apos;d brave death for love&apos;s sake—a testament to the power of
      devotion that knows no bounds.
      <br />
      <span className="flex justify-end pr-3 mt-4 text-right italic">- OSV2</span>
    </p>,
    <p>Everyday is a chance to get better. Don&#x27;t waste it.</p>,
    <p>
      Never put off for tomorrow what you can do today.
      <span className="flex justify-end pr-3 mt-4 text-right italic">- Thomas Jefferson</span>
    </p>,
    <p>
      Yesterday is <b>History</b>. Tomorrow is a <b>Mystery</b>. Today is a <b>Gift</b> that&#x27;s
      why we call it{' '}
      <b>
        <u>Present</u>
      </b>
      <span className="flex justify-end pr-3 mt-4 text-right italic">- Eleanor Roosevelt</span>
    </p>,
    <p>Each day, do something to make you feel happy until it becomes a habit.</p>,
  ];

  useOnLoad(() => {
    setOpen(true);
    if (!quote) setQuote(quotes.sort(() => Math.random() - 0.5).pop());
  });

  return (
    <Dialog
      size="xs"
      open={open}
      handler={handleClose}
      className="attendance-time-in-modal__container rounded-xl"
    >
      {/* header */}
      <DialogHeader className="flex justify-center">
        <div className="flex flex-col items-center gap-2 attendance-time-in-modal__header">
          <div className="flex items-center gap-2">
            <h2>Time in Successfully !</h2>
            <span>{moment(currentDateTime).format('MMMM YYYY hh:mm A')}</span>
          </div>
        </div>
      </DialogHeader>

      {/* body */}
      <DialogBody className="attendance-time-in-modal__body">
        <div className="justify-center">
          {formattedTime && (
            <div className="flex items-center gap-2 pb-4">
              <div className="">
                <h2>{minsAdvance ? 'Advanced Time in:' : 'You are currently late: '}</h2>
              </div>
              <p
                className={
                  minsAdvance
                    ? 'attendance-time-in-modal__body--advance'
                    : 'attendance-time-in-modal__body--late'
                }
              >
                {formattedTime}
              </p>
            </div>
          )}
          <Typography className="muiTypography--headline5 mb-5">
            {`Hello👋,  Awesome ${greetingTime}!`}
          </Typography>
          <div className="attendance-timein-wrapper mt-5">{quote}</div>
        </div>
      </DialogBody>

      {/* footer */}
      <DialogFooter className="attendance-time-in-modal__footer">
        <Button variant="text" onClick={handleClose} className="attendance-time-in__button">
          <span>Close</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default UserTimeInModal;
