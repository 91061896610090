import React, { useState } from 'react';
import { ErrorMessage, FormikHelpers } from 'formik';
import FileDisplayWithModal from './FileDisplayWithModal';
import LeaveFileAttachment from '../../../../store/constants/leaves/leave-file-attachment.interface';
import { ReactComponent as UploadIcon } from '../../../../assets/images/icons/upload-file.svg';

interface FileUploadProps<T> {
  id: string;
  name: string;
  label: string;
  accept: string;
  setFieldValue: FormikHelpers<T>['setFieldValue'];
  setFieldTouched: FormikHelpers<T>['setFieldTouched'];
}

const UploadFileAttachments: React.FC<FileUploadProps<LeaveFileAttachment>> = ({
  id,
  name,
  label,
  accept,
  setFieldTouched,
  setFieldValue,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const allowedFileTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  const maxSizeMB = 2;
  const maxSizeBytes = maxSizeMB * 1024 * 1024;

  const handleFileChange = (newFiles: FileList) => {
    const validFiles: File[] = [];
    const invalidFiles: string[] = [];

    Array.from(newFiles).forEach((file) => {
      if (!allowedFileTypes.includes(file.type)) {
        invalidFiles.push(`${file.name}: Invalid file type. Please upload PNG, JPG, PDF, or DOCX.`);
      } else if (file.size > maxSizeBytes) {
        invalidFiles.push(`${file.name}: File size exceeds the 2MB limit.`);
      } else {
        validFiles.push(file);
      }
    });

    setErrorMessages(invalidFiles);
    setFiles((prev) => [...prev, ...validFiles]);
    setFieldValue(name, [...files, ...validFiles]);
    setFieldTouched(name, true);

    (document.getElementById(id) as HTMLInputElement).value = '';
  };

  const handleDeleteFile = (fileName: string) => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    setFieldValue(name, updatedFiles);
  };

  return (
    <div className="w-full leave-attachment__parent-container invisible">
      <label
        htmlFor={id}
        className="w-full leave-label-text cursor-pointer leave-application__label-title"
      >
        Attachments
      </label>

      <div className="flex justify-between rounded-lg p-2 mb-3 leave-attachment__wrapper">
        <label htmlFor={id} className="w-full h-[1.6rem] flex items-center cursor-pointer">
          <p className="w-full h-full flex items-center">{label}</p>
        </label>
        <div className="rounded-lg icon-wrapper">
          <label htmlFor={id}>
            <UploadIcon className="w-5" />
          </label>
        </div>
        <input
          type="file"
          id={id}
          name={name}
          accept={accept}
          multiple
          className="hidden"
          onChange={(e) => e.target.files && handleFileChange(e.target.files)}
        />
      </div>

      <ErrorMessage name={name} component="div" className="leave-error" />
      {errorMessages.length > 0 && (
        <div className="leave-error">
          <ul>
            {errorMessages.map((data) => (
              <li key={data}>{data}</li>
            ))}
          </ul>
        </div>
      )}

      {/* File Display with Modal */}
      <FileDisplayWithModal files={files} onDeleteFile={handleDeleteFile} />
    </div>
  );
};

export default UploadFileAttachments;
