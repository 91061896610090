import { useAppDispatch, useAppSelector } from '../../store/store';
import holidayTypeActions from '../../store/actions/holidays/holidayTypeActions';
import {
  HolidayTypesState,
  holidayTypesReducerActions,
} from '../../store/reducers/holidays/holidayTypesReducer';
import HolidayType from '../../store/constants/holidays/holiday-type.interface';

const useHolidayTypes = () => {
  const dispatch = useAppDispatch();
  const holidayTypesState: HolidayTypesState = useAppSelector((state) => state.holidayTypes);

  const resetHolidayTypeState = () => {
    dispatch(holidayTypesReducerActions.resetHolidayTypesState());
  };

  const setHolidayTypeState = (state: Partial<HolidayTypesState>) => {
    dispatch(holidayTypesReducerActions.setHolidayTypesState(state));
  };

  const get = async (id: HolidayType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayTypeActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayTypeActions.getAll({}));

  const post = async (data: Omit<HolidayType, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayTypeActions.post(data));

  const put = async (data: Partial<HolidayType>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayTypeActions.put(data));

  const remove = async (id: HolidayType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(holidayTypeActions.remove(id));

  return {
    resetHolidayTypeState,
    holidayTypesState,
    setHolidayTypeState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useHolidayTypes;
