import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AuthServices, {
  AuthLoginParams,
  AuthLoginResponse,
  AuthRefreshAccessTokenResponse,
} from '../../services/auths/authServices';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';

const login = createAsyncThunk(
  'auth/login',
  async (
    loginProps: AuthLoginParams,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<AuthLoginResponse> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await AuthServices.login(loginProps, state);
    } catch (error) {
      if (error instanceof Error) return rejectWithValue(error);
    }

    return null;
  },
);

const refreshToken = createAsyncThunk(
  'auth/refresh',
  async (
    _,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<AuthRefreshAccessTokenResponse> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await AuthServices.refreshToken(state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default { login, refreshToken } as const;
