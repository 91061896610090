import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
  openedItems: number[];
}

const initialState: MenuState = {
  openedItems: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleItem: (state, action: PayloadAction<number>) => {
      const index = state.openedItems.indexOf(action.payload);
      if (index !== -1) {
        // If the item is already in openedItems, remove it.
        state.openedItems.splice(index, 1);
      } else {
        // If the item is not in openedItems, add it.
        state.openedItems.push(action.payload);
      }
    },
    resetMenuState: (state) => {
      state.openedItems = initialState.openedItems;
    },
  },
});

export const { toggleItem, resetMenuState } = menuSlice.actions;
export default menuSlice.reducer;
