import React, { useState } from 'react';
import { Spinner, Tooltip, Typography } from '@material-tailwind/react';
import moment from 'moment';
import Calendar from '../../calendar/Calendar';
import useSchedules from '../../../../hooks/schedule/useSchedules';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import { ReactComponent as QuestionMarkIcon } from '../../../../assets/images/icons/question-mark-circle.svg';

const UserTimeLogs = () => {
  const { schedulesState: curentSchedule, getCurrentSchedule } = useSchedules();
  const [isLoading, setIsLoading] = useState(false);
  const getTime = (timeString: string) => moment(timeString, 'HH:mm:ss').format('hh:mm A');

  const numericDayToAbbreviation = (numericDay: string | number) => {
    const dayMap: Record<string, string> = {
      '1': 'M',
      '2': 'T',
      '3': 'W',
      '4': 'Th',
      '5': 'F',
      '6': 'S',
      '7': 'Su',
    };

    const dayKey = typeof numericDay === 'number' ? String(numericDay) : numericDay;

    return dayMap[dayKey] || '';
  };

  const convertDays = (days: string[] | string | undefined): string => {
    if (Array.isArray(days)) {
      return days.map((numericDay) => numericDayToAbbreviation(Number(numericDay))).join(',');
    }

    if (typeof days === 'string' && days.trim() !== '') {
      return days
        .split(',')
        .map((numericDay) => numericDayToAbbreviation(Number(numericDay)))
        .join(',');
    }

    return '';
  };

  const convertedDays = convertDays(curentSchedule?.currentEmployeeSchedule?.days);
  const userCurrentSchedule = {
    ...curentSchedule?.currentEmployeeSchedule,
    days: convertedDays,
  };

  useOnLoad(() => {
    setIsLoading(true);
    getCurrentSchedule({}).finally(() => setIsLoading(false));
  });

  return (
    <div className="user-tab-container">
      <div className="w-full h-full flex user-timelog-wrapper">
        <div className="user-timelog-first">
          <div className="w-full pb-3 flex items-center gap-2">
            <h1 className="pl-1">UserTimeLogs</h1>
            <Tooltip
              className="shadow-md timelog-tooltip-body"
              placement="right-start"
              offset={{ top: 87, left: 0 }}
              content={
                <div className="w-80">
                  <Typography color="white" className="text-center font-medium">
                    Legend
                  </Typography>
                  {/* first-tooltip-wrapper */}
                  <div className="timelog-tooltip first-tooltip-wrapper">
                    {/* Attendance */}
                    <h2>Attendance</h2>
                    <div className="mb-1">
                      <span className="aos-present-background ml-1">Present</span>
                    </div>
                    <div className="mb-1">
                      <span className="aos-late-background ml-1">Late</span>
                    </div>
                    <div className="mb-1">
                      <span className="aos-absent-background ml-1">Absent</span>
                    </div>
                    {/* overtime */}
                    <div className="">
                      <span className="aos-approved-background ml-1">Overtime</span>
                      <span> (Approved)</span>
                    </div>
                  </div>
                  {/* second-tooltip-wrapper */}
                  <div className="timelog-tooltip second-tooltip-wrapper">
                    {/* Leave */}
                    <h2>Leave</h2>
                    <div className="mb-1">
                      <span className="timelog-pending ml-1">Pending</span>
                      <span> (Leave)</span>
                    </div>
                    <div className="mb-1">
                      <span className="timelog-offsite ml-1">Approved</span>
                      <span> (Offsite)</span>
                    </div>
                    <div className="mb-1 ml-1">
                      <span className="timelog-nature">Approved</span>
                      <span> ( Sick, Vacation, Bereavement, Paternity, Preventive, Others )</span>
                    </div>
                    <div className="mb-1 ml-1">
                      <span className="timelog-maternity">Approved </span>
                      <span> ( Maternity, Unpaid, Suspension )</span>
                    </div>
                  </div>
                </div>
              }
            >
              <QuestionMarkIcon className="w-5 h-auto cursor-pointer timelog-icon" />
            </Tooltip>
          </div>
          <div className="shadow-lg user-calendar-timelog-wrapper">
            <Calendar />
          </div>
        </div>
        <div className="user-timelog-second">
          {/* title & header */}
          <h2>Schedule</h2>
          <div className="timelog-header-wrapper timelog-header w-full">
            <span className="flex justify-center">Days</span>
            <span>Time-in</span>
            <span>Time-out</span>
          </div>
          {/* data list */}
          <div>
            {isLoading ? (
              <div className="w-full flex justify-center pt-4">
                <Spinner color="deep-orange" className="" />
              </div>
            ) : (
              userCurrentSchedule && (
                <div className="timelog-data-wrapper timelog-data">
                  <span>{userCurrentSchedule.days}</span>
                  <span>{userCurrentSchedule.timeIn && getTime(userCurrentSchedule.timeIn)}</span>
                  <span>{userCurrentSchedule.timeOut && getTime(userCurrentSchedule.timeOut)}</span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTimeLogs;
