import { useAppDispatch, useAppSelector } from '../../store/store';
import loanActions from '../../store/actions/loans/loanTypeActions';
import {
  loanTypesReducerActions,
  LoanTypesState,
} from '../../store/reducers/loans/loanTypesReducer';
import LoanType from '../../store/constants/loans/loan-type.interface';

const useLoanTypes = () => {
  const dispatch = useAppDispatch();
  const loanTypesState: LoanTypesState = useAppSelector((state) => state.loanTypes);

  const resetLoanTypesState = () => {
    dispatch(loanTypesReducerActions.resetLoanTypesState());
  };

  const setLoanTypesState = (state: Partial<LoanTypesState>) => {
    dispatch(loanTypesReducerActions.setLoanTypesState(state));
  };

  const get = async (id: LoanType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.getAll({}));

  const post = async (data: Omit<LoanType, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.post(data));

  const put = async (data: Partial<LoanType>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.put(data));

  const remove = async (id: LoanType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(loanActions.remove(id));

  return {
    resetLoanTypesState,
    loanTypesState,
    setLoanTypesState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useLoanTypes;
