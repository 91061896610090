import { useAppDispatch, useAppSelector } from '../../store/store';
import positionRateActions from '../../store/actions/positions/positionRateActions';
import {
  positionRatesReducerActions,
  PositionRatesState,
} from '../../store/reducers/positions/positionRatesReducer';
import EmploymentPositionRate from '../../store/constants/employments/positions/employment-position-rate.interface';
import EmploymentPosition from '../../store/constants/employments/positions/employment-position.interface';

const usePositionRates = () => {
  const dispatch = useAppDispatch();
  const positionRatesState: PositionRatesState = useAppSelector((state) => state.positionRates);

  const resetPositionRatesState = () => {
    dispatch(positionRatesReducerActions.resetPositionRatesState());
  };

  const setPositionRatesState = (state: Partial<PositionRatesState>) => {
    dispatch(positionRatesReducerActions.setPositionRatesState(state));
  };

  const get = async (id: EmploymentPositionRate['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionRateActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionRateActions.getAll({}));

  const post = async (data: Omit<EmploymentPositionRate, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionRateActions.post(data));

  const put = async (data: Partial<EmploymentPositionRate>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionRateActions.put(data));

  const remove = async (id: EmploymentPositionRate['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionRateActions.remove(id));

  const getUserPositionRate = (id: EmploymentPositionRate['id']) =>
    positionRatesState.positionRates.find((positionRate) => positionRate.id === id);

  const getRateByPositionId = (id: EmploymentPosition['id']) =>
    positionRatesState.positionRates.find(
      (positionRate) => positionRate.employmentPosition?.id === id,
    );

  return {
    resetPositionRatesState,
    positionRatesState,
    setPositionRatesState,
    getUserPositionRate,
    getRateByPositionId,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default usePositionRates;
