import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { LoadingState, loadingReducerActions } from '../../store/reducers/utils/loadingReducer';

const useLoading = () => {
  const dispatch = useAppDispatch();
  const isLoading: LoadingState = useAppSelector((state) => state.loading);

  const setLoading = useCallback(
    (loading: boolean) => {
      if (loading) {
        dispatch(loadingReducerActions.startLoading());
      } else {
        dispatch(loadingReducerActions.stopLoading());
      }
    },
    [dispatch],
  );

  return { isLoading, setLoading };
};

export default useLoading;
