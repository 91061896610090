import { useAppDispatch, useAppSelector } from '../../store/store';
import employmentStatusActions from '../../store/actions/employments/employmentStatusActions';
import UserActivity from '../../store/constants/utils/userActivity.interface';

import {
  EmploymentStatusState,
  employmentStatusReducerActions,
} from '../../store/reducers/employments/employmentStatusReducer';
import EmploymentStatus from '../../store/constants/employments/employment-status.interface';

const useEmploymentStatus = () => {
  const dispatch = useAppDispatch();
  const employmentStatusState: EmploymentStatusState = useAppSelector(
    (state) => state.employmentStatus,
  );

  const resetEmploymentStatusState = () => {
    dispatch(employmentStatusReducerActions.resetEmploymentStatusState());
  };

  const setEmploymentStatusState = (state: Partial<EmploymentStatusState>) => {
    dispatch(employmentStatusReducerActions.setEmploymentStatusState(state));
  };

  const get = async (employmentStatusId: EmploymentStatus['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentStatusActions.get(employmentStatusId));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentStatusActions.getAll({}));

  const postUser = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentStatusActions.postUser());

  const putUser = async (id: UserActivity['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(employmentStatusActions.putUser(id));
  return {
    resetEmploymentStatusState,
    employmentStatusState,
    setEmploymentStatusState,
    get,
    getAll,
    postUser,
    putUser,
  };
};

export default useEmploymentStatus;
