import moment from 'moment';

const excludingDates = (startDate: Date, endDate: Date, excludedDates: Date[]) => {
  const dates = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const currentDateWithoutTime = moment(currentDate).format('YYYY-MM-DD');

    if (
      !excludedDates.some((date) => moment(date).format('YYYY-MM-DD') === currentDateWithoutTime)
    ) {
      dates.push(new Date(currentDate));
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export default excludingDates;
