import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { Spinner } from '@material-tailwind/react';
import useDateTime from '../../../../hooks/utils/useDateTime';
import TextWithIcons from '../../../molecules/TextWithIcons';
import LeaveFormModalHistory from './LeaveFormModalHistory';
import LeaveRowHistory from './LeaveListRowHistory';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import useMyLeaves from '../../../../hooks/leave/useMyLeaves';
import LeaveApplication from '../../../../store/constants/leaves/leave-application.interface';
import useAuth from '../../../../hooks/auths/useAuth';
import EmptyListMessage from '../../../atoms/EmptyListMessage';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow-with-tail-down.svg';
import { ReactComponent as Search } from '../../../../assets/images/icons/search.svg';

type SortByHeaderOptions =
  | ''
  | 'dateFilled'
  | 'dateStart'
  | 'dateEnd'
  | 'nature'
  | 'reason'
  | 'status';

const MyLeaveListHistory: React.FC = () => {
  const {
    authState: { userInformation },
  } = useAuth();
  const { myLeavesState, getMyAll } = useMyLeaves();
  const { getDateTime } = useDateTime();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const [selectedLeave, setSelectedLeave] = useState<LeaveApplication | undefined>(undefined);
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const leaves = myLeavesState.myLeaves
    .filter(
      (leave: LeaveApplication) =>
        (leave.employeeNumber === userInformation?.employeeNumber &&
          leave.status &&
          leave.status.toLowerCase().includes(debouncedSearchValue.toLowerCase())) ||
        leave.notes.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    )
    .sort((a: LeaveApplication, b: LeaveApplication) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'dateStart') {
        return sortOrder * (new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime());
      }

      if (sortOption === 'dateEnd') {
        return sortOrder * (new Date(a.dateEnd).getTime() - new Date(b.dateEnd).getTime());
      }

      if (sortOption === 'nature') {
        if (!a.leaveNature?.nature) return -sortOrder;
        if (!b.leaveNature?.nature) return sortOrder;

        return sortOrder * a.leaveNature.nature.localeCompare(b.leaveNature.nature);
      }

      if (sortOption === 'reason') {
        return sortOrder * a.notes.localeCompare(b.notes);
      }

      if (sortOption === 'status') {
        return sortOrder * a.status.localeCompare(b.status);
      }

      if (sortOption === 'dateFilled') {
        return sortOrder * (new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());
      }

      // Default sorting
      const dateA = new Date(a.dateCreated).getTime();
      const dateB = new Date(b.dateCreated).getTime();
      return sortOrder * (dateA - dateB);
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const managePending = (leave: LeaveApplication) => {
    setSelectedLeave(leave);
  };

  const onLeaveModalClose = () => {
    setSelectedLeave(undefined);
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }

    const itemHeight = 50;
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    setIsLoading(true);
    getMyAll()
      .then()
      .finally(() => setIsLoading(false));
  });

  return (
    <div className="px-5 w-full">
      <div className="flex justify-between pb-2">
        {selectedLeave && (
          <LeaveFormModalHistory leave={selectedLeave} onClose={onLeaveModalClose} />
        )}
        <div className="flex justify-end w-full">
          <div className="flex leave-history-icon">
            <input
              onKeyDown={onSearchKeyDown}
              type="text"
              className="flex justify-end p-2 rounded-md leave-search-bar"
              placeholder="Search leave"
              onChange={handleSearch}
            />
            <div className="wrapper-search">
              <Search className="w-5 h-auto" />
            </div>
          </div>
        </div>
      </div>
      <div className="leave-history-header history-items table-header">
        <button
          type="button"
          onClick={() => sortByHeader('dateFilled', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'dateFilled' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'dateFilled' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <span className="flex gap-5">Date Filed</span>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('dateStart', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'dateStart' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'dateStart' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <span className="flex gap-5">Date Start</span>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('dateEnd', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'dateEnd' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'dateEnd' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <span className="flex gap-5">Date End</span>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('nature', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'nature' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'nature' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <span className="flex gap-5">Nature</span>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('reason', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'reason' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'reason' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <span className="flex gap-5">Reason</span>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('status', false)}
          className="flex items-center justify-center pr-5 gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'status' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'status' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <span className="flex gap-5">Status</span>
          </TextWithIcons>
        </button>

        <TextWithIcons className="justify-self-center" variant="h6">
          <span className="flex gap-5">Action</span>
        </TextWithIcons>
      </div>

      {isLoading && (
        <div className="w-full p-5 flex justify-center">
          <Spinner color="deep-orange" className="mr-2" />
        </div>
      )}

      {!isLoading && leaves.length === 0 ? (
        <EmptyListMessage message="You don't have any leave recorded." />
      ) : (
        <VariableSizeList
          itemCount={leaves.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <LeaveRowHistory
              data={leaves[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              getDateTime={getDateTime}
              managePending={managePending}
            />
          )}
        </VariableSizeList>
      )}
    </div>
  );
};

export default MyLeaveListHistory;
