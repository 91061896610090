import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import EmployeeOvertimeType from '../../../store/constants/employees/attendances/employee-overtime-type.interface';
import useOvertimes from '../../../hooks/attendances/useOvertimes';

export interface SelectStatusProps extends Partial<AutoSearchSelectProps<EmployeeOvertimeType>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectOvertimeType: React.FC<SelectStatusProps> = ({
  id,
  name,
  placeholder = 'Select Overtime Type',
  className = '',
  ...props
}) => {
  const { overtimeState } = useOvertimes();

  const overtimeTypeOptions = overtimeState.overtimeType.slice().map((overtimeType) => ({
    ...overtimeType,
    value: overtimeType.id,
    label: overtimeType.overtimeName,
  }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-overtime modal-select ${className}`}
      placeholder={placeholder}
      data={overtimeTypeOptions}
      isSearchable
    />
  );
};

export default SelectOvertimeType;
