import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Timezone from '../../constants/timezones/timezone.interface';
import SupportedLocale from '../../constants/utils/supported-locale';

enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
  GRADIENT = 'gradient',
}

export interface SettingsState {
  theme: string;
  timezone: Timezone;
  locale: SupportedLocale;
  dateTimeFormat: string;
  dateFormat: string;
  timeFormat: string;
  timezones: Timezone[];
  supportedLocales: SupportedLocale[];
  userDrawerOpen?: boolean;
}

const initialState: SettingsState = {
  theme: Themes.DARK,
  timezone: {
    id: 1,
    code: 'PHT',
    timezone: 'Asia/Manila',
    offset: 8,
  },
  locale: {
    id: 1,
    locale: 'en-US',
    label: 'English',
  },
  dateTimeFormat: 'YYYY-MM-DD hh:mm:ss A',
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'hh:mm:ss A',
  timezones: [
    { id: 1, code: 'PHT', timezone: 'Asia/Manila', offset: 8 },
    { id: 2, code: 'EST', timezone: 'America/New_York', offset: -5 },
    { id: 3, code: 'PST', timezone: 'America/Los_Angeles', offset: -8 },
  ],
  supportedLocales: [
    {
      id: 1,
      locale: 'en-US',
      label: 'English',
    },
  ],
  userDrawerOpen: false,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetSettingsState: () => ({
      ...initialState,
    }),
    setSettingsState: (state, action: PayloadAction<Partial<SettingsState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const settingsReducerActions = settingsSlice.actions;
export default settingsSlice.reducer;
