import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { Tooltip } from '@material-tailwind/react';
import TextWithIcons from '../../molecules/TextWithIcons';
import Team from '../../../store/constants/teams/team.interface';
import TeamFormModal from './TeamFormModal';
import useDateTime from '../../../hooks/utils/useDateTime';
import consoleLog from '../../../utils/consoleLog';
import useTeams from '../../../hooks/teams/useTeams';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import TeamListRow from './TeamListRow';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import SearchSvg from '../../../assets/images/icons/search.svg';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions = '' | 'team' | 'fte' | 'client' | 'department' | 'leader' | 'dateUpdated';

const TeamList: React.FC = () => {
  const { getDateTime, getDateAsMoment } = useDateTime();

  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const { teamsState, getAll, remove } = useTeams();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const teams = teamsState.teams
    .filter((team: Team) => {
      if (!team) return false;
      const { name, client, department, teamLeader } = team;
      return (
        name.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        client?.name.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        department?.name.toLowerCase().startsWith(debouncedSearchValue.toLowerCase()) ||
        teamLeader?.userInformation.firstName
          .toLowerCase()
          .startsWith(debouncedSearchValue.toLowerCase()) ||
        teamLeader?.userInformation.lastName
          .toLowerCase()
          .startsWith(debouncedSearchValue.toLowerCase())
      );
    })
    .sort((a: Team, b: Team) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'team') {
        return sortOrder * a.name.localeCompare(b.name);
      }

      if (sortOption === 'client') {
        if (!a.client) return -sortOrder;
        if (!b.client) return sortOrder;

        return sortOrder * a.client.name.localeCompare(b.client.name);
      }

      if (sortOption === 'department') {
        if (!a.department) return -sortOrder;
        if (!b.department) return sortOrder;

        return sortOrder * a.department.name.localeCompare(b.department.name);
      }

      if (sortOption === 'leader') {
        if (!a.teamLeader) return -sortOrder;
        if (!b.teamLeader) return sortOrder;

        return (
          sortOrder *
          b.teamLeader.userInformation.fullName.localeCompare(a.teamLeader.userInformation.fullName)
        );
      }

      if (sortOption === 'dateUpdated') {
        return sortOrder * getDateAsMoment(a.dateUpdated).diff(getDateAsMoment(b.dateUpdated));
      }
      return sortOrder * (a.id - b.id);
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    consoleLog('TEAM_LIST', 'onSearchKeyDown', e);

    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    consoleLog('TEAM_LIST', 'onSearchKeyDown', e);
    setSearchValue(e.target.value);
  };

  const onEditClick = (client: Team) => {
    consoleLog('TEAM_LIST', 'onEditClick', client);
    setSelectedTeam(client);
  };

  const onDeleteClick = async (client: Team) => {
    consoleLog('TEAM_LIST', 'onDeleteClick', client);

    remove(client.id).then((r) => {
      consoleLog('DELETE CLIENT', r);
    });
  };

  const onTeamModalClose = () => setSelectedTeam(undefined);

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full px-5 organisms-team-list">
      <div className="flex justify-between pb-5">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search team"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Team"
          />
        </div>
        <TeamFormModal team={selectedTeam} onClose={onTeamModalClose} />
      </div>
      <div className="team-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        <button
          type="button"
          onClick={() => sortByHeader('team', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'team' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'team' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Team</div>
          </TextWithIcons>
        </button>
        <TextWithIcons variant="h6">
          <Tooltip content="Full time equivalent" className="bg-[#343434]">
            <div className="client-list-header__item">FTE</div>
          </Tooltip>
        </TextWithIcons>
        <button
          type="button"
          onClick={() => sortByHeader('client', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'client' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'client' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Client</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('department', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'department' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'department' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Department</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('leader', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'leader' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'leader' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Leader</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('dateUpdated', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow
                  className={`sort-arrow-icon ${sortOption === 'dateUpdated' ? '' : 'hidden'}`}
                />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'dateUpdated' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Date updated</div>
          </TextWithIcons>
        </button>

        <TextWithIcons variant="h6" className=" justify-self-center">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>

      {teams.length !== 0 ? (
        <VariableSizeList
          itemCount={teams.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <TeamListRow
              data={teams[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              getDateTime={getDateTime}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          )}
        </VariableSizeList>
      ) : (
        <EmptyListMessage />
      )}
    </div>
  );
};

export default TeamList;
