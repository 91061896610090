import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Step, Stepper, Tooltip } from '@material-tailwind/react';
import { useNavigate, useParams } from 'react-router-dom';
import UserBasicInfoForm from '../../molecules/Users/UserBasicInfoForm';
import UserContactInfoForm from '../../molecules/Users/UserContactInfoForm';
import UserEmploymentInfoForm from '../../molecules/Users/UserEmploymentInfoForm';
import useUserForm from '../../../hooks/users/useUserForm';
import UserEmploymentPayrollForm from '../../molecules/Users/UserEmploymentPayrollForm';
import UserEmploymentTeamAssignmentForm from '../../molecules/Users/UserEmploymentTeamAssignmentForm';
import useUsers from '../../../hooks/users/useUsers';
import UserEmploymentSummary from '../../molecules/Users/UserEmploymentSummary';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import { UserFormPage, UserFormSteps } from '../../../store/reducers/users/userFormReducer';

const FormStepper: React.FC = () => {
  const navigate = useNavigate();
  const { get, usersState, getUserInState } = useUsers();
  const {
    activeStep,
    userFormState,
    getUrlStep,
    setActiveStep,
    resetUserFormState,
    setUserFormState,
  } = useUserForm();

  const { id, step = 'basic' } = useParams<{
    id: string;
    step: string;
  }>();

  const userId: number = parseInt(id ?? '0', 10);
  const urlStep: UserFormSteps = getUrlStep(step);
  const [loading, setLoading] = useState(true);

  const submitButton = useRef<HTMLButtonElement>(null);

  const renderStepComponent = useCallback(() => {
    if (loading) return null;

    // Display page
    switch (activeStep) {
      case UserFormSteps.BASIC_INFO:
        return <UserBasicInfoForm submitButton={submitButton} />;
      case UserFormSteps.CONTACT_INFO:
        return <UserContactInfoForm submitButton={submitButton} />;
      case UserFormSteps.EMPLOYMENT_INFO:
        return <UserEmploymentInfoForm submitButton={submitButton} />;
      case UserFormSteps.EMPLOYMENT_PAYROLL:
        return <UserEmploymentPayrollForm submitButton={submitButton} />;
      case UserFormSteps.EMPLOYMENT_TEAM_ASSIGNMENT:
        return <UserEmploymentTeamAssignmentForm submitButton={submitButton} />;
      case UserFormSteps.SUMMARY:
        return <UserEmploymentSummary submitButton={submitButton} />;
      default:
        setActiveStep(UserFormSteps.BASIC_INFO);
        return null;
    }

    // eslint-disable-next-line
  }, [loading, userId, usersState.users, activeStep]);

  useOnLoad(() => {
    setActiveStep(urlStep);

    if (userId === 0) {
      setLoading(false);
      return;
    }

    const user = getUserInState(userId);
    if (!user) {
      (async () => get(userId))().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  });

  const handleStep = (goToStep: UserFormPage) => {
    setUserFormState({ nextStep: goToStep });
  };

  useEffect(() => {
    if (submitButton.current) submitButton.current.click();
  }, [userFormState.nextStep]);

  useEffect(() => {
    if (!loading) {
      const user = getUserInState(userId);
      if (user) {
        setUserFormState({ user });
      }
    }
  }, [
    getUserInState,
    loading,
    navigate,
    resetUserFormState,
    setUserFormState,
    userId,
    usersState.users,
  ]);

  return (
    <div className="flex flex-col px-5 mt-5">
      <div className="stepper-icons">
        <Stepper
          activeStep={activeStep}
          lineClassName="bg-gray-700"
          activeLineClassName="bg-[#f05825]"
          className=" -z-0 w-96 m-auto"
        >
          <Step
            onClick={() => handleStep('basic')}
            className="!bg-[#393836] text-[#292929] bg-opacity-50 cursor-pointer"
            activeClassName="ring-0 !bg-[#f05825] text-white"
            completedClassName="!bg-[#f05825] text-white"
          >
            <Tooltip
              content={
                <div>
                  <span className="text-white text-sm ">Basic Information</span>
                </div>
              }
              offset={10}
              placement="bottom"
              className="bg-[#323232] shadow-lg"
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <div className="w-full h-full grid place-content-center rounded-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Tooltip>
          </Step>
          <Step
            onClick={() => handleStep('contact')}
            className="!bg-[#393836] text-[#292929] shadow-lg cursor-pointer"
            activeClassName="ring-0 !bg-[#f05825] text-white"
            completedClassName="!bg-[#f05825] text-white"
          >
            <Tooltip
              content={
                <div>
                  <span className="text-white text-sm">Contact & Address</span>
                </div>
              }
              offset={10}
              placement="bottom"
              className="bg-[#323232] shadow-lg"
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <div className="w-full h-full grid place-content-center rounded-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </Tooltip>
          </Step>
          <Step
            onClick={() => handleStep('employment')}
            className="!bg-[#393836] text-[#292929] cursor-pointer"
            activeClassName="ring-0 !bg-[#f05825] text-white"
            completedClassName="!bg-[#f05825] text-white"
          >
            <Tooltip
              content={
                <div>
                  <span className="text-white text-sm">Employment</span>
                </div>
              }
              offset={10}
              placement="bottom"
              className="bg-[#323232] shadow-lg"
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <div className="w-full h-full grid place-content-center rounded-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625ZM7.5 15a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5h-7.5A.75.75 0 0 1 7.5 15Zm.75 2.25a.75.75 0 0 0 0 1.5H12a.75.75 0 0 0 0-1.5H8.25Z"
                    clipRule="evenodd"
                  />
                  <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                </svg>
              </div>
            </Tooltip>
          </Step>
          <Step
            onClick={() => handleStep('payroll')}
            className="!bg-[#393836] text-[#292929] cursor-pointer"
            activeClassName="ring-0 !bg-[#f05825] text-white"
            completedClassName="!bg-[#f05825] text-white"
          >
            <Tooltip
              content={
                <div>
                  <span className="text-white text-sm">Payroll</span>
                </div>
              }
              offset={10}
              placement="bottom"
              className="bg-[#323232] shadow-lg"
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <div className="w-full h-full grid place-content-center rounded-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path d="M12 7.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z" />
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 14.625v-9.75ZM8.25 9.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM18.75 9a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75V9.75a.75.75 0 0 0-.75-.75h-.008ZM4.5 9.75A.75.75 0 0 1 5.25 9h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75H5.25a.75.75 0 0 1-.75-.75V9.75Z"
                    clipRule="evenodd"
                  />
                  <path d="M2.25 18a.75.75 0 0 0 0 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 0 0-.75-.75H2.25Z" />
                </svg>
              </div>
            </Tooltip>
          </Step>
          <Step
            onClick={() => handleStep('teams')}
            className="!bg-[#393836] text-[#292929] cursor-pointer"
            activeClassName="ring-0 !bg-[#f05825] text-white"
            completedClassName="!bg-[#f05825] text-white"
          >
            <Tooltip
              content={
                <div>
                  <span className="text-white text-sm">Team</span>
                </div>
              }
              offset={10}
              placement="bottom"
              className="bg-[#323232] shadow-lg"
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <div className="w-full h-full grid place-content-center rounded-3xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z"
                    clipRule="evenodd"
                  />
                  <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                </svg>
              </div>
            </Tooltip>
          </Step>
        </Stepper>
      </div>
      <div className="mt-2">{renderStepComponent()}</div>
    </div>
  );
};

export default FormStepper;
