import { useAppDispatch, useAppSelector } from '../../store/store';
import scheduleTypeActions from '../../store/actions/schedule/scheduleTypeActions';
import {
  scheduleTypesReducerActions,
  ScheduleTypesState,
} from '../../store/reducers/employments/employmentScheduleTypesReducer';
import ScheduleType from '../../store/constants/employments/schedules/employment-schedule-type.interface';

const useScheduleTypes = () => {
  const dispatch = useAppDispatch();
  const scheduleTypesState: ScheduleTypesState = useAppSelector((state) => state.scheduleTypes);

  const resetScheduleTypeState = () => {
    dispatch(scheduleTypesReducerActions.resetScheduleTypesState());
  };

  const setScheduleTypeState = (state: Partial<ScheduleTypesState>) => {
    dispatch(scheduleTypesReducerActions.setScheduleTypesState(state));
  };

  const get = async (id: ScheduleType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(scheduleTypeActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(scheduleTypeActions.getAll({}));

  const post = async (data: Omit<ScheduleType, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(scheduleTypeActions.post(data));

  const put = async (data: Partial<ScheduleType>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(scheduleTypeActions.put(data));

  const remove = async (id: ScheduleType['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(scheduleTypeActions.remove(id));

  const getScheduleTypeById = (id: ScheduleType['id'] | undefined) =>
    scheduleTypesState.scheduleTypes.find((scheduleType) => scheduleType.id === id);

  return {
    resetScheduleTypeState,
    scheduleTypesState,
    setScheduleTypeState,
    getScheduleTypeById,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useScheduleTypes;
