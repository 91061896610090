import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import EmployeeCalendar from '../../constants/calendar/employee-calendar.interface';
import ApigetAllCalendarEventsBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApigetAllCalendarEventsBaseResponse from '../../constants/utils/api-get-all-base-response.interface';

export type GetAllCalendarEventsQueryParams = ApigetAllCalendarEventsBaseQueryParams & {
  startDate?: string;
  endDate?: string;
};

const getAllCalendarEvents = async (
  { page = 1, limit = 50, search = '', startDate, endDate }: GetAllCalendarEventsQueryParams,
  state: StateHeaders,
): Promise<ApigetAllCalendarEventsBaseResponse<EmployeeCalendar>> =>
  apiFetch<ApigetAllCalendarEventsBaseResponse<EmployeeCalendar>>(
    `calendars?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}${
      startDate ? `&startDate=${startDate}` : ''
    }${endDate ? `&endDate=${endDate}` : ''}`,
    { state },
  )
    .then((response: ApigetAllCalendarEventsBaseResponse<EmployeeCalendar>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  getAllCalendarEvents,
} as const;
