import React, { FC } from 'react';
import Select, {
  ActionMeta,
  GroupBase,
  MultiValue,
  Props as SelectProps,
  SingleValue,
} from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import { FieldInputProps, FormikErrors } from 'formik';
import AutoSearchSelectStyle from '../../molecules/AutoSearchSelect/AutoSearchSelectStyle';
import { ReactComponent as Search } from '../../../assets/images/icons/search.svg';

const withLeftIconStyle: StylesConfig = {
  placeholder: (provided) => ({
    ...provided,
    marginLeft: '1.5rem',
  }),

  singleValue: (provided) => ({
    ...provided,
    color: 'white',
    marginLeft: '1.5rem',
  }),

  input: (provided) => ({
    ...provided,
    color: 'white',
    marginLeft: '1.5rem',
  }),
};

export interface AutoSearchSelectProps<T> extends Partial<SelectProps<T, boolean, GroupBase<T>>> {
  value: T;
  data: T[];
  icon?: React.ReactNode;
  hasSearchIcon?: boolean;
  onChange?:
    | ((newValue?: SingleValue<T> | MultiValue<T>, actionMeta?: ActionMeta<T>) => void)
    | undefined;
  setFieldValue?: (
    field: string,
    value?: T,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<unknown>>;
  shouldValidate?: boolean;
  className?: string;
  field: FieldInputProps<T>;
  isClearable?: boolean;
  isSearchable?: boolean;
}

const SelectAutoSearch: FC<AutoSearchSelectProps<unknown>> = ({
  value,
  data,
  onChange = undefined,
  setFieldValue = undefined,
  hasSearchIcon = false,
  icon = undefined,
  shouldValidate = false,
  className = '',
  field,
  isClearable = false,
  isSearchable = false,
  ...props
}) => {
  let styles: StylesConfig = AutoSearchSelectStyle;

  if (hasSearchIcon || icon) {
    styles = { ...styles, ...withLeftIconStyle };
  }

  const handleOnChange = (
    newValue?: SingleValue<unknown> | MultiValue<unknown>,
    actionMeta?: ActionMeta<unknown>,
  ) => {
    if (onChange) onChange(newValue, actionMeta);

    if (setFieldValue) {
      setFieldValue(field.name, newValue, shouldValidate).then();
    }
  };

  return (
    <div className="relative m-auto">
      {hasSearchIcon && !icon ? (
        <span className=" z-10 absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Search className="h-4 w-4 text-gray-400" />
        </span>
      ) : (
        <span className=" z-10 absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          {icon}
        </span>
      )}
      <Select
        {...props}
        className={`auto-search-select ${className}`}
        value={value}
        options={data}
        onChange={handleOnChange}
        styles={styles}
        isClearable={isClearable}
        isSearchable={isSearchable}
      />
    </div>
  );
};

export default SelectAutoSearch;
