import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import { Link, useParams } from 'react-router-dom';
import { Chip, Typography } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import DateRangePicker from 'react-datepicker';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import TextWithIcons from '../../molecules/TextWithIcons';
import ClientEmployeeListRow from './ClientEmployeeListRow';
import consoleLog from '../../../utils/consoleLog';
import useDateTime from '../../../hooks/utils/useDateTime';
import useClient from '../../../hooks/clients/useClient';
import SearchSvg from '../../../assets/images/icons/search.svg';
import SelectTeam from '../../atoms/dropdowns/SelectTeam';
import Team from '../../../store/constants/teams/team.interface';
import Employee from '../../../store/constants/employees/employee.interface';
import SelectEmploymentPosition from '../../atoms/dropdowns/SelectEmploymentPosition';
import EmploymentPosition from '../../../store/constants/employments/positions/employment-position.interface';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions =
  | ''
  | 'employeeNumber'
  | 'employee'
  | 'position'
  | 'team'
  | 'dateStart'
  | 'dateEnd';

const ClientEmployeeList: React.FC = () => {
  const { getDateTime, getDateAsMoment } = useDateTime();

  const [selectedEmploymentPosition, setSelectedEmploymentPosition] = useState<
    EmploymentPosition | undefined
  >();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const [dateStartRange, setDateStartRange] = useState<[Date | null, Date | null]>([null, null]);
  const [dateEndRange, setDateEndRange] = useState<[Date | null, Date | null]>([null, null]);
  const [dateStartFrom, dateStartTo] = dateStartRange;
  const [dateEndFrom, dateEndTo] = dateEndRange;

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const { id } = useParams<{
    id: string;
  }>();

  const { currentClient: client, loading } = useClient(id as string);

  if (loading) return null;

  if (!client) return null;

  const clientEmployees = client?.employees
    ?.filter((employee: Employee) =>
      employee.userInformation.fullName.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
    )
    ?.filter((employee: Employee) => {
      if (selectedEmploymentPosition) {
        return employee.currentPosition?.employmentPosition.id === selectedEmploymentPosition.id;
      }
      return true;
    })
    ?.filter((employee: Employee) => {
      if (selectedTeam) {
        if (!employee.teams[0].team) return false;
        return employee.teams[0].team.id === selectedTeam.id;
      }
      return true;
    })
    .filter((employee: Employee) => {
      const dateStartString = employee.teams[0]?.dateStart;
      const dateEndString = employee.teams[0]?.dateEnd;

      const dateStart = dateStartString && new Date(dateStartString);
      const dateEnd = dateEndString && new Date(dateEndString);

      const isDateStartInRange =
        !dateStartFrom ||
        !dateStartTo ||
        (dateStart && dateStart >= dateStartFrom && dateStart <= dateStartTo);

      const isDateEndInRange =
        !dateEndFrom || !dateEndTo || (dateEnd && dateEnd >= dateEndFrom && dateEnd <= dateEndTo);

      return isDateStartInRange && isDateEndInRange;
    })
    .sort((a: Employee, b: Employee) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'employeeNumber') {
        return sortOrder * a.employeeNumber - b.employeeNumber;
      }

      if (sortOption === 'employee') {
        return sortOrder * a.userInformation.fullName.localeCompare(b.userInformation.fullName);
      }

      if (sortOption === 'position') {
        if (!a.currentPosition) return -sortOrder;
        if (!b.currentPosition) return sortOrder;

        return (
          sortOrder *
          b.currentPosition.employmentPosition.name.localeCompare(
            a.currentPosition.employmentPosition.name,
          )
        );
      }

      if (sortOption === 'team') {
        if (!a.teams[0].team) return -sortOrder;
        if (!b.teams[0].team) return sortOrder;

        return sortOrder * a.teams[0].team.name.localeCompare(b.teams[0].team.name);
      }

      if (sortOption === 'dateStart') {
        if (!a.teams[0].dateStart) return -sortOrder;
        if (!b.teams[0].dateStart) return sortOrder;

        return (
          sortOrder *
          getDateAsMoment(new Date(b.teams[0].dateStart)).diff(
            getDateAsMoment(new Date(a.teams[0].dateStart)),
          )
        );
      }

      if (sortOption === 'dateEnd') {
        if (!a.teams[0].dateEnd) return -sortOrder;
        if (!b.teams[0].dateEnd) return sortOrder;

        return (
          sortOrder *
          getDateAsMoment(new Date(b.teams[0].dateEnd)).diff(
            getDateAsMoment(new Date(a.teams[0].dateEnd)),
          )
        );
      }

      return sortOrder * (b.id - a.id);
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (employee: Employee) => {
    consoleLog('LOG_THIS', 'EDIT CLIENT', employee);
  };

  const onDeleteClick = async (employee: Employee) => {
    consoleLog('DELETE CLIENT EMPLOYEE', employee);
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  return (
    <div className="organisms-client-employee-list w-full">
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/clients">
          <Typography className="crumb">Clients</Typography>
        </Link>
        <Link to={`/admin/clients/${client.id}`}>
          <Typography className="crumb">{client.name}</Typography>
        </Link>
        <span className=" flex items-center gap-2">
          <Typography className="current-crumb">Employees</Typography>{' '}
          <Chip className="chip-style" value={client?.employees?.length} variant="ghost" />
        </span>
      </Breadcrumbs>
      <div className="management-heading">
        <Typography variant="h3" className="management-heading__secondary">
          Client Employees Management
        </Typography>
      </div>
      <div className="client-employee-list-container pr-10">
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Form>
            <div className="organisms-client-employee-list__top-section flex-row-reverse">
              <div className="client-filters client-employee-list-filter">
                <div className="department-filter">
                  <SelectEmploymentPosition
                    id="activePosition"
                    name="activePosition"
                    value={selectedEmploymentPosition}
                    onChange={(value) => setSelectedEmploymentPosition(value as EmploymentPosition)}
                    hasSearchIcon
                    isSearchable
                    isClearable
                  />
                </div>
                <div className="team-filter">
                  <SelectTeam
                    id="team"
                    className=""
                    name="team"
                    clientTeams={client.teams}
                    value={selectedTeam}
                    onChange={(value) => setSelectedTeam(value as Team)}
                    hasSearchIcon
                    isSearchable
                    isClearable
                  />
                </div>
                <div className="date-filter">
                  <DateRangePicker
                    className="date-input"
                    startDate={dateStartFrom}
                    endDate={dateStartTo}
                    onChange={(update) => {
                      setDateStartRange(update);
                    }}
                    selectsRange
                    isClearable
                    placeholderText="Select date start range..."
                  />
                </div>
                <div className="date-filter">
                  <DateRangePicker
                    className="date-input"
                    startDate={dateEndFrom}
                    endDate={dateEndTo}
                    onChange={(update) => {
                      setDateEndRange(update);
                    }}
                    selectsRange
                    isClearable
                    placeholderText="Select date end range..."
                  />
                </div>
                <div className="search-body">
                  <input
                    onKeyDown={onSearchKeyDown}
                    type="text"
                    className="search-body__input search-bar"
                    placeholder="Search employee"
                    onChange={handleSearch}
                  />
                  <img
                    style={{ filter: 'invert(0) invert(1)' }}
                    className="search-body__icon"
                    src={SearchSvg}
                    alt="Search for Client"
                  />
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        <div className="client-employee-list-header table-header">
          <button
            type="button"
            onClick={() => sortByHeader('employeeNumber', true)}
            className="flex items-center gap-3"
          >
            <TextWithIcons variant="h6">
              <div className="flex gap-5">Employee #</div>
            </TextWithIcons>
            {sortOrderBy ? (
              <Arrow
                className={`sort-arrow-icon ${sortOption === 'employeeNumber' ? '' : 'hidden'}`}
              />
            ) : (
              <Arrow
                className={`sort-arrow-icon ${
                  sortOption === 'employeeNumber' ? 'sort-arrow-rotate-icon' : 'hidden'
                }`}
              />
            )}
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('employee', true)}
            className="flex items-center gap-3"
          >
            <TextWithIcons variant="h6">
              <div className="flex gap-5">Employee</div>
            </TextWithIcons>
            {sortOrderBy ? (
              <Arrow className={`sort-arrow-icon ${sortOption === 'employee' ? '' : 'hidden'}`} />
            ) : (
              <Arrow
                className={`sort-arrow-icon ${
                  sortOption === 'employee' ? 'sort-arrow-rotate-icon' : 'hidden'
                }`}
              />
            )}
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('position', true)}
            className="flex items-center gap-3"
          >
            <TextWithIcons variant="h6">
              <div className="flex gap-5">Position</div>
            </TextWithIcons>
            {sortOrderBy ? (
              <Arrow className={`sort-arrow-icon ${sortOption === 'position' ? '' : 'hidden'}`} />
            ) : (
              <Arrow
                className={`sort-arrow-icon ${
                  sortOption === 'position' ? 'sort-arrow-rotate-icon' : 'hidden'
                }`}
              />
            )}
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('team', true)}
            className="flex items-center gap-3"
          >
            <TextWithIcons variant="h6">
              <div className="flex gap-5">Team</div>
            </TextWithIcons>
            {sortOrderBy ? (
              <Arrow className={`sort-arrow-icon ${sortOption === 'team' ? '' : 'hidden'}`} />
            ) : (
              <Arrow
                className={`sort-arrow-icon ${
                  sortOption === 'team' ? 'sort-arrow-rotate-icon' : 'hidden'
                }`}
              />
            )}
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('dateStart', true)}
            className="flex items-center gap-3"
          >
            <TextWithIcons variant="h6">
              <div className="flex gap-5">Date start</div>
            </TextWithIcons>
            {sortOrderBy ? (
              <Arrow className={`sort-arrow-icon ${sortOption === 'dateStart' ? '' : 'hidden'}`} />
            ) : (
              <Arrow
                className={`sort-arrow-icon ${
                  sortOption === 'dateStart' ? 'sort-arrow-rotate-icon' : 'hidden'
                }`}
              />
            )}
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('dateEnd', true)}
            className="flex items-center gap-3"
          >
            <TextWithIcons variant="h6">
              <div className="flex gap-5">Date end</div>
            </TextWithIcons>
            {sortOrderBy ? (
              <Arrow className={`sort-arrow-icon ${sortOption === 'dateEnd' ? '' : 'hidden'}`} />
            ) : (
              <Arrow
                className={`sort-arrow-icon ${
                  sortOption === 'dateEnd' ? 'sort-arrow-rotate-icon' : 'hidden'
                }`}
              />
            )}
          </button>
        </div>

        {clientEmployees && clientEmployees?.length !== 0 ? (
          <VariableSizeList
            itemCount={clientEmployees.length}
            itemSize={getItemSize}
            height={window.innerHeight - 400}
            width="100%"
            className="no-scrollbars"
          >
            {({ index, style }) => (
              <ClientEmployeeListRow
                data={clientEmployees[index]}
                className={`${index % 2 === 0 ? 'stripped' : ''}`}
                style={style}
                getDateTime={getDateTime}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            )}
          </VariableSizeList>
        ) : (
          <EmptyListMessage />
        )}
      </div>
    </div>
  );
};

export default ClientEmployeeList;
