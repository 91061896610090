const updateAddressParams = (params: Record<string, string | undefined>) => {
  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
  });

  window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
};

export default updateAddressParams;
