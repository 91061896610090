import { useAppDispatch, useAppSelector } from '../../store/store';
import userEmploymentScheduleActions from '../../store/actions/users/employments/userEmploymentScheduleActions';
import {
  schedulesReducerActions,
  SchedulesState,
} from '../../store/reducers/schedules/schedulesReducer';
import UserEmploymentSchedules from '../../store/constants/employees/schedules/employee-schedule.interface';

const useSchedules = () => {
  const dispatch = useAppDispatch();
  const schedulesState: SchedulesState = useAppSelector((state) => state.schedule);

  const resetScheduleState = () => {
    dispatch(schedulesReducerActions.resetSchedulesState());
  };

  const setScheduleState = (state: Partial<SchedulesState>) => {
    dispatch(schedulesReducerActions.setSchedulesState(state));
  };

  const get = async (id: UserEmploymentSchedules['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentScheduleActions.get(id));

  const getAll = async (search = {}) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentScheduleActions.getAll(search));

  const post = async (data: Omit<UserEmploymentSchedules, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentScheduleActions.post(data));

  const put = async (data: Partial<UserEmploymentSchedules>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentScheduleActions.put(data));

  const remove = async (id: UserEmploymentSchedules['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentScheduleActions.remove(id));

  const currentEmployeeSchedule = (employeeNumber: UserEmploymentSchedules['employeeNumber']) =>
    schedulesState.schedules.find((schedule) => schedule.employeeNumber === employeeNumber);

  const getCurrentSchedule = async (data = {}) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(userEmploymentScheduleActions.getCurrentSchedule(data));

  return {
    resetScheduleState,
    schedulesState,
    setScheduleState,
    currentEmployeeSchedule,
    getCurrentSchedule,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useSchedules;
