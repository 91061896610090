import { useAppDispatch, useAppSelector } from '../../store/store';
import companyActions from '../../store/actions/companies/companyActions';
import {
  companiesReducerActions,
  CompaniesState,
} from '../../store/reducers/companies/companiesReducer';
import Company from '../../store/constants/companies/company.interface';

const useCompanies = () => {
  const dispatch = useAppDispatch();
  const companiesState: CompaniesState = useAppSelector((state) => state.company);

  const resetCompanyState = () => {
    dispatch(companiesReducerActions.resetCompaniesState());
  };

  const setCompanyState = (state: Partial<CompaniesState>) => {
    dispatch(companiesReducerActions.setCompaniesState(state));
  };

  const get = async (id: Company['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyActions.getAll({}));

  const post = async (data: Omit<Company, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyActions.post(data));

  const put = async (data: Partial<Company>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyActions.put(data));

  const remove = async (id: Company['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(companyActions.remove(id));

  return {
    resetCompanyState,
    companiesState,
    setCompanyState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useCompanies;
