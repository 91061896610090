import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Hmo from '../../../../store/constants/hmo/hmo.interface';
import useHmo from '../../../../hooks/hmo/useHmo';
import toastNotification from '../../../../hooks/utils/toastNotification';

export interface HmoFormModalProps {
  hmo?: Hmo;
  onClose: () => void;
}

const HmoFormModal: React.FC<HmoFormModalProps> = ({ hmo = undefined, onClose }) => {
  const [hmoForm, setHmoForm] = useState<Hmo | undefined>();
  const [open, setOpen] = React.useState(false);
  const { post, put } = useHmo();

  const handleClose = () => {
    setHmoForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setHmoForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<Hmo>) => {
    if (hmoForm) {
      await toastNotification({
        action: put({
          ...hmoForm,
          ...values,
        } as Partial<Hmo>),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Hmo information is successfully updated.',
          error: 'Error on updating Hmo information.',
        },
      });
    } else {
      await toastNotification({
        action: post(values as Hmo),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Hmo information is successfully added.',
          error: 'Error on adding Hmo information.',
        },
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    if (hmo) {
      setOpen(true);
      setHmoForm(hmo);
    }
  }, [hmo]);

  return (
    <div>
      <Dialog
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-12 md-modal-size"
      >
        <div className="m-2 p-0 absolute flex top-0 right-0">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="h-5 w-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512">
            <path
              fill="white"
              d="M96 352V96c0-35.3 28.7-64 64-64H416c35.3 0 64 28.7 64 64V293.5c0 17-6.7 33.3-18.7 45.3l-58.5 58.5c-12 12-28.3 18.7-45.3 18.7H160c-35.3 0-64-28.7-64-64zM272 128c-8.8 0-16 7.2-16 16v48H208c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V256h48c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H320V144c0-8.8-7.2-16-16-16H272zm24 336c13.3 0 24 10.7 24 24s-10.7 24-24 24H136C60.9 512 0 451.1 0 376V152c0-13.3 10.7-24 24-24s24 10.7 24 24l0 224c0 48.6 39.4 88 88 88H296z"
            />
          </svg>

          <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
            Update Hmo Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{
                  ...hmoForm,
                  accountNumber: hmoForm?.accountNumber ?? '',
                  name: `${hmoForm?.userInfo?.lastName}, ${hmoForm?.userInfo?.firstName}` ?? '',
                  isPrincipal: hmoForm?.isPrincipal ?? false,
                  companyPaid: hmoForm?.companyPaid ?? false,
                  effectiveDate: hmoForm?.effectiveDate ?? new Date(),
                  expiryDate: hmoForm?.expiryDate ?? new Date(),
                }}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form className="">
                    <div className="mt-4 mb-3 form-section flex flex-col gap-y-4">
                      <div>
                        <label htmlFor="name" className="modal-labels block mb-2 text-gray-200">
                          Name
                        </label>
                        <div className="">
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Account Number "
                            className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                            value={`${formik.values.userInfo?.lastName}, ${formik.values.userInfo?.firstName}`}
                            disabled
                          />
                          <ErrorMessage name="name" component="div" />
                        </div>
                      </div>
                      <div className="flex justify-between gap-4">
                        <div className="first-section w-full">
                          <div className="mb-6">
                            <label htmlFor="name" className="modal-labels block mb-2 text-gray-200">
                              Account Number
                            </label>
                            <div className="">
                              <Field
                                type="text"
                                id="accountNumber"
                                name="accountNumber"
                                placeholder="Account Number "
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              />
                              <ErrorMessage name="accountNumber" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label htmlFor="plan" className="modal-labels block mb-2 text-gray-200">
                              Plan <span className="text-[#f79220]">(SP, RP, LP)</span>
                            </label>
                            <div className="">
                              <Field
                                type="email"
                                id="plan"
                                name="plan"
                                disabled
                                placeholder="Plan"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              />
                              <ErrorMessage name="plan" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="isPrincipal"
                              className="relative inline-flex items-center mr-5 cursor-pointer"
                            >
                              {/* <input type="checkbox" value="" className="sr-only peer" checked /> */}
                              <Field
                                type="checkbox"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200 sr-only peer"
                                id="isPrincipal"
                                name="isPrincipal"
                                checked={formik.values.isPrincipal}
                              />
                              <div className="w-11 h-6 bg-gray-500 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-[#f79220] dark:peer-focus:ring-[#f05825] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#f05825]" />
                              <span className="ml-3 text-sm font-medium  dark:text-gray-300 text-white">
                                Principal
                              </span>
                              <ErrorMessage name="isPrincipal" component="div" />
                            </label>
                          </div>
                        </div>

                        <div className="second-section w-full">
                          <div className="mb-6">
                            <label
                              htmlFor="effectiveDate"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Date Start
                            </label>
                            <div className="">
                              <Field
                                type="date"
                                id="effectiveDate"
                                name="effectiveDate"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              />
                              <ErrorMessage name="effectiveDate" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="expiryDate"
                              className="modal-labels block mb-2 text-gray-200"
                            >
                              Date End
                            </label>
                            <div className="">
                              <Field
                                type="date"
                                id="expiryDate"
                                name="expiryDate"
                                placeholder="expiryDate"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200"
                              />
                              <ErrorMessage name="expiryDate" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="companyPaid"
                              className="relative inline-flex items-center mr-5 cursor-pointer"
                            >
                              {/* <input type="checkbox" value="" className="sr-only peer" checked /> */}
                              <Field
                                type="checkbox"
                                className="modal-fields w-full bg-[#343434] border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 text-gray-200 sr-only peer"
                                id="companyPaid"
                                name="companyPaid"
                                checked={formik.values.companyPaid}
                              />
                              <div className="w-11 h-6 bg-gray-500 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-[#f79220] dark:peer-focus:ring-[#f05825] peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#f05825]" />
                              <span className="ml-3 text-sm font-medium  dark:text-gray-300 text-white">
                                Company Paid
                              </span>
                              <ErrorMessage name="isPrincipal" component="div" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-2 w-max">
                      <div className="flex justify-center w-max">
                        <button
                          type="submit"
                          className="modal-buttons p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="flex justify-center w-full">
                        <button
                          type="button"
                          onClick={() => handleClose()}
                          className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default HmoFormModal;
