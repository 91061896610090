import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import TextWithIcons from '../../../molecules/TextWithIcons';
import consoleLog from '../../../../utils/consoleLog';
import PositionRateListRow from './PositionRateListRow';
import EmploymentPositionRate from '../../../../store/constants/employments/positions/employment-position-rate.interface';
import usePositionRates from '../../../../hooks/positions/usePositionRates';
import SearchSvg from '../../../../assets/images/icons/search.svg';
import PositionRateModal from './PositionRateModal';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow-with-tail-down.svg';
import useOnLoad from '../../../../hooks/utils/useOnLoad';

type SortByHeaderOptions = '' | 'position' | 'gross' | 'minGross' | 'maxGross';

const PositionRateList: React.FC = () => {
  const { positionRatesState, getAll: getAllPositionRates, remove } = usePositionRates();

  const [selectedPositionRates, setSelectedPositionRates] = useState<
    EmploymentPositionRate | undefined
  >();

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const positionRates = positionRatesState.positionRates
    .filter((positionRate: EmploymentPositionRate) => {
      // Check if position object and its name property are defined
      if (positionRate && positionRate.employmentPosition) {
        const positionName = positionRate.employmentPosition.name.toLowerCase();
        const searchValues = debouncedSearchValue.toLowerCase();

        return positionName.startsWith(searchValues);
      }

      return false; // Return false if position or position.name is undefined
    })
    .sort((a: EmploymentPositionRate, b: EmploymentPositionRate) => {
      const sortOrder = sortOrderBy ? -1 : 1;
      const defaultOrder = b.id - a.id;

      if (sortOption === 'position') {
        if (!a.employmentPosition) return -sortOrder;
        if (!b.employmentPosition) return sortOrder;

        return sortOrder * a.employmentPosition.name.localeCompare(b.employmentPosition.name);
      }

      if (sortOption === 'gross') {
        return sortOrder * a.gross - b.gross;
      }

      if (sortOption === 'maxGross') {
        return sortOrder * a.maxGross - b.maxGross;
      }

      return defaultOrder;
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (positionRate: EmploymentPositionRate) => {
    setSelectedPositionRates(positionRate);
  };

  const onDeleteClick = async (positionRate: EmploymentPositionRate) => {
    remove(positionRate.id).then((r) => {
      consoleLog('DELETE POSITION', r);
    });
  };

  const onPositionRatesModalClose = () => setSelectedPositionRates(undefined);

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAllPositionRates();
  });

  return (
    <div className="organisms-positions-list">
      <div className="flex justify-between pb-5 space-x-10">
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search position rate"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Position"
          />
        </div>
        <PositionRateModal onClose={onPositionRatesModalClose} positions={selectedPositionRates} />
      </div>
      <div className="position-rates-list-header table-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg">
        <button
          type="button"
          onClick={() => sortByHeader('position', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Position</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'position' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'position' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>

        <button
          type="button"
          onClick={() => sortByHeader('gross', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Gross</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'gross' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'gross' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('minGross', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Minimum Gross</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'minGross' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'minGross' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('maxGross', true)}
          className="flex items-center gap-3"
        >
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Maximum Gross</div>
          </TextWithIcons>
          {sortOrderBy ? (
            <Arrow className={`sort-arrow-icon ${sortOption === 'maxGross' ? '' : 'hidden'}`} />
          ) : (
            <Arrow
              className={`sort-arrow-icon ${
                sortOption === 'maxGross' ? 'sort-arrow-rotate-icon' : 'hidden'
              }`}
            />
          )}
        </button>

        <TextWithIcons variant="h6">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={positionRates.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <PositionRateListRow
            data={positionRates[index]}
            className={index % 2 === 0 ? 'stripped' : ''}
            style={style}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default PositionRateList;
