import React, { ReactNode } from 'react';

export interface CardProps {
  className?: string;
  children: ReactNode;
}

const Card: React.FC<CardProps> = ({ className = undefined, children }) => (
  <div className={className}>{children}</div>
);

export default Card;
