import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import moment from 'moment';
import CalendarHandleCLickEvents from './CalendarHandleCLickEvents';
import CalendarModal from './CalendarModal';
import EventContentHandler from './EventContentHandler';
import CalendarHeightHandler from './CalendarHeightHandler';
import useCalendarEvents from '../../../hooks/calendar/useCalendarEvents';
import useCalendarApiEvents from '../../../hooks/calendar/useCalendarApiEvents';
import Spinner from '../../atoms/Spinner';
import { ReactComponent as LeftIcon } from '../../../assets/images/icons/chevron-left.svg';
import { ReactComponent as RightIcon } from '../../../assets/images/icons/chevron-right.svg';
import {
  CustomDatesSetArg,
  ModalContent,
  TransformedEvent,
} from '../../../store/constants/calendar/calendar.interface';

const Calendar: React.FC = () => {
  const { calendarEvents } = useCalendarEvents();
  const { getAllCalendarEvents, setCurrentMonthView, calendarsState } = useCalendarApiEvents();
  const calendarHeight = CalendarHeightHandler();
  const calendarRef = useRef<FullCalendar>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>({
    attendanceRanges: [],
    leaveApproved: '',
    leavePending: '',
    leaveDenied: '',
    attendance: '',
    undertime: '',
    noEvents: '',
    overtime: [],
    holiday: [],
    absent: '',
    isLate: false,
  });
  const [calendarStart, setCalendarStart] = useState<Date | undefined>(undefined);
  const [currentMonth, setCurrentMonth] = useState<string>('');
  const [showTodayButton, setShowTodayButton] = useState(false);
  const [calendarReady, setCalendarReady] = useState(false);

  const filterStartDateEntries = calendarEvents.map((event) => event as TransformedEvent);

  const handleDayClick = (dayInfo: DateClickArg) =>
    CalendarHandleCLickEvents(dayInfo, filterStartDateEntries, setModalContent, setModalOpen);

  const handleCloseModal = () => setModalOpen((prevOpen) => !prevOpen);

  const handleDatesSet = (arg: CustomDatesSetArg) => {
    const newCurrentStart = arg.view.currentStart;
    setCalendarStart(newCurrentStart);
    setCurrentMonth(arg.view.title);
    setModalOpen(false);
    setCurrentMonthView(arg.view.title);
  };

  const handleNavigation = (actionButton: 'prev' | 'next' | 'today') => {
    if (!isLoading && calendarRef.current) {
      setIsLoading(true);
      const calendarApi = calendarRef.current.getApi();
      const actionMap: { [key: string]: () => void } = {
        prev: () => calendarApi.prev(),
        next: () => calendarApi.next(),
        today: () => calendarApi.today(),
      };
      actionMap[actionButton]();

      const isCurrentMonth = moment(calendarApi.getDate()).isSame(new Date(), 'month');
      setShowTodayButton(actionButton !== 'today' && !isCurrentMonth);
    }
  };

  useEffect(() => {
    if (isLoading && calendarStart) {
      const startDate = moment(calendarStart).startOf('month').format('YYYY-MM-DD');
      const endDate = moment(calendarStart).endOf('month').format('YYYY-MM-DD');
      getAllCalendarEvents({ startDate, endDate }).finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line
  }, [calendarStart, isLoading]);

  useEffect(() => {
    if (calendarsState.currentMonthView && calendarRef.current && !isLoading) {
      const calendarApi = calendarRef.current.getApi();
      const date = moment(calendarsState.currentMonthView, 'MMMM YYYY').toDate();
      calendarApi.gotoDate(date); // Go to the stored month view
      setShowTodayButton(!moment().isSame(date, 'month'));
      setCalendarReady(true);
    }
    // eslint-disable-next-line
  }, [calendarsState.currentMonthView]);

  return (
    <div className="fullcalendar__container">
      <div className="w-full px-2 py-3 flex justify-between items-center fullcalendar__header">
        {calendarReady ? (
          <div className="flex items-center fullcalendar__month">{currentMonth}</div>
        ) : (
          <div />
        )}
        {/* today */}
        {showTodayButton && (
          <div className="flex items-center fullcalendar__today-wrapper">
            <button
              className={`shadow-md fullcalendar__button fullcalendar__button--today ${
                isLoading && 'fullcalendar__button--disabled'
              }`}
              type="button"
              disabled={isLoading}
              onClick={() => handleNavigation('today')}
            >
              Today
            </button>
          </div>
        )}
        <div className="flex items-center gap-2 fullcalendar__controls">
          {/* previous */}
          <button
            className="fullcalendar__button fullcalendar__button--prev"
            type="button"
            disabled={isLoading}
            onClick={() => handleNavigation('prev')}
          >
            <LeftIcon className="w-6 h-auto" />
          </button>
          {/* next */}
          <button
            className="fullcalendar__button fullcalendar__button--next"
            type="button"
            disabled={isLoading}
            onClick={() => handleNavigation('next')}
          >
            <RightIcon className="w-6 h-auto" />
          </button>
        </div>
      </div>
      <div
        className={`fullcalendar__content ${
          isLoading ? 'fullcalendar__content--hidden' : 'fullcalendar__content--visible'
        }`}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={filterStartDateEntries}
          showNonCurrentDates
          height={calendarHeight}
          fixedWeekCount={false}
          dateClick={handleDayClick}
          datesSet={handleDatesSet}
          eventContent={EventContentHandler}
          dayMaxEvents={4}
          headerToolbar={false}
        />
      </div>
      {isLoading && (
        <div className="fullcalendar__loader-wrapper">
          <Spinner height="6rem" />
        </div>
      )}
      {modalOpen && (
        <CalendarModal content={modalContent} onClose={handleCloseModal} isVisible={modalOpen} />
      )}
    </div>
  );
};

export default Calendar;
