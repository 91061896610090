import React from 'react';
import BillingTitlesTable from './titles/BillingTitlesTable';
import ManagementHeading from '../../atoms/ManagementHeading';

const BillingTitles = () => (
  <div className="m-2 mr-20">
    <div className="m-2 title">
      <ManagementHeading title="Billing" subtitle="Titles" />
    </div>
    <div className="">
      <BillingTitlesTable />
    </div>
  </div>
);

export default BillingTitles;
