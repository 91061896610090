import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import HmoDependentType from '../../constants/my/hmo-dependent.interface';
import hmoDependentServices, {
  GetAllHmoDependentsQueryParams,
} from '../../services/my/hmoDependentServices';

const get = createAsyncThunk(
  'hmoDependent/get',
  async (
    id: HmoDependentType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoDependentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoDependentServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'hmoDependent/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllHmoDependentsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<HmoDependentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoDependentServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'hmoDependent/post',
  async (
    data: Omit<HmoDependentType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoDependentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoDependentServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'hmoDependent/put',
  async (
    data: AtLeast<HmoDependentType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoDependentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoDependentServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'hmoDependent/remove',
  async (
    id: HmoDependentType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoDependentType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoDependentServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
