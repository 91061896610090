import React, { useState, useMemo, useEffect } from 'react';
import { Table, ConfigProvider } from 'antd';
import { Spinner } from '@material-tailwind/react';
import type { SwitchStates } from '../PayrollToolOptions';
import getCreatePayslipColumns from './PayrollCreatePayslipTableColumns';
import PayrollTableContextMenu from './PayrollTableContextMenu';
import { ReactComponent as Money } from '../../../../../assets/images/icons/money.svg';
import usePayrolls from '../../../../../hooks/payroll/usePayrolls';
import useOnLoad from '../../../../../hooks/utils/useOnLoad';
import Payroll from '../../../../../store/constants/payroll/payroll.interface';
import toastNotification from '../../../../../hooks/utils/toastNotification';
import useLoading from '../../../../../hooks/utils/useLoading';

interface PayrollEmployeeListTableProps {
  switchStates?: SwitchStates;
}

const toastMessages = {
  pending: 'Calculating payroll...',
  success: 'Payroll is successfully created!',
  error: 'There was an error creating the payroll.',
};

const PayrollCreatePayslipTable: React.FC<PayrollEmployeeListTableProps> = ({
  switchStates = {},
}) => {
  const { isLoading, setLoading } = useLoading();
  const { payrollsState, createPayslip, setPayrollState } = usePayrolls();
  const [visible, setVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [selectedRowData, setSelectedRowData] = useState<Partial<Payroll> | null>(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const payrollColumns = useMemo(() => getCreatePayslipColumns(switchStates), [switchStates]);
  const tableData = useMemo(
    () => payrollsState.payrolls.map((payroll) => ({ ...payroll, key: payroll.id })),
    [payrollsState.payrolls],
  ) as Payroll[];

  const titleContent = useMemo(
    () => (
      <div className="flex items-center justify-between px-5">
        <div className="flex items-center gap-2">
          <Money className="w-5 h-auto" />
          <span className="capitalize">Create Payroll</span>
        </div>
        <span>Employee List</span>
      </div>
    ),
    [],
  );

  const handleMenuClose = () => setVisible(false);

  const handleContextMenu = (e: React.MouseEvent, rowData: Partial<Payroll>) => {
    if (e.button === 2) {
      e.preventDefault();
      setVisible(true);
      setMenuPosition({ top: e.clientY, left: e.clientX });
      setSelectedRowData(rowData);
    }
  };

  const handleOnSave = async () => {
    setLoading(true);
    const employeeNumbers = tableData
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .map(({ id }) => id);

    if (employeeNumbers && employeeNumbers.length > 1) {
      // eslint-disable-next-line
      const isConfirmed = window.confirm(
        `You are about to save ${employeeNumbers.length} payrolls from different employees. Confirm?`,
      );
      if (!isConfirmed) {
        setLoading(false);
        return;
      }
    }

    await toastNotification({
      action: createPayslip({
        payPeriod: payrollsState.payrollParams.payPeriod,
        employeeNumbers,
      }),
      onSuccess: () => {
        const updatedPayrolls = payrollsState.payrolls.filter(
          (payroll) => !employeeNumbers.includes(payroll.id),
        );
        setPayrollState({ ...payrollsState, payrolls: updatedPayrolls });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },

      toastPromiseMessages: {
        ...toastMessages,
        success:
          employeeNumbers.length > 1
            ? 'Payrolls are successfully created!'
            : 'Payroll is successfully created!',
      },
    });
  };

  const showTotal = (totalCount: number, range: [number, number]) => (
    <div className="ant-custom-show-total flex gap-3">
      {isLastPage && (
        <button
          type="button"
          className="textButton"
          onClick={handleOnSave}
          style={{ marginLeft: '10px' }}
        >
          Save
        </button>
      )}
      {`Displaying ${range[0]}-${range[1]} of ${totalCount} items`}
    </div>
  );

  useEffect(() => {
    setLoading(true);
    const totalNumberOfPages = Math.ceil(tableData.length / pageSize);
    setIsLastPage(currentPage === totalNumberOfPages);
    setLoading(false);
    // eslint-disable-next-line
  }, [tableData, pageSize]);

  useEffect(() => {
    if (tableData.length > 0) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [tableData]);

  useOnLoad(() => {
    setLoading(false);
    window.addEventListener('click', handleMenuClose);
    return () => {
      window.removeEventListener('click', handleMenuClose);
    };
  });

  return (
    <div className="px-2 payroll-container-parent">
      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '#3e3e3e !important',
            },
          },
        }}
        renderEmpty={() => (
          <div className="flex items-center justify-center h-40">
            <span className="text-md text-white">No payslip data.</span>
          </div>
        )}
      >
        <Table
          loading={{
            spinning: isLoading.isLoading,
            indicator: <Spinner height="1rem" color="deep-orange" />,
          }}
          className="payroll-table-content"
          onRow={(rowData: Partial<Payroll>) => ({
            onContextMenu: (event) => {
              event.preventDefault();
              handleContextMenu(event, rowData);
            },
          })}
          columns={payrollColumns}
          dataSource={tableData}
          size="small"
          tableLayout="auto"
          scroll={{ x: 'max-content', y: 'calc(100vh - 24rem)' }}
          title={() => titleContent}
          pagination={{
            position: ['bottomRight'],
            defaultPageSize: 10,
            showTotal,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50'],
            onChange: (page, newPageSize) => {
              setPageSize(newPageSize);
              setIsLastPage(page === Math.ceil(tableData.length / newPageSize));

              if (page > currentPage) {
                handleOnSave();
              }
              setCurrentPage(page);
            },
          }}
        />
      </ConfigProvider>
      {visible && (
        <div
          className="context-menu"
          style={{
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
            position: 'fixed',
            zIndex: 9999,
          }}
        >
          <PayrollTableContextMenu
            handleMenuClose={handleMenuClose}
            record={selectedRowData as Payroll}
          />
        </div>
      )}
    </div>
  );
};

export default PayrollCreatePayslipTable;
