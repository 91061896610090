import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import CompanyBranch from '../../../../store/constants/companies/branches/company-branch.interface';
import DeleteConfirmationBanner from '../../../molecules/DeleteConfirmationBanner';
import PopOverFeature from '../../../atoms/PopOverFeature';
import { ReactComponent as Edit } from '../../../../assets/images/icons/edit.svg';

export interface CompanyBranchListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: CompanyBranch;
  getDateTime: (date: Date) => string;
  onEditClick: (branch: CompanyBranch) => void;
  onDeleteClick: (branch: CompanyBranch) => Promise<unknown>;
}

const CompanyBranchListRow: React.FC<CompanyBranchListRowProps> = ({
  className = '',
  data,
  getDateTime,
  onEditClick,
  onDeleteClick,
  ...props
}: CompanyBranchListRowProps) => {
  const deleteMessage = {
    pending: 'Pending',
    success: `Company Branch ${data.company.name} ${data.name} is successfully deleted.`,
    error: `Error on deleting ${data.company.name} Company Branch information.`,
  };

  const sendEmail = () => {
    const emailLink = document.createElement('a');
    emailLink.href = `mailto:${data.emailDistro}`;
    emailLink.target = '_blank';
    emailLink.click();
  };

  const officeLength = data.companyBranchOffices.length;
  const officeName = data.companyBranchOffices.map((office) => office.name);

  return (
    <div {...props} className={`${className} companyBranch-list-data table-list-data`}>
      <div className="hidden md:block">{data.company?.name}</div>
      <div>{data.name}</div>
      <div>{data.contact}</div>
      <div>
        <button type="button" onClick={() => sendEmail()}>
          {data.emailDistro}
        </button>
      </div>
      <div>
        <PopOverFeature
          buttonName={officeLength}
          contentData={officeName}
          className="flex space-x-2 text-gray-300"
        />
      </div>
      <div className="hidden lg:block">{data.dateCreated && getDateTime(data.dateCreated)}</div>
      <div className="space-x-6 action-buttons justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <Edit className="w-5 h-5" />
          </button>
        </Tooltip>

        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default CompanyBranchListRow;
