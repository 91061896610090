import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DateRangePicker from 'react-datepicker';
import { VariableSizeList } from 'react-window';
import { ErrorMessage, Form, Formik } from 'formik';
import { Spinner } from '@material-tailwind/react';
import TextWithIcons from '../../../molecules/TextWithIcons';
import consoleLog from '../../../../utils/consoleLog';
import EmptyListMessage from '../../../atoms/EmptyListMessage';
import OvertimePageListRow from './OvertimePageListRow';
import Team from '../../../../store/constants/teams/team.interface';
import SelectTeam from '../../../atoms/dropdowns/SelectTeam';
import SelectEmployee from '../../../atoms/dropdowns/SelectEmployee';
import UserEmployment from '../../../../store/constants/employees/employee.interface';
import SelectOvertimeType from '../../../atoms/dropdowns/SelectOvertimeType';
import EmployeeOvertimeType from '../../../../store/constants/employees/attendances/employee-overtime-type.interface';
import EmployeeOvertime from '../../../../store/constants/employees/attendances/employee-overtime.interface';
import OvertimeEditForm from './OvertimeEditForm';
import OvertimeApproveModal from './OvertimeApproveModal';
import OvertimeNewTeamForm from './OvertimeNewTeamForm';
import OvertimeNewEmployeeForm from './OvertimeNewEmployeeForm';
import OvertimeEndEmployeeOvertime from './OvertimeEndEmployeeOvertime';
import useDateTime from '../../../../hooks/utils/useDateTime';
import useOvertimes from '../../../../hooks/attendances/useOvertimes';
import SelectStatusOptions, { StatusOption } from '../../../atoms/dropdowns/SelectStatusOptions';
import { ReactComponent as Export } from '../../../../assets/images/icons/upload-file.svg';
import { ReactComponent as ThreeUsers } from '../../../../assets/images/icons/user-three.svg';
import { ReactComponent as Plus } from '../../../../assets/images/icons/plus.svg';

interface OvertimeFilterState {
  selectedTeam: Team | undefined;
  selectedOvertimeType: EmployeeOvertimeType | undefined;
  selectedUserEmployment: UserEmployment | undefined;
  selectedCreatedByEmployee: UserEmployment | undefined;
  selectedStatus: StatusOption | undefined;
  selectedEmployeeOvertime: EmployeeOvertime | undefined;
  selectedEmployeeEndOvertime: EmployeeOvertime | undefined;
  selectedEditOvertime: EmployeeOvertime | undefined;
  openNewTeamOvertime: boolean;
  openNewEmployeeOvertime: boolean;
  isLoading: boolean;
}

const OvertimePageList: React.FC = () => {
  const { overtimeState, setOvertimeState, getAllOvertimes, remove } = useOvertimes();

  const { getDate } = useDateTime();
  const [overtimeFilterState, setOvertimeFilterState] = useState<OvertimeFilterState>({
    selectedTeam: undefined,
    selectedOvertimeType: undefined,
    selectedUserEmployment: undefined,
    selectedCreatedByEmployee: undefined,
    selectedStatus: { value: 'Pending', label: 'Pending' },
    selectedEmployeeOvertime: undefined,
    selectedEmployeeEndOvertime: undefined,
    selectedEditOvertime: undefined,
    openNewTeamOvertime: false,
    openNewEmployeeOvertime: false,
    isLoading: false,
  });

  const {
    selectedTeam,
    selectedOvertimeType,
    selectedUserEmployment,
    selectedCreatedByEmployee,
    selectedStatus,
    selectedEmployeeOvertime,
    selectedEmployeeEndOvertime,
    selectedEditOvertime,
    openNewTeamOvertime,
    openNewEmployeeOvertime,
    isLoading,
  } = overtimeFilterState;

  const getFirstDayOfCurrentMonth = () => moment().startOf('month').toDate();
  const getLastDayOfNextMonth = () => moment().add(0, 'months').endOf('month').toDate();

  const defaultStartDate = getFirstDayOfCurrentMonth();
  const defaultEndDate = getLastDayOfNextMonth();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [dateStart, dateEnd] = dateRange;

  const onGenerateClick = async () => {
    consoleLog('Generate Click');
  };

  const approveOvertime = (overtime: EmployeeOvertime) => {
    setOvertimeFilterState({ ...overtimeFilterState, selectedEmployeeOvertime: overtime });
  };

  const endOvertime = (overtime: EmployeeOvertime) => {
    setOvertimeFilterState({ ...overtimeFilterState, selectedEmployeeEndOvertime: overtime });
  };

  const endOvertimeClose = () => {
    setOvertimeFilterState({ ...overtimeFilterState, selectedEmployeeEndOvertime: undefined });
  };

  const editOvertime = (overtime: EmployeeOvertime) => {
    setOvertimeFilterState({ ...overtimeFilterState, selectedEditOvertime: overtime });
  };

  const onOvertimeApproveClose = () => {
    setOvertimeFilterState({ ...overtimeFilterState, selectedEmployeeOvertime: undefined });
  };

  const onOvertimeFormEditClose = () => {
    setOvertimeFilterState({ ...overtimeFilterState, selectedEditOvertime: undefined });
  };

  const handleToggleNewTeamOvertime = () => {
    setOvertimeFilterState({ ...overtimeFilterState, openNewTeamOvertime: !openNewTeamOvertime });
  };

  const handleToggleNewEmployeeOvertime = () => {
    setOvertimeFilterState({
      ...overtimeFilterState,
      openNewEmployeeOvertime: !openNewEmployeeOvertime,
    });
  };

  const onDeleteClick = async (overtime: EmployeeOvertime) => {
    remove(overtime.id).then((data) => {
      consoleLog('DELETED EMPLOYEE_OVERTIME', data);
    });
  };

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    const itemHeight = 50;
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  const getAllOvertime = overtimeState.overtimes
    .filter((overtime: EmployeeOvertime) => !!overtime)
    .sort((a, b) => {
      const dateA = new Date(a.dateCreated || 0);
      const dateB = new Date(b.dateCreated || 0);
      return dateB.getTime() - dateA.getTime();
    });

  useEffect(() => {
    setOvertimeFilterState({ ...overtimeFilterState, isLoading: true });
    setOvertimeState({ overtimes: [] });
    const formattedStartDate = dateStart ? getDate(dateStart) : undefined;
    const formattedEndDate = dateEnd ? getDate(dateEnd) : undefined;

    getAllOvertimes({
      teamId: selectedTeam?.id,
      employeeNumber: selectedUserEmployment?.employeeNumber,
      status: selectedStatus?.value,
      ...(formattedEndDate && {
        duration: {
          dateStart: formattedStartDate,
          dateEnd: formattedEndDate,
        },
      }),
    }).finally(() => setOvertimeFilterState({ ...overtimeFilterState, isLoading: false }));
    // eslint-disable-next-line
  }, [selectedTeam, selectedUserEmployment, selectedStatus, dateStart, dateEnd]);

  return (
    <div className="w-full h-full organisms-overtime-list pr-5">
      <div className="flex justify-center pb-5">
        {/* Modals */}
        <OvertimeApproveModal
          overtime={selectedEmployeeOvertime as EmployeeOvertime}
          onClose={onOvertimeApproveClose}
        />
        <OvertimeEndEmployeeOvertime
          overtime={selectedEmployeeEndOvertime as EmployeeOvertime}
          onClose={endOvertimeClose}
        />
        <OvertimeEditForm
          overtime={selectedEditOvertime as EmployeeOvertime}
          onClose={onOvertimeFormEditClose}
        />
        <OvertimeNewTeamForm
          manageOpenModal={openNewTeamOvertime}
          handleCloseModal={handleToggleNewTeamOvertime}
        />
        <OvertimeNewEmployeeForm
          manageOpenModal={openNewEmployeeOvertime}
          handleCloseModal={handleToggleNewEmployeeOvertime}
        />
        {/* input fields */}
        <div className="gap-5 items-center w-full">
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
              {/* First Content */}
              <div className="mb-4 overtime-content-wrapper">
                {/* Team Search */}
                <div className="w-full overtime-list-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Team search</h2>
                  <div className="w-full team-search-wrapper">
                    <SelectTeam
                      id="team"
                      name="team"
                      value={selectedTeam}
                      onChange={(value) =>
                        setOvertimeFilterState({
                          ...overtimeFilterState,
                          selectedTeam: value as Team,
                        })
                      }
                      hasSearchIcon
                      isSearchable
                      isClearable
                    />
                  </div>
                </div>
                {/* Ot Type */}
                <div className="w-full overtime-list-wrapper">
                  <h2 className="w-fit pl-1 mb-1">OT TYPE</h2>
                  <div className="w-full employee-search-wrapper">
                    <SelectOvertimeType
                      id="otType"
                      name="otType"
                      value={selectedOvertimeType}
                      onChange={(value) =>
                        setOvertimeFilterState({
                          ...overtimeFilterState,
                          selectedOvertimeType: value as EmployeeOvertimeType,
                        })
                      }
                      hasSearchIcon
                      isSearchable
                      isClearable
                    />
                    <ErrorMessage name="otType" component="div" />
                  </div>
                </div>
                {/* Status */}
                <div className="w-full overtime-list-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Status</h2>
                  <div className="w-full status-search-wrapper">
                    <SelectStatusOptions
                      id="status"
                      name="status"
                      className="overtime-status-text"
                      value={selectedStatus as StatusOption}
                      onChange={(value) => {
                        setOvertimeFilterState({
                          ...overtimeFilterState,
                          selectedStatus: value as StatusOption,
                        });
                      }}
                      isClearable
                      hasSearchIcon
                    />
                  </div>
                </div>
              </div>

              {/* second wrapper */}
              <div className="overtime-content-wrapper">
                {/* Date picker */}
                <div className="w-full overtime-date-wrapper">
                  <h2 className="w-fit pl-1 mb-1">Date</h2>
                  <div className="w-full h-fit date-wrapper datePicker-main-parent">
                    <DateRangePicker
                      className="rounded-lg datepicker-list-layout datepicker-list-date"
                      startDate={dateStart}
                      endDate={dateEnd}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      selectsRange
                      isClearable
                      placeholderText="Select Date Range..."
                    />
                  </div>
                </div>
                {/* Created By */}
                <div className="w-full overtime-list-wrapper">
                  <h2 className="w-fit pl-1 mb-1">CREATED BY</h2>
                  <div className="w-full employee-search-wrapper">
                    <SelectEmployee
                      id="createdBy"
                      name="createdBy"
                      placeholder="Select createdBy"
                      value={selectedCreatedByEmployee}
                      onChange={(value) =>
                        setOvertimeFilterState({
                          ...overtimeFilterState,
                          selectedCreatedByEmployee: value as UserEmployment,
                        })
                      }
                      hasSearchIcon
                      isSearchable
                      isClearable
                    />
                    <ErrorMessage name="createdBy" component="div" />
                  </div>
                </div>
                {/* Employee Search */}
                <div className="w-full overtime-list-wrapper">
                  <label htmlFor="employee" className="w-fit pl-1 mb-1 block">
                    Employee search
                  </label>
                  <div className="w-full employee-search-wrapper">
                    <SelectEmployee
                      id="employee"
                      name="employee"
                      placeholder="Select employee"
                      value={selectedUserEmployment}
                      onChange={(value) =>
                        setOvertimeFilterState({
                          ...overtimeFilterState,
                          selectedUserEmployment: value as UserEmployment,
                        })
                      }
                      hasSearchIcon
                      isSearchable
                      isClearable
                    />
                    <ErrorMessage name="employee" component="div" />
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      {/* button actions, container */}
      <div className="w-full flex justify-between items-center overtime-second-list-container">
        <div className="flex gap-1">
          <button className="overtime-list-button" type="button">
            <Export className="w-5 h-5" />
            <span>Export</span>
          </button>
        </div>
        <div className="flex gap-2">
          <button
            className="overtime-list-button"
            onClick={handleToggleNewTeamOvertime}
            type="button"
            disabled
          >
            <ThreeUsers className="w-5 h-5" />
            <span>New Team Overtime</span>
          </button>

          <button
            className="overtime-list-button"
            onClick={handleToggleNewEmployeeOvertime}
            type="button"
          >
            <Plus className="w-5 h-5" />
            <span>New Overtime</span>
          </button>
        </div>
      </div>
      <div className="border-b-2 overtime-list-header header-items overtime-admin-header">
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Employee Name</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">OT Date Shift</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Time in</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Time out</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Duration</div>
        </TextWithIcons>
        <TextWithIcons className="pl-2" variant="h6">
          <div className="flex gap-5">Type</div>
        </TextWithIcons>
        {/* <TextWithIcons variant="h6">
          <div className="flex gap-5">Reviewed by</div>
        </TextWithIcons> */}
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Approved by</div>
        </TextWithIcons>
        <TextWithIcons className="flex justify-center" variant="h6">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>

      {isLoading && (
        <div className="w-full p-5 flex justify-center">
          <Spinner color="deep-orange" className="mr-2" />
        </div>
      )}

      {!isLoading && getAllOvertime.length === 0 ? (
        <EmptyListMessage />
      ) : (
        <VariableSizeList
          itemCount={getAllOvertime.length}
          itemSize={getItemSize}
          height={window.innerHeight - 427}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            <OvertimePageListRow
              data={getAllOvertime[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              onGenerateClick={onGenerateClick}
              approveOvertime={approveOvertime}
              endOvertime={endOvertime}
              editOvertime={editOvertime}
              onDeleteClick={onDeleteClick}
            />
          )}
        </VariableSizeList>
      )}
    </div>
  );
};

export default OvertimePageList;
