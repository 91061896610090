import React, { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import useUsers from '../../../../hooks/users/useUsers';
import consoleLog from '../../../../utils/consoleLog';
import AtLeast from '../../../../types/atleast';
import User from '../../../../store/constants/users/user.interface';
import toastNotification from '../../../../hooks/utils/toastNotification';
import { ReactComponent as PencilIcon } from '../../../../assets/images/icons/pencil.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/icons/check-solid.svg';

interface ContactAddressInfoProps {
  isLoading?: boolean;
  user: User;
}

const ContactAddressInfo: React.FC<ContactAddressInfoProps> = ({ isLoading = true, user }) => {
  const { put } = useUsers();

  const [showButtonAction, setShowButtonAction] = useState(false);
  const [showInputAction, setShowInputAction] = useState(false);

  const handleSubmit = async (data: AtLeast<User, 'contact' | 'address'>) => {
    if (user.id) {
      consoleLog('DATA FROM FORM:', data);

      await toastNotification({
        action: put(data as User),
        onSuccess: () => {
          setShowButtonAction(false);
          setShowInputAction(false);
        },
        onError: () => {
          setShowButtonAction(false);
          setShowInputAction(false);
        },
        onFulfilled: () => {},
        toastPromiseMessages: {
          pending: `Updating contact and address for ${user.lastName} ${user.lastName}...`,
          success: `${user.lastName} ${user.lastName} is successfully updated.`,
          error: `Failed to update contact and address for ${user.lastName} ${user.lastName}! Try again.`,
        },
      });
    }
  };

  const onEditClick = () => {
    setShowButtonAction(!showButtonAction);
    setShowInputAction(!showInputAction);
  };

  const onCancel = () => {
    setShowButtonAction(false);
    setShowInputAction(false);
  };

  return (
    <Formik
      initialValues={{
        id: user.id,
        contact: user.contact,
        address: user.address,
        contactNumber: user.contactNumber,
      }}
      onSubmit={handleSubmit}
    >
      <Form className="rounded-xl shadow-xl user-update__form-wrapper">
        <div className="flex items-center mb-5">
          <div className="user-update__form-heading">
            <Typography variant="h4" className="user-update__form-title">
              Contact & Address
            </Typography>
            <span className="user-update__form-sub-title">Information</span>
          </div>
          {!isLoading && (
            <div className="ml-auto">
              {!showButtonAction ? (
                <Tooltip content="Edit">
                  <IconButton
                    onClick={onEditClick}
                    size="sm"
                    className="bg-primary-gray rounded-none"
                  >
                    <PencilIcon className="w-5 h-5 stroke-white" />
                  </IconButton>
                </Tooltip>
              ) : (
                <div className="flex flex-row-reverse gap-2">
                  <Tooltip content="Cancel">
                    <IconButton
                      onClick={onCancel}
                      size="sm"
                      className="bg-primary-gray rounded-none"
                    >
                      <CloseIcon className="w-5 h-5 stroke-red-900" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Save">
                    <IconButton type="submit" size="sm" className="bg-primary-gray rounded-none">
                      <CheckIcon className="w-5 h-5 stroke-green-800" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-5">
          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="contactNumber" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Contact Number
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contactNumber"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contactNumber || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contactNumber" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="contact.partnerFullName"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Partner&apos;s Full Name
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contact.partnerFullName"
                  name="contact.partnerFullName"
                  placeholder="Partner's Full Name"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contact?.partnerFullName || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contact.partnerFullName" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="contact.motherName"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Mother&apos;s Full Name
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contact.motherName"
                  name="contact.motherName"
                  placeholder="Mother's Full Name"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contact?.motherName || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contact.motherName" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label
              htmlFor="contact.fatherName"
              className="mt-2 mr-8 text-xs uppercase text-gray-600"
            >
              Father&apos;s Full Name
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="contact.fatherName"
                  name="contact.fatherName"
                  placeholder="Father's Full Name"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.contact?.fatherName || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="contact.fatherName" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="address.address" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Address
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  as="textarea"
                  id="address.address"
                  name="address.address"
                  placeholder="Address"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.address?.address || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="address.address" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="district" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              District
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="address.district"
                  name="address.district"
                  placeholder="District"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.address?.district || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="address.district" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="address.city" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              City
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="address.city"
                  name="address.city"
                  placeholder="City"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.address?.city || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="address.city" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="address.country" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Country
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="address.country"
                  name="address.country"
                  placeholder="Country"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.address?.country || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="address.country" component="div" />
          </div>

          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="address.zip" className="mt-2 mr-8 text-xs uppercase text-gray-600">
              Zip
            </label>
            <div className="w-full">
              {showInputAction ? (
                <Field
                  type="text"
                  id="address.zip"
                  name="address.zip"
                  placeholder="Zip"
                  className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                />
              ) : (
                <span className="capitalize ml-5 text-sm text-white">
                  {user.address?.zip || '- -'}
                </span>
              )}
            </div>
            <ErrorMessage name="address.zip" component="div" />
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ContactAddressInfo;
