import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import SearchSvg from '../../../../../assets/images/icons/search.svg';
import PayrollSidebarCompany from './payrollSidebarMenu/PayrollSidebarCompanyTeams';
import usePayrolls from '../../../../../hooks/payroll/usePayrolls';

const PayrollSidebarSearchOnly: React.FC = () => {
  const [open, setOpen] = useState(true);
  const { payrollsState, setPayrollState } = usePayrolls();

  const updatePayrollParams = useDebouncedCallback((newSearchParams: string) => {
    const updatedPayrollParams = {
      ...payrollsState.payrollParams,
      search: newSearchParams,
    };

    setPayrollState({
      ...payrollsState,
      payrollParams: updatedPayrollParams,
    });
  }, 2000);

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newSearchParams = e.currentTarget.value;
      updatePayrollParams(newSearchParams);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePayrollParams(e.currentTarget.value);
  };

  return (
    <div className="flex">
      <div
        className={`payroll-sidebar bg-[#343434] text-white duration-200 ${open ? 'w-80' : 'w-10'}`}
      >
        <div className="shadow-lg p-1 border border-solid border-[#2c2b2a] flex justify-between ">
          <span
            className={`my-auto text-xs text-[#f05825] font-semibold ${open ? 'block' : 'hidden'}`}
          >
            Filters
          </span>
          <button
            type="button"
            onClick={() => setOpen(!open)}
            className="p-1 rounded-md hover:bg-[#2c2b2a]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#f05825"
              className={`w-4 h-4 duration-300 ${open ? 'block' : 'transform -scale-x-100'}`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
              />
            </svg>
          </button>
        </div>
        <div className={`m-3 ${open ? 'hidden' : 'block'}`}>
          <span className="payroll-filter-title text-xs font-semibold text-[#f05825]">Filters</span>
        </div>
        <div className={`relative duration-300 m-1 ${open ? 'block' : 'hidden'}`}>
          <input
            type="text"
            className="text-white rounded-md bg-[#2c2b2a] text-[0.65rem] w-full p-1 py-2 placeholder:text-[0.65rem] search-bar_payroll"
            placeholder="Search Employee"
            onKeyDown={onSearchKeyDown}
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="absolute w-4 h-4 transform -translate-y-1/2 top-1/2 right-2 search-body_payroll"
            src={SearchSvg}
            alt="Search Employee"
          />
        </div>
        <div className={`duration-300 overflow-hidden ${open ? 'block' : 'hidden'}`}>
          <PayrollSidebarCompany />
        </div>
      </div>
    </div>
  );
};

export default PayrollSidebarSearchOnly;
