import { useState } from 'react';
import useCompanyBranches from './useCompanyBranches';
import useOnLoad from '../../utils/useOnLoad';

const useCompanyBranch = (id: string) => {
  const {
    get,
    companyBranchesState: { companyBranches },
  } = useCompanyBranches();
  const [loading, setLoading] = useState(true);

  const companyBranchId = parseInt(id, 10);
  const currentCompanyBranch = companyBranches.find(
    (companyBranch) => companyBranch.id === companyBranchId,
  );

  useOnLoad(() => {
    if (currentCompanyBranch) {
      setLoading(false);
      return;
    }

    setLoading(true);

    get(companyBranchId).then(() => {
      setLoading(false);
    });
  });

  return {
    loading,
    currentCompanyBranch,
  };
};

export default useCompanyBranch;
