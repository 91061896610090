import React from 'react';
import { Link } from 'react-router-dom';

export interface ManageCardProps {
  className?: string;
  heading: string;
  subHeading: string;
  toPath: string;
  icon?: React.ReactNode;
}

const ManageCards: React.FC<ManageCardProps> = ({
  className = '',
  heading,
  toPath,
  icon = undefined,
  subHeading,
}) => (
  <Link to={toPath} className="w-full">
    <div
      className={`bg-[#2c2c2e] p-5 rounded-md shadow-lg ${className} w-full grid grid-cols-1 justify-center items-center hover:opacity-80 cursor-pointer`}
    >
      <div className="flex justify-center items-center mt-2 mb-2">
        <span className="">{icon}</span>
      </div>
      <div className="flex flex-col justify-center items-center gap-2">
        <span className="text-[#f05825] flex gap-2 text-xl font-bold">{heading}</span>
        <span className=" text-white font-thin text-[1rem]">{subHeading}</span>
      </div>
    </div>
  </Link>
);

export default ManageCards;
