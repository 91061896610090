import React from 'react';
import { Typography } from '@material-tailwind/react';
import TabbedContent, { Tab } from '../../molecules/TabbedContent';
import LoanList from './LoanList';
import LoanTypeList from './LoanTypeList';

const tabs: Tab[] = [
  { title: 'Loan List', content: <LoanList /> },
  { title: 'Loan Type', content: <LoanTypeList /> },
];

const AttendancePage: React.FC = () => (
  <div className="w-full flex">
    <div className="flex flex-col w-full">
      <div className="m-2">
        <div className="title">
          <Typography variant="h3" className="text-[#f05825] flex gap-2 management-heading">
            <Typography variant="h3" className="text-gray-200">
              Loan
            </Typography>
            Management
          </Typography>
        </div>
      </div>

      <div className="">
        <TabbedContent tabs={tabs} />
      </div>
    </div>
  </div>
);

export default AttendancePage;
