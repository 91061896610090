import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { VariableSizeList } from 'react-window';
import Department from '../../../store/constants/departments/department.interface';
import useDateTime from '../../../hooks/utils/useDateTime';
import useDepartments from '../../../hooks/departments/useDepartments';
import consoleLog from '../../../utils/consoleLog';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import DepartmentListRow from './DepartmentListRow';
import SearchSvg from '../../../assets/images/icons/search.svg';
import DepartmentFormModal from './DepartmentFormModal';
import SortableTableHeader, { HeaderOption } from '../../molecules/SortableTableHeader';
import useVariableItemSize from '../../../hooks/utils/useVariableItemSize';

type SortByHeaderOptions = '' | keyof Department | 'teamCount';
type CustomTypeDepartment = Department & {
  teamCount?: number;
};

const DepartmentList: React.FC = () => {
  const { departmentsState, getAll, remove } = useDepartments();
  const { getItemSize } = useVariableItemSize();
  const { getDateAsMoment } = useDateTime();
  const { getDateTime } = useDateTime();

  const [searchValue, setSearchValue] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState<Department | undefined>();
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [debouncedSearchValue] = useDebounce(searchValue, 2000);
  const [sortOrderBy, setSortOrderBy] = useState(true);

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const departments = departmentsState.departments
    .filter((department: Department) => {
      const searchValues = debouncedSearchValue.toLowerCase();
      const formattedDate = getDateAsMoment(department.dateCreated)
        .format('MMM DD, YYYY')
        .toLowerCase();

      return (
        department.name.toLowerCase().includes(searchValues) || formattedDate.includes(searchValues)
      );
    })
    .sort((a: Department, b: Department) => {
      const sortOrder = sortOrderBy ? -1 : 1;
      const defaultOrder = b.id - a.id;

      if (sortOption === 'id') {
        return sortOrder * b.id - a.id;
      }

      if (sortOption === 'name') {
        return sortOrder * a.name.localeCompare(b.name);
      }

      if (sortOption === 'dateCreated') {
        return sortOrder * (new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
      }

      return defaultOrder;
    });

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleOnDataChange = () => {
    setSortOrderBy(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (department: Department) => {
    setSelectedDepartment(department);
  };

  const onDeleteClick = async (department: Department) => {
    remove(department.id).then((r) => {
      consoleLog('DELETE_DEPARTMENT', r);
    });
  };

  const onDepartmentModalClose = () => setSelectedDepartment(undefined);

  // Defining the sortable data from department
  const headers: HeaderOption<CustomTypeDepartment>[] = [
    { value: 'id', label: 'ID', defaultOrder: true },
    { value: 'name', label: 'Name' },
    {
      value: 'teamCount',
      label: 'Team Count',
      center: true,
      disable: true,
    },
    {
      value: 'dateCreated',
      label: 'Date Created',
      center: true,
    },
  ];

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full px-5 organisms-depart-list">
      <div className="flex justify-between pb-5">
        <DepartmentFormModal
          department={selectedDepartment}
          onClose={onDepartmentModalClose}
          resetSort={handleOnDataChange}
        />
        <div className="search-body">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search department"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Department"
          />
        </div>
      </div>

      <SortableTableHeader
        classNames="department-list-header bg-[#2a2a2d] border border-solid border-[#2d2d2d] rounded-t-lg"
        sortOption={sortOption}
        sortOrderBy={sortOrderBy}
        headers={headers}
        sortByHeader={sortByHeader}
      />

      <VariableSizeList
        itemCount={departments.length}
        itemSize={getItemSize}
        height={window.innerHeight - 300}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) => (
          <DepartmentListRow
            data={departments[index]}
            className={`${index % 2 === 0 ? 'stripped' : ''}`}
            style={style}
            getDateTime={getDateTime}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        )}
      </VariableSizeList>
    </div>
  );
};

export default DepartmentList;
