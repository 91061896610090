import React from 'react';
import { Typography } from '@material-tailwind/react';
import MyLeaveList from './MyLeaveListHistory';

const MyLeaveHistory: React.FC = () => (
  <div className="w-full h-full flex flex-col leave-history-container">
    <div className="ml-[33rem]">
      <div className="mt-4 ml-2">
        <Typography variant="h3" className="ml-1 mt-2 leave-manangement-title">
          My Leave History
        </Typography>
      </div>
      <div className="organisms-leave-management w-full">
        <MyLeaveList />
      </div>
    </div>
  </div>
);

export default MyLeaveHistory;
