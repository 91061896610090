import moment from 'moment';
import { TransformedEvent } from '../../store/constants/calendar/calendar.interface';
import useCalendarApiEvents from './useCalendarApiEvents';
import EmployeeCalendar from '../../store/constants/calendar/employee-calendar.interface';
import useAttendance from '../attendances/useAttendance';

const useCalendarEvents = () => {
  const { calendarsState } = useCalendarApiEvents();
  const { attendanceState } = useAttendance();
  const calendarData = calendarsState.calendar as unknown as EmployeeCalendar | undefined;
  const currentAttendance = attendanceState.myAttendance;

  const transformCalendar = (calendar: EmployeeCalendar): TransformedEvent[] => {
    // Attendances
    const attendances = (calendar.attendances || [])
      .filter((attendance) => attendance.attendanceTypeId === 2)
      .map((attendance) => {
        let timeIn = '00:00';
        let timeOut = '00:00';

        // Check if currentAttendance matches and has a timeOut
        if (currentAttendance && currentAttendance.id === attendance.id) {
          if (currentAttendance.employeeAttendanceDetails.timeOut) {
            timeIn = moment(currentAttendance.employeeAttendanceDetails.timeIn).format('hh:mm A');
            timeOut = moment(currentAttendance.employeeAttendanceDetails.timeOut).format('hh:mm A');
          }
        }

        // Fallback to calendar data if needed
        if (timeIn === '00:00' && attendance?.employeeAttendanceDetails?.timeIn) {
          timeIn = moment(attendance.employeeAttendanceDetails.timeIn).format('hh:mm A');
        }
        if (timeOut === '00:00' && attendance?.employeeAttendanceDetails?.timeOut) {
          timeOut = moment(attendance.employeeAttendanceDetails.timeOut).format('hh:mm A');
        }

        const attendanceIsLate = attendance?.employeeAttendanceDetails?.isLate || false;

        return {
          title: `A_${attendanceIsLate ? 'Late' : 'Present'}`,
          classNames: 'present' ?? '',
          start: moment(attendance?.dateShift).format('YYYY-MM-DD'),
          extendedProps: {
            timeIn,
            timeOut,
          },
        };
      });
    // Undertimes Events
    const undertimeEvents = (calendar.attendances || [])
      .filter((undertime) => undertime?.employeeAttendanceDetails?.undertime)
      .map((undertime) => ({
        title: 'B_Undertime',
        classNames: 'undertime-event',
        start: moment(undertime?.dateShift).format('YYYY-MM-DD'),
        extendedProps: {
          undertime: moment(undertime?.employeeAttendanceDetails?.timeOut).format('HH:mm:ss'),
        },
      }));
    // Holiday Events
    const holidays = (calendar.holidays || []).map((holiday) => ({
      title: `C_${holiday?.name}`,
      classNames: 'holiday-event',
      start: moment(holiday?.dateCelebrated).format('YYYY-MM-DD'),
      extendedProps: {
        holiday: holiday.name,
      },
    }));
    // Approved Overtimes Event
    const approvedOvertimes = (calendar.overtimes || []).map((overtime) => ({
      title: 'D_Overtime',
      classNames: 'overtime-event',
      start: moment(overtime?.overtimeTimeIn).format('YYYY-MM-DD'),
      extendedProps: {
        timeIn: overtime?.overtimeTimeIn
          ? moment(overtime.overtimeTimeIn).format('hh:mm A')
          : '00:00',
        timeOut: overtime?.overtimeTimeOut
          ? moment(overtime.overtimeTimeOut).format('hh:mm A')
          : '00:00',
      },
    }));
    // Leave Pending
    const leavePending = (calendar.leaveApplications || [])
      .filter((leave) => leave.status.toLowerCase() === 'pending')
      .flatMap((leave) =>
        leave.duration.map((date) => ({
          title: 'E_LeavePending',
          classNames: 'leave-pending',
          start: moment(date).format('YYYY-MM-DD'),
          extendedProps: {
            leavePending: leave.leaveNature?.nature ?? '',
          },
        })),
      );

    // Leave Approved
    const leaveApproved = (calendar.attendances || [])
      .filter((leave) => leave?.attendanceTypeId === 4)
      .map((leave) => ({
        title: 'F_LeaveApproved',
        classNames: 'leave-approved',
        start: moment(leave?.dateShift).format('YYYY-MM-DD'),
        extendedProps: {
          leaveApproved: leave?.leaveApplication?.leaveNature?.nature || '',
        },
      }));
    // leave Deinied
    const leaveDenied = (calendar.leaveApplications || [])
      .filter((leave) => leave.status.toLowerCase() === 'denied')
      .flatMap((leave) =>
        leave.duration.map((date) => ({
          title: 'G_LeaveDenied',
          classNames: 'leave-denied',
          start: moment(date).format('YYYY-MM-DD'),
          extendedProps: {
            leaveDenied: leave.leaveNature?.nature ?? '',
          },
        })),
      );

    return [
      ...attendances,
      ...undertimeEvents,
      ...approvedOvertimes,
      ...holidays,
      ...leavePending,
      ...leaveApproved,
      ...leaveDenied,
    ];
  };

  const calendarEvents = calendarData ? transformCalendar(calendarData) : [];

  return {
    calendarEvents,
  };
};

export default useCalendarEvents;
