import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmployeeOvertime from '../../constants/employees/attendances/employee-overtime.interface';
import Team from '../../constants/teams/team.interface';
import toURLParams from '../../../utils/toURLParams';
import UserEmployment from '../../constants/employees/employee.interface';
import { StatusOption } from '../../../components/atoms/dropdowns/SelectStatusOptions';
import { GetAllPayrollQueryParams } from '../payroll/payrollServices';

export interface ExportOvertimeQueryParams {
  teamId?: GetAllPayrollQueryParams['teamId'];
}

export interface Duration {
  dateStart: string | undefined;
  dateEnd: string | undefined;
}

export interface GetAllOvertimesQueryParams extends ApiGetAllBaseQueryParams {
  teamId?: Team['id'];
  duration?: Duration;
  employeeNumber?: UserEmployment['employeeNumber'];
  status?: StatusOption['value'];
}

const get = async (
  id: EmployeeOvertime['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime>> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>(`attendances/overtimes/${id}`, { state })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<EmployeeOvertime, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>('attendances/overtimes', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: EmployeeOvertime,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>(`attendances/overtimes/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<EmployeeOvertime, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>(`attendances/overtimes/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: EmployeeOvertime['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>(`attendances/overtimes/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllOvertimes = async (
  {
    page = 1,
    limit = 50,
    search = '',
    status = undefined,
    teamId = undefined,
    duration = undefined,
    employeeNumber = undefined,
  }: GetAllOvertimesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmployeeOvertime>> =>
  apiFetch<ApiGetAllBaseResponse<EmployeeOvertime>>(
    `attendances/overtimes?${
      toURLParams({
        limit,
        page,
        search,
        status,
        teamId,
        employeeNumber,
        ...(duration && {
          'date[from]': duration.dateStart,
          'date[to]': duration.dateEnd,
        }),
      }) ?? ''
    }`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const approveEmployeeOvertime = async (
  data: AtLeast<EmployeeOvertime, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>(`attendances/overtimes/${data.id}/approve`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const exportOvertime = async (
  { teamId: team_ids }: ExportOvertimeQueryParams,
  state: StateHeaders,
  // eslint-disable-next-line
): Promise<Promise<ApiBaseResponse<any>>> => {
  const {
    auth: { token },
  } = state;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/attendances/export/overtime?${toURLParams({
        ...(team_ids && { team_ids }),
      })}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ...(token && {
            Authorization: `Bearer ${token}`,
          }),
        },
      },
    );

    if (!response.ok) {
      return {
        data: null,
        status: response.status,
        error: `Error fetching overtime data: ${response.statusText}`,
        messages: {
          error: `Error fetching overtime data: ${response.statusText}`,
        },
      };
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    if (url.startsWith('blob:')) {
      const a = document.createElement('a');
      a.href = url;
      a.download = 'overtime_data.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return {
        data: url,
        status: response.status,
        error: null,
        messages: {
          success: 'File downloaded successfully',
        },
      };
    }
    return {
      data: null,
      status: 400,
      error: 'The URL is not a blob URL, download aborted for security reasons.',
      messages: {
        error: 'The URL is not a blob URL, download aborted for security reasons.',
      },
    };
  } catch (error) {
    // eslint-disable-next-line
    console.error('Error downloading file:', error);
    return {
      data: null,
      status: 500,
      error: error instanceof Error ? error.message : String(error),
      messages: {
        error: error instanceof Error ? error.message : 'An unknown error occurred',
      },
    };
  }
};

export default {
  get,
  post,
  patch,
  put,
  remove,
  getAllOvertimes,
  approveEmployeeOvertime,
  exportOvertime,
} as const;
