import { Typography, TypographyProps } from '@material-tailwind/react';
import React from 'react';

interface IconTextProps extends TypographyProps {
  iconLeft?: string | React.ReactNode;
  iconRight?: string | React.ReactNode;
  children: React.ReactNode;
  name?: string;
  className?: string;
}

const TextWithIcons: React.FC<IconTextProps> = ({
  iconLeft = undefined,
  iconRight = undefined,
  name = undefined,
  children,
  className = undefined,
  ...typographyProps
}) => (
  <div className={`text-with-icons flex items-center gap-5 ${className}`}>
    {typeof iconLeft === 'string' ? <img src={iconLeft} alt={name} /> : iconLeft}

    <Typography variant="paragraph" title={name} {...typographyProps}>
      {children}
    </Typography>
    {typeof iconRight === 'string' ? <img src={iconRight} alt={name} /> : iconRight}
  </div>
);

export default TextWithIcons;
