import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button, Dialog, DialogHeader, DialogBody, IconButton } from '@material-tailwind/react';
import { ReactComponent as Add } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close.svg';
import { ReactComponent as Clock } from '../../../assets/images/icons/clock.svg';
import useSchedules from '../../../hooks/schedule/useSchedules';
import useScheduleTypes from '../../../hooks/schedule/useScheduleTypes';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectEmploymentScheduleType from '../../atoms/dropdowns/SelectEmploymentScheduleType';
import SelectDays from '../../atoms/dropdowns/SelectDays';
import SelectTemporary from '../../atoms/dropdowns/SelectTemporary';
import EmployeeSchedule from '../../../store/constants/employees/schedules/employee-schedule.interface';
import ButtonAction from '../../atoms/buttons/ButtonAction';
import {
  ScheduleDaysOption,
  ScheduleTempOption,
} from '../../../store/reducers/schedules/schedulesReducer';
import toastNotification from '../../../hooks/utils/toastNotification';
import EmploymentScheduleType from '../../../store/constants/employments/schedules/employment-schedule-type.interface';
import Employee from '../../../store/constants/employees/employee.interface';

export interface SchedulerFormProps {
  scheduleData?: EmployeeSchedule;
  className?: string;
  employeeNumber?: Employee['employeeNumber'];
  onClose: () => void;
}

export const calculateScheduledTimeOut = (timeIn: string, scheduleHours: number): string => {
  const [hoursIn, minutesIn] = timeIn.split(':').map(Number);
  const totalMinutes = hoursIn * 60 + minutesIn + scheduleHours * 60;
  const hoursOut = Math.floor(totalMinutes / 60) % 24;
  const minutesOut = totalMinutes % 60;

  return `${hoursOut.toString().padStart(2, '0')}:${minutesOut.toString().padStart(2, '0')}`;
};

const SchedulerTestAddForm: React.FC<SchedulerFormProps> = ({
  scheduleData = undefined,
  className = undefined,
  employeeNumber = undefined,
  onClose,
}) => {
  const [userEmploymentScheduleForm, setUserEmploymentScheduleForm] = useState<
    EmployeeSchedule | undefined
  >();
  const { getAll: getAllScheduleTypes } = useScheduleTypes();
  const { post } = useSchedules();

  const [selectedDays, setSelectedDays] = useState<ScheduleDaysOption>();
  const [selectedTemp, setSelectedTemp] = useState<ScheduleTempOption | undefined>();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setUserEmploymentScheduleForm(undefined);
    setSelectedDays(undefined);
    setOpen(false);
    setSelectedTemp(undefined);
    onClose();
  };

  const handleOpen = () => {
    setUserEmploymentScheduleForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<EmployeeSchedule>) => {
    setLoading(true);

    if (!selectedDays) {
      // @TODO needs improvement
      throw new Error('Please select days');
    }
    const daysToString: string = Object.values(selectedDays)
      .map((item) => item.value)
      .join(',');

    const data = {
      ...values,
      scheduleTypeId: values.scheduleType?.id,
      temp: selectedTemp?.value,
      days: daysToString,
      dateUpdated: new Date(),
    } as unknown as EmployeeSchedule;

    await toastNotification({
      action: post(data),
      onSuccess: () => {
        setLoading(false);
      },
      onError: () => {},
      onFulfilled: () => {
        setLoading(false);
        handleClose();
      },
      toastPromiseMessages: {
        pending: 'Processing',
        success: 'Schedule information is successfully added.',
        error: 'Error on adding Schedule information.',
      },
    });
  };

  useEffect(() => {
    if (scheduleData) {
      setUserEmploymentScheduleForm({
        ...scheduleData,
      });
    }
  }, [scheduleData]);

  useOnLoad(() => {
    getAllScheduleTypes().then();
  });

  return (
    <>
      <Button className={` ${className} muiButton--primary add-button`} onClick={handleOpen}>
        <Add className="w-5 h-5" />
        Add Schedule
      </Button>
      <Dialog size="sm" open={open} handler={handleOpen} className="bg-[#343434] shadow-none p-8">
        <div className="m-2 p-0 absolute flex top-0 right-0">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <Close className="w-5 h-auto" />
          </IconButton>
        </div>
        <div className="flex gap-3">
          <Clock className="w-8 h-auto scheduler-icon" />

          <DialogHeader className="modal-header mt-1 p-0 text-gray-200">
            Enter New Schedule Info
          </DialogHeader>
        </div>
        <DialogBody divider className="mt-1">
          <div className="flex justify-center w-full">
            <Formik
              initialValues={{
                ...userEmploymentScheduleForm,
                employeeNumber,
                break: userEmploymentScheduleForm?.break ?? 0,
                scheduleType: userEmploymentScheduleForm?.scheduleType
                  ? ({
                      ...userEmploymentScheduleForm?.scheduleType,
                      label: userEmploymentScheduleForm?.scheduleType?.name,
                      value: userEmploymentScheduleForm?.scheduleType?.id,
                    } as EmploymentScheduleType)
                  : undefined,
                temporary: userEmploymentScheduleForm?.temp
                  ? ({
                      label: userEmploymentScheduleForm?.temp,
                      value: userEmploymentScheduleForm?.temp,
                    } as ScheduleTempOption)
                  : undefined,
              }}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form className="w-full">
                  <div className="w-full flex justify-center">
                    <div className="scheduler-container ">
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="time-schedule mb-3">
                          <div className="flex mb-2 text-sm font-semibold items-center">
                            <label htmlFor="timeIn_out" className="text-gray-200">
                              Time schedule
                            </label>
                            {formik.values.scheduleType ? (
                              <span className="text-xs ml-2">
                                ( {formik.values.scheduleType?.hours} hours )
                              </span>
                            ) : (
                              <span className="text-xs ml-2">( select a schedule type )</span>
                            )}
                          </div>
                          <div className="flex gap-2 justify-center items-center">
                            <Field
                              type="time"
                              id="timeIn"
                              name="timeIn"
                              placeholder="Time in"
                              className="w-full p-2 border border-gray-600 rounded-md shadow-lg placeholder:text-gray-600 scheduler-field-time"
                              disabled={!formik.values.scheduleType}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                formik.handleChange(e);
                                const timeIn = e.target.value;
                                const scheduleHours = formik.values.scheduleType?.hours ?? 0;
                                const timeOut = calculateScheduledTimeOut(timeIn, scheduleHours); // Implement this function to calculate Time Out
                                formik.setFieldValue('timeOut', timeOut);
                              }}
                            />
                            <ErrorMessage name="timeIn" component="div" />
                            <span>to</span>
                            <Field
                              type="time"
                              id="timeOut"
                              name="timeOut"
                              placeholder="Time out"
                              className="w-full p-2 border border-gray-600 rounded-md shadow-lg placeholder:text-gray-600 scheduler-field-time"
                              disabled
                            />
                            <ErrorMessage name="timeOut" component="div" />
                          </div>
                        </div>

                        <div className="schedule-type w-full mb-3">
                          <label
                            htmlFor="scheduleType"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Schedule type
                          </label>
                          <SelectEmploymentScheduleType
                            id="scheduleType"
                            name="scheduleType"
                            className="w-full text-xs placeholder:text-xs"
                            menuPlacement="auto"
                            value={formik.values.scheduleType}
                            setFieldValue={formik.setFieldValue}
                            isClearable
                            onChange={(value) => {
                              formik.setFieldValue('scheduleType', value);
                              const { timeIn } = formik.values;
                              const scheduleType = value as EmploymentScheduleType;
                              if (timeIn)
                                formik.setFieldValue(
                                  'timeOut',
                                  calculateScheduledTimeOut(timeIn, scheduleType.hours),
                                );
                            }}
                          />
                          <ErrorMessage name="scheduleType" component="div" />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="date-start mb-3">
                          <label
                            htmlFor="dateStart"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Date start
                          </label>
                          <Field
                            type="date"
                            id="dateStart"
                            name="dateStart"
                            placeholder="Date Start"
                            className="w-full p-2 border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 scheduler-field"
                          />
                          <ErrorMessage name="dateStart" component="div" />
                        </div>
                        <div className="date-end mb-3">
                          <label
                            htmlFor="dateEnd"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Date end
                          </label>
                          <Field
                            type="date"
                            id="dateEnd"
                            name="dateEnd"
                            placeholder="Type"
                            className="w-full p-2 border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 scheduler-field"
                          />
                          <ErrorMessage name="dateEnd" component="div" />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className=" temporary mb-3">
                          <label
                            htmlFor="temp"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Temporary
                          </label>

                          <SelectTemporary
                            id="temp"
                            name="temp"
                            className="w-full text-xs placeholder:text-xs"
                            menuPlacement="auto"
                            value={selectedTemp ?? formik.values.temporary}
                            onChange={(value) => setSelectedTemp(value as ScheduleTempOption)}
                            setFieldValue={formik.setFieldValue}
                          />
                          <ErrorMessage name="temp" component="div" />
                        </div>
                        <div className="break mb-3">
                          <label
                            htmlFor="break"
                            className="block mb-2 text-sm font-semibold text-gray-200"
                          >
                            Break
                          </label>
                          <Field
                            type="number"
                            id="break"
                            name="break"
                            className="w-full p-2 border border-gray-600 rounded-md shadow-lg  placeholder:text-gray-600 scheduler-field"
                            required
                          />
                          <ErrorMessage name="break" component="div" />
                        </div>
                      </div>
                      <div className="days mb-3">
                        <label
                          htmlFor="days"
                          className="block mb-2 text-sm font-semibold text-gray-200"
                        >
                          Days
                        </label>
                        <SelectDays
                          id="days"
                          name="days"
                          value={selectedDays}
                          className="w-full text-xs placeholder:text-xs"
                          onChange={(value) => setSelectedDays(value as ScheduleDaysOption)}
                          isMulti
                          required
                          menuPlacement="auto"
                        />
                        <ErrorMessage name="days" component="div" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between w-full mt-5 scheduler-button-wrapper">
                    <div className="flex justify-center w-max">
                      <ButtonAction
                        type="submit"
                        className=" p-2 rounded-md shadow-lg button-submit-scheduler"
                        isLoading={loading}
                        hasSpinnerText={false}
                        isSubmit
                      />
                    </div>
                    <button
                      type="button"
                      onClick={handleClose}
                      className="p-2  rounded-lg shadow-lg button-close-scheduler"
                    >
                      Close
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};

export default SchedulerTestAddForm;
