import React from 'react';
import { ReactComponent as Download } from '../../../../../assets/images/icons/download.svg';
import usePayroll from '../../../../../hooks/payroll/usePayrolls';
import LabelledToggleSwitch from '../../../../molecules/LabelledToggleSwitch';
import type { SwitchStates } from '../PayrollToolOptions';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import useLoading from '../../../../../hooks/utils/useLoading';
import type { ExportAttendanceQueryParams } from '../../../../../store/services/attendances/attendanceServices';
import useOvertimes from '../../../../../hooks/attendances/useOvertimes';
import toastNotification from '../../../../../hooks/utils/toastNotification';

interface OptionsContentProps {
  switchStates: SwitchStates;
  handleSwitchChange: (id: string, checked: boolean) => void;
  teamIds?: ExportAttendanceQueryParams['teamId'];
}

const PayrollCreateExcelExport: React.FC<OptionsContentProps> = ({
  switchStates,
  handleSwitchChange,
  teamIds = [],
}) => {
  const { getAllLatesUndertime } = usePayroll();
  const { exportAttendance } = useAttendance();
  const { exportOvertime } = useOvertimes();
  const { setLoading } = useLoading();

  const exportsAttendance = async () => {
    setLoading(true);

    await toastNotification({
      action: exportAttendance({
        teamId: teamIds,
      }),
      onSuccess: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
      onFulfilled: () => {
        setLoading(false);
      },
      toastPromiseMessages: {
        pending: 'Preparing data for export...',
        success: 'Attendance data is successfully exported!',
        error: {
          render() {
            if (teamIds.length === 0) return 'Please select a team.';

            return 'An error has occurred during exporting.';
          },
        },
      },
    });
  };

  const exportsOvertime = async () => {
    setLoading(true);

    await toastNotification({
      action: exportOvertime({
        teamId: teamIds,
      }),
      onSuccess: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
      onFulfilled: () => {
        setLoading(false);
      },
      toastPromiseMessages: {
        pending: 'Preparing data for export...',
        success: 'Overtime data is successfully exported!',
        error: {
          render() {
            if (teamIds.length === 0) return 'Please select a team.';

            return 'An error has occurred during exporting.';
          },
        },
      },
    });
  };

  return (
    <div className="flex gap-4">
      <div className="grid grid-cols-1 gap-3 group3">
        <div className="flex flex-col gap-1">
          <button
            type="button"
            onClick={exportsAttendance}
            className="payroll-options-export-button"
          >
            <Download className="w-5 h-5" />
            Attendance
          </button>
          <button type="button" onClick={exportsOvertime} className="payroll-options-export-button">
            <Download className="w-5 h-5" />
            Overtime
          </button>
          <button
            type="button"
            onClick={getAllLatesUndertime}
            className="payroll-options-export-button"
          >
            <Download className="w-5 h-5" />
            Lates & Undertime
          </button>
        </div>
        <div className="flex self-end justify-center">
          <span className="text-xs">Excel export</span>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="separator" />
        <div className="grid grid-cols-1 gap-3 group4">
          <div className="flex flex-col gap-5 space-x-5">
            <LabelledToggleSwitch
              id="lastPayIncentives"
              label="Last pay incentives"
              isChecked={switchStates.lastPayIncentives}
              onChange={(checked) => handleSwitchChange('lastPayIncentives', checked)}
            />
          </div>
          <div className="flex self-end justify-center">
            <span className="text-xs">Excel export</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollCreateExcelExport;
