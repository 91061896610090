import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import HolidayType from '../../constants/holidays/holiday-type.interface';

export type GetAllHolidayTypesQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: HolidayType['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<HolidayType>> =>
  apiFetch<ApiBaseResponse<HolidayType>>(`holidays/types/${id}`, { state })
    .then((response: ApiBaseResponse<HolidayType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllHolidayTypesQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<HolidayType>> =>
  apiFetch<ApiGetAllBaseResponse<HolidayType>>(
    `holidays/types?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<HolidayType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<HolidayType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<HolidayType> | void> =>
  apiFetch<ApiBaseResponse<HolidayType>>('holidays/types', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<HolidayType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: HolidayType,
  state: StateHeaders,
): Promise<ApiBaseResponse<HolidayType> | void> =>
  apiFetch<ApiBaseResponse<HolidayType>>(`holidays/types/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<HolidayType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<HolidayType, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<HolidayType> | void> =>
  apiFetch<ApiBaseResponse<HolidayType>>(`holidays/types/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<HolidayType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: HolidayType['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<HolidayType> | void> =>
  apiFetch<ApiBaseResponse<HolidayType>>(`holidays/types/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<HolidayType>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
