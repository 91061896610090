import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface UserSearchState {
  page: number;
  limit: number;
  search?: string;
}

const initialState: UserSearchState = {
  page: 1,
  limit: 50,
  search: '',
};

const userSearchSlice = createSlice({
  name: 'userSearch',
  initialState,
  reducers: {
    resetUserSearchState: () => ({
      ...initialState,
    }),
    setUserSearchState: (state, action: PayloadAction<Partial<UserSearchState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const userSearchReducerActions = userSearchSlice.actions;

export default userSearchSlice.reducer;
