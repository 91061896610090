import React from 'react';
import BillingExportFilters from './reports/BillingExportFilters';
import BillingExportTable from './reports/BillingExportTable';
import ManagementHeading from '../../atoms/ManagementHeading';

const BillingReport = () => (
  <div className="m-2 mr-20">
    <div className="m-2 title">
      <ManagementHeading title="Billing" subtitle="Export" />
    </div>
    <div className="flex flex-col gap-5">
      <BillingExportFilters />
      <BillingExportTable />
    </div>
  </div>
);

export default BillingReport;
