import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../../utils/apiFetch';
import AtLeast from '../../../../types/atleast';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import UserEmploymentSchedules from '../../../constants/employees/schedules/employee-schedule.interface';
import scheduleServices, {
  GetAllSchedulesQueryParams,
} from '../../../services/users/employments/userEmploymentScheduleServices';

const get = createAsyncThunk(
  'userEmploymentSchedule/get',
  async (
    id: UserEmploymentSchedules['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'userEmploymentSchedule/getAll',
  async (
    // @todo
    // accept employee number
    { page = 1, limit = 50, search = '', employeeNumber = undefined }: GetAllSchedulesQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<UserEmploymentSchedules> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleServices.getAll({ page, limit, search, employeeNumber }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'userEmploymentSchedule/post',
  async (
    data: Omit<UserEmploymentSchedules, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'userEmploymentSchedule/put',
  async (
    data: AtLeast<UserEmploymentSchedules, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'userEmploymentSchedule/remove',
  async (
    id: UserEmploymentSchedules['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<UserEmploymentSchedules> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getCurrentSchedule = createAsyncThunk(
  'userEmploymentSchedule/getCurrentSchedule',
  async (
    // @todo
    _,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<UserEmploymentSchedules> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await scheduleServices.getCurrentSchedule(state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove, getCurrentSchedule } as const;
