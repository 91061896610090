import { useAppDispatch, useAppSelector } from '../../store/store';
import positionActions from '../../store/actions/positions/positionActions';
import {
  positionsReducerActions,
  PositionsState,
} from '../../store/reducers/positions/positionsReducer';
import EmploymentPosition from '../../store/constants/employments/positions/employment-position.interface';

const usePositions = () => {
  const dispatch = useAppDispatch();
  const positionsState: PositionsState = useAppSelector((state) => state.positions);

  const resetPositionsState = () => {
    dispatch(positionsReducerActions.resetPositionsState());
  };

  const setPositionsState = (state: Partial<PositionsState>) => {
    dispatch(positionsReducerActions.setPositionsState(state));
  };

  const get = async (id: EmploymentPosition['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionActions.get(id));

  const getAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionActions.getAll({}));

  const post = async (data: Omit<EmploymentPosition, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionActions.post(data));

  const put = async (data: Partial<EmploymentPosition>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionActions.put(data));

  const remove = async (id: EmploymentPosition['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(positionActions.remove(id));

  return {
    resetPositionsState,
    positionsState,
    setPositionsState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default usePositions;
