import { useState } from 'react';
import moment from 'moment';

interface ExpectedAttendanceTime {
  date: string;
  timezone: string;
}

export interface TimeRemaining {
  timeDiff?: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const useTimeRemaining = (expectedTimeIn?: ExpectedAttendanceTime) => {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining | undefined>();

  const calculateTimeRemaining = () => {
    if (!expectedTimeIn) return { timeDiff: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };

    const { date, timezone } = expectedTimeIn;
    const now = new Date().getTime();
    const target = new Date(moment(date).tz(timezone).toDate()).getTime();
    const timeDiff = target - now;

    if (timeDiff <= 0) {
      // Target date has passed
      return { timeDiff, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return { timeDiff, days, hours, minutes, seconds };
  };

  const getTimeRemainingFormat = () => {
    if (!timeRemaining) {
      return null;
    }

    const { days, hours, minutes, seconds } = timeRemaining;

    if (days > 0) {
      return `${days}d ${hours}h ${minutes.toString().padStart(2, '0')}m`;
    }
    if (hours > 0) {
      return `${hours}h ${minutes.toString().padStart(2, '0')}m ${seconds
        .toString()
        .padStart(2, '0')}s`;
    }
    if (minutes > 0) {
      return `${minutes}m ${seconds.toString().padStart(2, '0')}s`;
    }
    return `${seconds}s`;
  };

  return {
    timeRemaining,
    calculateTimeRemaining,
    setTimeRemaining,
    getTimeRemainingFormat,
  };
};

export default useTimeRemaining;
