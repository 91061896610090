import React from 'react';
import { Dialog, DialogBody } from '@material-tailwind/react';
import Team from '../../../store/constants/teams/team.interface';

interface DepartmentTeamConfirmationProps {
  open: boolean;
  onClose: () => void;
  handleDeleteTeam: (leave: Team) => void;
  departmentTeamData: Team;
  isLoading?: boolean;
}

const DepartmentTeamConfirmation: React.FC<DepartmentTeamConfirmationProps> = ({
  open,
  onClose,
  isLoading = false,
  handleDeleteTeam,
  departmentTeamData,
}) => (
  <div className="confirmation-parent-container">
    <Dialog size="sm" open={open} handler={onClose} className="department-confirmation-container">
      <DialogBody className="text-lg font-semibold text-gray-800 ">
        <div className="flex flex-col justify-between confirmation-wrapper">
          <p className="flex justify-center items-center mb-4 text-center">
            {`You are about to remove 
            ${departmentTeamData && departmentTeamData?.name} from
            ${departmentTeamData && departmentTeamData.department?.name}.`}
          </p>
          <div className="flex justify-between items-center px-7">
            <button
              className="confirm-button"
              type="button"
              onClick={() => {
                handleDeleteTeam(departmentTeamData);
                onClose();
              }}
            >
              Confirm
            </button>
            <button className="cancel-button" type="button" onClick={onClose} disabled={isLoading}>
              <span className="h-[2rem] flex justify-center items-center">Cancel</span>
            </button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  </div>
);

export default DepartmentTeamConfirmation;
