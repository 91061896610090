import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import Team from '../../../store/constants/teams/team.interface';
import useTeams from '../../../hooks/teams/useTeams';

export interface SelectTeamProps extends Partial<AutoSearchSelectProps<Team>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  excludedData?: Team[];
  clientTeams?: Team[];
}

const SelectTeam: React.FC<SelectTeamProps> = ({
  id,
  name,
  placeholder = 'Select Team',
  className = '',
  excludedData = [],
  clientTeams = [],
  ...props
}) => {
  const { teamsState, getAll } = useTeams();

  useOnLoad(() => {
    getAll().then();
  });

  const teams = teamsState.teams
    .filter((team) => {
      if (!clientTeams.length) {
        return true;
      }
      return clientTeams.some((clientTeam) => clientTeam.id === team.id);
    })
    .filter((team) => !excludedData.some((excludedTeam) => excludedTeam.id === team.id))
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((team: Team) => ({
      ...team,
      value: team.id,
      label: team.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-client modal-select ${className}`}
      placeholder={placeholder}
      data={teams}
    />
  );
};

export default SelectTeam;
