import React from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import EmploymentRole from '../../../store/constants/employments/roles/employment-role.interface';
import useEmploymentRoles from '../../../hooks/employment/useEmploymentRoles';

export interface SelectEmploymentRoleProps extends Partial<AutoSearchSelectProps<EmploymentRole>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  allowedCodes?: EmploymentRole['code'][];
}

const SelectEmploymentRole: React.FC<SelectEmploymentRoleProps> = ({
  id,
  name,
  placeholder = 'Select EmploymentRole',
  className = '',
  allowedCodes = undefined,
  ...props
}) => {
  const { employmentRolesState, getAll } = useEmploymentRoles();

  useOnLoad(() => {
    getAll().then();
  });

  const employmentRoles = employmentRolesState.employmentRoles
    .slice()
    .filter(
      (employmentRole: EmploymentRole) =>
        !allowedCodes || allowedCodes.includes(employmentRole.code),
    )
    // @TODO Employment role should have order by in the backend
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((employmentRole: EmploymentRole) => ({
      ...employmentRole,
      value: employmentRole.code,
      label: employmentRole.name,
    }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-employment-role modal-select ${className}`}
      placeholder={placeholder}
      data={employmentRoles}
    />
  );
};

export default SelectEmploymentRole;
