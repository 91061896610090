import React, { useState } from 'react';
import PayrollAccordion from '../payrollSidebarProperties/PayrollAccordion';
import usePayrolls from '../../../../../../hooks/payroll/usePayrolls';
import Company from '../../../../../../store/constants/companies/company.interface';
import useTeams from '../../../../../../hooks/teams/useTeams';

interface PayrollSidebarCompanyTeamsProps {
  company?: Company;
}

const PayrollSidebarCompanyTeams: React.FC<PayrollSidebarCompanyTeamsProps> = ({
  company = undefined,
}) => {
  const { payrollsState, setPayrollState } = usePayrolls();
  const { teamsState } = useTeams();
  const [open, setOpen] = useState(0);

  const companyTeams = teamsState.teams.filter((team) =>
    team ? team.company?.id === company?.id : false,
  );

  const handleCheckboxChange = (id: number, checked: boolean) => {
    const updatedTeamId = checked
      ? [...(payrollsState.payrollParams.teamId || []), id]
      : (payrollsState.payrollParams.teamId || []).filter((teamId) => teamId !== id);

    setPayrollState({
      ...payrollsState,
      payrollParams: {
        ...payrollsState.payrollParams,
        teamId: updatedTeamId,
      },
    });
  };

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="no-scrollbars payroll-menu-sidebar">
      {companyTeams.map((team, index) => (
        <PayrollAccordion
          key={team.id}
          openNumber={index + 1}
          menuTitle={`${team.company?.code} - ${team.department?.name}`}
          teamOptions={companyTeams.filter((t) => t.department?.id === team.department?.id)}
          open={open}
          handleOpen={handleOpen}
          handleCheckboxChange={handleCheckboxChange}
        />
      ))}
    </div>
  );
};

export default PayrollSidebarCompanyTeams;
