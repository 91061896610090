import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import Department from '../../../store/constants/departments/department.interface';
import DeleteConfirmationBanner from '../../molecules/DeleteConfirmationBanner';
import { ReactComponent as TeamIcon } from '../../../assets/images/icons/user-two.svg';
import useTeams from '../../../hooks/teams/useTeams';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import { ReactComponent as Edit } from '../../../assets/images/icons/edit.svg';

export interface DepartmentListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: Department;
  getDateTime: (date: Date) => string;
  onEditClick: (department: Department) => void;
  onDeleteClick: (department: Department) => Promise<unknown>;
}

const DepartmentListRow: React.FC<DepartmentListRowProps> = ({
  className = '',
  data,
  getDateTime,
  onEditClick,
  onDeleteClick,
  ...props
}: DepartmentListRowProps) => {
  const navigate = useNavigate();
  const { teamsState, getAll: getAllTeams } = useTeams();

  const handleViewTeamsList = () => {
    navigate(`/admin/departments/${data.id}/teams-list`);
  };

  const deleteMessage = {
    pending: 'Pending',
    success: 'Department is successfully deleted.',
    error: 'Error on deleting department.',
  };

  // count of teams
  const departmentTeamList = teamsState.teams.filter((team) => team.department?.id === data.id);

  useOnLoad(() => {
    getAllTeams().then();
  });

  return (
    <div
      {...props}
      className={`${className} department-list-data table-list-data flex items-center`}
    >
      <span className="dept-row-text-sm">{data.id}</span>
      <span className="dept-row-text-sm">{data.name}</span>
      <span className="flex justify-center items-center">
        <button
          type="button"
          className={`text-left w-fit ${
            departmentTeamList.length === 0 ? 'cursor-default' : 'cursor-pointer'
          }`}
          onClick={() => {
            if (departmentTeamList.length === 0) return;

            handleViewTeamsList();
          }}
        >
          {departmentTeamList.length}
        </button>
      </span>
      <span className="dept-row-text-xs mx-auto">
        {data.dateCreated && getDateTime(data.dateCreated)}
      </span>
      <div className="flex justify-self-center action-buttons space-x-6">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <Edit className="w-6 h-auto" />
          </button>
        </Tooltip>
        <Tooltip content="View Teams" className="tooltip">
          <button
            className="flex gap-2 textButton"
            type="button"
            onClick={() => {
              handleViewTeamsList();
            }}
          >
            <TeamIcon className="w-5 h-5" />
          </button>
        </Tooltip>

        <DeleteConfirmationBanner
          data={data}
          label={data.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};

export default DepartmentListRow;
