import React, { useState } from 'react';
import { Field } from 'formik';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import EmploymentRole from '../../../store/constants/employments/roles/employment-role.interface';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import Employee from '../../../store/constants/employees/employee.interface';
import EmploymentPosition from '../../../store/constants/employments/positions/employment-position.interface';
import useEmploymentRoles from '../../../hooks/employment/useEmploymentRoles';

// Modify AutoSearchSelectProps to accept a generic type
export interface SelectEmployeeProps<T> extends Partial<AutoSearchSelectProps<T>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  allowedRolesByCode?: EmploymentRole['code'][];
  allowedPositionById?: EmploymentPosition['id'];
}

const SelectEmployee: React.FC<SelectEmployeeProps<Employee | Employee[]>> = ({
  id,
  name,
  placeholder = 'Select User Employment',
  className = '',
  allowedRolesByCode = undefined,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userEmploymentsStates, getAll } = useUserEmployments();
  const { findRoleById } = useEmploymentRoles();

  useOnLoad(() => {
    setIsLoading(true);
    getAll().then(() => {
      setIsLoading(false);
    });
  });

  const userEmployments = isLoading
    ? [{ value: undefined, label: 'Loading...' }]
    : userEmploymentsStates.userEmployments
        .filter((userEmployment) => {
          if (!userEmployment.employeeNumber) {
            return false;
          }
          if (
            !allowedRolesByCode ||
            (userEmployment.roles &&
              userEmployment.roles.find((userEmploymentRole) =>
                allowedRolesByCode.includes(findRoleById(userEmploymentRole.roleId)?.code ?? ''),
              ))
          ) {
            return true;
          }
          return false;
        })
        .sort((a, b) => a.userInformation.lastName.localeCompare(b.userInformation.lastName))
        .map((userEmployment) => ({
          ...userEmployment,
          value: userEmployment.employeeNumber,
          label: userEmployment.userInformation.fullName,
        }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-user-by-employment-transfer-reason modal-select ${className}`}
      placeholder={placeholder}
      data={userEmployments}
    />
  );
};

export default SelectEmployee;
