import React, { useState } from 'react';
import { Dialog, IconButton } from '@material-tailwind/react';
import { AttendanceState } from '../../../../../store/reducers/attendances/attendancesReducer';
import { timerToTimeString } from '../../../../../utils/convertTimes';
import toastNotification from '../../../../../hooks/utils/toastNotification';
import useAttendance from '../../../../../hooks/attendances/useAttendance';
import EmployeeOvertime from '../../../../../store/constants/employees/attendances/employee-overtime.interface';
import useTimer from '../../../../../hooks/utils/useTimer';
import useOvertime from '../../../../../hooks/attendances/useOvertime';
import formatTimeDuration from '../../../../../utils/formatTimeDuration';
import ButtonAction from '../../../../atoms/buttons/ButtonAction';
import useTheme from '../../../../../hooks/utils/useTheme';
import UserAttendanceModal from '../UserAttendanceModal';
import { ReactComponent as Close } from '../../../../../assets/images/icons/close.svg';

export interface OvertimeDurationTimerProps {
  overtimeDetail?: EmployeeOvertime;
}

const OvertimeDurationTimer: React.FC<OvertimeDurationTimerProps> = ({
  overtimeDetail = undefined,
}) => {
  const { attendanceState, overtimeTimeOut, setAttendanceState } = useAttendance();
  const { resetOvertimeDuration } = useOvertime();
  const { theme } = useTheme();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const parseDuration = (duration: string): number => {
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const { hours, minutes, seconds } = useTimer({
    otDuration: parseDuration(overtimeDetail?.duration || '00:00'),
    initialTime: overtimeDetail?.overtimeTimeIn,
  });

  const duration = timerToTimeString(hours, minutes, seconds);

  const handleClick = () => setOpen((prev) => !prev);

  const handleSubmit = async () => {
    setLoading(true);
    // check if the duration is less than the initial overtime duration
    let isDurationLessThanOvertime = false;
    if (hours * 3600 + minutes * 60 + seconds < parseDuration(overtimeDetail?.duration || '00:00'))
      isDurationLessThanOvertime = true;

    await toastNotification({
      action: overtimeTimeOut({
        ...overtimeDetail,
        duration: isDurationLessThanOvertime
          ? duration
          : overtimeDetail?.duration || overtimeDetail?.duration,
      } as EmployeeOvertime),
      onSuccess: () => {
        setAttendanceState({
          myOverTimeDetail: undefined,
          myOvertimes: [
            ...attendanceState.myOvertimes,
            {
              ...overtimeDetail,
              duration: isDurationLessThanOvertime
                ? duration
                : overtimeDetail?.duration || overtimeDetail?.duration,
              overtimeTimeOut: new Date(),
            },
          ],
        } as Partial<AttendanceState>);
      },
      onError: () => {},
      onFulfilled: () => {
        resetOvertimeDuration();
        setLoading(false);
      },
      toastPromiseMessages: {
        success: 'Overtime successfully timed out',
        pending: 'Processing overtime timeout...',
        error: {
          render: ({ data }) => (typeof data === 'string' ? data : 'Failed to time out overtime'),
        },
      },
    });
  };

  return (
    <div className="overtime-prompt-container">
      {/* overtime duration timer */}
      <button className="overtime-duration__button" type="button" onClick={handleClick}>
        <span>{`${timerToTimeString(hours, minutes, seconds)}`}</span>
      </button>
      <Dialog
        open={open}
        size="xs"
        handler={handleClick}
        animate={{
          mount: {
            transition: 'ease-in duration-800',
          },
          unmount: {
            transition: 'ease-out duration-800',
          },
        }}
        className={`user-attendance-action__prompt-container ${theme}`}
      >
        <UserAttendanceModal
          heading={
            <>
              <h1>
                You are currently{' '}
                <span className="dialog-prompt-sub-header__text-overtime">Timed in</span>.
              </h1>
              {duration !== overtimeDetail?.duration ? (
                <h1>
                  {`${formatTimeDuration({
                    hours,
                    minutes,
                    seconds,
                  })}`}{' '}
                  in overtime.
                </h1>
              ) : (
                <h1 className="text-xs">You&apos;re overtime has ended.</h1>
              )}
              <div className="close-prompt-button">
                <IconButton
                  color="blue-gray"
                  size="sm"
                  variant="text"
                  onClick={() => setOpen(false)}
                >
                  <Close className="h-5 w-5" />
                </IconButton>
              </div>
            </>
          }
        >
          <div className="flex items-center justify-center pb-3">
            <ButtonAction
              type="button"
              text="Overtime out"
              className="action-button__submit"
              isLoading={loading}
              hasSpinnerText={false}
              onClick={handleSubmit}
            />
          </div>
          {duration !== overtimeDetail?.duration && (
            <h1 className={`text text--size-xs text-center text--state-${theme}`}>
              <span className="text text--size-xs text--color-primary">Note:</span> Only the time
              you&apos;ve spent in overtime will be recorded.
            </h1>
          )}
        </UserAttendanceModal>
      </Dialog>
    </div>
  );
};

export default OvertimeDurationTimer;
