import React, { useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, IconButton } from '@material-tailwind/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import EmploymentPositionRate from '../../../../store/constants/employments/positions/employment-position-rate.interface';
import usePositionRates from '../../../../hooks/positions/usePositionRates';
import { ReactComponent as Add } from '../../../../assets/images/icons/plus.svg';
import toastNotification from '../../../../hooks/utils/toastNotification';
import SelectEmploymentPosition from '../../../atoms/dropdowns/SelectEmploymentPosition';
import ButtonAction from '../../../atoms/buttons/ButtonAction';
import useOnLoad from '../../../../hooks/utils/useOnLoad';

export interface PositionsFormModalParams {
  positions?: EmploymentPositionRate;
  className?: string;
  onClose: () => void;
}

const PositionRateModal: React.FC<PositionsFormModalParams> = ({
  positions = undefined,
  className = undefined,
  onClose,
}) => {
  const [positionsForm, setPositionsForm] = useState<EmploymentPositionRate | undefined>();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { positionRatesState, getAll, post, put } = usePositionRates();

  const handleClose = () => {
    setLoading(false);
    setPositionsForm(undefined);
    setOpen(false);
    onClose();
  };

  const handleOpen = () => {
    setPositionsForm(undefined);
    setOpen(true);
  };

  const handleSubmit = async (values: Partial<EmploymentPositionRate>) => {
    setLoading(true);
    if (positionsForm) {
      const data: EmploymentPositionRate = {
        ...positionsForm,
        ...values,
      };

      await toastNotification({
        action: put(data),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Position rate information is successfully updated.',
          error: {
            render: ({ data: errorMsg }) =>
              typeof errorMsg === 'string'
                ? errorMsg
                : 'Error on updating Position rate information.',
          },
        },
      });
    } else {
      await toastNotification({
        action: post(values as EmploymentPositionRate),
        onSuccess: () => {},
        onError: () => {},
        onFulfilled: () => {
          handleClose();
        },
        toastPromiseMessages: {
          pending: 'Processing',
          success: 'Position Rate information is successfully added.',
          error: 'Error on adding Position rate information.',
        },
      });
    }

    setOpen(false);
  };

  const excludedEmploymentPositions = positionRatesState.positionRates
    .map((employmentPositionRate) => employmentPositionRate.employmentPosition)
    .filter((employmentPosition) => employmentPosition);

  useEffect(() => {
    if (positions) {
      setOpen(true);
      setPositionsForm(positions);
    }
  }, [positions]);

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div>
      <button
        type="button"
        className={` ${className} button button--state-default gap-2`}
        onClick={handleOpen}
      >
        <Add className="w-5 h-5" />
        Add position rate
      </button>
      <Dialog
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-[#343434] shadow-none p-10 modal-size"
      >
        <div className="absolute top-0 right-0 flex p-0 m-2">
          <IconButton color="blue-gray" size="sm" variant="text" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
              className="w-5 h-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="flex gap-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1}
            stroke="white"
            className="modal-icons"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
            />
          </svg>

          <DialogHeader className="p-0 mt-1 text-gray-200 modal-header">
            Enter Positions Rate Info
          </DialogHeader>
        </div>
        <DialogBody divider className="p-0">
          <div className="">
            <div className="">
              <Formik
                initialValues={{
                  ...positionsForm,
                  employmentPosition: positionsForm?.employmentPosition
                    ? {
                        ...positionsForm?.employmentPosition,
                        label: positionsForm?.employmentPosition?.name,
                        value: positionsForm?.employmentPosition,
                      }
                    : undefined,
                }}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form className="form-with-number-input">
                    <div className="mt-4 mb-5 form-section">
                      <div className="flex flex-col justify-center w-full">
                        <div className="mb-6">
                          <div className="w-full mb-2">
                            <label
                              htmlFor="employmentPosition"
                              className="block mt-2 text-xs text-gray-300 uppercase"
                            >
                              Position
                            </label>
                          </div>
                          <div className="w-full">
                            <SelectEmploymentPosition
                              id="employmentPosition"
                              name="employmentPosition"
                              isClearable
                              isSearchable
                              excludedData={[...excludedEmploymentPositions]}
                              value={formik.values.employmentPosition}
                              setFieldValue={formik.setFieldValue}
                            />
                          </div>
                          <ErrorMessage name="employmentPosition" component="div" />
                        </div>

                        <div className="flex space-x-10">
                          <div className="mb-6">
                            <label
                              htmlFor="gross"
                              className="block mb-2 text-gray-200 modal-labels"
                            >
                              Gross
                            </label>
                            <div className="rate_field">
                              <span className="rate_field-symbol">₱</span>
                              <Field
                                type="number"
                                id="gross"
                                name="gross"
                                className="rate_field-input"
                                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                  if (
                                    !/^\d$/.test(event.key) &&
                                    event.key !== 'Backspace' &&
                                    event.key !== 'Delete' &&
                                    event.key !== 'ArrowLeft' &&
                                    event.key !== 'ArrowRight'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              <ErrorMessage name="gross" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="maxGross"
                              className="block mb-2 text-gray-200 modal-labels"
                            >
                              Max Gross
                            </label>
                            <div className="rate_field">
                              <span className="rate_field-symbol">₱</span>
                              <Field
                                type="number"
                                id="maxGross"
                                name="maxGross"
                                className="rate_field-input"
                              />
                              <ErrorMessage name="maxGross" component="div" />
                            </div>
                          </div>
                        </div>
                        <div className="flex space-x-10">
                          <div className="mb-6">
                            <label
                              htmlFor="minimumRate"
                              className="block mb-2 text-gray-200 modal-labels"
                            >
                              Minimum Rate
                            </label>
                            <div className="rate_field">
                              <span className="rate_field-symbol">₱</span>
                              <Field
                                type="number"
                                id="minimumRate"
                                name="minimumRate"
                                className="rate_field-input"
                              />
                              <ErrorMessage name="minimumRate" component="div" />
                            </div>
                          </div>

                          <div className="mb-6">
                            <label
                              htmlFor="maximumRate"
                              className="block mb-2 text-gray-200 modal-labels"
                            >
                              Maximum Rate
                            </label>
                            <div className="rate_field">
                              <span className="rate_field-symbol">₱</span>
                              <Field
                                type="number"
                                id="maximumRate"
                                name="maximumRate"
                                className="rate_field-input"
                              />
                              <ErrorMessage name="maximumRate" component="div" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex space-x-2 w-max">
                      <div className="flex justify-center w-full">
                        <ButtonAction
                          type="submit"
                          className="modal-buttons flex justify-center item-center p-2 rounded-md shadow-lg bg-[#f05825] hover:bg-[#f8183e] text-gray-200 text-md"
                          isLoading={loading}
                          hasSpinnerText={false}
                          isSubmit
                        />
                      </div>
                      <div className="flex justify-center w-max">
                        <button
                          type="button"
                          onClick={handleClose}
                          className="modal-buttons p-2 text-[#f8183e] hover:text-[#f05825] text-md"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default PositionRateModal;
