import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import ClientManager from '../../constants/clients/client-manager.interface';

export interface GetAllClientManagersQueryParams extends ApiGetAllBaseQueryParams {
  data: ClientManager;
}

const get = async (
  data: ClientManager,
  state: StateHeaders,
): Promise<ApiBaseResponse<ClientManager>> =>
  apiFetch<ApiBaseResponse<ClientManager>>(`clients/${data.clientId}/managers/${data.id}`, {
    state,
  })
    .then((response: ApiBaseResponse<ClientManager>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { data, page = 1, limit = 50, search = '' }: GetAllClientManagersQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<ClientManager>> =>
  apiFetch<ApiGetAllBaseResponse<ClientManager>>(
    `clients/${data.clientId}/managers?limit=${limit}&page=${page}${
      search !== '' ? `&search=${search}` : ''
    }`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<ClientManager>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<ClientManager, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<ClientManager> | void> =>
  apiFetch<ApiBaseResponse<ClientManager>>(`clients/${data.clientId}/managers`, {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<ClientManager>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const patch = async (
  data: ClientManager,
  state: StateHeaders,
): Promise<ApiBaseResponse<ClientManager> | void> =>
  apiFetch<ApiBaseResponse<ClientManager>>(`clients/${data.clientId}/managers/${data.id}`, {
    state,
    options: {
      method: 'PATCH',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<ClientManager>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const put = async (
  data: AtLeast<ClientManager, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<ClientManager> | void> =>
  apiFetch<ApiBaseResponse<ClientManager>>(`clients/${data.clientId}/managers/${data.id}`, {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<ClientManager>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  data: ClientManager,
  state: StateHeaders,
): Promise<ApiBaseResponse<ClientManager> | void> =>
  apiFetch<ApiBaseResponse<ClientManager>>(`clients/${data.clientId}/managers/${data.id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<ClientManager>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  post,
  patch,
  put,
  remove,
} as const;
