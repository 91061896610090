import { useAppDispatch, useAppSelector } from '../../store/store';
import myLeaveActions from '../../store/actions/leave/myLeaveActions';
import { leavesReducerActions, MyLeavesState } from '../../store/reducers/leaves/myLeavesReducer';
import LeaveApplication from '../../store/constants/leaves/leave-application.interface';

const useMyLeaves = () => {
  const dispatch = useAppDispatch();
  const myLeavesState: MyLeavesState = useAppSelector((state) => state.myLeaves);

  const resetLeaveState = () => {
    dispatch(leavesReducerActions.resetMyLeavesState());
  };

  const setLeaveState = (state: Partial<MyLeavesState>) => {
    dispatch(leavesReducerActions.setMyLeavesState(state));
  };

  const resetMyCurrentLeaveApplication = () => {
    dispatch(leavesReducerActions.resetMyCurrentLeaveApplication());
  };

  const getMy = async (leaveId: LeaveApplication['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myLeaveActions.getMy(leaveId));

  const getMyAll = async () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myLeaveActions.getMyAll({}));

  const myPost = async (leaveData: Omit<LeaveApplication, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myLeaveActions.myPost(leaveData));

  const myPut = async (leave: Partial<LeaveApplication>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myLeaveActions.myPut(leave));

  const myRemove = async (id: LeaveApplication['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myLeaveActions.myRemove(id));

  const uploadAttachments = async (formData: FormData, leaveId: number) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(myLeaveActions.uploadAttachments({ formData, leaveId }));

  return {
    resetLeaveState,
    myLeavesState,
    setLeaveState,
    getMy,
    getMyAll,
    myPost,
    myPut,
    myRemove,
    uploadAttachments,
    resetMyCurrentLeaveApplication,
  };
};

export default useMyLeaves;
