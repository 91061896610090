import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import HmoPrincipalType from '../../constants/my/hmo-principal.interface';
import hmoPrincipalServices, {
  GetAllHmoPrincipalsQueryParams,
} from '../../services/my/hmoPrincipalServices';

const get = createAsyncThunk(
  'hmoPrincipal/get',
  async (
    id: HmoPrincipalType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoPrincipalType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoPrincipalServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'hmoPrincipal/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllHmoPrincipalsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<HmoPrincipalType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoPrincipalServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'hmoPrincipal/post',
  async (
    data: Omit<HmoPrincipalType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoPrincipalType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoPrincipalServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'hmoPrincipal/put',
  async (
    data: AtLeast<HmoPrincipalType, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoPrincipalType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoPrincipalServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'hmoPrincipal/remove',
  async (
    id: HmoPrincipalType['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<HmoPrincipalType> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await hmoPrincipalServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
