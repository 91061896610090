import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmployeeAttendance from '../../constants/employees/attendances/employee-attendance.interface';
import EmployeeTimeOutDetail from '../../constants/employees/attendances/employee-Timeout-detail.interface';
import EmployeeOvertime from '../../constants/employees/attendances/employee-overtime.interface';
import { GetAllPayrollQueryParams } from '../payroll/payrollServices';
import toURLParams from '../../../utils/toURLParams';

export interface Duration {
  dateStart: string | undefined;
  dateEnd: string | undefined;
}

export interface GetAllAttendanceQueryParams extends ApiGetAllBaseQueryParams {
  dateStart?: string;
  dateEnd?: string;
  status?: string;
}

export interface ExportAttendanceQueryParams {
  teamId?: GetAllPayrollQueryParams['teamId'];
}

const get = async (
  id: EmployeeAttendance['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeAttendance>> =>
  apiFetch<ApiBaseResponse<EmployeeAttendance>>(`my/attendances/${id}`, { state })
    .then((response: ApiBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllAttendanceQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmployeeAttendance>> =>
  apiFetch<ApiGetAllBaseResponse<EmployeeAttendance>>(
    `my/attendances?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const post = async (
  data: Omit<EmployeeAttendance, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeAttendance> | void> =>
  apiFetch<ApiBaseResponse<EmployeeAttendance>>('my/attendances/attendance', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const remove = async (
  id: EmployeeAttendance['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeAttendance> | void> =>
  apiFetch<ApiBaseResponse<EmployeeAttendance>>(`my/attendance/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const timeIn = async (state: StateHeaders): Promise<ApiBaseResponse<EmployeeAttendance> | void> =>
  apiFetch<ApiBaseResponse<EmployeeAttendance>>('my/attendances/time-in', {
    state,
    options: {
      method: 'POST',
    },
  })
    .then((response: ApiBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const timeBreak = async (
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeAttendance> | void> =>
  apiFetch<ApiBaseResponse<EmployeeAttendance>>('my/attendances/time-break', {
    state,
    options: {
      method: 'PUT',
    },
  })
    .then((response: ApiBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const timeOut = async (
  data: AtLeast<EmployeeTimeOutDetail, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeTimeOutDetail> | void> =>
  apiFetch<ApiBaseResponse<EmployeeTimeOutDetail>>('my/attendances/time-out', {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeTimeOutDetail>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const underTime = async (
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeTimeOutDetail> | void> =>
  apiFetch<ApiBaseResponse<EmployeeTimeOutDetail>>('my/attendances/underTime', {
    state,
    options: {
      method: 'PUT',
    },
  })
    .then((response: ApiBaseResponse<EmployeeTimeOutDetail>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const overtimeTimeIn = async (
  data: Omit<EmployeeOvertime, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>('my/overtimes/time-in', {
    state,
    options: {
      method: 'POST',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const overtimeTimeOut = async (
  data: AtLeast<EmployeeOvertime, 'id'>,
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>('my/overtimes/time-out', {
    state,
    options: {
      method: 'PUT',
      body: data,
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllMyAttendance = async (
  { page = 1, limit = 50, search = '' }: GetAllAttendanceQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmployeeAttendance>> =>
  apiFetch<ApiGetAllBaseResponse<EmployeeAttendance>>(
    `my/attendances?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAllMyOvertimes = async (
  {
    page = 1,
    limit = 50,
    search = '',
    status = undefined,
    dateStart = undefined,
    dateEnd = undefined,
  }: GetAllAttendanceQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmployeeOvertime>> =>
  apiFetch<ApiGetAllBaseResponse<EmployeeOvertime>>(
    `my/overtimes?${
      toURLParams({
        page,
        limit,
        search,
        status,
        ...(dateStart && { dateStart }),
        ...(dateEnd && { dateEnd }),
      }) ?? ''
    }`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getMyCurrentOvertime = async (
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmployeeOvertime> | unknown> =>
  apiFetch<ApiGetAllBaseResponse<EmployeeOvertime>>('my/current/overtime', {
    state,
  })
    .then((response: ApiGetAllBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const myOvertimeRemove = async (
  id: EmployeeOvertime['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmployeeOvertime> | void> =>
  apiFetch<ApiBaseResponse<EmployeeOvertime>>(`my/overtimes/${id}`, {
    state,
    options: {
      method: 'DELETE',
    },
  })
    .then((response: ApiBaseResponse<EmployeeOvertime>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const exportAttendance = async (
  { teamId: team_ids }: ExportAttendanceQueryParams,
  state: StateHeaders,
  // eslint-disable-next-line
): Promise<ApiBaseResponse<any>> => {
  const {
    auth: { token },
  } = state;
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/attendances/export/attendance?${toURLParams({
        ...(team_ids && { team_ids }),
      })}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ...(token && {
            Authorization: `Bearer ${token}`,
          }),
        },
      },
    );

    if (!response.ok) {
      return {
        data: null,
        status: response.status,
        error: `Error fetching attendance data: ${response.statusText}`,
        messages: {
          error: `Error fetching attendance data: ${response.statusText}`,
        },
      };
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    if (url.startsWith('blob:')) {
      const a = document.createElement('a');
      a.href = url;
      a.download = 'attendance_data.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return {
        data: url,
        status: response.status,
        error: null,
        messages: {
          success: 'File downloaded successfully',
        },
      };
    }
    return {
      data: null,
      status: 400,
      error: 'The URL is not a blob URL, download aborted for security reasons.',
      messages: {
        error: 'The URL is not a blob URL, download aborted for security reasons.',
      },
    };
  } catch (error) {
    // eslint-disable-next-line
    console.error('Error downloading file:', error);
    return {
      data: null,
      status: 500,
      error: error instanceof Error ? error.message : String(error),
      messages: {
        error: error instanceof Error ? error.message : 'An unknown error occurred',
      },
    };
  }
};

export default {
  get,
  getAll,
  post,
  remove,
  timeIn,
  timeOut,
  timeBreak,
  underTime,
  overtimeTimeIn,
  overtimeTimeOut,
  myOvertimeRemove,
  getAllMyAttendance,
  getAllMyOvertimes,
  exportAttendance,
  getMyCurrentOvertime,
} as const;
