import React, { FC } from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';
import type { EmployeeTeamTransferReason } from '../../../store/constants/employees/teams/employee-team-transfer-reason';
import useUserEmployments from '../../../hooks/users/useUserEmployments';

export interface SelectUserEmploymentTransferReasonProps
  extends Partial<AutoSearchSelectProps<EmployeeTeamTransferReason>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

const SelectUserEmploymentTransferReason: FC<SelectUserEmploymentTransferReasonProps> = ({
  id,
  name,
  placeholder = 'Select Transfer Reason',
  className = '',
  ...props
}) => {
  const {
    userEmploymentsStates: { transferReasons },
  } = useUserEmployments();

  const userEmploymentTransferReasons = transferReasons
    .slice()
    .map((transferReason: EmployeeTeamTransferReason) => ({
      ...transferReason,
      value: transferReason.id,
      label: transferReason.name,
    }));

  return (
    <Field
      {...props}
      id={id}
      name={name}
      className={`select-client modal-select ${className}`}
      component={SelectAutoSearch}
      placeholder={placeholder}
      data={userEmploymentTransferReasons}
    />
  );
};

export default SelectUserEmploymentTransferReason;
