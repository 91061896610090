import { StylesConfig } from 'react-select/dist/declarations/src/styles';

const AutoSearchSelectStyle: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#2c2b2a',
    transition: 'all 0.3s ease',
    color: 'white',
    border: `${
      state.isDisabled ? '1px solid gray' : `2px solid ${state.isFocused ? '#f05825' : 'gray'}`
    }`,
    boxShadow: 'none',

    '&:hover': {
      border: '2px solid #f05825',
    },
  }),

  singleValue: (provided) => ({
    ...provided,
    color: 'white',
  }),

  input: (provided) => ({
    ...provided,
    color: 'white',
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease',

    '&:hover': {
      color: '#f05825',
    },
  }),

  clearIndicator: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: '#f05825',
    },
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: 5,
    zIndex: 99,
  }),

  menuList: (provided) => ({
    ...provided,
    borderRadius: 5,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: `${state.isSelected ? '#f05825' : 'white'}`,
    color: state.isSelected ? 'white' : 'black',
    whiteSpace: 'pre-line',

    '&:hover': {
      backgroundColor: state.isSelected ? '#f05825' : '#f058252b',
    },
  }),
};

export default AutoSearchSelectStyle;
