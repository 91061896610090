import apiFetch, { StateHeaders } from '../../../utils/apiFetch';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseQueryParams from '../../constants/utils/api-get-all-base-query-params.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentStatus from '../../constants/employments/employment-status.interface';
import EmployeeAttendance from '../../constants/employees/attendances/employee-attendance.interface';
import UserActivity from '../../constants/utils/userActivity.interface';

export type GetAllEmploymentStatusQueryParams = ApiGetAllBaseQueryParams;

const get = async (
  id: EmploymentStatus['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<EmploymentStatus>> =>
  apiFetch<ApiBaseResponse<EmploymentStatus>>(`employments/status/${id}`, { state })
    .then((response: ApiBaseResponse<EmploymentStatus>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const getAll = async (
  { page = 1, limit = 50, search = '' }: GetAllEmploymentStatusQueryParams,
  state: StateHeaders,
): Promise<ApiGetAllBaseResponse<EmploymentStatus>> =>
  apiFetch<ApiGetAllBaseResponse<EmploymentStatus>>(
    `employments/status?limit=${limit}&page=${page}${search !== '' ? `&search=${search}` : ''}`,
    { state },
  )
    .then((response: ApiGetAllBaseResponse<EmploymentStatus>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const postUser = async (state: StateHeaders): Promise<ApiBaseResponse<EmployeeAttendance> | void> =>
  apiFetch<ApiBaseResponse<EmployeeAttendance>>('users/activity', {
    state,
    options: {
      method: 'POST',
    },
  })
    .then((response: ApiBaseResponse<EmployeeAttendance>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

const putUser = async (
  id: UserActivity['id'],
  state: StateHeaders,
): Promise<ApiBaseResponse<UserActivity> | void> =>
  apiFetch<ApiBaseResponse<UserActivity>>(`users/activity/${id}`, {
    state,
    options: {
      method: 'PUT',
    },
  })
    .then((response: ApiBaseResponse<UserActivity>) => {
      if (response.messages?.error) throw new Error(response.messages.error);

      return response;
    })
    .catch((e) => {
      throw new Error(e);
    });

export default {
  get,
  getAll,
  postUser,
  putUser,
} as const;
