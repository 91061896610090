import React, { useState } from 'react';
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik';
import { Typography } from '@material-tailwind/react';
import User from '../../../store/constants/users/user.interface';
import useUserForm from '../../../hooks/users/useUserForm';
import SelectRelation from '../../atoms/dropdowns/SelectRelation';
import { UserRelation } from '../../../store/reducers/users/usersReducer';
import UserContacts from '../../../store/constants/users/user-contacts.interface';
import { ReactComponent as PersonIcon } from '../../../assets/images/icons/person.svg';

const PreviousButton: React.FC = () => {
  const { values }: { values: Partial<User> } = useFormikContext();
  const {
    userFormState: { user },
    setUserFormState,
    isFirstStep,
    handlePrev,
  } = useUserForm();

  const handleBack = () => {
    setUserFormState({
      user: { ...user, ...values },
    });
    handlePrev();
  };

  return (
    <button
      type="submit"
      onClick={handleBack}
      disabled={isFirstStep}
      className="rounded-lg shadow-lg muiButton--primary hover:bg-orange-500"
    >
      Previous
    </button>
  );
};

interface UserContactInfoFormProps {
  submitButton: React.RefObject<HTMLButtonElement>;
}

const UserContactInfoForm: React.FC<UserContactInfoFormProps> = ({ submitButton }) => {
  const {
    userFormState: { user },
    setUserFormState,
    isLastStep,
    handleNext,
  } = useUserForm();

  const [selectedRelation, setSelectedRelation] = useState<UserRelation | undefined>(
    user.contact?.emergencyContactRelation
      ? ({
          value: user.contact?.emergencyContactRelation,
          label: user.contact?.emergencyContactRelation,
        } as UserRelation)
      : undefined,
  );

  const [formError, setFormError] = useState('');
  const handleSubmit = (values: Partial<User>) => {
    setFormError('');
    setUserFormState({
      user: {
        ...user,
        ...values,
        ...(selectedRelation && {
          contact: {
            ...values.contact,
            emergencyContactRelation: selectedRelation.value,
          } as UserContacts,
        }),
      },
    });
    handleNext();
  };

  const handlePreSubmit = () => {
    setUserFormState({ nextStep: undefined });

    if (submitButton.current) submitButton.current.click();
  };

  return (
    <div>
      {formError && <div className="form-error">{formError}</div>}
      <Formik initialValues={user} onSubmit={handleSubmit}>
        <Form className="employee-contact-info-form p-5 pb-0 mt-5 mb-5 border border-[#2e2d2c] bg-[#252423] border-solid rounded-xl shadow-2xl">
          <Typography variant="h4" className="text-[#f05825] flex gap-2">
            <span className="text-gray-200">Contact & Address</span>
            Information
          </Typography>

          <div className="grid grid-cols-1 gap-5 p-5 mx-auto xl:grid-cols-3 form-section">
            {/* FIRST SECTION */}
            <div className="first-section p-5">
              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="address.address" className="mr-8 text-xs text-gray-600 uppercase">
                  Address
                </label>
                <div className="textarea-form">
                  <Field
                    as="textarea"
                    id="address.address"
                    name="address.address"
                    placeholder="Enter Address"
                    className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="address.address" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="district" className="mr-8 text-xs text-gray-600 uppercase">
                  District
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="address.district"
                    name="address.district"
                    placeholder="Enter District"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="address.district" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="address.city" className="mr-8 text-xs text-gray-600 uppercase">
                  City
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="address.city"
                    name="address.city"
                    placeholder="Enter City"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="address.city" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label htmlFor="address.country" className="mr-8 text-xs text-gray-600 uppercase">
                  Country
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="address.country"
                    name="address.country"
                    placeholder="Country"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                  />
                </div>
                <ErrorMessage name="address.country" component="div" />
              </div>

              <div className="flex flex-col gap-2">
                <label htmlFor="address.zip" className="mr-8 text-xs text-gray-600 uppercase">
                  Zip
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="address.zip"
                    name="address.zip"
                    placeholder="Enter Zip"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="address.zip" component="div" />
              </div>
            </div>
            {/* END FIRST SECTION */}

            {/* SECOND SECTION */}
            <div className="second-section p-5">
              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="contact.partnerFullName"
                  className="mr-8 text-xs text-gray-600 uppercase"
                >
                  Partner&apos;s Full Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="contact.partnerFullName"
                    name="contact.partnerFullName"
                    placeholder="Enter Partner's Full Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="contact.partnerFullName" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="contact.motherName"
                  className="mr-8 text-xs text-gray-600 uppercase"
                >
                  Mother&apos;s Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="contact.motherName"
                    name="contact.motherName"
                    placeholder="Enter Mother's Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="contact.motherName" component="div" />
              </div>

              <div className="flex flex-col gap-2">
                <label
                  htmlFor="contact.fatherName"
                  className="mr-8 text-xs text-gray-600 uppercase"
                >
                  Father&apos;s Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="contact.fatherName"
                    name="contact.fatherName"
                    placeholder="Enter Father's Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="fatherName" component="div" />
              </div>
            </div>
            {/* END SECOND SECTION */}

            {/* THIRD SECTION */}
            <div className="third-section p-5">
              <div className="p-2 mb-5 text-white bg-[#f05825] rounded-md">
                <Typography variant="paragraph" className="font-semibold">
                  Emergency Contact
                </Typography>
              </div>
              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="contact.emergencyContactNumber"
                  className="mr-8 text-xs text-gray-600 uppercase"
                >
                  Number
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 text-[#252423]"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                  </div>
                  <Field
                    type="text"
                    id="contact.emergencyContactNumber"
                    name="contact.emergencyContactNumber"
                    placeholder="Enter Emergency Contact Number"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="contact.emergencyContactNumber" component="div" />
              </div>
              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="contact.emergencyContactName"
                  className="mr-8 text-xs text-gray-600 uppercase"
                >
                  Name
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <PersonIcon className="w-5 h-5 text-[#252423]" />
                  </div>
                  <Field
                    type="text"
                    id="contact.emergencyContactName"
                    name="contact.emergencyContactName"
                    placeholder="Enter Emergency Contact Name"
                    className="pl-10 w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="contact.emergencyContactName" component="div" />
              </div>

              <div className="flex flex-col gap-2 mb-5">
                <label
                  htmlFor="contact.emergencyContactRelation"
                  className="mr-8 text-xs text-gray-600 uppercase"
                >
                  Relation
                </label>
                <div className="select-relation-field">
                  <SelectRelation
                    id="contact.emergencyContactRelation"
                    name="contact.emergencyContactRelation"
                    value={selectedRelation}
                    onChange={(value) => setSelectedRelation(value as UserRelation)}
                    isClearable
                  />
                  {/*
                  <Field
                    type="text"
                    id="contact.emergencyContactRelation"
                    name="contact.emergencyContactRelation"
                    placeholder="Relation"
                    className="w-full p-2 bg-[#393836] border border-[#2e2d2c] rounded-lg shadow-lg focus:border-t-blue-500 focus:border-blue-500 ring-4 ring-transparent focus:ring-blue-500/20 shadow-blue-gray-900/5 placeholder:text-blue-gray-600 text-white"
                  />
                  */}
                </div>
                <ErrorMessage name="contact.emergencyContactRelation" component="div" />
              </div>

              <div className="flex flex-col gap-2">
                <label
                  htmlFor="contact.emergencyContactAddress"
                  className="mr-8 text-xs text-gray-600 uppercase"
                >
                  Address
                </label>
                <div className="textarea-form">
                  <Field
                    as="textarea"
                    id="contact.emergencyContactAddress"
                    name="contact.emergencyContactAddress"
                    placeholder="Enter Emergency Contact Address"
                    className="w-full p-2 bg-[#393836] text-sm rounded-lg shadow-lg placeholder:text-gray-700 text-white"
                    autoComplete="off"
                  />
                </div>
                <ErrorMessage name="contact.emergencyContactAddress" component="div" />
              </div>
            </div>
            {/* END THIRD SECTION */}
          </div>

          <div className="flex justify-between w-full p-2">
            <PreviousButton />
            <button
              onClick={handlePreSubmit}
              type="button"
              disabled={isLastStep}
              className="rounded-lg shadow-lg muiButton--primary hover:bg-orange-500"
            >
              Next
            </button>
          </div>
          <button ref={submitButton} type="submit" className="invisible">
            Next
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default UserContactInfoForm;
