import { createSlice } from '@reduxjs/toolkit';
import UserActivity from '../../constants/utils/userActivity.interface';

export interface UserActivityState {
  activity: UserActivity | undefined;
}

const initialState: UserActivityState = {
  activity: undefined,
};

const userActivitySlice = createSlice({
  name: 'userActivity',
  initialState,
  reducers: {
    setUserActivity: (state, action) => {
      state.activity = action.payload;
    },
    resetUserActivity: (state) => {
      state.activity = undefined;
    },
  },
});

export const userActivityActions = userActivitySlice.actions;
export default userActivitySlice.reducer;
