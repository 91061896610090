import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../utils/consoleLog';
import upsertData from '../../../utils/upsertData';
import Payslip from '../../constants/payroll/payslip.interface';
import myPayslipActions from '../../actions/my/payslip/myPayslipActions';

export interface MyPayslipsState {
  payslips: Payslip[];
  payslipToPrint: Payslip[];
}

const initialState: MyPayslipsState = {
  payslips: [],
  payslipToPrint: [],
};

const getMyPayslipData = (payslip: Partial<Payslip>) => ({
  ...payslip,
});

const myPayslipsSlice = createSlice({
  name: 'myPayslips',
  initialState,
  reducers: {
    resetMyPayslipsState: () => ({
      ...initialState,
    }),
    resetPayslipToPrint: (state) => ({
      ...state,
      payslipToPrint: [],
    }),
    setMyPayslipsState: (state, action: PayloadAction<Partial<MyPayslipsState>>) => ({
      ...state,
      ...action.payload,
    }),
    upsertPayslip: (state, action: PayloadAction<Partial<Payslip>>) => {
      const data: Payslip = getMyPayslipData(action.payload) as Payslip;
      consoleLog('upsertPayslip data', data);

      if (typeof data.id === 'undefined') return;

      state.payslips = upsertData(state.payslips, data);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(myPayslipActions.getMyPayslips.fulfilled, (state, { payload }) => {
      consoleLog('MY PAYSLIPS REDUCER', 'GET-MY-PAYSLIPS', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Payslip>;

      if (status === 503) return;

      if (data) {
        state.payslips = data;
      }
    });

    builder.addCase(myPayslipActions.printMyPayslips.fulfilled, (state, { payload }) => {
      consoleLog('MY PAYSLIPS REDUCER', 'GET-MY-PAYSLIPS', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<Payslip>;

      if (status === 503) return;

      if (data) {
        state.payslipToPrint = data;
      }
    });
  },
});

export const myPayslipsReducerAction = myPayslipsSlice.actions;
export default myPayslipsSlice.reducer;
