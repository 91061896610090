import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import hmoActions from '../../store/actions/hmo/hmoActions';
import { hmoReducerActions, HmoState } from '../../store/reducers/hmo/hmoReducer';
import Hmo from '../../store/constants/hmo/hmo.interface';

const useHmo = () => {
  const dispatch = useDispatch();
  const hmoState: HmoState = useSelector((state: RootState) => state.hmo);

  const resetHmoState = () => {
    dispatch(hmoReducerActions.resetHmoState());
  };

  const setHmoState = (state: Partial<HmoState>) => {
    dispatch(hmoReducerActions.setHmoState(state));
  };

  const get = async (id: Hmo['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoActions.get(id));

  const getAll = async (search?: string | number) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoActions.getAll({ search }));

  const post = async (data: Omit<Hmo, 'id'>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoActions.post(data));

  const put = async (data: Partial<Hmo>) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoActions.put(data));

  const remove = async (id: Hmo['id']) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoActions.remove(id));

  const reports = async (search?: string | number) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(hmoActions.reports({ search }));

  return {
    reports,
    resetHmoState,
    hmoState,
    setHmoState,
    get,
    getAll,
    post,
    put,
    remove,
  };
};

export default useHmo;
