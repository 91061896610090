import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppInitializationType {
  init: boolean;
  initDate: string;
}

const initialState: AppInitializationType = {
  init: false,
  initDate: '',
};

const appInitializationSlice = createSlice({
  name: 'appInitialization',
  initialState,
  reducers: {
    setInit: (state, action: PayloadAction<boolean>) => {
      state.init = action.payload;
    },
    setInitDate: (state, action: PayloadAction<string>) => {
      state.initDate = action.payload;
    },
  },
});

export const { setInit, setInitDate } = appInitializationSlice.actions;
export default appInitializationSlice.reducer;
