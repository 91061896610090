import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, List, ListItem, ListItemPrefix, Drawer, Card } from '@material-tailwind/react';
import { adminMenu, reportsMenu, payrollMenu, companiesMenu, hmoMenu } from './AdminSidebarOptions';
import AdminAccordionMenu from '../../../molecules/AdminAccordionMenu';
import useAccordionMenu from '../../../../hooks/utils/useAccordionMenu';

const AdminSidebar: React.FC = () => {
  const { openedItems, toggleItem } = useAccordionMenu();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <ListItem
        onClick={openDrawer}
        className={`sidebar-borders flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825] 
        }`}
      >
        <ListItemPrefix>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6 text-gray-300"
          >
            <path
              fillRule="evenodd"
              d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
              clipRule="evenodd"
            />
          </svg>
        </ListItemPrefix>
        <span className="sidebar-text">Admin</span>
      </ListItem>

      <Drawer
        open={isDrawerOpen}
        onClose={closeDrawer}
        className="w-full overflow-y-auto h-full bg-[#2c2b2a]"
        overlay={false}
      >
        <Card shadow={false} className="h-full w-full shadow-lg p-4 bg-[#2c2b2a] rounded-none ">
          <div className="mb-2 flex items-center gap-4 p-2 mt-16">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-10 h-10 text-gray-200"
            >
              <path
                fillRule="evenodd"
                d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                clipRule="evenodd"
              />
            </svg>
            <Typography variant="h4" className="text-gray-200">
              Admin
            </Typography>
          </div>
          <hr className="rounded-md h-0.2 opacity-10" />

          <List>
            {adminMenu.map((menuItem) => (
              <Link key={menuItem.name} to={menuItem.linkTo}>
                <ListItem
                  className={`w-full flex items-center gap-2 p-2 cursor-pointer  hover:bg-[#f05825] focus:bg-[#f05825] active:bg-[#f05825] 
            }`}
                >
                  {menuItem.icon && <ListItemPrefix>{menuItem.icon}</ListItemPrefix>}
                  <span className="admin-sidebar-text">{menuItem.name}</span>
                </ListItem>
              </Link>
            ))}

            <AdminAccordionMenu
              menuTitle="Company"
              openNumber={2}
              options={companiesMenu}
              open={openedItems.includes(2) ? 2 : 0}
              handleOpen={() => toggleItem(2)}
              accordionIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="admin-sidebar-icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                  />
                </svg>
              }
            />

            <AdminAccordionMenu
              menuTitle="HMO"
              openNumber={3}
              options={hmoMenu}
              open={openedItems.includes(3) ? 3 : 0}
              handleOpen={() => toggleItem(3)}
              accordionIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="admin-sidebar-icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
                  />
                </svg>
              }
            />

            <AdminAccordionMenu
              menuTitle="Reports"
              openNumber={4}
              options={reportsMenu}
              open={openedItems.includes(4) ? 4 : 0}
              handleOpen={() => toggleItem(4)}
              accordionIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="admin-sidebar-icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>
              }
            />

            <AdminAccordionMenu
              menuTitle="Payroll Manager"
              openNumber={5}
              options={payrollMenu}
              open={openedItems.includes(5) ? 5 : 0}
              handleOpen={() => toggleItem(5)}
              accordionIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="admin-sidebar-icon"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              }
            />
          </List>
        </Card>
      </Drawer>
    </>
  );
};

export default AdminSidebar;
