import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import UserEmploymentTeam from '../../../../store/constants/employees/teams/employee-team.interface';
import DeleteConfirmationBanner from '../../DeleteConfirmationBanner';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';

export interface TeamListRowProps {
  className?: string;
  style: React.CSSProperties;
  data: UserEmploymentTeam;
  onEditClick: (team: UserEmploymentTeam) => void;
  onDeleteClick: (team: UserEmploymentTeam, key?: number) => Promise<unknown>;
}

const UserEmploymentTeamRow = ({
  className = '',
  data,
  onEditClick,
  onDeleteClick,
  ...props
}: TeamListRowProps) => {
  const {
    userEmploymentsStates: { transferReasons },
  } = useUserEmployments();
  const deleteMessage = {
    pending: 'Pending',
    success: 'User Employment Team is successfully deleted. ',
    error: 'Error',
  };

  const userEmploymentTransferReasons = transferReasons.find(
    (transferReason) => transferReason.id === data.transferReasonId,
  );

  return (
    <div {...props} className={`${className} grid grid-cols-7 list-data  items-center p-2`}>
      <div className="">{data.team?.name}</div>
      <div className="">{data.employmentRole?.name}</div>
      <div className="">
        {data.teamLeader?.userInformation.firstName} {data.teamLeader?.userInformation.lastName}
      </div>
      <div className="">{data.dateStart}</div>
      <div className="">{data.dateEnd}</div>
      <div className="">{userEmploymentTransferReasons?.name}</div>
      <div className="action-buttons space-x-1 justify-self-center">
        <Tooltip content="Edit" className="bg-[#343434]">
          <button className="textButton" type="button" onClick={() => onEditClick(data)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
        </Tooltip>

        <DeleteConfirmationBanner
          data={data}
          label={data.team?.name}
          message={deleteMessage}
          onDeleteConfirm={onDeleteClick}
        />
      </div>
    </div>
  );
};
export default UserEmploymentTeamRow;
