import React, { useState } from 'react';
import { VariableSizeList } from 'react-window';
import { useDebounce } from 'use-debounce';
import TextWithIcons from '../../molecules/TextWithIcons';
import HolidayType from '../../../store/constants/holidays/holiday-type.interface';
import useHolidayTypes from '../../../hooks/holidays/useHolidayTypes';
import consoleLog from '../../../utils/consoleLog';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import HolidayTypeFormModal from './HolidayTypeFormModal';
import HolidayTypeListRow from './HolidayTypeListRow';
import SearchSvg from '../../../assets/images/icons/search.svg';
import { ReactComponent as Arrow } from '../../../assets/images/icons/arrow-with-tail-down.svg';

type SortByHeaderOptions = '' | 'name' | 'byRate';

const HolidayTypeList: React.FC = () => {
  const [selectedHolidayType, setSelectedHolidayType] = useState<HolidayType | undefined>();

  const { holidayTypesState, getAll, remove } = useHolidayTypes();

  const [searchValue, setSearchValue] = useState('');

  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');

  // Use a state variable to track ascending or descending order
  const [sortOrderBy, setSortOrderBy] = useState(false);

  // Function to toggle the sorting order
  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const holidays = holidayTypesState.holidayTypes
    .filter((holiday: HolidayType) => {
      const searchValues = debouncedSearchValue.toLowerCase();
      const ratePercentageString = holiday.ratePercentage.toString(); // Convert ratePercentage to string

      return (
        holiday.name.toLowerCase().startsWith(searchValues) ||
        ratePercentageString.startsWith(searchValues) // Match the ratePercentage
      );
    })
    .sort((a: HolidayType, b: HolidayType) => {
      let comparison = 0;

      if (sortOption === '') {
        comparison = a.id - b.id;
      }
      if (sortOption === 'name') {
        comparison = a.name.localeCompare(b.name);
      }
      if (sortOption === 'byRate') {
        comparison = b.ratePercentage - a.ratePercentage;
      }
      if (sortOrderBy) {
        comparison *= -1;
      }

      return comparison; // Default sorting
    });

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = false) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onEditClick = (holiday: HolidayType) => {
    setSelectedHolidayType(holiday);
  };

  const onDeleteClick = async (holiday: HolidayType) => {
    remove(holiday.id).then((r) => {
      consoleLog('DELETE HOLIDAY', r);
    });
  };

  const onClose = () => setSelectedHolidayType(undefined);

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  const regularHolidayCount = holidays.filter((h) => h.ratePercentage === 100).length;

  // @TODO
  // 1. web console error validateDOMNesting
  // 2. Data default sort by oldest - newest must change to newest - oldest

  return (
    <div className="w-full h-full mt-2 organisms-holiday-list">
      <div className="flex justify-between pb-5">
        <HolidayTypeFormModal holidayType={selectedHolidayType} onClose={onClose} />
        <div className="relative w-60">
          <input
            onKeyDown={onSearchKeyDown}
            type="text"
            className="search-body__input search-bar"
            placeholder="Search holiday type"
            onChange={handleSearch}
          />
          <img
            style={{ filter: 'invert(0) invert(1)' }}
            className="search-body__icon"
            src={SearchSvg}
            alt="Search for Holiday"
          />
        </div>
      </div>
      <div className="user-holiday-type-header table-header">
        <button
          type="button"
          onClick={() => sortByHeader('name', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'name' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'name' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Name</div>
          </TextWithIcons>
        </button>
        <button
          type="button"
          onClick={() => sortByHeader('byRate', false)}
          className="flex items-center gap-3"
        >
          <TextWithIcons
            className="flex"
            variant="h6"
            iconRight={
              sortOrderBy ? (
                <Arrow className={`sort-arrow-icon ${sortOption === 'byRate' ? '' : 'hidden'}`} />
              ) : (
                <Arrow
                  className={`sort-arrow-icon ${
                    sortOption === 'byRate' ? 'sort-arrow-rotate-icon' : 'hidden'
                  }`}
                />
              )
            }
          >
            <div className="flex gap-5">Name</div>
          </TextWithIcons>
        </button>

        <TextWithIcons variant="h6">Action</TextWithIcons>
      </div>

      <VariableSizeList
        itemCount={holidays.length}
        itemSize={getItemSize}
        height={window.innerHeight - 400}
        width="100%"
        className="no-scrollbars"
      >
        {({ index, style }) =>
          holidays[index] && (
            <HolidayTypeListRow
              regularHolidayCount={regularHolidayCount}
              data={holidays[index]}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
            />
          )
        }
      </VariableSizeList>
    </div>
  );
};

export default HolidayTypeList;
