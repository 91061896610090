import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react';
import PayrollViewPayslipTable from '../employeeTable/PayrollViewPayslipTable';
import PayrollViewExcelExport from '../toolOptionsProperties/PayrollViewExcelExport';
// eslint-disable-next-line import/no-cycle
import PayrollViewHideColumns from '../toolOptionsProperties/PayrollViewHideColumns';

export interface SwitchStates {
  [key: string]: boolean;
}

const PayslipToolOptions: React.FC = () => {
  const [open, setOpen] = React.useState(true);

  const [switchStates, setSwitchStates] = useState<SwitchStates>({
    // hide columns
    taxable: true,
    incentiveCompany: true,
    allowances: true,
    incentiveClient: true,
    deductions: true,
  });

  const handleSwitchChange = (id: string, checked: boolean) => {
    setSwitchStates((prevStates) => ({ ...prevStates, [id]: checked }));
  };

  return (
    <div>
      <div className="grid grid-cols-1">
        <Accordion
          open={open}
          // icon={
          //   <svg
          //     xmlns="http://www.w3.org/2000/svg"
          //     fill="none"
          //     viewBox="0 0 24 24"
          //     strokeWidth={2}
          //     stroke="#f05825"
          //     className="w-4 h-4"
          //   >
          //     <path
          //       strokeLinecap="round"
          //       strokeLinejoin="round"
          //       d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5"
          //     />
          //   </svg>
          // }
        >
          <div className="p-2 hover:bg-[#343434] rounded-none border-2 border-solid border-t-[#2c2b2a] border-b-[#2c2b2a] border-r-transparent border-l-transparent">
            <AccordionHeader
              onClick={() => setOpen(!open)}
              className="border-b-0 p-0 bg-[rgb(44, 43, 42) focus:bg-[rgb(44, 43, 42)"
            >
              <span className="mr-auto text-xs font-normal text-white">Options</span>
            </AccordionHeader>
          </div>
          <AccordionBody className="p-3 px-2 py-2">
            <div className="flex gap-5">
              <div className="group1 w-max">
                <div className="flex gap-5 w-max">
                  <PayrollViewHideColumns
                    switchStates={switchStates}
                    handleParamsSwitchesChange={handleSwitchChange}
                  />
                  <div className="separator" />
                </div>
              </div>

              <div className="excel-exports">
                <PayrollViewExcelExport />
              </div>
            </div>
          </AccordionBody>
        </Accordion>
      </div>
      <PayrollViewPayslipTable switchStates={switchStates} />
    </div>
  );
};

export default PayslipToolOptions;
