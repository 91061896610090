import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import { Typography } from '@material-tailwind/react';
import { Form, Formik } from 'formik';
import Team from '../../../store/constants/teams/team.interface';
import TextWithIcons from '../../molecules/TextWithIcons';
import useOnLoad from '../../../hooks/utils/useOnLoad';
import SelectTeam from '../../atoms/dropdowns/SelectTeam';
import EmptyListMessage from '../../atoms/EmptyListMessage';
import useUserEmployments from '../../../hooks/users/useUserEmployments';
import Employee from '../../../store/constants/employees/employee.interface';
import TimelogPageListRow from './TimelogPageListRow';
import SelectEmployee from '../../atoms/dropdowns/SelectEmployee';

const TimelogPageList: React.FC = () => {
  const navigate = useNavigate();
  const { userEmploymentsStates, getAll } = useUserEmployments();

  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>();
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>();

  const employees = userEmploymentsStates.userEmployments.filter((employee: Employee) => {
    if (!employee.employeeNumber) return false;

    if (!employee.teams[0]) return false;

    if (selectedEmployee && selectedEmployee.id !== employee.id) return false;

    if (selectedTeam && selectedTeam.id !== employee.teams[0].team?.id) return false;

    return true;
  });

  const onViewEmployeeTimelog = async (data: Employee) => {
    navigate(`/admin/attendance/logs/employees/${data.id}`);
  };

  const itemSizes: {
    [key: number]: number;
  } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    const itemHeight = 50;
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAll().then();
  });

  return (
    <div className="w-full organisms-timelog-list pr-5">
      <Formik initialValues={{}} onSubmit={() => {}}>
        <Form>
          <div className="flex space-x-10">
            <div className="space-y-2 w-full">
              <Typography className="muiTypography--headline6">Search employee</Typography>
              <SelectEmployee
                id="employee"
                name="employee"
                value={selectedEmployee}
                onChange={(value) => setSelectedEmployee(value as Employee)}
                hasSearchIcon
                isSearchable
                isClearable
              />
            </div>
            <div className="space-y-2 w-full">
              <Typography className="muiTypography--headline6">Search Team</Typography>
              <SelectTeam
                id="team"
                name="team"
                value={selectedTeam}
                onChange={(value) => setSelectedTeam(value as Team)}
                hasSearchIcon
                isSearchable
                isClearable
              />
            </div>
          </div>
        </Form>
      </Formik>
      <div className="p-2 border-b-2 timelog-list-header header-items">
        <TextWithIcons variant="h6">
          <div className="flex gap-5">ID</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Employee #</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Employee</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Team</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Team Leader</div>
        </TextWithIcons>
        <TextWithIcons variant="h6">
          <div className="flex gap-5">Action</div>
        </TextWithIcons>
      </div>

      {employees.length === 0 ? (
        <EmptyListMessage />
      ) : (
        <VariableSizeList
          itemCount={employees.length}
          itemSize={getItemSize}
          height={window.innerHeight - 400}
          width="100%"
          className="no-scrollbars"
        >
          {({ index, style }) => (
            /**
             * @todo create a component for this list row and pass the data as props
             */
            <TimelogPageListRow
              data={employees[index] as unknown as Employee}
              className={`${index % 2 === 0 ? 'stripped' : ''}`}
              style={style}
              onGenerateClick={onViewEmployeeTimelog}
            />
          )}
        </VariableSizeList>
      )}
    </div>
  );
};

export default TimelogPageList;
