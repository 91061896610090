import React from 'react';
import moment from 'moment';
import MyCalendar from '../calendar/Calendar';
import CurrentTimeDisplay from '../calendar/CurrentTimeDisplay';

const UserCalendar: React.FC = () => {
  const currentYear = moment().format('YYYY');

  return (
    <div className=" shadow-lg calendar-container">
      <div className="h-full">
        {/* first container */}
        <div className="w-full rounded-xl flex flex-col overflow-hidden user-calendar-wrapper">
          <div className="h-full w-full px-5 py-4 calendar-content-wrapper">
            {/* Date & Time */}
            <div className="w-full flex items-center justify-center">
              <CurrentTimeDisplay />
            </div>
          </div>
          {/* calendar */}
          <div className="w-full h-full main-calendar-wrapper">
            <MyCalendar />
          </div>
        </div>
        {/* company-information */}
        <div className="p-3 rounded-lg mt-6 w-full company-info">
          <span className="first-content">
            AwesomeOs Ground Floor, Tavera Business Center, Tavera St., Davao City 8000{' '}
          </span>
          <div className="second-content">
            <a href="tel:(082) 224-6209">
              <span>(082) 224-6209 </span>
            </a>
            <a href="tel:(082) 224-6208">
              <span>(082) 224-6208</span>
            </a>
          </div>
          <span className="third-content">
            OSNet v2.1.3. All Rights Reserved. Copyright © {currentYear} <br />
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserCalendar;
