import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import Payroll from '../../constants/payroll/payroll.interface';
import payrollServices, { GetAllPayrollQueryParams } from '../../services/payroll/payrollServices';
import AtLeast from '../../../types/atleast';
import Company from '../../constants/companies/company.interface';
import Payslip from '../../constants/payroll/payslip.interface';

const get = createAsyncThunk(
  'payrolls/get',
  async (
    id: Payroll['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'payrolls/employees',
  async (
    { page = 1, limit = 50, search = '' }: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getCompanyITR = createAsyncThunk(
  'payrolls/companies/id/itr',
  async (
    id: Company['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Company> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getCompanyITR(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAllLatesUndertime = createAsyncThunk(
  'payrolls/undertimes/generate',
  async (
    { page = 1 }: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getAllLatesUndertime({ page }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getLastpays = createAsyncThunk(
  'payrolls/lastpays',
  async (
    { page = 1, limit = 50, search = '', teamId = undefined }: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getLastpays({ page, limit, search, teamId }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getPayPeriods = createAsyncThunk(
  'payrolls/payperiods',
  async (
    _: void,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getPayPeriods(state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getEmployeePayrolls = createAsyncThunk(
  'payrolls/payslips',
  async (
    {
      page = 1,
      limit = 50,
      search = '',
      payPeriod = undefined,
      employeeNumbers = undefined,
      companyId = undefined,
      teamId = undefined,
      inactive = undefined,
      resigned = undefined,
      terminated = undefined,
      suspended = undefined,
      endOfContract = undefined,
      authorizedCause = undefined,
      dropTraining = undefined,
      resignedWithCase = undefined,
      awol = undefined,
      floating = undefined,
      lastPayIncentives = undefined,
    }: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getEmployeePayrolls(
        {
          page,
          limit,
          search,
          payPeriod,
          employeeNumbers,
          companyId,
          teamId,
          inactive,
          resigned,
          terminated,
          suspended,
          endOfContract,
          authorizedCause,
          dropTraining,
          resignedWithCase,
          awol,
          floating,
          lastPayIncentives,
        },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getEmployeeGross = createAsyncThunk(
  'payrolls/employees/gross',
  async (
    {
      scheduleTypeId,
      monthlyGross,
      basicGross,
    }: { scheduleTypeId: number | undefined; monthlyGross: number; basicGross: number },
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payroll['employeeNumber']> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getEmployeeGross(
        {
          scheduleTypeId,
          monthlyGross,
          basicGross,
        },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getPayslips = createAsyncThunk(
  'payrolls/payslip',
  async (
    {
      page = 1,
      limit = 50,
      search = '',
      payPeriod = undefined,
      employeeNumbers,
      companyId = undefined,
      teamId = undefined,
    }: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<Payslip> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.getPayslips(
        { page, limit, search, payPeriod, employeeNumbers, companyId, teamId },
        state,
      );
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const createPayslips = createAsyncThunk(
  'payrolls/employees/payslip',
  async (
    data: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.createPayslip(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const addIncentives = createAsyncThunk(
  'payrolls/incentives',
  async (
    data: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.addIncentives(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const createLastpay = createAsyncThunk(
  'payrolls/lastpays',
  async (
    data: Omit<Payroll, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.createLastpay(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const generateThirteenMonth = createAsyncThunk(
  'payrolls/13months',
  async (
    { payPeriod = undefined }: GetAllPayrollQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.generateThirteenMonth({ payPeriod }, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const updateTeamPHICBasic = createAsyncThunk(
  'payrolls/teams/id/basic',
  async (
    data: AtLeast<Payroll, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.updateTeamPHICBasic(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const updateTeamPHICGross = createAsyncThunk(
  'payrolls/teams/id/gross',
  async (
    data: AtLeast<Payroll, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<Payroll> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await payrollServices.updateTeamPHICGross(data, state);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default {
  get,
  getAll,
  getLastpays,
  getPayPeriods,
  getEmployeePayrolls,
  getPayslips,
  getCompanyITR,
  addIncentives,
  createLastpay,
  createPayslips,
  updateTeamPHICGross,
  updateTeamPHICBasic,
  getAllLatesUndertime,
  generateThirteenMonth,
  getEmployeeGross,
} as const;
