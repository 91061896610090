import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { VariableSizeList } from 'react-window';
import { Chip, Spinner, Typography } from '@material-tailwind/react';
import { ReactComponent as Arrow } from '../../../../assets/images/icons/arrow-with-tail-down.svg';
import useDateTime from '../../../../hooks/utils/useDateTime';
import useUserEmployments from '../../../../hooks/users/useUserEmployments';
import UserEmployeePosition from '../../../../store/constants/employees/positions/employee-position.interface';
import PositionEmployeeListRow from './PositionEmployeeListRow';
import TextWithIcons from '../../../molecules/TextWithIcons';
import PositionEmployeeDateEndModal from './PositionEmployeeDateEndModal';
import Breadcrumbs from '../../../molecules/Breadcrumbs';
import SearchSvg from '../../../../assets/images/icons/search.svg';
import useUserEmploymentPosition from '../../../../hooks/users/useUserEmploymentPosition';
import EmptyListMessage from '../../../atoms/EmptyListMessage';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import useUserPositionsAssignment from '../../../../hooks/users/useUserPositionsAssignment';

type SortByHeaderOptions = '' | 'employeeNumber' | 'employeeName' | 'tier' | 'rate' | 'probation';

const PositionEmployeeList: React.FC = () => {
  const { getDateTime } = useDateTime();
  const { getEmployeeByEmployeeNumber } = useUserEmployments();
  const { userPositionsState, getAll: getAllUserEmployments } = useUserPositionsAssignment();
  const [selectedEmployeePosition, setSelectedEmployeePosition] = useState<
    UserEmployeePosition | undefined
  >(undefined);
  const [sortOption, setSortOption] = useState<SortByHeaderOptions>('');
  const [sortOrderBy, setSortOrderBy] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 2000);

  const onSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchValue(searchValue);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const toggleSortOrderBy = () => {
    setSortOrderBy(!sortOrderBy);
  };

  const sortByHeader = (field: SortByHeaderOptions, defaultOrder = true) => {
    setSortOption(field);
    if (sortOption !== field) {
      setSortOrderBy(defaultOrder);
    } else {
      toggleSortOrderBy();
    }
  };

  const itemSizes: { [key: number]: number } = {};

  const getItemSize = (index: number) => {
    if (itemSizes[index] !== undefined) {
      return itemSizes[index];
    }
    // Calculate and store the height for the item
    const itemHeight = 50; // You can calculate the height dynamically based on the content
    itemSizes[index] = itemHeight;
    return itemHeight;
  };

  useOnLoad(() => {
    getAllUserEmployments();
  });

  const { id } = useParams<{
    id: string;
  }>();

  const { currentPosition: position, loading } = useUserEmploymentPosition(id as string);

  if (loading) return null;

  if (!position) return null;

  const userPositions = userPositionsState.userPositions
    .filter(
      (userPosition: UserEmployeePosition) => userPosition.employmentPositionId === position.id,
    )
    .filter((userPosition: UserEmployeePosition) => {
      const searchValues = debouncedSearchValue.toLowerCase();
      const employee = getEmployeeByEmployeeNumber(userPosition.employeeNumber as number);

      if (userPosition.employeeNumber === 0 || userPosition.employeeNumber === null) {
        return false;
      }

      return (
        employee?.userInformation.fullName.toLowerCase().includes(searchValues) ||
        searchValues === ''
      );
    })
    .sort((a, b) => {
      const sortOrder = sortOrderBy ? -1 : 1;

      if (sortOption === 'employeeNumber') {
        const aEmployeeNumber = a.employeeNumber;
        const bEmployeeNumber = b.employeeNumber;
        return sortOrder * aEmployeeNumber - bEmployeeNumber;
      }

      if (sortOption === 'employeeName') {
        const employeeA = getEmployeeByEmployeeNumber(a.employeeNumber as number);
        const employeeB = getEmployeeByEmployeeNumber(b.employeeNumber as number);

        return (
          sortOrder *
          (employeeA?.userInformation.fullName ?? '').localeCompare(
            employeeB?.userInformation.fullName ?? '',
          )
        );
      }

      if (sortOption === 'tier') {
        return sortOrder * (a.tier ?? '').localeCompare(b.tier ?? '');
      }

      if (sortOption === 'rate') {
        return (
          sortOrder * (a.employmentPositionRates?.maxGross ?? 0) -
          (b.employmentPositionRates?.maxGross ?? 0)
        );
      }

      if (sortOption === 'probation') {
        return sortOrder * (a.isProbation ?? 0) - (b.isProbation ?? 0);
      }

      return sortOrder * (a.id - b.id);
    });

  const handleDateEndClick = (data: UserEmployeePosition) => {
    setSelectedEmployeePosition(data);
  };

  return (
    <div className="user-positions-manage-form-container">
      <PositionEmployeeDateEndModal
        dataName="2023-11-30"
        className="hidden"
        userEmployeePosition={selectedEmployeePosition}
        onClose={() => setSelectedEmployeePosition(undefined)}
      />
      <Breadcrumbs className="breadcrumb-wrapper">
        <Link to="/admin/positions">
          <Typography className="crumb">Positions</Typography>
        </Link>

        <span className="flex items-center gap-2 ">
          <Typography className="crumb">{position?.name ?? ''}</Typography>
        </span>
        <span className="flex items-center gap-2 ">
          <Typography className="current-crumb">Employees</Typography>{' '}
          <Chip className="chip-style" value={userPositions.length} variant="ghost" />
        </span>
      </Breadcrumbs>
      <div className="pr-10 main-user-positions-section">
        <div className="management-heading">
          <Typography variant="h3" className="management-heading__secondary">
            Position Employees
          </Typography>
        </div>
        <div className="py-2 filter-section">
          <div className="search-body">
            <input
              onKeyDown={onSearchKeyDown}
              type="text"
              className="search-body__input search-bar"
              placeholder="Search Employee Position"
              onChange={handleSearch}
            />
            <img
              style={{ filter: 'invert(0) invert(1)' }}
              className="search-body__icon"
              src={SearchSvg}
              alt="Search for Position"
            />
          </div>
        </div>
        <div className="p-2 border-b-2 user-positions-header header-items">
          <button
            type="button"
            onClick={() => sortByHeader('employeeNumber', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'employeeNumber' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'employeeNumber' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Employee #</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('employeeName', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'employeeName' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'employeeName' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Employee</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('tier', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'tier' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'tier' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Tier</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('rate', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow className={`sort-arrow-icon ${sortOption === 'rate' ? '' : 'hidden'}`} />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'rate' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Rate</div>
            </TextWithIcons>
          </button>
          <button
            type="button"
            onClick={() => sortByHeader('probation', false)}
            className="flex items-center gap-3"
          >
            <TextWithIcons
              className="flex"
              variant="h6"
              iconRight={
                sortOrderBy ? (
                  <Arrow
                    className={`sort-arrow-icon ${sortOption === 'probation' ? '' : 'hidden'}`}
                  />
                ) : (
                  <Arrow
                    className={`sort-arrow-icon ${
                      sortOption === 'probation' ? 'sort-arrow-rotate-icon' : 'hidden'
                    }`}
                  />
                )
              }
            >
              <div className="flex gap-5">Probation</div>
            </TextWithIcons>
          </button>
          {/* <TextWithIcons variant="h6">
            <div className="flex gap-5">Probation</div>
          </TextWithIcons> */}
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Date Start</div>
          </TextWithIcons>
          <TextWithIcons variant="h6">
            <div className="flex gap-5">Date End</div>
          </TextWithIcons>
        </div>

        {loading && (
          <div className="w-full p-5 flex justify-center">
            <Spinner color="deep-orange" className="mr-2" />
          </div>
        )}
        {!loading && userPositions.length === 0 ? (
          <EmptyListMessage />
        ) : (
          <VariableSizeList
            itemCount={userPositions.length}
            itemSize={getItemSize}
            height={window.innerHeight - 400}
            width="100%"
            className="no-scrollbars"
          >
            {({ index, style }) => (
              <PositionEmployeeListRow
                data={userPositions[index]}
                className={`${index % 2 === 0 ? 'stripped' : ''}`}
                style={style}
                getDateTime={getDateTime}
                handleDateEndClick={handleDateEndClick}
              />
            )}
          </VariableSizeList>
        )}
      </div>
    </div>
  );
};

export default PositionEmployeeList;
