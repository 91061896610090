import React from 'react';

const HmoDashboardDependents = () => (
  <div className="flex bg-[#343434] shadow-lg rounded-md justify-between w-full p-2">
    <div className="flex flex-col justify-between p-2">
      <div>
        <p className="text-sm text-[#f05825] font-semibold">Total Dependents</p>
        <p className="text-xs text-gray-200">Last mesage sent an hour ago</p>
      </div>
      <div className="text-sm text-gray-200">1200 users</div>
    </div>
    <div className="p-2 bg-[#c0392b] text-white rounded-full m-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-12 w-12"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
      </svg>
    </div>
  </div>
);

export default HmoDashboardDependents;
