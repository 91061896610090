import React, { ButtonHTMLAttributes, FC } from 'react';
import Spinner from '../Spinner';

export type ButtonActionProps = {
  isSubmit?: boolean;
  isLoading?: boolean;
  text?: string;
  spinnerText?: string;
  hasSpinnerText?: boolean;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonAction: FC<ButtonActionProps> = ({
  isSubmit = true,
  isLoading = false,
  text = 'Submit',
  spinnerText = 'Loading...',
  hasSpinnerText = true,
  className = '',
  disabled = undefined,
  ...props
}) => (
  <button
    {...props}
    type={isSubmit ? 'submit' : 'button'}
    className={`button ${className}`}
    disabled={disabled || isLoading}
  >
    {isLoading ? (
      <div className="flex items-center gap-2">
        {hasSpinnerText && spinnerText}
        <Spinner />
      </div>
    ) : (
      text
    )}
  </button>
);

export default ButtonAction;
