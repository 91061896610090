import React from 'react';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import SelectTeam from '../../atoms/dropdowns/SelectTeam';
import SelectEmploymentRole from '../../atoms/dropdowns/SelectEmploymentRole';
import SelectTeamLeader from '../../atoms/dropdowns/SelectTeamLeader';
import SelectUserEmploymentTransferReason from '../../atoms/dropdowns/SelectUserEmploymentTransferReason';
import Team from '../../../store/constants/teams/team.interface';
import EmploymentRole from '../../../store/constants/employments/roles/employment-role.interface';
import UserEmployment from '../../../store/constants/employees/employee.interface';
import UserEmploymentTeam from '../../../store/constants/employees/teams/employee-team.interface';
import type { EmployeeTeamTransferReason } from '../../../store/constants/employees/teams/employee-team-transfer-reason';

export interface UserTeamAssignmetTestFormProps {
  handleSubmit: (values: UserEmploymentTeam) => void;
  handleReset: () => void;
  formError?: string;
  selectedTeam: Team | null;
  setSelectedTeam: React.Dispatch<React.SetStateAction<Team | null>>;
  selectedEmploymentRole: EmploymentRole | null;
  setSelectedEmploymentRole: React.Dispatch<React.SetStateAction<EmploymentRole | null>>;
  selectedUser: UserEmployment | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<UserEmployment | null>>;
  selectedTeamTransferReason: EmployeeTeamTransferReason | null;
  setSelectedTeamTransferReason: React.Dispatch<
    React.SetStateAction<EmployeeTeamTransferReason | null>
  >;
}

const initialFormValues = {
  id: 0,
  employeeNumber: undefined,
  employmentRole: undefined,
  team: undefined,
  teamLeader: undefined,
  dateStart: undefined,
  dateEnd: undefined,
  transferReasonId: undefined,
};

const UserTeamAssignmentTestForm: React.FC<UserTeamAssignmetTestFormProps> = ({
  handleSubmit,
  handleReset,
  selectedTeam,
  setSelectedTeam,
  selectedEmploymentRole,
  setSelectedEmploymentRole,
  selectedUser,
  setSelectedUser,
  selectedTeamTransferReason,
  setSelectedTeamTransferReason,
  formError = '',
}) => (
  <div>
    {formError && <div className="form-error">{formError}</div>}
    <Formik
      initialValues={{
        ...initialFormValues,
        dateStart: '',
      }}
      // onSubmit={handleSubmit}
      onSubmit={(values, { resetForm }: FormikHelpers<UserEmploymentTeam>) => {
        handleSubmit(values); // Call the provided handleSubmit function

        // Optionally, you can reset the form here
        resetForm();
      }}
    >
      {(formik) => (
        <Form className="employee-incase-of-emergency-info-form p-2 px-5 border border-[#2e2d2c] bg-[#252423] border-solid rounded-xl shadow-2xl w-full">
          <div className="mb-8">
            <div className="w-full mb-2">
              <label htmlFor="teamId" className="block mt-2 text-xs uppercase text-gray-300">
                Team
              </label>
            </div>
            <div className="w-full">
              <SelectTeam
                id="teamId"
                name="teamId"
                value={selectedTeam as Team}
                onChange={(value) => setSelectedTeam(value as Team)}
                isClearable
                required
              />
            </div>
            <ErrorMessage name="teamId" component="div" />
          </div>

          <div className="mb-8">
            <div className="w-full mb-2">
              <label
                htmlFor="employmentRoleId"
                className="block mt-2 text-xs uppercase text-gray-300"
              >
                Employee Role In Team
              </label>
            </div>
            <div className="flex-grow">
              <SelectEmploymentRole
                id="employmentRoleId"
                name="employmentRoleId"
                value={selectedEmploymentRole as EmploymentRole}
                onChange={(value) => setSelectedEmploymentRole(value as EmploymentRole)}
                isClearable
                required
              />
            </div>
            <ErrorMessage name="employmentRoleId" component="div" />
          </div>

          <div className="mb-8">
            <div className="w-full mb-2">
              <label
                htmlFor="teamLeaderEmployeeNumber"
                className="block mt-2 text-xs uppercase text-gray-300"
              >
                Employee Immediate Supervisor For The Team
              </label>
            </div>
            <div className="flex-grow">
              <SelectTeamLeader
                id="teamLeaderEmployeeNumber"
                name="teamLeaderEmployeeNumber"
                value={selectedUser as UserEmployment}
                onChange={(value) => setSelectedUser(value as UserEmployment)}
                isSearchable
                isClearable
                required
              />
            </div>
            <ErrorMessage name="teamLeader" component="div" />
          </div>

          <div className="mb-8">
            <div className="w-full mb-2">
              <label htmlFor="dateStart" className="block mt-2 text-xs uppercase text-gray-300">
                Date Start
              </label>
            </div>
            <div className="flex-grow">
              <Field
                type="date"
                id="dateStart"
                name="dateStart"
                placeholder="Date Start"
                value={formik.values.dateStart}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full p-2 bg-[#393836] border border-[#2e2d2c] rounded-lg shadow-lg focus:border-t-blue-500 focus:border-blue-500 ring-4 ring-transparent focus:ring-blue-500/20 shadow-blue-gray-900/5 placeholder:text-blue-gray-600 text-white"
              />
            </div>
            <ErrorMessage name="dateStart" component="div" />
          </div>

          <div className="mb-8">
            <div className="w-full mb-2">
              <label
                htmlFor="transferReason"
                className="block mt-2 text-xs uppercase text-gray-300"
              >
                Transfer Reason
              </label>
            </div>
            <div className="flex-grow">
              <SelectUserEmploymentTransferReason
                id="transferReason"
                name="transferReason"
                value={selectedTeamTransferReason as EmployeeTeamTransferReason}
                onChange={(value) =>
                  setSelectedTeamTransferReason(value as EmployeeTeamTransferReason)
                }
                isClearable
              />
            </div>
            <ErrorMessage name="transferReasonId" component="div" />
          </div>

          <div className="flex justify-between w-full p-5">
            <button
              type="submit"
              className="text-gray-200 text-sm bg-[#f05825] p-2 px-4 rounded-lg hover:bg-[#f01222] hover:shadow-lg"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="text-[#f05825] text-sm p-2 px-4 rounded-lg hover:bg-[#2c2b2a] hover:shadow-lg"
            >
              Cancel
            </button>
          </div>
        </Form>
      )}
    </Formik>
  </div>
);

export default UserTeamAssignmentTestForm;
