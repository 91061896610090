import { ColumnsType } from 'antd/es/table';
import type { EmploymentPayroll } from '../../../../../../store/reducers/payroll/payrollsReducer';

const ThirteenthMonthMonthlyColumn = (): ColumnsType<Partial<EmploymentPayroll>> => {
  const columns: ColumnsType<Partial<EmploymentPayroll>> = [
    {
      title: 'ID',
      width: 60,
      dataIndex: 'id',
      className: 'first-column',
      align: 'center',
      key: 'id',
    },
    {
      title: 'Name',
      width: 100,
      dataIndex: 'name',
      align: 'center',
      key: 'name',
    },
    {
      title: 'Schedule',
      width: 80,
      dataIndex: 'schedule',
      align: 'center',
      key: 'schedule',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      width: 100,
      align: 'center',
      key: 'department',
    },
    {
      title: 'Team',
      dataIndex: 'team',
      width: 80,
      align: 'center',
      key: 'team',
    },
    {
      title: 'Date Started',
      dataIndex: 'dateStarted',
      width: 80,
      align: 'center',
      key: 'dateStarted',
    },
    {
      title: 'Monthly Basic',
      dataIndex: 'monthlyBasic',
      width: 80,
      align: 'center',
      key: 'monthlyBasic',
    },
    {
      title: 'Monthly Gross',
      dataIndex: 'monthlyGross',
      width: 60,
      align: 'center',
      key: 'monthlyGross',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: 60,
      align: 'center',
      key: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      width: 60,
      align: 'center',
      key: 'endDate',
    },
    {
      title: '13 Month',
      dataIndex: 'daysLeave',
      width: 60,
      align: 'center',
      key: 'daysLeave',
    },
  ];
  return columns;
};

export default ThirteenthMonthMonthlyColumn;
