import { useState, ChangeEvent } from 'react';

const useUploadFileAttachment = () => {
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const maxFileSizeInBytes = 2 * 1024 * 1024;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > maxFileSizeInBytes) {
        setErrorMessage('File size must be less than 2MB');
        setSelectedFile('');
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setSelectedFile(reader.result);
          setErrorMessage('');
        }
      };

      reader.readAsDataURL(file);
    } else {
      setErrorMessage('Please select a file');
      setSelectedFile('');
    }
  };

  return { selectedFile, setSelectedFile, errorMessage, handleFileChange };
};

export default useUploadFileAttachment;
