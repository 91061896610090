import React from 'react';
import { Dialog, DialogBody } from '@material-tailwind/react';
import ButtonAction from '../../../atoms/buttons/ButtonAction';

interface LeaveFormConfirmationProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

const LeaveFormConfirmation: React.FC<LeaveFormConfirmationProps> = ({
  open,
  onClose,
  onConfirm,
  loading,
}) => (
  <div className="leave-confirmation leave-confirmation__parent-container">
    <Dialog size="sm" open={open} handler={onClose} className="leave-confirmation__dialog">
      <DialogBody className="leave-confirmation__body">
        <div className="flex h-full flex-col gap-6 justify-between leave-confirmation__wrapper">
          <p className="text-center leave-confirmation__message">
            Are you sure you want to cancel your leave application?
          </p>
          {/* Buttons */}
          <div className="flex justify-between px-6 leave-confirmation__actions">
            <div className="flex justify-center items-center leave-confirmation__action--submit">
              <ButtonAction
                type="submit"
                text="YES"
                className="flex justify-center items-center rounded-md shadow-lg leave-confirmation__button leave-confirmation__button--submit"
                isLoading={loading}
                hasSpinnerText={false}
                onClick={() => onConfirm()}
                isSubmit
              />
            </div>
            <div className="flex justify-center w-fit leave-confirmation__action leave-confirmation__action--cancel">
              <button
                type="button"
                onClick={onClose}
                className="rounded-md leave-confirmation__button leave-confirmation__button--cancel"
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  </div>
);

export default LeaveFormConfirmation;
