import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import NurseStationVisitActions from '../../../actions/my/transactionFeedActions';
import ApiBaseResponse from '../../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../../constants/utils/api-get-all-base-response.interface';
import consoleLog from '../../../../utils/consoleLog';
import upsertData from '../../../../utils/upsertData';
import NurseStationVisit from '../../../constants/my/healthTracker/nurse-station-visit.interface';

export interface NurseStationVisitsState {
  nurseStationVisits: NurseStationVisit[];
}

const initialState: NurseStationVisitsState = {
  nurseStationVisits: [
    {
      id: 1,
      date: new Date('2023-03-16'),
      complains: 'Patient complains of headaches.',
      findings: 'None',
      intervention: 'Prescribed pain relievers.',
      nurseInCharge: 'Nurse Joy',
      action: 'Follow-up appointment scheduled.',
      notes: 'Patient Just Headbutted a Steelix.  ',
    },
  ],
};

const getNurseStationVisitData = (nurseStationVisit: Partial<NurseStationVisit>) => ({
  ...nurseStationVisit,
});

const nurseStationVisitsSlice = createSlice({
  name: 'nurseStationVisits',
  initialState,
  reducers: {
    resetNurseStationVisitsState: () => ({
      ...initialState,
    }),
    setNurseStationVisitsState: (
      state,
      action: PayloadAction<Partial<NurseStationVisitsState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
    upsertNurseStationVisit: (state, action: PayloadAction<Partial<NurseStationVisit>>) => {
      const data: NurseStationVisit = getNurseStationVisitData(action.payload) as NurseStationVisit;

      if (typeof data.id === 'undefined') return;

      state.nurseStationVisits = upsertData(state.nurseStationVisits, data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(NurseStationVisitActions.post.fulfilled, (state, { payload }) => {
      const { data } = payload as ApiBaseResponse<NurseStationVisit>;
      consoleLog('NURSE_STATION_VISIT REDUCER', 'POST', data);

      if (typeof data.id === 'undefined') return;

      if (data) {
        state.nurseStationVisits = upsertData(state.nurseStationVisits, data);
      }
    });
    builder.addCase(NurseStationVisitActions.getAll.fulfilled, (state, { payload }) => {
      consoleLog('NURSE_STATION_VISIT REDUCER', 'GET-ALL', payload);

      const { status, data } = payload as ApiGetAllBaseResponse<NurseStationVisit>;

      if (status === 503) return;

      if (data) {
        state.nurseStationVisits = data;
      }
    });
    builder.addCase(NurseStationVisitActions.get.fulfilled, (state, { payload }) => {
      consoleLog('NURSE_STATION_VISIT REDUCER', 'GET', payload);

      const { data } = payload as ApiBaseResponse<NurseStationVisit>;

      if (typeof data.id === 'undefined') return;
      state.nurseStationVisits = upsertData(state.nurseStationVisits, data);
    });
    builder.addCase(NurseStationVisitActions.put.fulfilled, (state, { payload }) => {
      consoleLog('NURSE_STATION_VISIT REDUCER', 'PUT', payload);

      const { data } = payload as ApiBaseResponse<NurseStationVisit>;

      if (typeof data.id === 'undefined') return;
      state.nurseStationVisits = upsertData(state.nurseStationVisits, data);
    });
    builder.addCase(NurseStationVisitActions.remove.fulfilled, (state, { payload }) => {
      consoleLog('NURSE_STATION_VISIT REDUCER', 'REMOVE', payload);

      const { data } = payload as ApiBaseResponse<NurseStationVisit>;

      if (typeof data.id === 'undefined') return;
      state.nurseStationVisits = state.nurseStationVisits.filter(
        (nurseStationVisit) => nurseStationVisit.id !== data.id,
      );
    });
  },
});

export const nurseStationVisitsReducerActions = nurseStationVisitsSlice.actions;
export default nurseStationVisitsSlice.reducer;
