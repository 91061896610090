import React from 'react';
import { Field } from 'formik';
import SelectAutoSearch, { AutoSearchSelectProps } from './SelectAutoSearch';

export type ActiveStatus = 0 | 1 | null;

export interface ActiveStatusOption {
  value: ActiveStatus;
  label: string;
}

export interface SelectStatusProps extends Partial<AutoSearchSelectProps<ActiveStatusOption>> {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
}

export const ActiveStatusOptions = [
  {
    value: null,
    label: 'All',
  },
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
];

const SelectActiveStatus: React.FC<SelectStatusProps> = ({
  id,
  name,
  placeholder = 'Select active status...',
  className = '',
  ...props
}) => {
  const ActiveStatus = ActiveStatusOptions.slice().map((status) => ({
    ...status,
    value: status.value,
    label: status.label,
  }));

  return (
    <Field
      {...props}
      component={SelectAutoSearch}
      id={id}
      name={name}
      className={`select-client modal-select ${className}`}
      placeholder={placeholder}
      data={ActiveStatus}
      isSearchable
    />
  );
};

export default SelectActiveStatus;
