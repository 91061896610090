import React from 'react';
import { List, ListItem, ListItemSuffix, Chip } from '@material-tailwind/react';

const HmoGeneralList = () => (
  <div className="flex flex-col bg-[#343434] shadow-lg rounded-lg w-96 h-[90%] p-2">
    <section className="flex justify-between pb-2 p-1">
      <p className="text-sm text-[#f05825] font-semibold">General Lists</p>
      <p className="text-sm text-[#f05825] font-semibold">October 2023</p>
    </section>
    <hr className="rounded-md h-0.2 opacity-10" />
    <div className="">
      <List>
        <ListItem className="hover:bg-[#2c2b2a] focus:bg-[#2c2b2a] active:bg-[#2c2b2a]">
          <div className="flex items-center gap-3">
            <div className="bg-[#f05825] shadow-[0_4px_9px_-4px_#dc4c64] py-1 px-2 rounded-md font-medium text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-5 "
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" />
              </svg>
            </div>
            <span className="text-gray-200 text-sm">Inactive Members</span>
          </div>
          <ListItemSuffix>
            <Chip
              value="53"
              variant="ghost"
              size="sm"
              className="rounded-full text-gray-200 bg-[#f05825]"
            />
          </ListItemSuffix>
        </ListItem>
        <ListItem className="hover:bg-[#2c2b2a] focus:bg-[#2c2b2a] active:bg-[#2c2b2a]">
          <div className="flex items-center gap-3">
            <div className="bg-primary-orange shadow-[0_4px_9px_-4px_#dc4c64] py-1 px-2 rounded-md font-medium text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <span className="text-gray-200 text-sm">Active Members</span>
          </div>
          <ListItemSuffix>
            <Chip
              value="53"
              variant="ghost"
              size="sm"
              className="rounded-full text-gray-200 bg-[#f05825]"
            />
          </ListItemSuffix>
        </ListItem>
        <ListItem className="hover:bg-[#2c2b2a] focus:bg-[#2c2b2a] active:bg-[#2c2b2a]">
          <div className="flex items-center gap-3">
            <div className="bg-[#9b59b6] shadow-[0_4px_9px_-4px_#dc4c64] py-1 px-2 rounded-md font-medium text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
              </svg>
            </div>
            <span className="text-gray-200 text-sm">All Members</span>
          </div>
          <ListItemSuffix>
            <Chip
              value="53"
              variant="ghost"
              size="sm"
              className="rounded-full text-gray-200 bg-[#f05825]"
            />
          </ListItemSuffix>
        </ListItem>
        <ListItem className="hover:bg-[#2c2b2a] focus:bg-[#2c2b2a] active:bg-[#2c2b2a]">
          <div className="flex items-center gap-3">
            <div className="bg-green-500 shadow-[0_4px_9px_-4px_#dc4c64] py-1 px-2 rounded-md font-medium text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
              </svg>
            </div>
            <span className="text-gray-200 text-sm">New Enrollees</span>
          </div>
          <ListItemSuffix>
            <Chip
              value="53"
              variant="ghost"
              size="sm"
              className="rounded-full text-gray-200 bg-[#f05825]"
            />
          </ListItemSuffix>
        </ListItem>
        <ListItem className="hover:bg-[#2c2b2a] focus:bg-[#2c2b2a] active:bg-[#2c2b2a]">
          <div className="flex items-center gap-3">
            <div className="bg-[#3498db] shadow-[0_4px_9px_-4px_#dc4c64] py-1 px-2 rounded-md font-medium text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12 13a1 1 0 100 2h5a1 1 0 001-1V9a1 1 0 10-2 0v2.586l-4.293-4.293a1 1 0 00-1.414 0L8 9.586 3.707 5.293a1 1 0 00-1.414 1.414l5 5a1 1 0 001.414 0L11 9.414 14.586 13H12z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <span className="text-gray-200 text-sm">Deduction History</span>
          </div>
          <ListItemSuffix>
            <Chip
              value="53"
              variant="ghost"
              size="sm"
              className="rounded-full text-gray-200 bg-[#f05825]"
            />
          </ListItemSuffix>
        </ListItem>
      </List>
    </div>
  </div>
);
export default HmoGeneralList;
