import React from 'react';
import { Typography, List, ListItem, Card } from '@material-tailwind/react';
import EmployeeClockOutModal from './EmployeeClockOutModal';

const ReportsPage: React.FC = () => {
  // const inactiveEmployeePage = () => {
  //   navigate('/admin/attendance/reports/inactive-employee-report');
  // };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const inactiveEmployeePage = () => {
    // Specify the URL you want to open in a new tab or window
    const externalLink = '/admin/attendance/reports/inactive-employee-report'; // Replace this with your desired external link

    // Open the external link in a new tab or window
    window.open(externalLink, '_blank');
  };

  const AccountInfoReportPage = () => {
    // Specify the URL you want to open in a new tab or window
    const externalLink = '/admin/attendance/reports/account_info_report'; // Replace this with your desired external link

    // Open the external link in a new tab or window
    window.open(externalLink, '_blank');
  };

  const AttendanceExportPage = () => {
    // Specify the URL you want to open in a new tab or window
    const externalLink = '/admin/attendance/reports/attendance-export'; // Replace this with your desired external link

    // Open the external link in a new tab or window
    window.open(externalLink, '_blank');
  };

  const ClientDailyLeaveAttendancePage = () => {
    // Specify the URL you want to open in a new tab or window
    const externalLink = '/admin/attendance/reports/ajax-client-leave-reports'; // Replace this with your desired external link

    // Open the external link in a new tab or window
    window.open(externalLink, '_blank');
  };

  const ReferralDataPage = () => {
    // Specify the URL you want to open in a new tab or window
    const externalLink = '/admin/attendance/reports/referral-data'; // Replace this with your desired external link

    // Open the external link in a new tab or window
    window.open(externalLink, '_blank');
  };

  const TermsAndAgreementPage = () => {
    // Specify the URL you want to open in a new tab or window
    const externalLink = '/admin/attendance/reports/term-and-agreement-reports'; // Replace this with your desired external link

    // Open the external link in a new tab or window
    window.open(externalLink, '_blank');
  };

  return (
    <div className="w-full list-of-reports mt-5">
      <div className="title">
        <Typography variant="h3" className="text-[#f05825] ml-0 mt-2 mb-5">
          List of Reports
        </Typography>
      </div>
      <div className="w-[90%] border border-solid border-[#343434] rounded-lg shadow-lg">
        <div className="p-3 w-full bg-[#343434] rounded-t-lg">
          <span className="text-gray-200 font-bold">Reports</span>
        </div>
        <div className="p-5">
          <Card className="w-96 rounded-md bg-[#343434]">
            <List className="p-0">
              <ListItem
                onClick={inactiveEmployeePage}
                className="hover:bg-[#f05825] active:bg-[#f05825] focus:bg-[#f05825] rounded-t-lg rounded-b-none"
              >
                <span className="text-gray-200">Inactive Employee Report</span>
              </ListItem>
              <hr className="rounded-md h-0.1 opacity-10" />
              <ListItem
                onClick={AccountInfoReportPage}
                className="hover:bg-[#f05825] active:bg-[#f05825] focus:bg-[#f05825] rounded-none"
              >
                <span className="text-gray-200">Account Info Report</span>
              </ListItem>
              <hr className="rounded-md h-0.1 opacity-10" />
              <ListItem
                onClick={AttendanceExportPage}
                className="hover:bg-[#f05825] active:bg-[#f05825] focus:bg-[#f05825] rounded-none"
              >
                <span className="text-gray-200">Attendance Export</span>
              </ListItem>
              <hr className="rounded-md h-0.1 opacity-10" />
              <ListItem
                onClick={handleOpen}
                className="hover:bg-[#f05825] active:bg-[#f05825] focus:bg-[#f05825] rounded-none"
              >
                <span className="text-gray-200">Employee Clock-out Status Report</span>
              </ListItem>
              <hr className="rounded-md h-0.1 opacity-10" />
              <ListItem
                onClick={ClientDailyLeaveAttendancePage}
                className="hover:bg-[#f05825] active:bg-[#f05825] focus:bg-[#f05825] rounded-none"
              >
                <span className="text-gray-200">Client Daily Attendance Report</span>
              </ListItem>
              <hr className="rounded-md h-0.1 opacity-10" />
              <ListItem
                onClick={ReferralDataPage}
                className="hover:bg-[#f05825] active:bg-[#f05825] focus:bg-[#f05825] rounded-none"
              >
                <span className="text-gray-200">Referral Data</span>
              </ListItem>

              <hr className="rounded-md h-0.1 opacity-10" />
              <ListItem
                onClick={TermsAndAgreementPage}
                className="hover:bg-[#f05825] active:bg-[#f05825] focus:bg-[#f05825] rounded-b-lg rounded-t-none"
              >
                <span className="text-gray-200">Terms and Agreements Reports</span>
              </ListItem>
            </List>
          </Card>
        </div>
      </div>
      {open && <EmployeeClockOutModal open={open} handleOpen={handleOpen} />}
    </div>
  );
};

export default ReportsPage;
