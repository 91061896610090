import React, { useState } from 'react';
import { Spinner } from '@material-tailwind/react';
import useDateTime from '../../hooks/utils/useDateTime';
import useTransactionFeed from '../../hooks/my/useTransactionFeeds';
import TransactionFeedType from '../../store/constants/my/transaction-feed.interface';
import { ReactComponent as Calendar } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-small-right.svg';

interface TransactionFeedCardProps {
  transaction: {
    id: number;
    name: string;
    description: string;
    dateRead: Date;
    dateShared: Date;
  };
  className?: string;
}

const TransactionFeedCard: React.FC<TransactionFeedCardProps> = ({
  transaction,
  className = '',
}) => {
  const { getDate } = useDateTime();
  const { isRead } = useTransactionFeed();

  const [loading, setLoading] = useState(false);

  const handleMarkAsRead = async () => {
    try {
      setLoading(true);
      await isRead(transaction as TransactionFeedType);
      // eslint-disable-next-line
      console.log('Marked as read successfully!');
    } catch (error) {
      // eslint-disable-next-line
      console.error('An error just occur', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`shadow-md ${className}`} key={transaction.id}>
      {/* img & title */}
      <div className="flex justify-between mb-2 pr-4">
        <div className="flex items-center w-full gap-2 mb-2">
          <span className="w-4 h-4 rounded-full user-image" />
          <h2 className="flex items-center">{transaction.name}</h2>
        </div>
        <div className="flex justify-center items-center">
          {loading ? <Spinner height="1.2rem" color="deep-orange" /> : <span />}
        </div>
      </div>
      {/* description */}
      <p className="transaction-description-card">{transaction.description}</p>
      {/* bottom read / shared */}
      <div className="w-full flex justify-between mt-2 px-1 transaction-date-wrapper">
        <div className="flex items-center gap-2">
          <span className="w-2 h-2 rounded-full user-image" />
          <button
            type="button"
            onClick={handleMarkAsRead}
            className="flex items-center gap-1 cursor-pointer read-date"
          >
            <span className="read-text text-[0.7rem]">Mark as read</span>
            <ArrowRight className="w-4 h-auto icons arrow-right" />
          </button>
        </div>
        <div className="flex items-center gap-2">
          <Calendar className="w-5 h-5 icons" />
          <span className="shared-date">Shared on {getDate(transaction.dateShared)}</span>
        </div>
      </div>
    </div>
  );
};

export default TransactionFeedCard;
