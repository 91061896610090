import { createAsyncThunk } from '@reduxjs/toolkit';
import { StateHeaders } from '../../../utils/apiFetch';
import AtLeast from '../../../types/atleast';
import ApiBaseResponse from '../../constants/utils/api-base-response.interface';
import ApiGetAllBaseResponse from '../../constants/utils/api-get-all-base-response.interface';
import EmploymentPosition from '../../constants/employments/positions/employment-position.interface';
import positionsServices, {
  GetAllPositionsQueryParams,
} from '../../services/positions/positionsServices';

const get = createAsyncThunk(
  'position/get',
  async (
    id: EmploymentPosition['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionsServices.get(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const getAll = createAsyncThunk(
  'position/getAll',
  async (
    { page = 1, limit = 50, search = '' }: GetAllPositionsQueryParams,
    { getState, rejectWithValue },
  ): Promise<ApiGetAllBaseResponse<EmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionsServices.getAll({ page, limit, search }, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const post = createAsyncThunk(
  'position/post',
  async (
    data: Omit<EmploymentPosition, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionsServices.post(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const put = createAsyncThunk(
  'position/put',
  async (
    data: AtLeast<EmploymentPosition, 'id'>,
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionsServices.put(data, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

const remove = createAsyncThunk(
  'position/remove',
  async (
    id: EmploymentPosition['id'],
    { getState, rejectWithValue },
  ): Promise<ApiBaseResponse<EmploymentPosition> | unknown> => {
    try {
      const state = getState() as StateHeaders;
      return await positionsServices.remove(id, state);
    } catch (err) {
      // The validation error that is stored in the authReducer state error
      return rejectWithValue(err);
    }
  },
);

export default { get, getAll, post, put, remove } as const;
