import React, { useState } from 'react';
import { Navbar, Typography } from '@material-tailwind/react';
import { useDebounce } from 'use-debounce';
import { Link } from 'react-router-dom';
import InputIconLeft from '../../../molecules/InputIconLeft';
import UserNotification from './UserHeaderNotification';
import consoleLog from '../../../../utils/consoleLog';
import useTheme from '../../../../hooks/utils/useTheme';
import useOnLoad from '../../../../hooks/utils/useOnLoad';
import UserAttendanceAction from '../attendanceAction/UserAttendanceAction';
import useSettings from '../../../../hooks/utils/useSettings';
import { ReactComponent as MenuIcon } from '../../../../assets/images/icons/menu.svg';

const UserNavbar: React.FC = () => {
  const [search, setSearch] = useState<string>();
  const { theme, handleThemeChange } = useTheme();
  const [debouncedSearchValue] = useDebounce(search, 2000);
  const { showUserDrawer } = useSettings();

  consoleLog('LOG_THIS', 'debouncedSearchValue', debouncedSearchValue);

  const navbarComponent = (
    <div className="flex items-center gap-5 lg:gap-7">
      <div className="user-navbar__search hidden text-xs lg:flex">
        <InputIconLeft
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={`user-navbar__search-input ${theme} w-full `}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 theme-mode-icon__light"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={1}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          }
          placeholder="Search..."
        />
      </div>

      {/* search bar for smaller devices */}
      <div className="hidden md:flex lg:hidden">
        <div className="user-navbar__search--size-sm">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="user-navbar__search-input--size-sm"
            type="text"
            placeholder="Search..."
          />
          <button className="user-navbar__search-button--size-sm" type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Notification button */}
      <div className="">
        <UserNotification />
      </div>

      {/* remove className="hidden" to enable theme switcher button */}
      <div className="hidden place-content-center">
        <button type="button" onClick={handleThemeChange}>
          {theme === 'dark' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 theme-mode-icon__light"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
              />
            </svg>
          )}
        </button>
      </div>
      {/* Attendance Buttons */}
      <UserAttendanceAction />
    </div>
  );

  useOnLoad(() => () => {
    setSearch(undefined);
  });

  return (
    <div className="navbar sticky top-0 z-50">
      <Navbar className={`user-navbar ${theme} max-w-full rounded-none`}>
        <div className="flex items-center justify-between mr-4">
          <div className="flex gap-3">
            <Link to="/user/newsfeed">
              <div className="user-navbar__logo">
                <Typography className="user-navbar__logo-text">AWESOME CX</Typography>
                <span className={`user-navbar__logo-subtext ${theme}`}>by Transcom</span>
              </div>
            </Link>
            <button type="button" onClick={showUserDrawer} className="block 2xl:hidden">
              <MenuIcon className="w-6 h-6" />
            </button>
          </div>
          <div className="flex items-center">{navbarComponent}</div>
        </div>
      </Navbar>
    </div>
  );
};

export default UserNavbar;
